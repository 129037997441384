import cx from "classnames";
import parse from "html-react-parser";
import React from "react";
import { ClueProps } from "src/pages/Play/Clue/types";
import classes from "./styles.module.scss";
type TextFieldQuestionProps = { clue: ClueProps; isModal?: boolean };

const TextFieldQuestion: React.FC<TextFieldQuestionProps> = ({
  isModal = false,
  clue,
}) => {
  return (
    <div
      className={cx(classes.wrapper, {
        [classes.modalQuestionWrapper]: isModal,
      })}
    >
      <div className={classes.extraText}>{parse(clue?.extra_text || "")}</div>
    </div>
  );
};

export default TextFieldQuestion;

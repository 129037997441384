import { useReducerData } from "src/store/hooks";
import { ROLE_LEVEL } from "../constants/role";

const useUserProfile = () => {
  return useReducerData("auth", "userProfile.data", {});
};

const useUserProfileLoading = () => {
  return useReducerData("auth", "userProfile.loading", false);
};

const useIsUserProfileFreeTrial = () => {
  const userProfile = useUserProfile();
  return userProfile.type === ROLE_LEVEL.FREE_TRIAL;
};

export { useUserProfileLoading, useIsUserProfileFreeTrial };
export default useUserProfile;

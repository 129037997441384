import cn from "classnames";
import { useEffect } from "react";
import classes from "./TicToe.module.scss";
import { initGame } from "./utils";

interface Props {
  onSuccess?: () => void;
  answer?: string;
  disabled?: boolean;
}

const TicToe = ({ onSuccess, answer, disabled }: Props) => {
  useEffect(() => {
    const { setIntervalId } = initGame({
      onRestart: () => {
        // restart event
      },
      onStart: () => {
        // start event
      },
      onEnd: () => {
        // end event
      },
      onSuccess: disabled ? undefined : onSuccess,
      onDraw: () => {
        // draw event
      },
      answer,
    });
    return () => {
      clearInterval(setIntervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.gameWrapper}>
        <main className="gameboard">
          <div className="row row-01">
            <div className="square square-00" data-index="0"></div>
            <div className="square square-01" data-index="1"></div>
            <div className="square square-02" data-index="2"></div>
          </div>

          <div className="row row-02">
            <div className="square square-03" data-index="3"></div>
            <div className="square square-04" data-index="4"></div>
            <div className="square square-05" data-index="5"></div>
          </div>

          <div className="row row-03">
            <div className="square square-06" data-index="6"></div>
            <div className="square square-07" data-index="7"></div>
            <div className="square square-08" data-index="8"></div>
          </div>
        </main>
      </div>
      <div
        id="start_game"
        className={cn("success_popup", {
          hide: !!answer,
        })}
      >
        <h2>Play!</h2>
        <button id="bt_start_game">Start Game</button>
      </div>

      <div
        id="success_game"
        className={cn("success_popup", {
          hide: !answer,
        })}
      >
        <h2>Congratulations!</h2>
        <div>Here is your clue answer:</div>
        <div
          id="custom-game-answer"
          className={cn({
            getting: !answer,
          })}
        >
          {answer || ""}
        </div>
      </div>

      <div id="fail_game" className="success_popup hide">
        <div id="error_message"></div>
        <button id="bt_restart_game">Try Again</button>
      </div>
    </div>
  );
};

export default TicToe;

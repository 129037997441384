import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { useUserProfileLoading } from "src/helpers/hooks/useUserProfile";
import WishLampImage from "src/pages/Auth/assets/images/wish-lamp-img.svg";
import { getUserProfile, postPaymentDetails } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Confirm: React.FC<Props> = ({ selectedPlans }) => {
  const navigate = useNavigate();
  const { loading } = useReducerData("auth", "payment", {});
  const addPaymentMethodsLoading = useReducerData(
    "auth",
    "addPaymentMethods.loading",
    false
  );
  const profileLoading = useUserProfileLoading();
  const { payment, plan } = selectedPlans;
  const cardHolderName = payment?.name_on_card?.split(" ") || [];
  const [firstName, lastName] = cardHolderName;
  const actions = useStoreActions({
    postPaymentDetails,
    getUserProfile,
  });

  const handleConfirm = async () => {
    const paymentDetail = {
      payment_id: payment?.payment_id,
      plan_id: plan?.id,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.postPaymentDetails(paymentDetail);
    await actions.getUserProfile();
    if (status) {
      navigate("/subscription/completed");
    }
  };

  useEffect(() => {
    if (!payment.payment_id) {
      navigate("/subscription/plan");
    }
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.signup_confirm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.confirmTitle}>
          <div>
            <Typography
              variant="p24"
              className={cx("mb-2", classes.confirmText)}
            >
              Confirm
            </Typography>
            <Typography variant="p16" className="mb-4">
              Review your order details before completing
            </Typography>
          </div>

          <div
            className={classes.edit}
            onClick={() => navigate("/subscription/payment")}
          >
            <FontAwesomeIcon icon={faPencil} />
            <Typography variant="p16" className="mb-2">
              Edit
            </Typography>
          </div>
        </div>

        <div className={classes.purchase}>
          <Typography
            fontWeight="bold"
            variant="p18"
            className={classes.purchaseText}
          >
            Purchase
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}{" "}
          <div>
            <Typography variant="p16" className="mb-2">
              {plan?.type === "monthly"
                ? "Monthly Subscription for Wish Lamp$"
                : "Annual Subscription for Wish Lamp$"}
            </Typography>
            <img src={WishLampImage} alt="image not found" width={180} />
          </div>
        </div>
        <div className={cx(classes.nameWrapper, "mt-5")}>
          <Typography
            fontWeight="bold"
            variant="p18"
            className={classes.nametext}
          >
            First Name
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}
          <Typography variant="p16">{firstName}</Typography>
        </div>
        <div className={classes.nameWrapper}>
          <Typography fontWeight="bold" variant="p18">
            Last Name
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}{" "}
          <Typography variant="p16">{lastName}</Typography>
        </div>
        <div className={classes.nameWrapper}>
          <Typography fontWeight="bold" variant="p18">
            Card Number
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}{" "}
          <Typography variant="p16">**** **** **** {payment?.last4}</Typography>
        </div>
        <div className={(classes.nameWrapper, classes.bottomMg)}>
          <Typography fontWeight="bold" variant="p18">
            Subscription
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}{" "}
          <Typography variant="p16">
            {plan?.type === "monthly" ? "Monthly" : "Annual"}
          </Typography>
        </div>
      </div>

      <div className={classes.buttonWrapper}>
        <Button
          buttonText="Confirm and Complete"
          buttonColor="secondary"
          type="submit"
          loading={loading || profileLoading}
          onClick={handleConfirm}
          buttonClassName={classes.button}
          disabled={addPaymentMethodsLoading}
        />
      </div>
    </div>
  );
};

export default Confirm;

import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import Key from "src/pages/Home/assets/images/key.png";
import classes from "./styles.module.scss";

const GameAbout = ({ redirectLink = "" }) => {
  const navigate = useNavigate();

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.container}
        style={{
          backgroundImage: `url(${Key})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className={classes.title}>
          <Typography
            fontWeight="bold"
            variant="p44"
            fontFamily="milonga"
            className={classes.titleText}
          >
            ABOUT TREASURE GAME<span>$</span>
          </Typography>
          <Typography
            fontFamily="swiss"
            variant="p28"
            className={classes.subtitleText}
          >
            Treasure Game$ is the first global company to provide
            <br className={classes.break} />{" "}
            <span>
              NATIONWIDE LIVE ACTION TECH DRIVEN REAL TREASURE HUNTING GAMES.
            </span>
          </Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.info}>
            <Typography variant="p18" fontWeight="bold" fontFamily="inter">
              <span>‘WISH LAMP$’</span> is the first game we are releasing.{" "}
              <span>(Find a lamp, get $1 Million Cash or 3 Real Wishes.)</span>{" "}
              We have developed more original and fun treasure hunting games
              that will soon follow. We will be starting in the US and then
              quickly rolling out our games around the world.
            </Typography>
            <Typography variant="p18" fontWeight="bold" fontFamily="inter">
              <span>Our games and prizes are real.</span> Our process is secure.
              Code of game play conduct is required.
            </Typography>{" "}
            <Typography variant="p18" fontWeight="bold" fontFamily="inter">
              <span className={classes.underLine}>
                You can Play or Watch or both.
              </span>{" "}
              There will be plenty of self-filmed content posted by <br /> our{" "}
              <span>Treasure Hunters</span> to enjoy <br /> while also seeing
              who finds <br /> the Lamps and what they <br /> Wish for!
            </Typography>{" "}
            <Typography variant="p18" fontWeight="bold" fontFamily="inter">
              A percentage of all profits <br /> will be invested in people that
              <br />
              are working to <span>make the world better for all.</span>
            </Typography>
          </div>
          <div className={classes.button}>
            <Button
              buttonText="Join Treasure Game$"
              buttonColor="secondary"
              onClick={() => navigate(redirectLink)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameAbout;

import * as notificationActions from "src/store/actions/notification";
import { useReducerData, useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {
    userNotificationCount: useReducerData(
      "notification",
      "userNotificationCount.data",
      0
    ),
    userNotifications: useReducerData(
      "notification",
      "userNotifications.data",
      []
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...notificationActions,
  });
};

import get from "lodash/get";
import { Dispatch } from "redux";
import {
  apiGetWatchChannel,
  apiGetWatchSubChannel,
  apiGetWatchUser,
  apiGetWatchUserFeed,
  apiGetWatchUserPosts,
  apiUpdateChannelFollowStatus,
  apiUpdateWatchUser,
  getChannelFollowers as getChannelFollowersApi,
} from "src/api/watch";
import { ROLE_NAME } from "src/helpers/constants/role";
import { TREASURE_GAME_USERNAME } from "src/pages/Watch/constants";

import store from "..";
import Types from "../types/watch";

export const syncUpdateWatchUser = (data: any) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_WATCH_USER,
    payload: data,
  });
};
export const syncResetWatchUserFeedMeta = () => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_RESET_WATCH_USER_FEED_META,
  });
};
export const syncResetWatchUserRepostFeedMeta = () => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_RESET_WATCH_USER_REPOST_FEED_META,
  });
};
export const getWatchUser =
  (id: number | string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_WATCH_USER,
      asyncCall: () => {
        return apiGetWatchUser(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getWatchChannel =
  (slug: string, filters?: any, cb?: any) => (dispatch: Dispatch) => {
    const { isMore, ...other } = filters || {};
    dispatch({
      isAsyncCall: true,
      payload: { slug, isMore },
      type: Types.GET_WATCH_CHANNEL,
      asyncCall: () => {
        const tState = store.getState();
        const isAdmin =
          get(tState, "auth.userProfile.data.user.role.name") ===
          ROLE_NAME.ADMIN;
        if (isAdmin) {
          other.is_full_access = true;
        }

        return apiGetWatchChannel(slug, other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getWatchSubChannel =
  (slug: string, subSlug: string, filters?: any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = filters || {};
    dispatch({
      isAsyncCall: true,
      payload: { slug: subSlug, isMore },
      type: Types.GET_WATCH_SUB_CHANNEL,
      asyncCall: () => {
        const tState = store.getState();
        const isAdmin =
          get(tState, "auth.userProfile.data.user.role.name") ===
          ROLE_NAME.ADMIN;
        if (isAdmin) {
          other.is_full_access = true;
        }
        return apiGetWatchSubChannel(slug, subSlug, other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getWatchUserPosts =
  (id: number, filters: any, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_WATCH_USER_POSTS,
      asyncCall: () => {
        return apiGetWatchUserPosts(id, filters);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getWatchUserFeed =
  (userId: number | string, filters?: any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = filters || {};
    dispatch({
      isAsyncCall: true,
      payload: { userId, isMore },
      type: Types.GET_WATCH_USER_FEED,
      asyncCall: () => {
        return apiGetWatchUserFeed(userId, other);
      },
      onSuccess: (dispatch: any, response: any) => {
        if (
          userId === TREASURE_GAME_USERNAME &&
          !filters?.cursor &&
          !filters?.isMore
        ) {
          dispatch(
            syncUpdateWatchUser({
              posts_count: response.data?.meta?.total,
            })
          );
        }
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getWatchUserRepostFeed =
  (userId: number | string, filters?: any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = filters || {};
    dispatch({
      isAsyncCall: true,
      payload: { userId, isMore },
      type: Types.GET_WATCH_USER_REPOST_FEED,
      asyncCall: () => {
        return apiGetWatchUserFeed(userId, {
          ...(other || {}),
          reposted: true,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const updateWatchUser =
  (id: number, data: { desc: string }, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { ...data },
      type: Types.UPDATE_WATCH_USER,
      asyncCall: () => {
        return apiUpdateWatchUser(id, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const getChannelFollowers =
  (userId: number, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAysncCall: true,
      payload: {},
      type: Types.GET_CHANNEL_FOLLOWERS,
      asyncCall: () => {
        return getChannelFollowersApi(userId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const updateChannelFollowStatus =
  (id: number, isFollowing: boolean, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { isFollowing: isFollowing },
      type: Types.UPDATE_CHANNEL_FOLLOW_STATUS,
      asyncCall: () => {
        return apiUpdateChannelFollowStatus(id, isFollowing);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

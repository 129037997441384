import Text from "./Text";
import classes from "./styles.module.scss";

const ReleaseAssumption = () => {
  return (
    <>
      <div className={classes.termContent}>
        <h2 className={classes.underline}>
          <span>TG$ Risk Waiver</span>
        </h2>
        <Text>Last Modified: December 18, 2023</Text>
        <br />
        <Text>
          In consideration of the services of Treasure Games, LLC, their agents,
          owners, officers, volunteers, employees, and all other persons or
          entities acting in any capacity on their behalf (hereinafter
          collectively referred to as “TREASURE GAMES” I hereby agree to
          release, indemnify, and discharge TREASURE GAMES, on behalf of
          TREASURE GAMES myself, my spouse, my children, my parents, my heirs,
          assigns, personal representative and estate as follows:
        </Text>
        <ul>
          <li>
            I acknowledge that my participation in treasure hunting activities
            entails known and unanticipated risks that could result in physical
            or emotional injury, paralysis, death, or damage to myself, to
            property, or to third parties. I understand that such risks simply
            cannot be eliminated without jeopardizing the essential qualities of
            the activity.
          </li>
        </ul>
        <Text>
          The risks include, among other things: slips and falls; moderate
          physical activity; falling object; colliding with others; strains,
          sprains, broken bones and musculoskeletal injuries including head,
          neck, and back injuries; possibility of drowning; or other water
          hazards; cuts, abrasions, rope burns, bruises; cardiac related
          illness; heat exhaustion, heat stroke; my own physical condition, and
          the physical exertion associated with this activity; transmissible
          pathogen or disease; equipment failure or operator error; possibility
          of collisions with fixed or moving objects; being lost or separated
          from their companion(s); exposure to sun, strong wind, cold; exposure
          to temperature and weather extremes which could cause cold shock,
          hypothermia, hyperthermia (heat related illnesses), heat exhaustion,
          sunburn, dehydration; exposure to potentially dangerous wild animals,
          insect bites, and hazardous plant life; aggressive and/or poisonous
          marine life; accidents or illness can occur in remote places without
          medical facilities and emergency treatment or other services rendered;
          my own physical condition and the physical exertion associated with
          this activity. In any event, if you or your child is injured, any
          medical assistance will be at your own expense.
        </Text>
        <Text>
          Furthermore, TREASURE GAMES personnel have difficult jobs to perform.
          They seek safety, but they are not infallible. They might be unaware
          of a participant's fitness or abilities. They might misjudge the
          weather or other environmental conditions. They may give incomplete
          warnings or instructions, and the equipment being used might
          malfunction.
        </Text>
        <ul>
          <li style={{ height: 0, visibility: "hidden" }}></li>
          <li>
            I expressly agree and promise to accept and assume all of the risks
            existing in this activity. My participation in this activity is
            purely voluntary, and I elect to participate in spite of the risks.
          </li>
          <li>
            I hereby voluntarily release, forever discharge, and agree to
            indemnify and hold harmless TREASURE GAMES from any and all claims,
            demands, or causes of action, which are in any way connected with my
            participation in this activity or my use of TREASURE
            <br />
            GAMES equipment or facilities, including any such claims which
            allege negligent acts or omissions of TREASURE GAMES.
          </li>
          <li>
            Should TREASURE GAMES or anyone acting on their behalf, be required
            to incur attorney's fees and costs to enforce this agreement, I
            agree to indemnify and hold them harmless for all such fees and
            costs.
          </li>
          <li>
            I certify that I have adequate insurance to cover any injury or
            damage I may cause or suffer while participating, or else I agree to
            bear the costs of such injury or damage myself. I further certify
            that I am willing to assume the risk of any medical or physical
            condition I may have.
          </li>
          <li>
            In the event that I file a lawsuit against TREASURE GAMES, I agree
            to do so solely in the state of Delaware and I further agree that
            the substantive law of that state shall apply in that action without
            regard to the conflict of law rules of that state. I agree that if
            any portion of this agreement is found to be void or unenforceable,
            the remaining portions of this participant agreement shall remain in
            full force and effect.
          </li>
        </ul>
        <Text>
          By checking the corresponding box below, I acknowledge that if anyone
          is hurt or property is damaged during my participation in this
          activity, I may be found by a court of law to have waived my right to
          maintain a lawsuit against TREASURE GAMES on the basis of any claim
          from which I have released them herein. I have had sufficient
          opportunity to read this entire document. I also agree that this
          document is valid for subsequent visits and/or participation at any
          and all events or games hosted by TREASURE GAMES. I have read and
          understood it, and I agree to be bound by its terms.
        </Text>
        <br />
        <Text>
          PARENT'S OR GUARDIAN'S ADDITIONAL INDEMNIFICATION (Must be completed
          for participants under the age of 18). If I am a parent or legal
          guardian of a minor participant, I further agree to indemnify and hold
          harmless TREASURE GAMES from any and all claims which are brought by,
          or on behalf of minor(s), and which are in any way connected with such
          use or participation by minor(s).
        </Text>
      </div>
    </>
  );
};

export default ReleaseAssumption;

import { createTypes } from "./createTypes";

export default createTypes(
  "CLUE_MODAL_TOGGLE",
  "PURCHASE_MODAL_TOGGLE",
  "SET_CLUE_DATA",
  "FETCH_CLUES_STARTED",
  "FETCH_CLUES_SUCCESS",
  "FETCH_CLUES_FAILED",
  "POST_CLUE_ANSWER_STARTED",
  "POST_CLUE_ANSWER_SUCCESS",
  "POST_CLUE_ANSWER_FAILED",
  "PURCHASE_CLUE_HINT_STARTED",
  "PURCHASE_CLUE_HINT_SUCCESS",
  "PURCHASE_CLUE_HINT_FAILED",
  "FETCH_PURCHASE_HINT_LIST_STARTED",
  "FETCH_PURCHASE_HINT_LIST_SUCCESS",
  "FETCH_PURCHASE_HINT_LIST_FAILED",
  "EDIT_CLUE_ANSWER_STARTED",
  "EDIT_CLUE_ANSWER_SUCCESS",
  "EDIT_CLUE_ANSWER_FAILED",
  "EDIT_CLUE_STATUS_STARTED",
  "EDIT_CLUE_STATUS_SUCCESS",
  "EDIT_CLUE_STATUS_FAILED",
  "FETCH_CLUE_TYPES_STARTED",
  "FETCH_CLUE_TYPES_SUCCESS",
  "FETCH_CLUE_TYPES_FAILED",
  "FETCH_SEASONS_STARTED",
  "FETCH_SEASONS_SUCCESS",
  "FETCH_SEASONS_FAILED",
  "SET_SEASON_INFO",
  "INTERACTIVE_WORD_PUZZLE_STATE_UPDATE",
  "GET_ADVANTAGE_CLUES_PURCHASE_HISTORY",
  "PURCHASE_RANGE_FINDER_ADVANTAGE_CLUE",
  "PURCHASE_TREASURE_VIEWS_ADVANTAGE_CLUE",
  "RANGE_FINDER_BUY_OPTIONS",
  "TREASURE_VIEWS_BUY_OPTIONS",
  "RESET_ADVANTAGE_CLUES_DATA",
  "ADVANTAGE_CLUES_COUNT"
);

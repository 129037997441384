import { Dispatch } from "redux";
import {
  AddRegionProps,
  UpdateRegionProps,
  getRegion as getRegionApi,
  getRegionList as getRegionListApi,
  getUsersSavedRegionList as getUsersSavedRegionListApi,
  saveRegion as saveRegionApi,
  updateSavedRegion as updateSavedRegionApi,
} from "src/api/regions";

import Types from "../types/regions";

export const getRegionList = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_REGION_LIST_STARTED,
    });

    const resp = await getRegionListApi();
    if (resp?.status) {
      dispatch({
        type: Types.FETCH_REGION_LIST_SUCCESS,
        payload: { data: resp.data.regions },
      });
    } else {
      dispatch({
        type: Types.FETCH_REGION_LIST_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getUsersSavedRegionList = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.FETCH_USERS_SAVED_REGION_LIST_STARTED,
    });

    const resp = await getUsersSavedRegionListApi();
    if (resp?.status) {
      dispatch({
        type: Types.FETCH_USERS_SAVED_REGION_LIST_SUCCESS,
        payload: { data: resp.data.regions },
      });
    } else {
      dispatch({
        type: Types.FETCH_USERS_SAVED_REGION_LIST_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const saveRegion = (data: AddRegionProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.POST_SELECTED_REGION_STARTED,
    });

    const resp = await saveRegionApi(data);

    if (resp?.status) {
      dispatch({
        type: Types.POST_SELECTED_REGION_SUCCESS,
        payload: { data: resp.data },
      });
    } else {
      dispatch({
        type: Types.POST_SELECTED_REGION_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const updateSavedRegion = (data: UpdateRegionProps) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_SELECTED_REGION_STARTED,
    });

    const resp = await updateSavedRegionApi(data);

    if (resp?.status) {
      dispatch({
        type: Types.UPDATE_SELECTED_REGION_SUCCESS,
        payload: { data: resp.data },
      });
    } else {
      dispatch({
        type: Types.UPDATE_SELECTED_REGION_FAILED,
        payload: {
          message: resp?.message,
        },
      });
    }
    return resp;
  };
};

export const getRegion = (id: string, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.FETCH_REGION,
    asyncCall: () => {
      return getRegionApi(id);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

import { useEffect } from "react";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import { isLoggedIn } from "src/utils/utils";
import GameInfo from "./Components/GameInfo";
import HeroSection from "./Components/HeroSection";
import SubscribeCTA from "./Components/SubscribeCTA";
import classes from "./styles.module.scss";

const PreviewPage = () => {
  const isLogIn = isLoggedIn();
  const redirectSignupUrl = isLogIn ? "/subscriptions" : "/auth/access-info";

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.preview);
  }, []);

  return (
    <div className={classes.previewPageWrapper}>
      <HeroSection redirectSignupUrl={redirectSignupUrl} />
      <GameInfo />
      <SubscribeCTA redirectSignupUrl={redirectSignupUrl} />
    </div>
  );
};

export default PreviewPage;

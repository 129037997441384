import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import { Spinner } from "reactstrap";
import MediaBlock from "src/pages/Watch/components/MediaBlock";
import { MediaInfo } from "src/types/media";
import { WatchMedia } from "src/types/watch";
import { getBasicMediaInfo } from "src/utils/media";
import HeaderText from "../HeaderText";
import InfoCardWrapper from "../InfoCardWrapper";
import { useIndexData } from "./selectorData";
import classes from "./styles.module.scss";

const Watch = () => {
  const sliderRef = useRef<Slider | null>(null);
  const navigate = useNavigate();
  const [state, setState] = useState(0);
  const { dashboardWatch, dashboardWatchLoading } = useIndexData();
  const treasureGames = dashboardWatch["treasure-games"] || [];
  const trending = dashboardWatch.trending || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalData, setModalData] = useState<WatchMedia | null>(null);
  const SLIDES_TO_SHOW = 1.7;
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: 1,
    afterChange: (current: number) => {
      setState(current);
    },
  };
  const WATCH_DATA = [
    {
      id: 1,
      title: "Treasure Game$ Content",
      actionButton: {
        text: "Go to Channel",
        style: { textDecoration: "underline" },
        action: () => {
          navigate("/watch/treasure-games");
        },
      },
      details: treasureGames,
      parentPath: "/watch/treasure-games",
    },
    {
      id: 2,
      title: "Trending",
      actionButton: {
        text: "See More",
        style: {},
        action: () => {
          navigate("/watch/trending");
        },
      },
      details: trending,
      parentPath: "/watch/trending",
    },
  ];
  const isLast = state + SLIDES_TO_SHOW === WATCH_DATA.length;
  const isFirst = state === 0;

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <HeaderText section="watch" text="Watch" />
        {dashboardWatchLoading && (
          <Spinner className={classes.loading} color="light" />
        )}
        <div className={classes.sliderActions}>
          <div
            onClick={() => sliderRef.current?.slickPrev()}
            className={cx(classes.iconWrapper, {
              [classes.disableFirst]: isFirst,
            })}
          >
            <FontAwesomeIcon size="lg" color="#fff" icon={faChevronLeft} />
          </div>
          <div
            onClick={() => sliderRef.current?.slickNext()}
            className={cx(classes.iconWrapper, {
              [classes.disableLast]: isLast,
            })}
          >
            <FontAwesomeIcon size="lg" color="#fff" icon={faChevronRight} />
          </div>
        </div>
      </div>
      <div className={classes.sliderWrapper}>
        <Slider className={classes.slider} ref={sliderRef} {...settings}>
          {WATCH_DATA?.map((data, i) => (
            <InfoCardWrapper
              title={data.title}
              actionButton={data.actionButton}
              key={i}
            >
              {data.details.map((mediaData: MediaInfo) => {
                const media = {
                  ...getBasicMediaInfo(mediaData),
                  onClick: () => {
                    navigate(
                      `${data.parentPath}/playing-media/${mediaData.id}`
                    );
                  },
                };
                return (
                  <MediaBlock
                    {...media}
                    className={classes.mediaBlock}
                    mediaClassName={classes.media}
                    videoClassName={classes.video}
                    hideShareAndViews
                    noRatio
                    key={mediaData.id}
                  />
                );
              })}
            </InfoCardWrapper>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Watch;

import { useLocation } from "react-router-dom";
import { WATCH_TREASURE_GAME_URL1 } from "src/pages/Watch/constants";
import useCurrentPath from "./useCurrentPath";

const SPLIT_SLASH: Record<string, number> = {
  "/watch/my-channel/manage-content": 1,
  "/watch/my-channel/followers": 1,
  "/watch/my-channel/followers/:name/:userId": 2,
  "/watch/my-channel/manage-content/post-details/:postId": 2,
  "/watch/:channelSlug/playing-media/:id": 2,
  "/watch/:channelSlug/playing-media/:id/:name/:userId": 2,
  "/watch/:channelSlug/playing-media/:id/:name/:userId/followers": 1,
  "/watch/:channelSlug/:subChannelSlug/:subChannelTitle": 2,
  "/watch/:channelSlug/:subChannelSlug/:subChannelTitle/playing-media/:id": 2,
  "/watch/:channelSlug/:subChannelSlug/:subChannelTitle/playing-media/:id/:name/:userId": 2,
  "/engagement/playing-media/:id/:name/:userId": 2,
  "/engagement/playing-media/:id/:name/:userId/followers": 1,
  "/announcements/playing-media/:id/:name/:userId": 2,
  "/announcements/playing-media/:id/:name/:userId/followers": 1,
  "/prizes/:channelSlug/playing-media/:id/:name/:userId": 2,
  "/prizes/:channelSlug/playing-media/:id/:name/:userId/followers": 1,
  "/prizes/:channelSlug/playing-media/:id": 2,
  "/watch/user-profile/:username/followers": 1,
};

const useBackUrl = (defaultUrl: string) => {
  const currentPath = useCurrentPath();
  const { pathname } = useLocation();
  const pathnameArr = pathname.split("/").filter((v) => !!v);
  const splitNumber = SPLIT_SLASH[String(currentPath)];
  if (String(pathname).includes("/watch/treasure-games/playing-media/")) {
    return WATCH_TREASURE_GAME_URL1;
  }
  if (splitNumber) {
    const returnUrl = pathnameArr.slice(0, -splitNumber).join("/");
    return returnUrl ? `/${returnUrl}` : defaultUrl;
  }
  return defaultUrl;
};

export default useBackUrl;

import cn from "classnames";
import React from "react";
import { ReactComponent as LampLogo } from "src/assets/icons/lamp-outlined-icon.svg";
import classes from "./SeasonBlock.module.scss";

interface Props {
  children: React.ReactNode;
}

const Lamp = ({ children }: Props) => {
  return (
    <span className={cn(classes.lamp)}>
      <span>
        <span>{children}</span>
        <span>LAMP FOUND</span>
      </span>
      <LampLogo />
    </span>
  );
};

export default Lamp;

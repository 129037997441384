import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import isString from "lodash/isString";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, FormGroup } from "reactstrap";
import * as Yup from "yup";
import { ReactComponent as UpIcon } from "src/assets/icons/left-arrow-icon.svg";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import Typography from "src/components/Typography";
import { BE_DATE_FORMAT } from "src/helpers/constants";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import {
  getUserProfile,
  setUserSubscriptionFlow,
  updateUserProfile,
} from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { getMomentFormat } from "src/utils/date";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import AgreementTermContent from "./AgreementTermContent";
import classes from "./styles.module.scss";
import { TermsProps } from "./types";

const TermsAndCondition = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isFullAccess = searchParams.get("full_access") === "true";
  const signupDetail = useReducerData("auth", "signupDetails", {});
  const isRisk = pathname === "/auth/risk-waiver";
  const [showIndicator, setShowIndicator] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [errors, setErrors] = useState("");
  const { loading } = useReducerData("auth", "register", {});
  const { loading: editProfileLoader } = useReducerData(
    "auth",
    "editUserProfile",
    false
  );
  const actions = useStoreActions({
    setUserSubscriptionFlow,
    updateUserProfile,
    getUserProfile,
  });

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.t_and_c_signup);
  }, []);

  const signUpSchema = Yup.object().shape({
    privacyPolicy: Yup.boolean().oneOf(
      [true],
      "Please accept the participation agreement"
    ),
    acceptedTerms: Yup.boolean().oneOf(
      [true],
      "Please accept terms and conditions"
    ),
  });
  const signUpRishSchema = Yup.object().shape({
    releaseAssumption: Yup.boolean().oneOf(
      [true],
      "Please accept release and assumption"
    ),
  });

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const {
      scrollHeight = 0,
      scrollTop = 0,
      clientHeight = 0,
    } = e.currentTarget;
    const scrollBottom = scrollHeight - scrollTop < clientHeight + 16;
    if (scrollBottom) {
      setIsActive(true);
    }
    if (scrollTop > 0) {
      setShowIndicator(false);
    }
  };

  function objectToFormData(obj: Record<string, any>): FormData {
    const formData = new FormData();

    for (const key in obj) {
      formData.append(key, obj[key]);
    }

    return formData;
  }
  const handleSuccess = (resp: boolean) => {
    if (resp) {
      actions.getUserProfile();
    }
  };
  const handleSubmit = async (
    values: TermsProps,
    formikHelpers: FormikHelpers<TermsProps>
  ) => {
    if (!isRisk) {
      navigate("/auth/risk-waiver");
      if (!values.releaseAssumption) {
        const myDiv: any = document.getElementById("term-condition-text");
        if (myDiv) {
          myDiv.scrollTop = 0;
        }
        setIsActive(false);
        setShowIndicator(true);
      }
    } else {
      const profileData = {
        first_name: signupDetail.first_name,
        last_name: signupDetail.last_name,
        gender: signupDetail.gender,
        phone: signupDetail.phone,
        rules_accepted: 1,
        tnc_accepted: 1,
        birth_date: getMomentFormat(signupDetail.birth_date, BE_DATE_FORMAT),
      };

      const formData = objectToFormData(profileData);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const profileRes: any = await actions.updateUserProfile(
        formData,
        handleSuccess
      );

      const { data, status, message } = profileRes || {};
      const isFromValidDevice = message !== "Device not registered.";

      if (!isFromValidDevice) {
        toast.error("Device not registered");
        return;
      }
      if (status) {
        // actions.setUserSubscriptionFlow(true);
        navigate(`/auth/payment${isFullAccess ? "?full_access=true" : ""}`);
        formikHelpers.resetForm();
      } else {
        if (data) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { error } = data;
          const errorMessage = Object.values(error || {}).flatMap((b) => b);
          if (errorMessage[0]) {
            setErrors(errorMessage[0] as string);
          } else if (typeof data?.message === "object") {
            const tErrorMessage = Object.values(data.message || {}).flatMap(
              (b) => b
            );
            if (tErrorMessage?.[0]) {
              setErrors(String(tErrorMessage[0]));
            }
          }
        } else {
          isString(message) && setErrors(message);
        }
      }
    }
  };

  return (
    <Container className={classes.wrapper}>
      <div className={classes.inputs}>
        <Formik
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={isRisk ? signUpRishSchema : signUpSchema}
          initialValues={{
            privacyPolicy: false,
            acceptedTerms: false,
            releaseAssumption: false,
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
          }: FormikProps<TermsProps>) => {
            return (
              <Form className={cx(classes.checkboxWrapper)}>
                <div>
                  <div
                    className={classes.content}
                    onScroll={handleScroll}
                    id="term-condition-text"
                  >
                    {showIndicator && (
                      <div className={classes.scrollIndicator}>
                        <UpIcon />
                        <span>Scroll</span>
                        <UpIcon />
                      </div>
                    )}
                    <AgreementTermContent isRisk={isRisk} />
                    <div className={classes.scrollBottom}></div>
                  </div>
                  {!isRisk ? (
                    <>
                      <FormGroup
                        className={cx("text-start", classes.formGroupCheckBox)}
                      >
                        <CheckBox
                          id="rules"
                          onChange={handleChange}
                          checked={values.privacyPolicy}
                          onBlur={handleBlur}
                          error={errors.privacyPolicy}
                          touched={touched.privacyPolicy}
                          disabled={!isActive}
                          showOutline
                          label={
                            <Typography
                              className={cx(
                                !isActive && classes.disabledText,
                                "d-flex gap-1 mb-0"
                              )}
                              variant="p16"
                              fontFamily="inter"
                            >
                              By checking this box you acknowledge you have read
                              and accept the participation agreement.
                            </Typography>
                          }
                          name="privacyPolicy"
                        />
                      </FormGroup>
                      <FormGroup
                        className={cx("text-start ", classes.formGroupCheckBox)}
                      >
                        <CheckBox
                          id="terms"
                          onChange={handleChange}
                          checked={values.acceptedTerms}
                          onBlur={handleBlur}
                          error={errors.acceptedTerms}
                          touched={touched.acceptedTerms}
                          disabled={values.privacyPolicy ? false : true}
                          showOutline
                          label={
                            <Typography
                              className={cx(
                                !values.privacyPolicy && classes.disabledText,
                                "d-flex gap-1 mb-0"
                              )}
                              variant="p16"
                              fontFamily="inter"
                            >
                              By checking this box you are acknowledging that
                              you have read and agree to the terms and
                              conditions.
                            </Typography>
                          }
                          name="acceptedTerms"
                        />
                      </FormGroup>
                    </>
                  ) : (
                    <FormGroup
                      className={cx("text-start ", classes.formGroupCheckBox)}
                    >
                      <CheckBox
                        id="release"
                        onChange={handleChange}
                        checked={values.releaseAssumption}
                        onBlur={handleBlur}
                        error={errors.releaseAssumption}
                        touched={touched.releaseAssumption}
                        disabled={!isActive}
                        showOutline
                        label={
                          <Typography
                            className={cx(
                              !isActive && classes.disabledText,
                              "d-flex gap-1 mb-0"
                            )}
                            variant="p16"
                            fontFamily="inter"
                          >
                            By checking this box you acknowledge that you have
                            read the Treasure Games Release & Assumption of Risk
                            and agree to its terms.
                          </Typography>
                        }
                        name="releaseAssumption"
                      />
                    </FormGroup>
                  )}
                </div>

                <div className={classes.buttonWrapper}>
                  <Button
                    buttonText="Next"
                    buttonColor="purple-gradient"
                    disabled={
                      !isValid ||
                      !dirty ||
                      (isRisk && !values.releaseAssumption) ||
                      editProfileLoader
                    }
                    type="submit"
                    loading={loading || editProfileLoader}
                    buttonClassName={classes.button}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
        {errors && (
          <div>
            <Typography className={classes.error}>{errors}</Typography>
          </div>
        )}
      </div>
      {isFullAccess && (
        <div className={classes.footerText}>
          Want to try it out first?
          <span
            onClick={() => {
              navigate("/play?free_trial_signup_success=true");
            }}
          >
            Try free trial
          </span>
        </div>
      )}
    </Container>
  );
};

export default TermsAndCondition;

import React, { useEffect } from "react";
import * as watchActions from "src/store/actions/watch";
import { useStoreActions } from "src/store/hooks";
import { NormalTab } from "src/types";
import classes from "./Watch.module.scss";
import ChannelInfo from "./components/ChannelInfo";
import Followers from "./components/Followers";
import { useUserDetailsData } from "./selectorData";
interface Props {
  tab?: string;
  tabs?: NormalTab[];
  onTabChanged?: (tTab: string) => void;
  isMyChannel?: boolean;
}

const useActions = () => {
  return useStoreActions({
    ...watchActions,
  });
};

const UserFollowers = ({ tab, onTabChanged, tabs, isMyChannel }: Props) => {
  const { watchUser } = useUserDetailsData();
  const { getChannelFollowers } = useActions();
  useEffect(() => {
    watchUser?.id && getChannelFollowers(watchUser?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchUser?.id]);

  return (
    <div className={classes.userFollowerWrapper}>
      <ChannelInfo
        isMyChannel={isMyChannel}
        userProfile={watchUser}
        tabs={tabs}
        tab={tab}
        onTabChanged={onTabChanged}
      />
      <Followers />
    </div>
  );
};

export default UserFollowers;

import * as mediaActions from "src/store/actions/media";
import * as watchActions from "src/store/actions/watch";
import { useReducerData, useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {
    userFeed: useReducerData("watch", "watchUserFeed.data", {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...watchActions,
    ...mediaActions,
  });
};

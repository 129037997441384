import cx from "classnames";
import sortBy from "lodash/sortBy";
import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { ReactComponent as LampIcon } from "src/assets/icons/lamp-outlined-icon.svg";
import Typography from "src/components/Typography";
import { RegionListProps } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import LampLogo from "src/pages/PreviewPage/assets/images/wish-lamp-logo.png";
import { useReducerData } from "src/store/hooks";
import { getRegionInfo } from "src/utils/region";
import { useRegionData } from "src/utils/useData";
import classes from "./styles.module.scss";

const getValueText = (states = ["Idaho", "Montana", "Wyoming"]) => {
  const STATES_TO_SHOW = 3;
  const leftStates = states.length - STATES_TO_SHOW;
  const valueText = states.map((state) => state);

  const combinedStr = valueText
    .slice(0, STATES_TO_SHOW)
    .map((i: string) => i || " ")
    .toString()
    .replace(/,/g, ", ");
  return `${combinedStr}${leftStates > 0 ? `...+${leftStates} more` : ""}`;
};

const Header = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const regionId = searchParams.get("region") || "1";
  const season = searchParams.get("season") || "1";

  const {
    data: regions,
    loading,
  }: { data: RegionListProps[]; loading: boolean } = useReducerData(
    "region",
    "regionList",
    {
      data: [],
      loading: false,
    }
  );

  const { region } = useRegionData();
  const { seasons = [], id, states = [] } = region.data;
  const activeSeasons = seasons.filter((s) => !!s?.lamp);

  const selectedRegion = regions.find(
    ({ slug }) => slug === `region-${regionId}`
  );
  const { totalLamp, foundLamp } = getRegionInfo(selectedRegion);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.mainSection}>
        <div className={classes.dropdownWrapper}>
          <div className={classes.content}>
            <Typography className="mb-0" fontWeight="bold" variant="p20">
              WL$ Region {id}
            </Typography>
            <div className={classes.statesList}>
              <span>{getValueText(states)}</span>
            </div>
          </div>
          {totalLamp === foundLamp ? (
            <Typography
              className={classes.lampFoundText}
              fontWeight="bold"
              variant="p20"
            >
              All Lamps Found
            </Typography>
          ) : (
            <div className={classes.lampFound}>
              <div className={classes.lamps}>
                {Array(totalLamp)
                  .fill(totalLamp)
                  .map((_, index) => {
                    return (
                      <LampIcon
                        width={30}
                        height={20}
                        key={index}
                        stroke="#FFFFFF"
                        {...(index + 1 <= foundLamp ? { fill: "#5AA332" } : {})}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <div className={classes.headerContent}>
          <div className={classes.seasons}>
            <Typography className="mb-0" variant="p16" fontFamily="inter">
              Season:
            </Typography>
            <div className={classes.season}>
              {sortBy(activeSeasons, ["id"])?.map((seasonData, index) => {
                return (
                  <div
                    onClick={() => {
                      navigate(
                        `/play/clues?region=${regionId}&season=${seasonData?.id}`
                      );
                    }}
                    className={cx(classes.badge, {
                      [classes.active]: seasonData?.id === Number(season),
                      [classes.found]: selectedRegion?.lamp_count === 0,
                    })}
                    key={index}
                  >
                    {seasonData.id}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.imageWrapper}>
        <img src={LampLogo} alt="lamp" />
      </div>
    </div>
  );
};

export default Header;

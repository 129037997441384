import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { useState } from "react";
import { ReactComponent as EyeIcon } from "src/assets/icons/eye-icon.svg";
import { ReactComponent as ShareIcon } from "src/assets/icons/share-icon.svg";
import dummyImage from "src/assets/images/placeholder-image.png";
import Modal from "src/components/Modal";
import { PostTag, PostTagWrapper } from "src/components/Tag";
import Text from "src/components/Text";
import Description from "src/pages/Watch/components/Description";
import PreviewVideo from "src/pages/Watch/components/PreviewVideo";
import ShareModal from "src/pages/Watch/components/ShareModal";
import SocialButton from "src/pages/Watch/components/SocialButton";
import SocialLove from "src/pages/Watch/components/SocialLove";
import { MediaInfo } from "src/types/media";
import { getPostDate } from "src/utils/date";
import { getIsImage, getIsText, getMediaTags } from "src/utils/media";
import { numberFormatter } from "src/utils/number";
import classes from "./NewsModal.module.scss";

type Props = {
  isOpen: boolean;
  post: MediaInfo;
  onClose: () => void;
  onLoveChanged: (val: boolean) => void;
};

const NewsModal: React.FC<Props> = ({
  post,
  isOpen,
  onClose,
  onLoveChanged,
}) => {
  const [isShareModal, setIsShareModal] = useState<boolean>(false);
  const tags = getMediaTags(post?.tags || []);
  const followsCount = post.author?.follows_count || 0;
  const isText = getIsText(post.media_type);
  const isImage = getIsImage(post.media_type) || isText;
  let source = post[isImage ? "images" : "video"]?.[0]?.url || "";
  if (isText) {
    source = post.orignal_background || "";
  }

  return (
    <>
      <Modal
        className={classes.newsModalWrapper}
        bodyClassName={classes.modalBody}
        isOpen={isOpen}
        toggle={onClose}
      >
        <div className={classes.close}>
          <FontAwesomeIcon
            onClick={onClose}
            size="2x"
            icon={faXmark}
            color="white"
          />
        </div>
        <div className={classes.content}>
          <div
            className={cn(classes.media, {
              [classes.isImage]: isImage,
            })}
          >
            {isImage ? (
              isText ? (
                <div
                  className={cn("image-placeholder", {
                    "not-source": !source,
                  })}
                >
                  {!!source && <img src={source} alt="thumbnail" />}
                  <span
                    style={{
                      color: post?.background_image_text_color || "white",
                    }}
                  >
                    {post?.desc}
                  </span>
                </div>
              ) : (
                <img src={source} alt="thumbnail" />
              )
            ) : (
              <PreviewVideo
                source={post?.video?.[0].url as string}
                videoClassName={classes.video}
                defaultPlaying
                emdOverlay
              />
            )}
          </div>
          <div className={classes.info}>
            <div className={classes.leftSide}>
              <span className={classes.title}>
                {post?.title || "Video Title"}
              </span>
              <div className={classes.middle}>
                <div>
                  {post?.author?.avatar ? (
                    <img src={post.author.avatar} alt="profile-pic" />
                  ) : (
                    <img src={dummyImage} alt="dummy-pic" />
                  )}
                  <span>
                    <span>{post?.author?.name || "Author Name"}</span>
                    <span>
                      {numberFormatter(followsCount || 0, 1)} Follower
                      {followsCount !== 1 && "s"}
                    </span>
                  </span>
                </div>
              </div>
              <div className={classes.bottom}>
                <Description content={post?.desc || ""} />
                {tags.length > 0 && (
                  <PostTagWrapper>
                    {tags.map((tag: string, index: number) => {
                      return <PostTag key={index}>{tag}</PostTag>;
                    })}
                  </PostTagWrapper>
                )}
              </div>
            </div>
            <div className={classes.rightSide}>
              <div className={classes.postedAt}>
                <span>posted: {getPostDate(post?.posted_date)}</span>
              </div>
              <div className={classes.socialButtons}>
                <SocialLove
                  isLoved={!!post?.has_liked}
                  setIsLoved={onLoveChanged}
                  likeCount={post?.likes_count}
                  isFree
                />
                {/* <div className={classes.likeCount}>
                  <FontAwesomeIcon
                    onClick={onClose}
                    size="lg"
                    icon={faHeart}
                    color="white"
                  />
                  <Text fontFamily="inter" size={18} color="white">
                    {numberFormatter(post?.likes_count || 0, 1)}
                  </Text>
                </div> */}
                <div className={classes.likeCount}>
                  <EyeIcon />
                  <Text fontFamily="inter" size={18} color="white">
                    {numberFormatter(post?.views_count || 0, 1)}
                  </Text>
                </div>
                <SocialButton
                  icon={<ShareIcon />}
                  title="Share"
                  onClick={() => {
                    setIsShareModal(true);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ShareModal
        isOpen={isShareModal}
        onClose={() => {
          setIsShareModal(false);
        }}
        shareLink={`${origin}/public/post?id=${post?.id}&free=1`}
        isFree
      />
    </>
  );
};

export default NewsModal;

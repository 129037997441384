import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { ReactComponent as VideoPlusIcon } from "src/assets/icons/video-plus-icon.svg";
import Button from "src/components/Button";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import { useIsUserProfileFreeTrial } from "src/helpers/hooks/useUserProfile";
import BreadCrumbs from "src/pages/Play/BaseComponents/BreadCrumbs";
import Profile from "../Profile";
import SearchBox from "../SearchBox";
import classes from "./Header.module.scss";

interface Props {
  isRoot?: boolean;
  onProfileClick?: () => void;
  onBack?: () => void;
  onManageContent?: () => void;
  onUpload?: () => void;
  hideProfile?: boolean;
  hideSearch?: boolean;
  showManageContent?: boolean;
  showUpload?: boolean;
  isMainPage?: boolean;
  onSearchChanged?: (val: string) => void;
  avatarSrc?: string;
  className?: string;
  defaultSearchActive?: boolean;
  defaultSearchKeyword?: string;
  searchLocal?: boolean;
}

const WatchHeader = ({
  isRoot = false,
  onProfileClick = () => {},
  onBack,
  hideProfile,
  showManageContent,
  onManageContent,
  onUpload,
  showUpload,
  isMainPage = false,
  onSearchChanged,
  avatarSrc,
  hideSearch,
  className,
  defaultSearchActive,
  defaultSearchKeyword,
  searchLocal,
}: Props) => {
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const pathArr = currentPath.split("/");
  const isPlayingMedia =
    pathArr[pathArr.length - 1] === ":id" &&
    pathArr[pathArr.length - 2] === "playing-media";

  const isFreeTrial = useIsUserProfileFreeTrial();

  return (
    <div className={cn(classes.wrapper, className)}>
      {isMainPage ? (
        <div></div>
      ) : (
        <BreadCrumbs
          isRoot={isRoot}
          noMargin
          buttonText=""
          gapSize="xs"
          onBack={onBack}
          isUseCurrentPath
        />
      )}
      <div
        className={cn(classes.right, {
          [classes.showManageContent]: showManageContent || showUpload,
        })}
      >
        {!hideSearch && !isPlayingMedia && (
          <SearchBox
            onSearchChanged={(val) => {
              if (searchLocal) {
                if (onSearchChanged) onSearchChanged(val);
              } else {
                if (val) {
                  // navigate to search page
                  navigate(`/search?q=${val}`);
                }
              }
            }}
            defaultActive={defaultSearchActive}
            defaultKeyword={defaultSearchKeyword}
          />
        )}
        {!hideProfile && (
          <Profile onClick={onProfileClick} avatarSrc={avatarSrc} />
        )}
        <>
          {showManageContent && !isFreeTrial && (
            <Button
              buttonText="Manage Content"
              buttonColor="gray-light"
              radius="lg"
              size="sm"
              onClick={onManageContent}
            />
          )}
          {showUpload && !isFreeTrial && (
            <Button
              buttonText="Upload"
              buttonColor="white"
              radius="lg"
              size="sm"
              leftIcon={<VideoPlusIcon />}
              gapSize="xs"
              flex
              onClick={onUpload}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default WatchHeader;

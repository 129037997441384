import { ClueProps, ClueStatus } from "./types";

export const getCurrentClue = (clues: ClueProps[]) => {
  return (
    clues.find((clue) => clue.is_current) ||
    clues.find((clue) => clue.answer_status !== "locked")
  );
};

export const getClueStatus = (status: ClueStatus) => {
  if (status === "not-viewed") {
    return "viewed";
  } else {
    return status;
  }
};

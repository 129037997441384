import get from "lodash/get";
import { MediaInfo } from "src/types/media";

export const getIsImage = (mediaType?: number | string) => {
  return mediaType === 1 || mediaType === "image";
};
export const getIsVideo = (mediaType?: number | string) => {
  return mediaType === 2 || mediaType === "video";
};
export const getIsText = (mediaType?: number | string) => {
  return mediaType === "text" || mediaType === "";
};
export const getIsAdminMedia = (info?: MediaInfo) => {
  return info?.is_admin_post === 1;
};
export const getBasicMediaInfo = (post: MediaInfo) => {
  const isImage = getIsImage(post.media_type) || getIsText(post.media_type);
  let sourceSrc: string = post?.[isImage ? "images" : "video"]?.[0]?.url || "";
  if (getIsText(post.media_type)) {
    sourceSrc = post.orignal_background || "";
  }
  return {
    type: post.media_type,
    source: sourceSrc,
    info: post,
    thumbnail: get(post, "images[0].url"),
  };
};
export const getMediaTags = (tags: string[]) => {
  return tags.filter((v) => !!v).map((v) => `${v[0] === "$" ? "" : "$"}${v}`);
};

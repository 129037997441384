import { Request } from "./request";

export type LoginUser = {
  email: string;
  password: string;
  device_name?: string;
  device_token?: string;
  fp_request_id?: string;
};

export type User = {
  email_confirmation: string;
  password_confirmation: string;
  username: string;
  first_name: string;
  last_name: string;
  phone: string;
  birth_date: string;
  gender: string;
  tnc_accepted: number;
  rules_accepted: number;
  avatar_path: string;
} & LoginUser;

export type ForgotPasswordProps = {
  email: string;
};

export type ResetPasswordProps = {
  password: string;
  password_confirmation: string;
  email?: string;
  token?: string;
  device_name: string;
  device_token: string;
  fp_request_id: string;
};

export type PaymentDetailsProps = {
  payment_id: string | null;
  plan_id: number | null;
  payment_type?: string;
};

export type UserParams = {
  field: string;
  value: string;
};

export type AddPaymentProps = {
  payment_method_id: string;
  is_default: boolean;
};

export type UserProfileUpdateProps = {
  username: string;
  first_name: string;
  last_name: string;
  phone: string;
  birth_date: string;
  gender: string;
  avatar: File;
};

export type PaymentMethodsProps = {
  id: number;
  user_id: number;
  is_default: number;
  country: string;
  last4: number;
  brand: string;
  created_at: string;
  updated_at: string;
  stripe_method_id: string;
};

export type NotificationPreferences = {
  new_clue: boolean;
  promotions: boolean;
  treasure_found: boolean;
  new_announcement: boolean;
};

export type UpdateUserProfileProps = {
  notification_preferences: NotificationPreferences;
  email_preferences: boolean;
  privacy_terms: boolean;
  cookies: boolean;
};

export type UserSettingsData = {
  id: number;
  user_id: number;
  notification_preferences: NotificationPreferences;
  email_preferences: boolean;
  privacy_terms: boolean;
  cookies: boolean;
  created_at: string;
  updated_at: string;
};

export type SSOSignUpData = {
  access_token: string;
  sso_type: "facebook" | "google" | "apple";
  username?: string;
  device_name: string;
  device_token: string;
};

export const postSignup = async (data: User) => {
  return Request.call({
    url: "/api/register",
    method: "POST",
    data,
  });
};

export const postPaymentDetails = async (data: PaymentDetailsProps) => {
  return Request.call({
    url: "/api/subscription",
    method: "POST",
    data,
  });
};

export const postLogin = async (data: LoginUser) => {
  return Request.call({
    url: "/api/login",
    method: "POST",
    data,
  });
};

export const forgotPassword = async (data: ForgotPasswordProps) => {
  return Request.call({
    url: "/api/forgot-password",
    method: "POST",
    data,
  });
};

export const resetPassword = async (data: ResetPasswordProps) => {
  return Request.call({
    url: "/api/reset-password",
    method: "POST",
    data,
  });
};

export const postUserExistQuery = async (data: UserParams) => {
  return Request.call({
    url: "/api/check-user-exist",
    method: "POST",
    data,
  });
};

export const getSubscriptionPlans = async () => {
  return Request.call({
    url: "/api/plans",
    method: "GET",
  });
};

export const getSubscriptionDetails = async () => {
  return Request.call({
    url: "/api/subscription",
    method: "GET",
  });
};

export const getPaymentMethods = async () => {
  return Request.call({
    url: "/api/user/payment-methods",
    method: "GET",
  });
};

export const addPaymentMethods = async (data: AddPaymentProps) => {
  return Request.call({
    url: "/api/user/payment-methods",
    method: "POST",
    data,
  });
};

export const getUserProfile = async () => {
  return Request.call({
    url: "/api/profile",
    method: "GET",
  });
};

export const updateUserProfile = async (data: FormData) => {
  //UserProfileUpdateProps
  return Request.call({
    url: "/api/profile",
    method: "POST",
    data,
    headers: {
      contentType: "multipart/form-data",
    },
  });
};

export const getUserSettings = async () => {
  return Request.call({
    url: "/api/user/settings",
    method: "GET",
  });
};

export const updateUserSettings = async (data: UpdateUserProfileProps) => {
  return Request.call({
    url: "/api/user/settings",
    method: "POST",
    data,
  });
};

export const removePaymentMethod = async (id: number) => {
  return Request.call({
    url: `api/user/payment-methods/${id}`,
    method: "DELETE",
  });
};

export const unsubscribePlan = async () => {
  return Request.call({
    url: "/api/subscription/cancel",
    method: "POST",
  });
};

export const apiDeleteAccount = async () => {
  return Request.call({
    url: "/api/watch/users",
    method: "DELETE",
  });
};

export const ssoSignUp = async (data: SSOSignUpData) => {
  return Request.call({
    url: "/api/sso-register",
    method: "POST",
    data,
  });
};

export const getPaymentIntent = async (subscriptionId: string) => {
  return Request.call({
    url: `/api/subscription/${subscriptionId}/payment-intent`,
    method: "GET",
  });
};

export const verifyPayment = async (subscriptionId: string) => {
  return Request.call({
    url: `/api/subscription/${subscriptionId}/verify-payment`,
    method: "GET",
  });
};

export const ssoLogin = async (data: SSOSignUpData) => {
  return Request.call({
    url: "/api/sso-login",
    method: "POST",
    data,
  });
};

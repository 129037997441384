import React from "react";
import Button from "src/components/Button";
import Modal, { ModalBody } from "src/components/Modal";
import Text from "src/components/Text";
import classes from "./SwitchToAppModal.module.scss";
type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const SwitchToAppModal: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal
      backdrop
      className={classes.modal}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      <ModalBody className={classes.body}>
        <Text
          color="#fff"
          size={24}
          fontFamily="inter"
          fontWeight="semibold"
          className="mb-2"
        >
          Unable to update
        </Text>
        <Text
          color="#fff"
          size={20}
          fontFamily="inter"
          className="max-w[450px] w-ful"
        >
          Update your subscription settings in the Treasure Games app.
        </Text>
      </ModalBody>
      <Button
        buttonText="OK"
        buttonColor="secondary"
        buttonClassName={classes.btn}
        onClick={() => {
          onClose();
        }}
      />
    </Modal>
  );
};

export default SwitchToAppModal;

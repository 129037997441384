import cn from "classnames";
import ReactSlider, { ReactSliderProps } from "react-slider";
import classes from "./Slider.module.scss";

type Props = ReactSliderProps & {
  className?: string;
  hideThumb?: boolean;
  sliderClassName?: string;
  trackClassName?: string;
  thumbClassName?: string;
  loadedValue?: number;
  loadedMax?: number;
  loadedStep?: number;
  displayLoaded?: boolean;
};

const Slider = ({
  className,
  hideThumb,
  sliderClassName,
  thumbClassName,
  trackClassName,
  loadedValue,
  loadedMax,
  loadedStep,
  displayLoaded,
  ...other
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.hideThumb]: hideThumb,
          [classes.displayLoaded]: displayLoaded,
          [classes.isEmpty]: other.value === 0,
        },
        className
      )}
    >
      <ReactSlider
        className={cn(classes.slider, sliderClassName)}
        thumbClassName={cn(classes.thumb, thumbClassName)}
        trackClassName={cn(classes.track, trackClassName)}
        {...other}
      />
      {displayLoaded && (
        <ReactSlider
          className={cn(classes.slider, sliderClassName, classes.loadedSlider)}
          thumbClassName={cn(classes.thumb, thumbClassName)}
          trackClassName={cn(classes.track, trackClassName)}
          value={loadedValue}
          max={loadedMax}
          step={loadedStep}
          min={0}
        />
      )}
    </div>
  );
};

export default Slider;

import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Input from "src/components/Input";
import { IS_TESTING } from "src/helpers/constants";
import {
  addSignupDetails,
  doSignUp,
  postUserExistQuery,
  ssoSignUp,
} from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { userNameRegex } from "src/utils/regex";
import { getUserAgentData } from "src/utils/utils";
import classes from "./styles.module.scss";
import { CreateUsernameInputProps } from "./types";

const mockDeviceData = {
  deviceToken: "fKyraHf68WJyEttKkpKb",
  fpRequestId: "1699334038264.O8kjWp",
};

const CreateUsername = () => {
  const [usernameExistError, setUsernameExistError] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referralCode = localStorage.getItem("referralCode");
  const isFreeTrial = searchParams.get("free_trial") === "true";
  const signupDetail = useReducerData("auth", "signupDetails", {});
  const ssoData = useReducerData("auth", "ssoData", {});
  const actions = useStoreActions({
    addSignupDetails,
    postUserExistQuery,
    doSignUp,
    ssoSignUp,
  });
  const { loading } = useReducerData("auth", "userExistQuery", {
    loading: false,
  });
  const [redirectLoader, setRedirectLoader] = useState(false);
  const [ssoSignUpError, setSsoSignUpError] = useState("");
  const signUpUserNameSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required")
      .matches(userNameRegex, "Invalid username"),
  });

  const userAgent = getUserAgentData();
  const { data: deviceData = null, error: deviceError } = useVisitorData({
    extendedResult: true,
  });

  useEffect(() => {
    if (deviceError) {
      toast.error("Please disable any ad blocker plugin you have installed.");
    }
  }, [deviceError]);

  const handleSubmit = async (
    values: CreateUsernameInputProps,
    formikHelpers: FormikHelpers<CreateUsernameInputProps>
  ) => {
    const usernameCheck = {
      field: "username",
      value: values.username as string,
    };

    const onSignUpSuccess = (response: any) => {
      setRedirectLoader(false);
      if (response.status) {
        navigate(
          isFreeTrial
            ? "/play?free_trial_signup_success=true"
            : "/auth/profile?full_access=true"
        );
        localStorage.removeItem("referralCode");
      } else {
        setSsoSignUpError(response.data.error);
      }
      formikHelpers.resetForm();
    };

    const onUserNameCheckSuccess = (res: any) => {
      if (!res?.status) {
        setUsernameExistError(res.message);
        return;
      }
      if (ssoData.access_token) {
        setRedirectLoader(true);
        actions.ssoSignUp(
          {
            ...ssoData,
            username: values.username,
            referral_code: referralCode,
            device_name: userAgent.deviceName + " - " + userAgent.type,
            device_token: IS_TESTING
              ? mockDeviceData.deviceToken
              : deviceData?.visitorId || "",
          },
          onSignUpSuccess
        );
        return;
      } else {
        setRedirectLoader(true);
        actions.doSignUp(
          {
            ...signupDetail,
            username: values.username,
            referral_code: referralCode,
            device_name: userAgent.deviceName + " - " + userAgent.type,
            device_token: IS_TESTING
              ? mockDeviceData.deviceToken
              : deviceData?.visitorId || "",
          },
          onSignUpSuccess
        );
      }
    };

    actions.postUserExistQuery(usernameCheck, onUserNameCheckSuccess);
  };

  return (
    <div className={classes.container}>
      <Formik
        onSubmit={(values, formikHelpers) =>
          handleSubmit(values, formikHelpers)
        }
        validateOnMount
        validationSchema={signUpUserNameSchema}
        initialValues={{
          username: signupDetail?.username || "",
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
        }: FormikProps<CreateUsernameInputProps>) => {
          return (
            <Form className={cx(classes.signupInputs)}>
              <div className={classes.inputContainer}>
                <FormGroup className={classes.formGroup}>
                  <Input
                    labelClassName={classes.label}
                    inputGroupClassName={classes.inputWrapper}
                    inputClassName={classes.input}
                    label="Unique Username"
                    value={values.username}
                    placeholder="Treasure_Hunter"
                    onChange={(e) => {
                      handleChange(e);
                      setUsernameExistError("");
                    }}
                    onBlur={handleBlur}
                    error={errors.username || usernameExistError}
                    touched={touched.username}
                    name="username"
                    required
                    disabled={!!ssoSignUpError}
                  />
                </FormGroup>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  buttonText={isFreeTrial ? "Create Account" : "Next"}
                  buttonColor="purple-gradient"
                  loading={loading || redirectLoader}
                  type="submit"
                  disabled={!isValid || !!ssoSignUpError}
                />
              </div>
              {ssoSignUpError && (
                <div className={classes.error}>{ssoSignUpError}</div>
              )}
            </Form>
          );
        }}
      </Formik>
      <div className={classes.tAndC}>
        Already have an account?
        <span
          onClick={() => {
            navigate(`/auth/login${isFreeTrial ? "?free_trial=true" : ""}`);
          }}
        >
          Sign in
        </span>
      </div>
    </div>
  );
};

export default CreateUsername;

import cx from "classnames";
import get from "lodash/get";
import React from "react";
import { toast } from "react-toastify";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Text from "src/components/Text";
import { unsubscribePlan } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "../SelectSubscription/SelectSubscription.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}
const UnsubscribeModal: React.FC<Props> = ({ isOpen, onClose, onSuccess }) => {
  const { loading } = useReducerData("auth", "unsubscribePlan", {
    loading: false,
  });
  const actions = useStoreActions({
    unsubscribePlan,
  });
  const handleUnsubscribe = async () => {
    const response = (res: any) => {
      const status = get(res, "status", 0);
      const message = get(res, "message", null);

      if (status) {
        toast.dark(message);
        onClose();
        if (onSuccess) {
          onSuccess();
        }
      } else {
        toast.error("Something went wrong");
        onClose();
        if (onSuccess) {
          onSuccess();
        }
      }
    };

    await actions.unsubscribePlan(response);
  };

  return (
    <Modal
      backdrop
      className={cx(classes.closeModalWrapper)}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      <div className={classes.modalHeader}>
        <Text color="#fff" className="mb-4" size={40} fontFamily="milonga">
          Unsubscribe
        </Text>
        <div className={classes.modalBody}>
          <Text color="#fff" size={20} className="mb-2">
            Are you sure you want to unsubscribe from <b>Full</b> Access? Your
            account will become <b>Free</b> Access at the end of your current
            billing period.
          </Text>
          <Text color="#fff" size={20} className="mb-2">
            By Unsubscribing, you will lose access to any answered clues beyond
            clues #2 - #11.
          </Text>
          <Text color="#fff" size={20}>
            By clicking confirm you agree to this change
          </Text>
        </div>
      </div>
      <div className={classes.actions}>
        <Button
          buttonText={
            <Text color="#fff" fontWeight="bold" fontFamily="impact" size={36}>
              Confirm
            </Text>
          }
          buttonColor="secondary"
          onClick={() => handleUnsubscribe()}
          // disabled={!isValid || !dirty}
          loading={loading}
          type="button"
          buttonClassName={classes.submit}
        />
        <div className={classes.cancelWrapper}>
          <Button
            buttonText={
              <Text color="#fff" fontFamily="impact" size={36}>
                Cancel
              </Text>
            }
            onClick={onClose}
            buttonColor="secondary"
            variant="link"
            buttonClassName={classes.cancel}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UnsubscribeModal;

import isEmpty from "lodash/isEmpty";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Spinner from "src/components/Spinner";
import WarningModal from "src/components/WarningModal";
import {
  NEW_ID,
  POST_STATUS_MAPPING,
  POST_STATUS_VALUES,
} from "src/helpers/constants";
import { actionCb } from "src/utils/action";
import { getUploadedPostDate } from "src/utils/date";
import { getIsImage } from "src/utils/media";
import { getUrlParamValueByKey } from "src/utils/routes";
import classes from "./Watch.module.scss";
import DeletePostWarning from "./components/ManageContentTable/components/DeletePostWarning";
import PostView from "./components/PostDetails/PostView";
import Socials from "./components/PostDetails/Socials";
import UploadContent from "./components/UploadModal/components/UploadContent";
import { useActions, usePostDetailsData } from "./selectorData";
import { getPostMediaBR } from "./utils";

interface Props {
  reloadCurrentManageContentTable?: () => void;
}

const PostDetails = ({ reloadCurrentManageContentTable }: Props) => {
  const inputRef = useRef(null);
  const matchParams = useParams();
  const { search } = useLocation();
  const tAction = getUrlParamValueByKey(search, "action");
  const postId = String(matchParams?.postId || "");
  const navigate = useNavigate();
  const { deletePost, updateMediaPost, syncSetPostDetails, uploadMediaPost } =
    useActions();
  const { postDetailsLoading, postDetails } = usePostDetailsData();
  const isImage = getIsImage(postDetails?.media_type);
  const [updatePostLoading, setUpdatePostLoading] = useState(false);
  const [deletePostLoading, setDeletePostLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(tAction === "edit");
  const [deleteObj, setDeleteObj] = useState({} as any);
  const handleStartEdit = () => {
    setIsEdit(true);
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        (inputRef.current as any).focus();
      }
    }, 100);
  };
  const resetToViewUrl = () => {
    if (tAction) {
      navigate(`/watch/my-channel/manage-content/post-details/${postId}`);
    }
  };
  const handleUpdatePost = (values: any, tStatus?: string) => {
    const tValues = {
      ...values,
      isUploadImage: isImage,
    };
    const br = getPostMediaBR(tValues, tStatus || postDetails?.status);
    const submitFn = () => {
      updateMediaPost(
        postId,
        br,
        actionCb(
          (res: any) => {
            if (reloadCurrentManageContentTable)
              reloadCurrentManageContentTable();
            setIsEdit(false);
            syncSetPostDetails(res.data?.post || {});
            setUpdatePostLoading(false);
            resetToViewUrl();
          },
          () => {
            setUpdatePostLoading(false);
          }
        )
      );
    };
    if (!tValues.isUploadImage && tValues.activeThumbnail?.id === NEW_ID) {
      setUpdatePostLoading(true);
      const tFormData = new FormData();
      tFormData.append("file", tValues.customThumbnail);
      uploadMediaPost(
        postId,
        tFormData,
        "cover",
        actionCb(
          () => {
            submitFn();
          },
          () => {
            setUpdatePostLoading(false);
          },
          false,
          "Custom thubnail failed!"
        )
      );
    } else {
      setUpdatePostLoading(true);
      submitFn();
    }
  };

  useEffect(() => {
    if (tAction === "edit") {
      setTimeout(() => {
        if (inputRef && inputRef.current) {
          (inputRef.current as any).focus();
        }
      }, 100);
    }
  }, [tAction]);

  return (
    <>
      <div className={classes.postDetailsWrapper}>
        {postDetailsLoading && <Spinner wholePage />}
        <h2>Post Details</h2>
        <div className={classes.inner}>
          <h3>
            {`${
              POST_STATUS_MAPPING[postDetails.status] ||
              postDetails.status ||
              ""
            } Post`}
            {!!postDetails.updated_at && (
              <span>
                Last updated on{" "}
                {getUploadedPostDate(postDetails.updated_at, true)}
              </span>
            )}
          </h3>
          {isEdit ? (
            <UploadContent
              onSaveDraft={() => {}}
              onSubmit={(values) => {
                handleUpdatePost(values, POST_STATUS_VALUES.PUBLISHED);
              }}
              onSave={handleUpdatePost}
              mediaUploaded={false}
              mediaVerifying={false}
              mode="edit"
              onDelete={() => {
                setDeleteObj({
                  id: 1,
                });
              }}
              onCancel={() => {
                if (!updatePostLoading) {
                  setIsEdit(false);
                  resetToViewUrl();
                }
              }}
              leftAdditional={
                <Socials
                  className={classes.socials}
                  viewCount={postDetails.views_count}
                  likeCount={postDetails.likes_count}
                  shareCount={postDetails.share_count}
                />
              }
              editObj={{
                ...postDetails,
                src: postDetails.images?.[0]?.url,
              }}
              sourcePreviewTitle={isImage ? "Image" : "Video"}
              isUploadImage={isImage}
              publishLoading={updatePostLoading}
              inputRef={inputRef}
            />
          ) : (
            <PostView
              onDelete={() => {
                setDeleteObj({
                  id: postId,
                });
              }}
              onEdit={() => {
                handleStartEdit();
              }}
              title={isImage ? "Image" : "Video"}
              data={postDetails}
            />
          )}
        </div>
      </div>
      <WarningModal
        isOpen={!isEmpty(deleteObj)}
        onClose={() => {
          setDeleteObj({});
        }}
        onSubmit={() => {
          if (!deletePostLoading) {
            setDeletePostLoading(true);
            deletePost(
              deleteObj.id,
              actionCb(
                () => {
                  setDeletePostLoading(false);
                  navigate("/watch/my-channel/manage-content");
                  setDeleteObj({});
                },
                () => {
                  setDeletePostLoading(false);
                }
              )
            );
          }
        }}
        cancelText="Cancel"
        submitText="Delete Post"
        title="Permanently delete this post?"
        submitButonProps={{
          variant: "link",
          disabled: !deleteObj?.checked,
          loading: deletePostLoading,
          linkHasLoading: true,
        }}
      >
        <DeletePostWarning
          checked={deleteObj?.checked}
          onChange={(checked: boolean) => {
            setDeleteObj({
              ...deleteObj,
              checked,
            });
          }}
          title={postDetails.title}
          createdAt={postDetails.posted_date}
          viewCount={postDetails.views_count}
          imgSrc={postDetails?.images?.[1]?.url}
        />
      </WarningModal>
    </>
  );
};

export default PostDetails;

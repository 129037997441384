import cn from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Text from "src/components/Text";
import classes from "./MenuDropdown.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  signinUrl: string;
  signupUrl: string;
}

const MenuDropdown = ({ children, className, signinUrl, signupUrl }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown
      className={cn(classes.dropdown, className)}
      persist={true}
      active
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle onClick={toggle} className={classes.actionButton}>
        {children}
      </DropdownToggle>
      <DropdownMenu className={classes.menu} end>
        <DropdownItem
          onClick={() => navigate(signinUrl)}
          className={classes.item}
        >
          <Text size={20} color="#fff">
            Sign In
          </Text>
        </DropdownItem>
        <DropdownItem
          onClick={() => navigate(signupUrl)}
          className={classes.item}
        >
          <Text size={20} color="#fff">
            Sign Up
          </Text>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default MenuDropdown;

/* eslint-disable react/no-multi-comp */
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import invert from "lodash/invert";
import moment from "moment";
import React, { Dispatch, SetStateAction, useState } from "react";
import DatePicker from "react-datepicker";
import { useSearchParams } from "react-router-dom";
import { Collapse, UncontrolledPopover } from "reactstrap";
import { ReactComponent as CalenderIcon } from "src/assets/icons/calender-icon.svg";
import Button from "src/components/Button";
import Card from "src/components/Card";
import CheckBox from "src/components/CheckBox";
import Typography from "src/components/Typography";
import { DEFAULT_DATE_FORMAT } from "src/helpers/constants";
import { getQuestionIcon } from "src/pages/Play/Clue/getIcon";
import { useReducerData } from "src/store/hooks";
import { useClueActions } from "src/utils/useActions";
import classes from "./styles.module.scss";

type TypeProps =
  | "image"
  | "video"
  | "audio"
  | "riddle"
  | "word_search"
  | "puzzle";

type TypesProps = {
  [key in TypeProps]: boolean;
};
type TypesPropsValue = {
  [key in number]: string;
};

export type FilterProps = {
  type: TypesProps;
  dateRange: [Date | null, Date | null];
};

const typeValue: TypesPropsValue = {
  1: "puzzle",
  7: "image",
  27: "video",
  5: "audio",
  2: "riddle",
  8: "word_search",
};

const dateRangeButtons: { title: string; date: [Date | null, Date | null] }[] =
  [
    {
      title: "This Week",
      date: [
        new Date(),
        new Date(new Date().setDate(new Date().getDate() + 7)),
      ],
    },
    {
      title: "This Month",
      date: [
        new Date(),
        new Date(new Date().setMonth(new Date().getMonth() + 1)),
      ],
    },
    {
      title: "Last Week",
      date: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ],
    },
    {
      title: "Last Month",
      date: [
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        new Date(),
      ],
    },
  ];

type Props = {
  toggleFilter: () => void;
  initialFilter: FilterProps;
  setInitialFilter: Dispatch<SetStateAction<FilterProps>>;
};

const defaultType: FilterProps = {
  type: {
    image: false,
    video: false,
    audio: false,
    riddle: false,
    word_search: false,
    puzzle: false,
  },
  dateRange: [null, null],
};

const ClueFilter: React.FC<Props> = ({
  toggleFilter,
  initialFilter,
  setInitialFilter,
}) => {
  const { getClues } = useClueActions();
  const [searchParams] = useSearchParams();
  const regionId = searchParams.get("region") || "region-1";
  const season = searchParams.get("season") || "1";

  const { data: clueTypes } = useReducerData("clue", "clueTypes", {
    data: {},
    loading: false,
  });

  const filterTypes = Object.entries(clueTypes)
    .map(([key, value]) => {
      if (!typeValue[parseInt(key)]) {
        return;
      }
      return {
        value: typeValue[parseInt(key)],
        label: value,
      };
    })
    .filter((val) => val) as { value: TypeProps; label: string }[];

  const [filter, setFilter] = useState<FilterProps>(initialFilter);
  const [isActiveId, setIsActiveId] = useState<number | null>(null);
  const [dateCollapse, setDateCollapse] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [startDate, endDate] = filter.dateRange;

  // const isOneOFTheTypeSelected = Object.values(filter.type).some(
  //   (value) => value === true
  // );

  // const isDateRangeSelected =
  //   filter.dateRange[0] !== null && filter.dateRange[1] !== null;

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const toggle = () => setDateCollapse(!dateCollapse);

  const onTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      type: {
        ...filter.type,
        [event.target.name]: event.target.checked,
      },
    });
    setInitialFilter({
      ...initialFilter,
      type: {
        ...filter.type,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const handleDateRange = (range: [Date | null, Date | null]) => {
    setFilter({
      ...filter,
      dateRange: range,
    });
    setInitialFilter({
      ...initialFilter,
      dateRange: range,
    });
  };

  const isInRange = (date: Date | null) => {
    if (!startDate || !endDate || !date) {
      return false;
    }
    return startDate < date && date < endDate;
  };

  const dayClassName = (date: Date | null) => {
    if (isInRange(date)) {
      return "selected-range-custom";
    }
    return null;
  };

  const handleActiveClick = (id: number | null) => {
    setIsActiveId(id);
  };

  const getSelectedTypes = (types: TypesProps) => {
    const typeKeys = [];
    for (const key in types) {
      const k = key as TypeProps;
      if (types[`${k}`] === true) {
        const getKey = invert(typeValue)[k];
        typeKeys.push(getKey);
      }
    }

    return typeKeys;
  };

  const handleClueFilter = async () => {
    let params = {};
    const [startDate, endDate] = filter.dateRange;
    const selectedTypes = getSelectedTypes(filter.type);

    params = {
      ...params,
      season,
      ...(startDate && endDate
        ? {
            start_date: moment(startDate).format("YYYY-MM-DD") || "",
            end_date: moment(endDate).format("YYYY-MM-DD") || "",
          }
        : {}),

      clue_types: `${selectedTypes.join(",")}`,
    };
    toggleFilter();

    getClues(regionId, params as any);
  };

  return (
    <>
      <UncontrolledPopover
        id="uncontrolled-date-popover"
        hideArrow
        placement="left"
        isOpen={popoverOpen}
        trigger="click"
        target="Popover1"
        container="grid-view-root"
        innerClassName={classes.datePopover}
      >
        <DatePicker
          selectsStart
          inline
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          selected={startDate}
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          onChange={(update) => {
            handleDateRange(update);
          }}
          dayClassName={dayClassName}
          className={classes.datePicker}
        />
        <div className={classes.dateActions}>
          <Button
            onClick={() => {
              setFilter({
                ...filter,
                dateRange: [null, null],
              });
              setInitialFilter({
                ...filter,
                dateRange: [null, null],
              });
            }}
            color="link"
            className={classes.clear}
            buttonText="Clear"
          />
          <Button
            disabled={
              filter.dateRange[0] === null || filter.dateRange[1] === null
            }
            onClick={togglePopover}
            className={classes.done}
            buttonText="Done"
          />
        </div>
      </UncontrolledPopover>
      <Card cardBodyClassName={classes.cardBody} className={classes.wrapper}>
        <div className={classes.typeSelection}>
          <Typography
            className={classes.title}
            fontWeight="semibold"
            variant="p16"
          >
            Clue Type
          </Typography>

          <div className={classes.typeInputs}>
            {filterTypes?.map((category) => (
              <div key={category.value}>
                <CheckBox
                  id={category.value}
                  checkBoxClassName={classes.checkbox}
                  onChange={onTypeChange}
                  checked={filter.type[category.value]}
                  showOutline
                  label={
                    <Typography className={classes.inputLabel} variant="p14">
                      {category.label}{" "}
                      {getQuestionIcon({
                        type: category.value,
                        style: { marginLeft: "6px" },
                      })}
                    </Typography>
                  }
                  name={category.value}
                />
              </div>
            ))}
          </div>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.dateSelection}>
          <div
            className={cx(classes.dateRangeToggle, {
              [classes.open]: dateCollapse,
            })}
            onClick={toggle}
          >
            <div className={classes.dateRangeText}>
              <Typography className="mb-0" fontWeight="semibold" variant="p14">
                Date Range
              </Typography>
              {filter.dateRange[0] !== null && filter.dateRange[1] !== null && (
                <Typography
                  className={cx("mb-0", classes.dates)}
                  fontWeight="medium"
                  variant="p12"
                >
                  {moment(filter.dateRange[0]).format(DEFAULT_DATE_FORMAT)}-
                  {moment(filter.dateRange[1]).format(DEFAULT_DATE_FORMAT)}
                </Typography>
              )}
            </div>

            <FontAwesomeIcon icon={faAngleDown} />
          </div>
          <Collapse isOpen={dateCollapse}>
            <div className={classes.dateContent}>
              <div
                id="Popover1"
                onClick={() => {
                  togglePopover();
                  handleActiveClick(0);
                  setFilter({ ...filter, dateRange: [null, null] });
                }}
                className={cx(classes.datePopoverButton, {
                  [classes.dateActiveSelectButton]: 0 === isActiveId,
                })}
              >
                <Typography className="mb-0" fontWeight="medium" variant="p14">
                  Choose Dates
                </Typography>
                <CalenderIcon />
              </div>
              {dateRangeButtons.map((data, index) => {
                return (
                  <div
                    key={index}
                    className={cx(classes.dateSelectButton, {
                      [classes.dateActiveSelectButton]:
                        index + 1 === isActiveId,
                    })}
                    onClick={() => {
                      handleActiveClick(index + 1);
                      setPopoverOpen(false);
                      setFilter({
                        ...filter,
                        dateRange: data.date,
                      });
                    }}
                  >
                    <Typography
                      className="mb-0"
                      fontWeight="medium"
                      variant="p14"
                    >
                      {data.title}
                    </Typography>
                  </div>
                );
              })}
            </div>
          </Collapse>
        </div>
        <div className={classes.filterActions}>
          <Button
            // disabled={!isOneOFTheTypeSelected && !isDateRangeSelected}
            outline
            className={classes.apply}
            buttonText="Apply Filters"
            onClick={handleClueFilter}
          />
          <Button
            onClick={() => {
              setFilter(defaultType);
              setInitialFilter(defaultType);
              setIsActiveId(null);
            }}
            color="link"
            className={classes.clear}
            buttonText="Clear"
          />
        </div>
      </Card>
    </>
  );
};

export default ClueFilter;

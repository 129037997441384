import cn from "classnames";
import { ReactComponent as ThreeDotsIcon } from "src/assets/icons/three-dots-icon.svg";
import Button from "src/components/Button";
import Dropdown from "src/components/Dropdown";
import { DropdownItem } from "src/types";
import classes from "./ActionDropdown.module.scss";

interface Props {
  className?: string;
  onChange: (val: string) => void;
  children?: React.ReactNode;
  items: DropdownItem[];
  disabled?: boolean;
}

const ActionDropdown = ({
  className,
  onChange,
  children,
  items,
  disabled,
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.disabled]: disabled,
        },
        className
      )}
    >
      <Dropdown
        text={
          children || (
            <Button
              buttonText={<ThreeDotsIcon />}
              variant="link"
              className={classes.actionBtn}
            />
          )
        }
        dropdownItemClass={classes.dropMenuItem}
        options={disabled ? [] : items}
        handleChange={(_, tValue) => {
          onChange(tValue);
        }}
        noPadding
        overlayColor="gray"
      />
    </div>
  );
};

export default ActionDropdown;

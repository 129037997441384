import cx from "classnames";
import React from "react";
import Select, { StylesConfig } from "react-select";
import DropdownIndicator from "./DropdownIndicator";
import classes from "./styles.module.scss";
import { Props } from "./types";

const RSelect: React.FC<Props> = ({
  getOptionLabel,
  getOptionValue,
  placeholder,
  onChange,
  isClearable,
  options = [],
  touched,
  error,
  label,
  leftIcon,
  rightIcon,
  leftIconClass,
  rightIconClass,
  components,
  name = "",
  helperText = "",
  selectColor = "",
  className = "",
  ...rest
}) => {
  const styles: StylesConfig<unknown, boolean> = {
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "6px",

        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#F0F0F0",
        width: "4px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#B5B5B5",
        borderRadius: "50px",
        width: "4px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  return (
    <>
      {label && <label className="form-control-label mb-2">{label}</label>}
      <div
        className={cx(
          {
            [classes.isInvalid]: touched && error,
            [classes.hasLeftIcon]: leftIcon,
          },
          classes.selectStyling,
          classes[`color_${selectColor}`]
        )}
      >
        {leftIcon && (
          <span className={cx(classes.leftIcon, leftIconClass)}>
            {leftIcon}
          </span>
        )}
        <Select
          styles={styles}
          name={name}
          className={cx(classes.reactSelect, className)}
          classNamePrefix="rselect"
          isClearable={isClearable}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onChange={onChange}
          options={options}
          components={{
            DropdownIndicator,
            ...components,
          }}
          {...rest}
        />
        {rightIcon && (
          <span className={cx(classes.rightIcon, rightIconClass)}>
            {rightIcon}
          </span>
        )}
      </div>
      {!!helperText && <span className={classes.helperText}>{helperText}</span>}
      {error && touched && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </>
  );
};

export default RSelect;

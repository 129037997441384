import cx from "classnames";
import React, { useState } from "react";
import { ModalHeader } from "reactstrap";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import Button from "src/components/Button";
import Modal from "src/components/Modal";

import { PlanProps } from "src/pages/Subscription/Plans/Components/PlanCard/types";
import PaymentInformation from "./PaymentInformation";

import SelectSubscription from "./SelectSubscription";
import classes from "./UpdateSubscriptionModal.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const UpdateSubscriptionModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [selectedPlans, setSelectedPlans] = useState<PlanProps>({});

  const handleSelectedPlan = (plan: PlanProps) => {
    setSelectedPlans({ ...selectedPlans, ...plan });
  };

  const handleStep = (step: number) => setStep(step);

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <SelectSubscription
            handleSelectedPlan={handleSelectedPlan}
            handleStep={handleStep}
            onClose={onClose}
          />
        );
      case 2:
        return (
          <PaymentInformation selectedPlans={selectedPlans} onClose={onClose} />
        );
    }
  };

  return (
    <Modal
      backdrop
      className={cx(classes.modal)}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      <ModalHeader
        close={
          <Button
            buttonText=""
            rightIcon={<CloseIcon />}
            variant="link"
            onClick={onClose}
          />
        }
      ></ModalHeader>
      {getComponent()}
    </Modal>
  );
};

export default UpdateSubscriptionModal;

import cn from "classnames";
import { ReactComponent as ClockIcon } from "src/assets/icons/clock-icon.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import { ReactComponent as LampIcon } from "src/assets/icons/lamp-gradient-yellow-icon.svg";
import Typography from "src/components/Typography";
import { GAME } from "src/pages/Play/Clue/Components/ClueDashboard/Clues/ClueListView/constant";
import classes from "./ClueDetailHeader.module.scss";

interface Props {
  className?: string;
  description: string;
  maxScore: number;
  onClose?: () => void;
  isWhite?: boolean;
  gameName: string;
  answer?: string;
}

const ClueDetailHeader = ({
  className,
  description,
  maxScore,
  onClose,
  isWhite,
  gameName,
  answer,
}: Props) => {
  const renderScore = () => {
    switch (gameName) {
      case GAME.SNAKE.NAME:
        return `${answer ? 30 : 0}/${maxScore}`;
      case GAME.TIC_TOE.NAME:
      case GAME.MATCHING.NAME:
        return `1:00`;
      default:
        break;
    }
  };
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isWhite]: isWhite,
        },
        classes[`game_${gameName}`],
        className
      )}
    >
      <div>
        <div className={classes.description}>
          <Typography variant="p16">{description}</Typography>
        </div>
      </div>
      <div>
        <div className={classes.score}>
          {[GAME.TIC_TOE.NAME, GAME.MATCHING.NAME] && (
            <ClockIcon width={35} height={35} />
          )}
          <span id="clue-game-score">{renderScore()}</span>
          {[GAME.SNAKE.NAME].includes(gameName) && (
            <LampIcon width={45} height={26} />
          )}
        </div>
      </div>
      {onClose && (
        <div className={classes.close}>
          <span onClick={onClose}>
            <span>Close</span>
            <span>
              <CloseIcon />
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default ClueDetailHeader;

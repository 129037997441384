import cx from "classnames";
import React from "react";

import Card from "src/components/Card";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";
import { SubscriptionCardProps } from "./types";

const PlanCardMobile: React.FC<SubscriptionCardProps> = ({
  plan,
  handleSubscriptionSelection,
  selectedPlans,
  error,
}) => {
  return (
    <Card
      onClick={() => handleSubscriptionSelection(plan)}
      cardClassName={cx(classes.wrapper, {
        [classes.activeCard]: selectedPlans?.plan.id === plan.id,
        [classes.error]: !selectedPlans?.plan.id && error,
      })}
      cardBodyClassName={classes.bodyStyles}
    >
      <div>
        <div className={classes.header}>
          <Typography
            className={classes.title}
            variant="p18"
            fontWeight="medium"
            fontFamily="impact"
          >
            ${plan.price && +plan.price}{" "}
            <span>Per {plan?.type === "monthly" ? "Month" : "Year"}</span>
          </Typography>
          <div
            className={cx(classes.select, {
              [classes.activeBorder]: selectedPlans?.plan.id === plan.id,
            })}
          >
            {selectedPlans?.plan.id === plan.id && (
              <span className={classes.active}></span>
            )}
          </div>
        </div>

        <Typography
          fontWeight="light"
          className={classes.details}
          variant="p12"
        >
          {plan?.type === "monthly" ? (
            <>
              Automatic renewal every 30 days.<span>($72/year)</span>
            </>
          ) : (
            <>
              Automatic renewal every 365 days. <span>(Save $22)</span>
            </>
          )}
        </Typography>
      </div>
    </Card>
  );
};

export default PlanCardMobile;

import cs from "classnames";
import get from "lodash/get";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Spinner } from "reactstrap";
import WishLampIcon from "src/assets/icons/wish-lamp-icon.svg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import useUserProfile, {
  useUserProfileLoading,
} from "src/helpers/hooks/useUserProfile";
import WishLamp from "src/pages/PreviewPage/assets/images/wish-lamp-logo.png";
import CardBackground from "src/pages/Subscriptions/assets/card-bg.png";
import Map from "src/pages/Subscriptions/assets/map.svg";
import Star from "src/pages/Subscriptions/assets/star.png";
import { setUserSubscriptionFlow } from "src/store/actions/auth";
import { getSeasons } from "src/store/actions/clue";
import { getUsersSavedRegionList } from "src/store/actions/regions";
import { useStoreActions } from "src/store/hooks";
import { useSeason, useSeasonCountdown } from "src/utils/helper";
import classes from "./styles.module.scss";

const AvailableGames = ({
  actions: actionsArray,
  userType,
}: {
  userType: string;
  actions?: { onClick: () => void; buttonText: string }[];
}) => {
  const navigate = useNavigate();
  const actions = useStoreActions({
    setUserSubscriptionFlow,
    getUsersSavedRegionList,
    getSeasons,
  });
  const userProfile = useUserProfile();
  const loading = useUserProfileLoading();
  const { currentSeason } = useSeason();
  const { duration } = useSeasonCountdown();
  const {
    days = 0,
    hours = 0,
    minutes = 0,
  } = duration || {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  const {
    subscription = {},
    active_subscription = false,
    is_tg_account,
  } = userProfile;
  const isSubscriptionActive = active_subscription || is_tg_account;

  useEffect(() => {
    actions.getUsersSavedRegionList();
    actions.getSeasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getButton = () => {
    return (
      <div
        className={cs(classes.action, {
          [classes.actionForAdmin]: userType === "admin",
        })}
      >
        {actionsArray?.map((action, index) => {
          return (
            <Button
              key={index}
              onClick={action?.onClick}
              buttonClassName={classes.button}
              buttonText={action?.buttonText}
              buttonColor="secondary"
            />
          );
        })}
      </div>
    );
  };

  // const new_clues = subscriptionDetails?.new_clues;

  // if (loading || detailsLoading) {
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className={classes.container} data-testid="my_subscriptions_container">
      <div className={classes.title}>
        <Typography fontFamily="milonga">
          {isSubscriptionActive ? "My Subscriptions" : "Available Games"}
        </Typography>
      </div>
      <div
        style={{
          backgroundImage: `url(${CardBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={classes.gameCard}
      >
        <div className={classes.header}>
          <Typography className={classes.title} fontFamily="inter">
            Wish Lamp$
          </Typography>
          {isSubscriptionActive && (
            <Typography className={classes.type} fontFamily="inter">
              Subscription:{" "}
              {get(subscription, "plan.type") === "monthly"
                ? "Monthly"
                : "Annual"}
            </Typography>
          )}
        </div>
        <div className={classes.content}>
          <div className={classes.card}>
            {/* {isSubscriptionActive && new_clues && (
              <div className={classes.badge}>{new_clues} New Clues</div>
            )} */}
            <div className={classes.details}>
              <div className={classes.detail}>
                <div className={classes.imageWrapper}>
                  <img src={Map} alt="map" />
                </div>
                <div>
                  <Typography
                    className="mb-1"
                    variant="p16"
                    fontWeight="medium"
                    fontFamily="inter"
                  >
                    Regions
                  </Typography>
                  <Typography
                    className="mb-0"
                    variant="p16"
                    fontWeight="semibold"
                    fontFamily="inter"
                  >
                    10 Total
                  </Typography>
                </div>
              </div>
              <div className={classes.detail}>
                <div className={classes.imageWrapper}>
                  <img src={WishLampIcon} alt="lamp" />
                </div>
                <div>
                  <Typography
                    className="mb-1"
                    variant="p16"
                    fontWeight="medium"
                    fontFamily="inter"
                  >
                    Lamps Available
                  </Typography>
                  <Typography
                    className="mb-0"
                    variant="p16"
                    fontWeight="semibold"
                    fontFamily="inter"
                  >
                    20 Per Year
                  </Typography>
                </div>
              </div>
              <div className={classes.detail}>
                <div className={classes.imageWrapper}>
                  <img src={Star} alt="star" />
                </div>
                <div>
                  <Typography
                    className="mb-1"
                    variant="p16"
                    fontWeight="medium"
                    fontFamily="inter"
                  >
                    Time left in season {currentSeason?.id || 1}:
                  </Typography>
                  <Typography
                    className="mb-0"
                    variant="p16"
                    fontWeight="semibold"
                    fontFamily="inter"
                  >
                    {days} Days • {hours} Hours • {minutes} Minutes
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.lamp}>
              <img src={WishLamp} alt="wish-lamp" />
            </div>

            {getButton()}
            {!isSubscriptionActive && (
              <div>
                <Button
                  buttonColor="secondary"
                  onClick={() => navigate("/preview")}
                  buttonText={
                    <Typography fontFamily="inter" variant="p20">
                      View Preview Page
                    </Typography>
                  }
                  variant="link"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableGames;

import cn from "classnames";
import find from "lodash/find";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { ANNOUNCEMENT_IDS } from "src/helpers/constants";
import useBackUrl from "src/helpers/hooks/useBackUrl";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import useInfiniteScroll from "src/helpers/hooks/useInfiniteScroll";
import useMediaDetailsInit from "src/helpers/hooks/useMediaDetailsInit";
import useUserDetailsInit from "src/helpers/hooks/useUserDetailsInit";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import UserFollowers from "src/pages/Watch/UserFollowers";
import WatchHeader from "src/pages/Watch/components/Header";
import { CommonGetPayload } from "src/types";
import { scrollToTop } from "src/utils/scrollTop";
import classes from "./Announcements.module.scss";
import AnnouncementsMain from "./Main";
import AnnouncementsPlayingMedia from "./PlayingMedia";
import AnnouncementsUserDetails from "./UserDetails";
import { useActions, useIndexData } from "./selectorData";

let searchValue = "";
let isLoadingTemp = false;

const Announcements = () => {
  const navigate = useNavigate();
  useMediaDetailsInit();
  const { loadUserFeed } = useUserDetailsInit();
  const userProfile = useUserProfile();
  const { pastAnnouncementChannels, userFeed, currentAnnouncementChannels } =
    useIndexData();
  const pastAnnouncementData = find(pastAnnouncementChannels, {
    slug: ANNOUNCEMENT_IDS.PAST,
  });
  const currentAnnouncementData = find(currentAnnouncementChannels, {
    slug: ANNOUNCEMENT_IDS.CURRENT,
  });
  const { pathname } = useLocation();
  const params = useParams();
  const currentPath = useCurrentPath();
  const { getCurrentAnnouncements, getPastAnnouncements } = useActions();
  const userId = Number(params.userId || 0);
  const isMainPage = pathname === "/announcements";
  const isPlayingMediaPage = currentPath === "/announcements/playing-media/:id";
  const isUserDetailsPage =
    currentPath === "/announcements/playing-media/:id/:name/:userId";
  const isFollowersPage =
    currentPath === "/announcements/playing-media/:id/:name/:userId/followers";
  const backUrl = useBackUrl("/announcements");
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const loadMainFeed = (key: string, nextCursor?: string, cb?: any) => {
    const tFilters: CommonGetPayload = {};
    if (nextCursor) {
      tFilters.cursor = nextCursor;
      tFilters.isMore = true;
    }
    if (searchValue) {
      tFilters.q = searchValue;
    }
    if (key === "both") {
      getCurrentAnnouncements(tFilters, cb);
      getPastAnnouncements(tFilters, cb);
    } else if (key === ANNOUNCEMENT_IDS.CURRENT) {
      getCurrentAnnouncements(tFilters, cb);
    } else if (key === ANNOUNCEMENT_IDS.PAST) {
      getPastAnnouncements(tFilters, cb);
    }
  };

  const getPageComponent = () => {
    switch (true) {
      case isPlayingMediaPage:
        return <AnnouncementsPlayingMedia />;
      case isUserDetailsPage:
        return <AnnouncementsUserDetails />;
      case isFollowersPage:
        return (
          <UserFollowers
            isMyChannel={false}
            onTabChanged={undefined}
            tabs={undefined}
          />
        );
      default:
        return <AnnouncementsMain />;
    }
  };
  const loadMore = () => {
    if (!isLoadingTemp) {
      if (isMainPage) {
        if (
          !!pastAnnouncementData?.meta?.next_cursor ||
          !!currentAnnouncementData?.meta?.next_cursor
        ) {
          setIsLazyLoading(true);
        }
        if (currentAnnouncementData?.meta?.next_cursor) {
          loadMainFeed(
            ANNOUNCEMENT_IDS.CURRENT,
            currentAnnouncementData?.meta.next_cursor,
            () => {
              setIsLazyLoading(false);
              isLoadingTemp = false;
            }
          );
        }
        if (pastAnnouncementData?.meta?.next_cursor) {
          loadMainFeed(
            ANNOUNCEMENT_IDS.PAST,
            pastAnnouncementData?.meta.next_cursor,
            () => {
              setIsLazyLoading(false);
              isLoadingTemp = false;
            }
          );
        }
      } else if (isUserDetailsPage) {
        setIsLazyLoading(true);
        loadUserFeed(
          userFeed.meta.next_cursor,
          () => {
            setIsLazyLoading(false);
            isLoadingTemp = false;
          },
          userId
        );
      }
    }
    isLoadingTemp = true;
  };
  const handleSearchChange = (val: string) => {
    searchValue = val;
    if (isMainPage) {
      loadMainFeed("both");
    } else if (isUserDetailsPage) {
      loadUserFeed("", null, userId, searchValue);
    }
  };

  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      (isMainPage
        ? !!pastAnnouncementData?.meta?.next_cursor ||
          !!currentAnnouncementData?.meta?.next_cursor
        : isUserDetailsPage
        ? !!userFeed?.meta?.next_cursor
        : false),
    elementId: "watch-container",
    isMultipleSection: true,
  });

  useEffect(() => {
    searchValue = "";
    isLoadingTemp = false;
    setIsLazyLoading(false);
    scrollToTop("watch-container");
    if (isMainPage) {
      loadMainFeed("both");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className={cn(classes.wrapper)}>
      <WatchHeader
        isRoot={isMainPage}
        onProfileClick={() => navigate("/watch/my-channel")}
        onBack={() => {
          navigate(backUrl);
        }}
        hideProfile={false}
        showManageContent={false}
        showUpload={false}
        onManageContent={() => {}}
        isMainPage={isMainPage}
        onUpload={() => {}}
        onSearchChanged={handleSearchChange}
        avatarSrc={userProfile.user?.avatar_path || ""}
      />
      {getPageComponent()}
    </div>
  );
};

export default Announcements;

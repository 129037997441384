import { useReducerData } from "src/store/hooks";

export const useIndexData = () => {
  return {
    newCluesAvailable: useReducerData("dashboard", "newCluesAvailable.data", 0),
    newCluesAvailableLoading: useReducerData(
      "dashboard",
      "newCluesAvailable.loading",
      false
    ),
    dashboardContent: useReducerData("dashboard", "dashboardContent.data", {}),
    dashboardAnnouncements: useReducerData(
      "dashboard",
      "dashboardAnnouncements.data",
      {}
    ),
    dashboardAnnouncementsLoading: useReducerData(
      "dashboard",
      "dashboardAnnouncements.loading",
      false
    ),
  };
};

import get from "lodash/get";
import { GAME } from "./constant";

export const getClueGameInfo = (currentClue?: any) => {
  if (currentClue?.field === "custom") {
    const gameKey =
      currentClue?.category?.slug === "matching"
        ? "MATCHING"
        : currentClue?.category?.slug === "snake"
        ? "SNAKE"
        : currentClue?.category?.slug === "tic-tac-toe"
        ? "TIC_TOE"
        : "";
    const gameObj = get(GAME, gameKey) as any;
    return {
      displayGame: gameObj?.NAME || "",
      gameDescription: gameObj?.DESCRIPTION || "",
    };
  }
  return {
    displayGame: "",
    gameDescription: "",
  };
};

import get from "lodash/get";
import { Dispatch } from "redux";
import {
  apiCreatePost,
  apiDeletePost,
  apiGetAllMedia,
  apiGetMediaDetail,
  apiGetMediaRelated,
  apiGetPostDetails,
  apiGetPostStatus,
  apiLikeUnlikePost,
  apiReportMedia,
  apiRepostPost,
  apiSearchMedia,
  apiUpdateMediaPost,
  apiUploadMediaPost,
} from "src/api/media";
import { ROLE_NAME } from "src/helpers/constants/role";
import { store } from "src/store";
import { ReportMediaPayload, UpdateMediaPostPayload } from "src/types/media";

import Types from "../types/media";

export const syncResetPostStatus = () => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_RESET_POST_STATUS,
  });
};
export const syncSetPostStatus = (status: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_SET_POST_STATUS,
    payload: { status },
  });
};
export const syncResetUploadMediaPost = () => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_RESET_UPLOAD_MEDIA_POST,
  });
};
export const syncSetPostDetails =
  (postData: Record<string, unknown>) => (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_SET_POST_DETAILS,
      payload: postData,
    });
  };
export const syncSetMediaDetails =
  (mediaData: Record<string, unknown>) => (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_SET_MEDIA_DETAIL,
      payload: mediaData,
    });
  };
export const getAllMedia =
  (search?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_ALL_MEDIA,
      asyncCall: () => {
        const tState = store.getState();
        const isAdmin =
          get(tState, "auth.userProfile.data.user.role.name") ===
          ROLE_NAME.ADMIN;
        const params: {
          q?: string;
          is_full_access?: boolean;
        } = { q: search };
        if (isAdmin) {
          params.is_full_access = true;
        }
        return apiGetAllMedia(params);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const searchMedia =
  (filters?: any, cb?: any) => (dispatch: Dispatch) => {
    const { isMore, ...other } = filters || {};
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.SEARCH_MEDIA,
      asyncCall: () => {
        return apiSearchMedia(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getMediaDetail =
  (id: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_MEDIA_DETAIL,
      asyncCall: () => {
        return apiGetMediaDetail(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getMediaRelated =
  (
    channelId: string | number,
    postId: string,
    userId?: number,
    search?: string,
    cb?: any
  ) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_MEDIA_RELATED,
      asyncCall: () => {
        return apiGetMediaRelated(channelId, postId, userId, search);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getPostStatus = (id: string, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_POST_STATUS,
    asyncCall: () => {
      return apiGetPostStatus(id);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getPostDetails =
  (userId: number, postId: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { postId },
      type: Types.GET_POST_DETAILS,
      asyncCall: () => {
        return apiGetPostDetails(userId, postId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const reportMedia =
  (id: string, data: ReportMediaPayload, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.REPORT_MEDIA,
      asyncCall: () => {
        return apiReportMedia(id, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const createPost = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_POST,
    asyncCall: () => {
      return apiCreatePost();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const uploadMediaPost =
  (postId: string, data: FormData, key: string, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPLOAD_MEDIA_POST,
      asyncCall: () => {
        return apiUploadMediaPost(postId, data, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const updateMediaPost =
  (postId: string, data: UpdateMediaPostPayload, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_MEDIA_POST,
      asyncCall: () => {
        return apiUpdateMediaPost(postId, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const deletePost =
  (postId: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.DELETE_POST,
      asyncCall: () => {
        return apiDeletePost(postId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const likeUnlikePost =
  (postId: string, key: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.LIKE_UNLIKE_POST,
      asyncCall: () => {
        return apiLikeUnlikePost(postId, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const repostPost =
  (postId: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.REPOST_POST,
      asyncCall: () => {
        return apiRepostPost(postId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

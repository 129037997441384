import { Dispatch } from "redux";
import {
  apiGetUserNotificationCount,
  apiGetUserNotifications,
  apiOpenUserNotification,
  apiViewUserNotification,
} from "src/api/notification";
import Types from "../types/notification";

export const syncSetUserNotificationCount =
  (data: number) => (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_SET_USER_NOTIFICATION_COUNT,
      payload: data,
    });
  };
export const syncSetViewNotification =
  (ids: string[]) => (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_SET_VIEW_NOTIFICATION,
      payload: { ids },
    });
  };
export const getUserNotifications = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_USER_NOTIFICATIONS,
    asyncCall: () => {
      return apiGetUserNotifications();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getUserNotificationCount = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_USER_NOTIFICATION_COUNT,
    asyncCall: () => {
      return apiGetUserNotificationCount();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const viewUserNotification =
  (ids: string[], cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { ids },
      type: Types.VIEW_USER_NOTIFICATION,
      asyncCall: () => {
        return apiViewUserNotification(ids);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const openUserNotification =
  (ids: string[], cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { ids },
      type: Types.OPEN_USER_NOTIFICATION,
      asyncCall: () => {
        return apiOpenUserNotification(ids);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const clearUserNotification =
  (ids: string[], cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { ids },
      type: Types.CLEAR_USER_NOTIFICATION,
      asyncCall: () => {
        return apiOpenUserNotification(ids);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

import { useReducerData } from "src/store/hooks";
const useOptions = () => {
  const regions = useReducerData("region", "regionList.data", []);
  const regionOptions = regions.map((region: Record<string, unknown>) => ({
    value: region.id,
    label: `${region.name} - ${region.state_names}`,
  }));
  return {
    regionOptions,
  };
};

export default useOptions;

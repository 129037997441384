import { ClueProps } from "src/pages/Play/Clue/types";
import { RegionListProps } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import { useReducerData } from "src/store/hooks";
import { PurchaseHistoryProps, RangeFinder } from "src/types/clue";
import { Region } from "src/types/region";
import { CountdownData, DeviceProps } from "src/types/user";

export const useAuthData = () => {
  return {
    forgotPassword: {
      data: useReducerData("auth", "forgotPassword.data", {}),
      loading: useReducerData(
        "auth",
        "forgotPassword.loading",
        false
      ) as boolean,
    },
    resetPassword: {
      data: useReducerData("auth", "resetPassword.data", {}),
      loading: useReducerData(
        "auth",
        "resetPassword.loading",
        false
      ) as boolean,
    },
  };
};

export const useUserData = () => {
  return {
    devices: {
      data: useReducerData("user", "devices.data", []) as DeviceProps[],
      loading: useReducerData("user", "devices.loading", false) as boolean,
    },
    removeDevice: {
      data: useReducerData("user", "removeDevice.data", []),
      loading: useReducerData("user", "removeDevice.loading", false) as boolean,
    },
    changeSubscription: {
      data: useReducerData("user", "changeSubscription.data", {}),
      loading: useReducerData(
        "user",
        "changeSubscription.loading",
        false
      ) as boolean,
    },
    newsData: {
      data: useReducerData("user", "newsData.data", []),
      loading: useReducerData("user", "newsData.loading", false) as boolean,
    },
    playCountdown: {
      data: useReducerData("user", "playCountdown.data", {}) as CountdownData,
      loading: useReducerData(
        "user",
        "playCountdown.loading",
        false
      ) as boolean,
    },
  };
};

export const useRegionData = () => {
  return {
    region: {
      data: useReducerData("region", "region.data", {}) as Region,
      loading: useReducerData("region", "region.loading", false) as boolean,
    },
    regionList: {
      data: useReducerData(
        "region",
        "regionList.data",
        []
      ) as RegionListProps[],
      loading: useReducerData("region", "regionList.loading", false) as boolean,
    },
  };
};

export const useCluesData = () => {
  return {
    clues: {
      data: useReducerData("clue", "clues.data", []) as ClueProps[],
      loading: useReducerData("clue", "clues.loading", false) as boolean,
    },
    rangeFinderBuyOptions: {
      data: useReducerData(
        "clue",
        "rangeFinderBuyOptions.data",
        []
      ) as RangeFinder[],
      loading: useReducerData(
        "clue",
        "rangeFinderBuyOptions.loading",
        false
      ) as boolean,
    },
    advantageCluesPurchaseHistoryData: {
      data: useReducerData(
        "clue",
        "advantageCluesPurchaseHistoryData.data",
        []
      ) as PurchaseHistoryProps[],
      loading: useReducerData(
        "clue",
        "advantageCluesPurchaseHistoryData.loading",
        false
      ) as boolean,
    },
    treasureViewsBuyOptions: {
      data: useReducerData(
        "clue",
        "treasureViewsBuyOptions.data",
        []
      ) as RangeFinder[],
      loading: useReducerData(
        "clue",
        "treasureViewsBuyOptions.loading",
        false
      ) as boolean,
    },
  };
};

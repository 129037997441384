import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router";
// import BellIcon from "src/assets/icons/bell-icon.svg";
import UserIcon from "src/assets/icons/user-icon.svg";
import TgTile from "src/assets/images/tg-title.png";
// import TGLOGO from "src/assets/images/tgs-button.svg";
import TitleIcon from "src/pages/Auth/assets/icon/gametitle_icon.svg";
import BurgerIcon from "src/pages/Home/assets/icons/burger-icon.svg";
import Tsg from "src/pages/Home/assets/images/tgs-img.svg";
// import TgtextMobile from "src/pages/Subscription/assets/tg-mobile-text.png";
import { useWindowSize } from "src/utils/useWindowSize";
import UserDropdown from "../UserDropdown";
import classes from "./styles.module.scss";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;

  const getHeaderImage = () => {
    if (pathname === "/user/news") {
      if (isMobile) {
        return <img src={TitleIcon} alt="tg-img" />;
      } else {
        return <img style={{ maxWidth: "166px" }} src={Tsg} alt="tg-img" />;
      }
    }
    if (isMobile) {
      return <img src={TitleIcon} alt="tg-img" />;
    } else {
      return <img src={TgTile} alt="tg-img" style={{ maxWidth: "471px" }} />;
    }
  };

  return (
    <div className={classes.navWrapper}>
      <div className={classes.wrapper}>
        <div
          className={classes.title}
          onClick={() => navigate("/subscriptions")}
        >
          {getHeaderImage()}
        </div>
        <div className={classes.navActions}>
          {/* {!isMobile && <img src={BellIcon} alt="bell-icon" />} */}
          <UserDropdown notAlign>
            {isMobile ? (
              <img alt="burger" src={BurgerIcon} />
            ) : (
              <>
                <img src={UserIcon} alt="user-icon" />
                <FontAwesomeIcon icon={faAngleDown} size="xs" />
              </>
            )}
          </UserDropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  RegionListProps,
  SavedRegionProps,
} from "src/pages/Play/SelectRegions/Components/RegionList/types";
import Map from "src/pages/Play/SelectRegions/Components/RegionMap/Map";
import { useReducerData } from "src/store/hooks";
import HeaderText from "../HeaderText";
import PlayCta from "./components/PlayCta";
import PlayInfo from "./components/PlayInfo";
import classes from "./styles.module.scss";

const PlayPreview = () => {
  const navigate = useNavigate();
  const { data: regionList }: { data: RegionListProps[] } = useReducerData(
    "region",
    "regionList",
    {
      data: [],
      loading: false,
    }
  );
  const { data: savedRegions } = useReducerData("region", "savedRegionList", {
    data: [],
  });

  const [addedRegions, setAddedRegions] = useState<string[]>([]);

  useEffect(() => {
    const savedRegionsIds = savedRegions.map(
      (region: SavedRegionProps) => region.slug
    );
    setAddedRegions([...savedRegionsIds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRegions]);

  return (
    <div className={classes.wrapper}>
      <HeaderText section="play" text="Play" />
      <div className={classes.mapWrapper}>
        <PlayInfo />
        <div className={classes.map} onClick={() => navigate("/play")}>
          <Map
            handleRemoveRegion={() => {}}
            handleAddRegion={() => {}}
            handleRegionSelect={() => {}}
            handleHoveredRegion={() => {}}
            addedRegions={addedRegions}
            regionSelectionFlow={false}
            regionList={regionList}
            hideTooltip
          />
        </div>
        <PlayCta />
      </div>
    </div>
  );
};

export default PlayPreview;

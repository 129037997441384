import cx from "classnames";
import React, { ChangeEvent } from "react";
import { FormGroup } from "reactstrap";
import CheckBox from "src/components/CheckBox";
import Text from "src/components/Text";
import { FormProps } from "..";
import classes from "./Preferences.module.scss";
type Props = {
  wrapperClassName?: string;
  handleChange: (e: ChangeEvent<any>) => void;
  values: FormProps;
};

const Preferences: React.FC<Props> = ({
  wrapperClassName = "",
  handleChange,
  values,
}) => {
  return (
    <>
      <div className={cx(classes.wrapper, wrapperClassName)}>
        <Text className="mb-4" color="#fff" size={24}>
          Notification Preferences
        </Text>
        <FormGroup className={cx("text-start", classes.formGroupCheckBox)}>
          <CheckBox
            id="notify_me_when_new_clue"
            wrapperClassName="align-items-baseline"
            onChange={handleChange}
            checked={values.notify_me_when_new_clue}
            showOutline
            label={
              <Text color="#fff" className={cx("d-flex gap-1 mb-0")} size={16}>
                Notify me anytime there is a new clue
              </Text>
            }
            name="notify_me_when_new_clue"
          />
        </FormGroup>
        <FormGroup className={cx("text-start", classes.formGroupCheckBox)}>
          <CheckBox
            wrapperClassName="align-items-baseline"
            id="notify_me_when_treasure_found"
            onChange={handleChange}
            checked={values.notify_me_when_treasure_found}
            showOutline
            label={
              <Text color="#fff" className={cx("d-flex gap-1 mb-0")} size={16}>
                Notify me when a treasure is found in a region
              </Text>
            }
            name="notify_me_when_treasure_found"
          />
        </FormGroup>
        <FormGroup className={cx("text-start", classes.formGroupCheckBox)}>
          <CheckBox
            wrapperClassName="align-items-baseline"
            id="notify_me_when_new_contest"
            onChange={handleChange}
            checked={values.notify_me_when_new_contest}
            showOutline
            label={
              <Text color="#fff" className={cx("d-flex gap-1 mb-0")} size={16}>
                Notify me when there is a new contest/prizes/promotions
              </Text>
            }
            name="notify_me_when_new_contest"
          />
        </FormGroup>
        <FormGroup className={cx("text-start", classes.formGroupCheckBox)}>
          <CheckBox
            wrapperClassName="align-items-baseline"
            id="notify_me_when_new_announcement"
            onChange={handleChange}
            checked={values.notify_me_when_new_announcement}
            showOutline
            label={
              <Text color="#fff" className={cx("d-flex gap-1 mb-0")} size={16}>
                Notify me when there is a new engagement post
              </Text>
            }
            name="notify_me_when_new_announcement"
          />
        </FormGroup>
      </div>
      <div className={cx(classes.wrapper, wrapperClassName)}>
        <Text className="mb-4" color="#fff" size={24}>
          Email Preferences
        </Text>
        <FormGroup className={cx("text-start", classes.formGroupCheckBox)}>
          <CheckBox
            id="email_preferences"
            wrapperClassName="align-items-baseline"
            onChange={handleChange}
            checked={values.email_preferences}
            showOutline
            label={
              <Text color="#fff" className={cx("d-flex gap-1 mb-0")} size={16}>
                Allow TG$ to send automated emails to me when certain actions
                have been taken.
              </Text>
            }
            name="email_preferences"
          />
        </FormGroup>
      </div>
    </>
  );
};

export default Preferences;

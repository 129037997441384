import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGoogleLogin } from "@react-oauth/google";
import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import get from "lodash/get";
import isString from "lodash/isString";
import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { IS_TESTING } from "src/helpers/constants";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { ROLE_LEVEL } from "src/helpers/constants/role";
import { doLogin, ssoLogin } from "src/store/actions/auth";
import { useStoreActions } from "src/store/hooks";
import { TGS_BASE_URL } from "src/utils/constants";
import { trackEvent } from "src/utils/googleAnalytics";
import { useWindowSize } from "src/utils/useWindowSize";
import { getUserAgentData } from "src/utils/utils";
import LimitDeviceModal from "../components/LimitDeviceModal";
import classes from "./styles.module.scss";
import { SignInInputProps } from "./types";

export const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID || "";

const mockDeviceData = {
  deviceToken: "fKyraHf68WJyEttKkpKb",
  fpRequestId: "1699334038264.O8kjWp",
};

const SignIn = () => {
  const { width = 0 } = useWindowSize();
  const isMobile = width > 0 && width <= 768;
  const navigate = useNavigate();
  const actions = useStoreActions({
    doLogin,
    ssoLogin,
  });
  const { data: deviceData = null, error: deviceError } = useVisitorData({
    extendedResult: true,
  });

  const [searchParams] = useSearchParams();
  const fromMobile = searchParams.get("fromMobile");
  const privatePath = searchParams.get("private-path");
  const isFreeTrial = searchParams.get("free_trial");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLitmitDevice, setIsLimitDevice] = useState("");
  const signInSchema = Yup.object().shape({
    email: Yup.string().required("Username Or Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const userAgent = getUserAgentData();

  useEffect(() => {
    if (deviceError) {
      toast.error("Please disable any ad blocker plugin you have installed.");
    }
  }, [deviceError]);
  useEffect(() => {
    setIsLimitDevice("");
  }, []);

  const handleSubmit = async (
    values: SignInInputProps,
    formikHelpers: FormikHelpers<SignInInputProps>
  ) => {
    setLoading(true);
    const submitValues = {
      ...values,
      device_name: userAgent.deviceName + " - " + userAgent.type,
      device_token: deviceData?.visitorId || "",
      fp_request_id: deviceData?.requestId || "",
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status, data, errorStatus } = await actions.doLogin(
      submitValues,
      (res: any) => {
        if (privatePath) {
          setLoading(false);
          const roleName = get(res, "data.user.role.name");
          navigate(
            roleName === ROLE_LEVEL.FULL_ACCESS
              ? privatePath
              : isMobile
              ? "/user/device-blocked"
              : "/play"
          );
        }
      }
    );
    if (!(privatePath && status)) {
      setLoading(false);
    }
    const message = get(data, "message", "");
    const role = get(data, "user.role", null);
    const isTGAccount = get(data, "user.is_tg_account", false);

    const isFromValidDevice = message !== "Device not registered.";

    if (errorStatus === 403) {
      setIsLimitDevice(message);
      return;
    }

    if (!isFromValidDevice) {
      toast.error("Device not registered");
      return;
    }

    if (status) {
      if (!role && !isTGAccount) {
        navigate("/subscription/select-access");
        return;
      }
      if (fromMobile) {
        navigate("/subscription/plan");
        return;
      }
      if (!privatePath) {
        if (isMobile) {
          navigate("/user/device-blocked");
        } else {
          navigate("/play");
        }
        formikHelpers.resetForm();
      }
    } else {
      if (isString(message)) {
        setError(message);
      } else {
        const { error } = data;
        const errorMessage = Object.values(error).flatMap((b) => b);
        setError(errorMessage[0] as string);
      }
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setLoading(true);
      const access_token = codeResponse.access_token;
      if (access_token) {
        const onSsoLoginSuccess = (res: any) => {
          if (res.status) {
            navigate("/subscriptions");
          }
          const message = get(res, "data.error", "");
          if (isString(message)) {
            setError(message);
          } else {
            const { error } = res;
            const errorMessage = Object.values(error).flatMap((b) => b);
            setError(errorMessage[0] as string);
          }
          setLoading(false);
        };

        actions.ssoLogin(
          {
            access_token,
            sso_type: "google",
            device_name: userAgent.deviceName + " - " + userAgent.type,
            device_token: IS_TESTING
              ? mockDeviceData.deviceToken
              : deviceData?.visitorId || "",
          },
          onSsoLoginSuccess
        );
      }
    },
  });

  const handleFacebookLogin = (response: any) => {
    // eslint-disable-next-line no-console
    const { accessToken } = response;
    setLoading(true);
    if (accessToken) {
      const onSsoLoginSuccess = (res: any) => {
        if (res.status) {
          navigate("/subscriptions");
        }
        const message = get(res, "data.error", "");
        if (isString(message)) {
          setError(message);
        } else {
          const { error } = res;
          const errorMessage = Object.values(error).flatMap((b) => b);
          setError(errorMessage[0] as string);
        }
        setLoading(false);
      };

      actions.ssoLogin(
        {
          access_token: accessToken,
          sso_type: "facebook",
          device_name: userAgent.deviceName + " - " + userAgent.type,
          device_token: IS_TESTING
            ? mockDeviceData.deviceToken
            : deviceData?.visitorId || "",
        },
        onSsoLoginSuccess
      );
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.signInOption}>
          <div
            className={classes.optionWrapper}
            onClick={() => handleGoogleLogin()}
          >
            <FontAwesomeIcon icon={faGoogle} color="#fff" />
          </div>
          <FacebookLogin
            fields="email"
            scope="public_profile,email"
            appId={facebookAppId}
            callback={handleFacebookLogin}
            icon={<FontAwesomeIcon icon={faFacebookF} />}
            render={(renderProps) => (
              <div className={classes.optionWrapper}>
                <FontAwesomeIcon
                  icon={faFacebookF}
                  color="#fff"
                  onClick={renderProps.onClick}
                />
              </div>
            )}
          />
        </div>
        <div className={classes.divider}>
          <div className={classes.wordWithLine}>OR</div>
        </div>
        <div className={classes.mainWrapper}>
          <div>
            <Formik
              onSubmit={(values, formikHelpers) =>
                handleSubmit(values, formikHelpers)
              }
              validationSchema={signInSchema}
              initialValues={{
                email: "",
                password: "",
              }}
            >
              {({
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                isValid,
                dirty,
              }: FormikProps<SignInInputProps>) => (
                <Form
                  className={cx(classes.signupInputs, "d-flex flex-column")}
                >
                  <FormGroup className={classes.formGroup}>
                    <Input
                      labelClassName={classes.label}
                      inputClassName={classes.input}
                      inputGroupClassName={classes.inputWrapper}
                      label="Username or Email Address"
                      required={true}
                      value={values.email}
                      placeholder="“My_username” or example@email.com"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.email}
                      touched={touched.email}
                      name="email"
                    />
                  </FormGroup>
                  <FormGroup className={classes.formGroup}>
                    <Input
                      showPasswordIcon
                      inputClassName={classes.input}
                      labelClassName={classes.label}
                      inputGroupClassName={classes.inputWrapper}
                      label="Password"
                      value={values.password}
                      placeholder="Enter your password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.password}
                      touched={touched.password}
                      name="password"
                      type="password"
                      iconClassName={classes.eyeIcon}
                    />
                  </FormGroup>
                  <div className={classes.submit}>
                    <Button
                      disabled={!isValid || !dirty}
                      loading={loading}
                      type="submit"
                      buttonColor="purple-gradient"
                      buttonText="Login"
                    />
                  </div>
                  <div className={`${classes.forgotPassword}`}>
                    <div className={classes.footerLink}>
                      <a href={`${TGS_BASE_URL}/auth/forgot-password`}>
                        Forgot Password
                      </a>
                    </div>
                  </div>
                  <Typography className={classes.errorMessage}>
                    {error}
                  </Typography>
                  <div className={classes.footer}>
                    <div className={classes.footerLink}>
                      By Logging in, you agree to our{" "}
                      <a
                        href={`${TGS_BASE_URL}/a/privacy-policy-ip-policy`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>{" "}
                      ,{" "}
                      <a
                        href={`${TGS_BASE_URL}/a/terms-and-conditions`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms and Conditions
                      </a>{" "}
                      ,{" "}
                      <a
                        href={`${TGS_BASE_URL}/a/tg-risk-waiver`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Risk Waiver
                      </a>
                    </div>
                    <div className={classes.noAccount}>
                      Don’t have an account?
                      <span
                        onClick={() => {
                          navigate(
                            `/auth/signup${
                              isFreeTrial ? "?free_trial=true" : ""
                            }`
                          );
                          trackEvent(
                            ANALYTICS_CONSTANTS.category.sign_up,
                            isFreeTrial
                              ? ANALYTICS_CONSTANTS.action.sign_up_free
                              : ANALYTICS_CONSTANTS.action.sign_up_full
                          );
                        }}
                      >
                        Sign up
                      </span>
                    </div>
                  </div>
                  <div className={classes.mobileNext}>
                    <Button
                      buttonText="Login"
                      buttonColor="purple-gradient"
                      disabled={!isValid || !dirty}
                      loading={loading}
                      type="submit"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <LimitDeviceModal
        isOpen={!!isLitmitDevice}
        onClose={() => {
          setIsLimitDevice("");
        }}
        message={isLitmitDevice}
      />
    </>
  );
};

export default SignIn;

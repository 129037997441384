import cn from "classnames";
import OInputMask from "react-input-mask";
import { InputProps } from "reactstrap";
import classes from "./InputMask.module.scss";

type Props = {
  className?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  mask: string;
  placeholder?: string;
  touched?: boolean;
  error?: string;
  labelClassName?: string;
} & InputProps;

const InputMask = ({
  className,
  label,
  required,
  mask,
  placeholder,
  touched,
  error,
  labelClassName,
  ...rest
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isInvalid]: touched && !!error,
        },
        className
      )}
    >
      {!!label && (
        <label className={cn("form-control-label mb-2", labelClassName)}>
          {label}
          {required && <span className={classes.required}> *</span>}
        </label>
      )}
      <div className={classes.input}>
        <OInputMask
          {...rest}
          mask={mask}
          placeholder={placeholder}
          maskChar={null}
        />
      </div>
      {touched && error && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </div>
  );
};

export default InputMask;

import cn from "classnames";
import Button from "src/components/Button";
import Upload from "src/components/Upload";
import {
  ACCEPT_UPLOAD_IMAGES,
  ACCEPT_UPLOAD_VIDEOS,
} from "src/helpers/constants";
import classes from "./SourcePreview.module.scss";

interface Props {
  className?: string;
  isUploadImage?: boolean;
  name: string;
  size: string;
  src?: string;
  title?: string;
  noPadding?: boolean;
  length?: string;
  uploadable?: boolean;
  onFileChanged?: (tFile: any) => void;
  loading?: boolean;
}

const SourcePreview = ({
  className,
  isUploadImage,
  name,
  size,
  src,
  title,
  noPadding,
  length,
  uploadable,
  onFileChanged,
  loading,
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isUploadImage]: isUploadImage,
          [classes.noPadding]: noPadding,
        },
        className
      )}
    >
      {title || (!isUploadImage && <label>Video</label>)}
      <div
        className={cn(classes.resourceWrapper, {
          [classes.isUploading]: !src,
          [classes.uploadable]: uploadable,
        })}
      >
        <div>
          {uploadable ? (
            <>
              <span>Please select another file to upload.</span>
              <Upload
                placeholderClassName={classes.uploadWrapper}
                onChange={(tFile) => {
                  if (onFileChanged) onFileChanged(tFile);
                }}
                accept={
                  isUploadImage ? ACCEPT_UPLOAD_IMAGES : ACCEPT_UPLOAD_VIDEOS
                }
              >
                <Button
                  buttonText="Upload"
                  buttonColor="purple"
                  buttonClassName={classes.uploadBtn}
                  loading={loading}
                />
              </Upload>
              <span>
                Please ensure that your file is no larger than 5GB and doesn't
                exceed 15 minutes in duration
              </span>
              <span>
                Accepted file types: .MOV, .MP4, .MPG, .AVI, .WMV, .FLV, 3GPP,
                WebM, JPEG, PNG, GIF
              </span>
            </>
          ) : (
            <>
              {src ? (
                <img src={src} alt="placeholder" />
              ) : (
                <span>{isUploadImage ? "Image" : "Video"} Uploading</span>
              )}
            </>
          )}
        </div>
        <div>
          <span>
            <span>File: </span>
            <span>{name || "--"}</span>
          </span>
          <span>
            <span>Size: </span>
            <span>{size || "--"}</span>
          </span>
          {!isUploadImage && (
            <span>
              <span>Length: </span>
              <span>{length || "--"}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SourcePreview;

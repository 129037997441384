/* eslint-disable react/no-multi-comp */
import { useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import cn from "classnames";
import { useEffect, useState } from "react";
import {
  Button as BaseButton,
  ButtonProps,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import LeftArrow from "src/assets/icons/left-arrow-icon.svg";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Text from "src/components/Text";
import rangeFinder from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/range-finder.png";
import { PurchaseHistoryProps, RangeFinder } from "src/types/clue";
import { useRegionData } from "src/utils/useData";
import RangeFinderInfo from "./RangeFinderInfo";
import classes from "./RangeFinderModal.module.scss";
import RangeFinderPayment from "./RangeFinderPayment";
import RangeInputMap, { LatLng } from "./RangeInputMap";
import Result from "./Result";
interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  lampId: number;
  initialStep?: number;
  initialResultData?: PurchaseHistoryProps;
  refreshCluePurchaseData: () => void;
  lampData: any;
}

const googleMapApiKey = process.env.REACT_APP_GMAP_API_KEY as string;

const RangeFinderModal = ({
  className = "",
  isOpen,
  onClose,
  lampId,
  initialStep = 1,
  initialResultData,
  refreshCluePurchaseData,
  lampData,
}: Props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey,
  });
  const {
    region: { data: currentRegion },
  } = useRegionData();
  const { states = [] } = currentRegion;
  const [step, setStep] = useState(initialStep);
  const [isInRange, setIsInRange] = useState(
    initialResultData?.is_in_range || false
  );
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [statesPosition, setStatesPosition] = useState<
    { lat: number; lng: number }[] | null
  >(null);
  const [selectedOption, setSelectedOption] = useState<RangeFinder | null>(
    null
  );
  const [selectedMarker, setSelectedMarker] = useState<LatLng | null>(
    initialResultData?.pin_location || null
  );

  useEffect(() => {
    if (initialResultData) {
      setSelectedOption({
        ...selectedOption,
        range: initialResultData?.clue?.range as number,
        id: initialResultData.clue.id as number,
        amount: initialResultData.clue.amount as string,
        type: initialResultData.clue.type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialResultData]);

  const next = () => setStep(step + 1);
  const prev = () => setStep(step - 1);
  const handleSelectOption = (option: RangeFinder) => {
    setSelectedOption(option);
    next();
  };

  const handlePinLocation = (markerPosition: LatLng) => {
    setSelectedMarker(markerPosition);
    next();
  };

  const handleSeeResult = (is_in_range: boolean) => {
    setIsInRange(is_in_range);
    next();
  };

  const BackButton = (props: ButtonProps) => {
    return (
      <BaseButton
        className={classes.backButton}
        onClick={() => {
          prev();
        }}
        {...props}
      >
        <span className={classes.imageWrapper}>
          <img alt="left-arrow" src={LeftArrow} />
        </span>
        <Text fontFamily="inter" size={18} color="#fff">
          Back
        </Text>
      </BaseButton>
    );
  };

  const geocodeStateNames = async () => {
    try {
      const geocodingBaseUrl =
        "https://maps.googleapis.com/maps/api/geocode/json?";

      // Combine state names into a single string separated by |
      const stateNames = states?.join("|");

      const response = await axios.get(
        `${geocodingBaseUrl}address=${stateNames}&key=${googleMapApiKey}`
      );

      if (response.data.status === "OK" && response.data.results.length > 0) {
        let totalLat = 0;
        let totalLng = 0;
        const locations = response.data.results.map(
          (location: any) => location.geometry.location
        );
        setStatesPosition(locations);

        response.data.results.forEach((result: any) => {
          const { location } = result.geometry;
          totalLat += location.lat;
          totalLng += location.lng;
        });

        const averageLat = totalLat / response.data.results.length;
        const averageLng = totalLng / response.data.results.length;
        const center = { lat: averageLat, lng: averageLng };
        setCenter(center);
      }
    } catch (error) {
      console.error("Error geocoding state names:", error);
    }
  };

  useEffect(() => {
    geocodeStateNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, states]);

  const getComponent = () => {
    switch (step) {
      case 2:
        return (
          <RangeInputMap
            BackButton={BackButton}
            handlePinLocation={handlePinLocation}
            isLoaded={isLoaded}
            prev={prev}
            next={next}
            center={center}
            statesPosition={statesPosition}
            selectedOption={selectedOption}
          />
        );
      case 3:
        return (
          <RangeFinderPayment
            BackButton={BackButton}
            selectedOption={selectedOption}
            prev={prev}
            lampId={lampId}
            handleSeeResult={handleSeeResult}
            selectedMarker={selectedMarker}
            refreshCluePurchaseData={refreshCluePurchaseData}
          />
        );
      case 4:
        return (
          <Result
            isLoaded={isLoaded}
            isInRange={isInRange}
            onClose={onClose}
            center={selectedMarker as LatLng}
            selectedOption={selectedOption}
          />
        );
      case 1:
      default:
        return (
          <RangeFinderInfo
            handleSelectOption={handleSelectOption}
            lampData={lampData}
          />
        );
    }
  };

  const getStepInString = () => {
    switch (step) {
      case 2:
        return "two";
      case 3:
        return "three";
      case 4:
        return "four";
      case 5:
        return "five";
      case 1:
      default:
        return "one";
    }
  };

  return (
    <>
      <Modal
        backdrop
        className={cn(classes.modalWrapper, className, [
          classes[getStepInString()],
        ])}
        bodyClassName={classes.modalBody}
        isOpen={isOpen}
        toggle={onClose}
      >
        <ModalHeader
          close={
            <Button
              buttonText="Close"
              rightIcon={<CloseIcon />}
              variant="link"
              onClick={onClose}
            />
          }
        >
          <div className={classes.modalHeaderContent}>
            <img src={rangeFinder} />
            <Text color="#fff" size={40} fontWeight="semibold">
              Range Finder
            </Text>
          </div>
        </ModalHeader>
        <ModalBody className={classes.modalBody}>{getComponent()}</ModalBody>
      </Modal>
    </>
  );
};

export default RangeFinderModal;

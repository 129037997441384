import { useNavigate } from "react-router";
import TGLOGO from "src/assets/images/tgs-button.svg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";

import classes from "./styles.module.scss";

const FreeAccess = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <Typography fontFamily="milonga">Free Access</Typography>
      </div>
      <div className={classes.content}>
        <div className={classes.image}>
          <img src={TGLOGO} alt="logo" />
        </div>
        <div className={classes.action}>
          <Button
            onClick={() => {
              navigate("/user/news");
            }}
            buttonClassName={classes.button}
            buttonText={"ENTER"}
            buttonColor="secondary"
          />
        </div>
      </div>
    </div>
  );
};

export default FreeAccess;

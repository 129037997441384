import { Dispatch } from "redux";
import { apiGetSharePost } from "src/api/sharePost";

import Types from "../types/sharePost";

export const getSharePost =
  (postId: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { postId },
      type: Types.GET_SHARE_POST,
      asyncCall: () => {
        return apiGetSharePost(postId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

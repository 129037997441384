import cx from "classnames";
import React from "react";
import { ClueProps } from "src/pages/Play/Clue/types";
import classes from "./styles.module.scss";

type PuzzleQuestionProps = { clue: ClueProps; isModal?: boolean };

const PuzzleQuestion: React.FC<PuzzleQuestionProps> = ({
  isModal = false,
  clue,
}) => {
  return (
    <div
      className={cx(classes.wrapper, {
        [classes.modalQuestionWrapper]: isModal,
      })}
    >
      <img src={clue.url[0]} alt="puzzle-question" />
    </div>
  );
};

export default PuzzleQuestion;

import { Reducer } from "redux";
import { getMoreEngagements } from "src/utils/reducer/engagement";
import { handleData } from "../middlewares/handleData";
import Types from "../types/engagement";

const initialState = {
  engagements: {
    loading: false,
    data: {},
    error: "",
  },
};

const EngagementReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.GET_ENGAGEMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          engagements: {
            ...prevState.engagements,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          engagements: {
            loading: false,
            error: "",
            data: meta?.payload?.isMore
              ? getMoreEngagements(prevState, payload)
              : payload.data || {},
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          engagements: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });

    default:
      return state;
  }
};

export default EngagementReducer;

import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import TitleIcon from "src/pages/Auth/assets/icon/gametitle_icon.svg";
import classes from "./AuthModal.module.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  privatePath: string;
}

const AuthModal = ({ isOpen, onClose, privatePath }: Props) => {
  const navigate = useNavigate();

  return (
    <Modal
      backdrop
      className={classes.modalWrapper}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      <div className={classes.content}>
        <img src={TitleIcon} alt="icon not found" />
        <h1>Welcome to Treasure Game$</h1>
        <p>
          If you’d like to interact more with our content, please create an
          account to continue.
        </p>
        <div>
          <Button
            buttonText="Create Account"
            buttonColor="secondary"
            onClick={() => {
              navigate(`/auth/access-info?private-path=${privatePath}`);
            }}
          />
          <div>
            Already have an account?{" "}
            <span
              onClick={() => {
                navigate(`/auth/login?private-path=${privatePath}`);
              }}
            >
              Sign In
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;

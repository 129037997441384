import { Reducer } from "redux";
import { handleData } from "../middlewares/handleData";
import Types from "../types/sharePost";

const initialState = {
  sharePost: {
    postId: "",
    loading: false,
    data: {},
    error: "",
  },
};

const SharePostReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.GET_SHARE_POST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,

          sharePost: {
            ...prevState.sharePost,
            data:
              prevState.sharePost.postId === meta.payload?.postId
                ? { ...prevState.sharePost.data }
                : {},
            loading: true,
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          sharePost: {
            loading: false,
            error: "",
            data: payload.data?.post || {},
            postId: meta.payload?.postId,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          sharePost: {
            loading: false,
            data: {},
            error: payload,
            postId: meta.payload?.postId,
          },
        }),
      });

    default:
      return state;
  }
};

export default SharePostReducer;

import { Circle, GoogleMap, Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import { milesToMeters } from "src/utils/utils";
import { LatLng } from "../RangeFinderModal/RangeInputMap";

type Props = {
  center: LatLng;
  containerStyle: React.CSSProperties | undefined;
  range: number;
  isInRange: boolean;
  hideCircle?: boolean;
  icon?: string;
  options?: google.maps.MapOptions;
};
const ResultMap: React.FC<Props> = ({
  center,
  containerStyle,
  range,
  isInRange,
  hideCircle,
  icon = "http://maps.google.com/mapfiles/ms/icons/purple-dot.png",
  options = {},
}) => {
  const [map, setMap] = useState();

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={5} // Initial zoom level
      onLoad={(map: any) => {
        setMap(map);
        if (map) {
          // Calculate the bounds of the circle based on the radius
          const bounds = new window.google.maps.LatLngBounds();
          const circleCenter = new window.google.maps.LatLng(
            center.lat,
            center.lng
          );
          bounds.extend(circleCenter);
          // Calculate the northeast and southwest points of the bounds
          const radiusMeters = range * 1609.34; // Convert miles to meters
          const latLngDelta = radiusMeters / 111000; // Approximate degrees per meter
          const northeast = new window.google.maps.LatLng(
            center.lat + latLngDelta,
            center.lng + latLngDelta
          );
          const southwest = new window.google.maps.LatLng(
            center.lat - latLngDelta,
            center.lng - latLngDelta
          );
          bounds.extend(northeast);
          bounds.extend(southwest);

          // Fit the map to the calculated bounds
          map.fitBounds(bounds);
        }
      }}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        ...options,
      }}
    >
      {map && <Marker position={center} icon={icon}></Marker>}
      {!hideCircle && (
        <Circle
          center={center}
          radius={milesToMeters(range)}
          options={{
            fillColor: isInRange ? "#61B036" : "#F93939",
            strokeWeight: 0,
            fillOpacity: isInRange ? 0.43 : 0.5,
          }}
        />
      )}
    </GoogleMap>
  );
};
export default ResultMap;

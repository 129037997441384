import { toast } from "react-toastify";

export const actionCb =
  (cb?: any, errCb?: any, hideToast?: boolean, errorMsg?: string) =>
  (res: any, err: any) => {
    if (res) {
      if (cb) cb(res);
    } else {
      if (errCb) errCb();
      if (!hideToast) {
        toast.error(errorMsg || err.message || "Something went wrong!");
      }
    }
  };

import cn from "classnames";
import Logo from "src/assets/images/header-logo.png";
import Typography from "src/components/Typography";
import Notification from "src/layout/Notification";
import classes from "./styles.module.scss";

interface Props {
  title?: string | React.ReactNode;
  contentRight?: React.ReactNode;
  className?: string;
}

const Header = ({ title, contentRight, className }: Props) => {
  return (
    <div className={cn(classes.navWrapper, className)}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.headerLogo}>
            <img src={Logo} alt="tg-logo" />
          </div>
          <div>
            <Typography
              fontWeight="semibold"
              variant="p32"
              fontFamily="inter"
              className={classes.title}
            >
              {title}
            </Typography>
          </div>
        </div>
        <div className={classes.actions}>
          {contentRight || <Notification />}
        </div>
      </div>
    </div>
  );
};

export default Header;

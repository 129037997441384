import React from "react";
import CookieConsent from "react-cookie-consent";
import "./Cookie.scss";

const Cookie = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="treasureGamesCookie"
      containerClasses="cookieContainer"
      buttonClasses="cookieBtn"
      contentClasses="cookieText"
      expires={150}
    >
      This website uses cookies to enhance the user experience
    </CookieConsent>
  );
};

export default Cookie;

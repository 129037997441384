import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useReducerData } from "src/store/hooks";
import HeaderDetails from "../SelectRegions/Components/HeaderDetails";
import RegionList from "../SelectRegions/Components/RegionList";
import { SavedRegionProps } from "../SelectRegions/Components/RegionList/types";
import RegionMap from "../SelectRegions/Components/RegionMap";
import classes from "./styles.module.scss";

type Props = {
  fetchSavedRegions: () => void;
};

const SelectRegions: React.FC<Props> = ({ fetchSavedRegions }) => {
  const { pathname } = useLocation();

  const { data: savedRegions } = useReducerData("region", "savedRegionList", {
    data: [],
  });

  const [hoveredRegion, setHoveredRegion] = useState<string | undefined>();
  const [hoveredCard, setHoveredCard] = useState<string | undefined>();
  const [addedRegions, setAddedRegions] = useState<string[]>([]);
  const [isClueExpanded, setIsClueExpanded] = useState(true);

  const isRegionSelectionPage = pathname === "/play/select-region";

  const toggleClueExpand = () => setIsClueExpanded(!isClueExpanded);

  useEffect(() => {
    const savedRegionsIds = savedRegions.map(
      (region: SavedRegionProps) => region.slug
    );
    setAddedRegions([...savedRegionsIds]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRegions]);

  useEffect(() => {
    fetchSavedRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHoveredRegion = (hoveredRegion?: string) => {
    setHoveredRegion(hoveredRegion);
  };

  const handleHoveredCard = (hoveredRegion?: string) => {
    setHoveredCard(hoveredRegion);
  };

  const handleAddRegion = (region: string) => {
    setAddedRegions([...addedRegions, region]);
  };

  const handleRemoveRegion = (region: string) => {
    const filteredRegions = addedRegions.filter((reg) => reg !== region);
    setAddedRegions(filteredRegions);
  };

  const handleAddAllRegion = (region: string[]) => {
    setAddedRegions([...addedRegions, ...region]);
  };
  const handleRemoveAllRegion = () => {
    const savedRegionsIds = savedRegions.map(
      (region: SavedRegionProps) => region.slug
    );
    setAddedRegions(savedRegionsIds);
  };

  return (
    <div className={classes.container}>
      <div
        className={cx(classes.mapWrapper, {
          [classes.expand]: !isClueExpanded,
        })}
      >
        <HeaderDetails />
        <RegionMap
          handleAddRegion={handleAddRegion}
          handleRemoveRegion={handleRemoveRegion}
          currentHoveredRegion={hoveredRegion}
          hoveredCard={hoveredCard}
          regionSelectionFlow={isRegionSelectionPage}
          addedRegions={addedRegions}
          handleHoveredRegion={handleHoveredRegion}
        />
      </div>
      <RegionList
        className={cx(classes.regionList, {
          [classes.collapsed]: !isClueExpanded,
        })}
        handleRemoveAllRegion={handleRemoveAllRegion}
        isExpanded={isClueExpanded}
        toggleExpand={toggleClueExpand}
        handleRemoveRegion={handleRemoveRegion}
        handleAddAllRegion={handleAddAllRegion}
        handleHoveredRegion={handleHoveredCard}
        regionSelectionFlow={isRegionSelectionPage}
        addedRegions={addedRegions}
        handleAddRegion={handleAddRegion}
        hoveredRegion={hoveredRegion}
        testId="select-regions-list"
      />
    </div>
  );
};

export default SelectRegions;

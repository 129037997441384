import cn from "classnames";
import React from "react";
import classes from "./SocialButton.module.scss";

interface Props {
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  color?: "white" | undefined;
  onClick?: () => void;
  className?: string;
  active?: boolean;
}

const SocialButton = ({
  icon,
  title,
  color,
  onClick,
  className,
  active,
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        classes[`color_${color}`],
        {
          pointer: !!onClick,
          [classes.active]: active,
        },
        className
      )}
      onClick={onClick}
    >
      {!!icon && <span className={classes.socialButtonIcon}>{icon}</span>}
      {(!!title || title === 0) && (
        <span className={classes.socialButtonTitle}>{title}</span>
      )}
    </div>
  );
};

export default SocialButton;

/* eslint-disable max-lines */
/* eslint-disable react/no-multi-comp */
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { Formik, FormikHelpers, FormikProps } from "formik";

import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import { HintProps } from "src/api/clue";
import { ReactComponent as CheckIcon } from "src/assets/icons/check-rounded-icon.svg";
import { ReactComponent as DottedCircle } from "src/assets/icons/dotted-circle-icon.svg";
import { ReactComponent as HintBulb } from "src/assets/icons/hint-bulb-icon.svg";
import { ReactComponent as LockedIcon } from "src/assets/icons/locked-icon.svg";
import { ReactComponent as InvalidRetryIcon } from "src/assets/icons/retry-icon.svg";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Text from "src/components/Text";
import Typography from "src/components/Typography";
import { useIsUserProfileFreeTrial } from "src/helpers/hooks/useUserProfile";
import { ProgressIconProps } from "src/pages/Play/Clue/getIcon";
import { AnswerProps, ClueProps } from "src/pages/Play/Clue/types";
import {
  editClueStatus,
  editCluesAnswer,
  postCluesAnswer,
  setClueData,
  setCluePurchaseModalState,
} from "src/store/actions/clue";
import { useStoreActions } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import { checkAllFreeCluesCompleted } from "src/utils/utils";
import classes from "./styles.module.scss";

type Props = {
  [key: string]: string;
};

type ClueInputProps = {
  isLastClue: boolean;
  order: number;
  givenAnswers: { order: number; answer: AnswerProps }[];
  clueId: number | undefined;
  answer: AnswerProps | null;
  currentClue: ClueProps;
  hints: HintProps[];
};

export const getAnswerIcon = ({ status, ...rest }: ProgressIconProps) => {
  switch (status) {
    case "complete":
      return <CheckIcon stroke="0" {...rest} />;
    case "invalid":
      return <InvalidRetryIcon stroke="0" {...rest} />;
    case "incomplete":
    default:
      return <DottedCircle {...rest} />;
  }
};

const ClueInput: React.FC<ClueInputProps> = ({
  order,
  answer,
  clueId,
  currentClue,
  hints,
}) => {
  const actions = useStoreActions({
    postCluesAnswer,
    editCluesAnswer,
    editClueStatus,
    setClueData,
    setCluePurchaseModalState,
  });
  const isFreeTrial = useIsUserProfileFreeTrial();

  const [searchParams, setSearchParams] = useSearchParams();
  const [containerWidth, setContainerWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const { width } = useWindowSize();
  const hint = hints?.find((h) => h?.hint_number === order);

  const answerSchema = Yup.object().shape({
    [`answer-${order}`]: Yup.string(),
  });

  const answerStatuses: { [key in number]: string } = {
    0: "invalid",
    1: "complete",
  };

  const clueDifficulty = Number(currentClue.clue_difficulty) || 0;

  const hintSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Access the DOM element using containerRef
    const containerElement = hintSectionRef.current;

    if (containerElement) {
      // Get the width of the container element using offsetWidth
      const width = containerElement.offsetWidth;
      setContainerWidth(width);
    }
  }, [width, hint]);

  const InputActions = ({
    handleSubmit,
    dirty,
    setFieldValue,
    values,
    answerStatus,
  }: Partial<FormikProps<Props>> & { answerStatus: string }) => {
    return (
      <div className={cx(classes.actionsWrapper)}>
        {answerStatus === "complete" ? (
          <>
            <Typography
              fontWeight="semibold"
              className={cx("mb-0", classes.correctText)}
              variant="p14"
            >
              CORRECT!
            </Typography>
          </>
        ) : (
          <>
            <div
              className={cx(classes.clear, {
                [classes.disable]:
                  answerStatus !== "invalid" &&
                  (!dirty || (values && values[`answer-${order}`] === "")),
              })}
              onClick={() => {
                setFieldValue && setFieldValue(`answer-${order}`, "");
              }}
            >
              <Typography className="mb-0" variant="p14">
                Clear
              </Typography>
            </div>
            <div
              className={cx(classes.buttonWrapper, {
                [classes.disable]:
                  !dirty || (values && values[`answer-${order}`] === ""),
              })}
            >
              <Button
                buttonText={
                  <Typography fontWeight="bold" className="mb-0" variant="p16">
                    Submit
                  </Typography>
                }
                type="submit"
                loading={loading && clueId === currentId}
                onClick={() => handleSubmit && handleSubmit()}
                className={classes.button}
              ></Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const callback = (updatedClues: ClueProps[]) => {
    if (isFreeTrial) {
      const isAllFreeCluesCompleted = checkAllFreeCluesCompleted(
        updatedClues || []
      );
      if (isAllFreeCluesCompleted) {
        searchParams.set("show_upgrade_modal", "true");
        searchParams.set("all_clues_completed", "true");
        setSearchParams(searchParams);
      }
    }
  };

  const handleSubmit = async (
    values: Props,
    { setFieldValue }: FormikHelpers<Props>
  ) => {
    let isCorrectAnswer = false;
    let resp: any;
    setLoading(true);
    setSpinning(false);
    if (!answer) {
      setCurrentId(clueId as number);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resp = await actions.postCluesAnswer(
        clueId,
        values[`answer-${order}`],
        {
          answer_number: order,
        },
        callback
      );
    } else {
      setCurrentId(clueId as number);
      resp = await actions.editCluesAnswer(
        clueId,
        answer.id,
        values[`answer-${order}`],
        { answer_number: order },
        callback
      );
    }
    isCorrectAnswer = resp?.data?.is_correct_answer;

    setLoading(false);
    setSpinning(!isCorrectAnswer);
    setFieldValue(
      `answer-${order}`,
      isCorrectAnswer ? values[`answer-${order}`] : ""
    );
  };

  return (
    <div className={classes.inputWrapper}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={answerSchema}
        initialValues={{
          [`answer-${order}`]: answer?.answer || "",
        }}
        enableReinitialize
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          dirty,
          setFieldValue,
        }: FormikProps<Props>) => (
          <FormGroup className={cx(classes.formGroup, "mb-0")}>
            {getAnswerIcon({
              status:
                answerStatuses[answer?.is_correct as number] ??
                ("incomplete" as string),
              height: 36,
              width: 36,
              className: cx(
                {
                  [classes.activeCircle]:
                    (answerStatuses[answer?.is_correct as number] ??
                      ("incomplete" as string) === "incomplete") &&
                    dirty &&
                    values[`answer-${order}`] !== "",
                },
                {
                  [classes.retrySpinIcon]: answer && spinning,
                }
              ),
            })}
            <Input
              inputGroupClassName={cx(classes.inputGroupStyles, {
                [classes.inputGroupActive]:
                  dirty && values[`answer-${order}`] !== "",
              })}
              rows={1}
              type="textarea"
              autoComplete="off"
              inputClassName={classes.inputStyle}
              iconClassName={classes.actionStyling}
              value={values[`answer-${order}`]}
              placeholder={
                answer
                  ? answer?.is_correct
                    ? ""
                    : "Try Again"
                  : "Type answer here..."
              }
              onChange={(event) => {
                event.target.style.height = "inherit";
                event.target.style.height = `${Math.max(
                  event.target.scrollHeight
                )}px`;
                handleChange(event);
              }}
              onBlur={handleBlur}
              error={errors[`answer-${order}`]}
              touched={touched[`answer-${order}`]}
              rightIcon={
                <InputActions
                  dirty={dirty}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  values={values}
                  answerStatus={
                    answerStatuses[answer?.is_correct as number] ??
                    ("incomplete" as string)
                  }
                />
              }
              name={`answer-${order}`}
            />
          </FormGroup>
        )}
      </Formik>
      {clueDifficulty > 3 &&
        (hint?.hint ? (
          <div className={classes.hintSectionWrapper}>
            <div></div>
            <div className={classes.hintSection} ref={hintSectionRef}>
              <Text
                className={classes.text}
                size={20}
                fontWeight="medium"
                color="#8053EA"
              >
                <HintBulb height={19} /> Hint:
              </Text>{" "}
              <ShowMoreText
                className={classes.hint}
                lines={1}
                width={containerWidth - 180}
                more="See More"
                less="See Less"
              >
                {hint?.hint || ""}
              </ShowMoreText>
            </div>
          </div>
        ) : (
          <div className={cx(classes.purchaseWrapper, "mt-3")}>
            <div></div>
            <Button
              buttonText={
                <Text
                  color="#8053EA"
                  size={20}
                  fontFamily="inter"
                  fontWeight="medium"
                >
                  Purchase a hint
                </Text>
              }
              variant="outline"
              // loading={loading && clueId === currentId}
              onClick={() => {
                if (isFreeTrial) {
                  searchParams.set("show_upgrade_modal", "true");
                  setSearchParams(searchParams);
                  return;
                }
                actions.setCluePurchaseModalState(true, order.toString());
              }}
              rightIcon={
                isFreeTrial ? (
                  <LockedIcon height={24} width={24} />
                ) : (
                  <FontAwesomeIcon icon={faLightbulb} />
                )
              }
              buttonClassName={classes.purchase}
            ></Button>
          </div>
        ))}
    </div>
  );
};

export default ClueInput;

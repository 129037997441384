import * as authActions from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {
    deleteAccountLoading: useReducerData(
      "auth",
      "deleteAccount.loading",
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
  });
};

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { SeasonProps } from "src/api/clue";
import { CountDownProps } from "src/pages/Subscriptions/types";
import { useReducerData } from "src/store/hooks";
import { useUserData } from "./useData";

const getPositiveValues = (value: number) => {
  return Math.max(value, 0);
};

export const useSidebar = () => {
  const isSidebarOpen = localStorage.getItem("isSidebarOpen");

  const isExpanded = isSidebarOpen === "true";

  const [expand, setExpand] = useState(isExpanded ?? true);

  useEffect(() => {
    if (!isSidebarOpen) {
      localStorage.setItem("isSidebarOpen", "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => {
    if (expand) {
      localStorage.setItem("isSidebarOpen", "false");
    } else {
      localStorage.setItem("isSidebarOpen", "true");
    }
    setExpand(!expand);
  };
  return { expand, toggle };
};

export const useSeason = () => {
  const { data: seasons }: { data: SeasonProps[] } = useReducerData(
    "clue",
    "seasonList",
    { data: [], loading: false }
  );
  const [currentSeason, setCurrentSeason] = useState<SeasonProps | null>(null);

  const today = moment();

  useEffect(() => {
    if (seasons.length) {
      seasons.forEach((season) => {
        if (
          today.isBetween(
            moment(season.start_date, "YYYY-MM-DD"),
            moment(season.end_date, "YYYY-MM-DD"),
            null,
            "[]"
          )
        ) {
          setCurrentSeason(season);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasons]);

  return { currentSeason, seasons };
};

export const useSeasonCountdown = () => {
  const initialDuration: CountDownProps = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  const [duration, setDuration] = useState<CountDownProps>(initialDuration);

  const { data: seasons }: { data: SeasonProps[] } = useReducerData(
    "clue",
    "seasonList",
    { data: [], loading: false }
  );

  const today = moment();

  useEffect(() => {
    if (seasons.length) {
      seasons.forEach((season) => {
        if (
          today.isBetween(
            moment(season.start_date, "YYYY-MM-DD"),
            moment(season.end_date, "YYYY-MM-DD"),
            "days",
            "[]"
          )
        ) {
          const d = moment.duration(
            moment(season.end_date, "YYYY-MM-DD").endOf("day").diff(today)
          );
          const days = getPositiveValues(Math.floor(d.asDays()));
          const hours = getPositiveValues(d.hours());
          const minutes = getPositiveValues(d.minutes());
          setDuration({
            days,
            hours,
            minutes,
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasons]);

  return { duration };
};

const LAUNCH_DATE = moment("2024-01-01T00:00:00-05:00");

function calculateCountdown(closeTimer: () => void) {
  // Set the target date to New Year's Day in New York timezone
  // Get the current date and time
  const currentDate = moment();
  // Calculate the duration between the target date and the current date
  const duration = moment.duration(LAUNCH_DATE.diff(currentDate));
  // Format the duration as days, hours, minutes, and seconds
  const days = getPositiveValues(Math.floor(duration.asDays()));
  const hours = getPositiveValues(duration.hours());
  const minutes = getPositiveValues(duration.minutes());
  const seconds = getPositiveValues(duration.seconds());

  if (duration.asSeconds() <= 0) {
    closeTimer();
  }

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export const usePlayCountdown = () => {
  const [durations, setDurations] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const {
    playCountdown: {
      data: { visible },
    },
  } = useUserData();

  const isPlayCountdownModalVisible = useRef(visible);

  useEffect(() => {
    const interval = setInterval(() => {
      const closeTimer = () => {
        isPlayCountdownModalVisible.current = false;
      };
      setDurations(calculateCountdown(closeTimer));
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return {
    durations,
    isPlayCountdownModalVisible: isPlayCountdownModalVisible.current,
  };
};

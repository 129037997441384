import cx from "classnames";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import Button from "src/components/Button";
import Spinner from "src/components/Spinner";
import Text from "src/components/Text";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import PlanCard from "src/pages/Subscription/Plans/Components/PlanCard";
import { PlanProps } from "src/pages/Subscription/Plans/Components/PlanCard/types";

import { getSubscriptionPlans } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";

import UnsubscribeModal from "../unsubscribeModal";
import classes from "./SelectSubscription.module.scss";

type Props = {
  handleStep: (step: number) => void;
  handleSelectedPlan: (plan: PlanProps) => void;
  onClose: () => void;
};

const SelectSubscription: React.FC<Props> = ({
  handleSelectedPlan,
  handleStep,
  onClose,
}) => {
  const actions = useStoreActions({
    getSubscriptionPlans,
  });
  const userProfile = useUserProfile() as {
    data: { plans: PlanProps[] };
  };

  const {
    data: { plans },
    loading,
  } = useReducerData("auth", "subscriptionPlans", {}) as {
    data: { plans: PlanProps[] };
    loading: false;
  };

  const activeSubscription = get(userProfile, "active_subscription", false);
  const planId = activeSubscription
    ? get(userProfile, "subscription.plan_id", null)
    : null;
  const isCancelled = get(userProfile, "subscription.is_cancelled", 0);
  const subscriptionStatus = get(userProfile, "subscription.status");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanProps>();
  const canUnsubscribe =
    planId && !isCancelled && subscriptionStatus === "active";

  useEffect(() => {
    actions.getSubscriptionPlans();
    setSelectedPlan(get(userProfile, "subscription"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubscriptionSelection = (plan: PlanProps) => {
    if (planId === plan.id) {
      return;
    }
    setSelectedPlan({ ...plan, plan_id: plan.id });
  };
  return (
    <>
      {isOpen ? (
        <UnsubscribeModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onSuccess={onClose}
        />
      ) : null}
      <div className={classes.wrapper}>
        <Text
          color="#fff"
          className="text-center mb-3"
          size={18}
          fontWeight="semibold"
        >
          Select your Subscription:
        </Text>
        <div className={classes.subscriptionCards}>
          {loading ? (
            <Spinner />
          ) : (
            <div className={classes.cardsWrapper}>
              {plans?.map((plan) => {
                return (
                  <div key={plan.id}>
                    <PlanCard
                      wrapperClassName={cx({
                        [classes.selectedCard]:
                          selectedPlan?.plan_id === plan.id,
                      })}
                      plan={plan}
                      handleSubscriptionSelection={handleSubscriptionSelection}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className={cx(classes.unsubscribe, {
            [classes.right]: !canUnsubscribe,
          })}
        >
          {canUnsubscribe && (
            <Button
              buttonText={
                <Text color="#fff" size={18}>
                  Unsubscribe
                </Text>
              }
              onClick={() => setIsOpen(true)}
              buttonColor="secondary"
              variant="link"
              buttonClassName={classes.cancel}
            />
          )}
          <Button
            buttonText={
              <Text
                color="#fff"
                fontWeight="bold"
                fontFamily="impact"
                size={20}
              >
                Update
              </Text>
            }
            buttonColor="secondary"
            onClick={() => {
              if (selectedPlan) {
                handleSelectedPlan(selectedPlan);
                handleStep(2);
              } else return;
            }}
            type="button"
            buttonClassName={classes.confirm}
          />
        </div>
      </div>
    </>
  );
};

export default SelectSubscription;

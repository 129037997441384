import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { ReactComponent as CloseSvg } from "src/assets/icons/close-icon.svg";
import { ReactComponent as SearchSvg } from "src/assets/icons/search-icon.svg";
import Input from "src/components/Input";
import classes from "./SearchBox.module.scss";
import { useSearchClickOutside } from "./utils";

interface Props {
  onSearchChanged?: (val: string) => void;
  defaultActive?: boolean;
  defaultKeyword?: string;
}

const SearchBox = ({
  onSearchChanged,
  defaultActive,
  defaultKeyword,
}: Props) => {
  const { pathname } = useLocation();
  const activeSearchRef = useRef(null);
  const inputRef = useRef(null);
  const [active, setActive] = useState(defaultActive || false);
  const [keyword, setKeyword] = useState(defaultKeyword || "");
  useSearchClickOutside(activeSearchRef, setActive);
  const handleFocusInput = () => {
    if (inputRef && inputRef.current) {
      const inputRefCurrent: any = inputRef.current;
      inputRefCurrent.focus();
    }
  };

  useEffect(() => {
    setKeyword("");
    if (defaultActive) {
      handleFocusInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    if (
      (defaultActive === true || defaultActive === false) &&
      defaultActive !== active
    ) {
      setActive(defaultActive);
      setTimeout(() => {
        if (defaultActive) {
          handleFocusInput();
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultActive]);
  useEffect(() => {
    if (!!defaultKeyword && defaultKeyword !== keyword) {
      setKeyword(String(defaultKeyword));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultKeyword]);

  return (
    <div className={cn(classes.wrapper, "search-box-wrapper")}>
      {active ? (
        <div className={classes.searchActive} ref={activeSearchRef}>
          <Input
            value={keyword}
            placeholder=""
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (onSearchChanged) onSearchChanged(keyword);
              }
            }}
            innerRef={inputRef}
          />
          {!!keyword && (
            <span
              className={classes.closeIcon}
              onClick={() => {
                setKeyword("");
                if (onSearchChanged) onSearchChanged("");
              }}
            >
              <CloseSvg />
            </span>
          )}
          <span
            className={classes.searchIcon}
            onClick={() => {
              if (onSearchChanged) onSearchChanged(keyword);
            }}
          >
            <SearchSvg />
          </span>
        </div>
      ) : (
        <div
          className={cn(classes.searchInactive, "search-inactive")}
          onClick={() => {
            setTimeout(() => {
              const currentInput = inputRef?.current as any;
              if (currentInput?.focus) {
                currentInput.focus();
              }
            }, 100);
          }}
        >
          <span>Search</span>
          <SearchSvg />
        </div>
      )}
    </div>
  );
};

export default SearchBox;

import React from "react";
import Button from "src/components/Button";
import Spinner from "src/components/Spinner";
import Text from "src/components/Text";
import ResultMap from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/components/ResultMap";
import { RangeFinder } from "src/types/clue";
import { LatLng } from "../RangeInputMap";
import classes from "./Result.module.scss";

type Props = {
  isLoaded: boolean;
  onClose: () => void;
  center: LatLng;
  isInRange: boolean;
  selectedOption: RangeFinder | null;
};

const Result: React.FC<Props> = ({
  isLoaded = false,
  onClose,
  center,
  isInRange = false,
  selectedOption,
}) => {
  const containerStyle = {
    width: "100%",
    height: "500px",
    borderRadius: "8px",
  };

  const range = selectedOption?.range ?? 100;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Text fontFamily="inter" size={24} fontWeight="semibold" color="#fff">
          {isInRange ? "Success!" : "Try Again"}
        </Text>
        <Text
          fontFamily="inter"
          size={16}
          style={{ lineHeight: "normal" }}
          color="#fff"
        >
          {isInRange
            ? `You did it! You are within ${
                selectedOption?.range || "-"
              } miles of the lamp perimeter. Congrats!`
            : `Unfortunately your pin is not within ${
                selectedOption?.range || "-"
              } miles from the Outer Zone. Try again soon!`}
        </Text>
      </div>
      <div className={classes.mapWrapper}>
        {isLoaded ? (
          <ResultMap
            center={center}
            containerStyle={containerStyle}
            isInRange={isInRange}
            range={range}
          />
        ) : (
          <Spinner />
        )}
      </div>

      <div className={classes.footer}>
        <Button
          buttonClassName={classes.proceedButton}
          onClick={() => {
            onClose();
          }}
          buttonText={"Done"}
        />
      </div>
    </div>
  );
};

export default Result;

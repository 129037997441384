import cn from "classnames";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Modal, { ModalBody, ModalHeader } from "src/components/Modal";
import classes from "./ShareModal.module.scss";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  shareLink: string;
  className?: string;
  isFree?: boolean;
}

const ShareModal = ({
  isOpen,
  onClose,
  shareLink,
  className,
  isFree,
}: ModalProps) => {
  const resetState = () => {};

  useEffect(() => {
    if (isOpen) {
      resetState();
    }
  }, [isOpen]);

  return (
    <Modal
      backdrop
      className={cn(
        classes.modalWrapper,
        {
          [classes.isFree]: isFree,
        },
        className
      )}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
      hasHeader
      color="primary"
    >
      <ModalHeader
        toggle={onClose}
        close={<CloseIcon onClick={onClose} className="modal-close-btn" />}
      >
        Share
      </ModalHeader>
      <ModalBody>
        <p>Copy link to share through social media or a private message</p>
        <div>
          <Input value={shareLink} readOnly />
          <Button
            buttonText="Copy Link"
            buttonColor="purple"
            size="sm"
            onClick={() => {
              navigator.clipboard.writeText(shareLink);
              toast.success(`Link copied successfully`, {
                position: "bottom-center",
                className: cn({
                  "toast-free": isFree,
                }),
              });
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;

import cx from "classnames";
import React from "react";
import Question from "src/pages/Play/Clue/Components/ClueDashboard/Clues/Question";
import { ClueProps } from "src/pages/Play/Clue/types";
import ClueHeader from "../ClueHeader";
import classes from "./styles.module.scss";

type ClueQuestionProps = {
  clue: ClueProps | null;
  allFound: boolean;
};

const ClueQuestion: React.FC<ClueQuestionProps> = ({ clue, allFound }) => {
  return (
    <div
      className={cx(classes.questionsWrapper, { [classes.disable]: allFound })}
    >
      <ClueHeader clue={clue} />
      <Question />
    </div>
  );
};

export default ClueQuestion;

import { useNavigate, useParams } from "react-router";
import Spinner from "src/components/Spinner";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import {
  TREASURE_GAME_USERNAME,
  UserDetailTab,
} from "src/pages/Watch/constants";
import { NormalTab } from "src/types";
import { MediaInfo } from "src/types/media";
import { getBasicMediaInfo } from "src/utils/media";
import classes from "./Watch.module.scss";
import ChannelBlock from "./components/ChannelBlock";
import ChannelInfo from "./components/ChannelInfo";
import { useUserDetailsData } from "./selectorData";

interface Props {
  tab?: string;
  tabs?: NormalTab[];
  onTabChanged?: (tTab: string) => void;
}

const UserDetails = ({ tab, onTabChanged, tabs }: Props) => {
  const isRepostsTab = tab === UserDetailTab.reposts;
  const {
    watchUser,
    watchUserLoading,
    userFeed,
    userFeedLoading,
    userRepostFeed,
    userRepostFeedLoading,
  } = useUserDetailsData();
  const navigate = useNavigate();
  const matchParams = useParams();
  const currentPath = useCurrentPath();
  const isUserDetailsPage1 = currentPath === "/watch/user-profile/:username";
  const postDetailPath = String(currentPath || "")
    .replace("/:userId", "")
    .replace("/:name", "");
  const medias = ((isRepostsTab ? userRepostFeed : userFeed)?.items || []).map(
    (feedItem: MediaInfo) => {
      return {
        ...getBasicMediaInfo(feedItem),
        onClick: postDetailPath
          ? () => {
              const newUrl = postDetailPath
                .replace(":id", String(feedItem.id))
                .replace(":channelSlug", String(matchParams.channelSlug))
                .replace(":subChannelSlug", String(matchParams.subChannelSlug))
                .replace(
                  ":subChannelTitle",
                  String(matchParams.subChannelTitle)
                );
              navigate(
                isUserDetailsPage1
                  ? `/watch/treasure-games/playing-media/${String(feedItem.id)}`
                  : newUrl
              );
            }
          : undefined,
      };
    }
  );
  const feedLoading = isRepostsTab ? userRepostFeedLoading : userFeedLoading;

  return (
    <div className={classes.userDetailsWrapper}>
      {watchUserLoading && <Spinner wholePage />}
      <ChannelInfo
        userProfile={watchUser}
        tabs={tabs}
        tab={tab}
        onTabChanged={onTabChanged}
        loading={
          matchParams.username === TREASURE_GAME_USERNAME && userFeedLoading
        }
      />
      <ChannelBlock
        title={""}
        medias={medias}
        onClick={() => {
          navigate("/watch");
        }}
        hideButton
        paddingBottom
        loading={!watchUserLoading && feedLoading}
        loadingBottom={
          !!(isRepostsTab ? userRepostFeed : userFeed)?.meta?.next_cursor
        }
      />
    </div>
  );
};

export default UserDetails;

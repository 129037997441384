import get from "lodash/get";
import { matchRoutes, useLocation } from "react-router-dom";
import {
  announcementRoutes,
  engagementRoutes,
  prizesRoutes,
  searchRoutes,
  watchRoutes,
} from "src/routes";

const getCorrectRoutes = (tRoutes: Record<string, any>[], prefix: string) => {
  return (tRoutes || []).map((r, index) => ({
    ...r,
    path: index > 0 ? `/${prefix}${r.path}` : r.path,
  }));
};

const useCurrentPath = () => {
  const location = useLocation();
  // need to push all routes here
  const tWatchRoutes = getCorrectRoutes(watchRoutes, "watch");
  const tEngagementRoutes = getCorrectRoutes(engagementRoutes, "engagement");
  const tAnnouncementRoutes = getCorrectRoutes(
    announcementRoutes,
    "announcements"
  );
  const tPrizeRoutes = getCorrectRoutes(prizesRoutes, "prizes");
  const tSearchRoutes = getCorrectRoutes(searchRoutes, "search");
  const routeObj = matchRoutes(
    [
      ...tWatchRoutes,
      ...tEngagementRoutes,
      ...tAnnouncementRoutes,
      ...tPrizeRoutes,
      ...tSearchRoutes,
    ],
    location
  );

  return get(routeObj, "[0].route.path") || "";
};

export default useCurrentPath;

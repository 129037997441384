import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import Spinner from "src/components/Spinner";
import WarningModal from "src/components/WarningModal";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import { RepostAction } from "src/pages/Watch/constants";
import { MediaInfo } from "src/types/media";
import { actionCb } from "src/utils/action";
import { getBasicMediaInfo } from "src/utils/media";
import classes from "./Watch.module.scss";
import ChannelBlock from "./components/ChannelBlock";
import MediaDetail from "./components/MediaDetail";
import { useActions, usePlayingMediaData } from "./selectorData";

interface Props {
  otherVideoTitle?: string;
}

let tempFixedVideo = false;

const PlayingMedia = ({ otherVideoTitle }: Props) => {
  const { reportMedia, syncSetMediaDetails, likeUnlikePost, repostPost } =
    useActions();
  const {
    mediaDetail,
    mediaRelated,
    reportMediaLoading,
    mediaDetailLoading,
    mediaRelatedLoading,
  } = usePlayingMediaData();
  const [repostAction, setRepostAction] = useState<string>("");
  const [fixedVideo, setFixedVideo] = useState(false);
  const [repostLoading, setRepostLoading] = useState(false);
  const navigate = useNavigate();
  const matchParams = useParams();
  const currentPath = useCurrentPath();
  const mediaId = String(matchParams?.id || "");
  const isReposted = !!mediaDetail.has_reposted;
  const isRepostActionRemove = repostAction === RepostAction.remove;
  const { updateChannelFollowStatus, getChannelFollowers } = useActions();
  const followsCount = mediaDetail?.author?.follows_count || 0;
  const handleLoveChanged = (val: boolean) => {
    syncSetMediaDetails({
      has_liked: val,
      likes_count: mediaDetail.likes_count + (val ? 1 : -1),
    });
    likeUnlikePost(mediaId, val ? "like" : "unlike");
  };
  const handleRepost = () => {
    setRepostLoading(true);
    repostPost(
      mediaId,
      actionCb(
        () => {
          syncSetMediaDetails({
            has_reposted: repostAction === RepostAction.add,
          });
          setRepostLoading(false);
          setRepostAction("");
          toast.success(
            `${
              repostAction === RepostAction.remove
                ? "Media removed reposted"
                : "Media reposted"
            } successfully!`,
            {
              position: "bottom-center",
            }
          );
        },
        () => {
          setRepostLoading(false);
        },
        false,
        `${
          repostAction === RepostAction.remove ? "Remove Repost" : "Repost"
        } media failed. Please try again!`
      )
    );
  };

  useEffect(() => {
    tempFixedVideo = false;
    const handleScroll = (event: any) => {
      const tScrollTo = event.currentTarget.scrollTop;
      const screenHeight = window.innerHeight;
      if (tScrollTo >= screenHeight - 315) {
        if (!tempFixedVideo) {
          tempFixedVideo = true;
          setFixedVideo(tempFixedVideo);
        }
      } else {
        if (tempFixedVideo) {
          tempFixedVideo = false;
          setFixedVideo(tempFixedVideo);
        }
      }
    };
    const el = document.getElementById("watch-container");
    if (el) {
      el.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (el) {
        el.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleFollow = (currentState: boolean) => {
    updateChannelFollowStatus(
      mediaDetail?.user_id as number,
      !currentState,
      (res: any) => {
        if (res.status) {
          if (!currentState) {
            syncSetMediaDetails({
              author: {
                ...mediaDetail?.author,
                follows_count: followsCount + 1,
              },
            });
          } else {
            syncSetMediaDetails({
              author: {
                ...mediaDetail?.author,
                follows_count: followsCount - 1,
              },
            });
          }
          mediaDetail?.user_id && getChannelFollowers(mediaDetail?.user_id);
        }
      }
    );
  };

  return (
    <>
      <div className={classes.mediaDetailsWrapper}>
        {mediaDetailLoading && <Spinner wholePage />}
        <MediaDetail
          fixedVideo={fixedVideo}
          onCloseFixed={() => {
            setFixedVideo(false);
          }}
          type={mediaDetail?.media_type}
          data={mediaDetail}
          onReportMedia={(reason: string, comment: string, cb: () => void) => {
            reportMedia(mediaId, { reason, comment }, actionCb(cb));
          }}
          reportLoading={reportMediaLoading}
          onLoveChanged={handleLoveChanged}
          onRepost={() => {
            setRepostAction(
              isReposted ? RepostAction.remove : RepostAction.add
            );
          }}
          isReposted={isReposted}
          onFollow={(currentState) => {
            handleFollow(currentState);
            return !currentState;
          }}
          followsCount={followsCount}
        />
        <ChannelBlock
          title={otherVideoTitle || "Other Videos"}
          medias={(mediaRelated || []).map((m: MediaInfo) => {
            const newPath = String(currentPath)
              .replace(":id", String(m.id))
              .replace(":channelSlug", String(matchParams.channelSlug))
              .replace(":subChannelSlug", String(matchParams.subChannelSlug))
              .replace(":subChannelTitle", String(matchParams.subChannelTitle));
            return {
              ...getBasicMediaInfo(m),
              onClick: () => {
                navigate(newPath);
              },
            };
          })}
          onClick={() => {
            navigate("/watch");
          }}
          hideButton
          loading={mediaRelatedLoading}
          paddingBottom
        />
      </div>
      <WarningModal
        isOpen={!!repostAction}
        onClose={() => {
          setRepostAction("");
        }}
        onSubmit={handleRepost}
        cancelText="Cancel"
        submitText={isRepostActionRemove ? "Remove" : "Repost"}
        title={isRepostActionRemove ? "Remove Repost" : "Confirm Repost"}
        className={classes.reportModalWrapper}
        submitButonProps={{
          loading: repostLoading,
        }}
        cancelButonProps={{
          disabled: repostLoading,
        }}
      >
        <span>
          {isRepostActionRemove ? (
            <>
              Are you sure you want to remove the repost {mediaDetail?.title}{" "}
              from your account? Please confirm below.
            </>
          ) : (
            <>
              Are you sure you want to repost {mediaDetail?.title} to your
              account? This content will be visible in the Reposts section of
              your channel. Please confirm your choice below.
            </>
          )}
        </span>
      </WarningModal>
    </>
  );
};

export default PlayingMedia;

import cn from "classnames";
import omit from "lodash/omit";
import { useEffect, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import { ReactComponent as PauseIcon } from "src/assets/icons/controls/pause-icon.svg";
import { VideoSeekAction } from "src/types/media";
import Control from "./Control";
import classes from "./PreviewVideo.module.scss";

interface Props {
  source: string;
  simple?: boolean;
  videoClassName?: string;
  hideFullscreen?: boolean;
  defaultPlaying?: boolean;
  emdOverlay?: boolean;
  defaultMuted?: boolean;
  forcePlay?: boolean | undefined;
}

let timeoutRef: any = null;

const PreviewVideo = ({
  source,
  videoClassName,
  hideFullscreen,
  defaultPlaying = false,
  emdOverlay,
  defaultMuted = false,
  forcePlay,
}: Props) => {
  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const videoRefCurrent = videoPlayerRef.current as any;
  const wrapperRef = useRef(null);
  const wrapperRefCurrent = wrapperRef.current as any;
  const [videoObj, setVideoObj] = useState<any>({
    playing: defaultPlaying,
    playsinline: true,
    muted: defaultMuted,
    volume: 0.5,
    played: 0,
    loaded: 0,
    playedPercent: 0,
  });
  const [ended, setEnded] = useState(false);

  const duration = videoRefCurrent
    ? parseInt(String(videoRefCurrent.getDuration() || "0"), 10)
    : 0;
  const { playing, played, volume, muted, loaded, playedPercent } = videoObj;

  const handlePlayPause = () => {
    if (!videoObj.playing) {
      setEnded(false);
    }
    setVideoObj({
      ...videoObj,
      playing: !videoObj.playing,
    });
  };
  const handleEnded = () => {
    setVideoObj({
      ...videoObj,
      playing: false,
    });
    setEnded(true);
  };
  const handleMute = () => {
    setVideoObj({
      ...videoObj,
      muted: !videoObj.muted,
      volume: videoObj.muted ? 0.5 : 0,
    });
  };
  const handleSeek = (value: number) => {
    setVideoObj({
      ...videoObj,
      played: value,
    });
    if (videoRefCurrent) videoRefCurrent.seekTo(value);
  };
  const handleSeek10 = (action: VideoSeekAction) => () => {
    const newPlayed =
      action === "prev" ? videoObj.played - 10 : videoObj.played + 10;
    const appliedPlayed =
      newPlayed < 0 ? 0 : newPlayed > duration ? duration : newPlayed;
    setVideoObj({
      ...videoObj,
      played: appliedPlayed,
    });
    if (videoRefCurrent) videoRefCurrent.seekTo(appliedPlayed);
  };
  const handleProgress = (videoState: any) => {
    setVideoObj({
      ...videoObj,
      played: videoState.playedSeconds,
      loaded: videoState.loaded,
      playedPercent: videoState.played,
    });
  };
  const handleVolumeChanged = (value: number) => {
    setVideoObj({
      ...videoObj,
      volume: value,
      muted: value === 0,
    });
  };
  const handleFullscreen = () => {
    // eslint-disable-next-line react/no-find-dom-node
    screenfull.toggle(findDOMNode(wrapperRefCurrent) as any);
  };

  useEffect(() => {
    timeoutRef = setTimeout(() => {
      if (controlRef && controlRef.current) {
        const tCurrent = controlRef.current as any;
        tCurrent.style.display = "none";
      }
    }, 5000);

    return () => {
      if (timeoutRef) {
        timeoutRef = null;
        clearTimeout(timeoutRef);
      }
    };
  }, []);
  useEffect(() => {
    if (
      (forcePlay === false || forcePlay === true) &&
      forcePlay !== videoObj.playing
    ) {
      handlePlayPause();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forcePlay]);

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <ReactPlayer
        ref={videoPlayerRef}
        url={source}
        width="100%"
        height="100%"
        // controls
        onEnded={handleEnded}
        onProgress={handleProgress}
        {...omit(videoObj, ["playedPercent"])}
        // light={
        //   hover === undefined && (
        //     <img
        //       src={thumbnail || "https://placehold.co/1000x1000/EEE/31343C"}
        //     />
        //   )
        // }
        className={cn(videoClassName, classes.videoWrapper)}
      />
      <Control
        playing={playing}
        onPlayPause={handlePlayPause}
        onMute={handleMute}
        onSeek={handleSeek}
        played={played}
        duration={duration}
        volume={volume}
        onVolumeChanged={handleVolumeChanged}
        muted={muted}
        onFullscreen={handleFullscreen}
        loaded={loaded}
        playedPercent={playedPercent}
        hideFullscreen={hideFullscreen}
        onSeekBack10={handleSeek10("prev")}
        controlRef={controlRef}
      />
      {ended && emdOverlay && (
        <div className={classes.endedOverlay}>
          <span
            onClick={() => {
              setEnded(false);
              handlePlayPause();
            }}
          >
            <PauseIcon />
          </span>
        </div>
      )}
    </div>
  );
};

export default PreviewVideo;

import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import WishLampRegion from "src/pages/Play/assets/wish-lamp-region.png";
import classes from "./styles.module.scss";

const PlayCta = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.card}>
      <div className={classes.ctaWrapper}>
        <div className={classes.image}>
          <img src={WishLampRegion} alt="wish-lamp" />
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            buttonClassName={classes.button}
            buttonText={"Play"}
            buttonColor="secondary"
            onClick={() => navigate("/play")}
          />
        </div>
      </div>
    </div>
  );
};

export default PlayCta;

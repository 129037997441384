import cx from "classnames";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as LockedIcon } from "src/assets/icons/locked-icon.svg";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Typography from "src/components/Typography";
import { getProgressIcon, getQuestionIcon } from "src/pages/Play/Clue/getIcon";
import { ClueProps } from "src/pages/Play/Clue/types";
import { setClueData, setClueModalState } from "src/store/actions/clue";
import { useStoreActions } from "src/store/hooks";
import { isClueCompleted } from "src/utils/utils";
import classes from "./styles.module.scss";

type ClueCardProps = {
  clue: ClueProps;
  index: number;
  handleClueUpdateForCard: (clue: ClueProps) => void;
};

const ClueCard: React.FC<ClueCardProps> = ({
  clue,
  handleClueUpdateForCard,
  index = 0,
}) => {
  const actions = useStoreActions({
    setClueData,
    setClueModalState,
  });
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isCompleted = isClueCompleted(clue);

  const handleClueExpanded = async () => {
    setLoading(true);
    await actions.setClueData(clue);
    await handleClueUpdateForCard(clue);
    actions.setClueModalState(true, clue);
    setLoading(false);
  };

  const renderCompleted = (
    <div className={classes.completedCard}>
      <div className={classes.answer}>
        <Typography
          fontWeight="medium"
          variant="p12"
          className={cx("mb-0", classes.title)}
        >
          Answer
        </Typography>
        {clue?.answers?.map((data, index) => {
          return (
            <Typography
              key={index}
              fontWeight="medium"
              variant="p16"
              className={cx("mb-0")}
            >
              {data?.answer?.answer}
            </Typography>
          );
        })}
      </div>
      <div className={classes.date}>
        <Typography fontWeight="medium" variant="p12" className={cx("mb-0")}>
          Received on: {clue?.received_date}
        </Typography>
      </div>
    </div>
  );

  const showThumbnail = ({ type, clue }: { type: string; clue: ClueProps }) => {
    if (clue.answer_status === "locked") {
      return <LockedIcon />;
    }
    switch (type) {
      case "video":
      case "video-upload":
        return clue?.media?.[0]?.thumbnail ? (
          <div className={classes.thumbnailWrapper}>
            <img
              className="object-fit-contain"
              src={clue?.media?.[0]?.thumbnail_url}
              alt="thumbnail"
            />
          </div>
        ) : (
          getQuestionIcon({ type: clue?.field_type?.slug || "" })
        );
      default:
        return getQuestionIcon({ type: clue?.field_type?.slug || "" });
    }
  };

  const getCardContent = () => {
    switch (clue.answer_status) {
      case "complete":
        return renderCompleted;
      case "invalid":
      case "viewed":
        return (
          <div className={classes.skipCard}>
            <div className={classes.skipImg}>
              <div className={classes.cluImg}>
                {showThumbnail({
                  type: clue?.field_type?.slug || "",
                  clue: clue,
                })}
              </div>
            </div>
            <Button
              className={classes.button}
              onClick={handleClueExpanded}
              loading={loading}
              buttonText={
                <Typography
                  className={cx("mb-0")}
                  variant="p16"
                  fontWeight="bold"
                >
                  View Clue
                </Typography>
              }
            />
          </div>
        );
      case "active": // ?NOTE : need to update this as well for grid view
        return (
          <>
            {isCompleted ? (
              renderCompleted
            ) : (
              <div className={classes.activeCard}>
                <div>
                  {showThumbnail({
                    type: clue?.field_type?.slug || "",
                    clue: clue,
                  })}
                </div>
                <Button
                  onClick={handleClueExpanded}
                  className={classes.button}
                  loading={loading}
                  buttonText={
                    <Typography
                      className={cx("mb-0")}
                      variant="p16"
                      fontWeight="bold"
                    >
                      View Clue
                    </Typography>
                  }
                />
              </div>
            )}
          </>
        );
      case "not-viewed":
        return (
          <div className={classes.upcomingCard}>
            <div>
              {showThumbnail({
                type: clue?.field_type?.slug || "",
                clue: clue,
              })}
            </div>
            <Button
              onClick={handleClueExpanded}
              className={classes.button}
              loading={loading}
              buttonText={
                <Typography
                  className={cx("mb-0")}
                  variant="p16"
                  fontWeight="bold"
                >
                  View Clue
                </Typography>
              }
            />
          </div>
        );
      case "locked":
        return (
          <div className={classes.lockedCard}>
            <div>
              {showThumbnail({
                type: clue?.field_type?.slug || "",
                clue: clue,
              })}
            </div>
            <Button
              className={classes.button}
              onClick={() => {
                searchParams.set("show_upgrade_modal", "true");
                setSearchParams(searchParams);
              }}
              buttonText={
                <Typography
                  className={cx("mb-0")}
                  variant="p16"
                  fontWeight="bold"
                >
                  View Clue
                </Typography>
              }
            />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  const giveSvgStyle = (status: string) => {
    const styles = {};
    switch (status) {
      case "upcoming":
        return { stroke: "#696969" };
      case "pending":
        return { stroke: "#D0D0D0" };

      default:
        return styles;
    }
  };

  return (
    <Card
      cardBodyClassName={classes.clueCardBody}
      className={classes.cardWrapper}
    >
      <div className={classes.cardHeader}>
        <div className={classes.leftSide}>
          {getProgressIcon({
            clue,
            ...giveSvgStyle(clue.answer_status),
          })}
          <Typography fontWeight="bold" className="mb-0" variant="p16">
            WL$ Clue #{index + 1}
          </Typography>
          {clue.answer_status === "not-viewed" && (
            <span className={classes.notViewedBadge}>New!</span>
          )}
        </div>
        <div className={classes.clueType}>
          {getQuestionIcon({ type: clue?.field_type?.slug || "" })}
        </div>
      </div>
      <div className={classes.cardContent}>{getCardContent()}</div>
    </Card>
  );
};

export default ClueCard;

import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "src/helpers/constants";

export const getMomentDate = (val?: string) => {
  return val ? moment(val) : "";
};
export const getMomentFormat = (val?: string, fmString?: string) => {
  if (!val || !fmString) return "";
  return moment(val).format(fmString);
};
export const getPostDate = (val?: string) => {
  const mVal = getMomentDate(val);
  return mVal ? mVal.format("MMMM DD, YYYY") : "";
};
export const getUploadedPostDate = (val?: string, isShort?: boolean) => {
  const mVal = getMomentDate(val);
  return mVal ? mVal.format(isShort ? "M/D/YYYY" : DEFAULT_DATE_FORMAT) : "";
};
export const getIsValidDate = (val?: string) => {
  if (!val) return false;
  return moment(val).isValid();
};

export const checkValidBirthDate = (value: string | undefined) => {
  if (!getIsValidDate(value || "")) {
    return false;
  }
  const minAge = 13;
  const currentDate = new Date();
  const userBirthDate = value ? new Date(value) : new Date();
  const age = currentDate.getFullYear() - userBirthDate.getFullYear();

  if (age < minAge) {
    return false;
  }
  if (age === minAge) {
    if (currentDate.getMonth() < userBirthDate.getMonth()) {
      return false;
    }

    if (
      currentDate.getMonth() === userBirthDate.getMonth() &&
      currentDate.getDate() < userBirthDate.getDate() + 1
    ) {
      return false;
    }
  }
  return true;
};

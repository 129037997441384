import cn from "classnames";
import { WithContext as ReactTags } from "react-tag-input";
import { ISuggestTag } from "src/types";
import classes from "./SuggestTag.module.scss";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

interface Props {
  className?: string;
  value: ISuggestTag[];
  onChange: (val: ISuggestTag, vals: ISuggestTag[]) => void;
  onDelete: (val: ISuggestTag) => void;
  placeholder?: string;
  label?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  prefix?: string;
  limit?: number;
}

const SuggestTag = ({
  className,
  value,
  onChange,
  onDelete,
  placeholder,
  label,
  helperText,
  prefix = "$",
  limit = 0,
}: Props) => {
  const handleDelete = (i: number) => {
    const tVal = value.filter((v, index) => index === i)[0];
    onDelete(tVal);
  };

  const handleAddition = (tag: ISuggestTag) => {
    const tTag = {
      ...tag,
      text: `${prefix}${tag.text}`,
    };
    const newTags = [...(value || []), tTag];
    if (!limit || newTags.length <= limit) {
      onChange(tTag, newTags);
    }
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      {!!label && <label>{label}</label>}
      <ReactTags
        tags={value || []}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        autocomplete
        placeholder={placeholder}
      />
      {!!helperText && <span>{helperText}</span>}
    </div>
  );
};

export default SuggestTag;

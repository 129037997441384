type Params = {
  onScoreChanged?: (score: number) => void;
  onStart?: () => void;
  onRestart?: () => void;
  onEnd?: (score: number) => void;
  onSuccess?: () => void;
  successScore?: number;
  timeout?: number;
  answer?: string;
};

export const gameInit = ({
  onScoreChanged,
  onStart,
  onRestart,
  onEnd,
  onSuccess,
  successScore = 30,
  timeout = 300,
  answer,
}: Params) => {
  // Config ===============
  const boxSizeWidth = 12;
  const boxSizeHeight = 7;
  // ======================

  const playBoard: any = document.querySelector(".play-board");

  const startGamePopup: any = document.querySelector("#start_game");
  const startGameButton: any = document.querySelector("#bt_start_game");

  const failGamePopup: any = document.querySelector("#fail_game");
  const restartGameButton: any = document.querySelector("#bt_restart_game");

  const gameScoreDisplay: any = document.querySelector("#clue-game-score");

  const successGamePopup: any = document.querySelector("#success_game");
  const errorMessage: any = document.querySelector("#error_message");
  errorMessage.innerHTML = `Sorry, you didn’t gather all ${successScore} Lamps`;

  const getRandomNumber = (min: any, max: any) => {
    return Math.floor(Math.random() * (max - min) + min);
  };

  let gameOver = false;
  let foodX: number, foodY: number;
  let snakeX = 3,
    snakeY = getRandomNumber(1, boxSizeHeight);
  let velocityX = 0,
    velocityY = 0;
  let snakeBody = [
    [snakeX, snakeY],
    [snakeX - 1, snakeY],
  ];
  let setIntervalId: any;
  let score = 0;
  let currentDirect = "";
  let directQueue: string[] = [];

  const resetAttrs = () => {
    gameOver = false;
    snakeX = 3;
    snakeY = getRandomNumber(1, boxSizeHeight);
    velocityX = 0;
    velocityY = 0;
    snakeBody = [
      [snakeX, snakeY],
      [snakeX - 1, snakeY],
    ];
    score = 0;
    currentDirect = "";
    directQueue = [];
  };

  const updateFoodPosition = () => {
    // Passing a random value as food position
    let i = 0;

    while (i < 100) {
      foodX = Math.floor(Math.random() * boxSizeWidth) + 1;
      foodY = Math.floor(Math.random() * boxSizeHeight) + 1;

      const touchSnakeBody = snakeBody.find((b) => {
        if (foodY === b[1] && foodX === b[0]) {
          return true;
        }
        return false;
      });

      if (!touchSnakeBody) break;

      i++;
    }
  };

  const handleGameOver = () => {
    // Clearing the timer and reloading the page on game over
    clearInterval(setIntervalId);
    failGamePopup.classList.remove("hide");
    if (onEnd) onEnd(score);
  };

  const handleGameSuccess = () => {
    successGamePopup.classList.remove("hide");
    clearInterval(setIntervalId);
    if (onSuccess) onSuccess();
  };

  const changeDirection = (key: string) => {
    directQueue.push(key);
  };

  let speed = 1;

  const runGame = () => {
    if (directQueue.length > 0) {
      const key = directQueue.pop();
      // Changing velocity value based on key press
      if ((key === "ArrowUp" || key === "w") && velocityY != 1) {
        velocityX = 0;
        velocityY = -1;
        currentDirect = "up";
      } else if ((key === "ArrowDown" || key === "s") && velocityY != -1) {
        velocityX = 0;
        velocityY = 1;
        currentDirect = "down";
      } else if ((key === "ArrowLeft" || key === "a") && velocityX != 1) {
        velocityX = -1;
        velocityY = 0;
        currentDirect = "left";
      } else if ((key === "ArrowRight" || key === "d") && velocityX != -1) {
        velocityX = 1;
        velocityY = 0;
        currentDirect = "right";
      }

      directQueue = [];
    }

    if (velocityX === 0 && velocityY === 0) return;

    if (score === successScore) {
      handleGameSuccess();
      return;
    }

    if (gameOver) return handleGameOver();

    let html = `<div class="food" style="grid-area: ${foodY} / ${foodX}"></div>`;

    // Checking if the snake hit the food
    if (snakeX === foodX && snakeY === foodY) {
      updateFoodPosition();
      snakeBody.push([foodY, foodX]); // Pushing food position to snake body array
      score++; // increment score by 1
      if (onScoreChanged) onScoreChanged(score);
      gameScoreDisplay.innerHTML = `${score}/${successScore}`;
    }

    // increase speed of snake every 5 lamps
    if (score === speed * 5) {
      clearInterval(setIntervalId);
      speed++;
      timeout -= 5;
      setIntervalId = setInterval(runGame, timeout);
      if (onScoreChanged) onScoreChanged(score);
      gameScoreDisplay.innerHTML = `${score}/${successScore}`;
    }

    // Updating the snake's head position based on the current velocity
    snakeX += velocityX;
    snakeY += velocityY;

    // Shifting forward the values of the elements in the snake body by one
    snakeBody.pop();
    snakeBody.unshift([snakeX, snakeY]);

    // Checking if the snake's head is out of wall, if so setting gameOver to true
    if (
      snakeX <= 0 ||
      snakeX > boxSizeWidth ||
      snakeY <= 0 ||
      snakeY > boxSizeHeight
    ) {
      return (gameOver = true);
    }

    html += displaySnake();

    playBoard.innerHTML = html;
  };

  const displaySnake = () => {
    let html = "";
    for (let i = 0; i < snakeBody.length; i++) {
      // Adding a div for each part of the snake's body
      let divClass = "body";
      if (i === 0) {
        divClass = `head direct-${currentDirect} `;
      } else if (i === snakeBody.length - 1) {
        const currPos = snakeBody[i];
        const prevPos = snakeBody[i - 1];

        let tailDirect = "";
        if (currPos[0] === prevPos[0]) {
          // up / down
          if (currPos[1] > prevPos[1]) {
            // up
            tailDirect = "up";
          } else {
            // down
            tailDirect = "down";
          }
        } else {
          // left / right
          if (currPos[0] > prevPos[0]) {
            // left
            tailDirect = "left";
          } else {
            // right
            tailDirect = "right";
          }
        }
        divClass = `tail direct-${tailDirect}`;
      }

      html += `<div class="${divClass}" style="grid-area: ${snakeBody[i][1]} / ${snakeBody[i][0]}"></div>`;

      // Checking if the snake head hit the body, if so set gameOver to true
      if (
        i !== 0 &&
        snakeBody[0][1] === snakeBody[i][1] &&
        snakeBody[0][0] === snakeBody[i][0]
      ) {
        gameOver = true;
      }
    }

    return html;
  };

  const onInit = () => {
    updateFoodPosition();

    let html = `<div class="food" style="grid-area: ${foodY} / ${foodX}"></div>`;

    html += displaySnake();
    playBoard.innerHTML = html;
  };
  if (!setIntervalId && !answer) {
    onInit();
    setIntervalId = setInterval(runGame, timeout);
  }

  document.addEventListener("keyup", (e) => {
    if (!answer) {
      changeDirection(e.key);
    }
  });

  document.addEventListener("swiped-up", function () {
    if (!answer) {
    }
    changeDirection("ArrowUp");
  });

  document.addEventListener("swiped-down", function () {
    if (!answer) {
      changeDirection("ArrowDown");
    }
  });

  document.addEventListener("swiped-left", function () {
    if (!answer) {
      changeDirection("ArrowLeft");
    }
  });

  document.addEventListener("swiped-right", function () {
    if (!answer) {
      changeDirection("ArrowRight");
    }
  });

  startGameButton.addEventListener("click", () => {
    if (!answer) {
      if (onStart) onStart();
      startGamePopup.classList.add("hide");
      changeDirection("ArrowRight");
    }
  });

  restartGameButton.addEventListener("click", () => {
    if (!answer) {
      if (onRestart) onRestart();
      resetAttrs();
      onInit();
      setIntervalId = setInterval(runGame, timeout);
      failGamePopup.classList.add("hide");
      changeDirection("ArrowRight");
    }
  });

  return { setIntervalId };
};

import { createTypes } from "./createTypes";

export default createTypes(
  "FETCH_REGION_LIST_STARTED",
  "FETCH_REGION_LIST_SUCCESS",
  "FETCH_REGION_LIST_FAILED",
  "FETCH_USERS_SAVED_REGION_LIST_STARTED",
  "FETCH_USERS_SAVED_REGION_LIST_SUCCESS",
  "FETCH_USERS_SAVED_REGION_LIST_FAILED",
  "POST_SELECTED_REGION_STARTED",
  "POST_SELECTED_REGION_SUCCESS",
  "POST_SELECTED_REGION_FAILED",
  "UPDATE_SELECTED_REGION_STARTED",
  "UPDATE_SELECTED_REGION_SUCCESS",
  "UPDATE_SELECTED_REGION_FAILED",
  "FETCH_REGION"
);

import React, { useEffect, useRef } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AuthBackground from "src/assets/images/auth-bg.png";

import { subscriptionRoute } from "src/routes";
import { isLoggedIn } from "src/utils/utils";
import Header from "./Header";

import classes from "./styles.module.scss";

const SubscriptionLayout: React.FC = () => {
  const { pathname } = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const isLogIn = isLoggedIn();

    if (!isLogIn) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (containerRef) {
      containerRef?.current?.scroll({ top: 0, behavior: "smooth" });
    }
  }, [pathname]);

  const getRoutes = (
    routes: {
      path: string;
      name: string;
      component: () => JSX.Element;
      layout: string;
    }[]
  ) => {
    return routes.map(({ path, component: Component }, key) => {
      return <Route path={path} element={<Component />} key={key} />;
    });
  };

  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${AuthBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <div ref={containerRef} className={classes.container}>
        <Routes>
          {getRoutes(subscriptionRoute)}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default SubscriptionLayout;

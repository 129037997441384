import React, { useRef } from "react";
import Slider from "react-slick";
import Typography from "src/components/Typography";
import GameBg from "src/pages/Home/assets/images/game-bg.png";
import RectImg1 from "src/pages/Home/assets/images/rectangle-img1.svg";
import RectImg2 from "src/pages/Home/assets/images/rectangle-img2.svg";
import RectImg3 from "src/pages/Home/assets/images/rectangle-img3.svg";
import RectImg4 from "src/pages/Home/assets/images/rectangle-img4.svg";
import RectImg5 from "src/pages/Home/assets/images/rectangle-img5.svg";
import MobileRightIcon from "../assets/images/rightArrowIcon.svg";
import classes from "./styles.module.scss";

const settings = {
  speed: 500,
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Gamedata = [
  {
    img: RectImg1,
    title: "Solve The Clues",
    active: true,
  },
  {
    img: RectImg2,
    title: "Find The Treasure",
    active: false,
  },
  {
    img: RectImg3,
    title: "Win Prizes & Money",
    active: false,
  },
  {
    img: RectImg4,
    title: "Enjoy Fun Content",
    active: false,
  },
  {
    img: RectImg5,
    title: "Make The World Better",
    active: false,
  },
];

const GameCards = () => {
  const sliderRef = useRef<Slider | null>(null);
  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${GameBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={classes.content}>
        <div className={classes.cardWapper}>
          {Gamedata?.map((gameValue, index) => (
            <div key={index} className={classes.cards}>
              <div className={classes.cardImage}>
                <img
                  alt="Not found"
                  src={gameValue.img}
                  width={281}
                  height={156}
                />
              </div>
              <Typography variant="p32" className={classes.title}>
                {gameValue.title}
              </Typography>
            </div>
          ))}
        </div>
        <img
          onClick={() => sliderRef.current?.slickNext()}
          className={classes.MobileRightIcon}
          alt="right-icon"
          src={MobileRightIcon}
        />
        <div className={classes.sliderWrapper}>
          <Slider ref={sliderRef} {...settings}>
            {Gamedata?.map((gameValue, i) => (
              <div key={i} className={classes.cards}>
                <div
                  className={classes.cardImage}
                  style={{
                    backgroundImage: `url(${gameValue.img})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100%",
                    backgroundPosition: "center",
                  }}
                ></div>
                <Typography variant="p24" className={classes.title}>
                  {gameValue.title}
                </Typography>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default GameCards;

import { useNavigate } from "react-router";
import classes from "./NotFound.module.scss";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.wrapper}>
      404 Not Found. Click{" "}
      <span
        onClick={() => {
          navigate("/play/my-dashboard");
        }}
      >
        here
      </span>{" "}
      to go to Dashboard
    </div>
  );
};

export default NotFound;

import cn from "classnames";
import VideoPlaceholder from "src/assets/images/video-placeholder.png";
import CheckBox from "src/components/CheckBox";
import { numberFormatter } from "src/utils/number";
import classes from "./DeletePostWarning.module.scss";

interface Props {
  className?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  title: string;
  createdAt: string;
  viewCount: number;
  imgSrc: string;
}

const DeletePostWarning = ({
  className,
  checked,
  onChange,
  title,
  createdAt,
  viewCount,
  imgSrc,
}: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.details}>
        <img src={imgSrc || VideoPlaceholder} alt="media" />
        <div>
          <span>{title}</span>
          <span>Created: {createdAt}</span>
          <span>{numberFormatter(viewCount || 0, 1)} Views</span>
        </div>
      </div>
      <CheckBox
        checkBoxClassName={classes.checkbox}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        checked={checked}
        showOutline
        id="confirm-delete"
        label="By checking this box, I understand deleting this post is permanent and cannot be undone."
      />
    </div>
  );
};

export default DeletePostWarning;

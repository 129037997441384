import { DashboardSection } from "src/types/dashboard";
import { Request } from "./request";

export const apiGetDashboardEntity = async (key: DashboardSection) => {
  return Request.call({
    url: `/api/user/dashboard-${key}`,
    method: "GET",
  });
};
export const apiGetNewCluesAvailable = async (filters = {}) => {
  return Request.call({
    url: `/api/user/notification-count/new_clues_available`,
    method: "GET",
    params: filters,
  });
};

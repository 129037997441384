import { toast } from "react-toastify";

export const readFileSrc = async (tFile: any) => {
  const src = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(tFile);
    reader.onload = () => resolve(reader.result);
  });
  return src;
};
export const getFileSize = (size?: number, unit?: string) => {
  let tSize = size || 0;
  const fSExt = ["Bytes", "KB", "MB", "GB"];
  const unitIndex = fSExt.findIndex((v) => v === unit);
  let i = 0;
  while (unitIndex > 0 ? i < unitIndex : tSize > 900) {
    tSize = tSize / 1024;
    i = i + 1;
  }
  const intSize = Math.round(tSize * 100) / 100;
  return unitIndex > 0 ? `${intSize}` : `${intSize} ${fSExt[i]}`;
};
export const checkValidUploadedSie = (tFile: any) => {
  const tSize = tFile?.size || 0;
  const sizeAsGB = parseFloat(getFileSize(tSize, "GB"));
  const isValidSize = sizeAsGB <= 5;
  if (!isValidSize) {
    toast.error(
      "File size is bigger than maximum size, 5GB for video and image!"
    );
  }
  return !!tFile && isValidSize;
};

import cn from "classnames";
import React from "react";
import classes from "./SeasonBlock.module.scss";

interface Props {
  children: React.ReactNode;
}

const Prize = ({ children }: Props) => {
  return (
    <span className={cn(classes.prize)}>
      <span>PRIZE</span>
      <span>{children}</span>
    </span>
  );
};

export default Prize;

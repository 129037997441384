import cn from "classnames";
import { ReactComponent as LampIcon } from "src/assets/icons/lamp-circle-1-icon.svg";
import { ReactComponent as VideoPlusIcon } from "src/assets/icons/video-plus-icon.svg";
import Button from "src/components/Button";
import classes from "./UploadEmptyState.module.scss";

interface Props {
  className?: string;
  onClick?: () => void;
}

const UploadEmptyState = ({ className, onClick }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span>
        <LampIcon />
      </span>
      <p>
        <span>Select “Upload” below to get started. </span>
        <span>Content you upload will show here.</span>
      </p>
      <Button
        buttonText="Upload"
        buttonColor="purple"
        leftIcon={<VideoPlusIcon />}
        onClick={onClick}
        buttonClassName={classes.uploadBtn}
      />
    </div>
  );
};

export default UploadEmptyState;

import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import get from "lodash/get";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FormGroup, Modal, Button as RButton } from "reactstrap";
import * as Yup from "yup";
import avatarSrc from "src/assets/images/default-avatar.png";
import Button from "src/components/Button";
import Input from "src/components/Input";
import InputMask from "src/components/InputMask";
import Select from "src/components/Select";
import Text from "src/components/Text";
import {
  BE_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  MASK_PATTERN,
} from "src/helpers/constants";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { OptionType, ProfileInputProps } from "src/pages/Auth/Profile/types";
import { getUserProfile, updateUserProfile } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { telephoneNumberRegex } from "src/utils/constants";
import { checkValidBirthDate, getMomentFormat } from "src/utils/date";
import { getDefaultFormattedNumber, getFormattedNumber } from "src/utils/utils";
import classes from "./EditProfile.module.scss";

type ProfileProps = ProfileInputProps & {
  username: string;
  avatar: File | null;
};

const EditProfile = () => {
  const navigate = useNavigate();
  const actions = useStoreActions({
    updateUserProfile,
    getUserProfile,
  });
  const { loading } = useReducerData("auth", "editUserProfile", {
    loading: false,
  });
  const userProfile = useUserProfile();
  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [avatarImage, setAvatarImage] = useState(
    userProfile.user?.avatar_path || null
  );

  const modalToggle = () => setOpenAvatarModal(false);

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Prefer Not to say", label: "Prefer Not to say" },
  ];
  const selectedGender = genderOptions.find(
    (gender: { value: string }) => userProfile.user?.gender === gender.value
  );

  const profileSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    username: Yup.string(),
    lastName: Yup.string(),
    phone: Yup.string()
      .required("Phone is required")
      .matches(
        telephoneNumberRegex,
        "Number needs to be in the format XXX-XXX-XXXX"
      ),
    avatar: Yup.mixed(),
    birthDate: Yup.string()
      .required("Birth date is required")
      .test("dob", "Must be over 13 years old", function (value) {
        return checkValidBirthDate(value);
      }),
    gender: Yup.object()
      .required("Please select gender")
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
  });

  const handleSubmit = async (
    values: ProfileProps,
    formikHelpers: FormikHelpers<ProfileProps>
  ) => {
    const updatedProfile = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      phone: values?.phone.replaceAll("-", ""),
      birth_date: getMomentFormat(values?.birthDate, BE_DATE_FORMAT),
      gender: values?.gender?.value,
      ...(values?.avatar
        ? {
            avatar: values?.avatar as File,
          }
        : {}),
      username: values?.username,
      rules_accepted: 1,
      tnc_accepted: 1,
    };

    function objectToFormData(obj: Record<string, any>): FormData {
      const formData = new FormData();

      for (const key in obj) {
        formData.append(key, obj[key]);
      }

      return formData;
    }

    const formData = objectToFormData(updatedProfile);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await actions.updateUserProfile(formData);
    const status = get(res, "status", 0);
    if (status) {
      actions.getUserProfile();
      toast.dark("Profile Information successfully updated");

      navigate("/user/profile");
      formikHelpers.resetForm();
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div className={classes.wrapper}>
      <Formik
        onSubmit={(values, formikHelpers) =>
          handleSubmit(values, formikHelpers)
        }
        validationSchema={profileSchema}
        initialValues={{
          firstName: userProfile.user?.first_name || "",
          lastName: userProfile.user?.last_name || "",
          username: userProfile.user?.username || "",
          phone: getDefaultFormattedNumber(userProfile.user?.phone) || "",
          birthDate: userProfile.user?.birth_date
            ? getMomentFormat(userProfile.user.birth_date, DEFAULT_DATE_FORMAT)
            : "",
          gender: selectedGender || (null as unknown as OptionType),
          avatar: null,
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          isValid,
          setFieldValue,
          setFieldTouched,
          dirty,
        }: FormikProps<ProfileProps>) => {
          return (
            <Form className={cx(classes.form)}>
              <div className={classes.personalInfo}>
                <div className={classes.title}>
                  <Text color="#fff" size={24}>
                    Personal Information
                  </Text>
                </div>
                <div className={classes.photo}>
                  <div>
                    <Text className="mb-1" size={16}>
                      Photo
                    </Text>
                    <img
                      src={avatarImage || avatarSrc}
                      className={classes.img}
                    />
                  </div>
                  <div className={classes.changePic}>
                    <RButton
                      onClick={() => setOpenAvatarModal(true)}
                      type="button"
                      outline
                      color="secondary"
                    >
                      Change Photo
                    </RButton>
                    <Modal
                      className={classes.fileModal}
                      centered
                      isOpen={openAvatarModal}
                      toggle={modalToggle}
                    >
                      <Text
                        style={{ marginBottom: "50px" }}
                        color="#fff"
                        size={40}
                        fontFamily="milonga"
                      >
                        Change your photo
                      </Text>
                      <Button
                        buttonText={
                          <label htmlFor="avatar">Choose Photo</label>
                        }
                        buttonColor="secondary"
                        buttonClassName={classes.select}
                      />
                      <div className={classes.input}>
                        <input
                          type="file"
                          id="avatar"
                          name="avatar"
                          accept="image/*"
                          onChange={(event) => {
                            const file = event?.target?.files?.[0];
                            if (file) {
                              setFieldValue("avatar", file);
                              if (file) {
                                const reader = new FileReader();

                                reader.onload = (e) => {
                                  const base64Url = e.target?.result; // This is the base64-encoded URL
                                  setAvatarImage(base64Url);
                                };

                                reader.readAsDataURL(file);
                              }
                              modalToggle();
                            }
                          }}
                        />
                      </div>
                    </Modal>
                  </div>
                </div>
                <div className={classes.names}>
                  <div>
                    <FormGroup className={classes.formGroup}>
                      <Input
                        labelClassName={classes.label}
                        label="First Name"
                        required
                        value={values.firstName}
                        inputClassName={classes.input}
                        placeholder="John"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.firstName}
                        touched={touched.firstName}
                        name="firstName"
                      />
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className={classes.formGroup}>
                      <Input
                        labelClassName={classes.label}
                        label="Last Name"
                        value={values.lastName}
                        placeholder="Doe"
                        inputClassName={classes.input}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.lastName}
                        touched={touched.lastName}
                        name="lastName"
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className={classes.numbers}>
                  <div>
                    <FormGroup className={classes.formGroup}>
                      <Input
                        labelClassName={classes.label}
                        label="Phone Number"
                        required
                        placeholder="999-888-7777"
                        value={values.phone}
                        inputClassName={classes.input}
                        onChange={(value) => {
                          setFieldValue("phone", getFormattedNumber(value));
                        }}
                        onBlur={handleBlur}
                        error={errors.phone}
                        touched={touched.phone}
                        name="phone"
                      />
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup className={classes.formGroup}>
                      <InputMask
                        mask={MASK_PATTERN.DATE.MASK}
                        placeholder={MASK_PATTERN.DATE.PLACEHOLDER}
                        label="Birthdate"
                        name="birthDate"
                        required
                        error={errors.birthDate}
                        touched={touched.birthDate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.birthDate}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className={classes.email}>
                  <div>
                    <Text className="mb-1" size={16}>
                      Email{" "}
                    </Text>
                    <Text size={18}>{userProfile.user?.email || ""}</Text>
                  </div>
                  <div>
                    <FormGroup className={classes.formGroup}>
                      <Select
                        label="Gender"
                        name="gender"
                        className={classes.gender}
                        placeholder={<Text size={18}>Select one</Text>}
                        value={values?.gender}
                        error={errors?.gender as string}
                        touched={touched?.gender as unknown as boolean}
                        leftIconClass={classes.icon}
                        onBlur={() => setFieldTouched("gender", true)}
                        onChange={(selectedOption) => {
                          if (setFieldValue) {
                            setFieldValue(
                              "gender",
                              selectedOption as OptionType
                            );
                          }
                        }}
                        options={genderOptions}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className={classes.username}>
                <div className={classes.title}>
                  <Text size={24}>Username</Text>
                  <Text size={12}>
                    Your username will also appear as your channel name for the
                    content you post.{" "}
                  </Text>
                </div>
                <div className={classes.names}>
                  <FormGroup className={classes.formGroup}>
                    <Input
                      labelClassName={classes.label}
                      label="Username"
                      required
                      value={values.username}
                      inputClassName={classes.input}
                      placeholder=""
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.username}
                      touched={touched.username}
                      name="username"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className={classes.actions}>
                <Button
                  buttonText="Save Profile"
                  buttonColor="secondary"
                  disabled={!isValid || !dirty}
                  loading={loading}
                  type="submit"
                  buttonClassName={classes.submit}
                />
                <div className={classes.cancelWrapper}>
                  <Button
                    buttonText={
                      <Text fontFamily="impact" size={32}>
                        Cancel
                      </Text>
                    }
                    onClick={() => navigate("/user/profile")}
                    buttonColor="secondary"
                    variant="link"
                    buttonClassName={classes.cancel}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default EditProfile;

import cn from "classnames";
import classes from "./styles.module.scss";

interface TextProps {
  children: React.ReactNode;
  noMargin?: boolean;
  marginLeft?: boolean;
  weight?: number;
}

const Text = ({ children, noMargin, marginLeft, weight }: TextProps) => {
  return (
    <p
      className={cn(classes.text, {
        [classes.noMargin]: noMargin,
        [classes.marginLeft]: marginLeft,
      })}
      style={{
        fontWeight: weight || "inherit",
      }}
    >
      {children}
    </p>
  );
};

export default Text;

import * as mediaActions from "src/store/actions/media";
import { useReducerData, useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {
    searchMediaData: useReducerData("media", "searchMedia.data", {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...mediaActions,
  });
};

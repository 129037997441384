import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import React from "react";
import ActivateDate from "./ActivateDate";
import Lamp from "./Lamp";
import Name from "./Name";
import Prize from "./Prize";
import classes from "./SeasonBlock.module.scss";

interface Props {
  name: React.ReactNode;
  status?: string;
  activateDate?: string;
  prize?: string;
  lamp?: any;
  defaultLabel?: string;
  gap?: number;
}

const Season = ({
  name,
  status,
  activateDate,
  prize,
  lamp,
  defaultLabel,
  gap,
}: Props) => {
  const hasLamp = !isEmpty(lamp);
  return (
    <div
      className={cn(classes.season, {
        [classes.hasPrize]: !!prize,
        [classes.hasLamp]: hasLamp,
        [classes.hasDefaultLabel]: !!defaultLabel,
      })}
      style={{ gap: `${gap || 24}px` }}
    >
      <Name status={status}>{name}</Name>
      {!!activateDate && <ActivateDate>{activateDate}</ActivateDate>}
      {!!prize && <Prize>{prize}</Prize>}
      {hasLamp && <Lamp>{lamp.price}</Lamp>}
      {!!defaultLabel && (
        <span className={classes.defaultLabel}>{defaultLabel}</span>
      )}
    </div>
  );
};

export default Season;

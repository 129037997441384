import cloneDeep from "lodash/cloneDeep";

export const getMoreWatchChannel = (
  prevState: any,
  payload: any,
  key?: string
) => {
  const newData = cloneDeep(prevState?.[key || "watchChannel"].data);
  newData.sections[0] = {
    ...newData.sections[0],
    meta: {
      ...payload.data.channel.sections[0].meta,
    },
    posts: [
      ...newData.sections[0].posts,
      ...payload.data.channel.sections[0].posts,
    ],
  };
  return newData;
};
export const getMoreWatchUserFeed = (
  prevState: any,
  payload: any,
  attrKey?: string
) => {
  return {
    items: [
      ...prevState[attrKey || "watchUserFeed"].data.items,
      ...payload.data.items,
    ],
    meta: { ...payload.data.meta },
  };
};

import get from "lodash/get";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "src/components/Spinner";
import { ROLE_LEVEL, ROLE_NAME } from "src/helpers/constants/role";
import useUserProfile, {
  useUserProfileLoading,
} from "src/helpers/hooks/useUserProfile";
import { setUserSubscriptionFlow } from "src/store/actions/auth";
import { useStoreActions } from "src/store/hooks";
import { getAccessLevel } from "src/utils/role";
import { useWindowSize } from "src/utils/useWindowSize";
import AvailableGames from "./Components/AvailableGames";
import FreeAccess from "./Components/FreeAccess";
import UpcomingGames from "./Components/UpcomingGames";

const Subscriptions = () => {
  const { width = 0 } = useWindowSize();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const actions = useStoreActions({
    setUserSubscriptionFlow,
  });
  const isMobile = width > 0 && width <= 768;
  const nextPath = String(searchParams.get("next-path") || "");

  const userProfile = useUserProfile();
  const loading = useUserProfileLoading();
  const accessLevel = getAccessLevel(
    get(userProfile, "user.role.name", ROLE_NAME.FREE_ACCESS)
  );
  const { active_subscription = false, is_tg_account } = userProfile;
  const isSubscriptionActive = active_subscription || is_tg_account;
  const isFreeTrial = accessLevel === ROLE_LEVEL.FREE_TRIAL;

  if (loading) {
    return <Spinner />;
  }

  const fullAccessAction = async () => {
    if (isSubscriptionActive || isFreeTrial) {
      if (isMobile) {
        navigate("/user/device-blocked");
      } else {
        navigate(nextPath || "/play");
      }
      return;
    }
    await actions.setUserSubscriptionFlow(false);
    navigate("/auth/profile");
  };

  const adminActions = [
    {
      onClick: fullAccessAction,
      buttonText: "ENTER FULL ACCESS",
    },
    {
      onClick: () => {
        navigate("/user/news");
      },
      buttonText: "ENTER FREE ACCESS",
    },
  ];

  const fullAccessActions = [
    {
      onClick: fullAccessAction,
      buttonText: isSubscriptionActive || isFreeTrial ? "ENTER" : "SUBSCRIBE",
    },
  ];

  const renderAccessComponent = () => {
    switch (accessLevel) {
      case ROLE_LEVEL.ADMIN:
        return <AvailableGames userType={accessLevel} actions={adminActions} />;
      case ROLE_LEVEL.FREE_ACCESS:
        return <FreeAccess />;

      case ROLE_LEVEL.FULL_ACCESS:
      case ROLE_LEVEL.FREE_TRIAL:
        return (
          <AvailableGames userType={accessLevel} actions={fullAccessActions} />
        );

      default:
        return <></>;
    }
  };
  return (
    <div>
      {renderAccessComponent()}
      <UpcomingGames accessLevel={accessLevel} />
    </div>
  );
};

export default Subscriptions;

import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import UserIcon from "src/assets/icons/user-icon.svg";
import TgTile from "src/assets/images/tg-title.png";
import TgMobileLogo from "src/assets/images/tgs-logo.png";
import Button from "src/components/Button";
import UserDropdown from "src/layout/PrivateLayout/UserDropdown";
import { logoutUser } from "src/store/actions/auth";
import { useStoreActions } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import { isLoggedIn } from "src/utils/utils";
import classes from "./styles.module.scss";
const Header = () => {
  const navigate = useNavigate();
  const isLogIn = isLoggedIn();
  const actions = useStoreActions({ logoutUser });
  const { width = 0 } = useWindowSize();
  const { pathname } = useLocation();
  const isAbout = pathname === "/about";
  const isMobile = width <= 768;

  const handleRedirect = () => {
    if (isLogIn) {
      actions.logoutUser();
    }
    navigate("/auth/login");
  };

  return (
    <div className={classes.navWrapper}>
      <div
        className={cx(classes.wrapper, {
          [classes.aboutWrapper]: isAbout,
        })}
      >
        {isMobile ? (
          <div className={classes.desktopLogo}>
            <img src={TgMobileLogo} alt="logo" />
          </div>
        ) : (
          <div className={classes.desktopLogo}>
            <img src={TgTile} alt="logo" />
          </div>
        )}
        {isLogIn ? (
          <UserDropdown className={classes.userDropdown}>
            <img src={UserIcon} alt="user-icon" />
            <FontAwesomeIcon icon={faAngleDown} size="xs" />
          </UserDropdown>
        ) : (
          <div
            className={cx(classes.signInButton, {
              [classes.aboutButton]: isAbout,
            })}
          >
            <Button
              onClick={handleRedirect}
              buttonText={
                isLogIn ? "Sign Out" : isAbout ? "Sign In" : "SIGN IN"
              }
              variant="link"
            />
            {!isAbout && (
              <FontAwesomeIcon
                icon={faAngleRight}
                color="#FFFFFF"
                fontSize={"1.3em"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

import cn from "classnames";
import React from "react";
import Card from "src/components/Card";
import { getRegionInfo } from "src/utils/region";
import RegionContent from "./Content";
import classes from "./styles.module.scss";
import { Props } from "./types";

const TooltipContent: React.FC<Props> = ({
  content,
  isJoined = false,
  handleRemoveRegions,
  handleSaveRegion,
  handleViewClues,
}) => {
  const { isAllLampFound, isInactiveRegion, season1, season2, isDisabledView } =
    getRegionInfo(content);
  return (
    <Card
      cardBodyClassName={classes.cardBodyStyles}
      cardClassName={classes.wrapper}
    >
      <RegionContent
        content={content}
        isJoined={isJoined}
        handleRemoveRegions={handleRemoveRegions}
        handleSaveRegion={handleSaveRegion}
        handleViewClues={handleViewClues}
        isAllLampFound={isAllLampFound}
        isInactiveRegion={isInactiveRegion}
        regionName={content?.name || ""}
        regionStates={content?.states || []}
        className={cn(classes.tooltipContentWrapper, {
          [classes.contentJoined]: isJoined,
        })}
        season1={season1}
        season2={season2}
        isDisabledView={isDisabledView}
        seasonGap={16}
      />
    </Card>
  );
};

export default TooltipContent;

import cn from "classnames";
import React from "react";
import classes from "./RegionDate.module.scss";

interface Props {
  className?: string;
  status: string;
  dateStr: string;
}

const RegionDate = ({ className, status, dateStr }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span className={cn(classes.status, classes[status])}></span>
      <span className={classes.text}>
        {status} <b>{dateStr}</b>
      </span>
    </div>
  );
};
export default RegionDate;

import { Dispatch } from "redux";
import { apiGetAnnouncements } from "src/api/announcement";
import { CommonGetPayload } from "src/types";
import Types from "../types/announcement";

export const getAnnouncements =
  (filters: CommonGetPayload = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = filters;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_ANNOUNCEMENTS,
      asyncCall: () => {
        return apiGetAnnouncements("announcements", other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getCurrentAnnouncements =
  (filters: CommonGetPayload = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = filters;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_CURRENT_ANNOUNCEMENTS,
      asyncCall: () => {
        return apiGetAnnouncements("current-announcements", other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getPastAnnouncements =
  (filters: CommonGetPayload = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = filters;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_PAST_ANNOUNCEMENTS,
      asyncCall: () => {
        return apiGetAnnouncements("past-announcements", other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

import cn from "classnames";
import { ReactComponent as FullscrenIcon } from "src/assets/icons/controls/fullscreen-icon.svg";
import { ReactComponent as PauseIcon } from "src/assets/icons/controls/pause-icon.svg";
import { ReactComponent as PlayingIcon } from "src/assets/icons/controls/playing-icon.svg";
import { ReactComponent as Seek10SecondsIcon } from "src/assets/icons/controls/seek-10-seconds-icon.svg";
import { ReactComponent as VolumeOffIcon } from "src/assets/icons/controls/volume-off-icon.svg";
import { ReactComponent as VolumeOnIcon } from "src/assets/icons/controls/volume-on-icon.svg";
import Slider from "src/components/Slider";
import { getVideoDuration } from "src/utils/number";
import classes from "./PreviewVideo.module.scss";

interface Props {
  onPlayPause: () => void;
  playing: boolean;
  played: number;
  onSeek: (value: number) => void;
  onVolumeChanged: (value: number) => void;
  volume: number;
  muted: boolean;
  onMute: () => void;
  duration: number;
  controlRef?: any;
  onFullscreen: () => void;
  onSeekBack10: () => void;
  loaded: number;
  playedPercent: number;
  className?: string;
  hideFullscreen?: boolean;
}

const Control = ({
  onPlayPause,
  playing,
  played,
  onSeek,
  volume,
  muted,
  onMute,
  duration,
  controlRef,
  onVolumeChanged,
  onFullscreen,
  loaded,
  playedPercent,
  className,
  hideFullscreen,
  onSeekBack10,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isVideoLoading = playedPercent < loaded;

  return (
    <div className={cn(classes.customControl, className)} ref={controlRef}>
      <div className={classes.bottom}>
        <div onClick={onSeekBack10}>
          <Seek10SecondsIcon />
        </div>
        <div onClick={onPlayPause}>
          {playing ? <PlayingIcon /> : <PauseIcon />}
        </div>
        <div className={classes.volume}>
          <span onClick={onMute}>
            {muted ? <VolumeOffIcon /> : <VolumeOnIcon />}
          </span>
          <Slider
            hideThumb
            sliderClassName={classes.sliderVolume}
            trackClassName={classes.trackVolume}
            thumbClassName={classes.thumb}
            value={volume}
            min={0}
            max={1}
            step={0.1}
            onChange={onVolumeChanged}
            className={classes.sliderVolumeWrapper}
          />
          <div className={classes.videoPlayTime}>
            <span>{getVideoDuration(parseInt(String(played), 10) || 0)}</span>
            <span></span>
            <span>{getVideoDuration(duration || 0)}</span>
          </div>
        </div>
        <div className={classes.bottomRight}>
          {!hideFullscreen && <FullscrenIcon onClick={onFullscreen} />}
        </div>
      </div>
      <div className={classes.top}>
        <Slider
          hideThumb
          sliderClassName={classes.slider}
          trackClassName={classes.track}
          thumbClassName={classes.thumb}
          value={played}
          min={0}
          max={duration}
          step={0.1}
          onChange={onSeek}
          loadedValue={loaded === 1 ? 0 : loaded}
          loadedMax={1}
          loadedStep={0.1}
          displayLoaded
        />
      </div>
    </div>
  );
};

export default Control;

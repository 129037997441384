import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import get from "lodash/get";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import WishLampImage from "src/pages/Auth/assets/images/wish-lamp-img.svg";
import { PlanProps } from "src/pages/Subscription/Plans/Components/PlanCard/types";
import { postPaymentDetails } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { useUserActions } from "src/utils/useActions";
import { NewPaymentDetailsProps } from "..";
import classes from "./Confirm.module.scss";

type Props = {
  selectedPlans: PlanProps;
  paymentDetails?: NewPaymentDetailsProps;
  handleAddPaymentFlowSteps: (step: number) => void;
};

const Confirm: React.FC<Props> = ({
  selectedPlans,
  paymentDetails,
  handleAddPaymentFlowSteps,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const actions = useStoreActions({
    postPaymentDetails,
  });
  const addPaymentMethodsLoading = useReducerData(
    "auth",
    "addPaymentMethods.loading",
    false
  );

  const { changeSubscription } = useUserActions();

  const userProfile = useUserProfile() as {
    data: { plans: PlanProps[] };
  };
  const planId = get(userProfile, "subscription.plan_id", null);

  const cardHolderName = paymentDetails?.name_on_card?.split(" ") || [];
  const [firstName, lastName] = cardHolderName;

  const handleConfirm = async () => {
    setLoading(true);
    const paymentDetail = {
      payment_id: paymentDetails?.payment_id,
      plan_id: selectedPlans?.id,
    };

    let res: any;

    if (planId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res = await changeSubscription(paymentDetail);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res = await actions.postPaymentDetails(paymentDetail);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment

    setLoading(false);
    const status = get(res, "status", 0);
    const message = get(res, "message", null);
    if (status) {
      toast.dark(message);
      handleAddPaymentFlowSteps(3);
    } else {
      toast.error("Something Went Wrong.");
    }
  };

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.modalBodyHeader}>
          <Typography variant="p42" className={classes.confirmHeader}>
            Confirm
          </Typography>
        </div>
        <div className={classes.confirmTitle}>
          <div>
            <Typography
              variant="p24"
              className={cx("mb-2", classes.confirmText)}
            >
              Confirm
            </Typography>
            <Typography variant="p16" className="mb-4">
              Review your order details before completing
            </Typography>
          </div>

          <div
            className={classes.edit}
            onClick={() => navigate("/subscription/payment")}
          >
            <FontAwesomeIcon icon={faPencil} />
            <Typography variant="p16" className="mb-2">
              Edit
            </Typography>
          </div>
        </div>

        <div className={classes.purchase}>
          <Typography
            fontWeight="bold"
            variant="p18"
            className={classes.purchaseText}
          >
            Purchase
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}{" "}
          <div>
            <Typography variant="p16" className="mb-2">
              {selectedPlans?.type === "monthly"
                ? "Monthly Subscription for Wish Lamp$"
                : "Annual Subscription for Wish Lamp$"}
            </Typography>
            <img src={WishLampImage} alt="image not found" width={180} />
          </div>
        </div>
        <div className={cx(classes.nameWrapper, "mt-5")}>
          <Typography
            fontWeight="bold"
            variant="p18"
            className={classes.nametext}
          >
            First Name
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}
          <Typography variant="p16">{firstName}</Typography>
        </div>
        <div className={classes.nameWrapper}>
          <Typography fontWeight="bold" variant="p18">
            Last Name
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}{" "}
          <Typography variant="p16">{lastName}</Typography>
        </div>
        <div className={classes.nameWrapper}>
          <Typography fontWeight="bold" variant="p18">
            Card Number
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}{" "}
          <Typography variant="p16">
            **** **** **** {paymentDetails?.last4}{" "}
          </Typography>
        </div>
        <div className={(classes.nameWrapper, classes.bottomMg)}>
          <Typography fontWeight="bold" variant="p18">
            Subscription
          </Typography>
          {<span style={{ marginLeft: "3px" }}>:</span>}{" "}
          <Typography variant="p16">
            {" "}
            {selectedPlans?.type === "monthly" ? "Monthly" : "Annual"}
          </Typography>
        </div>
      </div>

      <div className={classes.buttonWrapper}>
        <Button
          buttonText="Confirm and Complete"
          buttonColor="secondary"
          type="submit"
          loading={loading}
          onClick={handleConfirm}
          buttonClassName={classes.button}
          disabled={addPaymentMethodsLoading}
        />
      </div>
    </div>
  );
};

export default Confirm;

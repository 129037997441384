import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { publicRoute } from "src/routes";
import AboutFooter from "./AboutFooter";
import Footer from "./Footer";
import Header from "./Header";
import PreviewFooter from "./PreviewFooter";
import PreviewHeader from "./PreviewHeader";
import classes from "./styles.module.scss";

const PublicLayout: React.FC = () => {
  const { pathname } = useLocation();
  const isPreview = pathname.includes("preview");
  const isNotFound = pathname === "/not-found";
  const isCongratulationsPage = pathname === "/congratulations";
  const getRoutes = (
    routes: {
      path: string;
      name: string;
      component: () => JSX.Element;
    }[]
  ) => {
    return routes.map(({ path, component: Component }, key) => {
      return <Route path={path} element={<Component />} key={key} />;
    });
  };
  const getFooter = () => {
    switch (pathname) {
      case "/preview":
        return <PreviewFooter />;
      case "/about":
        return <AboutFooter />;
      default:
        return <Footer />;
    }
  };
  return (
    <div className={classes.container}>
      {!isNotFound && !isCongratulationsPage && (
        <>{isPreview ? <PreviewHeader /> : <Header />}</>
      )}
      <Routes>
        {getRoutes(publicRoute)}
        <Route path="/*" element={<Navigate to="/not-found" replace />} />
      </Routes>
      {!isNotFound && !isCongratulationsPage && <>{getFooter()}</>}
    </div>
  );
};

export default PublicLayout;

import { useLocation, useNavigate } from "react-router";
import Spinner from "src/components/Spinner";
import { PRIZE_SUB_TITLE } from "src/helpers/constants";
import ChannelBlock from "src/pages/Watch/components/ChannelBlock";
import { MediaInfo } from "src/types/media";
import { WatchMedia } from "src/types/watch";
import { getBasicMediaInfo } from "src/utils/media";
import classes from "./Prizes.module.scss";
import { useMainData } from "./selectorData";

type ChannelData = {
  title: string;
  onClick: () => void;
  medias: WatchMedia[];
  slug?: string;
  id?: string;
  posts: MediaInfo[];
};

const PrizesMain = () => {
  const { prizeChannels, prizeChannelsLoading } = useMainData();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const channels: ChannelData[] = prizeChannels.map((channel: ChannelData) => {
    return {
      slug: channel.slug,
      title: String(PRIZE_SUB_TITLE[String(channel.slug)] || channel.title),
      medias: channel.posts.map((post: MediaInfo) => {
        return {
          ...getBasicMediaInfo(post),
          onClick: () =>
            navigate(`${pathname}/current-contests/playing-media/${post.id}`),
        };
      }),
    };
  });
  return (
    <div className={classes.mainWrapper}>
      {prizeChannelsLoading && <Spinner center className={classes.loading} />}
      {channels.map((channel, index: number) => {
        const isLastChannel = index === channels.length - 1;
        return (
          <ChannelBlock
            {...channel}
            key={channel.slug}
            paddingBottom={isLastChannel}
            onClick={() => {
              navigate(`/prizes/${channel.slug}`);
            }}
          />
        );
      })}
    </div>
  );
};

export default PrizesMain;

import { useReducerData } from "src/store/hooks";

export const useIndexData = () => {
  return {
    dashboardEngagements: useReducerData(
      "dashboard",
      "dashboardEngagements.data",
      {}
    ),
    dashboardEngagementsLoading: useReducerData(
      "dashboard",
      "dashboardEngagements.loading",
      false
    ),
  };
};

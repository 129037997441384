import cn from "classnames";
import { useEffect, useState } from "react";
import { getScheduleRegions } from "src/api/regions";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import { useWindowSize } from "src/utils/useWindowSize";
import { isLoggedIn } from "src/utils/utils";
import GameDetails from "./GameDetails";
import JoinNowSection from "./JoinNowSection";
import PlayGuide from "./PlayGuide";
import RegionMap from "./RegionMap";
import RegionTable from "./RegionTable";
import classes from "./styles.module.scss";

const Home = () => {
  const [scheduleRegionsLoading, setScheduleRegionsLoading] = useState(false);
  const [scheduleRegions, setScheduleRegions] = useState<any>({});
  const isLogIn = isLoggedIn();
  const { width = 0 } = useWindowSize();
  const isMobile = width > 0 && width <= 760;
  const PER_PAGE = 5;
  const total = scheduleRegions?.total || 0;
  const totalPages =
    parseInt(String(total / PER_PAGE)) + (total % PER_PAGE > 0 ? 1 : 0);

  const loadRegionData = async (tPage = 1) => {
    setScheduleRegionsLoading(true);
    const tRes = await getScheduleRegions({
      page: tPage,
    });
    setScheduleRegions(tRes?.data || {});
    setScheduleRegionsLoading(false);
  };
  const getRedirectLink = () => {
    switch (true) {
      case isMobile && isLogIn:
        return "/user/device-blocked";
      case isLogIn && !isMobile:
        return "/play";
      default:
        return "/auth/signup";
    }
  };

  const redirectLink = getRedirectLink();

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.home);
    loadRegionData();
  }, []);

  return (
    <>
      <div className={classes.homeWrapper}>
        <div className={classes.background}></div>
        <div className={classes.container}>
          <JoinNowSection redirectLink={redirectLink} />
          <PlayGuide redirectLink={redirectLink} />
          <GameDetails redirectLink={redirectLink} />
        </div>
      </div>
      <div className={cn(classes.homeWrapper, classes.homeRegionsWrapper)}>
        <div className={classes.background}></div>
        <div className={classes.container}>
          <h3 id="region-activation">Region Activation Schedule</h3>
          <RegionMap />
          <RegionTable
            className={classes.regionTable}
            data={scheduleRegions?.data || []}
            pagination={{
              totalPages,
              currentPage: scheduleRegions?.current_page || 1,
              onChange: (tPage) => {
                if (!scheduleRegionsLoading) {
                  loadRegionData(tPage);
                }
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Home;

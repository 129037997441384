import { Dispatch } from "redux";
import { apiGetEngagements } from "src/api/engagement";
import { CommonGetPayload } from "src/types";

import Types from "../types/engagement";

export const getEngagements =
  (filters: CommonGetPayload = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = filters;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_ENGAGEMENTS,
      asyncCall: () => {
        return apiGetEngagements(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

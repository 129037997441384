import cn from "classnames";
import get from "lodash/get";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import screenfull from "screenfull";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import { WATCH_TREASURE_GAME_URL1 } from "src/pages/Watch/constants";
import { MediaInfo } from "src/types/media";
import { getIsAdminMedia, getIsImage, getIsText } from "src/utils/media";
import PreviewVideo from "../PreviewVideo";
import ShareModal from "../ShareModal";
import classes from "./MediaDetail.module.scss";
import MediaInformation from "./MediaInformation";

const FULLSCREEN_CLASSNAME = "fullscreen";
interface Props {
  thumbnail?: string;
  fixedVideo?: boolean;
  onCloseFixed?: () => void;
  type?: number | string;
  data?: MediaInfo;
  onReportMedia: (reason: string, comment: string, cb: () => void) => void;
  reportLoading?: boolean;
  onLoveChanged: (val: boolean) => void;
  onRepost?: () => void;
  isReposted?: boolean;
  displayActions?: string[];
  isPublic?: boolean;
  isShare?: boolean;
  onFollow?: (state: boolean) => boolean | void;
  onShare?: () => void;
  onLink?: () => void;
  followsCount?: number;
}

const MediaDetail = ({
  fixedVideo,
  onCloseFixed,
  type,
  data,
  onReportMedia,
  reportLoading,
  onLoveChanged,
  onRepost,
  isReposted,
  displayActions = [],
  isPublic,
  onFollow,
  onShare,
  onLink,
  followsCount,
  isShare,
}: Props) => {
  const videoWrapperRef = useRef(null);
  const videoWrapperRefCurrent = videoWrapperRef.current as any;
  const isAdminMedia = getIsAdminMedia(data);
  const isDisableUserProfile = isAdminMedia;
  const isText = getIsText(type);
  const isImage = getIsImage(type) || isText;
  const [forcePlay, setForcePlay] = useState<boolean | undefined>(undefined);
  const [hover, setHover] = useState<boolean>(false);
  const [isShareModal, setIsShareModal] = useState<boolean>(false);
  const { pathname } = useLocation();
  const origin = get(location, "origin");
  const navigate = useNavigate();
  let source = data?.[isImage ? "images" : "video"]?.[0]?.url || "";
  if (isText) {
    source = data?.orignal_background || "";
  }
  const handleUserProfileClick = () => {
    if (!isDisableUserProfile) {
      if (isPublic) {
        onLink && onLink();
      } else {
        navigate(
          pathname.includes("/watch/treasure-games/playing-media/")
            ? WATCH_TREASURE_GAME_URL1
            : `${pathname}/@${data?.author?.username}/${data?.author?.user_id}`
        );
      }
    }
  };
  const handleForcePlayChange = (val: boolean) => {
    setForcePlay(val);
    setTimeout(() => {
      setForcePlay(undefined);
    }, 1000);
  };
  const mediaInformationProps = {
    data,
    onReportMedia,
    reportLoading,
    onLoveChanged,
    onRepost,
    isReposted,
    displayActions,
    isPublic,
    onFollow,
    onShare,
    followsCount,
    setIsShareModal,
    handleUserProfileClick,
    isDisableUserProfile,
  };
  useEffect(() => {
    if (
      videoWrapperRefCurrent &&
      screenfull &&
      typeof screenfull.on === "function"
    ) {
      screenfull.on("change", () => {
        if (screenfull.isFullscreen) {
          videoWrapperRefCurrent.classList.add(FULLSCREEN_CLASSNAME);
        } else {
          videoWrapperRefCurrent.classList.remove(FULLSCREEN_CLASSNAME);
        }
      });
    }
    return () => {
      if (screenfull && typeof screenfull.off === "function") {
        screenfull.off("change", () => {});
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoWrapperRefCurrent]);
  return (
    <>
      <div className={classes.wrapper}>
        <div
          className={cn(classes.videoWrapper, {
            [classes.fixedVideo]: fixedVideo && !isImage,
            [classes.isImage]: isImage,
          })}
          onMouseEnter={() => {
            if (!isImage) setHover(true);
          }}
          onMouseLeave={() => {
            if (isImage) setHover(false);
          }}
          ref={videoWrapperRef}
        >
          {isImage ? (
            isText ? (
              <div
                className={cn("image-placeholder", {
                  "not-source": !source,
                })}
              >
                {!!source && <img src={source} alt="thumbnail" />}
                <span
                  style={{
                    color: data?.background_image_text_color || "white",
                  }}
                >
                  {data?.desc}
                </span>
              </div>
            ) : (
              <img src={source} alt="thumbnail" />
            )
          ) : (
            <>
              <PreviewVideo
                source={source}
                videoClassName={classes.video}
                hideFullscreen={fixedVideo}
                defaultPlaying
                emdOverlay
                defaultMuted={isShare}
                forcePlay={forcePlay}
              />
              {fixedVideo && hover && (
                <div
                  className={classes.closeFixed}
                  onClick={() => {
                    if (onCloseFixed) onCloseFixed();
                    handleForcePlayChange(false);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </>
          )}
        </div>
        <MediaInformation {...mediaInformationProps} />
      </div>
      <ShareModal
        isOpen={isShareModal}
        onClose={() => {
          setIsShareModal(false);
        }}
        shareLink={`${origin}/public/post?id=${data?.id}&private-path=${pathname}`}
      />
    </>
  );
};

export default MediaDetail;

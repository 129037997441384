import cn from "classnames";
import React from "react";
import classes from "./SeasonBlock.module.scss";

interface Props {
  children: React.ReactNode;
  status?: string;
}

const Name = ({ children, status }: Props) => {
  return (
    <span className={cn(classes.name, classes[`status_${status}`])}>
      {children}
    </span>
  );
};

export default Name;

import * as dashboardActions from "src/store/actions/dashboard";
import * as regionsActions from "src/store/actions/regions";
import { useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {};
};

export const useActions = () => {
  return useStoreActions({
    ...dashboardActions,
    ...regionsActions,
  });
};

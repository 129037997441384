import cn from "classnames";
import RDropzone, { DropzoneProps } from "react-dropzone";
import classes from "./Dropzone.module.scss";

type Props = DropzoneProps & {
  className?: string;
  onChange: (tFile: any) => void;
  innerRef?: any;
  inputRef?: any;
  placeholder: React.ReactNode;
};

const Dropzone = ({
  className,
  onChange,
  innerRef,
  placeholder,
  inputRef,
  ...other
}: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <RDropzone {...other} onDrop={onChange} ref={innerRef}>
        {({ getRootProps, getInputProps }: any) => {
          return (
            <div
              {...getRootProps()}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <input {...getInputProps()} ref={inputRef} />
              {placeholder}
            </div>
          );
        }}
      </RDropzone>
    </div>
  );
};

export default Dropzone;

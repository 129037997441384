import * as sharePostActions from "src/store/actions/sharePost";
import { useReducerData, useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {
    previousPostId: useReducerData("sharePost", "sharePost.postId", ""),
    sharedMediaDetail: useReducerData("sharePost", "sharePost.data", {}),
    sharedMediaDetailLoading: useReducerData(
      "sharePost",
      "sharePost.loading",
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...sharePostActions,
  });
};

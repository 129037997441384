import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import AuthBreadcrumb from "src/components/AuthBreadcrumb";
import { getSubscriptionPlans } from "src/store/actions/auth";
import { getSeasons } from "src/store/actions/clue";
import { useStoreActions } from "src/store/hooks";
import Completed from "./Completed";
import Confirm from "./Confirm";
import { SelectedPlans } from "./Confirm/types";
import Payment from "./Payment";
import { PaymentProps } from "./Payment/types";
import Plans from "./Plans";
import { PlanProps } from "./Plans/Components/PlanCard/types";
import SelectAccess from "./SelectAccess";

const Subscription = () => {
  const { pathname } = useLocation();
  const actions = useStoreActions({
    getSubscriptionPlans,
    getSeasons,
  });
  const [selectedPlans, setSelectedPlans] = useState<SelectedPlans>({
    plan: {},
    payment: {},
  });

  const handleSelectedPlan = (plan: PlanProps) => {
    setSelectedPlans({ ...selectedPlans, plan });
  };

  const handlePayment = (payment: PaymentProps) => {
    setSelectedPlans({ ...selectedPlans, payment });
  };

  useEffect(() => {
    actions.getSubscriptionPlans();
    actions.getSeasons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComponent = () => {
    switch (pathname) {
      case "/subscription/select-access":
        return <SelectAccess />;
      case "/subscription/plan":
        return (
          <Plans
            handleSelectedPlan={handleSelectedPlan}
            selectedPlans={selectedPlans}
          />
        );
      case "/subscription/payment":
        return (
          <Payment
            handleSelectedPlan={handlePayment}
            selectedPlans={selectedPlans}
          />
        );
      case "/subscription/confirm":
        return <Confirm selectedPlans={selectedPlans} />;
      case "/subscription/completed":
        return <Completed />;
      default:
        return <Plans handleSelectedPlan={handleSelectedPlan} />;
    }
  };

  return (
    <div>
      <AuthBreadcrumb />
      {getComponent()}
    </div>
  );
};

export default Subscription;

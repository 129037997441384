// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ROLE_NAME } from "src/helpers/constants/role";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import socket from "src/helpers/socket";
import SharePostLayout from "src/layout/SharePostLayout";
import { getPaymentMethods, getUserProfile } from "src/store/actions/auth";
import {
  getUserNotificationCount,
  getUserNotifications,
} from "src/store/actions/notification";
import { getRegionList } from "src/store/actions/regions";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { getAccessLevel } from "src/utils/role";
import classes from "./App.module.scss";
import Cookie from "./components/Cookie";
import ScrollToTop from "./components/ScrollToTop";
import AuthLayout from "./layout/AuthLayout";
import PlayLayout from "./layout/PlayLayout";
import PrivateLayout from "./layout/PrivateLayout";
import PublicLayout from "./layout/PublicLayout";
import SubscriptionLayout from "./layout/SubscriptionLayout";
import WatchLayout from "./layout/WatchLayout";
import {
  announcementRoutes,
  engagementRoutes,
  prizesRoutes,
  searchRoutes,
} from "./routes";

let calledMe = 0;

const App = () => {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("referral") || "";
  const utmCompaign = searchParams.get("utm_campaign") || "";
  const actions = useStoreActions({
    getUserProfile,
    getPaymentMethods,
    getRegionList,
    getUserNotifications,
    getUserNotificationCount,
  });
  const userProfile = useUserProfile();
  const accessLevel = getAccessLevel(
    get(userProfile, "user.role.name", ROLE_NAME.FREE_ACCESS)
  );
  const isLoggedIn = useReducerData("auth", "isLoggedIn", false);
  const [callMeLoading, setCallMeLoading] = useState(false);

  const tawkWidgetId = process.env.REACT_APP_TAWK_WIDGET_ID || "";
  const tawkPropertyId = process.env.REACT_APP_TAWK_PROPERTY_ID || "";

  const loadMetadata = () => {
    actions.getPaymentMethods();
    actions.getRegionList();
    actions.getUserNotifications();
    actions.getUserNotificationCount();
  };

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem("referralCode", referralCode);
    }
    if (utmCompaign) {
      localStorage.setItem("utmCompaign", utmCompaign);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    calledMe = 0;
    if (isLoggedIn && calledMe === 0 && !callMeLoading) {
      setCallMeLoading(true);
      actions.getUserProfile((res: unknown) => {
        if (res) {
          // socket.start(res);
        } else {
          socket.stop();
        }
        calledMe = 1;
        setCallMeLoading(false);
      });
      loadMetadata();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, accessLevel]);

  return (
    <div className={classes.app}>
      <ScrollToTop />
      <Routes>
        <Route path="/subscription/*" element={<SubscriptionLayout />} />
        <Route path="/auth/*" element={<AuthLayout />} />
        <Route path="/subscriptions/*" element={<PrivateLayout />} />
        <Route path="/watch/*" element={<WatchLayout />} />
        <Route
          path="/announcements/*"
          element={
            <WatchLayout
              customRoutes={announcementRoutes}
              title="Announcements"
            />
          }
        />
        <Route
          path="/prizes/*"
          element={<WatchLayout customRoutes={prizesRoutes} title="Prizes" />}
        />
        <Route
          path="/engagement/*"
          element={
            <WatchLayout customRoutes={engagementRoutes} title="Engagement" />
          }
        />
        <Route
          path="/search/*"
          element={<WatchLayout customRoutes={searchRoutes} title=" " />}
        />
        <Route path="/play/*" element={<PlayLayout />} />
        <Route path="/user/*" element={<PrivateLayout />} />
        <Route path="/public/*" element={<SharePostLayout />} />
        <Route path="/*" element={<PublicLayout />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Cookie />
      <TawkMessengerReact propertyId={tawkPropertyId} widgetId={tawkWidgetId} />
    </div>
  );
};

export default App;

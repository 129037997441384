import cn from "classnames";
import { ModalBody, ModalHeader } from "reactstrap";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Text from "src/components/Text";
import ColdExamplePng from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/cold-example.png";
import hotAndColdBarometer from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/hot-and-cold-barometer.png";
import HotColdExample1Png from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/hot-cold-example-1.png";
import HotColdExample2Png from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/hot-cold-example-2.png";
import HotColdExample3Png from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/hot-cold-example-3.png";
import HotExamplePng from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/hot-example.png";
import DetailsBlock from "../RangeFinderModal/RangeFinderInfo/DetailsBlock";
import AvailableButton from "./AvailableButton";
import classes from "./HotColdBarometerModal.module.scss";
import MobileTag from "./MobileTag";

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

const HotColdBarometerModal = ({ className = "", isOpen, onClose }: Props) => {
  return (
    <>
      <Modal
        backdrop
        className={cn(classes.modalWrapper, className)}
        bodyClassName={classes.modalBody}
        isOpen={isOpen}
        toggle={onClose}
      >
        <ModalHeader
          close={
            <Button
              buttonText="Close"
              rightIcon={<CloseIcon />}
              variant="link"
              onClick={onClose}
            />
          }
        >
          <div className={classes.modalHeaderContent}>
            <img src={hotAndColdBarometer} />
            <Text color="#fff" size={40} fontWeight="semibold">
              Hot & Cold Barometer
            </Text>
          </div>
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <div className={classes.modalBodyInner}>
            <div className={classes.description}>
              <Text
                style={{ lineHeight: "normal" }}
                color="#fff"
                size={16}
                fontWeight="medium"
              >
                The Hot & Cold Barometer Advantage Clue allows a player to
                activate a hot and cold interface that shows them if they are
                generally moving towards or away from the Lamps 5-mile radius
                zone perimeter. The Barometer lasts for 10 continuous minutes.
                <br />
                <br />
                The Barometer is designed to get you within 10 miles of the
                lamp’s 5-mile radius zone perimeter. Once you are within that
                range, this clue can no longer be used.
                <br />
                <br />
                Make sure your notifications are on and your location access is
                enabled for the TG$ app.
              </Text>
            </div>
            <DetailsBlock title="Details" className={classes.detailsBlock}>
              <>
                <div className="how-it-works">
                  <b>How It Works:</b>
                  <ul>
                    <li>Click “Buy Now.”</li>
                    <li>A modal will appear when you are ready.</li>
                    <li>
                      Click “Start.” At this time, the Barometer time will start
                      counting down.
                    </li>
                    <li>
                      Begin walking in any direction and the barometer’s needle
                      will begin moving into the cold (blue) section, or the
                      warm (red) section of the barometer. As you get further or
                      closer to the lamp radius zone, the needle will go deeper
                      into the warm/cold section.
                    </li>
                    <li>
                      Do not drive any motorized vehicle while looking at any
                      advantage clue.
                    </li>
                    <li>Only works on mobile device.</li>
                  </ul>
                </div>
                <div>
                  <div>
                    <b>Additional Information:</b>
                  </div>
                  <br />
                  <p>
                    The Hot & Cold Barometer indicates if the player is getting
                    further away from or closer to the lamp's 5-mile radius zone
                    perimeter by the needle moving into the cold(blue) section
                    when getting further away, or into the hot(red) section when
                    getting closer.
                  </p>
                  <p>
                    If the player is already within the 5-mile lamp zone radius,
                    the “Buy Now” button is deactivated.
                  </p>
                  <div>
                    <b>Results State:</b>
                  </div>
                  <br />
                  <p>
                    When a player successfully gets within 10 miles of the
                    lamp's 5-mile radius zone perimeter, their screen will
                    change to red, the barometer needle will be pointing to the
                    far right side, and the text “Getting Close” will be
                    displayed. Following this, a modal will display informing
                    the player that they are now within 10 miles.
                  </p>
                  <div className={classes.images}>
                    <img src={HotColdExample1Png} alt="hot-cold-example" />
                    <img src={HotColdExample2Png} alt="hot-cold-example" />
                  </div>
                  <br />
                  <br />
                  <br />
                  <p>
                    If the player does not get within 10 miles of the lamps zone
                    in the given 10 minutes, the time runs out and a modal is
                    displayed, “Time’s Up!”
                  </p>
                  <div className={classes.images}>
                    <img src={HotColdExample3Png} alt="hot-cold-example" />
                  </div>
                </div>
              </>
            </DetailsBlock>
            <div className={classes.content}>
              <div className={classes.details}>
                <Text className="mb-3" size={18} color="#fff" fontWeight="bold">
                  Examples
                </Text>
                <div className={classes.infoWrapper}>
                  <Text className={classes.text} size={16}>
                    The Hot & Cold Barometer will let you know if you are
                    getting further away from or closer to the lamp’s 5-mile
                    radius zone perimeter, by the needle moving into the cold
                    (blue) section when getting further away or into the hot
                    (red) section when getting closer.
                  </Text>
                  <div className={classes.info}>
                    <div className={classes.example}>
                      <Text size={14} color="#016CB5" fontWeight="bold">
                        COLD
                      </Text>
                      <img src={ColdExamplePng} />
                    </div>
                    <div className={classes.example}>
                      <Text size={14} color="#DA3449" fontWeight="bold">
                        HOT
                      </Text>
                      <img src={HotExamplePng} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.buyActions}>
                <div>
                  <MobileTag>Mobile Only</MobileTag>
                </div>
                <div>
                  <AvailableButton>
                    <>
                      <img src={hotAndColdBarometer} />
                      <span>Available on the Mobile App</span>
                    </>
                  </AvailableButton>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default HotColdBarometerModal;

/* eslint-disable react/no-multi-comp */
import get from "lodash/get";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ROLE_NAME } from "src/helpers/constants/role";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { privateRoutes } from "src/routes";
import { getAccessLevel, isAllowedRoute } from "src/utils/role";
import { isLoggedIn } from "src/utils/utils";
import Footer from "./Footer";
import Header from "./Header";
import classes from "./styles.module.scss";

const getRoutes = (
  routes: {
    path: string;
    name: string;
    component: () => JSX.Element;
    layout: string;
    permission: string[];
  }[],
  accessLevel: string
) => {
  return routes.map(({ path, component: Component, permission }, key) => {
    const isAllowed = isAllowedRoute(permission, accessLevel);
    if (isAllowed) {
      return <Route path={path} element={<Component />} key={key} />;
    }
    return (
      <Route
        path={path}
        element={<Navigate to="/subscriptions" replace />}
        key={key}
      />
    );
  });
};

const PrivateLayout: React.FC = () => {
  const navigate = useNavigate();
  const userProfile = useUserProfile();
  const accessLevel = getAccessLevel(
    get(userProfile, "user.role.name", ROLE_NAME.FREE_ACCESS)
  );

  useEffect(() => {
    const isLogIn = isLoggedIn();

    if (!isLogIn) {
      navigate("/auth/access-info");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.container}>
      <Header />
      {
        <Routes>
          {getRoutes(privateRoutes, accessLevel)}
          <Route path="/*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      }
      <Footer />
    </div>
  );
};

export default PrivateLayout;

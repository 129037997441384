import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import cx from "classnames";
import get from "lodash/get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Text from "src/components/Text";
import { DEFAULT_DATE_FORMAT } from "src/helpers/constants";
import { useUserActions } from "src/utils/useActions";
import { useUserData } from "src/utils/useData";
import classes from "./DeviceManagement.module.scss";

type Props = {
  wrapperClassName?: string;
};

const DeviceManagement: React.FC<Props> = ({ wrapperClassName = "" }) => {
  const { getDevices, removeDevice } = useUserActions();
  const [removeDeviceModal, setRemoveDeviceModal] = useState({
    isOpen: false,
    id: 0,
  });
  const { data: currentDeviceData = null } = useVisitorData();
  const current_device_token = get(currentDeviceData, "visitorId", "");
  const {
    devices,
    removeDevice: { loading: removeLoading },
  } = useUserData();
  const { data: deviceList } = devices;

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveDevice = async () => {
    const response = (res: any) => {
      const status = get(res, "status", 0);
      const message = get(res, "message", null);

      if (status) {
        toast.dark(message);
        setRemoveDeviceModal({
          isOpen: false,
          id: 0,
        });
      } else {
        toast.error("Something Went Wrong.");
        setRemoveDeviceModal({
          isOpen: false,
          id: 0,
        });
      }
    };
    await removeDevice(
      {
        id: removeDeviceModal.id,
        current_device_token: current_device_token as string,
      },
      response
    );
  };

  return (
    <>
      {removeDeviceModal.isOpen ? (
        <Modal
          backdrop
          className={cx(classes.closeModalWrapper)}
          bodyClassName={classes.modalBody}
          isOpen={removeDeviceModal.isOpen}
          toggle={() =>
            setRemoveDeviceModal({
              isOpen: false,
              id: 0,
            })
          }
        >
          <div className={classes.modalHeader}>
            <Text color="#fff" className="mb-4" size={40} fontFamily="milonga">
              Remove Device
            </Text>
            <Text color="#fff" size={24}>
              Would you like to remove this device from your profile?
            </Text>
          </div>
          <div className={classes.actions}>
            <Button
              buttonText={
                <Text
                  color="#fff"
                  fontWeight="bold"
                  fontFamily="impact"
                  size={36}
                >
                  Delete
                </Text>
              }
              buttonColor="secondary"
              onClick={() => handleRemoveDevice()}
              // disabled={!isValid || !dirty}
              loading={removeLoading}
              type="submit"
              buttonClassName={classes.submit}
            />
            <div className={classes.cancelWrapper}>
              <Button
                buttonText={
                  <Text color="#fff" fontFamily="impact" size={36}>
                    Cancel
                  </Text>
                }
                onClick={() =>
                  setRemoveDeviceModal({
                    isOpen: false,
                    id: 0,
                  })
                }
                buttonColor="secondary"
                variant="link"
                buttonClassName={classes.cancel}
              />
            </div>
          </div>
        </Modal>
      ) : null}
      <div className={cx(classes.wrapper, wrapperClassName)}>
        <Text className="mb-1" color="#fff" size={24}>
          Device Management
        </Text>
        <Text className="mb-3" size={12} color="#fff">
          You may have 2 devices per subscription.
        </Text>
        <div className={classes.cardWrapper}>
          {deviceList.map((device, i) => {
            return (
              <div className={classes.deviceCard} key={i}>
                <div className={classes.content}>
                  <Text color="#fff" size={18}>
                    {device.device_name}
                  </Text>
                  <Text color="#fff" size={14}>
                    {device.registered_at
                      ? moment(device.registered_at).format(DEFAULT_DATE_FORMAT)
                      : "-"}
                  </Text>
                </div>
                <div className={classes.action}>
                  {current_device_token &&
                    current_device_token !== device.device_token && (
                      <Button
                        buttonText={
                          <Text color="#fff" size={14}>
                            Remove Device
                          </Text>
                        }
                        onClick={() =>
                          setRemoveDeviceModal({
                            isOpen: true,
                            id: device?.id,
                          })
                        }
                        buttonColor="secondary"
                        variant="link"
                        buttonClassName={classes.cancel}
                      />
                    )}
                </div>
              </div>
            );
          })}
        </div>
        <Text size={12} color="#fff">
          If you are not able to access both your registered devices, please
          email support@treasuregames.fun to register a new device to your
          account
        </Text>
      </div>
    </>
  );
};

export default DeviceManagement;

import * as authActions from "src/store/actions/auth";
import * as mediaActions from "src/store/actions/media";
import * as regionsActions from "src/store/actions/regions";
import * as userActions from "src/store/actions/user";
import * as watchActions from "src/store/actions/watch";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { ChannelFollower } from "src/types/watch";

export const useIndexData = () => {
  return {
    subscription: useReducerData("auth", "userProfile.data.subscription", {}),
    userFeed: useReducerData("watch", "watchUserFeed.data", {}),
    userRepostFeed: useReducerData("watch", "watchUserRepostFeed.data", {}),
    watchChannel: useReducerData("watch", "watchChannel.data", {}),
    watchSubChannel: useReducerData("watch", "watchSubChannel.data", {}),
    watchUserPostsObj: useReducerData("watch", "watchUserPosts.data", {}),
    channelFollowers: useReducerData(
      "watch",
      "channelFollowers.data",
      []
    ) as ChannelFollower[],
    channelFollowersLoading: useReducerData(
      "watch",
      "channelFollowers.loading",
      false
    ),
  };
};

export const useSubData = () => {
  return {
    userFeed: useReducerData("watch", "watchUserFeed.data", {}),
    userRepostFeed: useReducerData("watch", "watchUserRepostFeed.data", {}),
    userFeedLoading: useReducerData("watch", "watchUserFeed.loading", false),
    userRepostFeedLoading: useReducerData(
      "watch",
      "watchUserRepostFeed.loading",
      false
    ),
    watchUser: useReducerData("watch", "watchUser.data.user", {}),
    watchChannel: useReducerData("watch", "watchChannel.data", {}),
    watchChannelLoading: useReducerData("watch", "watchChannel.loading", false),
  };
};

export const useSub1Data = () => {
  return {
    watchSubChannel: useReducerData("watch", "watchSubChannel.data", {}),
    watchSubChannelLoading: useReducerData(
      "watch",
      "watchSubChannel.loading",
      false
    ),
  };
};

export const usePlayingMediaData = () => {
  return {
    mediaDetail: useReducerData("media", "mediaDetail.data", {}),
    mediaDetailLoading: useReducerData("media", "mediaDetail.loading", false),
    mediaRelated: useReducerData("media", "mediaRelated.data", []),
    mediaRelatedLoading: useReducerData("media", "mediaRelated.loading", false),
    reportMediaLoading: useReducerData("media", "reportMedia.loading", false),
  };
};

export const useUserDetailsData = () => {
  return {
    watchUserLoading: useReducerData("watch", "watchUser.loading", false),
    watchUser: useReducerData("watch", "watchUser.data.user", {}),
    userFeed: useReducerData("watch", "watchUserFeed.data", {}),
    userRepostFeed: useReducerData("watch", "watchUserRepostFeed.data", {}),
    userFeedLoading: useReducerData("watch", "watchUserFeed.loading", false),
    userRepostFeedLoading: useReducerData(
      "watch",
      "watchUserRepostFeed.loading",
      false
    ),
  };
};

export const useMainData = () => {
  return {
    allMedia: useReducerData("media", "allMedia.data.channels", []),
    allMediaLoading: useReducerData("media", "allMedia.loading", false),
  };
};

export const useManageContentData = () => {
  return {
    postStatusData: useReducerData("media", "postStatus.data", {}),
    postStatus: useReducerData("media", "postStatus.data.status", ""),
    deletePostLoading: useReducerData("media", "deletePost.loading", false),
    watchUserPostsObj: useReducerData("watch", "watchUserPosts.data", {}),
    watchUserPostsLoading: useReducerData(
      "watch",
      "watchUserPosts.loading",
      false
    ),
    updateMediaPostLoading: useReducerData(
      "media",
      "updateMediaPost.loading",
      false
    ),
  };
};

export const usePostDetailsData = () => {
  return {
    deletePostLoading: useReducerData("media", "deletePost.loading", false),
    postDetailsLoading: useReducerData("media", "postDetails.loading", false),
    postDetails: useReducerData("media", "postDetails.data", {}),
    updateMediaPostLoading: useReducerData(
      "media",
      "updateMediaPost.loading",
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
    ...watchActions,
    ...mediaActions,
    ...regionsActions,
    ...userActions,
  });
};

import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import get from "lodash/get";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import { ForgotPasswordProps } from "src/api/auth";
import copyrightIcon from "src/assets/icons/copyright-icon.svg";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Text from "src/components/Text";
import Typography from "src/components/Typography";
import CheckIcon from "src/pages/Auth/assets/images/check-icon.png";
import { useAuthActions } from "src/utils/useActions";
import { useAuthData } from "src/utils/useData";
import { currentYear } from "src/utils/utils";
import classes from "./ForgotPassword.module.scss";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword } = useAuthActions();

  const {
    forgotPassword: { loading },
  } = useAuthData();

  const [error, setError] = useState("");
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Username Or Email is required"),
  });

  const handleSubmit = async (
    values: ForgotPasswordProps,
    formikHelpers: FormikHelpers<ForgotPasswordProps>
  ) => {
    const res = await forgotPassword(values);
    const status = get(res, "status", 0);
    const data = get(res, "data", null);

    const message = get(res, "message", "Something went wrong");

    if (status) {
      setShowEmailSuccess(true);
      formikHelpers.resetForm();
      toast.dark(message);
    } else {
      if (data) {
        const { message } = data;
        if (typeof message === "string") {
          setError(message);
        } else {
          const errorMessage = Object.values(message).flatMap((b) => b);
          setError(errorMessage[0] as string);
        }
      } else {
        setError(message);
      }
    }
  };

  return (
    <div className={classes.container}>
      {showEmailSuccess ? (
        <div className={classes.emailSentMainWrapper}>
          <img className={classes.checkIcon} src={CheckIcon} alt="check-icon" />
          <div className={classes.content}>
            <Typography className={classes.welcomeText} fontFamily="milonga">
              Email Sent
            </Typography>
            <Typography
              className={classes.infoText}
              variant="p18"
              fontFamily="inter"
            >
              Check your email and open the link we sent to continue.
            </Typography>
          </div>
          <div className={classes.footer}>
            <Typography variant="p12">
              <span>
                <img src={copyrightIcon} alt="copyright-icon" />
              </span>{" "}
              Copyright {currentYear}{" "}
            </Typography>
            <div className={classes.footerLink}>
              <a href="#">Privacy</a>
              <Typography variant="p12"> • </Typography>
              <a href="#"> Terms and Conditions</a>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.header}>
            <Text className="mb-4" fontFamily="milonga" size={40} color="#fff">
              Forgot Password
            </Text>
            <Text className="mb-4" size={24} color="#fff">
              Enter your email and we’ll send you a link to reset your password.
            </Text>
          </div>
          <div className={classes.mainWrapper}>
            <div>
              <Formik
                onSubmit={(values, formikHelpers) =>
                  handleSubmit(values, formikHelpers)
                }
                validationSchema={forgotPasswordSchema}
                initialValues={{
                  email: "",
                }}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleChange,
                  isValid,
                  dirty,
                }: FormikProps<ForgotPasswordProps>) => (
                  <Form
                    className={cx(classes.signupInputs, "d-flex flex-column")}
                  >
                    <FormGroup className={classes.formGroup}>
                      <Input
                        labelClassName={classes.label}
                        label="Email Address"
                        required={true}
                        value={values.email}
                        placeholder="example@email.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.email}
                        touched={touched.email}
                        name="email"
                      />
                    </FormGroup>

                    <div className={classes.submit}>
                      <Button
                        buttonText="Send link to email"
                        buttonColor="secondary"
                        disabled={!isValid || !dirty}
                        loading={loading}
                        type="submit"
                        buttonClassName={classes.button}
                      />
                    </div>
                    <Typography className={classes.errorMessage}>
                      {error}
                    </Typography>

                    <div className={classes.mobileSignin}>
                      <Button
                        buttonColor="secondary"
                        onClick={() => navigate("/auth/access-info")}
                        buttonText={
                          <Typography variant="p16">Sign Up</Typography>
                        }
                        variant="link"
                      />
                    </div>
                    <div className={classes.footer}>
                      <Typography variant="p12">
                        <span>
                          <img src={copyrightIcon} alt="copyright-icon" />
                        </span>{" "}
                        Copyright {currentYear}{" "}
                      </Typography>
                      <div className={classes.footerLink}>
                        <a href="/a/privacy-policy-ip-policy" target="_blank">
                          Privacy
                        </a>
                        <Typography variant="p12"> • </Typography>
                        <a href="/a/terms-and-conditions" target="_blank">
                          Terms and Conditions
                        </a>
                        <Typography variant="p12"> • </Typography>
                        <a href="/a/tg-risk-waiver" target="_blank">
                          Risk Waiver
                        </a>
                      </div>
                    </div>
                    <div className={classes.mobileNext}>
                      <Button
                        buttonText="Send link to email"
                        buttonColor="secondary"
                        disabled={!isValid || !dirty}
                        loading={loading}
                        type="submit"
                        buttonClassName={classes.button}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      )}
      <div></div>
    </div>
  );
};

export default ForgotPassword;

import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronRight,
  faPencil,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { User } from "src/api/auth";
import Button from "src/components/Button";
import Spinner from "src/components/Spinner";
import Typography from "src/components/Typography";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import { useActions, usePlayingMediaData } from "src/pages/Watch/selectorData";
import { NormalTab } from "src/types";
import { ChannelUserProfile } from "src/types/channel";
import { numberFormatter } from "src/utils/number";
// import { getDisplayName } from "src/utils/text";
import SocialFollow from "../SocialFollow";
import classes from "./ChannelInfo.module.scss";

interface Props {
  isMyChannel?: boolean;
  userProfile?: ChannelUserProfile & Partial<User>;
  onDescriptionSave?: (val: string) => void;
  tabs?: NormalTab[];
  tab?: string;
  onTabChanged?: (tTab: string) => void;
  loading?: boolean;
}

const DESCRIPTION =
  "Description of the user or channel can go here. It’s their “about”.Morem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.";

const ChannelInfo = ({
  isMyChannel,
  userProfile,
  onDescriptionSave,
  tabs,
  tab,
  onTabChanged,
  loading,
}: Props) => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPath = useCurrentPath();
  const { mediaDetail } = usePlayingMediaData();
  const {
    updateChannelFollowStatus,
    getChannelFollowers,
    syncSetMediaDetails,
  } = useActions();

  const isChannelFollowersPage =
    currentPath ===
      "/watch/:channelSlug/playing-media/:id/:name/:userId/followers" ||
    pathname === "/watch/my-channel/followers" ||
    currentPath.includes("/followers");
  const [description, setDescription] = useState<string>(DESCRIPTION);
  const [isExpanded, setIsExpanded] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const descLength = description.length;
  const postsCount = userProfile?.posts_count || 0;
  const followsCount = userProfile?.follows_count || 0;

  useEffect(() => {
    if (!!userProfile && userProfile?.desc !== description) {
      setDescription(userProfile?.desc || "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.desc]);

  useEffect(() => {
    if (userProfile?.id) {
      getChannelFollowers(userProfile?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.id]);

  const followChannel = (currentState: boolean) => {
    updateChannelFollowStatus(
      userProfile?.id as number,
      !currentState,
      (res: any) => {
        if (res.status) {
          if (!currentState) {
            syncSetMediaDetails({
              author: {
                ...mediaDetail?.author,
                follows_count: followsCount + 1,
              },
            });
          } else {
            syncSetMediaDetails({
              author: {
                ...mediaDetail?.author,
                follows_count: followsCount - 1,
              },
            });
          }
          userProfile?.id && getChannelFollowers(userProfile?.id);
        }
      }
    );
  };

  return (
    <div className={classes.channelInfo}>
      {loading && <Spinner wholePage />}
      <div className={classes.details}>
        <div className={classes.userImage}>
          {!!userProfile?.avatar_path && (
            <img src={userProfile?.avatar_path} alt="profile-pic" />
          )}
        </div>
        <div className={classes.content}>
          <div className={classes.name}>
            {!!userProfile?.username && (
              <>
                <span>@</span>
                {userProfile.username}
              </>
            )}
          </div>
          <div className={classes.detail}>
            <span>
              {numberFormatter(postsCount, 1)} post{postsCount !== 1 && "s"}
            </span>
            <span
              {...(isChannelFollowersPage
                ? {}
                : {
                    role: "button",
                    onClick: () => navigate(`${pathname}/followers`),
                  })}
            >
              {numberFormatter(followsCount, 1)} Follower
              {followsCount !== 1 && "s"}
              <FontAwesomeIcon
                size="sm"
                className="mx-2"
                icon={faChevronRight}
              />
            </span>
          </div>
          <div className={classes.description}>
            {edit && !isSaved ? (
              <>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  ref={inputRef}
                />
              </>
            ) : (
              <div className={classes.content}>
                <div className={cx({ [classes.ellipse]: !isExpanded })}>
                  {description}
                </div>
                {descLength > 84 && (
                  <div className={classes.expandIcon}>
                    {isExpanded ? (
                      <FontAwesomeIcon
                        onClick={() => setIsExpanded(false)}
                        icon={faXmark}
                      />
                    ) : (
                      <FontAwesomeIcon
                        onClick={() => setIsExpanded(true)}
                        icon={faChevronRight}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.action}>
        <div className={classes.buttonWrapper}>
          {isMyChannel ? (
            <>
              {edit || isSaved ? (
                <div className={classes.editActions}>
                  <Button
                    onClick={() => {
                      setDescription(description);
                      setIsSaved(true);
                      setEdit(false);
                      if (onDescriptionSave) onDescriptionSave(description);
                      setTimeout(() => {
                        setIsSaved(false);
                      }, 2000);
                    }}
                    buttonColor="gray"
                    buttonText={`Save${isSaved ? "d" : ""}`}
                    radius="lg"
                    leftIcon={
                      isSaved && (
                        <FontAwesomeIcon color="#fff" icon={faCheckCircle} />
                      )
                    }
                    gapSize="xs"
                    flex
                  />
                  {!isSaved && (
                    <Button
                      onClick={() => {
                        setDescription(userProfile?.desc || "");
                        setEdit(false);
                      }}
                      className={classes.cancel}
                      color="link"
                      buttonText="Cancel"
                    />
                  )}
                </div>
              ) : (
                <div
                  className={classes.edit}
                  onClick={() => {
                    setEdit(true);
                    setTimeout(() => {
                      const tInputRefCurrent = inputRef?.current as any;
                      if (tInputRefCurrent) {
                        tInputRefCurrent.setSelectionRange(
                          descLength,
                          descLength
                        );
                        tInputRefCurrent.focus();
                      }
                    }, 0);
                  }}
                >
                  <FontAwesomeIcon icon={faPencil} />
                  <Typography variant="p16" className="mb-0">
                    Edit Description
                  </Typography>
                </div>
              )}
            </>
          ) : (
            <SocialFollow
              isPublic
              onClick={(currentState) => {
                followChannel(currentState);
                return !currentState;
              }}
            />
          )}
        </div>
      </div>
      {!!tabs && tabs?.length > 0 && (
        <div className={classes.tabs}>
          <ul>
            {tabs.map((tTab: NormalTab) => {
              return (
                <li
                  key={tTab.value}
                  className={cx({
                    [classes.activeTab]: tTab.value === tab,
                  })}
                >
                  <span
                    onClick={() => {
                      onTabChanged && onTabChanged(tTab.value);
                    }}
                  >
                    {tTab.label}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChannelInfo;

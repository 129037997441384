import cn from "classnames";
import React, { useEffect } from "react";
import classes from "./AppDrawer.module.scss";

interface AppDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  direction?: "left" | "right" | "top" | "bottom";
  overlay?: boolean;
  drawerClassName?: string;
  duration?: "fast" | "slow";
}

const AppDrawer: React.FC<AppDrawerProps> = ({
  isOpen,
  onClose,
  children,
  direction = "left",
  overlay = true,
  drawerClassName = "",
  duration = "",
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={cn(classes.overlay, {
          [classes.open]: isOpen,
          [classes.noBg]: !overlay,
          [classes[duration]]: !!duration,
        })}
        onClick={onClose}
      ></div>
      <div
        className={cn(
          classes.drawer,
          classes[direction],
          classes[duration],
          drawerClassName,
          {
            [classes.open]: isOpen,
          }
        )}
      >
        {children}
      </div>
    </>
  );
};

export default AppDrawer;

import React from "react";
import { useNavigate } from "react-router";
import Tsg from "src/assets/images/tgs-button.svg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import Carpet from "src/pages/PreviewPage/assets/images/carpet.png";
import CtaBackground from "src/pages/PreviewPage/assets/images/cta-bg.png";
import GirlGenie from "src/pages/PreviewPage/assets/images/girl-genie.png";
import classes from "./styles.module.scss";

interface Props {
  redirectSignupUrl?: string;
}

const SubscribeCTA = ({ redirectSignupUrl = "" }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${CtaBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div
        className={classes.container}
        style={{
          backgroundImage: `url(${GirlGenie})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "content",
          backgroundPosition: "100% 30%",
        }}
      >
        <div className={classes.content}>
          <Typography
            className={classes.title}
            fontWeight="bold"
            fontFamily="arial"
          >
            Once you have set up your TG$ account and subscribed to Wish Lamp$
            you can download the full access{" "}
            <img src={Tsg} width={65} height={65} alt="Icon not found" /> mobile
            app. You can play on the TG$ mobile app or the web app or both.
          </Typography>
          <br />
          <Typography
            fontStyle="italic"
            fontFamily="merriweather"
            fontWeight="bold"
            className={classes.italicText}
          >
            What will you choose; the $<span className={classes.arvo}>1</span>
            Million in Cash or the <span className={classes.arvo}>3</span> Real
            Life Wishes...?
          </Typography>
          <br />
          <Typography
            className={classes.haveFunText}
            fontWeight="bold"
            fontFamily="arial"
          >
            Have FUN!
          </Typography>
          <Button
            onClick={() => navigate(redirectSignupUrl)}
            buttonColor="secondary"
            buttonText="SUBSCRIBE TO WISH LAMP$"
          />
        </div>
        <img className={classes.carpetImage} src={Carpet} alt="carpet-image" />
      </div>
    </div>
  );
};

export default SubscribeCTA;

import cx from "classnames";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import Button from "src/components/Button";
import Text from "src/components/Text";
import Typography from "src/components/Typography";
import { ROLE_LEVEL } from "src/helpers/constants/role";
import LeftIcon from "src/pages/Home/assets/icons/left-icon.svg";
import RightIcon from "src/pages/Home/assets/icons/right-icon.svg";
import Game1 from "src/pages/Home/assets/images/game-1.svg";
import LeftCurtain from "src/pages/Home/assets/images/left-curtain.png";
import RightCurtain from "src/pages/Home/assets/images/right-curtain.png";
import Chest from "src/pages/Subscriptions/assets/chest.png";
import Cubes from "src/pages/Subscriptions/assets/cubes.png";
import Eggs from "src/pages/Subscriptions/assets/eggs.png";
import classes from "./styles.module.scss";

type Props = {
  accessLevel: string;
};

const UpcomingGames: React.FC<Props> = ({ accessLevel = "" }) => {
  const navigate = useNavigate();
  const sliderRef = useRef<Slider | null>(null);
  const [state, setState] = useState(0);
  const isFreeAccess = accessLevel === ROLE_LEVEL.FREE_ACCESS;

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,

    slidesToShow: 2.5,
    slidesToScroll: 1,
    afterChange: (current: number) => {
      setState(current);
    },

    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    ...(isFreeAccess
      ? [
          {
            img: Game1,
            title: "Wish Lamp$",
            active: true,
          },
        ]
      : []),
    {
      img: Eggs,
      title: "Coming Soon",
      active: false,
    },
    {
      img: Cubes,
      title: "Coming Soon",
      active: false,
    },
    {
      img: Chest,
      title: "Coming Soon",
      active: false,
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <Typography fontFamily="milonga">Games Coming Soon...</Typography>
        </div>
        <div className={classes.sliderWrapper}>
          {state < 1.5 && (
            <div
              className={cx(classes.shadows, {
                [classes.activeShadow]: isFreeAccess,
              })}
              style={{
                right: "-16px",
                background:
                  "linear-gradient(270deg, #141414 33.23%, rgba(20, 20, 20, 0.04) 94.61%)",
              }}
            ></div>
          )}
          {state !== 0 && (
            <div
              className={cx(classes.shadows, {
                [classes.activeShadow]: isFreeAccess,
              })}
              style={{
                left: "-16px",
                background:
                  "linear-gradient(90deg, #141414 33.23%, rgba(20, 20, 20, 0.04) 94.61%)",
              }}
            ></div>
          )}
          <div
            className={classes.leftIcon}
            onClick={() => sliderRef.current?.slickPrev()}
          >
            <img alt="left-icon" src={LeftIcon} />
          </div>
          <div
            className={classes.RightIcon}
            onClick={() => sliderRef.current?.slickNext()}
          >
            <img alt="right-icon" src={RightIcon} />
          </div>
          <Slider ref={sliderRef} {...settings}>
            {data?.map((game, i) => (
              <div key={i} className={classes.game}>
                <div className={classes.gameWrapper}>
                  <div
                    className={classes.imageWrapper}
                    style={{
                      backgroundImage: `url(${game.img})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "220px",
                      backgroundPosition: "center",
                    }}
                  >
                    {!game.active && (
                      <>
                        <img
                          className={classes.leftCurtain}
                          src={LeftCurtain}
                          alt="left-curtain"
                        />
                        <img
                          className={classes.rightCurtain}
                          src={RightCurtain}
                          alt="right-curtain"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className={classes.text}>
                  <Typography fontFamily="impact">{game.title}</Typography>
                </div>
                <div className={classes.cardBottom}>
                  {game.active && (
                    <Button
                      buttonClassName={classes.button}
                      onClick={() => navigate("/preview")}
                      buttonColor="secondary"
                      buttonText={
                        <Text
                          className={classes.buttonText}
                          size={28}
                          fontFamily="impact"
                        >
                          Find Out More
                        </Text>
                      }
                    />
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default UpcomingGames;

import { Reducer } from "redux";
import { NotificationData } from "src/types/notification";
import { handleData } from "../middlewares/handleData";
import Types from "../types/notification";

const initialState = {
  userNotifications: {
    loading: false,
    data: [],
    error: "",
  },
  userNotificationCount: {
    loading: false,
    data: 0,
    error: "",
  },
  viewUserNotification: {
    loading: false,
    data: {},
    error: "",
  },
  openUserNotification: {
    loading: false,
    data: {},
    error: "",
  },
};

const NotificationReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.SYNC_SET_USER_NOTIFICATION_COUNT:
      return {
        ...state,
        userNotificationCount: {
          ...state.userNotificationCount,
          data: payload,
        },
      };
    case Types.SYNC_SET_VIEW_NOTIFICATION:
      return {
        ...state,
        userNotifications: {
          ...state.userNotifications,
          data: state.userNotifications.data.map(
            (notification: NotificationData) => {
              if (payload.ids.includes(notification._id)) {
                notification.view = 1;
              }
              return notification;
            }
          ),
        },
      };
    case Types.GET_USER_NOTIFICATIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          userNotifications: {
            ...prevState.userNotifications,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            userNotifications: {
              loading: false,
              error: "",
              data: payload.data,
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          userNotifications: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_USER_NOTIFICATION_COUNT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          userNotificationCount: {
            ...prevState.userNotificationCount,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            userNotificationCount: {
              loading: false,
              error: "",
              data: payload.data,
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          userNotificationCount: {
            loading: false,
            data: 0,
            error: payload,
          },
        }),
      });
    case Types.VIEW_USER_NOTIFICATION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          viewUserNotification: {
            ...prevState.viewUserNotification,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            viewUserNotification: {
              loading: false,
              error: "",
              data: payload.data,
            },
            userNotifications: {
              ...prevState.userNotifications,
              data: prevState.userNotifications.data.map(
                (notification: NotificationData) => {
                  if (meta.payload.ids.includes(notification._id)) {
                    notification.view = 1;
                  }
                  return notification;
                }
              ),
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          viewUserNotification: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.OPEN_USER_NOTIFICATION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          openUserNotification: {
            ...prevState.openUserNotification,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            openUserNotification: {
              loading: false,
              error: "",
              data: payload.data,
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          openUserNotification: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CLEAR_USER_NOTIFICATION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          userNotifications: {
            ...prevState.userNotifications,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            userNotifications: {
              loading: false,
              error: "",
              data: [],
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          userNotifications: {
            loading: false,
            error: payload,
          },
        }),
      });

    default:
      return state;
  }
};

export default NotificationReducer;

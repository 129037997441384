import cn from "classnames";
import { ReactComponent as ExternalIcon } from "src/assets/icons/external-icon.svg";
import classes from "./ExternalBlock.module.scss";

interface Props {
  className?: string;
  title: string;
  onClick?: () => void;
}

const ExternalBlock = ({ className, title, onClick }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span onClick={onClick}>
        <span>{title}</span>
        <ExternalIcon />
      </span>
    </div>
  );
};

export default ExternalBlock;

import cn from "classnames";
import { ReactComponent as EyeIcon } from "src/assets/icons/eye-icon.svg";
import { ReactComponent as HeartIcon } from "src/assets/icons/heart-icon.svg";
import { MediaInfo } from "src/types/media";
import { getPostDate } from "src/utils/date";
import { getIsImage, getIsText } from "src/utils/media";
import { numberFormatter } from "src/utils/number";
import WatchVideo from "../Video";
import classes from "./MediaBlock.module.scss";

interface Props {
  onClick?: () => void;
  redirectToPage?: () => void;
  type?: string | number;
  source: string;
  thumbnail?: string;
  className?: string;
  videoClassName?: string;
  mediaClassName?: string;
  infoWrapperClassName?: string;
  hideShareAndViews?: boolean;
  info?: MediaInfo;
  noRatio?: boolean;
}

const MediaBlock = (props: Props) => {
  const {
    type,
    source,
    thumbnail,
    onClick,
    className = "",
    mediaClassName = "",
    videoClassName = "",
    infoWrapperClassName = "",
    hideShareAndViews = false,
    redirectToPage = () => {},
    info,
    noRatio,
  } = props;
  const isText = getIsText(type);
  const isImage = getIsImage(type) || isText;

  return (
    <div
      className={cn(classes.wrapper, className, {
        pointer: !!onClick,
        [classes.noRatio]: noRatio,
      })}
      onClick={onClick}
    >
      <div className={cn(classes.media, mediaClassName)}>
        {isImage ? (
          isText ? (
            <div
              className={cn("image-placeholder", {
                "not-source": !source,
              })}
            >
              {!!source && <img src={source} alt="thumbnail" />}
              <span
                style={{
                  color: info?.background_image_text_color || "white",
                }}
              >
                {info?.desc}
              </span>
            </div>
          ) : (
            <img src={source} alt="thumbnail" />
          )
        ) : (
          <WatchVideo
            source={source}
            thumbnail={thumbnail}
            videoClassName={videoClassName}
            videoDuration={info?.metadata?.duration}
          />
        )}
      </div>
      <div className={cn(classes.info, infoWrapperClassName)}>
        <div>
          {!hideShareAndViews && (
            <>
              <div>
                <span>
                  <EyeIcon />
                  <span>{numberFormatter(info?.views_count || 0, 1)}</span>
                </span>
                <span>
                  <HeartIcon />
                  <span>{numberFormatter(info?.likes_count || 0, 1)}</span>
                </span>
              </div>
              <div>posted: {getPostDate(info?.posted_date) || ""}</div>
            </>
          )}
        </div>
        <div>
          <span>{info?.title || ""}</span>
          <span onClick={redirectToPage}>{info?.author?.username || ""}</span>
        </div>
      </div>
    </div>
  );
};

export default MediaBlock;

import { Reducer } from "redux";

import { handleData } from "../middlewares/handleData";
import Types from "../types/regions";

const initialState = {
  regionList: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  savedRegionList: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  postRegion: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  updateSavedRegion: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  region: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
};

const RegionReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.FETCH_REGION_LIST_STARTED:
      return {
        ...state,
        regionList: {
          ...state.regionList,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_REGION_LIST_SUCCESS:
      return {
        ...state,
        regionList: {
          ...state.regionList,
          data: payload.data,
          loading: false,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_REGION_LIST_FAILED:
      return {
        ...state,
        regionList: {
          ...state.regionList,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.FETCH_USERS_SAVED_REGION_LIST_STARTED:
      return {
        ...state,
        savedRegionList: {
          ...state.savedRegionList,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_USERS_SAVED_REGION_LIST_SUCCESS:
      return {
        ...state,
        savedRegionList: {
          ...state.savedRegionList,
          data: payload.data,
          loading: false,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_USERS_SAVED_REGION_LIST_FAILED:
      return {
        ...state,
        savedRegionList: {
          ...state.savedRegionList,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.POST_SELECTED_REGION_STARTED:
      return {
        ...state,
        postRegion: {
          ...state.postRegion,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.POST_SELECTED_REGION_SUCCESS:
      return {
        ...state,
        postRegion: {
          ...state.postRegion,
          data: payload.data,
          loading: false,
          isError: false,
          message: "",
        },
      };
    case Types.POST_SELECTED_REGION_FAILED:
      return {
        ...state,
        postRegion: {
          ...state.postRegion,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.UPDATE_SELECTED_REGION_STARTED:
      return {
        ...state,
        updateSavedRegion: {
          ...state.updateSavedRegion,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.UPDATE_SELECTED_REGION_SUCCESS:
      return {
        ...state,
        updateSavedRegion: {
          ...state.updateSavedRegion,
          data: payload.data,
          loading: false,
          isError: false,
          message: "",
        },
      };
    case Types.UPDATE_SELECTED_REGION_FAILED:
      return {
        ...state,
        updateSavedRegion: {
          ...state.updateSavedRegion,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.FETCH_REGION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          region: {
            ...prevState.region,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          region: {
            ...prevState.region,
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          region: {
            ...prevState.region,
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default RegionReducer;

import cn from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import useBackUrl from "src/helpers/hooks/useBackUrl";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import useInfiniteScroll from "src/helpers/hooks/useInfiniteScroll";
import useMediaDetailsInit from "src/helpers/hooks/useMediaDetailsInit";
import useUserDetailsInit from "src/helpers/hooks/useUserDetailsInit";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import UserFollowers from "src/pages/Watch/UserFollowers";
import WatchHeader from "src/pages/Watch/components/Header";
import { CommonGetPayload } from "src/types";
import { scrollToTop } from "src/utils/scrollTop";
import classes from "./Engagement.module.scss";
import EngagementMain from "./Main";
import EngagementPlayingMedia from "./PlayingMedia";
import EngagementUserDetails from "./UserDetails";
import { useActions, useIndexData } from "./selectorData";

let searchValue = "";

const Engagement = () => {
  const navigate = useNavigate();
  useMediaDetailsInit();
  const { loadUserFeed } = useUserDetailsInit();
  const userProfile = useUserProfile();
  const { engagementsData, userFeed } = useIndexData();
  const { pathname } = useLocation();
  const currentPath = useCurrentPath();
  const params = useParams();
  const { getEngagements } = useActions();
  const userId = Number(params.userId || 0);
  const isMainPage = pathname === "/engagement";
  const isPlayingMediaPage = currentPath === "/engagement/playing-media/:id";
  const isUserDetailsPage =
    currentPath === "/engagement/playing-media/:id/:name/:userId";
  const isFollowersPage =
    currentPath === "/engagement/playing-media/:id/:name/:userId/followers";
  const backUrl = useBackUrl("/engagement");
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const loadMainFeed = (nextCursor?: string, cb?: any) => {
    const tFilters: CommonGetPayload = {};
    if (nextCursor) {
      tFilters.cursor = nextCursor;
      tFilters.isMore = true;
    }
    if (searchValue) {
      tFilters.q = searchValue;
    }
    getEngagements(tFilters, cb);
  };

  const getPageComponent = () => {
    switch (true) {
      case isPlayingMediaPage:
        return <EngagementPlayingMedia />;
      case isUserDetailsPage:
        return <EngagementUserDetails />;
      case isFollowersPage:
        return (
          <UserFollowers
            isMyChannel={false}
            onTabChanged={undefined}
            tabs={undefined}
          />
        );
      default:
        return <EngagementMain />;
    }
  };
  const loadMore = () => {
    setIsLazyLoading(true);
    if (isMainPage) {
      loadMainFeed(engagementsData.meta.next_cursor, () => {
        setIsLazyLoading(false);
      });
    } else if (isUserDetailsPage) {
      loadUserFeed(
        userFeed?.meta.next_cursor,
        () => {
          setIsLazyLoading(false);
        },
        userId
      );
    }
  };
  const handleSearchChange = (val: string) => {
    searchValue = val;
    if (isMainPage) {
      loadMainFeed();
    } else if (isUserDetailsPage) {
      loadUserFeed("", null, userId, searchValue);
    }
  };

  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      (isMainPage
        ? !!engagementsData.meta?.next_cursor
        : isUserDetailsPage
        ? !!userFeed?.meta?.next_cursor
        : false),
    elementId: "watch-container",
  });

  useEffect(() => {
    searchValue = "";
    scrollToTop("watch-container");
    setIsLazyLoading(false);
    if (isMainPage) {
      loadMainFeed();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className={cn(classes.wrapper)}>
      <WatchHeader
        isRoot={isMainPage}
        onProfileClick={() => navigate("/watch/my-channel")}
        onBack={() => {
          navigate(backUrl);
        }}
        hideProfile={false}
        showManageContent={false}
        showUpload={false}
        onManageContent={() => {}}
        isMainPage={isMainPage}
        onUpload={() => {}}
        onSearchChanged={handleSearchChange}
        avatarSrc={userProfile.user?.avatar_path || ""}
        className={classes.header}
      />
      {getPageComponent()}
    </div>
  );
};

export default Engagement;

import { useLocation, useNavigate } from "react-router";
import Spinner from "src/components/Spinner";
import { ANNOUNCEMENT_IDS } from "src/helpers/constants";
import ChannelBlock from "src/pages/Watch/components/ChannelBlock";
import { MediaInfo } from "src/types/media";
import { WatchMedia } from "src/types/watch";
import { getBasicMediaInfo } from "src/utils/media";
import classes from "./Announcements.module.scss";
import { useMainData } from "./selectorData";

type ChannelData = {
  title: string;
  onClick: () => void;
  medias: WatchMedia[];
  slug?: string;
  id?: string;
  posts: MediaInfo[];
  meta?: {
    next_cursor?: string;
  };
};

const AnnouncementsMain = () => {
  const {
    currentAnnouncementLoading,
    pastAnnouncementLoading,
    currentAnnouncementChannels,
    pastAnnouncementChannels,
  } = useMainData();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const announcementChannels: any = [
    ...currentAnnouncementChannels,
    ...pastAnnouncementChannels,
  ];

  const channels: ChannelData[] = announcementChannels.map(
    (channel: ChannelData) => {
      return {
        id: channel.id,
        slug: channel.slug,
        title: channel.title,
        medias: channel.posts.map((post: MediaInfo) => {
          return {
            ...getBasicMediaInfo(post),
            onClick: () => navigate(`${pathname}/playing-media/${post.id}`),
          };
        }),
        meta: channel.meta,
      };
    }
  );
  return (
    <div className={classes.mainWrapper}>
      {(currentAnnouncementLoading || pastAnnouncementLoading) &&
        channels.length === 0 && <Spinner center className={classes.loading} />}
      {channels.map((channel, index: number) => {
        const isLastChannel = index === channels.length - 1;
        const tLoading =
          channel.slug === ANNOUNCEMENT_IDS.PAST
            ? pastAnnouncementLoading
            : currentAnnouncementLoading;
        return (
          <ChannelBlock
            {...channel}
            hideButton
            key={`${channel.slug}_${index}`}
            paddingBottom={isLastChannel}
            loading={tLoading}
            loadingBottom={!!channel?.meta?.next_cursor}
          />
        );
      })}
    </div>
  );
};

export default AnnouncementsMain;

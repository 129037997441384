import cx from "classnames";
import React from "react";
import Button from "src/components/Button";
import Card from "src/components/Card";
import Typography from "src/components/Typography";
import classes from "./styles.module.scss";
import { SubscriptionCardProps } from "./types";

const PlanCard: React.FC<SubscriptionCardProps> = ({
  plan,
  handleSubscriptionSelection,
  wrapperClassName = "",
}) => {
  return (
    <Card
      cardClassName={cx(classes.wrapper, wrapperClassName)}
      cardBodyClassName={classes.bodyStyles}
    >
      <div>
        <Typography
          className={classes.title}
          variant="p32"
          fontWeight="semibold"
        >
          {`$${plan?.price && +plan.price} Per ${
            plan?.type === "monthly" ? "Month" : "Year"
          }`}
        </Typography>
        <Typography className={classes.details} variant="p14">
          {plan?.type === "monthly"
            ? "Automatic renewal every 30 days. ($72/year)"
            : "Automatic renewal every 365 days. (Save $22)"}
        </Typography>
      </div>
      <Button
        buttonClassName={classes.button}
        buttonColor="secondary-filled"
        buttonText={
          plan?.type === "monthly" ? "Select Monthly" : "Select Annual"
        }
        onClick={() => handleSubscriptionSelection(plan)}
      />
    </Card>
  );
};

export default PlanCard;

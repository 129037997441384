/* eslint-disable react/no-multi-comp */
import cn from "classnames";
import React, { useEffect, useState } from "react";
import {
  Button as BaseButton,
  ButtonProps,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import LeftArrow from "src/assets/icons/left-arrow-icon.svg";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Text from "src/components/Text";
import treasureViews from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/treasure-views.png";
import {
  PurchaseHistoryProps,
  PurchasedImage,
  RangeFinder,
} from "src/types/clue";
import { useClueActions } from "src/utils/useActions";
import TreasureViewsInfo from "./TreasureViewsInfo";
import classes from "./TreasureViewsModal.module.scss";
import TreasureViewsPayment from "./TreasureViewsPayment";
import TreasureViewsResult from "./TreasureViewsResult";

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  lampId: number;
  purchasedTreasureViewsMedia: {
    total: number;
    purchased: number;
    purchased_media: PurchasedImage[];
  };
  initialStep?: number;
  initialResultData?: PurchaseHistoryProps;
  refreshCluePurchaseData: () => void;
  updateRegion: () => void;
}

const TreasureViewsModal: React.FC<Props> = ({
  className = "",
  onClose,
  isOpen,
  lampId,
  purchasedTreasureViewsMedia,
  initialStep = 1,
  initialResultData,
  refreshCluePurchaseData,
  updateRegion,
}) => {
  const { getTreasureViewsBuyOptions } = useClueActions();
  const {
    purchased: purchasedImagesLength,
    total,
    purchased_media = [],
  } = purchasedTreasureViewsMedia;
  const [images, setImages] = useState<PurchasedImage[]>(
    initialResultData?.purchased_images || []
  );
  const isAllImagePurchased = total === purchasedImagesLength;

  const [selectedOption, setSelectedOption] = useState<RangeFinder | null>(
    null
  );
  const [step, setStep] = useState(initialStep);
  const handleAddImages = (newImages: PurchasedImage[]) => {
    setImages(newImages);
  };

  useEffect(() => {
    if (isAllImagePurchased) {
      setImages(purchased_media);
      setStep(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllImagePurchased]);

  const next = () => setStep(step + 1);
  const prev = () => setStep(step - 1);

  const handleSelectOption = (option: RangeFinder) => {
    setSelectedOption(option);
    next();
  };

  const buyMore = () => {
    setStep(1);
    getTreasureViewsBuyOptions(lampId);
  };

  const handleSeeResult = () => next();

  const BackButton = (props: ButtonProps) => {
    return (
      <BaseButton
        className={classes.backButton}
        onClick={() => {
          prev();
        }}
        {...props}
      >
        <span className={classes.imageWrapper}>
          <img alt="left-arrow" src={LeftArrow} />
        </span>
        <Text fontFamily="inter" size={18} color="#fff">
          Back
        </Text>
      </BaseButton>
    );
  };

  const getComponent = () => {
    switch (step) {
      case 2:
        return (
          <TreasureViewsPayment
            BackButton={BackButton}
            selectedOption={selectedOption}
            handleAddImages={handleAddImages}
            prev={prev}
            handleSeeResult={handleSeeResult}
            lampId={lampId}
            refreshCluePurchaseData={refreshCluePurchaseData}
            updateRegion={updateRegion}
          />
        );
      case 3:
        return (
          <TreasureViewsResult
            isAllImagePurchased={isAllImagePurchased}
            images={images}
            onClose={() => {
              onClose();
              getTreasureViewsBuyOptions(lampId);
            }}
            buyMore={buyMore}
            total={total}
          />
        );
      case 1:
      default:
        return (
          <TreasureViewsInfo
            imageCount={images.length}
            handleSelectOption={handleSelectOption}
            purchasedTreasureViewsMedia={purchasedTreasureViewsMedia}
          />
        );
    }
  };

  const getStepInString = () => {
    switch (step) {
      case 2:
        return "two";
      case 3:
        return "three";
      case 1:
      default:
        return "one";
    }
  };

  return (
    <Modal
      backdrop
      className={cn(classes.modalWrapper, className, [
        classes[getStepInString()],
      ])}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      <ModalHeader
        close={
          <Button
            buttonText="Close"
            rightIcon={<CloseIcon />}
            variant="link"
            onClick={onClose}
          />
        }
      >
        <div className={classes.modalHeaderContent}>
          <img src={treasureViews} />
          <Text color="#fff" size={40} fontWeight="semibold">
            Treasure Views
          </Text>
        </div>
      </ModalHeader>
      <ModalBody className={classes.modalBody}>{getComponent()}</ModalBody>
    </Modal>
  );
};

export default TreasureViewsModal;

import React from "react";
import { useNavigate } from "react-router";
import { ReactComponent as BellIcon } from "src/assets/icons/bell-icon.svg";
import { ReactComponent as EyeIcon } from "src/assets/icons/eye-icon.svg";
import { ReactComponent as NotebookIcon } from "src/assets/icons/notebook-icon.svg";
import { ReactComponent as StarIcon } from "src/assets/icons/star-icon.svg";
import { ReactComponent as TrophyIcon } from "src/assets/icons/trophy-icon.svg";
import Text from "src/components/Text";
import classes from "./styles.module.scss";

type Props = {
  section: "play" | "watch" | "prizes" | "announcements" | "engagement";
  text: string;
};

const HeaderText: React.FC<Props> = ({ section = "", text = "" }) => {
  const navigate = useNavigate();

  const getIcon = () => {
    const icon: { [key: string]: React.ReactNode } = {
      play: <StarIcon />,
      watch: <EyeIcon />,
      prizes: <TrophyIcon />,
      announcements: <BellIcon />,
      engagement: <NotebookIcon />,
    };
    return icon[section] ?? <StarIcon />;
  };

  const pathsToNavigate: { [key: string]: string } = {
    play: "/play",
    watch: "/watch",
    prizes: "/prizes",
    announcements: "/announcements",
    engagement: "/engagement",
  };

  return (
    <div
      onClick={() =>
        pathsToNavigate[section] && navigate(pathsToNavigate[section])
      }
      className={classes.headerText}
    >
      {getIcon()}
      <Text size={28} fontWeight="semibold" color="#fff">
        {text}
      </Text>
    </div>
  );
};

export default HeaderText;

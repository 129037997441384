import cn from "classnames";
import React, { useState } from "react";
import Button from "src/components/Button";
import Spinner from "src/components/Spinner";
import Text from "src/components/Text";
import RangeFinder from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/range-finder.png";
import TreasureViews from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/treasure-views.png";
import { LatLng } from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/components/RangeFinderModal/RangeInputMap";
import ResultImageSlider from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/components/ResultImageSlider";
import ResultMap from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/components/ResultMap";
import { PurchaseHistoryProps } from "src/types/clue";
import { getUploadedPostDate } from "src/utils/date";
import classes from "./styles.module.scss";

type Props = {
  history: PurchaseHistoryProps;
  handleOpenModal: (history: PurchaseHistoryProps) => void;
  isLoaded: boolean;
  disabled?: boolean;
};

const PurchaseHistoryCard: React.FC<Props> = ({
  history,
  isLoaded,
  handleOpenModal,
  disabled,
}) => {
  const { type } = history.clue;
  const [state, setState] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (i: number) => {
      setState(i);
    },
  };

  const containerStyle = {
    width: "100%",
    height: "150px",
    borderRadius: "6px",
    border: `5px solid ${history.is_in_range ? "#61B036" : "#DC4B4B"}`,
  };

  const getContent = () => {
    if (type === "range_finder") {
      return isLoaded ? (
        <ResultMap
          center={history?.pin_location as LatLng}
          containerStyle={containerStyle}
          range={history?.clue.range as number}
          isInRange={history.is_in_range as boolean}
          icon=""
          hideCircle
          options={{ gestureHandling: "none" }}
        />
      ) : (
        <Spinner center />
      );
    } else {
      return (
        <ResultImageSlider
          images={history.purchased_images}
          iconSize="lg"
          settings={settings}
          state={state}
          className={classes.historyContent}
        />
      );
    }
  };

  const getContentTitle = () => {
    if (type === "range_finder") {
      if (history.is_in_range) {
        return (
          <Text size={18} color="#61B036" fontWeight="bold">
            IN RANGE
          </Text>
        );
      } else {
        return (
          <Text size={18} color="#DC4B4B" fontWeight="bold">
            NOT IN RANGE
          </Text>
        );
      }
    } else {
      return;
    }
  };

  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <img
            src={type === "treasure_view_images" ? TreasureViews : RangeFinder}
          />
          <Text fontFamily="inter" size={16} fontWeight="bold" color="#000">
            {type === "treasure_view_images"
              ? "Treasure Views"
              : "Range Finder"}
          </Text>
        </div>
        <Text size={16} color="#000" fontFamily="inter">
          {type === "treasure_view_images"
            ? history.clue.image_count
            : history.clue.range}{" "}
          {type === "treasure_view_images" ? "Pack" : "Miles"}
        </Text>
      </div>
      <div className={classes.body}>
        <div className={classes.info}>
          {getContentTitle()}
          {getContent()}
        </div>
        <Button
          buttonClassName={cn(classes.openButton, {
            [classes.openButtonSlider]: type === "treasure_view_images",
          })}
          disabled={disabled}
          onClick={() => handleOpenModal(history)}
          buttonText={"Open"}
        />
      </div>
      <div className={classes.footer}>
        <Text fontFamily="inter" style={{ fontStyle: "italic" }} size={14}>
          Used : {getUploadedPostDate(history.created_at)}
        </Text>
      </div>
    </div>
  );
};

export default PurchaseHistoryCard;

import { Reducer } from "redux";
import { handleData } from "../middlewares/handleData";
import Types from "../types/prize";

const initialState = {
  prizes: {
    loading: false,
    data: {},
    error: "",
  },
};

const PrizeReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_PRIZES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          prizes: {
            ...prevState.prizes,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          prizes: {
            loading: false,
            error: "",
            data: payload.data,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          prizes: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });

    default:
      return state;
  }
};

export default PrizeReducer;

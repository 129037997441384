import ReactGA from "react-ga4";

export const trackPageViewEvent = (page: string) => {
  if (process.env.REACT_APP_NODE_ENV !== "production") {
    return;
  }
  ReactGA.set({
    page,
  });
  ReactGA.send({ hitType: "pageview", page });
};

export const trackEvent = (
  category: string,
  action: string,
  label?: string
) => {
  if (process.env.REACT_APP_NODE_ENV !== "production") {
    return;
  }
  ReactGA.event({
    category,
    action,
    label,
  });
};

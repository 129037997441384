import React from "react";
import { useNavigate } from "react-router";
import { ReactComponent as ClueSearch } from "src/assets/icons/clue-search.svg";
import { ReactComponent as Lamp } from "src/assets/icons/lamp.svg";
import { ReactComponent as Prize } from "src/assets/icons/prize.svg";

import Button from "src/components/Button";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { trackEvent } from "src/utils/googleAnalytics";
import classes from "./styles.module.scss";

const PlayGuide = ({ redirectLink = "" }) => {
  const navigate = useNavigate();
  const isSignupRedirect = redirectLink.includes("/auth/signup");

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.title}>
          <p>HOW IT WORKS</p>
        </div>
        <div className={classes.stepContainer}>
          <div className={classes.step}>
            <div className={classes.iconContainer}>
              <div className={classes.iconWrapper}>
                <ClueSearch />
              </div>
            </div>
            <div className={classes.textWrapper}>
              <div className={classes.header}>Solve the Clues</div>
              <div className={classes.description}>
                Each clue contains info to assist you in searching for the lamp.
              </div>
            </div>
          </div>
          <div className={classes.step}>
            <div className={classes.iconContainer}>
              <div className={classes.iconWrapper}>
                <Lamp />
              </div>
            </div>
            <div className={classes.textWrapper}>
              <div className={classes.header}>find the lamp</div>
              <div className={classes.description}>
                Use the answers from clues you’ve solved to find the location of
                the lamp before anyone else!
              </div>
            </div>
          </div>
          <div className={classes.step}>
            <div className={classes.iconContainer}>
              <div className={classes.iconWrapper}>
                <Prize />
              </div>
            </div>
            <div className={classes.textWrapper}>
              <div className={classes.header}>win the prize</div>
              <div className={classes.description}>
                Once you've found the lamp, select your prize; the CASH or 3
                WISHES. It's your choice.
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => {
              navigate(redirectLink);
              if (isSignupRedirect) {
                trackEvent(
                  ANALYTICS_CONSTANTS.category.sign_up,
                  ANALYTICS_CONSTANTS.action.sign_up_full
                );
              }
            }}
            buttonClassName={classes.joinButton}
            buttonText="Join Now"
          />
          <Button
            onClick={() => {
              navigate(`${redirectLink}?free_trial=true`);
              if (isSignupRedirect) {
                trackEvent(
                  ANALYTICS_CONSTANTS.category.sign_up,
                  ANALYTICS_CONSTANTS.action.sign_up_free
                );
              }
            }}
            buttonClassName={classes.trialButton}
            buttonText="Try Free Trial"
          />
        </div>
      </div>
    </div>
  );
};

export default PlayGuide;

/* eslint-disable max-lines */
import { Reducer } from "redux";

import { handleData } from "../middlewares/handleData";
import Types from "../types/user";

const initialState = {
  devices: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  removeDevice: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  changeSubscription: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  newsData: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  playCountdown: {
    loading: false,
    message: "",
    error: "",
    data: {},
  },
};

const UserReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_DEVICES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          devices: {
            ...prevState.devices,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          devices: {
            loading: false,
            data: payload.data || [],
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          devices: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.REMOVE_DEVICE: {
      const { id } = action.meta.payload;

      const updatedDevices = state.devices.data.filter(
        (device: any) => id !== device.id
      );
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          removeDevice: {
            ...prevState.removeDevice,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          removeDevice: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
          devices: {
            ...prevState.devices,
            data: updatedDevices,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          removeDevice: {
            data: {},
            loading: false,
            error: payload,
          },
        }),
      });
    }
    case Types.CHANGE_SUBSCRIPTION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          changeSubscription: {
            ...prevState.changeSubscription,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          changeSubscription: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          changeSubscription: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_NEWS_DATA:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          newsData: {
            ...prevState.newsData,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          newsData: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          newsData: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_PLAY_COUNTDOWN:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          playCountdown: {
            ...prevState.playCountdown,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          playCountdown: {
            loading: false,
            data: payload.data || {},
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          playCountdown: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default UserReducer;

import React from "react";
import { Button } from "reactstrap";
import Text from "src/components/Text";
import { RangeFinder } from "src/types/clue";
import { TGS_BASE_URL } from "src/utils/constants";
import { useCluesData } from "src/utils/useData";
import classes from "./TreasureViewsInfo.module.scss";
import { TreasureViewInfoImage } from "./infoImage";

type Props = {
  handleSelectOption: (option: RangeFinder) => void;
  imageCount: number;
  purchasedTreasureViewsMedia: { total: number; purchased: number };
};

const TreasureViewsInfo: React.FC<Props> = ({
  handleSelectOption,
  imageCount,
  purchasedTreasureViewsMedia,
}) => {
  const {
    treasureViewsBuyOptions: { data: options = [] },
  } = useCluesData();

  // const { total: totalImages, purchased: purchasedImages } =
  const { purchased: purchasedImages } = purchasedTreasureViewsMedia;
  const TextContent = (
    <g>
      <foreignObject y="0" width="100%" height="100%">
        <div className={classes.info}>
          <Text size={16} fontFamily="inter" fontWeight="bold" color={"#fff"}>
            You have bought
          </Text>
          <Text
            fontFamily="inter"
            fontWeight="bold"
            className={classes.packDetail}
          >
            {imageCount || purchasedImages} of 10
          </Text>
          <Text size={20} fontFamily="inter" fontWeight="bold" color={"#fff"}>
            Treasure View Photos
          </Text>
        </div>
      </foreignObject>
    </g>
  );

  return (
    <div className={classes.container}>
      <div className={classes.description}>
        <Text
          size={16}
          fontFamily="inter"
          fontWeight="medium"
          style={{ lineHeight: "normal" }}
          color={"#fff"}
        >
          The Treasure Views Advantage Clue allows players to purchase up to
          <strong>five 2 Packs</strong> of photo reveals that are taken from the
          perspective of the hidden lamp. Each 2 Pack has 2 images included for
          a total of 10 images if you purchase all five 2 packs.
          <br />
          <br />
          One 2 Pack is available to purchase every 30 days starting on Day 30.
          <br />
          <br />
          The images are not intended to be obvious or clear as to what you are
          seeing, but they can be helpful as additional clues to aid in finding
          the Treasure.{" "}
          <a
            href={`${TGS_BASE_URL}/a/wl-advantage-clues/#treasure-views`}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </a>
          .
        </Text>
      </div>
      <div className={classes.detailsBlock}>
        <div className={classes.detailsBlockInner}>
          Your previous purchases will appear in the <strong>Purchased</strong>{" "}
          section.
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.leftSide}>
          <Text
            className="mb-3"
            size={18}
            fontFamily="inter"
            fontWeight="bold"
            color={"#fff"}
          >
            Example
          </Text>
          <div className={classes.imageContainer}>
            <TreasureViewInfoImage Content={TextContent} />
          </div>
        </div>
        <div className={classes.rightSide}>
          <Text
            className="mb-3"
            size={18}
            fontFamily="inter"
            fontWeight="bold"
            color={"#fff"}
          >
            Buy Now
          </Text>
          <div className={classes.actions}>
            {options
              ?.filter((option) => {
                return option?.image_count === 2;
              })
              .map((option, index: number) => {
                return (
                  <div key={option.id}>
                    <Button
                      onClick={() => handleSelectOption(option)}
                      className={classes.button}
                      key={index}
                      disabled={option.is_disabled || option.is_purchased}
                    >
                      <Text
                        color="#fff"
                        fontFamily="inter"
                        size={18}
                        fontWeight="bold"
                      >
                        {option?.image_count === 1
                          ? "Single"
                          : `${option?.image_count} Pack`}{" "}
                        for ${option?.amount}
                      </Text>
                    </Button>
                    {option.is_disabled && (
                      <p className={classes.availableOn}>
                        Available in {option.available_in_days} days
                      </p>
                    )}
                    {!option.is_disabled && option.is_purchased && (
                      <p className={classes.alreadyPurchased}>
                        Already purchased this cycle
                      </p>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreasureViewsInfo;

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React from "react";
import Modal from "src/components/Modal";
import Typography from "src/components/Typography";
import { getClueGameInfo } from "src/pages/Play/Clue/Components/ClueDashboard/Clues/ClueListView/utils";
import { getClueNumber } from "src/pages/Play/Clue/getIcon";
import { ClueProps } from "src/pages/Play/Clue/types";
import { getCurrentClue } from "src/pages/Play/Clue/utils";
import { setClueModalState } from "src/store/actions/clue";
import { useReducerData, useStoreActions } from "src/store/hooks";
import Question from "../Question";
import classes from "./styles.module.scss";

type ModalProps = {
  isOpen: boolean;
  data: ClueProps;
};

const QuestionModal: React.FC<ModalProps> = ({ isOpen, data }) => {
  const actions = useStoreActions({ setClueModalState });
  const { data: clues }: { data: ClueProps[] } = useReducerData(
    "clue",
    "clues",
    {
      data: [],
    }
  );
  const currentClue = getCurrentClue(clues);
  const { displayGame } = getClueGameInfo(currentClue);

  return (
    <Modal
      backdrop
      className={cn(classes.questionModalWrapper, {
        [classes.customGame]: !!displayGame,
      })}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={() => {
        actions.setClueModalState(false, null);
      }}
    >
      <div className={classes.header}>
        <div className={classes.rightSide}>
          <Typography
            className="mb-1 text-capitalize"
            fontWeight="semibold"
            variant="p32"
          >
            WL$ Clue #{getClueNumber(data?.id, clues)}: {data?.type}
          </Typography>
          <Typography className="mb-0" variant="p16">
            {data?.description}
          </Typography>
        </div>
        <div
          onClick={() => actions.setClueModalState(false, null)}
          className={classes.close}
        >
          <Typography className="mb-0" variant="p16">
            Close{" "}
            <FontAwesomeIcon
              style={{ marginLeft: "12px" }}
              size="lg"
              icon={faXmark}
            />
          </Typography>
        </div>
      </div>
      <div className={classes.questionWrapper}>
        <Question isModal />
      </div>
    </Modal>
  );
};

export default QuestionModal;

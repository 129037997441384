import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { PRIZE_SUB_TITLE } from "src/helpers/constants";
import ChannelBlock from "src/pages/Watch/components/ChannelBlock";
import { MediaInfo } from "src/types/media";
import { getBasicMediaInfo } from "src/utils/media";
import classes from "./Prizes.module.scss";
import { useSubData } from "./selectorData";

const PrizesSub = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const channelSlug = String(params.channelSlug || "");
  const { watchChannel, watchChannelLoading } = useSubData();

  const medias = (watchChannel.posts || []).map((feedItem: MediaInfo) => {
    return {
      ...getBasicMediaInfo(feedItem),
      onClick: () => navigate(`${pathname}/playing-media/${feedItem.id}`),
    };
  });

  return (
    <>
      <ChannelBlock
        title={PRIZE_SUB_TITLE[channelSlug]}
        medias={medias}
        hideButton={true}
        loading={watchChannelLoading}
        loadingBottom={!!watchChannel.meta?.next_cursor}
        paddingBottom
        className={classes.subWrapper}
      />
    </>
  );
};

export default PrizesSub;

import { MediaInfo } from "src/types/media";
import { Channel } from "src/types/user";

export const getUpdatedNews = (
  arr: Channel[],
  tPost: MediaInfo | undefined
) => {
  return arr.map((obj) => {
    obj.sections = obj.sections.map((section) => {
      section.posts = section.posts.map((post) => {
        if (post.id === tPost?.id) {
          post.views_count = tPost?.views_count;
          post.has_liked = tPost?.has_liked;
          post.likes_count = tPost?.likes_count;
        }
        return post;
      });
      return section;
    });
    return obj;
  });
};

export const getDisplayName = (data: any) => {
  return (
    data?.name ||
    [data?.first_name, data?.last_name].filter((v) => !!v).join(" ")
  );
};
export const capitalizeFirstLetter = (str: string) => {
  const tStr = str || "";
  return tStr.charAt(0).toUpperCase() + tStr.slice(1);
};

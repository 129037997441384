import cn from "classnames";
import { ModalBody, ModalHeader } from "reactstrap";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Text from "src/components/Text";
import TreasureCompassPng from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/treasure-compass.png";
import TreasureExample1Png from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/treasure-example-1.png";
import TreasureExample2Png from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/treasure-example-2.png";
import TreasureExample3Png from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/treasure-example-3.png";
import TreasureExample4Png from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/assets/treasure-example-4.png";
import AvailableButton from "../HotColdBarometerModal/AvailableButton";
import MobileTag from "../HotColdBarometerModal/MobileTag";
import DetailsBlock from "../RangeFinderModal/RangeFinderInfo/DetailsBlock";
import classes from "./TreasureCompassModal.module.scss";

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

const TreasureCompassModal = ({ className = "", isOpen, onClose }: Props) => {
  return (
    <>
      <Modal
        backdrop
        className={cn(classes.modalWrapper, className)}
        bodyClassName={classes.modalBody}
        isOpen={isOpen}
        toggle={onClose}
      >
        <ModalHeader
          close={
            <Button
              buttonText="Close"
              rightIcon={<CloseIcon />}
              variant="link"
              onClick={onClose}
            />
          }
        >
          <div className={classes.modalHeaderContent}>
            <img src={TreasureCompassPng} />
            <Text color="#fff" size={40} fontWeight="semibold">
              Treasure Compass
            </Text>
          </div>
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <div className={classes.modalBodyInner}>
            <div className={classes.description}>
              <Text
                style={{ lineHeight: "normal" }}
                color="#fff"
                size={16}
                fontWeight="medium"
              >
                The Treasure Compass Advantage Clue only unlocks for players who
                have successfully placed the 10-mile pin for the Range Finder
                Advantage Clue. The Treasure Compass points a player in the
                direction of the Lamps 5-mile radius zone perimeter. The Compass
                lasts for 10 continuous minutes.
                <br />
                <br />
                The Compass is designed to help find the 5-mile radius zone
                perimeter. Once you are within that range, this clue can no
                longer be used. It will stop functioning inside the 5-mile
                radius zone.
              </Text>
            </div>
            <DetailsBlock title="Details" className={classes.detailsBlock}>
              <>
                <div className="how-it-works">
                  <b>How It Works:</b>
                  <ul>
                    <li>
                      You must have successfully used the 10-mile Range Finder
                      Advantage Clue to unlock the Treasure Compass.
                    </li>
                    <li>Click “Buy Now.”</li>
                    <li>
                      <p>A modal will appear asking if you are ready.</p>
                      <div>
                        <img src={TreasureExample1Png} alt="treasure-example" />
                      </div>
                      <br />
                    </li>
                    <li>
                      Click “Start.” At this time, the Compass timer will start
                      counting down.
                    </li>
                    <li>
                      Begin moving in any direction and rotating your device to
                      point the compass needle towards the $ symbol, while
                      moving in that direction.
                    </li>
                    <li>
                      Do not operate any motorized vehicle while using any
                      Advantage Clue. Keep your eyes on the road, and be aware
                      of your surroundings at all times.
                    </li>
                  </ul>
                </div>
                <div>
                  <div>
                    <b>Additional Information:</b>
                  </div>
                  <br />
                  <p>
                    The Compass Advantage Clues goal is to point players in the
                    direction of the 5-mile lamp radius zone; there is a $
                    symbol on the compass instead of N, E, S, W. The $ symbol
                    symbolizes the position of the lamp radius zone. The player
                    needs to point the front portion of the compass needle
                    towards the $ symbol and move in that direction. It may take
                    a few seconds for the compass to calibrate after starting
                    the Advantage Clue, meaning the $ won’t be in the correct
                    position on the compass but this quickly updates.
                  </p>
                  <p>
                    It is also recommended that players calibrate their own
                    device compass.
                  </p>
                  <p>
                    If the player is already within the 5-mile lamp radius, the
                    “Buy Now” button is deactivated.
                  </p>
                  <div>
                    <b>Results State:</b>
                  </div>
                  <p>
                    Once the player gets to the lamp's 5-mile radius zone
                    perimeter, the compass will begin spinning to the left and
                    right and a modal will display informing the player that
                    they have hit the perimeter of the 5-mile radius zone that
                    the lamp is hidden within:
                  </p>
                  <div>
                    <img src={TreasureExample2Png} alt="treasure-example" />
                  </div>
                  <br />
                  <br />
                  <p>
                    If the player does not intersect the lamp's 5-mile radius
                    perimeter in the given 10 minutes, the time runs out and a
                    modal is displayed, “Time’s Up!”:
                  </p>
                  <div>
                    <img src={TreasureExample3Png} alt="treasure-example" />
                  </div>
                </div>
              </>
            </DetailsBlock>
            <div className={classes.content}>
              <div className={classes.details}>
                <Text className="mb-3" size={18} color="#fff" fontWeight="bold">
                  Examples
                </Text>
                <div className={classes.infoWrapper}>
                  <Text
                    className={classes.textHeading}
                    size={14}
                    color="#00A859"
                  >
                    DIRECTION OF LAMP PERIMETER
                  </Text>
                  <Text className={classes.text} size={12} color="#121429">
                    The <b>$</b> points towards the 5-mile radius perimeter.
                    When the compass is active and pointing towards the $, that
                    is the direction of the Lamps 5-Mile Radius Perimeter.
                  </Text>
                  <div className={classes.info}>
                    <img src={TreasureExample4Png} />
                  </div>
                </div>
              </div>
              <div className={classes.buyActions}>
                <div>
                  <MobileTag>Mobile Only</MobileTag>
                </div>
                <div>
                  <AvailableButton>
                    <>
                      <img src={TreasureCompassPng} />
                      <span>Available on the Mobile App</span>
                    </>
                  </AvailableButton>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TreasureCompassModal;

import React from "react";
import AppDownload from "src/components/AppDownload";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import CopyrightIcon from "src/pages/Home/assets/icons/copy-right-icon.svg";
import Tsg from "src/pages/Home/assets/images/tgs-img.svg";
import {
  TGS_BASE_URL,
  tawkPropertyId,
  tawkWidgetId,
} from "src/utils/constants";
import { currentYear } from "src/utils/utils";
import classes from "./styles.module.scss";

const AboutFooter = () => {
  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.leftSide}>
        <img src={Tsg} alt="img" />
        <Typography
          className={classes.copyRight}
          variant="p12"
          fontFamily="inter"
        >
          <img src={CopyrightIcon} alt="copy-right" /> Copyright {currentYear}
        </Typography>
        <div className={classes.links}>
          <a href={`${TGS_BASE_URL}/a/privacy-policy-ip-policy`}>Privacy</a>
          <span></span>
          <a href={`${TGS_BASE_URL}/a/terms-and-conditions`}>
            Terms and Conditions
          </a>
          <span></span>
          <a href={`${TGS_BASE_URL}/a/tg-risk-waiver`}>Risk Waiver</a>
        </div>
      </div>
      <div className={classes.button}>
        <Button
          buttonText="Impact Investment Program"
          buttonColor="secondary"
          onClick={() => {
            if (window) {
              (window as any).location = "/a/impact-investment-program";
            }
          }}
        />
        <Typography variant="p20" className={classes.footerText}>
          Unlock the App on Your Mobile Device
        </Typography>
        <AppDownload />
      </div>
      <div className={classes.footerLinks}>
        <img src={Tsg} alt="img" />
        <a href={`${TGS_BASE_URL}/a/faq`}>FAQ</a>
        <a href={`${TGS_BASE_URL}/a/community-guidelines`}>
          Community Guidelines
        </a>
        <a
          target="_blank"
          href={`https://tawk.to/chat/${tawkPropertyId}/${tawkWidgetId}`}
          rel="noreferrer"
        >
          Contact
        </a>
        <a href={`${TGS_BASE_URL}/a/employment`}>Employment</a>
        <a href={`${TGS_BASE_URL}/a/partners`}>Partners</a>
      </div>
    </footer>
  );
};
export default AboutFooter;

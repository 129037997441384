import { useNavigate } from "react-router";
import { Spinner } from "reactstrap";
import Text from "src/components/Text";
import MediaBlock from "src/pages/Watch/components/MediaBlock";
import { MediaInfo } from "src/types/media";
import { getBasicMediaInfo } from "src/utils/media";
import HeaderText from "../HeaderText";
import { useIndexData } from "./selectorData";
import classes from "./styles.module.scss";

const Notifications = () => {
  const navigate = useNavigate();
  const {
    newCluesAvailable,
    dashboardAnnouncements,
    newCluesAvailableLoading,
    dashboardAnnouncementsLoading,
  } = useIndexData();
  const announcements =
    dashboardAnnouncements["announcements"] ||
    dashboardAnnouncements["current-announcements"] ||
    [];

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <HeaderText section="announcements" text={`Announcements`} />
      </div>
      {(newCluesAvailableLoading || dashboardAnnouncementsLoading) && (
        <Spinner className={classes.loading} color="light" />
      )}
      <div className={classes.alert}>
        <Text
          color="#fff"
          size={30}
          fontWeight="medium"
          className={classes.notiMessage}
        >
          You have <span>{newCluesAvailable}</span> new clue
          {newCluesAvailable !== 1 ? "s" : ""}
        </Text>
      </div>
      <div className={classes.notificationWrapper}>
        {announcements.slice(0, 3).map((data: MediaInfo) => {
          const media = {
            ...getBasicMediaInfo(data),
            onClick: () => {
              navigate(`/announcements/playing-media/${data.id}`);
            },
          };
          return (
            <MediaBlock
              {...media}
              className={classes.mediaBlock}
              mediaClassName={classes.media}
              videoClassName={classes.video}
              noRatio
              key={data.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Notifications;

import cn from "classnames";
import ReactPaginate from "react-paginate";
import { ReactComponent as LeftArrow } from "src/assets/icons/arrow-left-single-icon.svg";
import { ReactComponent as RightArrow } from "src/assets/icons/arrow-right-single-icon.svg";
import classes from "./Pagination.module.scss";

interface Props {
  className?: string;
  page: number;
  onPageChange: ({ selected }: { selected: number }) => void;
  total: number;
  showTotalText?: boolean;
  pageSize?: number;
}

const Pagination = ({
  className,
  onPageChange,
  page,
  total,
  showTotalText,
  pageSize = 10,
}: Props) => {
  const pageDisplay = page + 1;
  const lastEntries = total % pageSize;
  const nextEntiresDisplay = pageDisplay * pageSize;
  const intPageCount = parseInt(String(total / pageSize), 10);
  const additionalPageCount = lastEntries > 0 ? 1 : 0;
  return (
    <div className={cn(classes.wrapper, className)}>
      {showTotalText && (
        <div className={classes.totalDisplay}>
          Showing {page * pageSize + 1} to{" "}
          {nextEntiresDisplay > total ? total : nextEntiresDisplay} of {total}{" "}
          entries
        </div>
      )}
      <ReactPaginate
        previousLabel={
          <>
            <LeftArrow /> <span>Previous</span>
          </>
        }
        nextLabel={
          <>
            <span>Next</span> <RightArrow />
          </>
        }
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item page-previous"
        previousLinkClassName="page-link"
        nextClassName="page-item page-next"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={intPageCount + additionalPageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={page}
        onClick={(event) => {
          const newPage = event.nextSelectedPage || 0;
          if (newPage !== page) {
            onPageChange({ selected: newPage });
          }
          return false;
        }}
      />
    </div>
  );
};

export default Pagination;

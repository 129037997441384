import cn from "classnames";
import { ReactComponent as EyeIcon } from "src/assets/icons/eye-icon.svg";
import { ReactComponent as HeartIcon } from "src/assets/icons/heart-icon.svg";
import { ReactComponent as ShareIcon } from "src/assets/icons/share-icon.svg";
import { numberFormatter } from "src/utils/number";
import classes from "./Socials.module.scss";

interface Props {
  className?: string;
  viewCount?: number;
  shareCount?: number;
  likeCount?: number;
}

const Socials = ({ className, viewCount, likeCount, shareCount }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span>
        <EyeIcon />
        {numberFormatter(viewCount || 0, 2)}
      </span>
      <span>
        <ShareIcon />
        {numberFormatter(shareCount || 0, 2)}
      </span>
      <span>
        <HeartIcon />
        {numberFormatter(likeCount || 0, 2)}
      </span>
    </div>
  );
};

export default Socials;

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { POST_STATUS_VALUES } from "src/helpers/constants";
import { store } from "src/store";
import * as mediaActions from "src/store/actions/media";
import { useStoreActions } from "src/store/hooks";
import { actionCb } from "src/utils/action";

const useActions = () => {
  return useStoreActions({
    ...mediaActions,
  });
};

let getStatusTimeout: any = null;

const useUploadStatus = (
  postId: string,
  setUploadFileLoading?: any,
  resetWatcher?: boolean | null,
  setResetStatusWatcher?: any
) => {
  const { getPostStatus, syncSetPostStatus, syncResetUploadMediaPost } =
    useActions();
  const getStatus = (tId: string) => {
    getPostStatus(
      tId,
      actionCb(
        (res: any) => {
          const status = res?.data?.status;
          const tState = store.getState();
          const uploadMediaError =
            get(tState, "media.uploadMediaPost.error") || {};
          if (
            status !== POST_STATUS_VALUES.VERIFIED &&
            isEmpty(uploadMediaError)
          ) {
            if ([POST_STATUS_VALUES.VERIFYING_FAILED].includes(status)) {
              if (setUploadFileLoading) setUploadFileLoading(false);
              if (setResetStatusWatcher) setResetStatusWatcher(null);
            }
            getStatusTimeout = setTimeout(() => {
              getStatus(tId);
            }, 3000);
          } else {
            if (!isEmpty(uploadMediaError)) {
              if (status === POST_STATUS_VALUES.UPLOADING) {
                syncSetPostStatus(POST_STATUS_VALUES.VERIFYING_FAILED);
              } else {
                syncSetPostStatus(POST_STATUS_VALUES.ERROR);
              }
              syncResetUploadMediaPost();
            }
            if (setUploadFileLoading) setUploadFileLoading(false);
          }
        },
        null,
        true
      )
    );
  };
  const clearGetStatusTimeout = () => {
    clearTimeout(getStatusTimeout);
    getStatusTimeout = null;
  };
  useEffect(() => {
    if (postId) {
      getStatus(postId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);
  useEffect(() => {
    if (resetWatcher === false || resetWatcher === true) {
      getStatus(postId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetWatcher]);
  return { clearGetStatusTimeout };
};

export default useUploadStatus;

import { RegionListProps } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import { getRegionInfo } from "src/utils/region";

export const getHoverRegionStyle = (
  currentHoveredRegion: string | undefined,
  addedRegions: string[],
  regionData: RegionListProps,
  regionSelectionFlow: boolean
) => {
  const slug = regionData.slug;
  const { isAllLampFound, isFoundEqualTotal } = getRegionInfo(regionData);

  if (isAllLampFound || isFoundEqualTotal) {
    if (currentHoveredRegion === slug) {
      return {
        fill: "#818381",
        stroke: "black",
        strokeWidth: 4,
      };
    }
    return {
      fill: "#B5B6B5",
      stroke: "black",
      strokeWidth: 4,
    };
  }
  const isRegionAlreadyAdded = addedRegions.includes(slug);
  const isAddedRegion =
    currentHoveredRegion === slug &&
    addedRegions.includes(currentHoveredRegion);
  if (isAddedRegion && regionSelectionFlow) {
    return {
      fill: "#308f1b",
      filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
      stroke: "white",
      strokeWidth: 4,
    };
  }
  if (
    currentHoveredRegion === slug &&
    (!isAddedRegion || !regionSelectionFlow)
  ) {
    const color = regionData?.color_code;
    return {
      fill: getDarkColor(color),
      filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
      stroke: "white",
      strokeWidth: 4,
    };
  }
  if (isRegionAlreadyAdded && regionSelectionFlow) {
    return {
      stroke: "black",
      strokeWidth: 4,
      fill: "#6CBD3F",
    };
  }
  return {
    stroke: "black",
    strokeWidth: 4,
    fill: regionData?.color_code,
  };
};

export const getBorderHoverStyle = (
  currentHoveredRegion: string | undefined,
  regionData: RegionListProps
) => {
  if (regionData.lamp_count === 0) {
    return { fill: "black" };
  }
  if (currentHoveredRegion === regionData.slug) {
    return { fill: "transparent" };
  } else {
    return { fill: "black" };
  }
};

export const getDarkColor = (region: string) => {
  switch (region) {
    case "#8053EA":
      return "#4918BF";
    case "#6C69F8":
      return "#27115A";
    case "#E9A260":
      return "#CA701C";
    case "#E5C85F":
      return "#C19E1F";
    case "#6CBD3F":
      return "#6CBD3F";
    default:
      return "#000000";
  }
};

import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { DropdownIndicatorProps } from "react-select";
import classes from "src/pages/Auth/Profile/styles.module.scss";

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => (
  <span className={classes.caret}>
    {props?.selectProps?.menuIsOpen ? (
      <FontAwesomeIcon icon={faAngleUp} />
    ) : (
      <FontAwesomeIcon icon={faAngleDown} />
    )}
  </span>
);

export default DropdownIndicator;

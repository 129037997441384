import cn from "classnames";
import { useRef } from "react";
import { Accept } from "react-dropzone";
import { ReactComponent as LineUploadIcon } from "src/assets/icons/line-upload-icon.svg";
import Button from "src/components/Button";
import Dropzone from "src/components/Dropzone";
import {
  ACCEPT_UPLOAD_IMAGES,
  ACCEPT_UPLOAD_VIDEOS,
} from "src/helpers/constants";
import classes from "./UploadPlaceholder.module.scss";

interface Props {
  className?: string;
  onFileChange: (tFile: any) => void;
  loading?: boolean;
}

const UploadPlaceholder = ({ className, onFileChange, loading }: Props) => {
  const inputRef = useRef(null);
  const acceptTypes: Accept = {};
  [
    ...ACCEPT_UPLOAD_IMAGES.split(", "),
    ...ACCEPT_UPLOAD_VIDEOS.split(", "),
  ].map((tType: string) => {
    acceptTypes[tType] = [];
  });
  return (
    <div className={cn(classes.wrapper, className)}>
      <Dropzone
        disabled={loading}
        multiple={false}
        onChange={onFileChange}
        inputRef={inputRef}
        placeholder={
          <div className={classes.inner}>
            <span>
              <LineUploadIcon />
            </span>
            <p>
              Drag & drop your files here
              <br />
              <b>OR</b>
            </p>
            <Button
              buttonText="Select"
              buttonColor="purple"
              onClick={() => {
                const inputRefCurrent = inputRef.current as any;
                if (inputRefCurrent) inputRefCurrent.click();
              }}
              type="button"
              loading={loading}
              flex
            />
            <div>
              <span>
                Please ensure that your file is no larger than 5GB and doesn't
                exceed 15 minutes in duration
              </span>
              <span>
                Accepted file types: .MOV, .MP4, .MPG, .AVI, .WMV, .FLV, 3GPP,
                WebM, JPEG, PNG, GIF
              </span>
            </div>
          </div>
        }
        accept={acceptTypes}
      />
    </div>
  );
};

export default UploadPlaceholder;

import { WATCH_SUB_SLUG_OBJ } from "src/helpers/constants";
import { ReportMediaPayload } from "src/types/media";
import { Request } from "./request";

export const apiGetAllMedia = async (params?: {
  q?: string;
  is_full_access?: boolean;
}) => {
  return Request.call({
    url: `/api/watch/posts`,
    method: "GET",
    params,
  });
};
export const apiSearchMedia = async (filters = {}) => {
  return Request.call({
    url: `/api/watch/posts/search`,
    method: "GET",
    params: filters,
  });
};
export const apiGetMediaDetail = async (id: string) => {
  return Request.call({
    url: `/api/watch/posts/${id}`,
    method: "GET",
  });
};
export const apiGetMediaRelated = async (
  channelId: string | number,
  postId: string,
  userId?: number,
  search?: string
) => {
  const url =
    channelId === WATCH_SUB_SLUG_OBJ.MY_CHANNEL
      ? `/api/watch/users/${userId}/feed/${postId}/related`
      : `/api/watch/channels/${channelId}/${postId}/related`;
  return Request.call({
    url,
    method: "GET",
    params: {
      q: search,
    },
  });
};
export const apiGetPostStatus = async (id: string) => {
  return Request.call({
    url: `/api/watch/posts/${id}/status`,
    method: "GET",
  });
};
export const apiGetPostDetails = async (userId: number, postId: string) => {
  return Request.call({
    url: `/api/watch/users/${userId}/content/${postId}`,
    method: "GET",
  });
};
export const apiReportMedia = async (id: string, data: ReportMediaPayload) => {
  return Request.call({
    url: `/api/watch/posts/${id}/report`,
    method: "POST",
    data,
  });
};
export const apiCreatePost = async () => {
  return Request.call({
    url: `/api/watch/posts`,
    method: "POST",
    data: {},
  });
};
export const apiUploadMediaPost = async (
  postId: string,
  data: FormData,
  key = "image"
) => {
  return Request.call({
    url: `/api/watch/posts/${postId}/${key}`,
    method: "POST",
    data,
    headers: {
      contentType: "multipart/form-data",
    },
  });
};
export const apiUpdateMediaPost = async (postId: string, data: any) => {
  return Request.call({
    url: `/api/watch/posts/${postId}`,
    method: "PATCH",
    data,
  });
};
export const apiDeletePost = async (postId: string) => {
  return Request.call({
    url: `/api/watch/posts/${postId}`,
    method: "DELETE",
    data: {},
  });
};
export const apiLikeUnlikePost = async (postId: string, key: string) => {
  return Request.call({
    url: `/api/watch/posts/${postId}/${key}`,
    method: "POST",
    data: {},
  });
};
export const apiRepostPost = async (postId: string) => {
  return Request.call({
    url: `/api/watch/posts/${postId}/repost`,
    method: "POST",
    data: {},
  });
};

import cn from "classnames";
import { useState } from "react";
import classes from "./Description.module.scss";

interface Props {
  className?: string;
  content: string;
}

const Description = ({ className, content }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const canExpanded = content.length > 77;
  const shortContent =
    content.length > 77 ? `${content.substring(0, 77)}...` : content;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.expanded]: expanded,
        },
        className
      )}
    >
      <span className={classes.description}>
        {canExpanded && expanded ? content : shortContent}
      </span>
      {canExpanded && (
        <span
          className={classes.seeMore}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          See {expanded ? "less" : "more"}
        </span>
      )}
    </div>
  );
};

export default Description;

import cx from "classnames";
import get from "lodash/get";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import NightSky from "src/assets/images/night-sky.png";
import { ROLE_NAME } from "src/helpers/constants/role";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import Header from "src/layout/PlayLayout/Header";
import Sidebar from "src/layout/PlayLayout/Sidebar";
import { watchRoutes } from "src/routes";
import { useSidebar } from "src/utils/helper";
import { getAccessLevel, isAllowedRoute } from "src/utils/role";
import { useWindowSize } from "src/utils/useWindowSize";
import { isLoggedIn } from "src/utils/utils";
import classes from "./styles.module.scss";

interface Props {
  customRoutes?: any[];
  title?: string;
}

const getRoutes = (
  routes: {
    path: string;
    name: string;
    component: () => JSX.Element;
    layout: string;
    permission: string[];
  }[],
  accessLevel: string
) => {
  return routes.map(({ path, component: Component, permission }, key) => {
    const isAllowed = isAllowedRoute(permission, accessLevel);
    if (isAllowed) {
      return <Route path={path} element={<Component />} key={key} />;
    }
    return (
      <Route
        path={path}
        element={<Navigate to="/subscriptions" replace />}
        key={key}
      />
    );
  });
};

const WatchLayout = ({ customRoutes, title }: Props) => {
  const { width = 0 } = useWindowSize();
  const isMobile = width > 0 && width <= 768;
  const navigate = useNavigate();
  const { expand, toggle } = useSidebar();
  const userProfile = useUserProfile();
  const accessLevel = getAccessLevel(
    get(userProfile, "user.role.name", ROLE_NAME.FREE_ACCESS)
  );

  useEffect(() => {
    const isLogIn = isLoggedIn();

    if (!isLogIn) {
      navigate("/auth/access-info");
    } else if (isMobile) {
      navigate("/user/device-blocked");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.layoutWrapper}>
      <div className={classes.wrapper}>
        <Header title={title || "Watch"} />
        <div className={classes.watchContainer}>
          <Sidebar expand={expand} toggle={toggle} />
          <div
            className={cx(classes.container, {
              [classes.collapsed]: !expand,
            })}
            style={{
              backgroundImage: `url(${NightSky})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflow: "auto",
              height: "calc(100vh -  67px)",
            }}
            id="watch-container"
          >
            {
              <Routes>
                {getRoutes(customRoutes || watchRoutes, accessLevel)}
              </Routes>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default WatchLayout;

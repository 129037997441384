import React from "react";
import Button from "src/components/Button";
import Text from "src/components/Text";
import classes from "./styles.module.scss";

type Props = {
  title: string;
  actionButton: {
    style: object;
    text: string;
    action: () => void;
  };
  children: React.ReactNode;
};

const InfoCardWrapper: React.FC<Props> = ({
  title,
  actionButton,
  children,
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.mainCard}>
        <div className={classes.header}>
          <Text
            color="#fff"
            className="text-start"
            size={18}
            fontFamily="inter"
            fontWeight="bold"
          >
            {title}
          </Text>
          <Button
            buttonText={
              <Text
                color="#fff"
                className="text-start"
                size={18}
                fontFamily="inter"
                style={actionButton.style}
              >
                {actionButton.text}
              </Text>
            }
            onClick={actionButton.action}
            variant="link"
          />
        </div>
        <div className={classes.cardsWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default InfoCardWrapper;

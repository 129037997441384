import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import Lamp from "src/assets/icons/wish-lamp-icon.svg";
import Text from "src/components/Text";
import { RegionListProps } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import Clock from "src/pages/Subscriptions/assets/clock.svg";
import Map from "src/pages/Subscriptions/assets/map.svg";
import { useReducerData } from "src/store/hooks";
import { useSeason, useSeasonCountdown } from "src/utils/helper";
import { getNumberLampCount } from "src/utils/region";
import classes from "./styles.module.scss";

const PlayInfo = () => {
  const { data: savedRegions } = useReducerData("region", "savedRegionList", {
    data: [],
  });
  const { data: regionList }: { data: RegionListProps[] } = useReducerData(
    "region",
    "regionList",
    {
      data: [],
      loading: false,
    }
  );

  const { loading: detailsLoading }: { loading: boolean } = useReducerData(
    "auth",
    "subscriptionDetails",
    {
      loading: false,
    }
  );
  const { currentSeason } = useSeason();
  const { duration } = useSeasonCountdown();

  const [lampsInSelectedRegions, setLampsInSelectedRegions] = useState(0);

  const {
    days = 0,
    hours = 0,
    minutes = 0,
  } = duration || {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  useEffect(() => {
    if (savedRegions.length) {
      const lamps = getNumberLampCount(
        savedRegions,
        currentSeason?.id ?? 1,
        regionList
      );
      setLampsInSelectedRegions(lamps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRegions]);

  return (
    <div className={classes.details}>
      {detailsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={classes.detail}>
            <div className={classes.imageWrapper}>
              <img src={Map} alt="map" />
            </div>
            <div>
              <Text
                color="#fff"
                className="mb-1"
                size={16}
                fontWeight="medium"
                fontFamily="inter"
              >
                Playing in:
              </Text>
              <Text
                color="#fff"
                size={16}
                className="mb-0"
                fontWeight="semibold"
                fontFamily="inter"
              >
                {savedRegions.length} of {regionList.length} Regions
              </Text>
            </div>
          </div>
          <div className={classes.detail}>
            <div className={classes.imageWrapper}>
              <img src={Lamp} alt="lamp" />
            </div>
            <div>
              <Text
                color="#fff"
                size={16}
                className="mb-1"
                fontWeight="medium"
                fontFamily="inter"
              >
                In selected regions:
              </Text>
              <Text
                color="#fff"
                size={16}
                className="mb-0"
                fontWeight="semibold"
                fontFamily="inter"
              >
                ({lampsInSelectedRegions} lamps)
              </Text>
            </div>
          </div>
          <div className={classes.detail}>
            <div className={classes.imageWrapper}>
              <img src={Clock} alt="clock" />
            </div>
            <div>
              <Text
                color="#fff"
                size={16}
                className="mb-1"
                fontWeight="medium"
                fontFamily="inter"
              >
                Time left in season {currentSeason?.id || 1}:
              </Text>
              <Text
                color="#fff"
                size={16}
                className="mb-0"
                fontWeight="semibold"
                fontFamily="inter"
              >
                {days} Days • {hours} Hours • {minutes} Minutes
              </Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PlayInfo;

import cn from "classnames";
import { useState } from "react";
import { ReactComponent as FlagIcon } from "src/assets/icons/flag.svg";
// import { ReactComponent as GroupPlusIcon } from "src/assets/icons/group-plus.svg";
import { ReactComponent as ThreeDotsIcon } from "src/assets/icons/three-dots-icon.svg";
import Dropdown from "src/components/Dropdown";
import ReportModal from "../ReportModal";
import SocialButton from "../SocialButton";
import classes from "./OtherAction.module.scss";

interface Props {
  className?: string;
  onReportMedia: (reason: string, comment: string, cb: () => void) => void;
  reportLoading?: boolean;
}

const OtherAction = ({ className, onReportMedia, reportLoading }: Props) => {
  const [isReportModal, setIsReportModal] = useState(false);
  return (
    <>
      <div className={cn(classes.wrapper, className)}>
        <Dropdown
          text={<SocialButton icon={<ThreeDotsIcon />} />}
          options={[
            {
              value: "report",
              label: "Report",
              icon: <FlagIcon />,
            },
            // {
            //   value: "other",
            //   label: "Other",
            //   icon: <GroupPlusIcon />,
            // },
          ]}
          handleChange={(_, tValue) => {
            if (tValue === "report") {
              setIsReportModal(true);
            }
          }}
          noPadding
          overlayColor="gray"
        />
      </div>
      <ReportModal
        isOpen={isReportModal}
        onClose={() => {
          setIsReportModal(false);
        }}
        onSubmit={onReportMedia}
        loading={reportLoading}
      />
    </>
  );
};

export default OtherAction;

import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { ModalHeader } from "reactstrap";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import WarningModal from "src/components/WarningModal";
import { POST_STATUS_VALUES } from "src/helpers/constants";
import { checkValidUploadedSie, readFileSrc } from "src/utils/file";
import classes from "./UploadModal.module.scss";
import UploadContent from "./components/UploadContent";
import UploadPlaceholder from "./components/UploadPlaceholder";

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onPublish: (values: any) => void;
  onFileChanged: (f: any, key: string, cb?: () => void) => void;
  uploadLoading?: boolean;
  postId?: number | string;
  postStatusData?: any;
  publishLoading?: boolean;
  onReloadFileChanged?: (tFile: any, key: string) => void;
}

const UploadModal = ({
  className,
  isOpen,
  onClose,
  onPublish,
  onFileChanged,
  uploadLoading,
  postStatusData,
  publishLoading,
  onReloadFileChanged,
}: Props) => {
  const inputRef = useRef(null);
  const postStatus = postStatusData?.status;
  const [mediaUploaded, setMediaUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [fileSrc, setFileSrc] = useState("");
  const [isClosing, setIsClosing] = useState(false);
  const didUploaded = !!uploadedFile && !!uploadedFile.name;
  const isUploadImage = uploadedFile?.type?.includes("image/");
  const mediaKey = isUploadImage ? "image" : "video";
  const tMediaVerifying = postStatus !== POST_STATUS_VALUES.VERIFIED;
  const resetState = () => {
    setMediaUploaded(false);
    setUploadedFile(null);
    setFileSrc("");
  };
  const handleClose = () => {
    setIsClosing(true);
  };
  const focusTitle = () => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        (inputRef.current as any).focus();
      }
    }, 100);
  };

  useEffect(() => {
    if (isOpen) {
      resetState();
    }
  }, [isOpen]);
  useEffect(() => {
    if (
      postStatusData &&
      postStatusData.status === POST_STATUS_VALUES.VERIFIED &&
      !isUploadImage
    ) {
      setFileSrc(postStatusData.images?.[0]?.url || "");
      // setActiveThumbnail(postStatusData.images?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postStatusData]);

  return (
    <>
      <Modal
        backdrop
        className={cn(classes.modalWrapper, className)}
        bodyClassName={classes.modalBody}
        isOpen={isOpen}
        toggle={handleClose}
      >
        <ModalHeader
          className={classes.header}
          close={
            <Button
              buttonText="Close"
              rightIcon={<CloseIcon />}
              variant="link"
              onClick={handleClose}
            />
          }
        >
          <span>Upload Media</span>
          {didUploaded && !mediaUploaded && (
            <span>All posts are made public when published.</span>
          )}
        </ModalHeader>
        {didUploaded ? (
          <UploadContent
            onSaveDraft={(values) => {
              if (!publishLoading) {
                onPublish({
                  ...values,
                  isDraft: true,
                  isUploadImage,
                });
              }
            }}
            onSubmit={(values) => {
              if (!mediaUploaded) {
                setMediaUploaded(true);
              } else {
                onPublish({
                  ...values,
                  isUploadImage,
                });
              }
            }}
            mediaUploaded={mediaUploaded}
            mediaVerifying={tMediaVerifying}
            isUploadImage={isUploadImage}
            fileDetails={{
              name: uploadedFile?.name,
              size: uploadedFile?.size,
              length: postStatusData?.metadata?.duration,
            }}
            uploadLoading={uploadLoading}
            fileSrc={fileSrc}
            publishLoading={publishLoading}
            postStatusData={postStatusData}
            inputRef={inputRef}
            onFileChanged={async (tFile) => {
              if (checkValidUploadedSie(tFile)) {
                if (onReloadFileChanged) onReloadFileChanged(tFile, mediaKey);
                setFileSrc("");
                setUploadedFile(tFile);
                if (isUploadImage) {
                  const tFileSrc = await readFileSrc(tFile);
                  setFileSrc(tFileSrc as string);
                }
              }
            }}
          />
        ) : (
          <UploadPlaceholder
            onFileChange={(tFile: any) => {
              const tFileObj = tFile?.[0];
              const isImage = tFileObj?.type?.includes("image/");
              if (checkValidUploadedSie(tFileObj)) {
                if (isImage) {
                  onFileChanged(tFileObj, "image", async () => {
                    setUploadedFile(tFileObj);
                    const tFileSrc = await readFileSrc(tFileObj);
                    setFileSrc(tFileSrc as string);
                    focusTitle();
                  });
                } else {
                  onFileChanged(tFileObj, "video", async () => {
                    setUploadedFile(tFileObj);
                    setFileSrc("");
                    focusTitle();
                  });
                }
              }
            }}
            loading={uploadLoading}
          />
        )}
      </Modal>
      <WarningModal
        isOpen={isClosing}
        onClose={() => {
          setIsClosing(false);
        }}
        onSubmit={() => {
          setIsClosing(false);
          onClose();
        }}
        cancelText="Go Back"
        submitText="Cancel Upload"
        title="Cancel uploading this post? "
      >
        <span>
          Are you sure you want to cancel this upload? All Progress will be
          lost.
        </span>
      </WarningModal>
    </>
  );
};

export default UploadModal;

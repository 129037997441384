import isEmpty from "lodash/isEmpty";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Spinner from "src/components/Spinner";
import { NormalTab } from "src/types";
import { ChannelConfig, ChannelSection } from "src/types/channel";
import { MediaInfo } from "src/types/media";
import { getBasicMediaInfo } from "src/utils/media";
import ManageContent from "./ManageContent";
import classes from "./Watch.module.scss";
import ChannelBlock from "./components/ChannelBlock";
import ChannelInfo from "./components/ChannelInfo";
import { CHANNEL_CONFIG, UserDetailTab } from "./constants";
import { useActions, useSubData } from "./selectorData";

interface Props {
  title: string | React.ReactNode;
  isOpenUpload: boolean;
  setIsOpenUpload: (val: boolean) => void;
  reloadManageContentPosts: any;
  tab?: string;
  tabs?: NormalTab[];
  onTabChanged?: (tTab: string) => void;
}

const WatchSub = ({
  title,
  isOpenUpload,
  setIsOpenUpload,
  reloadManageContentPosts,
  tab,
  tabs,
  onTabChanged,
}: Props) => {
  const params = useParams();
  const isRepostsTab = tab === UserDetailTab.reposts;
  const channelSlug = String(params.channelSlug || "");
  const cConfig: ChannelConfig = CHANNEL_CONFIG[channelSlug] || {};
  const { updateWatchUser } = useActions();
  const {
    userFeed,
    userRepostFeed,
    userFeedLoading,
    userRepostFeedLoading,
    watchChannel,
    watchUser,
    watchChannelLoading,
  } = useSubData();

  const channelSections: ChannelSection[] = watchChannel.sections || [];
  const { pathname } = useLocation();
  const isMyChannelPage = pathname === "/watch/my-channel";
  const isManageContentPage = pathname === "/watch/my-channel/manage-content";
  const navigate = useNavigate();
  const myChannelMedias = (
    (isRepostsTab ? userRepostFeed : userFeed)?.items || []
  ).map((feedItem: MediaInfo) => {
    return {
      ...getBasicMediaInfo(feedItem),
      onClick: () => navigate(`${pathname}/playing-media/${feedItem.id}`),
    };
  });

  return (
    <>
      {isMyChannelPage && (
        <ChannelInfo
          isMyChannel
          userProfile={watchUser}
          onDescriptionSave={(val) => {
            updateWatchUser(watchUser.id, {
              desc: val,
            });
          }}
          tabs={tabs}
          tab={tab}
          onTabChanged={onTabChanged}
        />
      )}
      {isManageContentPage ? (
        <ManageContent
          isOpenUpload={isOpenUpload}
          setIsOpenUpload={setIsOpenUpload}
          reloadPosts={reloadManageContentPosts}
        />
      ) : (
        <div className={classes.subInner}>
          {!isMyChannelPage && (
            <>
              <h2>{title}</h2>
              {watchChannelLoading &&
                (!!cConfig.multipleSections ||
                  (!cConfig.multipleSections && isEmpty(watchChannel))) && (
                  <Spinner center />
                )}
            </>
          )}
          {isMyChannelPage ? (
            <ChannelBlock
              medias={myChannelMedias}
              hideButton
              loading={isRepostsTab ? userRepostFeedLoading : userFeedLoading}
              loadingBottom={
                !!(isRepostsTab ? userRepostFeed : userFeed).meta?.next_cursor
              }
            />
          ) : (
            <>
              {channelSections.map((section) => {
                const posts = (section.posts || []).map(
                  (feedItem: MediaInfo) => {
                    return {
                      ...getBasicMediaInfo(feedItem),
                      onClick: () =>
                        navigate(
                          `${pathname}${
                            cConfig.viewLink
                              ? `/${section.slug}/${section.title}`
                              : ""
                          }/playing-media/${feedItem.id}`
                        ),
                    };
                  }
                );
                return (
                  <ChannelBlock
                    key={section.slug}
                    title={cConfig.multipleSections ? section.title : ""}
                    medias={posts}
                    hideButton={!cConfig.viewLink}
                    loading={
                      !cConfig.multipleSections ? watchChannelLoading : false
                    }
                    loadingBottom={
                      !cConfig.multipleSections
                        ? !!section?.meta?.next_cursor
                        : false
                    }
                    onClick={
                      cConfig.viewLink
                        ? () => {
                            navigate(
                              `${cConfig.viewLink
                                ?.replace(
                                  ":subChannelSlug",
                                  String(section.slug)
                                )
                                .replace(":subChannelTitle", section.title)}`
                            );
                          }
                        : undefined
                    }
                  />
                );
              })}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default WatchSub;

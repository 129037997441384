import { GAME_HEADER_TIME_UP_CLASSNAME } from "src/helpers/constants";
const ICONS = [
  "wish-lamp",
  "burger",
  "chicken",
  "chocolate",
  "coin-bronze",
  "coin-gold",
  "coin-silver",
  "cookie",
  "corndog",
  "croissant",
  "cupcake",
  "diamond-blue",
  "diamond-purple",
  "donut",
  "earth",
  "fries",
  "gemstone-lime",
  "gold",
  "hotdog",
  "ice-cream",
  "jupiter",
  "mars",
  "mercury",
  "moon",
  "neptune",
  "piece-pizza",
  "pink-gem",
  "pink-tree",
  "popcorn",
  "purple-gem",
  "rocket",
  "samosa",
  "sandwitch",
  "saturn",
  "smoothie-cream",
  "soda",
  "solar",
  "stars",
  "taco",
  "telescope",
  "tiecake",
  "ufo",
  "ursa",
  "venus",
];

const COL = 9;
const ROW = 5;
const TIME = 60;

type Params = {
  onStart?: () => void;
  onRestart?: () => void;
  onEnd?: () => void;
  onSuccess?: () => void;
  answer?: string;
};

export const initGame = ({
  onStart,
  onRestart,
  onEnd,
  onSuccess,
  answer,
}: Params) => {
  let board: any = [];
  let flippedCard: any = [];
  let playBox: any;
  let timer: any;
  let secondLeft = 0;
  let checkTimeout: any;

  const openClassName = "card-animation";

  const startBlock: any = document.querySelector("#start");
  const startGameButton: any = document.querySelector("#start_game_btn");
  const tryAgainButton: any = document.querySelector("#tryAgain");
  const timeupBlock: any = document.querySelector("#time-up");
  const successBlock: any = document.querySelector("#success");
  const scoreEl: any = document.querySelector("#clue-game-score");

  const getShowedTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    let seconds: any = time - minutes * 60;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    return `${minutes}:${seconds}`;
  };

  const shuffle = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

      // swap elements array[i] and array[j]
      // we use "destructuring assignment" syntax to achieve that
      // you'll find more details about that syntax in later chapters
      // same can be written as:
      // let t = array[i]; array[i] = array[j]; array[j] = t
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const randomizeIcon = () => {
    board = [0, 0]; // push 2 wish lamp to board
    const totalAvailable = COL * ROW - 2;
    // const uniqueArray = [];
    // while (uniqueArray.length < totalAvailable) {
    //   const random = Math.floor(Math.random() * totalAvailable) + 1;
    //   if (!uniqueArray.includes(random)) {
    //     uniqueArray.push(...[random, random]);
    //   }
    // }
    // if (totalAvailable % 2 !== 0) {
    //   uniqueArray.pop(); // remove 1 element out of the array in case board has odd number
    // }

    // board.push(...uniqueArray);
    for (let index = 1; index <= totalAvailable; index++) {
      board.push(index);
    }
    shuffle(board);
  };

  const showResult = (state: any) => {
    const wrapper = document.createElement("div");
    wrapper.className = "center-wrapper";
    if (state) {
      successBlock.classList.remove("hide");
      if (onSuccess) onSuccess();
    } else {
      timeupBlock.classList.remove("hide");
      scoreEl.classList.add(GAME_HEADER_TIME_UP_CLASSNAME);
      if (onEnd) onEnd();
    }
    clearInterval(timer);
  };

  const handleCountDown = () => {
    timer = setInterval(() => {
      const tTimer = getShowedTime(--secondLeft);
      scoreEl.innerHTML = tTimer;
      if (secondLeft == 0) {
        showResult(false);
      }
    }, 1000);
  };

  const hideUnmatchedCard = () => {
    flippedCard = [];
    const cards = document.getElementsByClassName(openClassName);
    while (cards.length > 0) {
      const el: any = cards[0];
      el.classList.remove(openClassName);
      setTimeout(() => {
        el.style.backgroundImage = "";
      }, 100);
    }
  };

  const checkMatching = () => {
    const cardA = board[flippedCard[0]];
    const cardB = board[flippedCard[1]];
    if (cardA === cardB && cardA === 0) {
      // win game
      showResult(true);
      clearTimeout(timer);
    } else {
      hideUnmatchedCard();
    }
  };

  const handleShowIcon = (dom: any, flipIndex: any) => {
    const backCard = dom.querySelector(".card-back");
    backCard.style.backgroundImage = `url(${
      process.env.PUBLIC_URL
    }/icons/matching-game/${ICONS[board[flipIndex]]}.svg)`;
  };

  const handleClickCard = (event: any) => {
    const index = +event.target.getAttribute("data-index");
    if (!flippedCard.includes(index)) {
      if (flippedCard.length === 2) {
        checkMatching();
        clearTimeout(checkTimeout);
      }
      if (flippedCard.length < 2) {
        event.target.classList.add(openClassName);
        flippedCard.push(index);
        handleShowIcon(event.target, index);
        if (flippedCard.length === 2) {
          checkTimeout = setTimeout(() => {
            checkMatching();
          }, 1000);
        }
      }
    }
  };

  const renderCard = (shouldRemove = false) => {
    playBox = document.getElementById("play-box");
    playBox.style.gridTemplateColumns = `repeat(${COL}, 1fr)`;
    if (shouldRemove) {
      playBox.innerHTML = "";
    }
    randomizeIcon();
    for (let i = 0; i < COL * ROW; i++) {
      const el = document.createElement("div");
      el.className = "card";

      const front = document.createElement("div");
      front.className = "card-front";
      front.style.backgroundImage = `url(${process.env.PUBLIC_URL}/icons/matching-game/hide-card.svg)`;
      const back = document.createElement("div");
      back.className = "card-back";

      el.setAttribute("data-index", String(i));
      el.addEventListener("click", handleClickCard);

      el.appendChild(front);
      el.appendChild(back);
      playBox.appendChild(el);
    }
  };

  const handleStart = (fromBegin = true) => {
    secondLeft = TIME;
    if (fromBegin) {
      startBlock.classList.add("hide");
    } else {
      timeupBlock.classList.add("hide");
      renderCard(true);
    }
    handleCountDown();
  };
  renderCard(true);
  startGameButton.addEventListener("click", () => {
    if (!answer) {
      handleStart(true);
      scoreEl.classList.remove(GAME_HEADER_TIME_UP_CLASSNAME);
      if (onStart) onStart();
    }
  });
  tryAgainButton.addEventListener("click", () => {
    if (!answer) {
      handleStart(false);
      scoreEl.classList.remove(GAME_HEADER_TIME_UP_CLASSNAME);
      if (onRestart) onRestart();
    }
  });

  return { timer };
};

import cn from "classnames";
import { useEffect, useState } from "react";
import { SortMethod } from "src/types";
import classes from "./SortIcon.module.scss";

interface Props {
  className?: string;
  onClick?: () => void;
  defaultMethod?: SortMethod;
  onMethodChanged?: (val: SortMethod | undefined) => void;
}

const SortIcon = ({
  className = "",
  onMethodChanged,
  defaultMethod,
}: Props) => {
  const [method, setMethod] = useState<undefined | SortMethod>(undefined);

  useEffect(() => {
    if (defaultMethod !== method) {
      setMethod(defaultMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultMethod]);

  return (
    <span
      className={cn(
        classes.wrapper,
        "pointer",
        classes[`method_${method}`],
        className
      )}
      onClick={() => {
        const newMethod =
          method === undefined ? "asc" : method === "asc" ? "desc" : undefined;
        setMethod(newMethod);
        if (onMethodChanged) onMethodChanged(newMethod);
      }}
    >
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8047 10.0286C11.5444 9.76825 11.1223 9.76825 10.8619 10.0286L8 12.8905L5.13807 10.0286C4.87772 9.76825 4.45561 9.76825 4.19526 10.0286C3.93491 10.2889 3.93491 10.7111 4.19526 10.9714L7.5286 14.3047C7.78895 14.5651 8.21106 14.5651 8.47141 14.3047L11.8047 10.9714C12.0651 10.7111 12.0651 10.2889 11.8047 10.0286Z"
          fill="#E0E0E0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.19526 6.9714C4.45561 7.23175 4.87772 7.23175 5.13807 6.9714L8 4.10948L10.8619 6.9714C11.1223 7.23175 11.5444 7.23175 11.8047 6.9714C12.0651 6.71105 12.0651 6.28895 11.8047 6.02859L8.47141 2.69526C8.21106 2.43491 7.78895 2.43491 7.5286 2.69526L4.19526 6.02859C3.93491 6.28894 3.93491 6.71105 4.19526 6.9714Z"
          fill="#E0E0E0"
        />
      </svg>
    </span>
  );
};

export default SortIcon;

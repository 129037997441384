import cn from "classnames";
import React, { useRef } from "react";
import classes from "./Upload.module.scss";

interface Props {
  className?: string;
  placeholderClassName?: string;
  children?: React.ReactNode;
  onChange: (f: any) => void;
  accept?: string;
}

const Upload: React.FC<Props> = ({
  className,
  children,
  placeholderClassName,
  onChange,
  accept,
}) => {
  const inputRef = useRef(null);
  return (
    <div className={cn(classes.wrapper, className)}>
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => {
          onChange(e.target.files?.[0]);
        }}
        accept={accept}
      />
      <div
        className={cn(classes.placeholder, placeholderClassName)}
        onClick={() => {
          const tInputCurrent = inputRef?.current as any;
          if (tInputCurrent) tInputCurrent.click();
        }}
      >
        {children || <>Upload</>}
      </div>
    </div>
  );
};

export default Upload;

import * as announcementctions from "src/store/actions/announcement";
import * as authActions from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {
    currentAnnouncementChannels: useReducerData(
      "announcement",
      "currentAnnouncements.data.channel.sections",
      []
    ),
    pastAnnouncementChannels: useReducerData(
      "announcement",
      "pastAnnouncements.data.channel.sections",
      []
    ),
    userFeed: useReducerData("watch", "watchUserFeed.data", {}),
  };
};
export const useMainData = () => {
  return {
    announcementChannels: useReducerData(
      "announcement",
      "announcements.data.channel.sections",
      []
    ),
    announcementsLoading: useReducerData(
      "announcement",
      "announcements.loading",
      false
    ),
    currentAnnouncementChannels: useReducerData(
      "announcement",
      "currentAnnouncements.data.channel.sections",
      []
    ),
    pastAnnouncementChannels: useReducerData(
      "announcement",
      "pastAnnouncements.data.channel.sections",
      []
    ),
    currentAnnouncementLoading: useReducerData(
      "announcement",
      "currentAnnouncements.loading",
      false
    ),
    pastAnnouncementLoading: useReducerData(
      "announcement",
      "pastAnnouncements.loading",
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
    ...announcementctions,
  });
};

export const ANALYTICS_CONSTANTS = {
  page: {
    home: "home",
    preview: "preview",
    initial_signup: "initial_signup",
    account_details_signup: "account_details_signup",
    user_info_signup: "user_info_signup",
    t_and_c_signup: "t_and_c_signup",
    access_selection_signup: "access_selection_signup",
    plan_selection_signup: "plan_selection_signup",
    payment_info_signup: "payment_info_signup",
    signup_confirm: "signup_confirm",
    get_access_signup: "get_access_signup",
  },
  category: {
    sign_up: "Signup",
  },
  action: {
    sign_up_completed: "Signup Completed",
    sign_up_free: "Free Trial Sign ups",
    sign_up_full: "Full Access Sign ups",
    convert_free_to_full: "Convert free trial to full access",
  },
};

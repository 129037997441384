import cx from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { getRegion } from "src/api/regions";
import Card from "src/components/Card";
import RegionContent from "src/pages/Play/SelectRegions/Components/TooltipContent/Content";
import {
  getUsersSavedRegionList,
  updateSavedRegion,
} from "src/store/actions/regions";
import { useStoreActions } from "src/store/hooks";
import { Region } from "src/types/region";
import { useSeason } from "src/utils/helper";
import { getRegionInfo } from "src/utils/region";
import classes from "./styles.module.scss";
import { Props } from "./types";

const RegionCard: React.FC<Props> = ({
  region = "",
  isHovered = false,
  states = [],
  isJoined = false,
  handleHoveredRegion,
  handleRemoveRegion,
  handleAddIndividualRegion,
  testId,
  customCardClassName,
  data,
}) => {
  const {
    isAllLampFound,
    isInactiveRegion,
    season1,
    season2,
    isFoundEqualTotal,
    isDisabledView,
  } = getRegionInfo(data);
  const navigate = useNavigate();
  const actions = useStoreActions({
    updateSavedRegion,
    getUsersSavedRegionList,
  });

  const { currentSeason } = useSeason();
  const regionId = region.split("-")[1];

  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSaveRegion = async () => {
    setLoading(true);
    await handleAddIndividualRegion(region);
    setLoading(false);
  };

  const handleRemoveRegions = async () => {
    const formData = new FormData();
    const regionId = region.split("-")[1];
    formData.append("removed_regions[]", `${regionId}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.updateSavedRegion(formData);
    if (status) {
      handleRemoveRegion(region);
      // handleRegionSelect(region);
      // setIsOpen(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await actions.getUsersSavedRegionList();
    }
  };

  const handleMouseEnter = () => {
    setHover(true);
    handleHoveredRegion(region);
  };

  const handleMouseLeave = () => {
    handleHoveredRegion("");
    setHover(false);
  };

  const handleViewClues = async () => {
    setLoading(true);
    const response = await getRegion(regionId.toString());
    const regionData: Region = response.data;
    const activeSeasons = regionData.seasons.filter((s) => !!s.lamp);
    if (activeSeasons.some((season) => season.id === currentSeason?.id)) {
      navigate(`/play/clues?region=${regionId}&season=${currentSeason?.id}`);
      return;
    }
    return navigate(
      `/play/clues?region=${regionId}&season=${activeSeasons[0]?.id}`
    );
  };

  return (
    <Card
      id={`region-list-${region}`}
      customCardClassName={cx(customCardClassName, classes.cardWrapper1)}
      cardBodyClassName={cx(classes.cardWrapper, {
        [classes.joined]: isJoined,
        [classes.hover]: hover || isHovered,
        [classes.founded]:
          isAllLampFound || isInactiveRegion || isFoundEqualTotal,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={testId}
    >
      <RegionContent
        isJoined={isJoined}
        handleRemoveRegions={handleRemoveRegions}
        handleSaveRegion={handleSaveRegion}
        handleViewClues={handleViewClues}
        isAllLampFound={isAllLampFound}
        isInactiveRegion={isInactiveRegion}
        regionName={region.split("-").join(" ")}
        regionStates={states}
        className={classes.contentWrapper}
        stateShowMore
        joinLoading={loading}
        season1={season1}
        season2={season2}
        isDisabledView={isDisabledView}
      />
    </Card>
  );
};

export default RegionCard;

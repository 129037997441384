export const staticRegionList = [
  {
    id: 1,
    name: "Region 1",
    status: "active",
    slug: "region-1",
    color_code: "#E5C85F",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 1,
        name: "Alaska",
        region_id: 1,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 2,
        name: "Washington",
        region_id: 1,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 3,
        name: "Oregon",
        region_id: 1,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names: "Alaska, Washington, Oregon",
    lamp_data: [
      {
        id: 32,
        season_id: 1,
        found_status: "not_found",
        founded_at: null,
        status: "active",
        price: 500000,
        custom_launch_date: "2024-04-30",
      },
      {
        id: 40,
        season_id: 2,
        found_status: "not_found",
        founded_at: null,
        status: "active",
        price: 500000,
        custom_launch_date: "2024-05-31",
      },
    ],
  },
  {
    id: 2,
    name: "Region 2",
    status: "active",
    slug: "region-2",
    color_code: "#6C69F8",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 4,
        name: "Idaho",
        region_id: 2,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 5,
        name: "Wyoming",
        region_id: 2,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 6,
        name: "Montana",
        region_id: 2,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names: "Idaho, Wyoming, Montana",
    lamp_data: [
      {
        id: 34,
        season_id: 1,
        found_status: "not_found",
        founded_at: null,
        status: "active",
        price: 500000,
        custom_launch_date: "2024-05-01",
      },
    ],
  },
  {
    id: 3,
    name: "Region 3",
    status: "active",
    slug: "region-3",
    color_code: "#8053EA",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 7,
        name: "North Dakota",
        region_id: 3,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 8,
        name: "South Dakota",
        region_id: 3,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 9,
        name: "Minnesota",
        region_id: 3,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 10,
        name: "Iowa",
        region_id: 3,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names: "North Dakota, South Dakota, Minnesota, Iowa",
    lamp_data: [],
  },
  {
    id: 4,
    name: "Region 4",
    status: "active",
    slug: "region-4",
    color_code: "#E5C85F",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 11,
        name: "Wisconsin",
        region_id: 4,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 12,
        name: "Illinois",
        region_id: 4,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 13,
        name: "Michigan",
        region_id: 4,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 14,
        name: "Indiana",
        region_id: 4,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 15,
        name: "Ohio",
        region_id: 4,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names: "Wisconsin, Illinois, Michigan, Indiana, Ohio",
    lamp_data: [
      {
        id: 36,
        season_id: 2,
        found_status: "not_found",
        founded_at: null,
        status: "active",
        price: 200000,
        custom_launch_date: "2024-07-01",
      },
    ],
  },
  {
    id: 5,
    name: "Region 5",
    status: "active",
    slug: "region-5",
    color_code: "#E9A260",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 16,
        name: "Delaware",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 17,
        name: "Maryland",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 18,
        name: "New Jersey",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 19,
        name: "Pennsylvania",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 20,
        name: "New York",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 21,
        name: "Vermont",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 22,
        name: "New Hampshire",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 23,
        name: "Maine",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 24,
        name: "Massachusetts",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 25,
        name: "Rhode Island",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 26,
        name: "Connecticut",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 27,
        name: "Virginia",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 28,
        name: "West Virginia",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 29,
        name: "Washington D.C.",
        region_id: 5,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names:
      "Delaware, Maryland, New Jersey, Pennsylvania, New York, Vermont, New Hampshire, Maine, Massachusetts, Rhode Island, Connecticut, Virginia, West Virginia, Washington D.C.",
    lamp_data: [],
  },
  {
    id: 6,
    name: "Region 6",
    status: "active",
    slug: "region-6",
    color_code: "#8053EA",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 30,
        name: "California",
        region_id: 6,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 31,
        name: "Nevada",
        region_id: 6,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 32,
        name: "Arizona",
        region_id: 6,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 33,
        name: "Utah",
        region_id: 6,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 34,
        name: "Hawaii",
        region_id: 6,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names: "California, Nevada, Arizona, Utah, Hawaii",
    lamp_data: [],
  },
  {
    id: 7,
    name: "Region 7",
    status: "active",
    slug: "region-7",
    color_code: "#E9A260",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 35,
        name: "Colorado",
        region_id: 7,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 36,
        name: "Nebraska",
        region_id: 7,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 37,
        name: "Kansas",
        region_id: 7,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 38,
        name: "Oklahoma",
        region_id: 7,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names: "Colorado, Nebraska, Kansas, Oklahoma",
    lamp_data: [
      {
        id: 35,
        season_id: 1,
        found_status: "not_found",
        founded_at: null,
        status: "active",
        price: 500000,
        custom_launch_date: "2024-05-02",
      },
    ],
  },
  {
    id: 8,
    name: "Region 8",
    status: "active",
    slug: "region-8",
    color_code: "#E5C85F",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 39,
        name: "New Mexico",
        region_id: 8,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 40,
        name: "Texas",
        region_id: 8,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names: "New Mexico, Texas",
    lamp_data: [
      {
        id: 39,
        season_id: 1,
        found_status: "not_found",
        founded_at: null,
        status: "draft",
        price: null,
        custom_launch_date: null,
      },
    ],
  },
  {
    id: 9,
    name: "Region 9",
    status: "active",
    slug: "region-9",
    color_code: "#6C69F8",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 41,
        name: "Missouri",
        region_id: 9,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 42,
        name: "Arkansas",
        region_id: 9,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 43,
        name: "Louisiana",
        region_id: 9,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 44,
        name: "Mississippi",
        region_id: 9,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 45,
        name: "Alabama",
        region_id: 9,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 46,
        name: "Tennessee",
        region_id: 9,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 47,
        name: "Kentucky",
        region_id: 9,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names:
      "Missouri, Arkansas, Louisiana, Mississippi, Alabama, Tennessee, Kentucky",
    lamp_data: [
      {
        id: 38,
        season_id: 1,
        found_status: "not_found",
        founded_at: null,
        status: "active",
        price: 100000,
        custom_launch_date: "2024-04-30",
      },
    ],
  },
  {
    id: 10,
    name: "Region 10",
    status: "active",
    slug: "region-10",
    color_code: "#8053EA",
    created_at: "2023-03-21T14:35:43.000000Z",
    updated_at: "2023-03-21T14:35:43.000000Z",
    states: [
      {
        id: 48,
        name: "North Carolina",
        region_id: 10,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 49,
        name: "South Carolina",
        region_id: 10,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 50,
        name: "Georgia",
        region_id: 10,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
      {
        id: 51,
        name: "Florida",
        region_id: 10,
        created_at: "2023-03-21T14:35:43.000000Z",
        updated_at: "2023-03-21T14:35:43.000000Z",
      },
    ],
    state_names: "North Carolina, South Carolina, Georgia, Florida",
    lamp_data: [
      {
        id: 31,
        season_id: 1,
        found_status: "not_found",
        founded_at: null,
        status: "active",
        price: 500000,
        custom_launch_date: "2024-05-03",
      },
    ],
  },
];

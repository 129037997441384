import get from "lodash/get";
import { Reducer } from "redux";
import {
  getMoreWatchChannel,
  getMoreWatchUserFeed,
} from "src/utils/reducer/watch";
import { handleData } from "../middlewares/handleData";
import Types from "../types/watch";

const initialState = {
  watchUser: {
    loading: false,
    data: {},
    error: "",
  },
  watchChannel: {
    slug: "",
    loading: false,
    data: {},
    error: "",
  },
  watchSubChannel: {
    slug: "",
    loading: false,
    data: {},
    error: "",
  },
  watchUserPosts: {
    loading: false,
    data: {},
    error: "",
  },
  watchUserFeed: {
    userId: 0,
    loading: false,
    data: {},
    error: "",
  },
  watchUserRepostFeed: {
    userId: 0,
    loading: false,
    data: {},
    error: "",
  },
  updateWatchUser: {
    loading: false,
    data: {},
    error: "",
  },
  channelFollowers: {
    loading: false,
    isError: false,
    data: [],
  },
  updateChannelFollowStatus: {
    loading: false,
    data: {},
    error: "",
  },
};

const WatchReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.SYNC_UPDATE_WATCH_USER:
      return {
        ...state,
        watchUser: {
          ...state.watchUser,
          data: {
            ...state.watchUser.data,
            user: {
              ...state.watchUser.data.user,
              ...(payload || {}),
            },
          },
        },
      };
    case Types.SYNC_RESET_WATCH_USER_FEED_META:
      return {
        ...state,
        watchUserFeed: {
          ...state.watchUserFeed,
          data: {
            ...state.watchUserFeed.data,
            meta: {},
          },
        },
      };
    case Types.SYNC_RESET_WATCH_USER_REPOST_FEED_META:
      return {
        ...state,
        watchUserPosts: {
          ...state.watchUserPosts,
          data: {
            ...state.watchUserPosts.data,
            meta: {},
          },
        },
      };
    case Types.GET_WATCH_USER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          watchUser: {
            data: {},
            error: "",
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          watchUser: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          watchUser: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_WATCH_CHANNEL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          watchChannel: {
            data:
              !!meta?.payload?.slug &&
              prevState.watchChannel?.slug === meta?.payload?.slug
                ? {
                    ...prevState.watchChannel.data,
                  }
                : {},
            error: "",
            loading: true,
            slug: meta?.payload?.slug,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            watchChannel: {
              loading: false,
              data: meta?.payload?.isMore
                ? getMoreWatchChannel(prevState, payload)
                : payload.data?.channel || {},
              error: "",
              slug: meta?.payload?.slug,
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          watchChannel: {
            loading: false,
            data: {},
            error: payload,
            slug: meta?.payload?.slug,
          },
        }),
      });
    case Types.GET_WATCH_SUB_CHANNEL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          watchSubChannel: {
            data:
              !!meta?.payload?.slug &&
              prevState.watchSubChannel?.slug === meta?.payload?.slug
                ? {
                    ...prevState.watchSubChannel.data,
                  }
                : {},
            error: "",
            loading: true,
            slug: meta?.payload?.slug,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          watchSubChannel: {
            loading: false,
            data: meta?.payload?.isMore
              ? getMoreWatchChannel(prevState, payload, "watchSubChannel")
              : payload.data?.channel || {},
            error: "",
            slug: meta?.payload?.slug,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          watchSubChannel: {
            loading: false,
            data: {},
            error: payload,
            slug: meta?.payload?.slug,
          },
        }),
      });
    case Types.GET_WATCH_USER_POSTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          watchUserPosts: {
            ...prevState.watchUserPosts,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          watchUserPosts: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          watchUserPosts: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_WATCH_USER_FEED:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          watchUserFeed: {
            ...prevState.watchUserFeed,
            error: "",
            data:
              !!meta?.payload?.userId &&
              prevState.watchUserFeed?.userId === meta?.payload?.userId
                ? { ...prevState.watchUserFeed.data }
                : {},
            userId: meta?.payload?.userId,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          watchUserFeed: {
            loading: false,
            data: meta?.payload?.isMore
              ? getMoreWatchUserFeed(prevState, payload)
              : payload.data || {},
            error: "",
            userId: meta?.payload?.userId,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          watchUserFeed: {
            loading: false,
            data: {},
            error: payload,
            userId: meta?.payload?.userId,
          },
        }),
      });
    case Types.GET_WATCH_USER_REPOST_FEED:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          watchUserRepostFeed: {
            ...prevState.watchUserRepostFeed,
            error: "",
            data:
              !!meta?.payload?.userId &&
              prevState.watchUserRepostFeed?.userId === meta?.payload?.userId
                ? { ...prevState.watchUserRepostFeed.data }
                : {},
            userId: meta?.payload?.userId,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          watchUserRepostFeed: {
            loading: false,
            data: meta?.payload?.isMore
              ? getMoreWatchUserFeed(prevState, payload, "watchUserRepostFeed")
              : payload.data || {},
            error: "",
            userId: meta?.payload?.userId,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          watchUserRepostFeed: {
            loading: false,
            data: {},
            error: payload,
            userId: meta?.payload?.userId,
          },
        }),
      });
    case Types.UPDATE_WATCH_USER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateWatchUser: {
            data: {},
            error: "",
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateWatchUser: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
          watchUser: {
            ...prevState.watchUser,
            data: {
              ...prevState.watchUser.data,
              desc: meta?.payload?.desc,
            },
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateWatchUser: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_CHANNEL_FOLLOW_STATUS: {
      let updatedFollowCount = 0;
      const isFollowing = get(meta, "payload.isFollowing");
      if (isFollowing === true) {
        updatedFollowCount = 1;
      } else if (isFollowing === false) {
        updatedFollowCount = -1;
      }

      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateChannelFollowStatus: {
            data: {},
            error: "",
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateChannelFollowStatus: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
          watchUser: {
            ...prevState.watchUser,
            data: {
              ...prevState.watchUser.data,
              user: {
                ...(prevState?.watchUser?.data?.user
                  ? prevState.watchUser.data.user
                  : {}),
                follows_count:
                  (prevState?.watchUser?.data?.user?.follows_count ?? 0) +
                  updatedFollowCount,
              },
            },
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateChannelFollowStatus: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    }
    case Types.GET_CHANNEL_FOLLOWERS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          channelFollowers: {
            data: [],
            error: "",
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          channelFollowers: {
            loading: false,
            data: payload.data || [],
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          channelFollowers: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default WatchReducer;

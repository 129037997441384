import { Request } from "./request";

export const apiGetWatchUser = async (id: number | string) => {
  return Request.call({
    url: `/api/watch/users/${id}`,
    method: "GET",
  });
};
export const apiGetWatchChannel = async (slug: string, filters = {}) => {
  return Request.call({
    url: `/api/watch/channels/${slug}`,
    method: "GET",
    params: filters,
  });
};
export const apiGetWatchSubChannel = async (
  slug: string,
  subSlug: string,
  filters = {}
) => {
  return Request.call({
    url: `/api/watch/channels/${slug}/${subSlug}`,
    method: "GET",
    params: filters,
  });
};
export const apiGetWatchUserPosts = async (
  id: number | string,
  filters = {}
) => {
  return Request.call({
    url: `/api/watch/users/${id}/content`,
    method: "GET",
    params: filters,
  });
};
export const apiGetWatchUserFeed = async (
  id: number | string,
  filters = {}
) => {
  return Request.call({
    url: `/api/watch/users/${id}/feed`,
    method: "GET",
    params: filters,
  });
};
export const apiUpdateWatchUser = async (
  id: number | string,
  data: { desc: string }
) => {
  return Request.call({
    url: `/api/watch/users/${id}`,
    method: "POST",
    data,
  });
};

export const getChannelFollowers = async (userId: number) => {
  return Request.call({
    url: `api/watch/users/${userId}/followers`,
    method: "GET",
  });
};

export const apiUpdateChannelFollowStatus = async (
  id: number,
  isFollowing: boolean
) => {
  return Request.call({
    url: `/api/watch/users/${id}/follow`,
    method: "PUT",
    data: { is_follow: isFollowing },
  });
};

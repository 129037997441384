import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Table from "src/components/Table";
import WarningModal from "src/components/WarningModal";
import { TableColumn } from "src/types";
import classes from "./ManageContentTable.module.scss";
import DeletePostWarning from "./components/DeletePostWarning";
import {
  actionFormatter,
  contentFormatter,
  dateFormatter,
  statusFormatter,
} from "./utils";

interface Props {
  className?: string;
  onRowClick?: (row: Record<string, unknown>) => void;
  data: any[];
  meta: any;
  onPaginationChanged?: (val: any) => void;
  onPostDeleted: (postId: string, cb?: any) => void;
  deletePostLoading?: boolean;
  dataLoading?: boolean;
}

const ManageContentTable = ({
  className,
  onRowClick,
  data,
  meta,
  onPaginationChanged,
  onPostDeleted,
  deletePostLoading,
  dataLoading,
}: Props) => {
  const navigate = useNavigate();
  const [deleteObj, setDeleteObj] = useState(undefined as any);
  const [paginationObj, setPaginationObj] = useState<Record<string, unknown>>({
    sortName: "",
    sortMethod: "",
    page: 1,
  });
  const handleEditRow = (row: any) => {
    navigate(
      `/watch/my-channel/manage-content/post-details/${row.id}?action=edit`
    );
  };
  const handleDeleteRow = (row: any) => {
    setDeleteObj(row);
  };
  const columns: TableColumn[] = [
    {
      header: "Content",
      accessorKey: "content",
      cell: contentFormatter,
      width: "36%",
    },
    {
      header: "Status",
      accessorKey: "status",
      width: "12%",
      cell: statusFormatter,
      sortable: true,
    },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: dateFormatter,
      width: "12%",
      dataAlign: "center",
      sortable: true,
    },
    {
      header: "Region",
      accessorKey: "region",
      width: "10%",
      dataAlign: "center",
      cell: (obj: any) => obj.getValue() || 0,
      sortable: true,
    },
    {
      header: "Views",
      accessorKey: "views_count",
      width: "10%",
      dataAlign: "center",
      cell: (obj: any) => obj.getValue() || 0,
      sortable: true,
    },
    {
      header: "Loves",
      accessorKey: "likes_count",
      width: "10%",
      dataAlign: "center",
      cell: (obj: any) => obj.getValue() || 0,
      sortable: true,
    },
    {
      header: "Shares",
      accessorKey: "share_count",
      width: "10%",
      dataAlign: "center",
      cell: (obj: any) => obj.getValue() || 0,
      sortable: true,
    },
    {
      header: "",
      width: "50px",
      id: "action",
      cell: (obj: any) => {
        const tRow = obj.row?.original as any;
        return actionFormatter(
          tRow,
          handleEditRow,
          handleDeleteRow,
          dataLoading
        );
      },
      noClick: true,
    },
  ];

  useEffect(() => {
    setPaginationObj({
      sortName: "",
      sortMethod: "",
      page: 1,
    });
  }, []);

  return (
    <>
      <div className={cn(classes.wrapper, className)}>
        <Table
          data={data}
          columns={columns}
          pagination={{
            ...(meta || {}),
            sortObj: {
              name: paginationObj.sortName,
              method: paginationObj.sortMethod,
            },
            onPaginationChanged: ({ page }: { page: number }) => {
              setPaginationObj({
                ...paginationObj,
                page,
              });
              if (onPaginationChanged)
                onPaginationChanged({
                  page: page + 1,
                  sortName: paginationObj.name,
                  sortMethod: paginationObj.method,
                });
            },
          }}
          rowClick={onRowClick}
          className={classes.table}
          loading={dataLoading}
          onSortChanged={(tColumn, tSortMethod) => {
            setPaginationObj({
              ...paginationObj,
              sortName: tColumn,
              sortMethod: tSortMethod,
            });
            if (onPaginationChanged)
              onPaginationChanged({
                page: paginationObj.page,
                sortName: tColumn,
                sortMethod: tSortMethod,
              });
          }}
        />
      </div>
      <WarningModal
        isOpen={!isEmpty(deleteObj)}
        onClose={() => {
          setDeleteObj(undefined);
        }}
        onSubmit={() => {
          onPostDeleted(deleteObj.id, () => {
            setDeleteObj(undefined);
          });
        }}
        cancelText="Cancel"
        submitText="Delete Post"
        title="Permanently delete this post?"
        submitButonProps={{
          variant: "link",
          disabled: !deleteObj?.checked,
          linkHasLoading: true,
          loading: deletePostLoading,
        }}
      >
        <DeletePostWarning
          checked={deleteObj?.checked}
          onChange={(checked) => {
            setDeleteObj({
              ...deleteObj,
              checked,
            });
          }}
          title={deleteObj?.title}
          createdAt={deleteObj?.created_at}
          viewCount={deleteObj?.views_count}
          imgSrc={deleteObj?.images?.[1]?.url}
        />
      </WarningModal>
    </>
  );
};

export default ManageContentTable;

import cn from "classnames";
import classes from "./HotColdBarometerModal.module.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const MobileTag = ({ className = "", children }: Props) => {
  return <span className={cn(classes.mobileTag, className)}>{children}</span>;
};

export default MobileTag;

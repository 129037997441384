import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Input from "src/components/Input";
import InputMask from "src/components/InputMask";
import Select from "src/components/Select";
import Typography from "src/components/Typography";
import { MASK_PATTERN } from "src/helpers/constants";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { addSignupDetails } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { telephoneNumberRegex } from "src/utils/constants";
import { checkValidBirthDate } from "src/utils/date";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import { getDefaultFormattedNumber, getFormattedNumber } from "src/utils/utils";
import classes from "./styles.module.scss";
import { OptionType, ProfileInputProps } from "./types";

const ProfilePage = () => {
  const navigate = useNavigate();
  const signupDetail = useReducerData("auth", "signupDetails", {});
  const actions = useStoreActions({
    addSignupDetails,
  });
  const [searchParams] = useSearchParams();
  const isFullAccess = searchParams.get("full_access") === "true";

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Prefer Not to say", label: "Prefer Not to say" },
  ];

  const profileSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(
        telephoneNumberRegex,
        "Number needs to be in the format XXX-XXX-XXXX"
      ),
    birthDate: Yup.string()
      .required("Birth date is required")
      .test("dob", "Must be over 13 years old", function (value) {
        return checkValidBirthDate(value);
      }),
    gender: Yup.object()
      .required("Please select gender")
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
  });

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.user_info_signup);
  }, []);

  const selectedGender = genderOptions.find(
    (gender: { value: string }) => signupDetail?.gender === gender.value
  );

  const handleSubmit = async (
    values: ProfileInputProps,
    formikHelpers: FormikHelpers<ProfileInputProps>
  ) => {
    const signupData = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      phone: values?.phone.replaceAll("-", ""),
      birth_date: values?.birthDate,
      gender: values?.gender?.value,
    };
    actions.addSignupDetails({ ...signupDetail, ...signupData });
    navigate(`/auth/risk-waiver${isFullAccess ? "?full_access=true" : ""}`);
    formikHelpers.resetForm();
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainWrapper}>
        <Formik
          validateOnMount
          onSubmit={(values, formikHelpers) =>
            handleSubmit(values, formikHelpers)
          }
          validationSchema={profileSchema}
          initialValues={{
            firstName: signupDetail.first_name || "",
            lastName: signupDetail?.last_name || "",
            phone: getDefaultFormattedNumber(signupDetail?.phone) || "",
            birthDate: signupDetail?.birth_date || "",
            gender: selectedGender || (null as unknown as OptionType),
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<ProfileInputProps>) => (
            <Form className={cx(classes.signupInputs)}>
              <div className={classes.inputContainer}>
                <div className={classes.InputName}>
                  <FormGroup className={classes.formGroup}>
                    <Input
                      labelClassName={classes.label}
                      inputClassName={classes.input}
                      label="First Name"
                      required
                      value={values.firstName}
                      placeholder="John"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.firstName}
                      touched={touched.firstName}
                      name="firstName"
                    />
                  </FormGroup>
                  <FormGroup className={classes.formGroup}>
                    <Input
                      labelClassName={classes.label}
                      inputClassName={classes.input}
                      label="Last Name"
                      required
                      value={values.lastName}
                      placeholder="Doe"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.lastName}
                      touched={touched.lastName}
                      name="lastName"
                    />
                  </FormGroup>
                </div>
                <div className={classes.InputName}>
                  <FormGroup className={classes.formGroup}>
                    <Input
                      labelClassName={classes.label}
                      inputClassName={classes.input}
                      label="Phone Number"
                      required
                      placeholder="999-888-7777"
                      value={values.phone}
                      onChange={(value) => {
                        setFieldValue("phone", getFormattedNumber(value));
                      }}
                      onBlur={handleBlur}
                      error={errors.phone}
                      touched={touched.phone}
                      name="phone"
                    />
                  </FormGroup>
                  <FormGroup className={classes.formGroup}>
                    <InputMask
                      mask={MASK_PATTERN.DATE.MASK}
                      placeholder={MASK_PATTERN.DATE.PLACEHOLDER}
                      label="Birthdate"
                      name="birthDate"
                      className={classes.dateInput}
                      required
                      error={errors.birthDate}
                      touched={touched.birthDate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.birthDate}
                      labelClassName={classes.label}
                    />
                  </FormGroup>
                </div>
                <FormGroup className={classes.formGroup}>
                  <Select
                    label="Gender"
                    name="gender"
                    className={classes.input}
                    placeholder={
                      <Typography
                        className="mb-0"
                        fontFamily="inter"
                        variant="p18"
                      >
                        Select one
                      </Typography>
                    }
                    value={values?.gender}
                    error={errors?.gender as string}
                    touched={touched?.gender as unknown as boolean}
                    leftIconClass={classes.icon}
                    onBlur={() => setFieldTouched("gender", true)}
                    onChange={(selectedOption) => {
                      if (setFieldValue) {
                        setFieldValue("gender", selectedOption as OptionType);
                      }
                    }}
                    options={genderOptions}
                  />
                </FormGroup>
              </div>

              <div className={classes.buttonWrapper}>
                <Button
                  buttonText="Next"
                  buttonColor="purple-gradient"
                  disabled={!isValid}
                  type="submit"
                  buttonClassName={classes.button}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {isFullAccess && (
        <div className={classes.footerText}>
          Want to try it out first?{" "}
          <span
            onClick={() => {
              navigate("/play?free_trial_signup_success=true");
            }}
          >
            Try free trial
          </span>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;

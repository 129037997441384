export const scrollToTop = (elementId = "") => {
  if (elementId) {
    const el = document.getElementById(elementId);
    if (el) {
      el.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  }
};

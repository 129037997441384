import { useNavigate } from "react-router";
import Spinner from "src/components/Spinner";
import { Channel } from "src/types/channel";
import { MediaInfo } from "src/types/media";
import { WatchMedia } from "src/types/watch";
import { getBasicMediaInfo } from "src/utils/media";
import classes from "./Watch.module.scss";
import ChannelBlock from "./components/ChannelBlock";
import { CHANNEL_URL_MAPPING } from "./constants";
import { useMainData } from "./selectorData";

type ChannelData = {
  title: string;
  onClick: () => void;
  medias: WatchMedia[];
  slug?: string;
};

const WatchMain = () => {
  const { allMedia, allMediaLoading } = useMainData();
  const navigate = useNavigate();

  const channels: ChannelData[] = allMedia.map((channel: Channel) => {
    const channelUrl = CHANNEL_URL_MAPPING[String(channel.slug)];
    const channelUrl1 = CHANNEL_URL_MAPPING[`${String(channel.slug)}1`];
    return {
      slug: channel.slug,
      title: channel.title,
      onClick: () => {
        navigate(channelUrl1 || channelUrl);
      },
      medias: (channel.posts || [])
        .filter((_, index: number) => index <= 2)
        .map((post: MediaInfo) => {
          return {
            ...getBasicMediaInfo(post),
            onClick: () => navigate(`${channelUrl}/playing-media/${post.id}`),
          };
        }),
    };
  });
  return (
    <div className={classes.mainWrapper}>
      {allMediaLoading && <Spinner center />}
      {channels.map((channel) => {
        return <ChannelBlock {...channel} key={channel.slug} />;
      })}
    </div>
  );
};

export default WatchMain;

import { useLoadScript } from "@react-google-maps/api";
import orderBy from "lodash/orderBy";
import React from "react";
import { Spinner } from "reactstrap";
import Text from "src/components/Text";
import { PurchaseHistoryProps } from "src/types/clue";
import { useCluesData } from "src/utils/useData";
import PurchaseHistoryCard from "./PurchaseHistoryCard";
import classes from "./styles.module.scss";

type Props = {
  handleOpenModal: (history: PurchaseHistoryProps) => void;
  disabled?: boolean;
};

const PurchaseHistory: React.FC<Props> = ({ handleOpenModal, disabled }) => {
  const { advantageCluesPurchaseHistoryData } = useCluesData();
  const { data: purchaseHistoryData, loading } =
    advantageCluesPurchaseHistoryData;

  const googleMapApiKey = process.env.REACT_APP_GMAP_API_KEY as string;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey,
  });

  const sortedPurchaseHistoryData = orderBy(
    purchaseHistoryData,
    ["updated_at"],
    "desc"
  );

  return (
    <div className={classes.container}>
      <Text
        fontFamily="inter"
        size={22}
        fontWeight="bold"
        color="#fff"
        className={classes.purchaseTitle}
      >
        Purchase History
      </Text>
      <div className={classes.cardContainer}>
        {purchaseHistoryData.length === 0 && !loading ? (
          <div className={classes.noData}>
            <Text
              fontFamily="inter"
              fontWeight="bold"
              size={40}
              color="#e2e2e2"
            >
              No Purchased Clues Yet
            </Text>
          </div>
        ) : loading ? (
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        ) : (
          sortedPurchaseHistoryData?.map((history, index) => {
            return (
              <PurchaseHistoryCard
                key={index}
                history={history}
                isLoaded={isLoaded}
                handleOpenModal={handleOpenModal}
                disabled={disabled}
              />
            );
          })
        )}
      </div>
    </div>
  );
};

export default PurchaseHistory;

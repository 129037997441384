import cn from "classnames";
import React, { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "src/assets/icons/plus-icon.svg";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { useIndexData } from "src/pages/Watch/selectorData";
import SocialButton from "../SocialButton";
import classes from "./SocialFollow.module.scss";

interface Props {
  isPublic?: boolean;
  onClick?: (prevState: boolean) => boolean | void;
}

const SocialFollow = ({ isPublic, onClick }: Props) => {
  const { channelFollowers } = useIndexData();
  const userProfile = useUserProfile();
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    const isUserFollowing = channelFollowers?.some(
      (follower) => follower.f_b === userProfile.user.id
    );
    setIsFollowing(isUserFollowing);
  }, [channelFollowers, userProfile]);

  return (
    <SocialButton
      className={cn(classes.actionButton, {
        [classes.following]: isFollowing,
      })}
      icon={
        <span
          className={cn("d-inline-flex", {
            [classes.followingIconColor]: isFollowing,
          })}
        >
          <PlusIcon />
        </span>
      }
      title={
        <span
          className={cn("d-flex align-items-center user-select-none", {
            [classes.title]: isFollowing,
          })}
        >
          {isFollowing ? "Following" : "Follow"}
        </span>
      }
      color={!isFollowing ? "white" : undefined}
      onClick={
        isPublic
          ? () => {
              if (onClick) {
                const followState = onClick(isFollowing);
                setIsFollowing(followState as boolean);
              }
            }
          : () => {
              setIsFollowing(!isFollowing);
            }
      }
      active={isFollowing}
    />
  );
};

export default SocialFollow;

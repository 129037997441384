import { createTypes } from "./createTypes";

export default createTypes(
  "SYNC_RESET_POST_STATUS",
  "SYNC_SET_POST_STATUS",
  "SYNC_SET_POST_DETAILS",
  "SYNC_SET_MEDIA_DETAIL",
  "SYNC_RESET_UPLOAD_MEDIA_POST",
  "GET_ALL_MEDIA",
  "GET_MEDIA_DETAIL",
  "GET_MEDIA_RELATED",
  "GET_POST_STATUS",
  "GET_POST_DETAILS",
  "REPORT_MEDIA",
  "CREATE_POST",
  "UPLOAD_MEDIA_POST",
  "UPDATE_MEDIA_POST",
  "DELETE_POST",
  "LIKE_UNLIKE_POST",
  "REPOST_POST",
  "SEARCH_MEDIA"
);

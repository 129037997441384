import Button from "src/components/Button";
import Typography from "src/components/Typography";
import lampImage from "src/pages/PreviewPage/assets/images/lamp-bg.jpg";
import classes from "./styles.module.scss";

const GameInfo = () => {
  const data = [
    {
      title: "PLAY",
      subTitle: "ANYTIME",
      descriptionList: ["Solve the clues", "Hunt for Lamp$ ", "Find and WIN!"],
      url: "/a/tg-participation-agreement",
    },
    {
      title: "RULES",
      subTitle: "HOW TO WIN",
      descriptionList: [
        "Must be 13 years of age or older",
        "Must follow game code of conduct",
        "Must follow all rules",
      ],
      url: "/a/wl-rules",
    },
    {
      title: "PRICE",
      subTitle: "LOW COST, BIG PRIZES",
      buttonOneTitle: "$6 Per Month",
      buttonOneSubTitle: "Auto-renews every 30 days. ($72/year)",
      buttonTwoTitle: "$50 Per Year",
      buttonTwoSubTitle: "Auto-renews every 365 days (Save $22)",
      url: "/a/wl-pricing-info",
    },
  ];
  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${lampImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "left bottom",
      }}
    >
      <div className={classes.container}>
        <div className={classes.lampcards}>
          {data?.map((cardDetail, i) => (
            <div className={classes.lampcard} key={i}>
              <div className={classes.cards}>
                <div className={classes.cardContentWrapper}>
                  <Typography className={classes.title}>
                    <span className={classes.titleBorder}>
                      {cardDetail?.title}
                    </span>
                  </Typography>
                  <Typography
                    fontWeight="medium"
                    variant="p24"
                    className={classes.subTitle}
                  >
                    {cardDetail?.subTitle}
                  </Typography>
                  <div className="d-flex justify-content-center">
                    <ul className={classes.descriptionList}>
                      {cardDetail?.descriptionList?.map((description, i) => (
                        <li key={i} className={classes.description}>
                          {description}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {!cardDetail?.descriptionList && (
                    <div className="mb-3">
                      <div className={classes.buttonWrapper}>
                        <Typography
                          fontWeight="bold"
                          variant="p28"
                          className="mb-0"
                        >
                          {cardDetail?.buttonOneTitle}
                        </Typography>
                        <Typography variant="p16" className="mb-0">
                          {cardDetail?.buttonOneSubTitle}
                        </Typography>
                      </div>
                      <Typography variant="p20" className="mt-3">
                        OR
                      </Typography>
                      <div className={classes.buttonWrapper}>
                        <Typography
                          fontWeight="bold"
                          variant="p28"
                          className="mb-0"
                        >
                          {cardDetail?.buttonTwoTitle}
                        </Typography>
                        <Typography variant="p16" className="mb-0">
                          {cardDetail?.buttonTwoSubTitle}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
                <div className={classes.detailsButtonWrapper}>
                  <Button
                    buttonText="More Details"
                    buttonClassName={classes.button}
                    onClick={() => {
                      if (window) {
                        (window as any).location = cardDetail.url;
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GameInfo;

import * as authActions from "src/store/actions/auth";
import * as engagementActions from "src/store/actions/engagement";
import { useReducerData, useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {
    engagementsData: useReducerData(
      "engagement",
      "engagements.data.channel.sections[0]",
      {}
    ),
    userFeed: useReducerData("watch", "watchUserFeed.data", {}),
  };
};
export const useMainData = () => {
  return {
    engagementsData: useReducerData(
      "engagement",
      "engagements.data.channel.sections[0]",
      {}
    ),
    engagementsLoading: useReducerData(
      "engagement",
      "engagements.loading",
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
    ...engagementActions,
  });
};

import cn from "classnames";
import { ReactComponent as LampCircle0Icon } from "src/assets/icons/lamp-circle-0-icon.svg";
import { ReactComponent as LampLoadingCircleIcon } from "src/assets/icons/lamp-loading-circle-icon.svg";
import classes from "./LampIcon.module.scss";

interface Props {
  className?: string;
  status: "verifying" | "success";
}

const LampIcon = ({ className, status }: Props) => {
  return (
    <div
      className={cn(classes.wrapper, classes[`status_${status}`], className)}
    >
      <LampCircle0Icon />
      {status === "verifying" && (
        <LampLoadingCircleIcon className={classes.loading} />
      )}
    </div>
  );
};

export default LampIcon;

import cx from "classnames";
import get from "lodash/get";
import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import NightSky from "src/assets/images/night-sky.png";
import Button from "src/components/Button";
import BurgerIcon from "src/pages/Home/assets/icons/burger-icon.svg";
import { getPrivatePath } from "src/pages/SharePost/utils";
import { sharePostRoutes } from "src/routes";
import { useReducerData } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import Header from "../PlayLayout/Header";
import MenuDropdown from "./MenuDropdown";
import classes from "./SharePostLayout.module.scss";

const getRoutes = (
  routes: {
    path: string;
    name: string;
    component: () => JSX.Element;
    layout: string;
  }[]
) => {
  return routes.map(({ path, component: Component }, key) => {
    return <Route path={path} element={<Component />} key={key} />;
  });
};

const SharePostLayout: React.FC = () => {
  const { width = 0 } = useWindowSize();
  const isSmallScreen = width <= 1024;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const privatePath = String(searchParams.get("private-path") || "");
  const postId = String(searchParams.get("id"));
  const sharedMediaDetail = useReducerData("sharePost", "sharePost.data", {});
  const postChannel = get(sharedMediaDetail, "channels")?.[0];
  const alternativePrivatePath = getPrivatePath(
    postChannel?.slug,
    postId,
    postChannel?.title
  );
  const tPrivatePath = privatePath || alternativePrivatePath;
  const contentRight = isSmallScreen ? (
    <MenuDropdown
      signinUrl={`/auth/login?private-path=${tPrivatePath}`}
      signupUrl={`/auth/access-info?private-path=${tPrivatePath}`}
    >
      <img alt="burger" src={BurgerIcon} />
    </MenuDropdown>
  ) : (
    <div className={classes.contentRight}>
      <Button
        buttonText="Sign In"
        variant="link"
        buttonColor="purple"
        onClick={() => {
          navigate(`/auth/login?private-path=${tPrivatePath}`);
        }}
      />
      <Button
        buttonText="Sign Up"
        buttonColor="purple"
        onClick={() => {
          navigate(`/auth/access-info?private-path=${tPrivatePath}`);
        }}
      />
    </div>
  );
  return (
    <div className={classes.layoutWrapper}>
      <div className={classes.wrapper}>
        <Header
          title="Wish Lamp$ Watch"
          contentRight={contentRight}
          className={classes.header}
        />
        <div
          className={cx(classes.container, {})}
          style={{
            backgroundImage: `url(${NightSky})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "auto",
            height: "calc(100vh -  67px)",
          }}
          id="watch-container"
        >
          {<Routes>{getRoutes(sharePostRoutes)}</Routes>}
        </div>
      </div>
    </div>
  );
};

export default SharePostLayout;

import cn from "classnames";
import { useEffect } from "react";
import classes from "./Matching.module.scss";
import { initGame } from "./utils";

interface Props {
  onSuccess?: () => void;
  answer?: string;
  disabled?: boolean;
}

const Matching = ({ onSuccess, answer, disabled }: Props) => {
  useEffect(() => {
    const { timer } = initGame({
      onRestart: () => {
        // restart event
      },
      onStart: () => {
        // start event
      },
      onEnd: () => {
        // end event
      },
      onSuccess: disabled ? undefined : onSuccess,
      answer,
    });

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className="game-container">
        <div id="play-box"></div>
        <div
          id="start"
          className={cn("center-wrapper", {
            hide: !!answer,
          })}
        >
          <div>
            <p>Play!</p>
            <button id="start_game_btn">Start Game</button>
          </div>
        </div>
        <div id="time-up" className="center-wrapper time-up-wrapper hide">
          <div>
            <p>Sorry, Times Up!</p>
            <button id="tryAgain">Try Again</button>
          </div>
        </div>
        <div
          id="success"
          className={cn("center-wrapper success-wrapper", {
            hide: !answer,
          })}
        >
          <div>
            <p className="congrats">Congratulations!</p>
            <p className="direction">Here is your clue answer:</p>
            <div
              className={cn("answer", {
                getting: !answer,
              })}
              id="custom-game-answer"
            >
              {answer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Matching;

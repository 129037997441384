import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import get from "lodash/get";
import React, { useState } from "react";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import Input from "src/components/Input";
import Select from "src/components/Select";
import Text from "src/components/Text";
import { OptionType } from "src/pages/Auth/Profile/types";
import { countries } from "src/pages/Subscription/Payment/constant";
import { ErrorMessageProps } from "src/pages/Subscription/Payment/types";
import { addPaymentMethods, getPaymentMethods } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import classes from "./PaymentForm.module.scss";

export type FormProps = {
  name_on_card: string;
  country: OptionType;
  zip_code: string;
  is_default: boolean;
};

export type StripeStateProps =
  | stripeJs.StripeCardCvcElementChangeEvent
  | stripeJs.StripeCardNumberElementChangeEvent
  | stripeJs.StripeCardExpiryElementChangeEvent;

type StripeElementProps = {
  cardExpiry: StripeStateProps | null;
  cardCvc: StripeStateProps | null;
  cardNumber: StripeStateProps | null;
};

type Props = {
  toggleFlow: () => void;
};

const PaymentForm: React.FC<Props> = ({ toggleFlow }) => {
  const stripe = useStripe();
  const elements = useElements();
  const actions = useStoreActions({
    addPaymentMethods,
    getPaymentMethods,
  });

  const userDetails = useReducerData("auth", "user", {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorMessageProps>({
    number: "",
    date: "",
    code: "",
  });

  const initialStripeValue = {
    cardExpiry: null,
    cardCvc: null,
    cardNumber: null,
  };

  const [stripeState, setStripeState] =
    useState<StripeElementProps>(initialStripeValue);

  const customCardStyle = {
    base: {
      fontSize: "18px",
      color: "#000",
      "::placeholder": {
        color: "#A1A1A1",
        fontSize: "18px",
        lineHeight: "18px",
      },
    },
    invalid: {
      iconColor: "#333333",
      color: "#000",
    },
    complete: {
      iconColor: "#333333",
    },
  };

  const handleInputChange = (event: StripeStateProps) => {
    setStripeState((prev) => ({
      ...prev,
      [event.elementType]: event,
    }));
  };

  const handle_blur = (event: { elementType: string }) => {
    const cardNumberError = stripeState?.cardNumber?.empty
      ? "Card number is required"
      : stripeState?.cardNumber
      ? "Your card number is incomplete"
      : "Card number is required";
    const cardExpiryError = stripeState?.cardExpiry?.empty
      ? "Card expiry is required"
      : stripeState?.cardExpiry
      ? "Your card's expiry date is incomplete"
      : "Card expiry is required";
    const cardCvcError = stripeState?.cardCvc?.empty
      ? "Card cvc is required"
      : stripeState?.cardCvc
      ? "Your card's security code is incomplete"
      : "Card cvc is required";

    switch (event.elementType) {
      case "cardNumber":
        return setError({
          ...error,
          number: stripeState?.cardNumber?.complete
            ? ""
            : stripeState?.cardNumber?.error?.message || cardNumberError,
        });
      case "cardExpiry":
        return setError({
          ...error,
          date: stripeState?.cardExpiry?.complete
            ? ""
            : stripeState?.cardExpiry?.error?.message || cardExpiryError,
        });
      case "cardCvc":
        return setError({
          ...error,
          code: stripeState?.cardCvc?.complete
            ? ""
            : stripeState?.cardCvc?.error?.message || cardCvcError,
        });
      default:
        return error;
    }
  };

  const paymentSchema = Yup.object().shape({
    is_default: Yup.boolean(),
    name_on_card: Yup.string().required("Name is required"),
    zip_code: Yup.string().required("zip code is required"),
    country: Yup.object()
      .required("Please select country")
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
  });

  const handleSubmit = async (values: FormProps) => {
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const cardNumberElement = await elements?.getElement(CardNumberElement);
    if (cardNumberElement && stripe) {
      const { token } = await stripe.createToken(cardNumberElement, {
        name: values.name_on_card,
      });
      if (token) {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement,
          billing_details: {
            name: values?.name_on_card,
            email: userDetails?.email,
            phone: userDetails?.phone,
            address: {
              country: values?.country?.value,
              postal_code: values?.zip_code,
            },
          },
        });
        if (paymentMethod) {
          const paymentDetails = {
            payment_method_id: paymentMethod?.id,
            is_default: values.is_default,
          };
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const res = await actions.addPaymentMethods(paymentDetails);
          const status = get(res, "status", 0);
          if (status) {
            actions.getPaymentMethods();
            toggleFlow();
          }
        }
      }
    }
    setLoading(false);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputs}>
        <Formik
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={paymentSchema}
          initialValues={{
            name_on_card: "",
            zip_code: "",
            country: null as unknown as OptionType,
            is_default: false,
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<FormProps>) => (
            <Form className={cx(classes.formWrapper)}>
              <div>
                <FormGroup
                  className={cx("text-start mb-3", classes.formGroupInput)}
                >
                  <Input
                    inputGroupClassName={classes.inputGroup}
                    labelClassName={classes.label}
                    label="Name On Card"
                    value={values.name_on_card}
                    placeholder="John Doe"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name_on_card}
                    touched={touched.name_on_card}
                    name="name_on_card"
                  />
                </FormGroup>
                <div className={classes.stripeInputs}>
                  <div
                    className={classes.cardNumber}
                    data-testid="stripe_card_number"
                  >
                    <label className="mb-2">Card Number</label>
                    <CardNumberElement
                      className={cx("inputFieldStripe", {
                        ["stripeError"]: error.number,
                      })}
                      options={{
                        placeholder: "16 digits",
                        style: customCardStyle,
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                      }}
                      onBlur={(event) => {
                        handle_blur(event);
                      }}
                    />
                    <p className={classes.errorMessage}>{error.number}</p>
                  </div>
                  <div className={classes.expiryAndCvc}>
                    <div
                      className={classes.cvcExpiry}
                      data-testid="stripe_exp_date"
                    >
                      <label className="mb-2">Exp</label>
                      <CardExpiryElement
                        className={cx("inputFieldStripe", {
                          ["stripeError"]: error.date,
                        })}
                        options={{
                          placeholder: "MM / YY",
                          style: customCardStyle,
                        }}
                        onBlur={(event) => {
                          handle_blur(event);
                        }}
                        onChange={(event) => {
                          handleInputChange(event);
                        }}
                      />
                      <p className={classes.errorMessage}>{error.date}</p>
                    </div>
                    <div data-testid="stripe_cvc">
                      <label className="mb-2">CVC</label>
                      <CardCvcElement
                        className={cx("inputFieldStripe", {
                          ["stripeError"]: error.code,
                        })}
                        options={{
                          placeholder: "123",
                          style: customCardStyle,
                        }}
                        onBlur={(event) => {
                          handle_blur(event);
                        }}
                        onChange={(event) => {
                          handleInputChange(event);
                        }}
                      />
                      <p className={classes.errorMessage}>{error.code}</p>
                    </div>
                  </div>
                </div>
                <div className={classes.address}>
                  <FormGroup className={classes.selectWrapper}>
                    <Select
                      className={classes.select}
                      label="Country"
                      name="country"
                      placeholder="Type here"
                      value={values?.country}
                      error={errors?.country as string}
                      touched={touched?.country as unknown as boolean}
                      leftIconClass={classes.icon}
                      onBlur={() => {
                        setFieldTouched("country", true);
                      }}
                      onChange={(selectedOption) => {
                        if (setFieldValue) {
                          setFieldValue(
                            "country",
                            selectedOption as OptionType
                          );
                        }
                      }}
                      options={countries}
                    />
                  </FormGroup>
                  <FormGroup className={classes.selectWrapper}>
                    <Input
                      inputGroupClassName={classes.inputGroup}
                      labelClassName={classes.label}
                      label="Zip Code"
                      value={values.zip_code}
                      placeholder="Type here"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.zip_code}
                      touched={touched.zip_code}
                      name="zip_code"
                    />
                  </FormGroup>
                </div>
                <FormGroup
                  className={cx("text-start ", classes.formGroupCheckBox)}
                >
                  <CheckBox
                    id="is_default"
                    onChange={handleChange}
                    checked={values.is_default}
                    onBlur={handleBlur}
                    error={errors.is_default}
                    touched={touched.is_default}
                    showOutline
                    label={
                      <Text size={16} fontFamily="inter">
                        Make this my default card
                      </Text>
                    }
                    name="is_default"
                  />
                </FormGroup>
              </div>
              <hr className={classes.divider} />
              <div className={classes.buttonWrapper}>
                <Button
                  buttonText={
                    <Text
                      color="#fff"
                      size={24}
                      fontFamily="inter"
                      fontWeight="bold"
                    >
                      Add Card
                    </Text>
                  }
                  loading={loading}
                  disabled={
                    !isValid ||
                    !dirty ||
                    !stripeState.cardCvc?.complete ||
                    !stripeState.cardExpiry?.complete ||
                    !stripeState.cardNumber?.complete
                  }
                  type="submit"
                  className={classes.button}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PaymentForm;

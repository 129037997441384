import { Dispatch } from "redux";
import {
  ChangeSubscriptionData,
  RemoveDeviceProps,
  changeSubscription as changeSubscriptionApi,
  getDevices as getDevicesApi,
  getNewsData as getNewsDataApi,
  getPlayCountdown as getPlayCountdownApi,
  removeDevice as removeDeviceApi,
} from "src/api/user";
import Types from "../types/user";

export const getDevices = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_DEVICES,
    asyncCall: () => {
      return getDevicesApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const removeDevice =
  (data: RemoveDeviceProps, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { id: data.id },
      type: Types.REMOVE_DEVICE,
      asyncCall: () => {
        return removeDeviceApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const changeSubscription =
  (data: ChangeSubscriptionData, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.CHANGE_SUBSCRIPTION,
      asyncCall: () => {
        return changeSubscriptionApi(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

export const getNewsData =
  (category: string, cb?: any) => (dispatch: Dispatch) => {
    return dispatch({
      isAysncCall: true,
      payload: {},
      type: Types.GET_NEWS_DATA,
      asyncCall: () => {
        return getNewsDataApi(category);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getPlayCountdown = (cb?: any) => (dispatch: Dispatch) => {
  return dispatch({
    isAysncCall: true,
    payload: {},
    type: Types.GET_PLAY_COUNTDOWN,
    asyncCall: () => {
      return getPlayCountdownApi();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

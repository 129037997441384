import cn from "classnames";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { getVideoDuration } from "src/utils/number";
import classes from "./Video.module.scss";

interface Props {
  source: string;
  thumbnail?: string;
  videoClassName?: string;
  videoDuration?: number;
}

const Video = ({
  source,
  videoClassName = "",
  videoDuration,
  thumbnail,
}: Props) => {
  const [hover, setHover] = useState<boolean | undefined>(undefined);
  // const [duration, setDuration] = useState<number>(0);
  const playingProps: {
    playing?: boolean;
    playsinline?: boolean;
    muted?: boolean;
    volume?: number;
  } = {};
  if (hover === true) {
    playingProps.playing = true;
    playingProps.playsinline = true;
    playingProps.muted = true;
    playingProps.volume = 0.5;
  }

  return (
    <div
      className={cn(classes.wrapper)}
      onMouseEnter={() => {
        if (hover === undefined) {
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        if (hover === true) {
          setHover(undefined);
        }
      }}
    >
      <ReactPlayer
        url={source}
        width="100%"
        height="100%"
        // onDuration={setDuration}
        onProgress={(state) => {
          const intPlayed = parseInt(String(state.playedSeconds || 0), 10);
          if (intPlayed >= 15) {
            setHover(false);
          }
        }}
        {...playingProps}
        light={hover === undefined && !!thumbnail && <img src={thumbnail} />}
        className={cn(classes.video, videoClassName)}
      />
      <div className={classes.duration}>
        {getVideoDuration(videoDuration || 0)}
      </div>
    </div>
  );
};

export default Video;

import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import get from "lodash/get";
import isString from "lodash/isString";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import { ResetPasswordProps } from "src/api/auth";
import copyrightIcon from "src/assets/icons/copyright-icon.svg";
import Button from "src/components/Button";
import Input from "src/components/Input";
import Typography from "src/components/Typography";
import { useAuthActions } from "src/utils/useActions";
import { useAuthData } from "src/utils/useData";
import {
  currentYear,
  getUserAgentData,
  passwordValidator,
} from "src/utils/utils";
import classes from "./ResetPassword.module.scss";

export type ResetPasswordFormProps = Pick<
  ResetPasswordProps,
  "password" | "password_confirmation"
>;

const ResetPassword = () => {
  const navigate = useNavigate();
  const { resetPassword } = useAuthActions();
  const [searchParams] = useSearchParams();
  const { data: deviceData = null, error: deviceError } = useVisitorData({
    extendedResult: true,
  });
  const userAgent = getUserAgentData();
  const email = searchParams.get("email") || "";
  const token = searchParams.get("token") || "";

  const {
    resetPassword: { loading },
  } = useAuthData();

  const [error, setError] = useState("");
  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required()
      .matches(passwordValidator.length, "At least 8 characters")
      .matches(passwordValidator.uppercase, "One uppercase")
      .matches(passwordValidator.lowercase, "One lowercase")
      .matches(passwordValidator.number, "One number")
      .matches(passwordValidator.unique, "One special character"),

    password_confirmation: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Password does not match"),
  });

  useEffect(() => {
    if (deviceError) {
      toast.error("Please disable any ad blocker plugin you have installed.");
    }
  }, [deviceError]);

  const handleSubmit = async (
    values: ResetPasswordFormProps,
    formikHelpers: FormikHelpers<ResetPasswordFormProps>
  ) => {
    const submitValues = {
      email,
      token,
      device_name: userAgent.deviceName + " - " + userAgent.type,
      device_token: deviceData?.visitorId || "",
      fp_request_id: deviceData?.requestId || "",
      ...values,
    };
    const res = await resetPassword(submitValues);

    const status = get(res, "status", 0);

    const message = get(res, "data.message", "Something went wrong");

    const isFromValidDevice =
      get(res, "data.message") !== "Device not registered.";

    if (!isFromValidDevice) {
      toast.error("Device not registered");
      return;
    }

    if (status) {
      navigate("/play/my-dashboard");
      formikHelpers.resetForm();
    } else {
      if (isString(message)) {
        setError(message);
      } else {
        const errorMessage = Object.values(message).flatMap((b) => b);
        setError(errorMessage[0] as string);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainWrapper}>
        <div>
          <Formik
            onSubmit={(values, formikHelpers) =>
              handleSubmit(values, formikHelpers)
            }
            validationSchema={forgotPasswordSchema}
            initialValues={{
              password: "",
              password_confirmation: "",
            }}
          >
            {({
              values,
              touched,
              errors,
              handleBlur,
              handleChange,
              isValid,
              dirty,
            }: FormikProps<ResetPasswordFormProps>) => (
              <Form className={cx(classes.signupInputs, "d-flex flex-column")}>
                <FormGroup className={classes.formGroup}>
                  <Input
                    showPasswordIcon
                    labelClassName={classes.label}
                    label="New Password"
                    value={values.password}
                    placeholder="Enter your password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                    name="password"
                    type="password"
                  />
                </FormGroup>
                <FormGroup className={classes.formGroup}>
                  <Input
                    showPasswordIcon
                    labelClassName={classes.label}
                    label="Confirm New Password"
                    value={values.password_confirmation}
                    placeholder="Enter your password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password_confirmation}
                    touched={touched.password_confirmation}
                    name="password_confirmation"
                    type="password"
                  />
                </FormGroup>

                <div className={classes.submit}>
                  <Button
                    buttonText="Login"
                    buttonColor="secondary"
                    disabled={!isValid || !dirty}
                    loading={loading}
                    type="submit"
                    buttonClassName={classes.button}
                  />
                </div>
                <Typography className={classes.errorMessage}>
                  {error}
                </Typography>

                <div className={classes.mobileSignin}>
                  <Button
                    buttonColor="secondary"
                    onClick={() => navigate("/auth/access-info")}
                    buttonText={<Typography variant="p16">Sign Up</Typography>}
                    variant="link"
                  />
                </div>
                <div className={classes.footer}>
                  <Typography variant="p12">
                    <span>
                      <img src={copyrightIcon} alt="copyright-icon" />
                    </span>{" "}
                    Copyright {currentYear}{" "}
                  </Typography>
                  <div className={classes.footerLink}>
                    <a href="#">Privacy</a>
                    <Typography variant="p12"> • </Typography>
                    <a href="#"> Terms and Conditions</a>
                  </div>
                </div>
                <div className={classes.mobileNext}>
                  <Button
                    buttonText="Login"
                    buttonColor="secondary"
                    disabled={!isValid || !dirty}
                    loading={loading}
                    type="submit"
                    buttonClassName={classes.button}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

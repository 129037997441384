export const ROLE_CHECKING = {
  FULL_ACCESS: "full_access",
  FREE_ACCESS: "free_access",
  FREE_TRIAL: "free-trial",
};
export const ROLE_LEVEL = {
  FULL_ACCESS: "full-access",
  FREE_ACCESS: "free-access",
  FREE_TRIAL: "free-trial",
  ADMIN: "admin",
};
export const ROLE_NAME = {
  FULL_ACCESS: "full-access",
  FREE_ACCESS: "free-access",
  FREE_TRIAL: "free-trial",
  ADMIN: "admin",
  ADMINISTRATOR: "administrator",
  ADMINISTRATOR_R: "Administrator",
  CLUE_CREATOR: "clue-creator",
  CLUE_CREATOR_R: "Clue Creator",
  CLUE_PUBLISHER: "clue-publisher",
  CLUE_PUBLISHER_R: "Clue Publisher",
  CONTENT_REVIEWER: "content-reviewer",
  CONTENT_REVIEWER_R: "Content Reviewer",
  CLUE_MANAGER: "clue-manager",
  CLUE_MANAGER_R: "Clue Manager",
};
export const ADMIN_ACCESS_ROLES = [
  ROLE_NAME.ADMIN,
  ROLE_NAME.ADMINISTRATOR,
  ROLE_NAME.ADMINISTRATOR_R,
];
export const FULL_ACCESS_ROLES = [
  ROLE_NAME.FULL_ACCESS,
  ROLE_NAME.CLUE_CREATOR,
  ROLE_NAME.CLUE_CREATOR_R,
  ROLE_NAME.CLUE_PUBLISHER,
  ROLE_NAME.CLUE_PUBLISHER_R,
  ROLE_NAME.CONTENT_REVIEWER,
  ROLE_NAME.CONTENT_REVIEWER_R,
  ROLE_NAME.CLUE_MANAGER,
  ROLE_NAME.CLUE_MANAGER_R,
];

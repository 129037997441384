import cx from "classnames";
import React from "react";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Typography: React.FC<Props> = ({
  variant = "p24",
  fontWeight = "regular",
  children,
  className = "",
  fontFamily = "inter",
  fontStyle = "regular",
  ...rest
}) => {
  const getTypography = () => {
    return (
      <p
        className={cx(
          classes[variant],
          classes[fontWeight],
          classes[fontFamily],
          classes[fontStyle],
          {
            [className]: className,
          }
        )}
        {...rest}
      >
        {children}
      </p>
    );
  };
  return getTypography();
};

export default Typography;

import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import Lamp from "src/assets/icons/wish-lamp-icon.svg";
import Typography from "src/components/Typography";
import Clock from "src/pages/Subscriptions/assets/clock.svg";
import Map from "src/pages/Subscriptions/assets/map.svg";
import { useReducerData } from "src/store/hooks";
import { useSeason, useSeasonCountdown } from "src/utils/helper";
import { getNumberLampCount } from "src/utils/region";
import classes from "./styles.module.scss";

const HeaderDetails = () => {
  const { data: savedRegions } = useReducerData("region", "savedRegionList", {
    data: [],
  });
  const { data: regionList } = useReducerData("region", "regionList", {
    data: [],
  });

  const { loading: detailsLoading }: { loading: boolean } = useReducerData(
    "auth",
    "subscriptionDetails",
    {
      loading: false,
    }
  );
  const { currentSeason } = useSeason();
  const { duration } = useSeasonCountdown();

  const [lampsInSelectedRegions, setLampsInSelectedRegions] = useState(0);
  const {
    days = 0,
    hours = 0,
    minutes = 0,
  } = duration || {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  useEffect(() => {
    if (savedRegions.length) {
      const lamps = getNumberLampCount(
        savedRegions,
        currentSeason?.id ?? 1,
        regionList
      );
      setLampsInSelectedRegions(lamps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRegions]);

  return (
    <div className={classes.details}>
      {detailsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={classes.detail}>
            <div className={classes.imageWrapper}>
              <img src={Map} alt="map" />
            </div>
            <div>
              <Typography
                className="mb-1"
                variant="p16"
                fontWeight="medium"
                fontFamily="inter"
              >
                Playing in:
              </Typography>
              <Typography
                className="mb-0"
                variant="p16"
                fontWeight="semibold"
                fontFamily="inter"
              >
                {savedRegions.length} Regions
              </Typography>
            </div>
          </div>
          <div className={classes.detail}>
            <div className={classes.imageWrapper}>
              <img src={Lamp} alt="lamp" />
            </div>
            <div>
              <Typography
                className="mb-1"
                variant="p16"
                fontWeight="medium"
                fontFamily="inter"
              >
                In selected regions:
              </Typography>
              <Typography
                className="mb-0"
                variant="p16"
                fontWeight="semibold"
                fontFamily="inter"
              >
                {lampsInSelectedRegions}
              </Typography>
            </div>
          </div>
          <div className={classes.detail}>
            <div className={classes.imageWrapper}>
              <img src={Clock} alt="clock" />
            </div>
            <div>
              <Typography
                className="mb-1"
                variant="p16"
                fontWeight="medium"
                fontFamily="inter"
              >
                Time left in season {currentSeason?.id || 1}:
              </Typography>
              <Typography
                className="mb-0"
                variant="p16"
                fontWeight="semibold"
                fontFamily="inter"
              >
                {days} Days • {hours} Hours • {minutes} Minutes
              </Typography>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HeaderDetails;

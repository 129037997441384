import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import {
  TREASURE_GAME_USERNAME,
  UserDetailTab,
} from "src/pages/Watch/constants";
import * as mediaActions from "src/store/actions/media";
import * as watchActions from "src/store/actions/watch";
import { useStoreActions } from "src/store/hooks";
import { CommonGetPayload } from "src/types";

const useActions = () => {
  return useStoreActions({
    ...mediaActions,
    ...watchActions,
  });
};

const useUserDetailsInit = () => {
  const [searchParams] = useSearchParams();
  const currentTab = String(searchParams.get("current-tab") || "");
  const params = useParams();
  const { pathname } = useLocation();
  const currentPath = useCurrentPath();
  const userId = Number(params.userId || 0);
  const username = String(params.username || "");
  const tComparePathName = currentPath || pathname;
  const isUserDetailsPage = tComparePathName?.includes(":id/:name/:userId");
  const isUserDetailsPage1 = currentPath === "/watch/user-profile/:username";
  const { getWatchUser, getWatchUserFeed, getWatchUserRepostFeed } =
    useActions();
  const userProfile = useUserProfile();
  const currentUserId = userProfile.user?.id;
  const loadUserFeed = (
    nextCursor?: string,
    cb?: any,
    tUserId?: number | string,
    search?: string
  ) => {
    const tFilters: CommonGetPayload = {};
    if (nextCursor) {
      tFilters.cursor = nextCursor;
      tFilters.isMore = true;
    }
    if (search) {
      tFilters.q = search;
    }
    if (tUserId === TREASURE_GAME_USERNAME) {
      tFilters.channel = "treasure-games";
    }
    getWatchUserFeed(tUserId || currentUserId, tFilters, cb);
  };
  const loadUserRepost = (
    nextCursor?: string,
    cb?: any,
    tUserId?: number | string,
    search?: string
  ) => {
    const tFilters: CommonGetPayload = {};
    if (nextCursor) {
      tFilters.cursor = nextCursor;
      tFilters.isMore = true;
    }
    if (search) {
      tFilters.q = search;
    }
    if (tUserId === TREASURE_GAME_USERNAME) {
      tFilters.channel = "treasure-games";
    }
    getWatchUserRepostFeed(tUserId || currentUserId, tFilters, cb);
  };

  useEffect(() => {
    if ((isUserDetailsPage && userId) || (isUserDetailsPage1 && username)) {
      getWatchUser(isUserDetailsPage1 ? username : userId);
      if (currentTab && currentTab === UserDetailTab.reposts) {
        loadUserRepost("", null, isUserDetailsPage1 ? username : userId);
      } else {
        loadUserFeed("", null, isUserDetailsPage1 ? username : userId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, username]);

  return { loadUserFeed, loadUserRepost };
};

export default useUserDetailsInit;

import { Dispatch } from "redux";
import {
  apiGetDashboardEntity,
  apiGetNewCluesAvailable,
} from "src/api/dashboard";
import Types from "../types/dashboard";

export const getDashboardWatch = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_DASHBOARD_WATCH,
    asyncCall: () => {
      return apiGetDashboardEntity("watch");
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getDashboardPrizes = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_DASHBOARD_PRIZES,
    asyncCall: () => {
      return apiGetDashboardEntity("prizes");
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getDashboardAnnouncements = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_DASHBOARD_ANNOUNCEMENTS,
    asyncCall: () => {
      return apiGetDashboardEntity("announcements");
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getDashboardEngagements = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_DASHBOARD_ENGAGEMENTS,
    asyncCall: () => {
      return apiGetDashboardEntity("engagements");
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getNewCluesAvailable = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_NEW_CLUES_AVAILABLE,
    asyncCall: () => {
      return apiGetNewCluesAvailable();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

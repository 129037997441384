import IMG_2981 from "src/assets/images/watch/IMG_2981.png";
import IMG_2982 from "src/assets/images/watch/IMG_2982.png";
import IMG_2983 from "src/assets/images/watch/IMG_2983.png";
import IMG_2984 from "src/assets/images/watch/IMG_2984.png";
import IMG_2987 from "src/assets/images/watch/IMG_2987.png";
import { WATCH_SUB_SLUG_OBJ } from "src/helpers/constants";
import { ChannelConfig } from "src/types/channel";

const WATCH_MY_CHANNEL_URL = `/watch/${WATCH_SUB_SLUG_OBJ.MY_CHANNEL}`;
const WATCH_TREASURE_GAME_URL = `/watch/${WATCH_SUB_SLUG_OBJ.TREASURE_GAME}`;
export const TREASURE_GAME_USERNAME = "TreasureGame$";
export const WATCH_TREASURE_GAME_URL1 = `/watch/user-profile/${TREASURE_GAME_USERNAME}`;
const WATCH_TRENDING_URL = `/watch/${WATCH_SUB_SLUG_OBJ.TRENDING}`;
const WATCH_FOLLOWING_URL = `/watch/${WATCH_SUB_SLUG_OBJ.FOLLOWING}`;
const WATCH_REGIONS_URL = `/watch/${WATCH_SUB_SLUG_OBJ.REGIONS}`;
const WATCH_WINNERS_URL = `/watch/${WATCH_SUB_SLUG_OBJ.WINNERS}`;

export const PAGE_TITLE: Record<string, string> = {
  "/watch": "Watch",
  [WATCH_MY_CHANNEL_URL]: "My Channel",
  [WATCH_TREASURE_GAME_URL]: "Treasure Game$ Channel",
  [WATCH_TRENDING_URL]: "Trending Channel",
  [WATCH_FOLLOWING_URL]: "Following Channel",
  [WATCH_REGIONS_URL]: "Regions Channel",
  [WATCH_WINNERS_URL]: "Winners Channel",
};
export const PAGE_IMAGES: Record<string, string[]> = {
  [WATCH_MY_CHANNEL_URL]: [IMG_2983, IMG_2982, IMG_2981, IMG_2984, IMG_2987],
  [WATCH_TREASURE_GAME_URL]: [IMG_2981, IMG_2982, IMG_2983, IMG_2984, IMG_2987],
  [WATCH_TRENDING_URL]: [IMG_2982, IMG_2981, IMG_2983, IMG_2984, IMG_2987],
  [WATCH_FOLLOWING_URL]: [IMG_2983, IMG_2982, IMG_2981, IMG_2984, IMG_2987],
  [WATCH_REGIONS_URL]: [IMG_2984, IMG_2982, IMG_2983, IMG_2981, IMG_2987],
  [WATCH_WINNERS_URL]: [IMG_2987, IMG_2982, IMG_2983, IMG_2984, IMG_2981],
};
export const CHANNEL_URL_MAPPING: Record<string, string> = {
  [WATCH_SUB_SLUG_OBJ.TREASURE_GAME]: WATCH_TREASURE_GAME_URL,
  [WATCH_SUB_SLUG_OBJ.TREASURE_GAME1]: WATCH_TREASURE_GAME_URL1,
  [WATCH_SUB_SLUG_OBJ.TRENDING]: WATCH_TRENDING_URL,
  [WATCH_SUB_SLUG_OBJ.FOLLOWING]: WATCH_FOLLOWING_URL,
  [WATCH_SUB_SLUG_OBJ.REGIONS]: WATCH_REGIONS_URL,
  [WATCH_SUB_SLUG_OBJ.WINNERS]: WATCH_WINNERS_URL,
  [WATCH_SUB_SLUG_OBJ.WINNERS_OLD]: WATCH_WINNERS_URL,
};
export const CHANNEL_CONFIG: Record<string, ChannelConfig> = {
  [WATCH_SUB_SLUG_OBJ.MY_CHANNEL]: {
    multipleSections: false,
    viewLink: "",
  },
  [WATCH_SUB_SLUG_OBJ.TREASURE_GAME]: {
    multipleSections: false,
    viewLink: "",
  },
  [WATCH_SUB_SLUG_OBJ.TRENDING]: {
    multipleSections: false,
    viewLink: "",
  },
  [WATCH_SUB_SLUG_OBJ.FOLLOWING]: {
    multipleSections: false,
    viewLink: "",
  },
  [WATCH_SUB_SLUG_OBJ.REGIONS]: {
    multipleSections: true,
    viewLink: `/watch/regions/:subChannelSlug/:subChannelTitle`,
  },
  [WATCH_SUB_SLUG_OBJ.WINNERS]: {
    multipleSections: false,
    viewLink: "",
  },
};
export const MANAGE_CONTENT_SORT_NAME_MAPPING: Record<string, string> = {
  status: "status",
  created_at: "date",
  region: "region",
  views_count: "views",
  likes_count: "loves",
  share_count: "shares",
};
export enum UserDetailTab {
  posts = "posts",
  reposts = "reposts",
}
export enum RepostAction {
  add = "add",
  remove = "remove",
}

import cn from "classnames";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import LeftArrow from "src/assets/icons/left-arrow-icon.svg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { PRIZE_SUB_TITLE, WATCH_SUB_TITLE } from "src/helpers/constants";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import { setClueData } from "src/store/actions/clue";
import { useStoreActions } from "src/store/hooks";
import { CommonSize } from "src/types";
import { PARSE_PATH_NAME, PARSE_USERNAME } from "./constants";
import classes from "./styles.module.scss";

interface Props {
  isRoot?: boolean;
  noMargin?: boolean;
  buttonText?: string;
  gapSize?: CommonSize;
  onBack?: () => void;
  isUseCurrentPath?: boolean;
}

const BreadCrumbs = (props: Props) => {
  const {
    isRoot,
    noMargin,
    buttonText = "",
    gapSize,
    onBack,
    isUseCurrentPath,
  } = props;
  const { pathname } = useLocation();
  const matchParams = useParams();

  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const actions = useStoreActions({ setClueData });
  const subTitle = {
    ...WATCH_SUB_TITLE,
    ...PRIZE_SUB_TITLE,
  };

  const BACK_COUNT: Record<string, number> = {
    "playing-media": 1,
    ":name": 1,
    ":subChannelSlug": 1,
    ":subChannelTitle": 0,
    watch: 0,
    engagement: 0,
    announcements: 0,
    prizes: 0,
  };

  const getIndividualPath = (adjacentPath: string) => {
    const lastPathIndex = BACK_COUNT[adjacentPath] ?? 0;
    const currentPathArr = (
      isUseCurrentPath && !!currentPath ? currentPath : pathname
    ).split("/");
    const indexOfPath =
      currentPathArr.slice(0, currentPathArr.length - 1).indexOf(adjacentPath) +
      1;
    const pathArr = pathname.split("/").slice(1, indexOfPath + lastPathIndex);
    return "/" + pathArr.join("/");
  };

  const getBreadCrumb = () => {
    const breadcrumbElements = (
      isUseCurrentPath && !!currentPath ? currentPath : pathname
    )
      .split("/")
      .map((path) => ({ path: path.split("-").join(" "), adjacentPath: path }))
      .filter(
        (el) =>
          el &&
          ![
            ":id",
            ":userId",
            ":postId",
            ":subChannelSlug",
            "user profile",
          ].includes(el.path)
      )
      .map((el) => ({
        ...el,
        path: el.path.replace("dashboard", "my subscription"),
      }))
      .map((el) => {
        const updatedEl = el.path
          .replace(":name", matchParams.name as string)
          .replace(
            ":username",
            (PARSE_USERNAME[String(matchParams.username || "")] ||
              matchParams.username) as string
          )
          .replace(":subChannelTitle", matchParams.subChannelTitle as string)
          .replace(
            ":channelSlug",
            String(
              subTitle[String(matchParams.channelSlug)] ||
                matchParams.channelSlug
            )
          );
        return { ...el, path: updatedEl };
      });
    return breadcrumbElements;
  };

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.noMargin]: noMargin,
        },
        classes[`gap_size_${gapSize}`]
      )}
    >
      {!isRoot && (
        <div className={classes.buttonWrapper}>
          <Button
            buttonClassName={classes.back}
            onClick={() => {
              if (onBack) {
                onBack();
              } else {
                actions.setClueData(null);
                navigate("/subscriptions");
              }
            }}
            leftIcon={<img alt="left-arrow" src={LeftArrow} />}
            buttonColor="primary"
            buttonText={buttonText}
          />
        </div>
      )}
      <div className={classes.breadcrumb}>
        {getBreadCrumb().map(({ path, adjacentPath }, index) => {
          return (
            <Typography
              className="mb-0"
              role="button"
              key={index}
              onClick={() => {
                if (getBreadCrumb().length - 1 === index) {
                  return;
                }
                const prevPath = getIndividualPath(adjacentPath);
                navigate(`${prevPath}`);
              }}
              fontFamily="inter"
              variant="p16"
              fontWeight={
                getBreadCrumb().length === index + 1 ? "bold" : "regular"
              }
            >
              {PARSE_PATH_NAME[path] || path}{" "}
              {index + 1 < getBreadCrumb().length && !!path && "/"}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default BreadCrumbs;

import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { FormGroup } from "reactstrap";
import Input from "src/components/Input";
import Text from "src/components/Text";
import { ClueProps } from "src/pages/Play/Clue/types";
import { interactiveWordPuzzleUpdate } from "src/store/actions/clue";

import { useReducerData, useStoreActions } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./styles.module.scss";

type InteractiveWordPuzzleQuestionProps = {
  clue: ClueProps;
  isModal?: boolean;
};

const InteractiveWordPuzzleQuestion: React.FC<
  InteractiveWordPuzzleQuestionProps
> = ({ isModal = false, clue }) => {
  const actions = useStoreActions({
    interactiveWordPuzzleUpdate,
  });
  const { error } = useReducerData("clue", "interactiveWordPuzzleState", {
    answer: "",
    error: "",
  });

  const isCompleted = clue?.answers?.some(
    (ans) => ans?.answer?.is_correct === 1
  );

  const findCorrectAnswer = clue?.answers?.find(
    (ans) => ans?.answer?.is_correct === 1
  );

  const WORD = clue?.custom_text?.visible_text || "***e*e***";

  const CORRECT_WORD = isCompleted ? findCorrectAnswer?.answer?.answer : "";

  const inputsArray = WORD?.split("").map((char) =>
    char === "*" ? "" : char.toUpperCase()
  );

  const { width = 0 } = useWindowSize();

  const [inputs, setInputs] = useState<string[]>([]);
  const BOX_WIDTH = 70;

  const [continousIndex, setContinuousIndex] = useState<number[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const inputRefs: React.RefObject<HTMLInputElement>[] = [];

  useEffect(() => {
    setInputs(inputsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WORD]);

  useEffect(() => {
    const answer = inputs?.join("") || "";
    actions.interactiveWordPuzzleUpdate(answer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  useEffect(() => {
    if (containerRef?.current?.clientWidth) {
      const wrapperWidth = containerRef.current.clientWidth - 4;
      const indexToAddDash: number[] = [];

      const inputsLength =
        inputs?.map((d) => (d ? BOX_WIDTH : BOX_WIDTH)) || [];
      let count = 1;
      const columns = Math.floor(wrapperWidth / BOX_WIDTH);
      const rows = Math.ceil(inputsLength.length / columns);
      const rowsWithDash = Math.ceil(
        (inputsLength.length + rows - 1) / columns
      );

      inputsLength.forEach((boxWidth, index) => {
        if (
          index % columns === 0 &&
          indexToAddDash.length + 1 !== rowsWithDash
        ) {
          indexToAddDash.push((columns - 1) * count - 1);
          count++;
        }
      });
      setContinuousIndex(indexToAddDash);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <div
      className={cx(classes.wrapper, {
        [classes.modalQuestionWrapper]: isModal,
      })}
    >
      <div className={classes.inputsWrapper} ref={containerRef}>
        <div className={classes.inputWrapper}>
          {inputs?.map((val, index) => {
            inputRefs[index] = inputRefs[index] || React.createRef();

            return (
              <React.Fragment key={index}>
                <FormGroup key={index} className={classes.formGroup}>
                  <Input
                    labelClassName={classes.label}
                    inputGroupClassName={cx(classes.inputGroup, {
                      [classes.error]: error,
                    })}
                    inputClassName={cx(classes.input, {
                      [classes.preFilled]: WORD?.[index] !== "*",
                    })}
                    value={isCompleted ? CORRECT_WORD?.[index] : val}
                    innerRef={inputRefs[index]}
                    readOnly={WORD?.[index] !== "*" || isCompleted}
                    maxLength={1}
                    placeholder=""
                    onChange={(e) => {
                      const inputsClone = [...inputs];
                      inputsClone[index] = e?.target?.value?.toUpperCase();
                      setInputs(inputsClone);
                      if (e.target.value && index < inputRefs.length - 1) {
                        let indexToSkip = 1; // Start with the next index

                        // Find the next non-prefilled input field
                        while (
                          index + indexToSkip < inputRefs.length &&
                          WORD?.[index + indexToSkip] !== "*"
                        ) {
                          indexToSkip++;
                        }
                        inputRefs[index + indexToSkip]?.current?.focus();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (
                        e.key === "Backspace" &&
                        index > 0 &&
                        WORD?.[index] === "*"
                      ) {
                        // Clear the current field
                        const inputsClone = [...inputs];
                        inputsClone[index] = "";
                        setInputs(inputsClone);
                        let indexToSkip = 1; // Start with the next index

                        // Find the previous non-prefilled input field
                        while (
                          index - indexToSkip >= 0 &&
                          WORD[index - indexToSkip] !== "*"
                        ) {
                          indexToSkip++;
                        }

                        // Focus on the previous input field
                        inputRefs[index - indexToSkip]?.current?.focus();
                      } else if (e.key === "Backspace" && index > 0) {
                        inputRefs[index - 1]?.current?.focus();
                      }
                    }}
                  />
                </FormGroup>
                {continousIndex.includes(index) && (
                  <div className={classes.dash}>
                    <Text size={36}>-</Text>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
        {error && (
          <Text
            className={classes.errorText}
            color="#f00"
            size={14}
            fontWeight="semibold"
          >
            {error}
          </Text>
        )}
      </div>
    </div>
  );
};

export default InteractiveWordPuzzleQuestion;

import { useReducerData } from "src/store/hooks";

export const useIndexData = () => {
  return {
    dashboardPrizes: useReducerData("dashboard", "dashboardPrizes.data", {}),
    dashboardPrizesLoading: useReducerData(
      "dashboard",
      "dashboardPrizes.loading",
      false
    ),
  };
};

import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Text from "src/components/Text";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { postPaymentDetails } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import classes from "./SelectAccess.module.scss";

const ACCESS_LEVELS = [
  {
    type: "free_access",
    title: "Free Access Level",
    list: [
      "No Cost",
      "TG$ News & Entertainment",
      "TG$ Channel Highlights",
      "User Generated Highlights",
      "Winner Highlights",
      "Games & Prizes Information",
      "TG$ Impact Investment Program",
    ],
    testId: "free_access_selector",
  },
  {
    type: "full_access",
    title: "Full Access Level",
    list: [
      "Subscription Plan Per Game",
      "Full Game Play Access",
      "Full Entertainment Content Access",
      "TG$ News & Entertainment",
      "TG$ Channel Highlights",
      "User Generated Highlights",
      "Winner Highlights",
      "Games & Prizes Information",
      "Weekly Prizes Access",
      "Grand Prize Access",
      "TG$ Impact Investment Program",
    ],
    testId: "full_access_selector",
  },
];

const SelectAccess = () => {
  const navigate = useNavigate();
  const actions = useStoreActions({
    postPaymentDetails,
  });
  const { loading } = useReducerData("auth", "payment", {});
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.access_selection_signup);
  }, []);

  const handleNext = async () => {
    if (selectedOption === "free_access") {
      const paymentDetail = {
        payment_id: null,
        plan_id: null,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { status } = await actions.postPaymentDetails(paymentDetail);
      if (status) {
        navigate("/subscription/completed");
      }
    } else {
      navigate("/subscription/plan");
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.cardWrapper}>
        {ACCESS_LEVELS.map((access, index) => {
          return (
            <div
              onClick={() => {
                setSelectedOption(access.type);
              }}
              className={cx(classes.cardContainer, {
                [classes.selected]: selectedOption === access.type,
              })}
              key={index}
              data-testid={access.testId}
            >
              <div className={cx(classes.select)}>
                {selectedOption === access.type && (
                  <span className={classes.active}></span>
                )}
              </div>
              <div
                className={cx(classes.card, {
                  [classes.selected]: selectedOption === access.type,
                })}
              >
                <Text
                  size={18}
                  fontFamily="impact"
                  color="#DEB771"
                  className={classes.title}
                >
                  {access.title}
                </Text>
                <ul className={classes.list}>
                  {access.list.map((info, index) => {
                    return (
                      <li key={index}>
                        <Text color="#ffffff" size={16} fontFamily="inter">
                          {info}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>

      <div className={classes.buttonWrapper}>
        <Button
          loading={loading}
          buttonText="Next"
          buttonColor="secondary"
          type="submit"
          onClick={handleNext}
          disabled={!selectedOption}
          buttonClassName={classes.button}
        />
      </div>
    </div>
  );
};

export default SelectAccess;

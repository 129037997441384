import cn from "classnames";
import { Button as BaseButton } from "reactstrap";
import classes from "./HotColdBarometerModal.module.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const AvailableButton = ({ className = "", children }: Props) => {
  return (
    <BaseButton
      className={cn(classes.availableButton, className)}
      onClick={() => {}}
      disabled
      type="button"
    >
      {children}
    </BaseButton>
  );
};

export default AvailableButton;

import cn from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { ReactComponent as BellIcon } from "src/assets/icons/bell-icon.svg";
import { ReactComponent as NotificationListCheckIcon } from "src/assets/icons/notification/list-check-icon.svg";
import { ReactComponent as NotificationSearchIcon } from "src/assets/icons/notification/search-icon.svg";
import { ReactComponent as NotificationSpeakerphoneIcon } from "src/assets/icons/notification/speakerphone-icon.svg";
import { ReactComponent as NotificationTreasureIcon } from "src/assets/icons/notification/treasure-icon.svg";
import { ENotificationType, NotificationData } from "src/types/notification";
import { actionCb } from "src/utils/action";
import { getUploadedPostDate } from "src/utils/date";
import classes from "./Notification.module.scss";
import { useActions, useIndexData } from "./selectorData";

interface Props {
  className?: string;
}

const NotificationLogo = {
  [ENotificationType.NewCluesAvailable]: <NotificationSearchIcon />,
  [ENotificationType.NewAnnouncement]: <NotificationSpeakerphoneIcon />,
  [ENotificationType.TreasureFound]: <NotificationTreasureIcon />,
  [ENotificationType.EngagementUpdates]: <NotificationListCheckIcon />,
};
const NotificationUrl = {
  [ENotificationType.NewCluesAvailable]: "/play",
  [ENotificationType.NewAnnouncement]: "/announcements",
  [ENotificationType.TreasureFound]: "/play",
  [ENotificationType.EngagementUpdates]: "/engagement",
};

let viewTimeout: any = null;

const Notification = ({ className }: Props) => {
  const navigate = useNavigate();
  const { userNotificationCount, userNotifications } = useIndexData();
  const {
    viewUserNotification,
    openUserNotification,
    getUserNotifications,
    getUserNotificationCount,
    syncSetUserNotificationCount,
    syncSetViewNotification,
    clearUserNotification,
  } = useActions();
  const notificationUnreadIds = userNotifications
    .filter((n: NotificationData) => !n.view)
    .map((n: NotificationData) => n._id);
  const notificationIds = userNotifications.map((n: NotificationData) => n._id);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      getUserNotificationCount();
      getUserNotifications();
      if (!viewTimeout && notificationUnreadIds.length > 0) {
        viewTimeout = setTimeout(() => {
          viewUserNotification(
            notificationUnreadIds,
            actionCb(() => {
              syncSetUserNotificationCount(0);
            })
          );
          resetTimeout();
        }, 5000);
      }
    }
  };
  const resetTimeout = () => {
    clearTimeout(viewTimeout);
    viewTimeout = null;
  };

  useEffect(() => {
    resetTimeout();
    return () => {
      resetTimeout();
    };
  }, []);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Dropdown
        className={classes.dropdown}
        persist={true}
        active
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle className={classes.bellButton}>
          <BellIcon />
          {userNotificationCount > 0 && (
            <Badge pill>{userNotificationCount}</Badge>
          )}
        </DropdownToggle>
        <DropdownMenu className={classes.menu} end>
          <div className={classes.menuHeader}>
            <span>Notifications</span>
            <span
              className={cn({
                [classes.disabled]: !notificationIds.length,
              })}
              onClick={() => {
                if (notificationUnreadIds.length > 0) {
                  viewUserNotification(
                    notificationUnreadIds,
                    actionCb(() => {
                      syncSetUserNotificationCount(0);
                    })
                  );
                  resetTimeout();
                } else {
                  clearUserNotification(notificationIds);
                  resetTimeout();
                }
              }}
            >
              {notificationUnreadIds.length
                ? "Mark as Read"
                : "Clear Notifications"}
            </span>
          </div>
          {userNotifications.map((notification: NotificationData) => {
            return (
              <DropdownItem
                className={classes.item}
                key={notification._id}
                onClick={() => {
                  if (!notification.view) {
                    syncSetViewNotification([notification._id]);
                    syncSetUserNotificationCount(userNotificationCount - 1);
                  }
                  openUserNotification(
                    [notification._id],
                    actionCb(() => {
                      getUserNotifications();
                    })
                  );
                  navigate(NotificationUrl[notification.type]);
                }}
              >
                <div className={classes.itemHeader}>
                  <span className={classes.itemIcon}>
                    {NotificationLogo[notification.type]}
                    {!notification.view && (
                      <span className={classes.red}></span>
                    )}
                  </span>
                  <span className={classes.itemTitle}>
                    {notification.title}
                  </span>
                  <span>{getUploadedPostDate(notification.created_at)}</span>
                </div>
                <div className={classes.itemDes}>{notification.body}</div>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default Notification;

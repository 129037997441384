import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGoogleLogin } from "@react-oauth/google";
import cx from "classnames";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useNavigate } from "react-router";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Input from "src/components/Input";
import {
  addSignupDetails,
  postUserExistQuery,
  setSsoData,
} from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { TGS_BASE_URL } from "src/utils/constants";
import { useWindowSize } from "src/utils/useWindowSize";
import { facebookAppId } from "../SignIn";
import classes from "./styles.module.scss";

interface SignupInputProps {
  email?: string;
}

const NewSignUp = () => {
  const { width = 0 } = useWindowSize();
  const isMobile = width > 0 && width <= 768;
  const [searchParams] = useSearchParams();
  // const isFreeTrial = searchParams.get("free_trial") === "true";
  const [emailExistError, setEmailExistError] = useState("");
  const navigate = useNavigate();
  const signupDetail = useReducerData("auth", "signupDetails", {});
  const actions = useStoreActions({
    addSignupDetails,
    postUserExistQuery,
    setSsoData,
  });
  const { loading } = useReducerData("auth", "userExistQuery", {
    loading: false,
  });

  const signUpEmailSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Must be valid email addresses"),
  });

  const handleSubmit = async (
    values: SignupInputProps,
    formikHelpers: FormikHelpers<SignupInputProps>
  ) => {
    const emailCheck = { field: "email", value: values.email as string };

    const onEmailCheckSuccess = (res: any) => {
      if (!res?.status) {
        setEmailExistError(res.message);
        return;
      }
      actions.setSsoData({}); // clear sso data on email sign request
      actions.addSignupDetails({
        ...signupDetail,
        email: values.email,
        email_confirmation: values.email,
      });
      navigate({
        pathname: `/auth/create-password`,
        search: `?${createSearchParams(searchParams)}`,
      });
      formikHelpers.resetForm();
    };

    actions.postUserExistQuery(emailCheck, onEmailCheckSuccess);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const access_token = codeResponse.access_token;
      if (access_token) {
        actions.setSsoData({ access_token, sso_type: "google" });
        navigate({
          pathname: `/auth/create-username`,
          search: `?${createSearchParams(searchParams)}`,
        });
      }
    },
  });

  const handleFacebookLogin = (response: any) => {
    const { accessToken } = response;
    if (accessToken) {
      actions.setSsoData({ access_token: accessToken, sso_type: "facebook" });
      navigate({
        pathname: `/auth/create-username`,
        search: `?${createSearchParams(searchParams)}`,
      });
    }
  };

  return (
    <>
      <div className={classes.signInOption}>
        <div
          className={classes.optionWrapper}
          onClick={() => handleGoogleLogin()}
        >
          <FontAwesomeIcon icon={faGoogle} color="#fff" />
        </div>
        <FacebookLogin
          // redirectUri={redirectURI}
          fields="email"
          scope="public_profile,email"
          appId={facebookAppId}
          callback={handleFacebookLogin}
          icon={<FontAwesomeIcon icon={faFacebookF} />}
          render={(renderProps) => (
            <div className={classes.optionWrapper}>
              <FontAwesomeIcon
                icon={faFacebookF}
                color="#fff"
                onClick={renderProps.onClick}
              />
            </div>
          )}
        />
      </div>
      <div className={classes.divider}>
        <div className={classes.wordWithLine}>OR</div>
      </div>
      <div className={classes.lowerContainer}>
        <Formik
          onSubmit={(values, formikHelpers) =>
            handleSubmit(values, formikHelpers)
          }
          validateOnMount
          validationSchema={signUpEmailSchema}
          initialValues={{
            email: signupDetail?.email || "",
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
          }: FormikProps<SignupInputProps>) => {
            return (
              <Form className={cx(classes.signupInputs)}>
                <div>
                  <FormGroup className={classes.formGroup}>
                    <Input
                      labelClassName={classes.label}
                      label="Email Address"
                      required={true}
                      inputGroupClassName={classes.inputWrapper}
                      inputClassName={classes.input}
                      value={values.email}
                      placeholder="example@email.com"
                      onChange={(e) => {
                        handleChange(e);
                        setEmailExistError("");
                      }}
                      onBlur={handleBlur}
                      error={errors.email || emailExistError}
                      touched={touched.email}
                      name="email"
                    />
                  </FormGroup>
                </div>
                <div
                  className={cx(classes.buttonContainer, {
                    [classes.error]: errors.email,
                  })}
                >
                  <Button
                    buttonText="Sign up with email"
                    buttonColor="purple-gradient"
                    loading={loading}
                    type="submit"
                    disabled={!isValid}
                  />
                </div>
                <div className={classes.mobileNext}>
                  <Button
                    buttonText="Sign up with email"
                    buttonColor="purple-gradient"
                    disabled={!isValid}
                    loading={loading}
                    type="submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
        <div className={classes.terms}>
          By clicking the button {isMobile ? "below" : "above"}, you agree to
          our{" "}
          <span>
            <Link target="_blank" to={`${TGS_BASE_URL}/a/terms-and-conditions`}>
              Terms of Use{" "}
            </Link>
          </span>
          and{" "}
          <span>
            <Link
              target="_blank"
              to={`${TGS_BASE_URL}/a/privacy-policy-ip-policy`}
            >
              {" "}
              Privacy Policy
            </Link>
          </span>
          .
        </div>
        <div className={classes.tAndC}>
          Already have an account?
          <span
            onClick={() => {
              navigate({
                pathname: `/auth/login`,
                search: `?${createSearchParams(searchParams)}`,
              });
            }}
          >
            Sign in
          </span>
        </div>
      </div>
    </>
  );
};

export default NewSignUp;

import cn from "classnames";
import React from "react";
import classes from "./RegionLabel.module.scss";

interface Props {
  className?: string;
  label: string;
}

const COLOR_MAPPING: Record<string, string> = {
  "Region 1": "#E5C85F",
  "Region 2": "#6C69F8",
  "Region 3": "#8053EA",
  "Region 4": "#E6C85F",
  "Region 5": "#E9A260",
  "Region 6": "#8053EA",
  "Region 7": "#E9A260",
  "Region 8": "#E6C85F",
  "Region 9": "#6C69F8",
  "Region 10": "#8053EA",
};

const RegionLabel = ({ className, label }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span style={{ background: COLOR_MAPPING[label] }}>{label}</span>
    </div>
  );
};
export default RegionLabel;

import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import { useState } from "react";
import { ClueProps } from "src/pages/Play/Clue/types";
import ClueDetailHeader from "../ClueDetailHeader";
import ClueHeader from "../ClueHeader";
import classes from "./ClueGame.module.scss";

interface Props {
  children: React.ReactNode;
  clue: ClueProps | null;
  gameDescription: string;
  maxScore: number;
  gameName: string;
  answer?: string;
  hideHeader?: boolean;
  hideModal?: boolean;
  disabled?: boolean;
}

const ClueGame = ({
  clue,
  children,
  gameDescription,
  maxScore,
  gameName,
  answer,
  hideHeader,
  hideModal,
  disabled,
}: Props) => {
  const [isModal, setIsModal] = useState(false);

  return (
    <div
      className={cn(classes.wrapper, {
        [classes.disabled]: disabled,
      })}
    >
      {!hideHeader && <ClueHeader clue={clue} />}
      <div
        className={cn(classes.inner, {
          [classes.isModal]: isModal,
        })}
      >
        <ClueDetailHeader
          description={gameDescription}
          maxScore={maxScore}
          onClose={
            isModal
              ? () => {
                  setIsModal(false);
                }
              : undefined
          }
          isWhite={isModal}
          gameName={gameName}
          answer={answer}
        />
        <div className={classes.gameWrapper}>
          {children}
          {!isModal && !hideModal && (
            <div
              onClick={() => {
                setIsModal(true);
              }}
              className={classes.arrow}
            >
              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClueGame;

import { createTypes } from "./createTypes";

export default createTypes(
  "SYNC_UPDATE_LOGIN",
  "USER_SIGNUP_STARTED",
  "USER_SIGNUP_SUCCESS",
  "USER_SIGNUP_FAILED",
  "FETCH_USER_PROFILE_STARTED",
  "FETCH_USER_PROFILE_SUCCESS",
  "FETCH_USER_PROFILE_FAILED",
  "UPDATE_USER_PROFILE_STARTED",
  "UPDATE_USER_PROFILE_SUCCESS",
  "UPDATE_USER_PROFILE_FAILED",
  "USER_PAYMENT_DETAILS_STARTED",
  "USER_PAYMENT_DETAILS_SUCCESS",
  "USER_PAYMENT_DETAILS_FAILED",
  "USER_LOGIN_STARTED",
  "USER_LOGIN_SUCCESS",
  "USER_LOGIN_FAILED",
  "USER_EMAIL_VERIFICATION_STARTED",
  "USER_EMAIL_VERIFICATION_SUCCESS",
  "USER_EMAIL_VERIFICATION_FAILED",
  "USER_RESET_PASSWORD_STARTED",
  "USER_RESET_PASSWORD_SUCCESS",
  "USER_RESET_PASSWORD_FAILED",
  "USER_FORGOT_PASSWORD_STARTED",
  "USER_FORGOT_PASSWORD_SUCCESS",
  "USER_FORGOT_PASSWORD_FAILED",
  "USER_LOGOUT_SUCCESS",
  "SET_USER_SIGNUP_DETAILS",
  "REMOVE_USER_SIGNUP_DETAILS",
  "GET_SUBSCRIPTION_PLANS_STARTED",
  "GET_SUBSCRIPTION_PLANS_SUCCESS",
  "GET_SUBSCRIPTION_PLANS_FAILED",
  "GET_PAYMENT_METHODS_STARTED",
  "GET_PAYMENT_METHODS_SUCCESS",
  "GET_PAYMENT_METHODS_FAILED",
  "ADD_PAYMENT_METHODS_STARTED",
  "ADD_PAYMENT_METHODS_SUCCESS",
  "ADD_PAYMENT_METHODS_FAILED",
  "SET_SELECTED_PLAN_DETAILS",
  "USER_EXIST_CHECK_STARTED",
  "USER_EXIST_CHECK__SUCCESS",
  "USER_EXIST_CHECK_FAILED",
  "IS_USER_SUBSCRIPTION_FLOW_FROM_SIGNUP",
  "FETCH_USER_SUBSCRIPTION_DETAILS_STARTED",
  "FETCH_USER_SUBSCRIPTION_DETAILS_SUCCESS",
  "FETCH_USER_SUBSCRIPTION_DETAILS_FAILED",
  "GET_SETTINGS",
  "USER_SETTINGS_UPDATE",
  "REMOVE_PAYMENT_METHOD",
  "UNSUBSCRIBE_PLAN",
  "FORGOT_PASSWORD",
  "RESET_PASSWORD",
  "DELETE_ACCOUNT",
  "SSO_SIGNUP",
  "SET_SSO_DATA",
  "GET_PAYMENT_INTENT",
  "VERIFY_PAYMENT",
  "SSO_LOGIN"
);

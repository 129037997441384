import { Reducer } from "redux";
import { handleData } from "../middlewares/handleData";
import Types from "../types/media";

const initialState = {
  allMedia: {
    loading: false,
    data: {},
    error: "",
  },
  searchMedia: {
    loading: false,
    data: {},
    error: "",
  },
  mediaDetail: {
    loading: false,
    data: {},
    error: "",
  },
  mediaRelated: {
    loading: false,
    data: [],
    error: "",
  },
  postStatus: {
    loading: false,
    data: {},
    error: "",
  },
  postDetails: {
    postId: "",
    loading: false,
    data: {},
    error: "",
  },
  reportMedia: {
    loading: false,
    data: {},
    error: "",
  },
  createPost: {
    loading: false,
    data: {},
    error: "",
  },
  uploadMediaPost: {
    loading: false,
    data: {},
    error: "",
  },
  updateMediaPost: {
    loading: false,
    data: {},
    error: "",
  },
  deletePost: {
    loading: false,
    data: {},
    error: "",
  },
  likeUnlikePost: {
    loading: false,
    data: {},
    error: "",
  },
  repostPost: {
    loading: false,
    data: {},
    error: "",
  },
};

const MediaReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.SYNC_RESET_POST_STATUS:
      return {
        ...state,
        postStatus: {
          loading: false,
          data: {},
          error: "",
        },
      };
    case Types.SYNC_SET_POST_STATUS:
      return {
        ...state,
        postStatus: {
          ...state.postStatus,
          data: {
            ...(state.postStatus?.data || {}),
            status: action.payload?.status,
          },
        },
      };
    case Types.SYNC_RESET_UPLOAD_MEDIA_POST:
      return {
        ...state,
        updateMediaPost: {
          loading: false,
          data: {},
          error: "",
        },
      };
    case Types.SYNC_SET_POST_DETAILS:
      return {
        ...state,
        postDetails: {
          ...state.postDetails,
          data: {
            ...state.postDetails.data,
            ...(payload || {}),
          },
        },
      };
    case Types.SYNC_SET_MEDIA_DETAIL:
      return {
        ...state,
        mediaDetail: {
          ...state.mediaDetail,
          data: {
            ...state.mediaDetail.data,
            ...(payload || {}),
          },
        },
      };
    case Types.GET_ALL_MEDIA:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          allMedia: {
            ...prevState.allMedia,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          allMedia: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          allMedia: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.SEARCH_MEDIA:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          searchMedia: {
            ...prevState.searchMedia,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          searchMedia: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          searchMedia: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_MEDIA_DETAIL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          mediaDetail: {
            loading: true,
            data: {},
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          mediaDetail: {
            loading: false,
            data: payload.data?.post || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          mediaDetail: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_MEDIA_RELATED:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          mediaRelated: {
            loading: true,
            data: [],
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          mediaRelated: {
            loading: false,
            data: payload.data?.items || [],
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          mediaRelated: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_POST_STATUS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          postStatus: {
            ...prevState.postStatus,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          postStatus: {
            loading: false,
            // TODO: harcode to update status
            data: {
              ...(payload.data || {}),
              // status: "verifying_failed",
            },
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          postStatus: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_POST_DETAILS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          postDetails: {
            data:
              meta?.payload?.postId === prevState.postDetails?.postId
                ? { ...(prevState.postDetails?.data || {}) }
                : {},
            error: "",
            loading: true,
            postId: meta?.payload?.postId,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          postDetails: {
            loading: false,
            data: payload.data?.post || {},
            error: "",
            postId: meta?.payload?.postId,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          postDetails: {
            loading: false,
            data: {},
            error: payload,
            postId: meta?.payload?.postId,
          },
        }),
      });
    case Types.REPORT_MEDIA:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          reportMedia: {
            loading: true,
            data: {},
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          reportMedia: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          reportMedia: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_POST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createPost: {
            loading: true,
            data: {},
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createPost: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createPost: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPLOAD_MEDIA_POST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          uploadMediaPost: {
            loading: true,
            data: {},
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          uploadMediaPost: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          uploadMediaPost: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_MEDIA_POST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateMediaPost: {
            loading: true,
            data: {},
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateMediaPost: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateMediaPost: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_POST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deletePost: {
            loading: true,
            data: {},
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deletePost: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deletePost: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.LIKE_UNLIKE_POST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          likeUnlikePost: {
            loading: true,
            data: {},
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          likeUnlikePost: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          likeUnlikePost: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.REPOST_POST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          repostPost: {
            loading: true,
            data: {},
            error: "",
          },
        }),
        success: (prevState) => ({
          ...prevState,
          repostPost: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          repostPost: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });

    default:
      return state;
  }
};

export default MediaReducer;

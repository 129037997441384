/* eslint-disable max-lines */
import { Reducer } from "redux";
import { handleData } from "../middlewares/handleData";
import Types from "../types/auth";
import UserTypes from "../types/user";

const initialState = {
  loading: false,
  isError: false,
  message: "",
  user: null,
  isLoggedIn: false,
  userProfile: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  editUserProfile: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  register: {
    loading: false,
    isError: false,
    message: "",
  },
  payment: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  signupDetails: {},
  ssoData: {},
  subscriptionPlans: {
    loading: false,
    isError: false,
    message: "",
    data: {
      plans: [],
    },
  },
  planDetails: {
    plan: {},
    subscriptionDetails: {},
  },
  userExistQuery: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  flow: {
    fromSignup: false,
  },
  subscriptionDetails: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  paymentMethods: {
    loading: false,
    isError: false,
    message: "",
    data: [],
  },
  addPaymentMethods: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  removePaymentMethod: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  userSettingsUpdate: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  userSettings: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  unsubscribePlan: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  forgotPassword: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  resetPassword: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  deleteAccount: {
    loading: false,
    message: "",
    data: {},
  },
  ssoSignUp: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  paymentIntent: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  verifyPayment: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
  ssoLogin: {
    loading: false,
    isError: false,
    message: "",
    data: {},
  },
};
const AuthReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_UPDATE_LOGIN:
      return {
        ...state,
        isLoggedIn: payload,
      };
    case Types.USER_SIGNUP_STARTED:
      return {
        ...state,
        register: {
          ...state.register,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_SIGNUP_SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          isError: false,
          message: "",
        },
        isLoggedIn: true,
        user: payload.data,
      };
    case Types.USER_SIGNUP_FAILED:
      return {
        ...state,
        register: {
          ...state.register,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.FETCH_USER_PROFILE_STARTED:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.FETCH_USER_PROFILE_FAILED:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.UPDATE_USER_PROFILE_STARTED:
      return {
        ...state,
        editUserProfile: {
          ...state.editUserProfile,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        editUserProfile: {
          ...state.editUserProfile,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        editUserProfile: {
          ...state.editUserProfile,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_PAYMENT_DETAILS_STARTED:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
        userProfile: {
          data: {
            ...state.userProfile.data,
            subscription: {
              ...(state?.userProfile?.data?.subscription ?? {}),
              ...(payload?.data?.subscription ?? {}),
            },
          },
        },
      };
    case UserTypes.CHANGE_SUBSCRIPTION:
      return {
        ...state,
        userProfile: {
          data: {
            ...state.userProfile.data,
            subscription: {
              ...(state?.userProfile?.data?.subscription ?? {}),
              ...(payload?.data?.subscription ?? {}),
            },
          },
        },
      };
    case Types.USER_PAYMENT_DETAILS_FAILED:
      return {
        ...state,
        payment: {
          ...state.payment,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_LOGIN_STARTED:
      return {
        ...state,
        loading: true,
        isError: false,
        isLoggedIn: false,
        message: "",
      };
    case Types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        message: "",
        isLoggedIn: true,
        user: payload.data.user,
        userProfile: {
          data: payload.data,
        },
      };
    case Types.USER_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        isLoggedIn: false,
        message: payload.message,
      };
    case Types.RESET_PASSWORD:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          resetPassword: {
            ...prevState.resetPassword,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          resetPassword: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
          user: payload.data,
          isLoggedIn: true,
        }),
        failure: (prevState) => ({
          ...prevState,
          resetPassword: { loading: false, data: {}, error: payload },
        }),
      });
    case Types.GET_SUBSCRIPTION_PLANS_STARTED:
      return {
        ...state,
        subscriptionPlans: {
          ...state.subscriptionPlans,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        subscriptionPlans: {
          ...state.subscriptionPlans,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.GET_SUBSCRIPTION_PLANS_FAILED:
      return {
        ...state,
        subscriptionPlans: {
          ...state.subscriptionPlans,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.SET_USER_SIGNUP_DETAILS:
      return {
        ...state,
        signupDetails: payload.data,
      };
    case Types.REMOVE_USER_SIGNUP_DETAILS:
      return {
        ...state,
        signupDetails: {},
      };
    case Types.SET_SELECTED_PLAN_DETAILS:
      return {
        ...state,
        planDetails: payload,
      };
    case Types.USER_EXIST_CHECK_STARTED:
      return {
        ...state,
        userExistQuery: {
          ...state.userExistQuery,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.USER_EXIST_CHECK__SUCCESS:
      return {
        ...state,
        userExistQuery: {
          ...state.userExistQuery,
          loading: false,
          isError: false,
          message: "",
          data: payload.data,
        },
      };
    case Types.USER_EXIST_CHECK_FAILED:
      return {
        ...state,
        userExistQuery: {
          ...state.userExistQuery,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.IS_USER_SUBSCRIPTION_FLOW_FROM_SIGNUP:
      return {
        ...state,
        flow: {
          ...state.flow,
          fromSignup: payload.data,
        },
      };
    case Types.FETCH_USER_SUBSCRIPTION_DETAILS_STARTED:
      return {
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.FETCH_USER_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.FETCH_USER_SUBSCRIPTION_DETAILS_FAILED:
      return {
        ...state,
        subscriptionDetails: {
          ...state.subscriptionDetails,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.GET_PAYMENT_METHODS_STARTED:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    case Types.GET_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.ADD_PAYMENT_METHODS_STARTED:
      return {
        ...state,
        addPaymentMethods: {
          ...state.addPaymentMethods,
          loading: true,
          isError: false,
          message: "",
        },
      };
    case Types.ADD_PAYMENT_METHODS_SUCCESS: {
      const dataToAdd = payload.data.payment_method;
      const prevData = state.paymentMethods.data;

      if (dataToAdd?.is_default) {
        prevData.forEach((d: any) => {
          d.is_default = 0;
        });
      }
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          data: [...prevData, dataToAdd],
        },
        addPaymentMethods: {
          ...state.addPaymentMethods,
          loading: false,
          isError: false,
          data: payload.data,
        },
      };
    }
    case Types.ADD_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        addPaymentMethods: {
          ...state.addPaymentMethods,
          loading: false,
          isError: true,
          message: payload.message,
        },
      };
    case Types.USER_SETTINGS_UPDATE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          userSettingsUpdate: {
            ...prevState.userSettingsUpdate,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          userSettingsUpdate: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          userSettingsUpdate: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_SETTINGS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          userSettings: {
            ...prevState.userSettings,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          userSettings: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          userSettings: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.REMOVE_PAYMENT_METHOD: {
      const { id } = action.meta.payload;

      const updatedPaymentMethods = state.paymentMethods.data.filter(
        (paymentMethod: any) => id !== paymentMethod.id
      );

      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          removePaymentMethod: {
            ...prevState.removePaymentMethod,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          paymentMethods: {
            ...prevState.paymentMethods,
            data: updatedPaymentMethods,
          },
          removePaymentMethod: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          removePaymentMethod: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    }
    case Types.UNSUBSCRIBE_PLAN:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          unsubscribePlan: {
            ...prevState.unsubscribePlan,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          unsubscribePlan: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          unsubscribePlan: { loading: false, data: {}, error: payload },
        }),
      });
    case Types.FORGOT_PASSWORD:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          forgotPassword: {
            ...prevState.forgotPassword,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          forgotPassword: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          forgotPassword: { loading: false, data: {}, error: payload },
        }),
      });
    case Types.DELETE_ACCOUNT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteAccount: {
            ...prevState.deleteAccount,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteAccount: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteAccount: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.SSO_SIGNUP:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          ssoSignUp: {
            ...prevState.ssoSignUp,
            loading: true,
          },
          isLoggedIn: false,
        }),
        success: (prevState) => ({
          ...prevState,
          ssoSignUp: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
          isLoggedIn: true,
        }),
        failure: (prevState) => ({
          ...prevState,
          ssoSignUp: {
            loading: false,
            data: {},
            error: payload,
          },
          isLoggedIn: false,
        }),
      });
    case Types.SET_SSO_DATA: {
      return {
        ...state,
        ssoData: payload,
      };
    }
    case Types.GET_PAYMENT_INTENT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          paymentIntent: {
            ...prevState.paymentIntent,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          paymentIntent: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          paymentIntent: { loading: false, data: {}, error: payload },
        }),
      });

    case Types.VERIFY_PAYMENT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          verifyPayment: {
            ...prevState.verifyPayment,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          verifyPayment: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          verifyPayment: { loading: false, data: {}, error: payload },
        }),
      });
    case Types.SSO_LOGIN:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          ssoLogin: {
            ...prevState.ssoLogin,
            loading: true,
          },
          isLoggedIn: false,
        }),
        success: (prevState) => ({
          ...prevState,
          ssoLogin: {
            loading: false,
            data: payload.data || {},
            error: "",
          },
          isLoggedIn: true,
        }),
        failure: (prevState) => ({
          ...prevState,
          ssoLogin: { loading: false, data: {}, error: payload },
          isLoggedIn: false,
        }),
      });

    case Types.USER_LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import TGLOGO from "src/assets/images/tgs-button.svg";
import Button from "src/components/Button";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import UserDropdown from "src/layout/PrivateLayout/UserDropdown";
import TitleIcon from "src/pages/Auth/assets/icon/gametitle_icon.svg";
import BurgerIcon from "src/pages/Home/assets/icons/burger-icon.svg";
import { logoutUser, removeSignupDetails } from "src/store/actions/auth";
import { useStoreActions } from "src/store/hooks";
import { trackEvent } from "src/utils/googleAnalytics";
import { useWindowSize } from "src/utils/useWindowSize";
import { isLoggedIn } from "src/utils/utils";
import classes from "./styles.module.scss";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  const isLogIn = isLoggedIn();
  const [buttonText, setButtonText] = useState({
    text: "Sign In",
    path: "/auth/login",
  });
  const actions = useStoreActions({ logoutUser, removeSignupDetails });
  const isSignInPage = pathname === "/auth/login";

  useEffect(() => {
    if (isLogIn) {
      setButtonText({ text: "Sign Out", path: "/auth/login" });
    } else {
      setButtonText({ text: "Sign Up", path: "/auth/signup" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogIn, pathname]);

  const handleSignUp = () => {
    if (isLogIn) {
      actions.logoutUser();
    }
    navigate(buttonText?.path);
    if ((buttonText?.path || "").includes("/auth/sigup")) {
      trackEvent(
        ANALYTICS_CONSTANTS.category.sign_up,
        ANALYTICS_CONSTANTS.action.sign_up_full
      );
    }
  };

  const handleHomeButton = () => {
    navigate("/");
    actions.removeSignupDetails();
  };
  const getHeaderImage = () => {
    if (isMobile) {
      return TGLOGO;
    } else {
      return TitleIcon;
    }
  };
  return (
    <div className={classes.navWrapper}>
      <div className={classes.wrapper}>
        <div className={classes.logoWrapper}>
          <img
            className={classes.titleImage}
            src={getHeaderImage()}
            alt="icon not found"
            onClick={handleHomeButton}
          />
        </div>
        {isSignInPage && (
          <>
            {isMobile ? (
              <UserDropdown className={classes.userDropdown}>
                <img alt="burger" src={BurgerIcon} />
              </UserDropdown>
            ) : (
              <div className={classes.btnWrapper}>
                <div className={classes.homeButton}>
                  <Button
                    onClick={handleHomeButton}
                    buttonText="Home"
                    variant="link"
                  />
                </div>
                <div className={classes.divider} />
                <div className={classes.signUpButton}>
                  <Button
                    onClick={handleSignUp}
                    buttonText={buttonText?.text}
                    variant="link"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import cn from "classnames";
import { SortIcon } from "src/components/Icon";
import Spinner from "src/components/Spinner";
import { SortMethod, TableColumn } from "src/types";
import Pagination from "../Pagination";
import classes from "./Table.module.scss";

interface Props {
  className?: string;
  data: Record<string, unknown>[];
  columns: TableColumn[];
  pagination?: boolean | Record<string, unknown>;
  rowClick?: (row: any) => void;
  loading?: boolean;
  onSortChanged?: (name: string, val: SortMethod | undefined) => void;
}

const Table = ({
  className,
  pagination,
  columns,
  data,
  rowClick,
  loading,
  onSortChanged,
}: Props) => {
  const hasPagination = !!pagination;
  const tPagination = pagination as any;
  const currentPage = tPagination?.current_page - 1;

  const table = useReactTable({
    data,
    columns: columns as any,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.hasLoading]: loading,
        },
        className
      )}
    >
      {loading && (
        <div className={classes.loading}>
          <Spinner />
        </div>
      )}
      <table>
        <colgroup>
          {columns.map((column: TableColumn, index: number) => (
            <col key={index} width={column.width}></col>
          ))}
        </colgroup>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnD = header.column.columnDef as TableColumn;
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      textAlign: columnD?.dataAlign || "left",
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={cn({
                          [classes.columnHasSort]: columnD.sortable,
                        })}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {columnD.sortable && (
                          <SortIcon
                            className={classes.sortIcon}
                            defaultMethod={
                              tPagination?.sortObj?.name !== header.id
                                ? undefined
                                : tPagination?.sortObj?.method
                            }
                            onMethodChanged={(val) => {
                              if (onSortChanged) onSortChanged(header.id, val);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const columnD = cell.column.columnDef as TableColumn;
                  return (
                    <td
                      key={cell.id}
                      style={{
                        textAlign: columnD.dataAlign || "left",
                        verticalAlign: columnD.verticalAlign || "top",
                        cursor:
                          !!rowClick && !columnD.noClick && !loading
                            ? "pointer"
                            : "default",
                      }}
                      onClick={
                        columnD.noClick || !rowClick || loading
                          ? undefined
                          : () => {
                              if (rowClick) rowClick(row.original);
                            }
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {hasPagination && (
        <Pagination
          total={tPagination?.total || 0}
          page={currentPage || 0}
          onPageChange={({ selected }) => {
            if (tPagination?.onPaginationChanged)
              tPagination.onPaginationChanged({
                page: selected,
              });
          }}
          showTotalText
          className={classes.pagination}
        />
      )}
    </div>
  );
};

export default Table;

import React, { useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";
import { ReactComponent as LampIcon } from "src/assets/images/congratulations-lamp.svg";
import LampLogo from "src/assets/images/wish-lamp-logo-2.png";
import Button from "src/components/Button";
import Text from "src/components/Text";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./Congratulations.module.scss";

const Congratulations = () => {
  const refAnimationInstance = useRef<any>(null);
  const lampRef = useRef<SVGSVGElement | null>(null);
  const { width = 0, height = 0 } = useWindowSize();

  const mobileRedirectLink = process.env.REACT_APP_MOBILE_APP_DEEP_LINK_URL;

  const getInstance = useCallback((instance: any) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback(
    (particleRatio: number, opts: any) => {
      const lampIcon = lampRef?.current;
      const lampIconRect = lampIcon?.getBoundingClientRect();
      const originY = (lampIconRect?.top ?? 0) + 30;
      const originX = (lampIconRect?.left ?? 0) + 140;
      refAnimationInstance?.current?.({
        ...opts,
        origin: {
          x: Number(originX / width).toFixed(2),
          y: Number(originY / height).toFixed(2),
        },
        particleCount: Math.floor(200 * particleRatio),
      });
    },
    [height, width]
  );

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 20,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 20,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 20,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    height && fire();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  const canvasStyles: React.CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoContainer}>
        <img src={LampLogo} alt="lamp logo" />
      </div>
      <Text className={classes.congratulationText}>Congratulations!</Text>
      <div className={classes.successText}>
        <Text fontFamily="inter" fontWeight="bold" size={16} color="#580e56">
          You found a Treasure Game$ Wish Lamp!
        </Text>
      </div>
      <div className={classes.confettiLamp}>
        <div className={classes.lampWrapper}>
          <div className={classes.lamp}>
            <ReactCanvasConfetti
              refConfetti={getInstance}
              style={canvasStyles}
            />

            <LampIcon ref={lampRef} className="lamp" />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerText}>
          <Text size={16} fontFamily="inter" fontWeight="medium" color="#fff">
            There are a few rules and reminders before we can give you the code
            to access the Wish Lamp.
          </Text>
        </div>
        <div className={classes.buttonContainer}>
          <a href={mobileRedirectLink}>
            <Button
              buttonClassName={classes.downloadButton}
              onClick={() => {}}
              buttonText={"Download App"}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;

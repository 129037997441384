import { Reducer } from "redux";
import { handleData } from "../middlewares/handleData";
import Types from "../types/customGame";

const initialState = {
  answerCustomClue: {
    loading: false,
    data: {},
    error: "",
  },
};

const CustomGameReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.ANSWER_CUSTOM_CLUE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          answerCustomClue: {
            ...prevState.answerCustomClue,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          answerCustomClue: {
            loading: false,
            error: "",
            data: meta?.payload,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          answerCustomClue: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });

    default:
      return state;
  }
};

export default CustomGameReducer;

import { Request } from "./request";

export type AddRegionProps = { "region_ids[]": string };
export type UpdateRegionProps = {
  "new_regions[]"?: string;
  "removed_regions[]"?: string;
};

export const getRegionList = async () => {
  return Request.call({
    url: "/api/regions",
    method: "GET",
  });
};

export const getUsersSavedRegionList = async () => {
  return Request.call({
    url: "/api/user/regions",
    method: "GET",
  });
};

export const saveRegion = async (data: AddRegionProps) => {
  return Request.call({
    url: "/api/user/regions",
    method: "POST",
    data,
  });
};

export const updateSavedRegion = async (data: UpdateRegionProps) => {
  return Request.call({
    url: "/api/user/regions",
    method: "PUT",
    data,
  });
};

export const getRegion = async (id: string) => {
  return Request.call({
    url: `/api/regions/${id}`,
    method: "GET",
  });
};

export const getScheduleRegions = async (params = {}) => {
  return Request.call({
    url: `/api/region-schedule`,
    method: "GET",
    params,
  });
};

import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import React, { useState } from "react";
import { Input as BaseInput, InputGroup, InputGroupText } from "reactstrap";
import EyeClosed from "src/assets/icons/eye-closed-icon.svg";
import classes from "./styles.module.scss";
import { Props } from "./types";

const Input: React.FC<Props> = (Props) => {
  const {
    isAutoComplete = "off",
    inputClassName = "",
    inputGroupClassName = "",
    outlined = true,
    loading = false,
    leftIcon,
    rightIcon,
    iconClassName = "",
    disabled = false,
    focused = false,
    error = "",
    label,
    labelClassName = "",
    id,
    alternative = false,
    inputId,
    touched = false,
    required = false,
    showPasswordIcon = false,
    type,
    inputColor,
    ...rest
  } = Props;

  const [inputType, setInputType] = useState(type);

  const renderIcon = (icon: React.ReactNode) => {
    if (!icon) return;
    return (
      <InputGroupText
        className={cx(classes.iconStyling, {
          "border-0": !outlined && !alternative,
          [iconClassName]: iconClassName,
        })}
      >
        {icon}
      </InputGroupText>
    );
  };

  const renderPasswordEyeIcon = () => {
    return (
      <InputGroupText
        onClick={() => {
          if (inputType === "text") {
            setInputType("password");
          } else {
            setInputType("text");
          }
        }}
        className={cx(classes.iconStyling, classes.passwordIcon, {
          "border-0": !outlined && !alternative,
          [iconClassName]: iconClassName,
        })}
      >
        {inputType === "text" ? (
          <FontAwesomeIcon icon={faEye} color="#000" />
        ) : (
          <img className={classes.eyeImage} src={EyeClosed} alt="eye-closed" />
        )}
      </InputGroupText>
    );
  };

  const renderLabel = () => {
    if (!label) return;
    return (
      <label
        className={cx("form-control-label mb-2", {
          [labelClassName]: labelClassName,
        })}
        htmlFor={id}
      >
        {label}
        {required && <span className={classes.requiredLabel}>*</span>}
      </label>
    );
  };

  if (!leftIcon && !rightIcon) {
    return (
      <>
        {renderLabel()}
        <InputGroup
          className={cx(
            "input-group-merge",
            classes.formGroupStyling,
            {
              [inputGroupClassName]: inputGroupClassName,
              "input-group-alternative": alternative,
              [classes.focused]: focused,
              [classes.isInvalid]: touched && error,
            },
            classes[`color_${inputColor}`]
          )}
        >
          <BaseInput
            {...(inputId ? { id: inputId } : {})}
            className={cx(classes.inputStyling, {
              [inputClassName]: inputClassName,
              "border-0": !outlined,
            })}
            disabled={disabled || loading}
            autoComplete={isAutoComplete ? isAutoComplete : "off"}
            type={inputType}
            {...rest}
          />
          {showPasswordIcon && renderPasswordEyeIcon()}
        </InputGroup>
        {touched && error && (
          <div className={classes.invalidFeedback}>{error}</div>
        )}
      </>
    );
  }

  return (
    <>
      {renderLabel()}
      <InputGroup
        className={cx(
          "input-group-merge",
          classes.formGroupStyling,
          {
            "input-group-alternative": alternative,
            [inputGroupClassName]: inputGroupClassName,
            [classes.focused]: focused,
            [classes.isInvalid]: touched && error,
          },
          classes[`color_${inputColor}`]
        )}
      >
        {renderIcon(leftIcon)}
        <BaseInput
          className={cx(classes.inputStyling, {
            ["px-0"]: !!leftIcon,
            [inputClassName]: inputClassName,
          })}
          {...(inputId ? { id: inputId } : {})}
          disabled={disabled || loading}
          error={null}
          type={type}
          {...rest}
        />
        {renderIcon(rightIcon)}
      </InputGroup>
      {touched && error && (
        <div className={classes.invalidFeedback}>{error}</div>
      )}
    </>
  );
};

export default Input;

import { NEW_ID, POST_STATUS_VALUES } from "src/helpers/constants";

export const getPostMediaBR = (values: any, status?: string) => {
  const br: Record<string, unknown> = {
    title: values?.title,
    desc: values?.description,
    status:
      status ||
      (values?.isDraft
        ? POST_STATUS_VALUES.DRAFT
        : POST_STATUS_VALUES.PUBLISHED),
    region: values?.region?.value,
    tags: (values.suggestTags || []).map((t: Record<string, unknown>) => t.id),
  };
  if (values.activeThumbnail?.id) {
    br.cover_id =
      values.activeThumbnail.id !== NEW_ID ? values.activeThumbnail.id : 1;
  }
  return br;
};

import {
  AxiosInstance,
  AxiosRequestConfig,
  default as BaseAxios,
  isAxiosError,
} from "axios";
import Cookie from "js-cookie";
import omit from "lodash/omit";
import store from "src/store";
import { logoutUser } from "src/store/actions/auth";

class RequestClass {
  axios: AxiosInstance;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60 * 60000 });
    this.axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
    };
    this.axios.defaults.withCredentials = true;
  }

  async call(config: AxiosRequestConfig, download = false) {
    try {
      const serverBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const configHeaders = config?.headers || {};
      const configContentType = configHeaders.contentType;
      let headers: { [key: string]: string } = {
        Accept: "application/json",
        "Content-Type": (configContentType as string) ?? "application/json",
      };

      const accessToken = `${Cookie.get("token")}`;

      if (accessToken) {
        headers = {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        };
      }
      const res = await this.axios.request({
        baseURL: serverBaseUrl,
        headers,
        ...omit(config, "headers"),
        ...omit(configHeaders, "contentType"),
      });
      if (download) return res;
      const apiStatus = res.data?.status;
      return { ...res.data, status: apiStatus === false ? 0 : 1 };
    } catch (error) {
      if (isAxiosError(error)) {
        const errorStatus = error?.response?.status || null;
        const data = error?.response?.data || null;
        if (
          errorStatus === 401 &&
          data?.message === "Unauthenticated." &&
          !config.url?.includes("/share")
        ) {
          logoutUser()(store.dispatch);
          setTimeout(() => {
            location.href = "/auth/login";
          }, 100);
        }
        return {
          status: 0,
          errorStatus,
          message: (error as { message: string }).message,
          data,
        };
      }
    }
  }
}

const Request = new RequestClass();
export { Request };

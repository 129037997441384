import React from "react";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import classes from "./styles.module.scss";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  message: string;
};

const LampPausedModal: React.FC<ModalProps> = ({
  isOpen,
  message,
  onClose,
}) => {
  return (
    <Modal
      backdrop
      className={classes.modalWrapper}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      <div className={classes.header}>
        <h3>Lamp has been paused</h3>
      </div>
      <div className={classes.thankWrapper}>
        <p>{message}</p>
      </div>
      <div className={classes.footer}>
        <Button
          buttonText="Close"
          buttonColor="purple"
          weight="semibold"
          onClick={onClose}
          size="xs"
        />
      </div>
    </Modal>
  );
};

export default LampPausedModal;

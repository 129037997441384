export const getNumber2 = (val: number) => (val < 10 ? `0${val}` : val);
export const getVideoDuration = (duration: number) => {
  const tDuration = duration || 0;
  const hours = parseInt(String(tDuration / 60 / 60));
  const minutes = parseInt(String(tDuration / 60));
  const seconds = tDuration - (hours * 3600 + minutes * 60);
  return `${getNumber2(minutes)}:${getNumber2(seconds)}`;
};

export const numberFormatter = (
  num: number,
  digits: number,
  tLookup?: any[],
  spaceBetween?: boolean
) => {
  const lookup = tLookup || [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  const tNum = item?.symbol ? num / item.value : num;
  return item
    ? Number(tNum).toFixed(digits).replace(rx, "$1") +
        (spaceBetween ? ` ${item.symbol}` : item.symbol)
    : 0;
};
export const currencyFormatter = (
  num: number,
  digits: number,
  isShort?: boolean
) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "" },
    { value: 1e6, symbol: isShort ? "M" : "Milion" },
    { value: 1e9, symbol: isShort ? "B" : "Bilion" },
    { value: 1e12, symbol: isShort ? "T" : "Trillion" },
    { value: 1e15, symbol: isShort ? "Q" : "Quadrillion" },
    { value: 1e18, symbol: isShort ? "Q" : "Quintillion" },
  ];
  return numberFormatter(num, digits, lookup, !isShort);
};
export const getCurrencyNumber = (val: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const currency = formatter.format(val);
  return currency.endsWith(".00")
    ? currency.substring(0, currency.length - 3)
    : currency;
};

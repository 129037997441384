import Settings from "src/pages/Settings";
import { ROLE_LEVEL } from "./helpers/constants/role";
import Announcements from "./pages/Announcements";
import Auth from "./pages/Auth";
import Congratulations from "./pages/Congratulations";
import DeviceBlocked from "./pages/DeviceBlocked";
import Engagement from "./pages/Engagement";
import Home from "./pages/Home";
import About from "./pages/Home/About";
import MyDashboard from "./pages/MyDashboard";
import News from "./pages/News";
import NotFound from "./pages/NotFound";
import Play from "./pages/Play";
import PreviewPage from "./pages/PreviewPage";
import Prizes from "./pages/Prizes";
import Profile from "./pages/Profile";
import Search from "./pages/Search";
import SharePost from "./pages/SharePost";
import Subscription from "./pages/Subscription";
import Subscriptions from "./pages/Subscriptions";
import Watch from "./pages/Watch";

export const authRoutes = [
  {
    path: "/signup",
    name: "Signup",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/create-password",
    name: "CreatePassword",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/create-username",
    name: "CreateUsername",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/profile",
    name: "Profile",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/terms",
    name: "TermsAndCondition",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/risk-waiver",
    name: "RiskWaiver",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/login",
    name: "SignIn",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/payment",
    name: "Payment",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: Auth,
    layout: "auth",
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: Auth,
    layout: "auth",
  },
];

export const subscriptionRoute = [
  {
    path: "/select-access",
    name: "SelectAccess",
    component: Subscription,
    layout: "auth",
  },
  {
    path: "/plan",
    name: "Subscription",
    component: Subscription,
    layout: "subscription",
  },
  {
    path: "/payment",
    name: "Payment",
    component: Subscription,
    layout: "subscription",
  },
  {
    path: "/confirm",
    name: "Confirm",
    component: Subscription,
    layout: "subscription",
  },
  {
    path: "/completed",
    name: "Completed",
    component: Subscription,
    layout: "subscription",
  },
];

export const publicRoute = [
  {
    path: "/",
    name: "Home",
    component: Home,
    layout: "public",
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
    layout: "public",
  },
  {
    path: "/preview",
    name: "PreviewPage",
    component: PreviewPage,
    layout: "public",
  },
  {
    path: "/congratulations",
    name: "Congratulations",
    component: Congratulations,
    layout: "public",
  },
  {
    path: "/about",
    name: "About",
    component: About,
    layout: "public",
  },
];

export const privateRoutes = [
  {
    path: "/",
    name: "Subscriptions",
    component: Subscriptions,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.FREE_ACCESS,
      ROLE_LEVEL.FREE_TRIAL,
      ROLE_LEVEL.ADMIN,
    ],
  },
  {
    path: "/device-blocked",
    name: "DeviceBlocked",
    component: DeviceBlocked,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.FREE_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.FREE_ACCESS,
      ROLE_LEVEL.FREE_TRIAL,
      ROLE_LEVEL.ADMIN,
    ],
  },
  {
    path: "/profile/edit",
    name: "Profile",
    component: Profile,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.FREE_ACCESS,
      ROLE_LEVEL.FREE_TRIAL,
      ROLE_LEVEL.ADMIN,
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.FREE_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/news",
    name: "News",
    component: News,
    layout: "private",
    permission: [ROLE_LEVEL.FREE_ACCESS, ROLE_LEVEL.ADMIN],
  },
];

export const playRoutes = [
  {
    path: "/",
    name: "Play",
    component: Play,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/clues",
    name: "Clues",
    component: Play,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/my-dashboard",
    name: "My Dashboard",
    component: MyDashboard,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
];

export const watchRoutes = [
  {
    path: "/",
    name: "Watch",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/my-channel/manage-content/post-details/:postId",
    name: "WatchMyChannelManageContentPostDetails",
    component: Watch,
    layout: "private",
    permission: [ROLE_LEVEL.FULL_ACCESS, ROLE_LEVEL.ADMIN],
  },
  {
    path: "/my-channel/manage-content",
    name: "WatchMyChannelManageContent",
    component: Watch,
    layout: "private",
    permission: [ROLE_LEVEL.FULL_ACCESS, ROLE_LEVEL.ADMIN],
  },
  {
    path: "/my-channel/followers",
    name: "MyChannelFollowers",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/my-channel/followers/:name/:userId",
    name: "MyChannelFollowersPage",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/playing-media/:id",
    name: "WatchChannelPlayingMedia",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/playing-media/:id/:name/:userId",
    name: "WatchChannelUserDetails",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/:subChannelSlug/:subChannelTitle",
    name: "WatchSubChannelDetails",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/:subChannelSlug/:subChannelTitle/playing-media/:id",
    name: "WatchSubChannelPlayingMedia",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/user-profile/:username/followers",
    name: "WatchChannelUserDetailsFollowers1",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/user-profile/:username",
    name: "WatchSubChannelUserDetails1",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/:subChannelSlug/:subChannelTitle/playing-media/:id/:name/:userId/followers",
    name: "WatchSubChannelUserDetailsFollowers",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/:subChannelSlug/:subChannelTitle/playing-media/:id/:name/:userId",
    name: "WatchSubChannelUserDetails",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/playing-media/:id/:name/:userId/followers",
    name: "WatchSubChannelUserDetailsFollowers",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug",
    name: "WatchChannelDetails",
    component: Watch,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
];

export const announcementRoutes = [
  {
    path: "/",
    name: "Announcements",
    component: Announcements,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/playing-media/:id",
    name: "AnnouncementsPlayingMedia",
    component: Announcements,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/playing-media/:id/:name/:userId",
    name: "AnnouncementsUserDetails",
    component: Announcements,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/playing-media/:id/:name/:userId/followers",
    name: "AnnouncementsUserDetailsFollowers",
    component: Announcements,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
];

export const prizesRoutes = [
  {
    path: "/",
    name: "Prizes",
    component: Prizes,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/playing-media/:id",
    name: "PrizesPlayingMedia",
    component: Prizes,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/playing-media/:id/:name/:userId/followers",
    name: "PrizesUserDetailsFollowers",
    component: Prizes,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug/playing-media/:id/:name/:userId",
    name: "PrizesUserDetails",
    component: Prizes,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/:channelSlug",
    name: "PrizesSub",
    component: Prizes,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
];

export const engagementRoutes = [
  {
    path: "/",
    name: "Engagement",
    component: Engagement,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/playing-media/:id",
    name: "EngagementPlayingMedia",
    component: Engagement,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/playing-media/:id/:name/:userId",
    name: "EngagementUserDetails",
    component: Engagement,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
  {
    path: "/playing-media/:id/:name/:userId/followers",
    name: "EngagementUserDetailsFollowers",
    component: Engagement,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
];
export const searchRoutes = [
  {
    path: "/",
    name: "Search",
    component: Search,
    layout: "private",
    permission: [
      ROLE_LEVEL.FULL_ACCESS,
      ROLE_LEVEL.ADMIN,
      ROLE_LEVEL.FREE_TRIAL,
    ],
  },
];

export const sharePostRoutes = [
  {
    path: "/post",
    name: "SharePost",
    component: SharePost,
    layout: "public",
  },
];

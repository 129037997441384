import { AnyAction, Reducer, combineReducers } from "redux";
import { RootState } from "../index";
import Types from "../types/auth";
import AnnouncementReducer from "./announcement";
import AuthReducer from "./auth";
import ClueReducer from "./clue";
import CustomGameReducer from "./customGame";
import DashboardReducer from "./dashboard";
import EngagementReducer from "./engagement";
import MediaReducer from "./media";
import NotificationReducer from "./notification";
import PrizeReducer from "./prize";
import RegionReducer from "./regions";
import SharePostReducer from "./sharePost";
import UserReducer from "./user";
import WatchReducer from "./watch";

const reducers = {
  auth: AuthReducer,
  region: RegionReducer,
  clue: ClueReducer,
  watch: WatchReducer,
  media: MediaReducer,
  user: UserReducer,
  engagement: EngagementReducer,
  announcement: AnnouncementReducer,
  customGame: CustomGameReducer,
  prize: PrizeReducer,
  dashboard: DashboardReducer,
  notification: NotificationReducer,
  sharePost: SharePostReducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === Types.USER_LOGOUT_SUCCESS) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;

import get from "lodash/get";
import queryString from "query-string";

export const getAllUrlParams = (search = "") => {
  const searchUrl = search;
  return queryString.parse(searchUrl.slice(1)) || {};
};

export const getUrlParamValueByKey = (search = "", key = "") => {
  const values = getAllUrlParams(search);
  return get(values, key, "");
};

import cx from "classnames";
import React from "react";
import { ClueProps } from "src/pages/Play/Clue/types";
import classes from "./styles.module.scss";

type UrlQuestionProps = { clue: ClueProps; isModal?: boolean };

const UrlQuestion: React.FC<UrlQuestionProps> = ({ isModal = false, clue }) => {
  return (
    <div
      className={cx(classes.wrapper, {
        [classes.modalQuestionWrapper]: isModal,
      })}
    >
      <div className={classes.urlWrapper}>
        <a href={clue?.url as string} target="_blank" rel="noreferrer">
          {clue?.url || ""}
        </a>
      </div>
    </div>
  );
};

export default UrlQuestion;

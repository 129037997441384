import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NEW_ID } from "src/helpers/constants";
import useUploadStatus from "src/helpers/hooks/useUploadStatus";
import ManageContentTable from "src/pages/Watch/components/ManageContentTable";
import UploadEmptyState from "src/pages/Watch/components/UploadEmptyState";
import UploadModal from "src/pages/Watch/components/UploadModal";
import { actionCb } from "src/utils/action";
import classes from "./Watch.module.scss";
import { useActions, useManageContentData } from "./selectorData";
import { getPostMediaBR } from "./utils";

interface Props {
  isOpenUpload: boolean;
  setIsOpenUpload: (val: boolean) => void;
  reloadPosts: any;
}

const ManageContent = ({
  isOpenUpload,
  setIsOpenUpload,
  reloadPosts,
}: Props) => {
  const navigate = useNavigate();
  const [newPostId, setNewPostId] = useState("");
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [deletePostLoading, setDeletePostLoading] = useState(false);
  const [resetStatusWatcher, setResetStatusWatcher] = useState<boolean | null>(
    null
  );
  const { clearGetStatusTimeout } = useUploadStatus(
    newPostId,
    setUploadFileLoading,
    resetStatusWatcher,
    setResetStatusWatcher
  );
  const {
    createPost,
    uploadMediaPost,
    syncResetPostStatus,
    updateMediaPost,
    deletePost,
  } = useActions();
  const { postStatusData, watchUserPostsLoading, watchUserPostsObj } =
    useManageContentData();
  const hasPosts = watchUserPostsObj.meta?.total > 0;
  const resetState = () => {
    setNewPostId("");
    setUploadFileLoading(false);
    setPublishLoading(false);
    setDeletePostLoading(false);
    setResetStatusWatcher(null);
  };
  const handleUploadFile = (tFile: any, key: string, cb?: () => void) => {
    if (tFile) {
      setUploadFileLoading(true);
      syncResetPostStatus();
      if (cb) cb();
      createPost(
        actionCb(
          (res: any) => {
            const tPostId = res?.data?.id;
            setNewPostId(tPostId);
            const fData = new FormData();
            fData.append("file", tFile);
            uploadMediaPost(tPostId, fData, key);
          },
          () => {
            setUploadFileLoading(false);
          }
        )
      );
    }
  };
  const handleReuploadFileChanged = (tFile: any, key: string) => {
    if (tFile) {
      setUploadFileLoading(true);
      syncResetPostStatus();
      const fData = new FormData();
      fData.append("file", tFile);
      uploadMediaPost(newPostId, fData, key, () => {
        setResetStatusWatcher(!resetStatusWatcher);
      });
    }
  };
  const handleDeletePost = (postId: string, cb?: any) => {
    if (!deletePostLoading) {
      setDeletePostLoading(true);
      deletePost(
        postId,
        actionCb(
          () => {
            setDeletePostLoading(false);
            if (cb) cb();
            reloadPosts();
          },
          () => {
            setDeletePostLoading(false);
          }
        )
      );
    }
  };
  const hideUploadModal = () => {
    setNewPostId("");
    setIsOpenUpload(false);
    if (uploadFileLoading) {
      setUploadFileLoading(false);
    }
    if (clearGetStatusTimeout) {
      clearGetStatusTimeout();
    }
  };

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.manageContentWrapper}>
        <h2>Manage Content</h2>
        <div className={classes.inner}>
          <>
            {hasPosts ? (
              <ManageContentTable
                className={classes.manageContentTable}
                onRowClick={(row) => {
                  navigate(
                    `/watch/my-channel/manage-content/post-details/${row.id}`
                  );
                }}
                data={watchUserPostsObj.items || []}
                meta={watchUserPostsObj.meta || {}}
                onPaginationChanged={({ page, sortName, sortMethod }: any) => {
                  reloadPosts(page, sortName, sortMethod);
                }}
                onPostDeleted={handleDeletePost}
                deletePostLoading={deletePostLoading}
                dataLoading={watchUserPostsLoading}
              />
            ) : (
              <>
                {!watchUserPostsLoading && (
                  <UploadEmptyState
                    onClick={() => {
                      if (!watchUserPostsLoading) {
                        setIsOpenUpload(true);
                      }
                    }}
                    className={classes.uploadEmptyState}
                  />
                )}
              </>
            )}
          </>
        </div>
      </div>
      <UploadModal
        isOpen={isOpenUpload}
        onClose={() => {
          if (newPostId) {
            deletePost(newPostId);
            setNewPostId("");
          }
          hideUploadModal();
        }}
        onPublish={(values) => {
          const br = getPostMediaBR(values);
          const publishFn = () => {
            updateMediaPost(
              newPostId,
              br,
              actionCb(
                () => {
                  // wait to make sure new post is added
                  setTimeout(() => {
                    setPublishLoading(false);
                    hideUploadModal();
                    reloadPosts();
                  }, 3000);
                },
                () => {
                  setPublishLoading(false);
                  hideUploadModal();
                }
              )
            );
          };
          if (!values.isUploadImage && values.activeThumbnail?.id === NEW_ID) {
            setPublishLoading(true);
            const tFormData = new FormData();
            tFormData.append("file", values.customThumbnail);
            uploadMediaPost(
              newPostId,
              tFormData,
              "cover",
              actionCb(
                () => {
                  publishFn();
                },
                () => {
                  setPublishLoading(false);
                },
                false,
                "Custom thubnail failed!"
              )
            );
          } else {
            setPublishLoading(true);
            publishFn();
          }
        }}
        onFileChanged={handleUploadFile}
        onReloadFileChanged={handleReuploadFileChanged}
        uploadLoading={uploadFileLoading}
        postId={newPostId}
        postStatusData={postStatusData}
        publishLoading={publishLoading}
      />
    </>
  );
};

export default ManageContent;

import cn from "classnames";
import React, { useState } from "react";
import { ReactComponent as HeartFilledIcon } from "src/assets/icons/heart-filled-icon.svg";
import { ReactComponent as HeartIcon } from "src/assets/icons/heart-icon.svg";
import { numberFormatter } from "src/utils/number";
import SocialButton from "../SocialButton";
import classes from "./SocialLove.module.scss";

interface Props {
  isLoved: boolean;
  setIsLoved: (val: boolean) => void;
  likeCount?: number;
  isPublic?: boolean;
  isFree?: boolean;
}

const SocialLove = ({
  isLoved,
  setIsLoved,
  likeCount,
  isPublic,
  isFree,
}: Props) => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <SocialButton
      icon={isClicked || isLoved ? <HeartFilledIcon /> : <HeartIcon />}
      title={numberFormatter(likeCount || 0, 2)}
      onClick={
        isPublic
          ? () => {
              setIsLoved(false);
            }
          : () => {
              if (!isClicked) {
                if (!isLoved) {
                  setIsClicked(true);
                  setTimeout(() => {
                    setIsClicked(false);
                    setIsLoved(true);
                  }, 500);
                } else {
                  setIsLoved(false);
                  setIsClicked(false);
                }
              }
            }
      }
      className={cn(classes.wrapper, {
        [classes.clicked]: isClicked && !isLoved,
        [classes.loved]: isLoved,
        [classes.isFree]: isFree,
      })}
    />
  );
};

export default SocialLove;

import cloneDeep from "lodash/cloneDeep";

export const getMoreEngagements = (prevState: any, payload: any) => {
  const newEngagementsData = cloneDeep(prevState.engagements.data);
  newEngagementsData.channel.sections[0].meta = {
    ...payload.data.channel.sections[0].meta,
  };
  newEngagementsData.channel.sections[0].posts = [
    ...newEngagementsData.channel.sections[0].posts,
    ...payload.data.channel.sections[0].posts,
  ];
  return newEngagementsData;
};

import { PRIZE_SUB_SLUG, WATCH_SUB_SLUG } from "src/helpers/constants";
import { capitalizeFirstLetter } from "src/utils/text";

export const getPrivatePath = (
  channelSlug: string,
  postId: string,
  channelTitle?: string
) => {
  const isWatch = WATCH_SUB_SLUG.includes(channelSlug);
  const isPrizes = PRIZE_SUB_SLUG.includes(channelSlug);
  const isRegion = channelSlug && channelSlug.includes("region-");
  if (channelSlug) {
    if (isRegion) {
      const slugArr = channelSlug.split("-");
      return `/watch/regions/${slugArr[1]}/${
        channelTitle || `${capitalizeFirstLetter(slugArr[0])} ${slugArr[1]}`
      }/playing-media/${postId}`;
    } else if (!isWatch && !isPrizes) {
      return `/${channelSlug}/playing-media/${postId}`;
    } else if (isWatch || isPrizes) {
      return `/${
        isWatch ? "watch" : "prizes"
      }/${channelSlug}/playing-media/${postId}`;
    }
  }

  return "/watch";
};

import { Request } from "./request";

export type ClueStatusProps = {
  id: number;
  status: string;
  is_current: boolean;
};

export type FilterParams = {
  start_date?: string;
  end_date?: string;
  "clue_types[]"?: string;
  season: string;
};

export type SeasonProps = {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  status: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
};

export type PurchaseHintProps = {
  hint_number: string;
  payment_id: string;
  charge_amount: string;
};

export type HintProps = {
  hint_number: number;
  hint: string;
};

export type PurchaseRangeFinderAdvantageClueProps = {
  lamp: number;
  advClue: number;
  data: { payment_id: number; lat?: number; lng?: number };
};

export type PurchaseTreasureViewsAdvantageClueProps = {
  lamp: number;
  advClue: number;
  data: { payment_id: number };
};

export const getClues = async (region_id: string, params?: FilterParams) => {
  return Request.call({
    url: `/api/region/${region_id}/clues`,
    method: "GET",
    params: params,
  });
};

export const getClueTypes = async () => {
  return Request.call({
    url: `/api/clue/types`,
    method: "GET",
  });
};

export const postCluesAnswer = async (
  clue_id: number | undefined,
  answer: string,
  params: { answer_number?: number } = {}
) => {
  return Request.call({
    url: `/api/clue/${clue_id}/answer`,
    method: "POST",
    data: { answer },
    params,
  });
};

export const editCluesAnswer = async (
  clue_id: number | undefined,
  answer: string,
  params: { answer_number?: number } = {}
) => {
  return Request.call({
    url: `/api/clue-answer/${clue_id}`,
    method: "PUT",
    data: { answer },
    params,
  });
};

export const purchaseHint = async (
  clue_id: number,
  data: PurchaseHintProps
) => {
  return Request.call({
    url: `api/clue/${clue_id}/purchase-hints`,
    method: "POST",
    data,
  });
};

export const editClueStatus = async (clue_status: ClueStatusProps[]) => {
  return Request.call({
    url: `/api/clue/status`,
    method: "POST",
    data: { clue_status },
  });
};

export const purchaseHintList = async (clue_id: number) => {
  return Request.call({
    url: `/api/clue/${clue_id}/hints`,
    method: "GET",
  });
};

export const getSeasons = async () => {
  return Request.call({
    url: `/api/seasons`,
    method: "GET",
  });
};

export const getAdvantageCluesPurchaseHistoryData = async (lamp: number) => {
  return Request.call({
    url: `/api/${lamp}/adv-clues/purchase/history`,
    method: "GET",
  });
};

export const purchaseRangeFinderAdvantageClue = async ({
  lamp,
  advClue,
  data,
}: PurchaseRangeFinderAdvantageClueProps) => {
  return Request.call({
    url: `/api/${lamp}/adv-clues/${advClue}/purchase`,
    method: "POST",
    data,
  });
};

export const purchaseTreasureViewsAdvantageClue = async ({
  lamp,
  advClue,
  data,
}: PurchaseTreasureViewsAdvantageClueProps) => {
  return Request.call({
    url: `/api/${lamp}/adv-clues/${advClue}/purchase`,
    method: "POST",
    data,
  });
};

export const getRangeFinderBuyOptions = async (lamp: number) => {
  return Request.call({
    url: `/api/${lamp}/adv-clues`,
    method: "GET",
  });
};

export const getTreasureViewsBuyOptions = async (lamp: number) => {
  return Request.call({
    url: `/api/${lamp}/adv-clues?type=treasure_view_images`,
    method: "GET",
  });
};

export const getAdvantageCluesCountApi = async (lampId: number) => {
  return Request.call({
    url: `/api/${lampId}/adv-clues`,
    method: "GET",
  });
};

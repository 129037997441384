import get from "lodash/get";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROLE_NAME } from "src/helpers/constants/role";
import useInfiniteScroll from "src/helpers/hooks/useInfiniteScroll";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { getPrivatePath } from "src/pages/SharePost/utils";
import ChannelBlock from "src/pages/Watch/components/ChannelBlock";
import WatchHeader from "src/pages/Watch/components/Header";
import { CommonGetPayload } from "src/types";
import { MediaInfo } from "src/types/media";
import { actionCb } from "src/utils/action";
import { getBasicMediaInfo } from "src/utils/media";
import classes from "./Search.module.scss";
import { useActions } from "./selectorData";

let isLoadingTemp = false;

const Search = () => {
  const { searchMedia } = useActions();
  const [searchParams] = useSearchParams();
  const userProfile = useUserProfile();
  const userRole = get(userProfile, "user.role.name");
  const qString = searchParams.get("q") || "";
  const [searchData, setSearchData] = useState<any>({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [defaultSearchActive, setDefaultSearchActive] = useState(false);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [defaultSearchKeyword, setDefaultSearchKeyword] = useState("");
  const navigate = useNavigate();
  const handleSearchFn = (tKeyword: string, nextCursor?: string, cb?: any) => {
    const tFilters: CommonGetPayload = {
      q: tKeyword,
    };
    if (userRole === ROLE_NAME.ADMIN) {
      tFilters.is_full_access = true;
    }
    if (nextCursor) {
      tFilters.cursor = nextCursor;
      tFilters.isMore = true;
    }
    setSearchLoading(true);
    searchMedia(
      tFilters,
      actionCb(
        (res: any) => {
          if (cb) cb();
          const tSearchData = res.data || {};
          setSearchData(
            tFilters.isMore
              ? {
                  ...tSearchData,
                  items: [...searchData.items, ...tSearchData.items],
                }
              : tSearchData
          );
          setSearchLoading(false);
        },
        () => {
          setSearchLoading(false);
        }
      )
    );
  };
  const handleSearchChange = (tKeyword: string) => {
    if (!tKeyword) {
      navigate(-1);
    } else {
      if (tKeyword !== defaultSearchKeyword) {
        setSearchData({});
        setDefaultSearchKeyword(tKeyword);
        handleSearchFn(tKeyword);
      }
    }
  };
  const medias = (searchData?.items || []).map((feedItem: MediaInfo) => {
    const channel = get(feedItem, "channels")?.[0];
    return {
      ...getBasicMediaInfo(feedItem),
      onClick: channel?.slug
        ? () => {
            const tPrivatePath = getPrivatePath(
              channel?.slug,
              String(feedItem.id),
              channel?.title
            );
            navigate(tPrivatePath);
          }
        : undefined,
    };
  });
  const loadMore = () => {
    setIsLazyLoading(true);
    if (!isLoadingTemp) {
      handleSearchFn(
        defaultSearchKeyword,
        searchData?.meta?.next_cursor,
        () => {
          setIsLazyLoading(false);
          isLoadingTemp = false;
        }
      );
    }
    isLoadingTemp = true;
  };

  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore: !isLazyLoading && searchData?.meta?.next_cursor,
    elementId: "watch-container",
  });

  useEffect(() => {
    isLoadingTemp = false;
    if (qString) {
      setSearchData({});
      handleSearchFn(qString);
      setDefaultSearchActive(true);
      setDefaultSearchKeyword(qString);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.wrapper}>
        <WatchHeader
          isRoot={true}
          onProfileClick={() => navigate("/watch/my-channel")}
          showManageContent={false}
          showUpload={false}
          isMainPage={true}
          onSearchChanged={handleSearchChange}
          avatarSrc={userProfile.user.avatar_path || ""}
          hideSearch={false}
          defaultSearchActive={defaultSearchActive}
          defaultSearchKeyword={defaultSearchKeyword}
          searchLocal
        />
        <div className={classes.content}>
          <div className={classes.title}>
            Search Results for "{defaultSearchKeyword}"
          </div>
          <ChannelBlock
            medias={medias}
            hideButton
            paddingBottom
            loading={searchLoading}
            loadingBottom={!!searchData?.meta?.next_cursor}
          />
        </div>
      </div>
    </>
  );
};

export default Search;

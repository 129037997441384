import * as authActions from "src/store/actions/auth";
import * as clueActions from "src/store/actions/clue";
import * as regionActions from "src/store/actions/regions";
import * as userActions from "src/store/actions/user";
import { useStoreActions } from "src/store/hooks";

export const useUserActions = () => {
  return useStoreActions({
    ...userActions,
  });
};

export const useRegionActions = () => {
  return useStoreActions({
    ...regionActions,
  });
};

export const useClueActions = () => {
  return useStoreActions({
    ...clueActions,
  });
};

export const useAuthActions = () => {
  return useStoreActions({
    ...authActions,
  });
};

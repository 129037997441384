import moment from "moment";
import React, { useState } from "react";
import avatarSrc from "src/assets/images/default-avatar.png";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import Text from "src/components/Text";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { getDefaultFormattedNumber } from "src/utils/utils";
import classes from "./ViewProfile.module.scss";
import { useActions, useIndexData } from "./selectorData";

const ViewProfile = () => {
  const { deleteAccount, logoutUser } = useActions();
  const { deleteAccountLoading } = useIndexData();
  const [isOpen, setIsOpen] = useState(false);
  const userProfile = useUserProfile();
  const birthDate = moment(userProfile.user?.birth_date, "YYYY-MM-DD").format(
    "MM-DD-YYYY"
  );

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.personalInfo}>
        <div className={classes.title}>
          <Text color="#fff" size={24}>
            Personal Information
          </Text>
        </div>
        <div className={classes.photo}>
          <Text className="mb-1" size={16}>
            Photo
          </Text>
          <img
            src={userProfile?.user?.avatar_path || avatarSrc}
            className={classes.img}
          />
        </div>
        <div className={classes.names}>
          <div>
            <Text className="mb-1" size={16}>
              First Name{" "}
            </Text>
            <Text size={18}> {userProfile.user?.first_name || "-"}</Text>
          </div>
          <div>
            <Text className="mb-1" size={16}>
              Last Name{" "}
            </Text>
            <Text size={18}>{userProfile.user?.last_name || "-"}</Text>
          </div>
        </div>
        <div className={classes.numbers}>
          <div>
            <Text className="mb-1" size={16}>
              Phone Number{" "}
            </Text>
            <Text size={18}>
              {getDefaultFormattedNumber(userProfile.user?.phone) || "-"}
            </Text>
          </div>
          <div>
            <Text className="mb-1" size={16}>
              Birthdate{" "}
            </Text>
            <Text size={18}>
              {userProfile.user?.birth_date ? birthDate : "-"}
            </Text>
          </div>
        </div>
        <div className={classes.email}>
          <div>
            <Text className="mb-1" size={16}>
              Email{" "}
            </Text>
            <Text size={18}>{userProfile.user?.email || "-"}</Text>
          </div>
          <div>
            <Text className="mb-1" size={16}>
              Gender{" "}
            </Text>
            <Text size={18}>{userProfile.user?.gender || "-"}</Text>
          </div>
        </div>
      </div>
      <div className={classes.username}>
        <div className={classes.title}>
          <Text size={24}>Username</Text>
          <Text size={12}>
            Your username will also appear as your channel name for the content
            you post.{" "}
          </Text>
        </div>
        <div className={classes.names}>
          <Text className="mb-1" size={16}>
            Username
          </Text>
          <Text size={18}>{userProfile.user?.username || "-"}</Text>
        </div>
      </div>
      <div className={classes.deleteAccount}>
        <span onClick={toggle}>Delete Account</span>
      </div>
      {isOpen && (
        <Modal
          backdrop
          className={classes.modal}
          bodyClassName={classes.modalBody}
          isOpen={isOpen}
          toggle={toggle}
        >
          <div className={classes.modalHeader}>
            <Text color="#fff" className="mb-4" size={40} fontFamily="milonga">
              Delete your account
            </Text>
            <Text color="#fff" size={24}>
              Are you sure you want to delete your account? This action can’t be
              undone.
            </Text>
          </div>
          <div className={classes.actions}>
            <Button
              buttonText={
                <Text
                  color="#fff"
                  fontWeight="bold"
                  fontFamily="impact"
                  size={36}
                >
                  Delete
                </Text>
              }
              buttonColor="secondary"
              onClick={() => {
                deleteAccount(() => {
                  logoutUser();
                  setTimeout(() => {
                    location.href = "/auth/login";
                  }, 0);
                });
              }}
              loading={deleteAccountLoading}
              type="submit"
              buttonClassName={classes.submit}
            />
            <div className={classes.cancelWrapper}>
              <Button
                buttonText={
                  <Text color="#fff" fontFamily="impact" size={36}>
                    Cancel
                  </Text>
                }
                onClick={toggle}
                buttonColor="secondary"
                variant="link"
                buttonClassName={classes.cancel}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ViewProfile;

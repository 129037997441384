import cn from "classnames";
import React from "react";
import classes from "./SeasonBlock.module.scss";

interface Props {
  children: React.ReactNode;
}

const ActivateDate = ({ children }: Props) => {
  return <span className={cn(classes.activateDate)}>{children}</span>;
};

export default ActivateDate;

import cn from "classnames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as ArrowLeftDownIcon } from "src/assets/icons/arrows-left-down-icon.svg";
import useBackUrl from "src/helpers/hooks/useBackUrl";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import useInfiniteScroll from "src/helpers/hooks/useInfiniteScroll";
import useMediaDetailsInit from "src/helpers/hooks/useMediaDetailsInit";
import useUserDetailsInit from "src/helpers/hooks/useUserDetailsInit";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { CommonGetPayload } from "src/types";
import { ChannelConfig } from "src/types/channel";
import { actionCb } from "src/utils/action";
import { scrollToTop } from "src/utils/scrollTop";
import WatchMain from "./Main";
import PlayingMedia from "./PlayingMedia";
import PostDetails from "./PostDetails";
import WatchSub from "./Sub";
import WatchSub1 from "./Sub1";
import UserDetails from "./UserDetails";
import UserFollowers from "./UserFollowers";
import classes from "./Watch.module.scss";
import WatchHeader from "./components/Header";
import {
  CHANNEL_CONFIG,
  MANAGE_CONTENT_SORT_NAME_MAPPING,
  PAGE_TITLE,
  TREASURE_GAME_USERNAME,
  UserDetailTab,
} from "./constants";
import { useActions, useIndexData } from "./selectorData";

let searchValue = "";
let isLoadingTemp = false;

const Watch = () => {
  const {
    getWatchUser,
    getAllMedia,
    getWatchChannel,
    getWatchSubChannel,
    getWatchUserPosts,
    getPostDetails,
    syncResetWatchUserFeedMeta,
    syncResetWatchUserRepostFeedMeta,
    syncUpdateWatchUser,
  } = useActions();
  const {
    userFeed,
    userRepostFeed,
    watchChannel,
    watchSubChannel,
    watchUserPostsObj,
  } = useIndexData();
  const userProfile = useUserProfile();
  useMediaDetailsInit();
  const { loadUserFeed, loadUserRepost } = useUserDetailsInit();
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [userDetailTab, setUserDetailTab] = useState<string>(
    UserDetailTab.posts
  );
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = String(searchParams.get("current-tab") || "");
  const params = useParams();
  const channelSlug = String(params.channelSlug || "");
  const subChannelSlug = String(params.subChannelSlug || "");
  const username = String(params.username || "");
  const postId = String(params?.postId || "");
  const userId = Number(params.userId || 0);
  const cConfig: ChannelConfig = CHANNEL_CONFIG[channelSlug] || {};
  const currentPath = useCurrentPath();
  const backUrl = useBackUrl("/watch");
  const navigate = useNavigate();
  const isMainPage = pathname === "/watch";
  const isMyChannelPage = pathname === "/watch/my-channel";
  const isSubPage =
    !isMyChannelPage && currentPath === "/watch/:channelSlug" && !!channelSlug;
  const isSubPage1 =
    currentPath === "/watch/:channelSlug/:subChannelSlug/:subChannelTitle";
  const isChannelFollowersPage2 =
    currentPath ===
    "/watch/:channelSlug/:subChannelSlug/:subChannelTitle/playing-media/:id/:name/:userId/followers";
  const isChannelFollowersPage1 =
    currentPath === "/watch/user-profile/:username/followers";
  const isChannelFollowersPage =
    currentPath === "/watch/my-channel/followers/:name/:userId" ||
    currentPath ===
      "/watch/:channelSlug/playing-media/:id/:name/:userId/followers" ||
    pathname === "/watch/my-channel/followers" ||
    isChannelFollowersPage1 ||
    isChannelFollowersPage2;
  const isManageContentPage = pathname === "/watch/my-channel/manage-content";
  const tComparePathName = currentPath || pathname;
  const isUserDetailsPage =
    !isChannelFollowersPage && tComparePathName?.includes(":id/:name/:userId");
  const isPostDetailsPage =
    tComparePathName ===
    "/watch/my-channel/manage-content/post-details/:postId";
  const isMediaDetailPage =
    pathname.includes("/playing-media/") &&
    !isUserDetailsPage &&
    !isChannelFollowersPage;
  const isUserDetailsPage1 = currentPath === "/watch/user-profile/:username";
  const isSubPageHasLazyLoad = isSubPage && !cConfig.multipleSections;
  const hasLazyLoad =
    isMyChannelPage ||
    isSubPageHasLazyLoad ||
    isSubPage1 ||
    isUserDetailsPage ||
    isUserDetailsPage1;
  const postRepostTabs = [
    {
      value: UserDetailTab.posts,
      label: "Posts",
    },
    {
      value: UserDetailTab.reposts,
      label: (
        <>
          <ArrowLeftDownIcon />
          Reposts
        </>
      ),
    },
  ];
  const loadWatchChannel = (nextCursor?: string, cb?: any) => {
    const tFilters: CommonGetPayload = {};
    if (nextCursor) {
      tFilters.cursor = nextCursor;
      tFilters.isMore = true;
    }
    if (searchValue) {
      tFilters.q = searchValue;
    }
    getWatchChannel(channelSlug, tFilters, cb);
  };
  const loadWatchSubChannel = (nextCursor?: string, cb?: any) => {
    const tFilters: CommonGetPayload = {};
    if (nextCursor) {
      tFilters.cursor = nextCursor;
      tFilters.isMore = true;
    }
    if (searchValue) {
      tFilters.q = searchValue;
    }
    getWatchSubChannel(channelSlug, subChannelSlug, tFilters, cb);
  };
  const reloadUserFeedData = (
    tTab: string,
    isResetMeta?: boolean,
    cb?: any
  ) => {
    if (tTab === UserDetailTab.reposts) {
      loadUserRepost(
        isResetMeta ? "" : userRepostFeed?.meta?.next_cursor,
        cb,
        isUserDetailsPage1 ? username : userId,
        searchValue
      );
    } else {
      loadUserFeed(
        isResetMeta ? "" : userFeed?.meta?.next_cursor,
        cb,
        isUserDetailsPage1 ? username : userId,
        searchValue
      );
    }
  };
  const loadMore = () => {
    setIsLazyLoading(true);
    if (!isLoadingTemp) {
      if (isMyChannelPage) {
        loadUserFeed(userFeed.meta.next_cursor, () => {
          setIsLazyLoading(false);
          isLoadingTemp = false;
        });
      } else if (isSubPageHasLazyLoad) {
        loadWatchChannel(watchChannel.sections?.[0].meta.next_cursor, () => {
          setIsLazyLoading(false);
          isLoadingTemp = false;
        });
      } else if (isSubPage1) {
        loadWatchSubChannel(
          watchSubChannel.sections?.[0].meta.next_cursor,
          () => {
            setIsLazyLoading(false);
            isLoadingTemp = false;
          }
        );
      } else if (isUserDetailsPage || isUserDetailsPage1) {
        reloadUserFeedData(userDetailTab, false, () => {
          setIsLazyLoading(false);
          isLoadingTemp = false;
        });
      }
    }
    isLoadingTemp = true;
  };
  const reloadManageContentPosts = (
    page = 1,
    tSearch = "",
    sortName = "",
    sortMethod = ""
  ) => {
    const tFilter = {
      page,
      q: tSearch,
    } as any;
    if (sortName && sortMethod) {
      tFilter.order = `${
        MANAGE_CONTENT_SORT_NAME_MAPPING[sortName] || sortName
      },${sortMethod}`;
    }
    getWatchUserPosts(userProfile.user.id, tFilter);
  };
  const handleSearchChange = (val: string) => {
    searchValue = val;
    switch (tComparePathName) {
      case "/watch/my-channel/manage-content":
        reloadManageContentPosts(1, val);
        break;
      case "/watch":
        getAllMedia(searchValue);
        break;

      default:
        if (isSubPage) {
          loadWatchChannel();
        } else if (isSubPage1) {
          loadWatchSubChannel();
        } else if (isUserDetailsPage || isUserDetailsPage1) {
          reloadUserFeedData(userDetailTab, true);
        }
        break;
    }
  };
  const handlePostRepostTabChanged = (tTab: string) => {
    if (tTab !== userDetailTab) {
      if (tTab === UserDetailTab.reposts) {
        syncResetWatchUserRepostFeedMeta();
      } else {
        syncResetWatchUserFeedMeta();
      }
      reloadUserFeedData(tTab, true);
      setUserDetailTab(tTab);
    }
  };
  const handleFollowersPostRepostTabChanged = (tTab: string) => {
    handlePostRepostTabChanged(tTab);
    navigate(`${pathname.replace("/followers", "")}?current-tab=${tTab}`);
  };
  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore:
      hasLazyLoad &&
      !isLazyLoading &&
      (isMyChannelPage || isUserDetailsPage || isUserDetailsPage1
        ? !!(
            userDetailTab === UserDetailTab.reposts ? userRepostFeed : userFeed
          )?.meta?.next_cursor
        : isSubPageHasLazyLoad
        ? !!watchChannel.sections?.[0]?.meta?.next_cursor
        : isSubPage1
        ? !!watchSubChannel.sections?.[0].meta?.next_cursor
        : false),
    elementId: "watch-container",
  });

  const callApiByPage = () => {
    switch (true) {
      case isMyChannelPage: {
        getWatchUser(userProfile.user.id);
        if (currentTab === UserDetailTab.reposts) {
          loadUserRepost();
        } else {
          loadUserFeed();
        }
        break;
      }
      case isMainPage: {
        getAllMedia();
        break;
      }
      case isSubPage: {
        loadWatchChannel();
        break;
      }
      case isSubPage1: {
        loadWatchSubChannel();
        break;
      }
      case isManageContentPage: {
        reloadManageContentPosts(1, "");
        break;
      }
      case isChannelFollowersPage: {
        getWatchUser(
          (isChannelFollowersPage1 ? username : userId) || userProfile.user.id,
          actionCb(() => {
            if (username === TREASURE_GAME_USERNAME) {
              syncUpdateWatchUser({
                posts_count: userFeed.meta?.total,
              });
            }
          })
        );
        break;
      }
    }
  };

  useEffect(() => {
    isLoadingTemp = false;
    searchValue = "";
    scrollToTop("watch-container");
    setIsLazyLoading(false);
    setUserDetailTab(currentTab || UserDetailTab.posts);
    if (currentTab) {
      searchParams.delete("current-tab");
      setSearchParams(searchParams);
    }
    callApiByPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    if (isPostDetailsPage) {
      if (postId) {
        getPostDetails(
          userProfile.user.id,
          postId,
          actionCb(
            null,
            () => {
              navigate("/watch/my-channel/manage-content");
            },
            false,
            "This post is not available, please remove it!"
          )
        );
      } else {
        navigate("/not-found");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);
  useEffect(() => {
    setIsOpenUpload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPageComponent = () => {
    switch (true) {
      case isMediaDetailPage:
        return <PlayingMedia />;
      case isMainPage:
        return <WatchMain />;
      case isUserDetailsPage:
      case isUserDetailsPage1:
        return (
          <UserDetails
            tab={userDetailTab}
            onTabChanged={handlePostRepostTabChanged}
            tabs={postRepostTabs}
          />
        );
      case isChannelFollowersPage:
        return (
          <UserFollowers
            isMyChannel={
              isMyChannelPage || pathname === "/watch/my-channel/followers"
            }
            onTabChanged={handleFollowersPostRepostTabChanged}
            tabs={postRepostTabs}
          />
        );
      case isPostDetailsPage:
        return (
          <PostDetails
            reloadCurrentManageContentTable={() => {
              reloadManageContentPosts(
                watchUserPostsObj.meta?.current_page || 1,
                searchValue
              );
            }}
          />
        );
      case isSubPage1:
        return <WatchSub1 />;
      default:
        return (
          <WatchSub
            title={`${PAGE_TITLE[pathname]}`}
            isOpenUpload={isOpenUpload}
            setIsOpenUpload={setIsOpenUpload}
            reloadManageContentPosts={(
              tPage: number,
              tSortName: string,
              tSortMethod: string
            ) => {
              reloadManageContentPosts(
                tPage,
                searchValue,
                tSortName,
                tSortMethod
              );
            }}
            tab={userDetailTab}
            onTabChanged={handlePostRepostTabChanged}
            tabs={postRepostTabs}
          />
        );
    }
  };

  return (
    <div className={cn(classes.wrapper)}>
      <WatchHeader
        isRoot={pathname === "/watch"}
        onProfileClick={() => navigate("/watch/my-channel")}
        onBack={() => navigate(backUrl)}
        hideProfile={isMyChannelPage || isManageContentPage}
        showManageContent={isMyChannelPage}
        showUpload={isMyChannelPage || isManageContentPage}
        onManageContent={() => navigate("/watch/my-channel/manage-content")}
        isMainPage={isMainPage}
        onUpload={() => {
          navigate("/watch/my-channel/manage-content");
          setIsOpenUpload(true);
        }}
        onSearchChanged={handleSearchChange}
        avatarSrc={userProfile.user.avatar_path || ""}
        hideSearch={isMyChannelPage}
      />
      {getPageComponent()}
    </div>
  );
};

export default Watch;

import cn from "classnames";
import { ReactComponent as PencilIcon } from "src/assets/icons/pencil-icon.svg";
import Button from "src/components/Button";
import { PostTag, PostTagWrapper } from "src/components/Tag";
import useOptions from "src/helpers/hooks/useOptions";
import SourcePreview from "src/pages/Watch/components/UploadModal/components/SourcePreview";
import { getFileSize } from "src/utils/file";
import { getIsImage, getMediaTags } from "src/utils/media";
import { getVideoDuration } from "src/utils/number";
import Socials from "../Socials";
import classes from "./PostView.module.scss";
import ViewItem from "./ViewItem";

interface Props {
  contentClassName?: string;
  fooerClassName?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  title?: string;
  data: any;
}

const PostView = ({
  contentClassName,
  fooerClassName,
  onDelete,
  onEdit,
  title,
  data,
}: Props) => {
  const { regionOptions } = useOptions();
  const tData = data || {};
  const isImage = getIsImage(tData.media_type);
  const tRegionObj = (regionOptions || []).find(
    (region: Record<string, unknown>) => region.value === tData.region
  );
  const tRegionArr = tRegionObj?.label?.split(" - ");

  return (
    <>
      <div className={cn(classes.content, contentClassName)}>
        <div className={classes.left}>
          <Socials className={classes.socials} />
          <ViewItem title="Title" content={<p>{tData.title || ""}</p>} />
          <ViewItem title="Description" content={<p>{tData.desc || ""}</p>} />
          <ViewItem
            title="Region"
            content={
              <p className={classes.region}>
                <span>{tRegionArr?.[0] || ""}</span> <span>-</span>{" "}
                <span>{tRegionArr?.[1] || ""}</span>
              </p>
            }
          />
          <ViewItem
            title="Tags"
            content={
              <PostTagWrapper>
                {getMediaTags(tData.tags || []).map(
                  (tag: string, index: number) => {
                    return <PostTag key={index}>{tag}</PostTag>;
                  }
                )}
              </PostTagWrapper>
            }
          />
        </div>
        <div className={classes.right}>
          <SourcePreview
            name={tData.metadata?.filename}
            size={getFileSize(tData.metadata?.size)}
            length={getVideoDuration(tData.metadata?.duration)}
            title={title}
            src={tData.images?.[0]?.url}
            isUploadImage={isImage}
            noPadding
          />
        </div>
      </div>
      <div className={cn(classes.footer, fooerClassName)}>
        <Button
          buttonText="Delete"
          variant="link"
          buttonColor="secondary-light"
          onClick={onDelete}
          textSize="lg"
          weight="semibold1"
        />
        <Button
          buttonText="Edit"
          buttonColor="white"
          isGhost
          textSize="lg"
          weight="semibold1"
          leftIcon={<PencilIcon />}
          flex
          paddingSize="lg"
          onClick={onEdit}
        />
      </div>
    </>
  );
};

export default PostView;

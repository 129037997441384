import cn from "classnames";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import classes from "./CloseCircle.module.scss";

const CloseCircle = ({ className = "" }) => {
  return (
    <span className={cn(classes.wrapper, className)}>
      <CloseIcon />
    </span>
  );
};

export default CloseCircle;

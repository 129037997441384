import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import { ReactComponent as LampMobileIcon } from "src/pages/Home/assets/icons/lamp-mobile-icon.svg";
import { ReactComponent as LampIcon } from "src/pages/Home/assets/icons/wish-lamp-new.svg";
import Landing1Bg from "src/pages/Home/assets/images/landing-1-background.png";
import Landing1MobileBg from "src/pages/Home/assets/images/landing-1-mobile-bg.png";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./CalloutSection.module.scss";
const CalloutSection = ({ redirectLink = "" }) => {
  const navigate = useNavigate();
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  return (
    <div className={classes.wrapper}>
      <div
        style={{
          backgroundImage: `url(${isMobile ? Landing1MobileBg : Landing1Bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
        className={classes.background}
      ></div>
      <div className={classes.container}>
        {isMobile ? (
          <LampMobileIcon className={classes.icon} />
        ) : (
          <LampIcon className={classes.icon} />
        )}
        <p>Find the lamp, change your life.</p>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => navigate(redirectLink)}
            buttonClassName={classes.button}
            buttonText="Join Now"
          />
        </div>
      </div>
    </div>
  );
};
export default CalloutSection;

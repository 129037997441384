import cn from "classnames";

import get from "lodash/get";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ButtonProps } from "reactstrap";
import { PaymentMethodsProps } from "src/api/auth";
import { ReactComponent as PaymentSuccess } from "src/assets/icons/payment-success-icon.svg";
import { ReactComponent as PlusCircle } from "src/assets/icons/plus-outline-circle-icon.svg";
import Button from "src/components/Button";

import PaymentForm, { PaymentHandleProps } from "src/components/PaymentForm";
import Text from "src/components/Text";

import { addPaymentMethods } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { RangeFinder } from "src/types/clue";
import { useClueActions } from "src/utils/useActions";
import { LatLng } from "../RangeInputMap";
import classes from "./RangeFinderPayment.module.scss";

type Props = {
  prev: () => void;
  handleSeeResult: (isInRange: boolean) => void;
  selectedOption: RangeFinder | null;
  selectedMarker: LatLng | null;
  BackButton: (props: ButtonProps) => JSX.Element;
  lampId: number;
  refreshCluePurchaseData: () => void;
};

const RangeFinderPayment: React.FC<Props> = ({
  handleSeeResult,
  BackButton,
  prev,
  lampId,
  selectedMarker,
  selectedOption,
  refreshCluePurchaseData,
}) => {
  const { purchaseRangeFinderAdvantageClue } = useClueActions();
  const actions = useStoreActions({
    addPaymentMethods,
  });
  const {
    data: paymentMethods,
  }: { data: PaymentMethodsProps[]; loading: boolean } = useReducerData(
    "auth",
    "paymentMethods",
    {
      data: [],
    }
  );

  const [addNewPaymentFlow, setAddNewPaymentFlow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isInRange, setIsInRange] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodsProps>();

  useEffect(() => {
    if (paymentMethods.length > 0) {
      const defaultMethod = paymentMethods.find(
        (paymentMethod) => paymentMethod.is_default === 1
      );
      if (defaultMethod) {
        setSelectedPaymentMethod(defaultMethod);
      } else {
        setSelectedPaymentMethod(paymentMethods[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  const toggleFlow = () => setAddNewPaymentFlow(!addNewPaymentFlow);

  const handlePurchaseClue = async () => {
    setLoading(true);
    const res = await purchaseRangeFinderAdvantageClue({
      lamp: lampId,
      advClue: selectedOption?.id as number,
      data: {
        payment_id: selectedPaymentMethod?.id as number,
        ...(selectedMarker ? selectedMarker : {}),
      },
    });
    const status = get(res, "status", 0);
    const message = get(res, "message", null);
    const is_in_range = get(res, "data.is_in_range", false);
    if (status) {
      setIsInRange(is_in_range);
      setShowSuccess(true);
      refreshCluePurchaseData();
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const handlePaymentUpdate = async (paymentInfo: PaymentHandleProps) => {
    const paymentDetails = {
      payment_method_id: paymentInfo?.id,
      is_default: paymentInfo.is_default,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await actions.addPaymentMethods(paymentDetails);
    const status = get(res, "status", 0);
    const message = get(res, "data.message", null);
    if (status) {
      toast.dark(message);
      toggleFlow();
    } else {
      toast.warning(message);
      toggleFlow();
    }
  };

  return (
    <>
      <div className={cn(classes.wrapper)}>
        {loading ? (
          <div className={classes.paymentLoading}>
            <Text size={24} fontWeight="semibold">
              Please wait while we process your payment...
            </Text>
          </div>
        ) : (
          <>
            {showSuccess ? (
              <div className={classes.success}>
                <Text
                  className="mb-4"
                  color="#fff"
                  size={32}
                  fontWeight="semibold"
                >
                  Purchase Range Finder for {selectedOption?.range} Mile Range
                </Text>
                <Text
                  className="mb-4"
                  color="#fff"
                  size={24}
                  fontWeight="semibold"
                >
                  Purchase Successful!
                </Text>

                <PaymentSuccess />
                <div className={classes.button}>
                  <Button
                    buttonText={
                      <Text
                        color="#fff"
                        size={18}
                        fontFamily="inter"
                        fontWeight="bold"
                      >
                        See Results
                      </Text>
                    }
                    type="submit"
                    // loading={loading}
                    onClick={() => handleSeeResult(isInRange)}
                    className={classes.seeResults}
                  />
                </div>
              </div>
            ) : (
              <>
                <BackButton
                  onClick={() => (addNewPaymentFlow ? toggleFlow() : prev())}
                />
                <div className={classes.title}>
                  <Text size={30} fontWeight="semibold">
                    Purchase Range Finder for {selectedOption?.range || "-"}{" "}
                    Mile Range
                  </Text>
                  <Text size={30} fontWeight="semibold">
                    ${selectedOption?.amount || "-"}
                  </Text>
                </div>
                {/*@TODO Uncomment and replace below placeholder text with Important Payment information once a decision is made on what we should display*/}
                {/*<div className={classes.info}>
                  <Text size={14} fontWeight="semibold">
                    Important Information
                  </Text>
                  <Text size={14}>
                    Torem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio
                    mattis. Class aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per inceptos himenaeos.
                  </Text>
                </div> */}
                {addNewPaymentFlow ? (
                  <div className={classes.paymentDetailsWrapper}>
                    <Text className="mb-0" size={14} fontWeight="semibold">
                      Payment Details
                    </Text>
                    <PaymentForm
                      buttonProps={{
                        buttonText: (
                          <Text size={24} fontWeight="bold" color="#fff">
                            Next
                          </Text>
                        ),
                        className: classes.newPaymentButton,
                      }}
                      selectClassName={classes.selectClassName}
                      inputGroupClassName={classes.paymentInputGroup}
                      wrapperClassName={classes.newPaymentWrapper}
                      hideDefaultCheckbox
                      stripeInputStyle={{
                        base: {
                          fontSize: "18px",
                          color: "#000",
                          "::placeholder": {
                            color: "#A1A1A1",
                            fontSize: "18px",
                            lineHeight: "18px",
                          },
                        },
                        invalid: {
                          iconColor: "#333333",
                          color: "#000",
                        },
                      }}
                      handlePayment={handlePaymentUpdate}
                    />
                  </div>
                ) : (
                  <div className={classes.paymentDetailsWrapperClass}>
                    <div className={classes.paymentDetails}>
                      <Text className="mb-3" size={14} fontWeight="semibold">
                        Payment Details
                      </Text>
                      <div className={classes.paymentWrapper}>
                        {paymentMethods.map((paymentMethod, i) => {
                          return (
                            <div
                              onClick={() =>
                                setSelectedPaymentMethod(paymentMethod)
                              }
                              key={i}
                              className={cn(classes.existingCard, {
                                [classes.active]:
                                  selectedPaymentMethod?.id ===
                                  paymentMethod.id,
                              })}
                            >
                              <div>
                                <Text
                                  style={{ textTransform: "uppercase" }}
                                  fontWeight="semibold"
                                  size={18}
                                >
                                  {paymentMethod?.brand}{" "}
                                  <span style={{ textTransform: "none" }}>
                                    {paymentMethod.is_default
                                      ? "(Default)"
                                      : ""}
                                  </span>
                                </Text>
                                <Text size={14}>
                                  Credit card ending in {paymentMethod?.last4}
                                </Text>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div
                        onClick={() => setAddNewPaymentFlow(true)}
                        className={classes.addNew}
                      >
                        <PlusCircle />
                        <Text size={18}>Add a new payment method</Text>
                      </div>
                    </div>
                    <div className={classes.button}>
                      <Button
                        buttonText={
                          <Text
                            color="#fff"
                            size={24}
                            fontFamily="inter"
                            fontWeight="bold"
                          >
                            Purchase Clue
                          </Text>
                        }
                        type="submit"
                        // loading={loading}
                        onClick={handlePurchaseClue}
                        className={classes.submit}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default RangeFinderPayment;

import moment from "moment";
import React from "react";
import { useNavigate } from "react-router";
import { Spinner } from "reactstrap";
import { SeasonProps } from "src/api/clue";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import WishLampImage from "src/pages/Auth/assets/images/wish-lamp-img.svg";
import { useReducerData } from "src/store/hooks";
import PlanCard from "../Components/PlanCard";
import { PlanProps } from "../Components/PlanCard/types";
import { Props } from "../types";
import classes from "./styles.module.scss";

const PlansDesktop: React.FC<Props> = ({ handleSelectedPlan }) => {
  const navigate = useNavigate();

  const {
    data: { plans },
    loading,
  } = useReducerData("auth", "subscriptionPlans", {}) as {
    data: { plans: PlanProps[] };
    loading: false;
  };

  const {
    data: seasons,
    loading: seasonLoading,
  }: { data: SeasonProps[]; loading: boolean } = useReducerData(
    "clue",
    "seasonList",
    { data: [], loading: false }
  );

  const handleSubscriptionSelection = (plan: PlanProps) => {
    handleSelectedPlan(plan);
    navigate("/subscription/payment");
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <img
          className={classes.lampImage}
          src={WishLampImage}
          alt="wish-lamp-image"
        />
        <Typography className={classes.title}>Wish Lamp$</Typography>
        <Typography className={classes.subTitle} variant="p16">
          All Treasure Game$ are played in a specific range per year, called
          “Seasons”. Wish Lamp$ is played in 2 Six Month Seasons.
        </Typography>
        <Typography fontWeight="semibold" variant="p16">
          Dates for WishLamp$ seasons are as follows:
        </Typography>

        {seasonLoading ? (
          <Spinner />
        ) : (
          <div className={classes.seasons}>
            {seasons.map((season, i) => {
              return (
                <div key={i} className={classes.seasonCard}>
                  <div className={classes.seasonTitle}>
                    <Typography
                      fontFamily="inter"
                      fontWeight="bold"
                      variant="p14"
                      className="mb-0"
                    >
                      {season?.name?.split(" ")?.join(" - ")}
                    </Typography>
                  </div>
                  <div className={classes.dates}>
                    <div className={classes.date}>
                      <Typography
                        fontFamily="inter"
                        fontWeight="light"
                        variant="p12"
                        className={classes.label}
                      >
                        Start date
                      </Typography>
                      <Typography
                        fontFamily="inter"
                        fontWeight="black"
                        variant="p14"
                      >
                        {moment(season?.start_date, "YYYY-MM-DD").format(
                          "MMM DD"
                        )}
                      </Typography>
                    </div>
                    <div className={classes.divider}></div>
                    <div className={classes.date}>
                      <Typography
                        fontFamily="inter"
                        fontWeight="light"
                        variant="p12"
                        className={classes.label}
                      >
                        End date
                      </Typography>
                      <Typography
                        fontFamily="inter"
                        fontWeight="black"
                        variant="p14"
                      >
                        {moment(season?.end_date, "YYYY-MM-DD").format(
                          "MMM DD"
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className={classes.bottomContent}>
          <Typography className="mb-2" variant="p16">
            Games are played 24/7 all year round.
          </Typography>
          <Typography variant="p16">You can join anytime.</Typography>
          <Typography variant="p16">
            You can cancel at anytime. Cancellation is effective at the end of
            your current subscription time period.{" "}
          </Typography>
        </div>
      </div>
      <div className={classes.subscriptionCards}>
        <Typography variant="p18" fontWeight="semibold">
          Select your Subscription:
        </Typography>
        {loading ? (
          <Spinner />
        ) : (
          <div className={classes.cardsWrapper}>
            {plans?.map((plan, i) => (
              <div key={i}>
                <PlanCard
                  plan={plan}
                  handleSubscriptionSelection={handleSubscriptionSelection}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        <Button
          buttonColor="secondary"
          onClick={() => navigate("/")}
          buttonText={<Typography variant="p20">View Preview Page</Typography>}
          variant="link"
          data-testid="view_preview_page"
        />
      </div>
    </div>
  );
};

export default PlansDesktop;

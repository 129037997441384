export const GAME = {
  SNAKE: {
    NAME: "snake",
    SLUG: "snake",
    TITLE: "Clue: Snake Game",
    DESCRIPTION: "Play Snake to receive the answer. Gather 30 Lamps.",
  },
  TIC_TOE: {
    NAME: "tic_toe",
    SLUG: "tic-tac-toe",
    TITLE: "Clue: Tic Tac Toe",
    DESCRIPTION:
      "When a game of Tic Tac Toe in a minute or less. Get 3 in a row to win.",
  },
  MATCHING: {
    NAME: "matching",
    SLUG: "matching",
    TITLE: "Clue: Lamp Match",
    DESCRIPTION:
      "There are two lamp cards to be matched, you have 1 minute to find them.",
  },
};

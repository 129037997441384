import React from "react";
import { useNavigate } from "react-router";
import wishLampLogo from "src/assets/images/wish-lamp-landing.png";
import Button from "src/components/Button";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { ReactComponent as LampMobileIcon } from "src/pages/Home/assets/icons/lamp-mobile-icon.svg";
import { trackEvent } from "src/utils/googleAnalytics";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./styles.module.scss";

const JoinNowSection = ({ redirectLink = "" }) => {
  const navigate = useNavigate();
  const { width = 0 } = useWindowSize();
  const isTablet = width <= 1200;
  const isSignupRedirect = redirectLink.includes("/auth/signup");

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <p>Find the lamp, change your life.</p>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => {
              navigate(redirectLink);
              if (isSignupRedirect) {
                trackEvent(
                  ANALYTICS_CONSTANTS.category.sign_up,
                  ANALYTICS_CONSTANTS.action.sign_up_full
                );
              }
            }}
            buttonClassName={classes.joinButton}
            buttonText="Join Now"
          />
          <Button
            onClick={() => {
              navigate(`${redirectLink}?free_trial=true`);
              if (isSignupRedirect) {
                trackEvent(
                  ANALYTICS_CONSTANTS.category.sign_up,
                  ANALYTICS_CONSTANTS.action.sign_up_free
                );
              }
            }}
            buttonClassName={classes.trialButton}
            buttonText="Try Free Trial"
          />
        </div>
      </div>
      <div className={classes.rightContainer}>
        {isTablet ? (
          <LampMobileIcon className={classes.icon} />
        ) : (
          <img src={wishLampLogo} />
        )}
      </div>
    </div>
  );
};

export default JoinNowSection;

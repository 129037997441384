import cx from "classnames";
import partition from "lodash/partition";
import React from "react";
import { Spinner } from "reactstrap";
import RightIcon from "src/assets/icons/chevrons-right-icon.svg";
import Text from "src/components/Text";
import Typography from "src/components/Typography";
import { getUsersSavedRegionList, saveRegion } from "src/store/actions/regions";
import { useReducerData, useStoreActions } from "src/store/hooks";
import RegionCard from "../RegionCard";
import classes from "./styles.module.scss";
import { Props, RegionListProps } from "./types";

const RegionList: React.FC<Props> = ({
  selectedRegion,
  hoveredRegion,
  handleAddRegion,
  handleRemoveRegion,
  addedRegions,
  regionSelectionFlow,
  handleHoveredRegion,
  isExpanded = true,
  toggleExpand = () => {},
  className = "",
  handleRemoveAllRegion,
  testId,
}) => {
  const actions = useStoreActions({
    saveRegion,
    getUsersSavedRegionList,
  });
  const {
    data: regions,
    loading,
  }: { data: RegionListProps[]; loading: boolean } = useReducerData(
    "region",
    "regionList",
    {
      data: [],
      loading: false,
    }
  );

  const { data: savedRegions } = useReducerData("region", "savedRegionList", {
    data: [],
  });

  // const [isListHover, setIsListHover] = useState(false);

  // useEffect(() => {
  //   if (isListHover) return;
  //   const element = document.getElementById(`region-list-${hoveredRegion}`);
  //   if (element) {
  //     element.scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [hoveredRegion]);

  const handleJoinAllRegions = async () => {
    const unsavedRegions = regions.filter(
      (reg) => !addedRegions.includes(reg.slug) && reg.status === "active"
    );
    const remainingRegionIds = unsavedRegions.map(
      (region) => region.slug.split("-")[1]
    );

    if (!(remainingRegionIds.length > 0)) {
      return;
    }

    const formData = new FormData();
    remainingRegionIds.forEach((regionId) => {
      formData.append("region_ids[]", `${regionId}`);
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.saveRegion(formData);
    if (status) {
      actions.getUsersSavedRegionList();
    }
  };

  const handleAddIndividualRegion = async (region: string) => {
    const regionId = region.split("-")[1];
    const formData = new FormData();
    formData.append("region_ids[]", `${regionId}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { status } = await actions.saveRegion(formData);
    if (status) {
      actions.getUsersSavedRegionList();
    }
  };

  const renderSelectedFlowList = () => {
    const [savedRegionsList, unsavedRegionsList] = partition(
      regions,
      (region) => addedRegions.includes(region.slug)
    );
    const displayedRegions = [...savedRegionsList, ...unsavedRegionsList];

    return (
      <>
        {savedRegionsList.length > 0 ? (
          <div className={classes.selectedRegion}>
            <Typography className="mb-0" fontWeight="semibold" variant="p24">
              My Regions ({savedRegions?.length})
            </Typography>
          </div>
        ) : (
          <div className={classes.joinAllWrapper}>
            <Typography className="mb-0" fontWeight="semibold" variant="p24">
              Join All Regions
            </Typography>
            <Typography
              fontWeight="medium"
              variant="p12"
              fontFamily="inter"
              className={classes.joinAllText}
              onClick={handleJoinAllRegions}
            >
              Join All
            </Typography>
          </div>
        )}
        {displayedRegions.map((data: RegionListProps, index: number) => {
          return (
            <React.Fragment key={index}>
              <RegionCard
                key={index}
                index={index}
                status={data?.status}
                region={data.slug}
                isJoined={addedRegions.includes(data.slug)}
                isHovered={data.slug == hoveredRegion}
                states={data.states}
                handleAddRegion={handleAddRegion}
                handleRemoveRegion={handleRemoveRegion}
                handleHoveredRegion={handleHoveredRegion}
                selectedRegion={selectedRegion}
                handleAddIndividualRegion={handleAddIndividualRegion}
                data={data}
              />
              <>
                {savedRegions.length !== regions?.length &&
                  savedRegions.length === index + 1 && (
                    <>
                      <div className={classes.divider}></div>
                      <div className={classes.remainingRegion}>
                        <Typography
                          className="mb-0"
                          fontWeight="semibold"
                          variant="p24"
                        >
                          Other Regions (
                          {regions?.length - savedRegions?.length})
                        </Typography>
                        {regions?.length - savedRegions?.length > 1 && (
                          <>
                            {savedRegionsList.length === regions?.length ? (
                              <Typography
                                fontWeight="medium"
                                variant="p12"
                                fontFamily="inter"
                                className={classes.selectAll}
                                onClick={handleRemoveAllRegion}
                              >
                                Deselect All
                              </Typography>
                            ) : (
                              <Typography
                                fontWeight="medium"
                                variant="p12"
                                fontFamily="inter"
                                className={classes.selectAll}
                                onClick={handleJoinAllRegions}
                              >
                                Join All
                              </Typography>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
              </>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <div
      className={cx(classes.wrapper, className)}
      // onMouseEnter={() => setIsListHover(true)}
      // onMouseLeave={() => setIsListHover(false)}
      data-testid={testId}
    >
      {loading && (
        <div className={classes.loaderWrapper}>
          <Spinner>Loading...</Spinner>
        </div>
      )}
      {!loading && (
        <>
          <div className={classes.header}>
            <div
              className={cx(classes.imgWrapper, {
                [classes.expandIcon]: !isExpanded,
              })}
            >
              <img onClick={toggleExpand} src={RightIcon} alt="right" />
              {!isExpanded && (
                <Text fontWeight="medium" size={14} color="#fff">
                  Clues
                </Text>
              )}
              {isExpanded && regionSelectionFlow && (
                <div className={classes.totalRegions}>
                  ({regions.length} Regions)
                </div>
              )}
            </div>
          </div>
          {isExpanded && (
            <div className={classes.listWrapper}>
              {renderSelectedFlowList()}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RegionList;

import cx from "classnames";
import React from "react";
import {
  Modal as BaseModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import classes from "./styles.module.scss";

import { Props } from "./types";

const Modal: React.FC<Props> = ({
  isOpen,
  toggle,
  children,
  className,
  bodyClassName,
  hasHeader,
  color,
  ...rest
}) => {
  return (
    <BaseModal
      className={cx(
        "modal-dialog-centered",
        classes.modalContainer,
        {
          [classes.hasHeader]: hasHeader,
        },
        classes[`color_${color}`],
        className
      )}
      isOpen={isOpen}
      toggle={toggle}
      {...rest}
    >
      <div className={cx(classes.modalWrapper, bodyClassName)}>{children}</div>
    </BaseModal>
  );
};

export { ModalHeader, ModalBody, ModalFooter };
export default Modal;

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import WishLampLogo from "src/assets/images/wish-lamp-landing.png";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import classes from "./UpgradeModal.module.scss";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  submitText: string;
  isAllFreeCluesCompleted?: boolean;
}

const UpgradeModal = ({
  isOpen,
  onClose,
  className,
  submitText = "Yes",
  isAllFreeCluesCompleted = false,
}: ModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal
      backdrop
      className={cn(classes.modalWrapper, className)}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      <div className={classes.close}>
        <FontAwesomeIcon
          icon={faXmark}
          color="#fff"
          onClick={onClose}
          cursor={"pointer"}
          size="2xl"
        />
      </div>
      <div
        className={cn(classes.content, {
          [classes.noMargin]: isAllFreeCluesCompleted,
        })}
      >
        <div className={classes.icon}>
          <img src={WishLampLogo} alt="logo" />
        </div>
        <div
          className={cn(classes.title, {
            [classes.completedTitle]: isAllFreeCluesCompleted,
          })}
        >
          {isAllFreeCluesCompleted
            ? "You have opened all 10 of the Free Trial Clues for this region."
            : "Upgrade to Full Access"}
        </div>
        <div className={classes.description}>
          Upgrade to Full Access to unlock all clues and be eligible to win
          weekly prizes and the grand prize if you find the lamp!
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.title}>SUBSCRIPTION OPTIONS</div>
        <div className={classes.subscriptionOption}>
          <div className={classes.optionWrapper}>
            <div className={classes.option}>$8.99 Per Month</div>
            <div className={classes.details}>
              Automatic renewal every 30 days
            </div>
          </div>
          <div className={classes.divider}></div>
          <div className={classes.optionWrapper}>
            <div className={classes.option}>$89.99 Per Year</div>
            <div className={classes.details}>
              Automatic renewal every 365 days. (Save $17.89)
            </div>
          </div>
        </div>
        <div className={classes.submitBtn}>
          <Button
            buttonText={submitText}
            buttonColor="purple-gradient"
            variant="fill"
            weight="semibold"
            onClick={() => {
              onClose();
              navigate("/auth/profile");
            }}
            buttonClassName={classes.upgradeButton}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeModal;

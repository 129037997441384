import cx from "classnames";
import parser from "html-react-parser";
import React from "react";
import { ClueProps } from "src/pages/Play/Clue/types";
import classes from "./styles.module.scss";

type TextImageQuestionProps = { clue: ClueProps; isModal?: boolean };

const TextImageQuestion: React.FC<TextImageQuestionProps> = ({
  isModal = false,
  clue,
}) => {
  return (
    <div
      className={cx(classes.wrapper, {
        [classes.modalQuestionWrapper]: isModal,
      })}
    >
      <img src={clue.url[0]} alt="riddle-question" />
      <div className={classes.extraText}>{parser(clue?.extra_text || "")}</div>
    </div>
  );
};

export default TextImageQuestion;

import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import Text from "src/components/Text";
import LeftIcon from "src/pages/Home/assets/icons/left-icon.svg";
import RightIcon from "src/pages/Home/assets/icons/right-icon.svg";
import MediaBlock from "src/pages/Watch/components/MediaBlock";
import { MediaInfo } from "src/types/media";
import { Channel } from "src/types/user";
import { getBasicMediaInfo } from "src/utils/media";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./VideoSection.module.scss";
type Props = {
  channel: Channel;
  onVideoClick: (data: MediaInfo | undefined) => void;
};

const VideoSection: React.FC<Props> = ({ channel, onVideoClick }) => {
  const sliderRef = useRef<Slider | null>(null);
  const [endReached, setEndReached] = useState(false);
  const [state, setState] = useState(0);
  const { width = 0 } = useWindowSize();
  const postsLength = channel.sections[0].posts.length;

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 3,
    afterChange: (current: number) => {
      if (current === postsLength - 3 || postsLength <= 3) {
        setEndReached(true);
      } else {
        setEndReached(false);
      }
      setState(current);
    },
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          afterChange: (current: number) => {
            if (current === postsLength - 2 || postsLength <= 2) {
              setEndReached(true);
            } else {
              setEndReached(false);
            }
            setState(current);
          },
        },
      },
      {
        breakpoint: 786,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (current: number) => {
            if (current === postsLength - 1 || postsLength <= 1) {
              setEndReached(true);
            } else {
              setEndReached(false);
            }
            setState(current);
          },
        },
      },
    ],
  };

  const handleInitialState = () => {
    if (width < 786) {
      if (postsLength === 1) {
        setEndReached(true);
      } else {
        setEndReached(false);
      }
    } else if (width < 1024) {
      if (postsLength === 2) {
        setEndReached(true);
      } else {
        setEndReached(false);
      }
    } else {
      if (postsLength === 3) {
        setEndReached(true);
      } else {
        setEndReached(false);
      }
    }
  };

  useEffect(() => {
    handleInitialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <div key={channel.slug} className={classes.section}>
      <div
        className={cn(classes.leftIcon, { [classes.disabled]: state === 0 })}
        onClick={() => sliderRef.current?.slickPrev()}
      >
        <img alt="left-icon" src={LeftIcon} />
      </div>
      <Text
        color="#fff"
        size={32}
        fontWeight="semibold"
        className={classes.newsHeader}
      >
        {channel.sections[0].title}
      </Text>
      <Slider className={classes.slider} ref={sliderRef} {...settings}>
        {channel.sections[0].posts.map((post: MediaInfo, i) => {
          const media = {
            ...getBasicMediaInfo(post),
            onClick: () => {
              onVideoClick(post);
            },
          };

          return (
            <div className={classes.mediaBlockWrapper} key={i}>
              <MediaBlock
                {...media}
                className={classes.mediaBlock}
                mediaClassName={classes.media}
                videoClassName={classes.video}
                infoWrapperClassName={classes.infoWrapper}
              />
            </div>
          );
        })}
      </Slider>
      <div
        className={cn(classes.RightIcon, { [classes.disabled]: endReached })}
        onClick={() => sliderRef.current?.slickNext()}
      >
        <img alt="right-icon" src={RightIcon} />
      </div>
    </div>
  );
};

export default VideoSection;

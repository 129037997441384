import React, { useEffect } from "react";
import { RegionListProps } from "src/pages/Play/SelectRegions/Components/RegionList/types";
import Marker, { MarkerProps } from "./Marker";
import Region1 from "./regions/Region1";
import Region10 from "./regions/Region10";
import Region2 from "./regions/Region2";
import Region3 from "./regions/Region3";
import Region4 from "./regions/Region4";
import Region5 from "./regions/Region5";
import Region6 from "./regions/Region6";
import Region7 from "./regions/Region7";
import Region8 from "./regions/Region8";
import Region9 from "./regions/Region9";

export type MapProps = {
  regionList: RegionListProps[];
  handleRemoveRegion: (region: string) => void;
  disabledHover?: boolean;
} & Omit<MarkerProps, "regionData">;

export type RegionProps = MarkerProps;

const getRegion = (region: string, props: RegionProps) => {
  switch (region) {
    case "region-1":
      return <Region1 {...props} />;
    case "region-2":
      return <Region2 {...props} />;
    case "region-3":
      return <Region3 {...props} />;
    case "region-4":
      return <Region4 {...props} />;
    case "region-5":
      return <Region5 {...props} />;
    case "region-6":
      return <Region6 {...props} />;
    case "region-7":
      return <Region7 {...props} />;
    case "region-8":
      return <Region8 {...props} />;
    case "region-9":
      return <Region9 {...props} />;
    case "region-10":
      return <Region10 {...props} />;
    default:
      return <></>;
  }
};

const Map: React.FC<MapProps> = (props) => {
  const getReOrderedList = (
    regions: RegionListProps[],
    currentHoveredRegion: string | undefined
  ) => {
    const regionsClone = [...regions];
    if (currentHoveredRegion) {
      const hoveredRegionIndex = regions.findIndex(
        (region) => region.slug === currentHoveredRegion
      );
      const hoveredRegion = regionsClone.splice(hoveredRegionIndex, 1);
      const reordered = [...regionsClone, ...hoveredRegion];
      return reordered;
    } else {
      return regionsClone;
    }
  };

  useEffect(() => {
    props.handleHoveredRegion(props.hoveredCard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hoveredCard]);

  return (
    <svg
      viewBox="0 0 948 684"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      {getReOrderedList(props.regionList, props.currentHoveredRegion).map(
        (regionData) => {
          return getRegion(regionData.slug, {
            ...props,
            regionData: regionData,
          });
        }
      )}
      {props.regionList.map((regionData) => {
        return (
          <Marker
            key={regionData.id}
            {...props}
            regionData={regionData}
            handleRemoveRegion={props.handleRemoveRegion}
          />
        );
      })}
    </svg>
  );
};

export default Map;

import React from "react";
import Map from "src/pages/Play/SelectRegions/Components/RegionMap/Map";
import classes from "./RegionMap.module.scss";
import { staticRegionList } from "./data";

const RegionMap = () => {
  return (
    <div className={classes.wrapper}>
      <Map
        handleRemoveRegion={() => {}}
        handleRegionSelect={() => {}}
        handleHoveredRegion={() => {}}
        addedRegions={[]}
        regionSelectionFlow={false}
        hoveredCard={undefined}
        currentHoveredRegion={undefined}
        handleAddRegion={() => {}}
        regionList={staticRegionList as any}
        disabledHover
      />
    </div>
  );
};
export default RegionMap;

import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import TsgIcon from "src/assets/images/tgs-button.svg";
import Typography from "src/components/Typography";
import { APP_LINKS } from "src/helpers/constants";
import classes from "./AppDownload.module.scss";

interface Props {
  className?: string;
  hideTGSIcon?: boolean;
  downloadClassName?: string;
}

const AppDownload = ({
  className,
  hideTGSIcon,
  downloadClassName = "",
}: Props) => {
  return (
    <div
      className={cn(
        classes.downloadApp,
        {
          [classes.hideTGSIcon]: hideTGSIcon,
        },
        className
      )}
    >
      <div
        className={cn(classes.download, {
          [downloadClassName]: downloadClassName,
        })}
        onClick={() => {
          const win = window.open(APP_LINKS.APPLE, "_blank");
          win?.focus();
        }}
      >
        <FontAwesomeIcon icon={faApple} className={classes.icon} />
        <div>
          <Typography variant="p10" className="mb-0">
            DOWNLOAD ON THE
          </Typography>
          <Typography fontWeight="bold" variant="p16" className="mb-0">
            APP STORE
          </Typography>
        </div>
      </div>
      {!hideTGSIcon && (
        <div className={classes.tgsIconWrapper}>
          <img className={classes.tgsButton} src={TsgIcon} alt="img" />
        </div>
      )}
      <div
        className={cn(classes.download, {
          [downloadClassName]: downloadClassName,
        })}
        onClick={() => {
          const win = window.open(APP_LINKS.GOOGLE, "_blank");
          win?.focus();
        }}
      >
        <FontAwesomeIcon icon={faGooglePlay} className={classes.icon} />
        <div>
          <Typography variant="p10" className="mb-0">
            GET IT ON
          </Typography>
          <Typography fontWeight="bold" variant="p16" className="mb-0">
            GOOGLE PLAY
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AppDownload;

import ReleaseAssumption from "./ReleaseAssumption";
import TermContent from "./TermContent";
import Text from "./Text";

const AgreementTermContent = ({ isRisk = false }) => {
  return (
    <>
      {isRisk ? (
        <ReleaseAssumption />
      ) : (
        <>
          <h2>TREASURE GAME$ PARTICIPATION AGREEMENT</h2>
          <Text>
            By checking the Participation statement box at the Terms &
            Conditions section of the sign-up process to set up your TG$ account
            all subscribers acknowledge that they have read this Participation
            Agreement and will adhere to the following:
          </Text>
          <Text noMargin>ALL TG$ SUBSCRIBERS WILL:</Text>
          <ul>
            <li>Adhere to all items in the TG$ Terms & Conditions.</li>
            <li>Adhere to all items in this TG$ Participation Agreement.</li>
            <li>Adhere to all guidelines in The Game Play Rules.</li>
            <li>Adhere to TG$ Community Guidelines.</li>
            <li>Follow all Local, State, Federal Laws, and Regulations.</li>
          </ul>
          <Text noMargin>SUBSCRIBER REQUIREMENTS:</Text>
          <ul>
            <li>Subscribers Must be 13 years of age or older.</li>
            <li>
              Subscribers 13-17 must have parental consent to participate and
              make any purchases.
            </li>
            <li>
              Subscribers must be legal citizens of the country they are
              participating in or they must be in the country legally.
            </li>
            <li>
              Subscribers can play a game in one country from any country, but
              must show up in the country they won in to receive the prize or
              have a designated partner subscriber in the game country that will
              be awarded the prize.
            </li>
            <li>
              Subscribers can register 2 electronic devices per subscription
              account. Passwords are restricted to the 2 devices you select. You
              can change devices at any time in your account settings. Account
              Password sharing attempts beyond the 2 registered devices will be
              seen as a violation of the participation agreement and result in
              consequences.
            </li>
          </ul>
          <Text noMargin>GAME PLAY:</Text>
          <ul>
            <li>
              When any new game launches the amount of active treasure items in
              play may be released in a staggered time frame in relation to the
              number of subscribers and other factors. There will always be at
              least one treasure item in play when a new game launches, and the
              numbers of items may increase to the maximum item number planned
              for the game season as more people sign up.
            </li>
            <li>
              Each TG$ Game will have different rules, time frames, treasures,
              prizes, and other elements that subscribers must read, understand,
              and follow.
            </li>
            <li>
              Subscribers can work alone, as a team, family, etc...but only one
              subscriber will be awarded the prize.
            </li>
            <li>
              If a group or team starts to monopolize winnings the rules may be
              modified to create equity for more people to win. We want to
              spread wealth, therefore there are winner limitations of 1 Lamp
              per season, per subscriber. If a situation comes up where a group
              is finding more than 1 Lamp per season, we have the right to
              adjust the rules to make sure no one group is monopolizing the
              prize awards.
            </li>
            <li>
              Subscribers can post content about their treasure hunting
              adventures on our platform for others to enjoy and to win prizes.
            </li>
            <li>
              Subscribers can post game related content on their individual
              social media platforms, but cannot share direct information about
              the actual clues.
            </li>
            <li>
              All self-posted content related to the game must be appropriate as
              per the participation agreement and TG$ “Community Guidelines.”
            </li>
            <li>
              Some TG$ Games have additional “Advantage Clues” and “Hints” or
              other in app items that can be purchased as desired. Additional
              assistance type purchases are not necessary to win.
            </li>
            <li>
              Any minor age 13-17 purchasing additional items must have parental
              consent.
            </li>
            <li>
              Subscribers must read the game rules, participation agreement,
              terms and conditions, and the community guidelines to fully
              understand how to engage and interact with the game and the games
              entertainment platform.
            </li>
            <li>
              Elements of TG$ engagement are repeated throughout these documents
              in order to increase awareness and assure that subscribers are
              fully informed and act accordingly.
            </li>
            <li>
              Subscribe to the games you want to play and follow all the rules
              and agreements to be able to win. Enjoy the adventure.
            </li>
          </ul>
          <Text noMargin>SEARCH RULES - PROPERTY:</Text>
          <ul>
            <li>
              Subscribers must NEVER GO ONTO PRIVATE RESIDENTIAL PROPERTY as
              part of a TG$ game. No TG$ Treasure for any TG$ game will ever be
              hidden on Private Residential Property.
            </li>
            <li>
              Subscribers will NEVER GO ONTO PRIVATE COMMERCIAL PROPERTY WITHOUT
              PERMISSION FROM AN AUTHOROZED REPRESENTIVE.
            </li>
            <li>
              Subscribers will ONLY GO ONTO PUBLIC COMMERCIAL PROPERTY DURING
              HOURS THEY ARE OPEN TO THE PUBLIC.
            </li>
            <li>
              Subscribers will ONLY GO ONTO FEDERAL, STATE, OR LOCAL PROPERTY
              THAT IS OPEN TO THE PUBLIC AND WILL FOLLOW ALL PUBLIC LAND RULES.
            </li>
            <li>
              Subscribers will NEVER DAMAGE, DISTURB, OR DESTORY PROPERTY OR
              ITEMS/FEATURES ON PROPERTY BOTH MANMADE OR NATURAL.
            </li>
            <li>
              Subscribers will SHOW RESPECT AND CARE FOR ALL PROPERTY ACCESSED
              AS PART OF A TG$ GAME.
            </li>
          </ul>
          <Text noMargin>LAWS & BEHAVIOR:</Text>
          <ul>
            <li>
              Subscribers MUST FOLLOW ALL FEDERAL, STATE, AND LOCAL LAWS &
              ORDINANCES AT ALL TIMES.
            </li>
            <li>
              If TG$ is made aware of a severe offense or unlawful activity
              committed by a subscriber, TG$ may terminate their account. Such
              behavior may include: A conviction of a crime. Violence of any
              kind toward others or animals. Being a member of a terrorist or
              extremist group. Any behavior that threatens the safety and
              wellbeing of others. Any illegal or harmful behavior that is
              damaging to TG$ stated mission and goal.
            </li>
            <li>
              TG$ will not support or enable humans that display negative
              behaviors that are harmful to others.
            </li>
          </ul>
          <Text noMargin>CODE OF CONDUCT:</Text>
          <ul>
            <li>
              Subscribers will follow all agreements, terms, game play rules and
              guidelines at all times.
            </li>
            <li>
              TG$ Games are intended to be fun and uplifting. No misconduct of
              any kind including any behavior that is disruptive, illegal, or
              harmful in anyway will be tolerated, either in the game play or in
              posted content.
            </li>
            <li>
              Any Violators will be exposed and held accountable. ALL Violators
              will be subject to the TG$ penalty process.
            </li>
            <li>
              Any attempt or actual act of cheating or fraud will result in a
              lifetime ban and legal prosecution.
            </li>
          </ul>
          <Text noMargin>TG$ PENALTY PROCESS:</Text>
          <ul>
            <li>
              Violators of any TG$ rules and conditions will be confronted and
              held responsible for their actions.
            </li>
            <li>
              Penalties and consequences may include, but are not limited to,
              warnings, suspension, total bans, and legal prosecution.
            </li>
            <li>
              Beyond the Penalty Process, violators may be exposed in public
              communications to assure all subscribers, players, sponsors, and
              the public, that we are addressing the violation, take all
              violations seriously, and there will be consequences.
            </li>
          </ul>
          <Text noMargin>FILMING REQUIREMENTS:</Text>
          <ul>
            <li>
              <span>
                All Subscribers must film themselves finding the treasure and
                provide that footage to TG$ for promotional use and as part of
                the vetting process to be cleared and awarded the actual prize.
              </span>
              <br />
              The more subscribers film their game play process and treasure
              hunting activities the more efficient it will be for verifying any
              prize award approvals and the more fun for others to watch and
              enjoy.
            </li>
            <li>
              Subscribers that find a treasure may be required to participate in
              the filming of the awards for TG$ to use in promotions and any
              other way deemed by TG$.
            </li>
            <li>
              TG$ owns all the footage and rights that we film and that our
              subscribers upload to our platform or provide to us.
            </li>
            <li>
              All user generated content must adhere to our community
              guidelines.
            </li>
            <li>
              TG$ has the right to edit, modify, repost, and repurpose any and
              all user generated self-shot footage/video/images that our
              subscribers upload to our platform or provide to TG$, to use as
              TG$ sees fit.
            </li>
          </ul>
          <Text noMargin>CLAIMING A PRIZE:</Text>
          <ul>
            <li>
              Instructions for claiming any prize are to contact us through the{" "}
              <span>Contact Page</span>, by scanning a QR code found on the
              treasure or treasure container and or at winner@treasuregames.fun
            </li>
            <li>
              Prize finders must provide TG$ with their self-filmed footage of
              them finding the treasure.
            </li>
            <li>
              Once you find a treasure you must contact TG$ to be vetted and
              cleared to make sure all rules, terms and participation conditions
              were followed.
            </li>
            <li>
              Once a subscriber has been cleared as a legitimate winner they
              will be instructed on how and when they will receive their prize.
            </li>
            <li>
              Some items are the actual prize to keep and others are just
              symbols that must be returned when the actual prize is awarded.
              Winners may get a special TG$ item to keep and to show they are an
              official winner.
            </li>
            <li>
              All winners will sign an award release and if a minor wins the
              grand prize, they will need a legal guardian to sign off on the
              TG$ award release.
            </li>
            <li>
              Subscribers are responsible for any attached costs/expenses/legal
              responsibilities related to a prize award. E.g., taxes, insurance
              etc...
            </li>
          </ul>
          <Text noMargin>WEEKLY CONTEST & PRIZES:</Text>
          <ul>
            <li>
              As TG$ evolves we have a goal to conduct weekly contests with a
              variety of prizes.
            </li>
            <li>
              These contests will be announced on our platform with the specific
              rules, terms, and prizes.
            </li>
            <li>
              Winners will be announced on our platform. And footage of winners
              may be posted on TG$ social media and our platform.
            </li>
            <li>Prizes will be awarded after vetting by the TG$ team.</li>
          </ul>
          <Text noMargin>SAFETY:</Text>
          <ul>
            <li>
              All subscribers are expected to be safe and aware at all times. No
              one should get hurt during a TG$ game or as part of a TG$ related
              activity, but accidents can happen even walking down the street,
              so a safety mindset and self-awareness is expected at all times.
            </li>
            <li>
              Our games are intended to be fun; we want our Treasure Hunters to
              have great adventures, but we also want everyone to keep them and
              their loved ones safe at all times.
            </li>
            <li>No driving while looking at devices.</li>
            <li>No moving without looking where you are going.</li>
            <li>
              For all TG$ subscribers, “Safety First” is not a saying, it is a
              behavior. Act accordingly.
            </li>
            <li>
              DO Not engage in irresponsible, risky, or dangerous actions or
              behaviors.
            </li>
          </ul>
          {/* <Text noMargin>RIGHTS & RISK WAIVER:</Text>
      <ul>
        <li>
          By engaging with our products and services all account holders and
          subscribers agree to waive certain rights and assume all risk
          liabilities including, but not limited to the following.
        </li>
        <li>
          All TG$ Subscribersacknowledge and understand that there is inherent
          risk in any physical or mental activity, and they agree to indemnify
          and hold harmless TG$ and our subsidiaries regarding any risk and harm
          that may occur as a result of participating with a TG$ product,
          activity, or service.
        </li>
        <li>
          Subscribers acknowledge and agree that TG$ is not responsible for
          their actions or the actions of others that are not employed by TG$
          nor any events/factors that are outside of our control.
        </li>
        <li>
          Subscribers agree to not sue or pursue any legal actions/claims
          against TG$ in any form as a result of participating and engaging with
          our products and services.
        </li>
        <li>
          TG$ Subscribers are responsible for their own safety and actions at
          all times.
        </li>
        <li>
          Subscribers acknowledge that they have voluntarily and willingly
          chosen to engage with TG$ products and services and take full
          responsibility for their well being and they waive any right to file a
          legal claim against TG$ related to their engagement.
        </li>
        <li>
          Any legal dispute will be conducted in and subject to the laws of
          Delaware.
        </li>
        <li>
          For both safety, game play functions, and to ensure integrity of the
          game, subscribers allow TG$ to use geo location monitoring technology
          and tracking systems.
        </li>
      </ul> */}
          <Text noMargin>COMPLIANCE & ENFORCEMENT:</Text>
          <Text>
            There is a Code of Conduct expected when participating with our
            games that must be adhered to at all times. You must follow all laws
            and you must be respectful of others and all property.
          </Text>
          <Text>
            Subscribers can be suspended or banned from a game at any time for
            violating any part of our terms & conditions, participation
            agreement, rules of the game, community guidelines, privacy policy
            or any behavior that is disruptive, illegal, or harmful in anyway.
          </Text>
          <Text>
            TG$ monitors content via monitoring software, user reports, and a
            team of people dedicated to providing a fair game and family
            friendly service to our users. You acknowledge and agree to allow
            TG$ to investigate and verify your identity, and furthermore whether
            or not you were in violation of the Guidelines.
          </Text>
          <Text>
            TG$ reserves the right to terminate any account and remove any
            content that has violated the Guidelines, as determined in TG$’ sole
            discretion. TG$ also reserves the right to cancel or reverse any
            winnings won by a user who is in violation of the Guidelines, as
            determined in TG$’s sole discretion. If you are found to be in
            violation of the Guidelines, we may issue a warning, limit your
            gameplay experience, suspend your account, permanently terminate
            your account with or without a warning, or even ban you from using
            any TG$ Services with or without warning.
          </Text>
          <Text>
            TG$ may collect relevant information pertaining to whether a player
            has cheated, including your account information, your location data,
            your game data, data indicative of an unauthorized modification to
            TG$ software, and other pertinent information.
          </Text>
          <Text>
            Any form of game cheating will not be tolerated. Due to the value of
            the Lamps any form of cheating will be categorized as fraud and may
            lead to full prosecution and significant consequences. Don’t cheat,
            do not try to hack our system, do not try to win the game in any way
            that is not in full compliance with your rules and agreements.
          </Text>
          <Text>
            This is a new game and entertainment category that we are launching
            and as such there are situations and issues that we may not have
            thought of pre-launch, but we will address any issues as the come up
            as we evolve and we will update and modify our operating process,
            our products, our services, and our agreements as needed.
          </Text>
          <Text>
            For any questions or clarification, you can communicate with us
            through the <span>Contact Page</span>.
          </Text>
          <br />
          <Text>
            TG$ Mission: Spread more wealth to more people than any other
            company in the world.
          </Text>
          <br />
          <Text>
            TG$ Goal: Invest in People that are making the world better for all.
          </Text>
          <Text>
            Thank you for helping us to achieve our mission and goal and to keep
            this experience positive and safe for everyone involved.
          </Text>
          <br />
          <Text>
            Sign up, follow the rules, have fun, find the treasures, good luck!
          </Text>
          <br />
          <Text>TG$ Team</Text>
          <br />
          <TermContent />
        </>
      )}
    </>
  );
};

export default AgreementTermContent;

import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { UserSettingsData } from "src/api/auth";
import Button from "src/components/Button";
import Text from "src/components/Text";
import { useReducerData } from "src/store/hooks";
import { useActions } from "../Watch/selectorData";
import DeviceManagement from "./DeviceManagement";
import PaymentInformation from "./PaymentInformation";
import Preferences from "./Preferences";
import classes from "./Settings.module.scss";
import SubscriptionSettings from "./SubscriptionSettings";

export type FormProps = {
  notify_me_when_new_clue: boolean;
  notify_me_when_treasure_found: boolean;
  notify_me_when_new_contest: boolean;
  notify_me_when_new_announcement: boolean;
  email_preferences: boolean;
  privacy_settings: boolean;
  cookie_settings: boolean;
};

const Settings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const plans = String(searchParams.get("plans") || "");
  const isPlans = plans === "true";
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const { updateUserSettings, getUserSettings } = useActions();
  const userSettings: UserSettingsData = useReducerData(
    "auth",
    "userSettings.data",
    {}
  );
  const { loading } = useReducerData("auth", "userSettingsUpdate", {
    loading: false,
  });

  const settingsSchema = Yup.object().shape({
    notify_me_when_new_clue: Yup.boolean(),
    notify_me_when_treasure_found: Yup.boolean(),
    notify_me_when_new_contest: Yup.boolean(),
    notify_me_when_new_announcement: Yup.boolean(),
    email_preferences: Yup.boolean(),
    privacy_settings: Yup.boolean(),
    cookie_settings: Yup.boolean(),
  });

  useEffect(() => {
    getUserSettings();
    if (isPlans) {
      setOpenSubscriptionModal(true);
      searchParams.delete("plans");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Text color="#fff" size={30} fontFamily="milonga">
          Settings
        </Text>
      </div>
      <Formik
        onSubmit={async (values) => {
          const payload = {
            email_preferences: values.email_preferences,
            notification_preferences: {
              new_clue: values.notify_me_when_new_clue,
              promotions: values.notify_me_when_new_contest,
              treasure_found: values.notify_me_when_treasure_found,
              new_announcement: values.notify_me_when_new_announcement,
            },
            privacy_terms: values.privacy_settings,
            cookies: values.cookie_settings,
          };
          const res = await updateUserSettings(payload);
          const status = get(res, "status", 0);
          const message = get(res, "message", null);
          if (status) {
            toast.dark(message || "Settings successfully updated");
          } else {
            toast.error("Something went wrong");
          }
        }}
        enableReinitialize
        validationSchema={settingsSchema}
        initialValues={{
          notify_me_when_new_clue:
            userSettings?.notification_preferences?.new_clue || false,
          notify_me_when_treasure_found:
            userSettings?.notification_preferences?.treasure_found || false,
          notify_me_when_new_contest:
            userSettings?.notification_preferences?.promotions || false,
          notify_me_when_new_announcement:
            userSettings?.notification_preferences?.new_announcement || false,
          email_preferences: userSettings?.email_preferences || false,
          privacy_settings: userSettings?.privacy_terms || false,
          cookie_settings: userSettings?.cookies || false,
        }}
      >
        {({ values, handleChange, dirty, isValid }: FormikProps<FormProps>) => (
          <Form className={cx(classes.form)}>
            <div className={classes.cardsWrapper}>
              <DeviceManagement wrapperClassName={classes.card} />
              <Preferences
                wrapperClassName={classes.card}
                handleChange={handleChange}
                values={values}
              />
              <PaymentInformation wrapperClassName={classes.card} />
              <SubscriptionSettings
                wrapperClassName={classes.card}
                openSubscriptionModal={openSubscriptionModal}
                setOpenSubscriptionModal={setOpenSubscriptionModal}
              />
              <div className={cx(classes.wrapper, classes.card)}>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="/a/privacy-policy-ip-policy/"
                >
                  Privacy & Cookie Policies
                </a>
              </div>
            </div>
            <div className={classes.actions}>
              <Button
                buttonText="Save Profile"
                buttonColor="secondary"
                disabled={!isValid || !dirty}
                loading={loading}
                type="submit"
                buttonClassName={classes.submit}
              />
              <div className={classes.cancelWrapper}>
                <Button
                  buttonText={
                    <Text color="#fff" fontFamily="impact" size={32}>
                      Cancel
                    </Text>
                  }
                  // onClick={() => navigate("/user/profile")}
                  buttonColor="secondary"
                  variant="link"
                  buttonClassName={classes.cancel}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Settings;

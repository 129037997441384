import cn from "classnames";
import { useEffect, useState } from "react";
import { Input, Label, UncontrolledTooltip } from "reactstrap";
import { ReactComponent as CheckCircle } from "src/assets/icons/check-rounded-icon.svg";
import { ReactComponent as InfoCircleIcon } from "src/assets/icons/info-circle.svg";
import Button from "src/components/Button";
import CheckBox from "src/components/CheckBox";
import Modal from "src/components/Modal";
import classes from "./ReportModal.module.scss";
import { Option, REPORT_OPTIONS } from "./constants";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (option: string, comment: string, cb: () => void) => void;
  loading?: boolean;
}

const ReportModal = ({ isOpen, onClose, onSubmit, loading }: ModalProps) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [comment, setComment] = useState("");
  const [step, setStep] = useState(1);
  const isStep1 = step === 1;
  const resetState = () => {
    setSelectedOption("");
    setComment("");
    setStep(1);
  };

  useEffect(() => {
    if (isOpen) {
      resetState();
    }
  }, [isOpen]);

  return (
    <Modal
      backdrop
      className={cn(classes.modalWrapper, classes[`step_${step}`])}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      {isStep1 ? (
        <>
          <div className={classes.header}>
            <h3>Report Content</h3>
          </div>
          <div className={classes.content}>
            <ul>
              {REPORT_OPTIONS.map((option: Option, index: number) => {
                const tooltipKey = `Tooltip-${index}`;
                return (
                  <li key={index}>
                    <CheckBox
                      onChange={() => {
                        setSelectedOption(option.value);
                      }}
                      checked={selectedOption === option.value}
                      showOutline
                      label={
                        <span>
                          <span>{option.label}</span>
                          <span id={tooltipKey}>
                            <InfoCircleIcon />
                          </span>
                          <UncontrolledTooltip
                            target={tooltipKey}
                            className={classes.tooltip}
                            placement="right"
                          >
                            {option.tooltip}
                          </UncontrolledTooltip>
                        </span>
                      }
                      name={option.value}
                      isRadio
                    />
                  </li>
                );
              })}
            </ul>
            <div className={classes.input}>
              <Label>Additional Comments (Optional)</Label>
              <Input
                name="comment"
                type="textarea"
                placeholder="Write here..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            <span>
              Before Submitting a Report, please be aware that submitting a
              fraudulent or misleading report may lead to liability under
              applicable laws.
            </span>
          </div>
        </>
      ) : (
        <div className={classes.thankWrapper}>
          <CheckCircle />
          <h3>Thank you for reporting</h3>
          <p>
            Your report regarding the content has been submitted successfully.
            Our team will review the content and take appropriate action if
            necessary.
          </p>
        </div>
      )}

      <div className={classes.footer}>
        {isStep1 && (
          <Button
            buttonText="Cancel"
            buttonColor="secondary-light"
            weight="semibold"
            variant="link"
            onClick={onClose}
            disabled={loading}
          />
        )}
        <Button
          buttonText={isStep1 ? "Report" : "Close"}
          buttonColor="purple"
          weight="semibold"
          onClick={() => {
            if (isStep1) {
              onSubmit(selectedOption, comment, () => {
                setStep(2);
              });
            } else {
              onClose();
            }
          }}
          size="xs"
          disabled={isStep1 && !selectedOption}
          loading={loading}
        />
      </div>
    </Modal>
  );
};

export default ReportModal;

import classes from "./Profile.module.scss";

const Profile = ({ onClick = () => {}, avatarSrc = "" }) => {
  return (
    <div className={classes.wrapper}>
      <span onClick={onClick}>My Channel</span>
      {!!avatarSrc && <img src={avatarSrc} alt="profile-pic" />}
    </div>
  );
};

export default Profile;

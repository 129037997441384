export enum ENotificationType {
  NewCluesAvailable = "new_clues_available",
  NewAnnouncement = "new_announcement",
  TreasureFound = "treasure_found",
  EngagementUpdates = "engagement_update",
}
export type NotificationType =
  | ENotificationType.NewCluesAvailable
  | ENotificationType.NewAnnouncement
  | ENotificationType.TreasureFound
  | ENotificationType.EngagementUpdates;

export type NotificationData = {
  _id: string;
  title: string;
  type: NotificationType;
  view: number;
  body: string;
  created_at: string;
};

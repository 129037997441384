import React, { useEffect } from "react";
import { useLocation } from "react-router";

import AuthBreadcrumb from "src/components/AuthBreadcrumb";
import CreatePassword from "./CreatePassword";
import CreateUsername from "./CreateUsername";
import ForgotPassword from "./ForgotPassword";
import NewSignUp from "./NewSignUp";
import Payment from "./Payment";
import ProfilePage from "./Profile";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
// import Signup from "./Signup";
import TermsAndCondition from "./TermsAndCondition";

const Auth = () => {
  const { pathname } = useLocation();

  const getComponent = () => {
    switch (pathname) {
      case "/auth/signup":
        return <NewSignUp />;
      case "/auth/create-password":
        return <CreatePassword />;
      case "/auth/create-username":
        return <CreateUsername />;
      case "/auth/profile":
        return <ProfilePage />;
      case "/auth/risk-waiver":
        return <TermsAndCondition />;
      case "/auth/payment":
        return <Payment />;
      case "/auth/forgot-password":
        return <ForgotPassword />;
      case "/auth/reset-password":
        return <ResetPassword />;
      case "/auth/login":
      default:
        return <SignIn />;
    }
  };

  useEffect(() => {
    getComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPaymentPath = pathname === "/auth/payment";
  return (
    <div>
      {!isPaymentPath && <AuthBreadcrumb />}
      {getComponent()}
    </div>
  );
};

export default Auth;

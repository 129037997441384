import cn from "classnames";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { useIndexData } from "src/pages/Watch/selectorData";
import AvatarFollower from "../AvatarFollower";
import classes from "./Followers.module.scss";

const Followers: React.FC = () => {
  const currentPath = useCurrentPath();
  const {
    channelSlug = null,
    id = null,
    subChannelSlug,
    subChannelTitle,
  } = useParams();
  const { channelFollowers, channelFollowersLoading } = useIndexData();
  const userProfile = useUserProfile();
  const currentUserId = get(userProfile, "user.id", null);
  const channelLink = (username: string, userId: number, fullName: string) => {
    if (userId === currentUserId) {
      return "/watch/my-channel";
    }
    if (
      [
        "/watch/user-profile/:username/followers",
        "/watch/my-channel/followers",
      ].includes(currentPath)
    ) {
      return `/watch/user-profile/${username}`;
    }
    if (
      channelSlug ||
      currentPath === "/announcements/playing-media/:id/:name/:userId/followers"
    ) {
      const tUrl = currentPath
        .replace(":channelSlug", String(channelSlug))
        .replace("/followers", "")
        .replace(":name", fullName)
        .replace(":subChannelSlug", String(subChannelSlug))
        .replace(":subChannelTitle", String(subChannelTitle))
        .replace(":id", String(id))
        .replace(":userId", String(userId));
      return tUrl;
    }
    return "";
  };

  const sortedChannelFollowers = orderBy(
    channelFollowers,
    ["created_at"],
    ["desc"]
  );
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>Followers</div>
        <div className={classes.followersCount}>
          {channelFollowers?.length
            ? channelFollowers?.length?.toLocaleString()
            : 0}{" "}
          Followers
        </div>
      </div>
      <div
        className={cn(classes.followersList, {
          [classes.spinner]: channelFollowersLoading,
        })}
      >
        {channelFollowersLoading ? (
          <Spinner color="light" />
        ) : (
          sortedChannelFollowers?.map((follower, index) => {
            const { username, f_b, f_n } = follower;
            return (
              <Link
                to={channelLink(username, f_b, String(f_n))}
                key={index}
                className="text-decoration-none"
              >
                <AvatarFollower follower={follower} />
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Followers;

import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import heroBackground from "src/pages/PreviewPage/assets/images/hero-bg.png";
import JinImage from "src/pages/PreviewPage/assets/images/jin-image.png";
import Lamp from "src/pages/PreviewPage/assets/images/lamp.png";
import LampLogo from "src/pages/PreviewPage/assets/images/wish-lamp-logo.png";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./styles.module.scss";

interface Props {
  redirectSignupUrl?: string;
}

const HeroSection = ({ redirectSignupUrl = "" }: Props) => {
  const navigate = useNavigate();
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${heroBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "bottom",
      }}
    >
      <div className={classes.container}>
        <div className={classes.title}>
          {isMobile ? (
            <>
              <Typography
                fontStyle="italic"
                fontFamily="merriweather"
                fontWeight="black"
              >
                What if you could find a lamp
                <br />
                and get <span className={classes.lobster}>3 </span>
                Real Life Wishes...
                <br />
                Now you can!
              </Typography>
            </>
          ) : (
            <>
              <Typography
                fontStyle="italic"
                fontFamily="merriweather"
                fontWeight="black"
              >
                What if you could find a lamp and
              </Typography>
              <Typography
                fontStyle="italic"
                fontFamily="merriweather"
                fontWeight="black"
              >
                get <span className={classes.lobster}>3 </span>
                Real Life Wishes... Now you can!
              </Typography>
            </>
          )}
        </div>
        <div className={classes.wishLampImage}>
          <img className={classes.lampImage} src={Lamp} alt="lamp" />
          <div className={classes.button}>
            <Button
              onClick={() => navigate(redirectSignupUrl)}
              buttonText="JOIN NOW"
              buttonColor="secondary"
            />
          </div>
        </div>
        <div className={classes.wishLampText}>
          <img
            className={classes.lampTextImage}
            src={LampLogo}
            alt="lamp-logo"
          />
        </div>
        <div className={classes.info}>
          <div className={classes.jinWrapper}>
            <img className={classes.jinImage} src={JinImage} alt="jin" />
          </div>
          <div className={classes.content}>
            <div className={classes.cardsContainer}>
              <div className={classes.cardWrapper}>
                <div className={classes.card}>
                  <Typography
                    fontFamily="impact"
                    variant="p48"
                    className={classes.title}
                  >
                    PLAY
                  </Typography>
                  <Typography
                    fontFamily="arial"
                    fontWeight="bold"
                    variant="p32"
                    className={classes.description}
                  >
                    Treasure Hunters solve clues to find “Wish Lamp$” that are
                    hidden across the country that are worth <br />
                    <span className={classes.yellow}>
                      $1 Million in Cash
                    </span>{" "}
                    or <br />
                    <span className={classes.yellow}>
                      3 non cash Real Life Wishes.
                    </span>
                  </Typography>
                </div>
              </div>
              <div className={classes.cardWrapper}>
                <div className={classes.card}>
                  <Typography
                    fontFamily="impact"
                    variant="p48"
                    className={classes.title}
                  >
                    WATCH
                  </Typography>
                  <Typography
                    fontFamily="arial"
                    fontWeight="bold"
                    variant="p32"
                    className={classes.description}
                  >
                    Subscribers can also watch posted content from the Wish
                    Lamp$ Treasure Hunters who are vying to{" "}
                    <span className={classes.yellow}>win weekly prizes</span>{" "}
                    and entertain their followers.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bottomHero}>
            <Typography fontFamily="arial" fontWeight="bold">
              Play, Watch, or do Both.
            </Typography>
            <Typography
              className={classes.createAccountText}
              fontFamily="arial"
              fontWeight="bold"
            >
              Create your TG$ Account and then Subscribe to Wish Lamp$ to Get
              Started.
            </Typography>
            <Button
              onClick={() => navigate(redirectSignupUrl)}
              buttonColor="secondary"
              buttonText="Create TG$ Account"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

import cn from "classnames";
import { useEffect } from "react";
import classes from "./Snake.module.scss";
import { gameInit } from "./utils";

interface Props {
  onSuccess?: () => void;
  answer?: string;
  disabled?: boolean;
}

const Snake = ({ onSuccess, answer, disabled }: Props) => {
  useEffect(() => {
    const { setIntervalId } = gameInit({
      onRestart: () => {
        // restart event
      },
      onStart: () => {
        // start event
      },
      onScoreChanged: () => {
        // score changed event with score at param
      },
      onEnd: () => {
        // end event with score at param
      },
      onSuccess: disabled ? undefined : onSuccess,
      answer,
    });
    return () => {
      clearInterval(setIntervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className="play-board"></div>
      <div
        id="start_game"
        className={cn("success_popup", {
          hide: !!answer,
        })}
      >
        <h2>Play!</h2>
        <button id="bt_start_game" type="button">
          Start Game
        </button>
      </div>

      <div
        id="success_game"
        className={cn("success_popup", {
          hide: !answer,
        })}
      >
        <h2>Congratulations!</h2>
        <div>Here is your clue answer:</div>
        <div
          id="custom-game-answer"
          className={cn({
            getting: !answer,
          })}
        >
          {answer}
        </div>
      </div>

      <div id="fail_game" className="success_popup hide">
        <div id="error_message"></div>
        <button id="bt_restart_game" type="button">
          Try Again
        </button>
      </div>
    </div>
  );
};

export default Snake;

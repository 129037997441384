import cn from "classnames";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import HomeIcon from "src/assets/icons/home-icon.svg";
import IDIcon from "src/assets/icons/id-icon.svg";
import LogInIcon from "src/assets/icons/login-icon.svg";
import LogOutIcon from "src/assets/icons/logout-icon.svg";
import Settings from "src/assets/icons/settings-icon.svg";
import UserIcon from "src/assets/icons/user-icon.svg";
import Text from "src/components/Text";
import { isLoggedIn } from "src/utils/utils";
import classes from "./UserDropdown.module.scss";
import { useActions } from "./selectorData";

interface Props {
  children: React.ReactNode;
  className?: string;
  notAlign?: boolean;
}

const UserDropdown = ({ children, className, notAlign }: Props) => {
  const { logoutUser } = useActions();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const isLogIn = isLoggedIn();
  const { pathname } = useLocation();
  const isMySubscriptions = pathname === "/subscriptions";
  const isUserDeviceBlock = pathname === "/user/device-blocked";
  const displayHome = isMySubscriptions || isUserDeviceBlock;

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleRedirect = () => {
    if (isLogIn) {
      logoutUser();
    }
    navigate("/auth/login");
  };

  return (
    <Dropdown
      className={cn(
        classes.dropdown,
        {
          [classes.notAlign]: notAlign,
        },
        className
      )}
      persist={true}
      active
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle onClick={toggle} className={classes.actionButton}>
        {children}
      </DropdownToggle>
      <DropdownMenu className={classes.menu} end>
        <DropdownItem
          onClick={() =>
            navigate(isLogIn ? (!displayHome ? "/subscriptions" : "/") : "/")
          }
          className={classes.item}
        >
          <img src={isLogIn ? IDIcon : HomeIcon} alt="home-icon" />
          <Text size={20} color="#fff">
            {isLogIn ? (!displayHome ? "My Subscriptions" : "Home") : "Home"}
          </Text>
        </DropdownItem>
        {isLogIn && (
          <>
            <DropdownItem
              onClick={() => navigate("/user/profile")}
              className={classes.item}
            >
              <img src={UserIcon} alt="user-icon" />
              <Text size={20} color="#fff">
                Profile
              </Text>
            </DropdownItem>
            <DropdownItem
              onClick={() => navigate("/user/settings")}
              className={classes.item}
            >
              <img src={Settings} alt="user-icon" />
              <Text size={20} color="#fff">
                Settings
              </Text>
            </DropdownItem>
          </>
        )}
        <DropdownItem onClick={handleRedirect} className={classes.item}>
          <img src={isLogIn ? LogOutIcon : LogInIcon} alt="user-icon" />
          <Text size={20} color="#fff">
            {isLogIn ? "Log out" : "Sign In"}
          </Text>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserDropdown;

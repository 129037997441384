import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import useCurrentPath from "src/helpers/hooks/useCurrentPath";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import * as mediaActions from "src/store/actions/media";
import * as watchActions from "src/store/actions/watch";
import { useStoreActions } from "src/store/hooks";
import { actionCb } from "src/utils/action";

const useActions = () => {
  return useStoreActions({
    ...mediaActions,
    ...watchActions,
  });
};

const useMediaDetailsInit = () => {
  const params = useParams();
  const { pathname } = useLocation();
  const currentPath = useCurrentPath();
  const navigate = useNavigate();
  const channelSlug = String(params?.channelSlug || "");
  const mediaId = String(params?.id || "");
  const tComparePathName = currentPath || pathname;
  const isEngagementMediaDetails =
    tComparePathName === "/engagement/playing-media/:id";
  const isAnnouncementsMediaDetails =
    tComparePathName === "/announcements/playing-media/:id";
  const isUserDetailsPage = tComparePathName?.includes(":id/:name/:userId");
  const isMediaDetailPage =
    pathname.includes("/playing-media/") && !isUserDetailsPage;
  const { getMediaDetail, getMediaRelated } = useActions();
  const userProfile = useUserProfile();
  const tChannelSlug = isEngagementMediaDetails
    ? "engagements"
    : isAnnouncementsMediaDetails
    ? "announcements"
    : channelSlug;
  const handleGetMediaRelated = (search?: string) => {
    getMediaRelated(tChannelSlug, mediaId, userProfile.user?.id, search);
  };

  useEffect(() => {
    if (mediaId && isMediaDetailPage) {
      getMediaDetail(
        mediaId,
        actionCb(null, () => {
          navigate("/not-found");
        })
      );
      handleGetMediaRelated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaId]);

  return { handleGetMediaRelated };
};

export default useMediaDetailsInit;

import { createTypes } from "./createTypes";

export default createTypes(
  "SYNC_SET_USER_NOTIFICATION_COUNT",
  "SYNC_SET_VIEW_NOTIFICATION",
  "GET_USER_NOTIFICATIONS",
  "GET_USER_NOTIFICATION_COUNT",
  "VIEW_USER_NOTIFICATION",
  "OPEN_USER_NOTIFICATION",
  "CLEAR_USER_NOTIFICATION"
);

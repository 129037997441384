import cx from "classnames";
import React, { useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Dropdown, DropdownMenu } from "reactstrap";
import { ClueProps } from "src/pages/Play/Clue/types";
import { getClueStatus } from "src/pages/Play/Clue/utils";
import { editClueStatus } from "src/store/actions/clue";
import { useStoreActions } from "src/store/hooks";

import { useClueActions } from "src/utils/useActions";
import ClueCard from "./ClueCard";
import ClueFilter, { FilterProps } from "./ClueFilter";
import classes from "./styles.module.scss";

type Props = {
  allFound: boolean;
  clues: ClueProps[];
};

const ClueGridView: React.FC<Props> = ({ allFound, clues }) => {
  const [searchParams] = useSearchParams();
  const regionId = searchParams.get("region") || "region-1";
  const season = searchParams.get("season") || "1";
  const actions = useStoreActions({ editClueStatus });
  const { getClues } = useClueActions();

  const [initialFilter, setInitialFilter] = useState<FilterProps>({
    type: {
      image: false,
      video: false,
      audio: false,
      riddle: false,
      word_search: false,
      puzzle: false,
    },
    dateRange: [null, null],
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggle = () => {
    const popoverElement = document.getElementById("uncontrolled-date-popover");
    if (popoverElement) {
      return;
    } else {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const toggleFilter = () => setDropdownOpen(false);

  const handleClueUpdateForCard = async (clue: ClueProps) => {
    const updatedStatus = [
      {
        id: clue.id,
        is_current: true,
        status: getClueStatus(clue.answer_status),
      },
    ];
    await actions.editClueStatus(updatedStatus);
    await getClues(`${regionId}`, { season });
  };

  return (
    <div id="grid-view-root" className={classes.viewWrapper} ref={dropdownRef}>
      <div className={classes.filter}>
        <Dropdown persist={true} active isOpen={dropdownOpen} toggle={toggle}>
          {/* <DropdownToggle
            onClick={() => {
              // e.stopPropagation();
              setDropdownOpen((prevState) => !prevState);
            }}
            className={cx(classes.filterButton, {
              [classes.filterButtonActive]: dropdownOpen,
            })}
          >
            <Typography className="mb-0" fontWeight="medium" variant="p20">
              Filter 
            </Typography>
            <FilterIcon />
          </DropdownToggle> */}
          <DropdownMenu end>
            <ClueFilter
              toggleFilter={toggleFilter}
              initialFilter={initialFilter}
              setInitialFilter={setInitialFilter}
            />
          </DropdownMenu>
        </Dropdown>
      </div>
      <div
        className={cx(classes.cardsWrapper, {
          [classes.disable]: allFound,
        })}
      >
        <div className={classes.cards}>
          {clues.length !== 0 ? (
            clues.map((clue, index) => {
              return (
                <ClueCard
                  key={index}
                  index={index}
                  clue={clue}
                  handleClueUpdateForCard={handleClueUpdateForCard}
                />
              );
            })
          ) : (
            <div className={classes.noData}>No clue found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClueGridView;

import cn from "classnames";

import get from "lodash/get";
import { useEffect, useState } from "react";
import { ModalHeader } from "reactstrap";
import { PaymentMethodsProps } from "src/api/auth";
import { ReactComponent as CheckIcon } from "src/assets/icons/check-rounded-icon.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import { ReactComponent as PaymentSuccess } from "src/assets/icons/payment-success-icon.svg";
import { ReactComponent as PlusCircle } from "src/assets/icons/plus-outline-circle-icon.svg";
import Button from "src/components/Button";

import CheckBox from "src/components/CheckBox";
import Modal from "src/components/Modal";

import Text from "src/components/Text";
import { ClueProps } from "src/pages/Play/Clue/types";
import { getPaymentMethods } from "src/store/actions/auth";

import { purchaseHint, purchaseHintList } from "src/store/actions/clue";
import { useReducerData, useStoreActions } from "src/store/hooks";
import PaymentForm from "./PaymentForm";
import classes from "./PurchaseModal.module.scss";

interface Props {
  className?: string;
  answerIndex: string;
  isOpen: boolean;
  onClose: () => void;
  clue: ClueProps;
}

const PurchaseModal = ({
  className = "",
  isOpen,
  onClose,
  clue,
  answerIndex,
}: Props) => {
  const actions = useStoreActions({
    purchaseHint,
    purchaseHintList,
    getPaymentMethods,
  });
  const {
    data: paymentMethods,
  }: { data: PaymentMethodsProps[]; loading: boolean } = useReducerData(
    "auth",
    "paymentMethods",
    {
      data: [],
    }
  );

  const [agreed, setAgreed] = useState(false);
  const [addNewPaymentFlow, setAddNewPaymentFlow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodsProps>();

  useEffect(() => {
    const defaultMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod.is_default === 1
    );
    setSelectedPaymentMethod(defaultMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  const toggleFlow = () => setAddNewPaymentFlow(!addNewPaymentFlow);

  const handleChange = (e: any) => {
    setAgreed(e.target.checked);
  };

  const handlePurchaseClue = async () => {
    setLoading(true);
    const payload = {
      hint_number: answerIndex,
      payment_id: selectedPaymentMethod?.id?.toString() || "",
      charge_amount: "1.99",
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await actions.purchaseHint(clue.id, payload);
    const status = get(res, "status", 0);
    if (status) {
      setShowSuccess(true);
    }
    setLoading(false);
  };

  const handleViewHint = () => {
    actions.purchaseHintList(clue.id);
    onClose();
  };

  return (
    <>
      <Modal
        backdrop
        className={cn(classes.modalWrapper, className)}
        bodyClassName={classes.modalBody}
        isOpen={isOpen}
        toggle={onClose}
      >
        {loading ? (
          <div className={classes.paymentLoading}>
            <Text size={24} fontWeight="semibold">
              Please wait while we process your payment...
            </Text>
          </div>
        ) : (
          <>
            <ModalHeader
              close={
                <Button
                  buttonText="Close"
                  rightIcon={<CloseIcon />}
                  variant="link"
                  onClick={showSuccess ? handleViewHint : onClose}
                />
              }
            ></ModalHeader>
            {showSuccess ? (
              <div className={classes.success}>
                <Text color="#fff" size={24} fontWeight="semibold">
                  Purchase Successful!
                </Text>
                <Text color="#fff" size={16}>
                  Proceed to the Clue page to view hint.
                </Text>
                <PaymentSuccess />
                <div className={classes.button}>
                  <Button
                    buttonText={
                      <Text
                        color="#fff"
                        size={24}
                        fontFamily="inter"
                        fontWeight="bold"
                      >
                        View Hint
                      </Text>
                    }
                    type="submit"
                    // loading={loading}
                    onClick={handleViewHint}
                    className={classes.viewHint}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={classes.title}>
                  <Text size={30} fontWeight="semibold">
                    Purchase Hint for Answer {answerIndex}
                  </Text>
                  <Text size={30} fontWeight="semibold">
                    $1.99
                  </Text>
                </div>
                <div className={classes.info}>
                  <Text size={14} fontWeight="semibold">
                    Important Information
                  </Text>
                  <Text size={14}>
                    Hints are available for purchase for some clues. Purchases
                    will be billed to the payment method linked to your account
                    unless otherwise specified here. Minors must have parental
                    consent to make purchases on our platform.
                  </Text>
                </div>
                {addNewPaymentFlow ? (
                  <div>
                    <Text className="mb-1" size={14} fontWeight="semibold">
                      Payment Details
                    </Text>
                    <PaymentForm toggleFlow={toggleFlow} />
                  </div>
                ) : (
                  <>
                    <div className={classes.paymentDetails}>
                      <Text className="mb-1" size={14} fontWeight="semibold">
                        Payment Details
                      </Text>
                      <div className={classes.paymentWrapper}>
                        {paymentMethods.map((paymentMethod, i) => {
                          return (
                            <div
                              onClick={() =>
                                setSelectedPaymentMethod(paymentMethod)
                              }
                              key={i}
                              className={classes.existingCard}
                            >
                              <div>
                                <Text
                                  style={{ textTransform: "uppercase" }}
                                  fontWeight="semibold"
                                  size={18}
                                >
                                  {paymentMethod?.brand}{" "}
                                  <span style={{ textTransform: "none" }}>
                                    {paymentMethod.is_default
                                      ? "(Default)"
                                      : ""}
                                  </span>
                                </Text>
                                <Text size={14}>
                                  Credit card ending in {paymentMethod?.last4}
                                </Text>
                              </div>
                              {selectedPaymentMethod?.id ===
                                paymentMethod.id && <CheckIcon />}
                            </div>
                          );
                        })}
                      </div>

                      <div
                        onClick={() => setAddNewPaymentFlow(true)}
                        className={classes.addNew}
                      >
                        <PlusCircle />
                        <Text size={14}>Add a new payment method</Text>
                      </div>
                    </div>
                    <div className={classes.terms}>
                      <CheckBox
                        id="rules"
                        onChange={handleChange}
                        className="sas"
                        checked={agreed}
                        label={
                          <Text>
                            I understand the{" "}
                            <Button
                              variant="link"
                              buttonText="Terms & Conditions"
                            />{" "}
                            of purchasing hints.
                          </Text>
                        }
                        name="privacyPolicy"
                      />
                    </div>
                    <div className={classes.button}>
                      <Button
                        buttonText={
                          <Text
                            color="#fff"
                            size={24}
                            fontFamily="inter"
                            fontWeight="bold"
                          >
                            Purchase Hint
                          </Text>
                        }
                        type="submit"
                        disabled={!agreed}
                        // loading={loading}
                        onClick={handlePurchaseClue}
                        className={classes.submit}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default PurchaseModal;

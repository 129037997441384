import cx from "classnames";
import get from "lodash/get";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ModalHeader } from "reactstrap";
import { PaymentMethodsProps } from "src/api/auth";
import { ReactComponent as CloseIcon } from "src/assets/icons/close-icon.svg";
import { ReactComponent as PlusCircle } from "src/assets/icons/plus-outline-circle-icon.svg";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import PaymentForm, { PaymentHandleProps } from "src/components/PaymentForm";
import Text from "src/components/Text";
import {
  addPaymentMethods,
  getPaymentMethods,
  removePaymentMethod,
} from "src/store/actions/auth";

import { useReducerData, useStoreActions } from "src/store/hooks";

import classes from "./PaymentInformation.module.scss";

type Props = {
  wrapperClassName?: string;
};

const PaymentInformation: React.FC<Props> = ({ wrapperClassName = "" }) => {
  const actions = useStoreActions({
    addPaymentMethods,
    getPaymentMethods,
    removePaymentMethod,
  });
  const { data: paymentMethods }: { data: PaymentMethodsProps[] } =
    useReducerData("auth", "paymentMethods", {
      data: [],
    });
  const { loading } = useReducerData("auth", "removePaymentMethod", {
    loading: false,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [removePaymentMethodModal, setRemovePaymentMethodModal] = useState({
    isOpen: false,
    currentPaymentMethodId: 0,
  });

  const onClose = () => setIsOpen(false);

  const handlePaymentUpdate = async (paymentInfo: PaymentHandleProps) => {
    const paymentDetails = {
      payment_method_id: paymentInfo?.id,
      is_default: paymentInfo.is_default,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = await actions.addPaymentMethods(paymentDetails);
    const status = get(res, "status", 0);
    const message = get(res, "data.message", null);
    if (status) {
      toast.dark(message);
      onClose();
    }
  };

  const handleRemovePaymentMethod = async () => {
    const resAction = (response: any) => {
      const status = get(response, "status", 0);
      const message = get(response, "message", null);
      if (status) {
        toast.dark(message);
        setRemovePaymentMethodModal({
          isOpen: false,
          currentPaymentMethodId: 0,
        });
      } else {
        toast.error("Something Went Wrong!");
        setRemovePaymentMethodModal({
          isOpen: false,
          currentPaymentMethodId: 0,
        });
      }
    };

    actions.removePaymentMethod(
      removePaymentMethodModal.currentPaymentMethodId,
      resAction
    );
  };

  return (
    <>
      {isOpen ? (
        <Modal
          backdrop
          className={cx(classes.modalWrapper)}
          bodyClassName={classes.modalBody}
          isOpen={isOpen}
          toggle={onClose}
        >
          <ModalHeader
            close={
              <Button
                buttonText=""
                rightIcon={<CloseIcon />}
                variant="link"
                onClick={onClose}
              />
            }
          ></ModalHeader>
          <div className={classes.modalHeader}>
            <Text color="#fff" size={40} fontFamily="milonga">
              Payment Information
            </Text>
            <Text color="#fff" size={24}>
              Enter your debit or credit information
            </Text>
          </div>

          <PaymentForm handlePayment={handlePaymentUpdate} />
        </Modal>
      ) : null}
      {removePaymentMethodModal.isOpen ? (
        <Modal
          backdrop
          className={cx(classes.closeModalWrapper)}
          bodyClassName={classes.modalBody}
          isOpen={removePaymentMethodModal.isOpen}
          toggle={() =>
            setRemovePaymentMethodModal({
              isOpen: false,
              currentPaymentMethodId: 0,
            })
          }
        >
          <div className={classes.modalHeader}>
            <Text color="#fff" className="mb-4" size={40} fontFamily="milonga">
              Remove Card
            </Text>
            <Text color="#fff" size={24}>
              Are you sure you want to remove this method of payment?
            </Text>
          </div>
          <div className={classes.actions}>
            <Button
              buttonText={
                <Text
                  color="#fff"
                  fontWeight="bold"
                  fontFamily="impact"
                  size={36}
                >
                  Delete
                </Text>
              }
              buttonColor="secondary"
              onClick={() => handleRemovePaymentMethod()}
              // disabled={!isValid || !dirty}
              loading={loading}
              type="submit"
              buttonClassName={classes.submit}
            />
            <div className={classes.cancelWrapper}>
              <Button
                buttonText={
                  <Text color="#fff" fontFamily="impact" size={36}>
                    Cancel
                  </Text>
                }
                onClick={() =>
                  setRemovePaymentMethodModal({
                    isOpen: false,
                    currentPaymentMethodId: 0,
                  })
                }
                buttonColor="secondary"
                variant="link"
                buttonClassName={classes.cancel}
              />
            </div>
          </div>
        </Modal>
      ) : null}
      <div className={cx(classes.wrapper, wrapperClassName)}>
        <Text color="#fff" size={24}>
          View Update Payment Information
        </Text>
        <Text className="mb-3" size={12} color="#fff">
          You may save up to 3 cards
        </Text>
        <div className={classes.paymentWrapper}>
          {paymentMethods?.map((paymentMethod, i) => {
            return (
              <div
                // onClick={() => setSelectedPaymentMethod(paymentMethod)}
                key={i}
                className={classes.existingCard}
              >
                <div>
                  <Text
                    style={{ textTransform: "uppercase" }}
                    fontWeight="bold"
                    size={18}
                  >
                    {paymentMethod?.brand}{" "}
                    <span style={{ textTransform: "none" }}>
                      {paymentMethod.is_default ? "(Default)" : ""}
                    </span>
                  </Text>
                  <Text size={14}>
                    Credit card ending in {paymentMethod?.last4}
                  </Text>
                </div>
                <div className={classes.action}>
                  <Button
                    buttonText={
                      <Text size={18}>
                        <u>Remove</u>
                      </Text>
                    }
                    onClick={() => {
                      setRemovePaymentMethodModal({
                        isOpen: true,
                        currentPaymentMethodId: paymentMethod.id,
                      });
                    }}
                    buttonColor="secondary"
                    variant="link"
                    buttonClassName={classes.cancel}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div onClick={() => setIsOpen(true)} className={classes.addNew}>
          <PlusCircle />
          <Text color="#fff" size={18}>
            Add a new payment method
          </Text>
        </div>
      </div>
    </>
  );
};

export default PaymentInformation;

import { createTypes } from "./createTypes";

export default createTypes(
  "SYNC_UPDATE_WATCH_USER",
  "SYNC_RESET_WATCH_USER_FEED_META",
  "SYNC_RESET_WATCH_USER_REPOST_FEED_META",
  "GET_WATCH_USER",
  "GET_WATCH_CHANNEL",
  "GET_WATCH_SUB_CHANNEL",
  "GET_WATCH_USER_POSTS",
  "GET_WATCH_USER_FEED",
  "GET_WATCH_USER_REPOST_FEED",
  "UPDATE_WATCH_USER",
  "GET_CHANNEL_FOLLOWERS",
  "UPDATE_CHANNEL_FOLLOW_STATUS"
);

/* eslint-disable max-lines */
import Text from "./Text";
import classes from "./styles.module.scss";

const TermContent = () => {
  return (
    <>
      <div className={classes.termContent}>
        <h2>Terms of Service</h2>
        <Text>Last Updated: Oct 16, 2023</Text>
        <br />
        <Text>Table of Contents</Text>
        <ul>
          <li>Terms</li>
          <li>Who May Use the Services</li>
          <li>Your Account</li>
          <li>Permissions</li>
          <li>Conduct and General Prohibitions</li>
          <li>Intellectual Property Rights</li>
          <li>Content</li>
          <li>Changing and Removing User Content</li>
          <li>Privacy</li>
          <li>Modification and Loss of Services</li>
          <li>Feedback</li>
          <li>Hyperlinks</li>
          <li>Indemnity</li>
          <li>Warranty Disclaimer</li>
          <li>Limitation of Liability</li>
          <li>Dispute Resolution</li>
          <li>Game Winner Requirements</li>
          <li>Prizes</li>
          <li>General</li>
        </ul>
        <br />
        <br />
        <Text weight={600}>1. Terms</Text>
        <Text>
          1.1. Introduction. Welcome to Treasure Game$, LLC (“TG$”). We provide
          adventure and entertainment through real-world, live-action, and
          technologically driven treasure hunts.
          <br />
          Thank you for adventuring with us to make our world a better place.
          <br />
          Applicable Terms. Please read these Terms of Service, the Privacy
          Policy, Intellectual
        </Text>
        <Text>
          1.2. Property Policy, Participation Agreement, Community Guidelines,
          and any applicable Game Rules (collectively, the “Agreement”) because
          the Agreement governs your access to, relationship, use, and
          interactions with TG$.
        </Text>
        <Text>
          1.3. TG$ Services. TG$ and its affiliates provide a range of products
          and services which are subject to the Agreement. The Agreement governs
          your use of the TG$ App, TG$ websites which we own or operate
          (“Sites), TG$ controlled social media pages, your participation at TG$
          events or promotions, your purchase or use of any TG$ products, and
          more generally your use of any TG$ products or services (collectively,
          termed as a whole, the “Services”).
        </Text>
        <Text>
          1.4. Acceptance. This Agreement serves as a legally binding contract
          between you and TG$.
          <br />
          Your use of the TG$ services or products in any way signifies that you
          accept and agree to this Agreement. Please read the Agreement
          carefully and make sure you understand it. If you do not understand
          the Agreement, or do not accept the Agreement, or any part thereof,
          then you must not use TG$ services or products.
        </Text>
        <Text>
          1.5. Changes. TG$ will, in its sole discretion, amend this Agreement
          from time to time. We will use commercially reasonable efforts to
          generally notify all users of any material changes to this Agreement.
          TG$ may notify you by posting any changes or updates on its App or
          Sites.
          <br />
          We will also update the “Last Updated” date. After any changes, your
          continued use of our
          <br />
          Services constitutes acceptance of the new Agreement. If you do not
          agree to the new Agreement, you must stop accessing or using TG$
          Services.
        </Text>
        <Text>
          1.6. Arbitration Notice in Section 16. FOR USERS IN THE UNITED STATES,
          THESE TERMS CONTAIN A BINDING ARBITRATION CLAUSE AND CLASS ACTION
          WAIVER THAT
          <br />
          AFFECT YOUR LEGAL RIGHTS. Note: depending on where you reside, you may
          have rights under applicable local laws that cannot be limited or
          waived, and this Agreement does not limit any such rights.
        </Text>
        <br />
        <Text weight={600}>2. Who May Use the Services</Text>
        <Text>
          2.1. Age Requirement. You must be at least 13 years old to use the
          Services.
        </Text>
        <Text>
          2.2. Permission by Parent or Guardian. If you are under 18, you may
          only use the Services with the consent of your parent or legal
          guardian. You represent that you have your parent or legal guardian’s
          permission to use the Services. Please have them read this Agreement
          with you. If you are a parent or legal guardian of a user under the
          age of 18, you are subject to the terms of this Agreement and
          responsible for the user’s activity on the Services. If you are under
          18, you must have the permission of your parent or legal guardian to
          purchase any and all products or services offered by TG$.
        </Text>
        <Text>
          2.3. Costs to Use TG$ Services. You agree that TG$ is not responsible
          for any of your expenditures, including internet and telecommunication
          connectivity, travel cost, accommodations, and incidentals, which may
          or may not be necessary in order to play TG$ games and otherwise
          access or use the Services. You are solely responsible for obtaining
          and paying for any and all internet and telecommunication connectivity
          to the Services. You are responsible for any charges that may apply to
          your use of our Services, including but not limited to any data
          charges.
        </Text>
        <Text>
          2.4. Residency. You must reside in an eligible country or region where
          TG$ offers its Services.
        </Text>
        <Text>
          2.5. Prohibited Users. TG$ prohibits certain individuals from using
          its Services, including employees of TG$, TG$ affiliates,
          subsidiaries, representatives, agencies, vendors, former employees who
          participated in the development and/or implementation of the Services,
          and/or immediate family members or persons living in the same
          household of such ineligible individuals.
        </Text>
        <br />
        <Text weight={600}>3. Your Account</Text>
        <Text>
          3.1. Registration. To use our Services, you must create an account
          with us. All information provided during account creation must be
          accurate and current. You agree to promptly notify us if any of your
          information changes or is inaccurate. You may not use someone else’s
          information or any information that violates someone else’s rights, is
          against the law, or is offensive or otherwise objectionable.
        </Text>
        <Text>
          3.2. Account Security. You agree to keep your account password
          confidential and to not disclose your account password to anyone. You
          must notify us immediately if you know or suspect that any third party
          knows your password or has accessed your account.
        </Text>
        <Text>
          3.3. Account Responsibility. You understand and agree that you are
          solely responsible, to TG$ and others, for the activity under your
          account, whether or not you know about such activity.
        </Text>
        <Text>
          3.4. Account Termination. TG$ has the right to deny the creation of,
          suspend for a period of time, or permanently terminate an account for
          any reason in its sole discretion and without notice. If TG$ suspends
          or terminates your account, you may not re-register or otherwise
          continue to use the Services. You may terminate your account at any
          time by requesting your account be closed and deleted through our
          contact page. Cancellation is effective at the end of your current
          subscription period. For the annual plan, cancellation is effective at
          the end of the 12-month period. For the monthly plan, cancellation is
          effective at the end of 30 day cycle you are currently in when you
          cancelled your account. Once your account is terminated, you will not
          be able to reactive your account or retrieve any of the content or
          information you have added to the Services. Upon account termination,
          the terms of this Agreement will survive and you will remain bound to
          them. Please write to us through the TG$ contact page for additional
          instructions on canceling your account.
        </Text>
        <Text>
          3.5. User Devices. You may register up to two (2) electronic devices
          for your account. You can manage your registered electronic devices,
          including changing, adding, or removing devices, in your account
          settings at any given time.
        </Text>
        <br />
        <Text weight={600}>4. Permissions</Text>
        <Text>
          4.1. Access. The Services are offered only for your personal,
          non-commercial use, and not for the use, sale, or benefit of any
          third-party. You may access and use the Services as long as you comply
          with this Agreement and applicable laws, including the Game Rules.
        </Text>
        <Text>
          4.2. Limited License to Use. Subject to your compliance with this
          Agreement, and subject to our permission, TG$ grants to you a
          revocable, limited, nonexclusive, non-transferable, non-sublicensable,
          non-assignable license to use the Services for your personal,
          non-commercial use only. TG$ reserves all rights in and to the
          Services not expressly granted in this Agreement. You may:
          <ul>
            <li>
              (a) have your computer temporarily store copies of requisite
              information in its memory incidental to your access and use of the
              Services;
            </li>
            <li>
              (b) store files that are automatically cached by your internet
              browser;
            </li>
            <li>
              (c) download a single copy of the TG$ App for your computer and a
              single copy of the TG$ App for your mobile device, provided you
              agree to be bound by our end user license agreement for the TG$
              App; and
            </li>
            <li>
              (d) if TG$ provides social media features, you may take such
              actions as are enabled by such features to access and use the
              social media features.
            </li>
          </ul>
        </Text>
        <Text>
          4.3. TG$’s Ownership of Commercial Rights. All commercial rights
          relating to the Services, including TG$ live-action games, are
          reserved for TG$. Users of the Services, including players of the
          live-action games, shall not associate themselves with the Services in
          any commercial manner, or allow any third party to do so, without the
          prior explicit and sole consent of TG$.
          <br />
          If you wish to engage in any commercial activities related to the
          Services, you must contact TG$ and obtain its explicit consent.
        </Text>
        <br />
        <Text weight={600}>5. Conduct and General Prohibitions</Text>
        <Text>
          5.1. Code of Conduct. TG$ Services are for every eligible human,
          regardless of any physical, mental, spiritual, socio-economic, or
          other grouping. You agree to be kind and respect the rights of others.
          You agree that you are responsible for your own conduct and User
          Content while using the Services, and for any consequences thereof.
          You agree that your use of the Services is at your own risk and at
          your own expense. You agree that you will not use the Services to
          violate any applicable law, regulation, any TG$ policy or rule, or
          instructions as outlined in this Agreement; and furthermore, you will
          not encourage or enable any other individual to do so.
        </Text>
        <Text>
          5.2. General Prohibitions. You agree not to do any of the following,
          unless applicable law mandates that you be given the right to do so:
          <ul>
            <li>
              (a) copy, modify, adapt, translate, reverse engineer, decompile,
              or dissemble the Services or any derivative thereof, including any
              and all services or products offered by TG$ and its affiliates;
            </li>
            <li>
              (b) determine or attempt to determine any source code, algorithms,
              methods, or techniques of the Services or any derivative works
              thereof;
            </li>
            <li>
              (c) make the functionality of the Services available to multiple
              users through any means;
            </li>
            <li>
              (d) create derivative works based on the Services or any content
              included therein;
            </li>
            <li>
              (e) distribute, transfer, sell, sublicense, lease, lend, market,
              or rent the right to use the Services;
            </li>
            <li>
              (f) use the Services for any commercial purpose, including any
              advertising, solicitation, spamming, or promotion;
            </li>
            <li>
              (g) use or incorporate the Services into any other program or
              product;
            </li>
            <li>
              (h) extract, scrape, or index the Services, including any content
              or information provided by TG$ or its users;
            </li>
            <li>
              (i) use, access, search, download, upload, or otherwise interact
              with the Services, including content or any information thereof,
              by using any technology or means other than those provided and
              allowed by TG$. Such means may include the use of machine learning
              software, AI, bots, spiders, crawlers, probes, scripts, data
              mining tools, hacks, trojans, worms, logic bombs, or any tool or
              device of any kind;
            </li>
            <li>
              (j) interfere with or attempt to interfere with the Services,
              including but not limited to disrupting, bypassing, removing,
              deactivating, or employing any modality to otherwise interact with
              the Services, any user thereof, or any other party associated with
              the Services;
            </li>
            <li>
              (k) fake or artificially increase user engagement associated with
              an account;
            </li>
            <li>
              (l) collect, store or share any personally identifiable and/or
              private information of other users from the Services without their
              express permission;
            </li>
            <li>(m) attempt to use another user’s account;</li>
            <li>
              (n) impersonate any person, channel, or entity, make any false
              statement or representation, including reposting of another’s
              content, deepfakes, or lookalikes of another person, or any
              content which may cause a confusion of source;
            </li>
            <li>
              (o) post content which was previously removed for violating the
              Agreement or any content from a banned account holder;
            </li>
            <li>
              (p) use the Services in any manner that may create a conflict of
              interest or undermine the Services;
            </li>
            <li>
              (q) use the Services in any manner that is unrelated to the games
              offered by TG$, misleading, untruthful, defamatory, discriminatory
              in any regard, overtly or subtilty sexual, obscene, offensive,
              pornographic, violent, hateful, hurtful, inflammatory,
              self-harming, or otherwise objectionable to TG$ in its sole
              discretion;
            </li>
            <li>
              (r) use the Services in any manner which infringes,
              misappropriates, or violates TG$’s or a third party’s intellectual
              property rights, trade secret(s), moral rights, publicity rights,
              or privacy rights;
            </li>
            <li>
              (s) harass, threaten, or otherwise violate the legal rights of
              others;
            </li>
            <li>
              (t) share game information provided by TG$, such as game clues, to
              non-users of the Services;
            </li>
            <li>
              (u) trespass, or in any manner gain access to, any property or
              location where you do not have a legal right or express permission
              to be;
            </li>
            <li>(v) violate any applicable law or regulation; or</li>
            <li>
              (w) encourage or enable any other individual to do any of the
              foregoing.
            </li>
          </ul>
          Any attempt by any user to deliberately undermine the legitimate
          operation of the Services may be in violation of criminal and civil
          law, and TG$ reserves the right to seek damages and other remedies,
          including attorneys’ fees, from the user to the fullest extent
          permitted by law.
        </Text>
        <br />
        <Text weight={600}>6. Intellectual Property Rights</Text>
        <Text>
          6.1. TG$ Ownership. Any and all intellectual property rights of the
          Services, including but not limited to TG$ trademarks, copyrights,
          patents, websites, designs, graphics, software, user interfaces, and
          products, are the property of TG$. TG$ intellectual property is
          protected from copying, imitation, communication, or simulation under
          applicable laws. Any and all modification and enhancement, lawful or
          unlawful, to TG$ intellectual property is the sole property of TG$.
          You understand that by using the Services, you do not acquire, by
          implication or otherwise, any license or right to use any of the
          intellectual property owned by TG$ in any manner, except as expressly
          permitted in this Agreement.
        </Text>
        <Text>
          6.2. Intellectual Property Rights of Others. TG$ respects the
          intellectual property rights of others and expects you to do the same.
          By accepting this Agreement, you also agree to the terms of our
          Intellectual Property Policy.
        </Text>
        <Text>
          6.3. Rights Holders. Please see our Intellectual Property Policy for
          notifying TG$ of any allegedly infringing activity.
        </Text>
        <br />
        <Text weight={600}>7. Content</Text>
        <Text>
          7.1. Definitions. “TG$ Content” means the text, software, scripts,
          graphics, images, photos, sounds, music, videos, interactive features,
          and any other data or information or materials that are generated,
          created, provided by, or otherwise made available by TG$, or which are
          either owned or licensed by TG$. “User Content” means any content a
          user of the Services provides, including but not limited to uploading,
          posting, or transmitting any and all text, photographs, videos, sound
          recordings, musical works, and any other data or information or
          materials.
        </Text>
        <Text>
          7.2. Content Disclaimer. TG$ makes no representations, warranties, or
          guarantees, whether express or implied, that any TG$ Content, or User
          Content, is accurate, complete, or up to date. Any content,
          information, or links to third parties associated with the Services
          should not be interpreted as being approved by TG$. You acknowledge
          that TG$ has no obligation to pre-screen, monitor, review, or edit any
          content on the Services, including User Content.
        </Text>
        <Text>
          7.3. TG$ Content Reservation. Using the Services does not grant you
          ownership of or rights to any aspect of the Service, TG$ Content, or
          User Content. Use of “TG$ Content” for any purpose not expressly
          permitted by this Agreement is strictly prohibited.
        </Text>
        <Text>
          7.4. Ownership of User Content. TG$ does not claim ownership rights in
          User Content and nothing in this Agreement restricts any rights that
          you may have to use and exploit your User Content. You are legally
          responsible for the content you submit to the Service. User Content is
          the responsibility of the person who provides it to the Service.
        </Text>
        <Text>
          7.5. License to TG$. By making any and all User Content available
          through the Services, you grant to TG$ a worldwide, nonexclusive,
          transferable, sublicenseable, royalty-free, perpetual license (or, if
          not permitted under applicable law, a license for the whole duration,
          including for any extension thereof, of all relevant rights under any
          applicable law), to use, copy, modify, create derivative works based
          upon, publicly display, publicly perform, market, promote and
          distribute your User Content in connection with operating and
          providing the Services, and to TG$’s successors and affiliates,
          including for purpose of administering, promoting, and redistributing
          part or all of the Services. You grant to us a worldwide,
          nonexclusive, transferable, sublicenseable, royalty-free, perpetual
          license to use your “user name”, channel name, image, voice, and
          likeness; provided, that your ability to provide an image, voice, and
          likeness may be subject to limitations due to age restrictions.
        </Text>
        <Text>
          7.6. User Representations and Warranties. You are solely responsible
          for all your User Content. You acknowledge and agree that you
          indemnify TG$ and further that you are responsible for any loss or
          damage we suffer as a result of your User Content. You represent and
          warrant that you own all your User Content or you have all rights that
          are necessary to grant to TG$ the license rights in your User Content.
          You also represent and warrant that your User Content does not
          infringe, misappropriate, or violate a third party’s intellectual
          property rights, or rights of publicity or privacy, or result in the
          violation of any applicable law or regulation.
        </Text>
        <Text>
          7.7. Waiver of Rights. You acknowledge and agree to waive any rights
          to prior inspection or approval of any use of your User Content on the
          Services. You also waive any and all rights of privacy, publicity, or
          any other equivalent rights in connection with your User Content, or
          any portion thereof. To the extent permitted by applicable law, you
          also agree that you will not exercise your moral rights, or equivalent
          rights under applicable laws, such as your right to be identified as
          the author of any of the User Content. To the extent any moral rights
          are not transferable or assignable, you hereby waive and agree to
          never assert any and all moral rights, or to support, maintain, or
          permit any action based on any moral rights that you may have in or
          with respect to any User Content.
        </Text>
        <Text>
          7.8. License to Other Users. You grant to each other user of the
          Services a worldwide, nonexclusive, royalty-free license to access
          your User Content, including to reproduce, distribute, prepare
          derivative works, display, and perform it, and extract all or any
          portion of your User Content to reproduce additional User Content.
          Note, this license does not grant any rights or permissions for
          another user to make use of your content independent of the Services.
        </Text>
        <Text>
          7.9. Right to Monetize. You grant to TG$ the right to monetize your
          User Content. You acknowledge and agree that TG$ may generate revenues
          and increase our value in any way from the use of User Content. For
          example, we may profit from the sale of advertising, sponsorships,
          promotions, data usage, and gifts. This agreement does not entitle you
          to any payments. You acknowledge and agree that you have no right to
          share in any increase in value of TG$. Further, you acknowledge and
          agree that you have no right to receive any income or other
          consideration in connection with any TG$ Content or User Content,
          including any User Content created by you, and you are prohibited from
          exercising any rights to monetize or obtain other consideration from
          any User Content, be it from TG$ or any third-party service, unless
          explicitly authorized by TG$ in its sole discretion.
        </Text>
        <Text>
          7.10. TG$ Gameplay Content. Any information that is provided to users
          related to finding the treasures, including but not limited to game
          clues, hints, answers to clues, advantage clues, and any other
          gameplay specific information related to finding the treasure items,
          cannot be shared outside the TG$ platform. Violations of sharing
          subscriber only information outside the TG$ platform may result in
          suspension or termination.
        </Text>
        <br />
        <Text weight={600}>8. Changing and Removing User Content</Text>
        <Text>
          Removal of User Content by TG$. TG$ is not obligated to post your User
          Content. We, including our affiliates, reserve the right to edit, cut,
          crop, or change in any way or refuse to publish, partially or
          completely, your User Content. TG$ may remove your User Content
          without notice and for any reason in its sole discretion. TG$ does not
          guarantee the accuracy, integrity, appropriateness, or quality of any
          User Content, and under no circumstance will TG$ be liable in any way
          for any User Content.
        </Text>
        <br />
        <Text weight={600}>9. Privacy</Text>
        <Text>
          Our Privacy Policy explains how your personal data is treated by TG$.
          By accepting the Agreement you also accept the terms of our Privacy
          Policy. Please read our Privacy Policy to better understand how we
          collect your information and how you may manage your information.
        </Text>
        <br />
        <Text weight={600}>10. Modification and Loss of Services</Text>
        <Text>
          TG$ reserves the right and, in its sole discretion, may cancel,
          suspend, and/or change all or any part of the Services, at any time,
          without notice, and with or without cause. Therein, it is possible
          that TG$ may change:
          <ul>
            <li>
              (a) Game rules of a particular game, before or after a game has
              begun;
            </li>
            <li>
              (b) A hiding location of a treasure, before or after a game has
              begun;
            </li>
            <li>
              (c) An availability of a treasure. As in, one or more treasures
              may be temporarily unavailable or completely cancelled from
              gameplay, before or after a game has begun;
            </li>
            <li>(d) A process or timing of awarding prizes; or</li>
            <li>(e) Any other variable which pertains to the Services.</li>
          </ul>
          TG$ may also change the eligibility criteria to access and use the
          Services, including user eligibility and winner eligibility, at any
          time, without notice, and with or without cause. TG$ is not liable to
          you or any other party if, for any reason, any or all of the Services
          are changed, temporarily unavailable, and/or cancelled by TG$ at any
          time, without notice, and with or without cause. For example, TG$
          shall not be liable to you or any other party with respect to any
          damages, injuries, nonperformance, or delay in performance, which may
          have been caused by a foreseeable or unforeseeable event, including a
          force majeure or act of God, such as bad weather, fire, flood, acts of
          terror, satellite or network failure, governmental order or
          regulation, trade dispute, or any other cause beyond the control of
          TG$. You acknowledge and agree that TG$ is not required, and may not,
          reimburse or refund you for any disruption, postponement, change, or
          cancellation, for any cause, of any and all of the Services.
        </Text>
        <br />
        <Text weight={600}>11. Feedback</Text>
        <Text>
          11.1. Introduction. TG$ prides itself in offering incredible
          adventures. We welcome any input that will help improve the user
          experience. However, TG$ has no obligation to review or implement your
          ideas.
        </Text>
        <Text>
          11.2. Definition. “Feedback” means any and all information provided to
          TG$, its employees, affiliates, or third parties associated with the
          Services, including but not limited to any ideas, documentation,
          content offerings, promotions, artwork, software, information, or any
          other intellectual property.
        </Text>
        <Text>
          11.3. License to TG$. By submitting Feedback to TG$ in any form, you
          grant to TG$ a worldwide, nonexclusive, transferable, sublicensable,
          royalty-free, perpetual license to use, reproduce, distribute, sell,
          offer for sale, import, patent, create derivative works of, modify,
          publicly perform, communicate to the public, make available, publicly
          display, and/or otherwise exploit the Feedback, and the modifications
          and derivatives thereof, for any purpose without notice, restriction,
          or attribution of any kind. You acknowledge and agree that you are not
          entitled to any compensation for your Feedback, which may be solicited
          or unsolicited.
        </Text>
        <br />
        <Text weight={600}>12. Links</Text>
        <Text>
          12.1. Third-Party Links. TG$ Services may include links to third
          parties, offering services or products, that are not owned or
          controlled by TG$. TG$ assumes no responsibility for such third
          parties. You are solely responsible for and assume all risk arising
          from, your use of the Services or any third party websites or
          resources.
        </Text>
        <Text>
          12.2. TG$ Links. You may not place a link to TG$ Sites on another
          website without TG$’s express written consent. Upon approval, you must
          adhere to the following so that the link: (i) does not damage or
          dilute the goodwill associated with TG$ or its affiliates, (ii) does
          not allow non-users to access game information that is meant only for
          our users, (iii) create the false appearance that your organization or
          entity is sponsored by, affiliated with, or associated with TG$, and
          (iv) when selected by a user, the Sites are displayed on full-screen
          and not within a frame on the linking site. TG$ reserves the right to
          revoke its consent to the link at any time and in its sole discretion
          without prior notice.
        </Text>
        <br />
        <Text weight={600}>13. Indemnity</Text>
        <Text>
          You agree to defend, indemnify, and hold harmless TG$, its parents,
          subsidiaries, affiliates, offices, directors, employees, and agents,
          from and against any and all claims, damages, obligations, losses,
          liabilities, costs or debt, and expenses, including but not limited to
          attorney's fees and expenses, arising out of your access and use of
          the Services, a breach of any term of this Agreement by you or any
          user of your account, or a violation of any third party right,
          including without limitation any copyright, property, or privacy
          right, or any claim that your User Content caused damage to a third
          party.
        </Text>
        <br />
        <Text weight={600}>14. Warranty Disclaimer</Text>
        <Text>
          OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY
          LAW, THE SERVICES, CONTENT THEREOF, AND PRODUCTS ARE PROVIDED “AS IS”,
          WITHOUT ANY SPECIFIC COMMITMENTS OR WARRANTIES OF ANY KIND. FOR
          EXAMPLE, WE DO NOT MAKE ANY WARRANTIES ABOUT THE CONTENT, THE SPECIFIC
          FEATURES OF THE SERVICES, OR THE ACCURACY, RELIABILITY, AVAILABILITY
          THEREOF, OR ITS ABILITY TO MEET YOUR NEEDS, OR DEFECTS OF ANY
          SOFTWARE, OR THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE
          SERVICES. WE MAY CHANGE, SUSPEND, WITHDRAW, OR RESTRICT THE
          AVAILABILITY OF ALL OR ANY PART OF OUR SERVICES FOR ANY REASON AT ANY
          TIME WITHOUT NOTICE.
        </Text>
        <br />
        <Text weight={600}>15. Limitation of Liability</Text>
        <Text>
          EXCEPT AS REQUIRED BY APPLICABLE LAW, TG$, ITS AFFILIATES, OFFICERS,
          DIRECTORS, EMPLOYEES, AND AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS
          OR DAMAGES INCURRED (DIRECTLY OR INDIRECTLY) BY YOU, INCLUDING BUT NOT
          LIMITED TO LOSS OF PROFITS, REVENUES, OPPORTUNITIES, GOODWILL, DATA,
          OR ANTICIPATED SAVINGS, INDIRECT OR CONSEQUENTIAL LOSS, OR PUNITIVE
          DAMAGES.
        </Text>
        <Text>
          TG$ IS NOT LIABLE TO YOU FOR:
          <ul>
            <li>(a) ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICES;</li>
            <li>
              (b) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF
              THE SERVICES;
            </li>
            <li>(c) ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICES;</li>
            <li>
              (d) ANY CHANGES, INTERRUPTION, OR CESSATION OF THE SERVICES;
            </li>
            <li>
              (e) ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE
              SERVICES;
            </li>
            <li>
              (f) ANY CONTENT WHETHER SUBMITTED BY A USER OR TG$, INCLUDING YOUR
              USE OF ANY CONTENT;
            </li>
            <li>
              (g) THE REMOVAL, CORRUPTION OF, FAILURE TO STORE, OR
              UNAVAILABILITY OF ANY CONTENT; AND/OR
            </li>
            <li>
              (h) ANY ADVERSE CONSQUENCES, REAL OR PERCIEVED AS A RESULT OF A
              USER NOT READING, UNDERSTANDING, AND ADHEREING TO THE TG$
              AGREEMENT.
            </li>
          </ul>
          THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM
          ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL
          THEORY. YOU ASSUME ALL RISKS RELATING TO YOUR ONLINE OR OFFLINE
          COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND
          WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT
          OF YOUR USE OF THE SERVICES. YOU UNDERSTAND THAT TG$ DOES NOT SCREEN
          OR INQUIRE INTO THE BACKGROUND OF ANY USERS OF THE SERVICES. TG$ MAKES
          NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE
          SERVICES. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL
          COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND
          WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT
          OF YOUR USE OF THE SERVICES, PARTICULARLY IF YOU DECIDE TO MEET
          OFFLINE OR IN PERSON.
        </Text>
        <Text>
          TO THE FULLEST EXTEND PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH ANY
          THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICES IS DIRECTLY
          BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE US AND
          OUR AFFILIATES FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL
          AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
          ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
        </Text>
        <br />
        <Text weight={600}>16. Dispute Resolution</Text>
        <Text>
          16.1. Introduction. This section defines the arbitration agreement and
          the agreement, between you and TG$, that all claims will be brought
          only in an individual capacity, and not as a class action or other
          representative proceeding. Arbitration means that the dispute will be
          resolved by a neutral arbitrator instead of in a court by a judge or
          jury. Please read this Section carefully. You may opt out of the
          arbitration agreement by following the opt out procedure described
          below. You agree that the U.S. Federal Arbitration Act governs the
          interpretation and enforcement of this section, and that you and TG$
          are each waiving the right to a trial by jury or to participate in a
          class action.
        </Text>
        <Text>
          16.2. Your Right to Opt Out. You have the right to opt out of
          arbitrating disputes with TG$. If you do not want to be bound by the
          binding arbitration agreement, you must notify us by sending an email
          to <a href="mailto:admin@treauregames.fun">admin@treauregames.fun</a>{" "}
          requesting such, within thirty (30) days of the date that you accept
          this agreement.
        </Text>
        <Text>
          16.3. Informal Dispute Resolution. You agree that in the event of any
          dispute between you and TG$, you will first contact TG$ to negotiate a
          resolution of the dispute, before resorting to formal means of
          resolution, including without limitation arbitration or any court
          action.
        </Text>
        <Text>
          16.4. Arbitration Agreement. Any dispute, controversy, or claim
          (collectively, “Claim”), after attempting the informal dispute
          resolution will be finally resolved by binding arbitration.
          <br />
          YOU AGREE THAT DISPUTES BETWEEN YOU AND TG$ WILL BE RESOLVED BY
          BINDING, INDIVIDUAL ARBITRATION, AND YOU ARE WAIVING YOUR RIGHT TO A
          TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING.
          <br />
          To begin an arbitration proceeding, you must send a letter to TG$
          requesting such at Treasure Game$, LLC, ATTN: Legal Department;
          Dispute Resolution, 910 Foulk Road, Suite 201, Wilmington, DE 19803.
          The arbitration will be administered by the American Arbitration
          Association ("AAA").
          <br />
          The AAA Rules are available at{" "}
          <a href="https://www.adr.org/Rules" target="_blank" rel="noreferrer">
            https://www.adr.org/Rules
          </a>{" "}
          or by calling <a href="tel:1-800-778-7879">1-800-778-7879</a>.
          <br />
          Payment of all filing, administration, and arbitrator fees will be
          governed by the AAA’s rules. To the extent permitted by law, the
          existence of the arbitration, the arbitration proceedings, and the
          outcome of the arbitration will be treated as confidential and will
          not be disclosed by either party
        </Text>
        <Text>
          16.5. Class Action Waiver. TG$ and you each agree that any Claim
          against the other may only be brought in an individual capacity, and
          not as a plaintiff or class member in any purported class or
          representative proceeding. If the Claim is subject to arbitration, the
          arbitrator will not have the authority to combine or aggregate similar
          claims or conduct any class action proceeding nor make an award to any
          person or entity that is not a part of the arbitration. If this
          specific provision is found to be unenforceable, then the arbitration
          agreement herein shall be null and void.
        </Text>
        <Text>
          16.6. Exceptions. The arbitration agreement does not apply to any
          Claim by TG$ for infringement of its intellectual property.
          Additionally, the arbitration agreement does not prevent TG$ or you
          from filing any claim in small claims court.
        </Text>
        <Text>
          16.7. Continuation. This arbitration provision will survive any
          termination of this Agreement.
        </Text>
        <Text>
          16.8. Governing Law and Exclusive Venue. Subject to the arbitration
          agreement and class action wavier, TG$ and you agree to the exclusive
          jurisdiction of and venue in the state and federal courts located in
          Delaware.
        </Text>
        <Text>
          16.9. Limitation of Time to File Claims. Any cause of action you may
          have arising out of or relating to this Agreement or the Services must
          be commenced within one (1) year after the cause of action occurs.
          OTHERWISE, SUCH A CAUSE OF ACTION IS PERMANENTLY BARRED.
        </Text>
        <br />
        <Text weight={600}>17. Game Winner Requirements</Text>
        <Text>
          17.1. Introduction. If you win a game, you may be awarded prizes,
          which may be in a monetary or non-monetary form. As a game winner, you
          acknowledge and agree to allow TG$ to use your personal information to
          both administer and promote TG$ Services. You also agree to film
          yourself finding the treasure. You further agree to conduct
          post-winning press conferences, which may or may not require you to
          travel.
        </Text>
        <Text>
          17.2. Filming Requirement. IF YOU FIND A TREASURE, YOU MUST FILM
          YOURSELF, OR HAVE A FRIEND FILM YOU, FINDING THE TREASURE AND OPENING
          THE TREASURE CONTAINER IN WHICH THE TREASURE IS HOUSED (if the
          Treasure is housed within a Treasure container). You must also send
          this footage to TG$ so that we may verify the successful finding of
          the Treasure and use this footage for promotional purposes.
        </Text>
        <Text>
          17.3. Personal Information Grant as a Game Winner for Administrative
          and Promotional Purposes. You grant to TG$ the right to use your
          personal information for administrative and promotional purposes. Such
          personal information may include your name, likeness, contact
          information, bank information, your gameplay statistics, age, shirt
          size, your country, state, province or region, dietary restrictions,
          or other personal information, as needed for TG$ to administer its
          Services. Additionally, TG$ has the right to provide your name and
          contact information to third parties in order to provide you with your
          monetary or non-monetary prizes. This grant is a worldwide,
          nonexclusive, transferable, sublicenseable, royalty-free, perpetual
          license (or, if not permitted under applicable law, a license for the
          whole duration, including for any extension thereof, of all relevant
          rights under any applicable law), to use, copy, modify, create
          derivative works based upon, publicly display, publicly perform,
          market, promote and distribute your provided personal information
          and/or User Content, and to TG$’s successors and affiliates, including
          for purpose of administering, promoting, and redistributing part or
          all of the Services; provided, that your ability to provide the
          personal information, such as your name, an image, voice, likeness,
          country, and gameplay statistics, may be subject to limitations due to
          age restrictions.
        </Text>
        <Text>
          17.4. Post-game Press. You acknowledge and agree that if you win a
          game, you shall make yourself available for any post-game award
          ceremonies, press, interviews, and any other activities reasonably
          requested by TG$ or our sponsors. You acknowledge and agree that you
          will not be compensated for such post-game press. TG$ in its sole
          discretion may choose to compensate you for some travel expenditures
          to and from press conferences if such press conferences require you to
          travel.
        </Text>
        <Text>
          17.5. Background Checks. You acknowledge and agree to a background
          check wherein TG$ can verify your identity, residency, and whether you
          qualify as a winner, as determined in TG$’s sole discretion. TG$
          reserves the right, in its sole discretion, to disqualify a game
          winner and cancel any prizes won by the game winner if the game winner
          is found to be in violation of the Agreement.
        </Text>
        <Text>
          17.6. Proof of Identity and Residency. You acknowledge and agree to
          allow TG$ to verify your identity and residency. As part of the
          verification process, you may be required to provide two different
          forms of identification, one of which being a government-issued photo
          ID. Users may also be required to produce a recent invoice from a
          utility company with a name and physical address thereon. If you are
          considered a minor, then your parent or legal guardian may also be
          required to provide proof of identity and residency. If you are
          considered a minor, then you may be required to provide a
          school-issued photo ID. If you (or your parent or legal guardian)
          choose to not comply, or cannot comply for any reason, your prizes may
          become forfeited. To be clear, if you choose to or cannot comply, TG$
          reserves the right, in its sole discretion, to deny any and all of its
          Services to you, including awarding you with any prizes. The
          sufficiency of any such proof of identity and residency shall be
          determined by TG$ in its sole discretion.
        </Text>
        <Text>
          17.7. Banking Information. Users of TG$ Services acknowledge and agree
          to provide their banking information to TG$. In order to receive
          prizes, you may be required to have a bank account and provide your
          banking information to TG$. Failure to communicate your banking
          information may result in the forfeiture of any and all prizes. If you
          are a considered a minor, then your parent or legal guardian may be
          required to have a bank account and provide such banking information
          to TG$.
        </Text>
        <Text>
          17.8. Disqualification and Forfeiture of Prizes. TG$ shall conduct a
          reasonable investigation to ensure that a user won a game fairly and
          in compliance with the Agreement. TG$ may disqualify any user, at any
          time and without notice, with or without cause. If, for example, a
          user is found to be in violation of the Agreement, TG$ shall
          disqualify the user in violation, and the user shall forfeit any and
          all prizes.
        </Text>
        <br />
        <Text weight={600}>18. Prizes</Text>
        <Text>
          18.1. Introduction. TG$ offers multiple different games, including a
          live-action treasure hunt. Prizes broadly refer to the awards and
          benefits game winners can receive upon winning a game. Some games,
          like the live-action treasure hunt games, include physical objects,
          termed “Treasure” items or “Treasures”, that are findable in the real
          world. Each Treasure has a monetary value associated therewith as
          specified in the Rules of each game. Some Treasure can be redeemable
          for a sum of money or a set number of wishes which may or may not be
          of equivalent in value to the sum of money.
        </Text>
        <Text>
          18.2. Physical Treasures. All Treasures, i.e., the findable physical
          objects, and any associated objects therewith such as the Treasure
          container, are the sole property of TG$ and must be returned to TG$ in
          good working order and without alteration. When in possession of
          Treasure, you agree to safeguard and take reasonable precautions to
          protect the Treasure, and furthermore you agree to not damage, mark,
          or otherwise alter the Treasure. If any TG$ property is damaged after
          you have found it, whether it was in your personal possession or not,
          or whether the damage was accidental or intentional, you agree to
          compensate TG$ for the damages incurred by TG$ in fixing or replacing
          the damaged property. TG$ may request that you do not remove any TG$
          property from the premises at which you found it. If you have taken
          TG$ property and are in possession of such property, TG$ may request
          that you mail such property to TG$ or request to allow TG$ personnel
          onto your residential property in order to retrieve the TG$ property.
          If you do not comply with the requests of TG$ any and all of your
          prizes may be forfeited.
        </Text>
        <Text>
          18.3. Prizes. Monetary winnings may be paid by check or bank deposit,
          as a lump sum or in installments, as decided in TG$’s sole discretion.
          If applicable and if you choose wishes instead of the monetary sum,
          then TG$ will initiate its wish fulfillment process wherein TG$ will
          take reasonable measures to fulfill your wishes. TG$ reserves the
          right to refuse offering wishes at any time without notice, in its
          sole discretion. TG$ also reserves the right to deny any and all
          wishes, in its sole discretion. TG$ determines which wishes it
          fulfills and the completion of such wishes, in its sole discretion and
          irrespective of the winner’s satisfaction. All completed wishes are
          final and cannot be reversed to receive a monetary sum. All federal,
          state, provincial and local taxes, and international tariffs, are the
          sole responsibility of the game winner.
        </Text>
        <Text>
          18.4. Charity if Forfeiture. If a prize is forfeited, for any reason,
          TG$ reserves the right in its sole discretion to donate the winnings
          to a charity as chosen by TG$.
        </Text>
        <br />
        <Text weight={600}>19. General</Text>
        <Text>
          19.1. Entire Agreement. This Agreement constitutes the whole legal
          agreement between TG$ and you and governs your use of the Services and
          completely supersedes all prior and contemporaneous understandings,
          agreements, representations, and warranties, both written and oral,
          regarding the Services.
        </Text>
        <Text>
          19.2. Severability. If a particular term of this Agreement is not
          enforceable for any reason, this will not affect any other terms.
        </Text>
        <Text>
          19.3. No Waiver. TG$’s failure to enforce any provision of these terms
          shall in no way be construed to be a present or future waiver of such
          provision, nor in any way affect the right of any party to enforce
          each such provision thereafter. The express waiver by us of any
          provision, condition or requirement of these terms shall not
          constitute a waiver of any future obligation to comply with such
          provision, condition, or requirement.
        </Text>
        <Text>
          19.4. Successors and Assigns. TG$ has the right to assign this
          Agreement, in whole or in part, to any person or entity at any time
          without your consent and without notice.
        </Text>
        <Text>
          19.5. Survival. The terms of this Agreement shall survive after
          termination of your account.
        </Text>
        <Text>
          19.6. California Consumer Rights Notice. Under California Civil Code
          Section 1789.3, California users of the Services receive the
          following: the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs may be
          contacted in writing at the contact information set forth at
          <br />
          <a
            href="https://www.dca.ca.gov/about_us/contactus.shtml"
            target="_blank"
            rel="noreferrer"
          >
            https://www.dca.ca.gov/about_us/contactus.shtml
          </a>
        </Text>
        <Text>
          19.7. Contact Information. If you have any questions about this
          Agreement or the Services, please contact TG$ at{" "}
          <a href="mailto:admin@treasuregames.fun">admin@treasuregames.fun</a>{" "}
          or write to Treasure Game$, LLC, ATTN: Legal Department, 910 Foulk
          Road, Suite 201, Wilmington, DE 19803.
        </Text>
      </div>
    </>
  );
};

export default TermContent;

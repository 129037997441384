/* eslint-disable max-lines */
import { faMap } from "@fortawesome/free-regular-svg-icons";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { ComposableMap, ZoomableGroup } from "react-simple-maps";
import { Button } from "reactstrap";
import WishLampRegion from "src/pages/Play/assets/wish-lamp-region.png";
import { useReducerData } from "src/store/hooks";
import { RegionListProps } from "../RegionList/types";
import Map from "./Map";

import classes from "./styles.module.scss";
import { Props } from "./types";

const RegionMap: React.FC<Props> = ({
  handleHoveredRegion,
  handleRemoveRegion,
  addedRegions,
  regionSelectionFlow,
  hoveredCard,
  currentHoveredRegion,
  handleAddRegion,
}) => {
  const { data: regionList }: { data: RegionListProps[] } = useReducerData(
    "region",
    "regionList",
    {
      data: [],
      loading: false,
    }
  );

  const [zoom, setZoom] = useState(0.8);

  const handleFilter = ({
    constructor: { name },
  }: {
    constructor: { name: string };
  }) => {
    return name !== "WheelEvent";
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.mapHeader}>
        <div className={classes.image}>
          <img className="invisible" src={WishLampRegion} alt="wish-lamp" />
        </div>
        <div className={classes.mapActions}>
          <Button
            onClick={() => {
              if (zoom < 1.5) setZoom(zoom + 0.1);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Button
            onClick={() => {
              if (zoom > 0.5) setZoom(zoom - 0.1);
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
          <Button>
            <FontAwesomeIcon
              onClick={() => setZoom(0.8)}
              width={14}
              height={20}
              icon={faMap}
            />
          </Button>
        </div>
        <div className={classes.image}>
          <img src={WishLampRegion} alt="wish-lamp" />
        </div>
      </div>

      <div className={classes.map}>
        <ComposableMap
          style={{
            width: "100%",
            height: "auto",
          }}
          projection="geoAlbers"
        >
          <ZoomableGroup
            center={[-95, 35]}
            zoom={zoom}
            filterZoomEvent={handleFilter}
          >
            <Map
              handleRemoveRegion={handleRemoveRegion}
              handleRegionSelect={() => {}}
              handleHoveredRegion={handleHoveredRegion}
              addedRegions={addedRegions}
              regionSelectionFlow={regionSelectionFlow}
              hoveredCard={hoveredCard}
              currentHoveredRegion={currentHoveredRegion}
              handleAddRegion={handleAddRegion}
              regionList={regionList}
            />
          </ZoomableGroup>
        </ComposableMap>
      </div>
    </div>
  );
};

export default RegionMap;

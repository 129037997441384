import cn from "classnames";
import React from "react";
import classes from "./Post.module.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const PostTag = ({ className, children }: Props) => {
  return <span className={cn(classes.postTag, className)}>{children}</span>;
};

export default PostTag;

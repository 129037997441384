import React, { useState } from "react";
import Button from "src/components/Button";
import Text from "src/components/Text";
import ResultImageSlider from "src/pages/Play/Clue/Components/ClueDashboard/CluePurchase/components/ResultImageSlider";
import { PurchasedImage } from "src/types/clue";
import classes from "./TreasureViewsResult.module.scss";

type Props = {
  onClose: () => void;
  buyMore: () => void;
  images: PurchasedImage[];
  isAllImagePurchased: boolean;
  total: number;
};

const TreasureViewsResult: React.FC<Props> = ({
  buyMore,
  onClose,
  images,
  isAllImagePurchased,
  total,
}) => {
  const [state, setState] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (i: number) => {
      setState(i);
    },
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div>
          <Text color="#fff" size={24}>
            <b>
              {images.length} of {total}
            </b>{" "}
            Treasure Views Purchased
          </Text>
        </div>
        {!isAllImagePurchased && (
          <div className={classes.buyMore}>
            <Button
              buttonText={
                <Text
                  color="#fff"
                  size={18}
                  fontFamily="inter"
                  fontWeight="bold"
                >
                  Buy More
                </Text>
              }
              type="submit"
              // loading={loading}
              onClick={buyMore}
              className={classes.buyMoreButton}
            />
          </div>
        )}
      </div>
      <div className={classes.content}>
        <ResultImageSlider
          iconSize={"2xl"}
          images={images}
          state={state}
          settings={settings}
        />
      </div>
      <div className={classes.progressText}>
        <Text color="#fff" size={24} fontFamily="inter" fontWeight="semibold">
          {state + 1} of {images.length}
        </Text>
      </div>
      <div className={classes.footer}>
        <Button
          buttonClassName={classes.done}
          onClick={onClose}
          buttonText={"Done"}
        />
      </div>
    </div>
  );
};

export default TreasureViewsResult;

import cx from "classnames";
import React, { useEffect, useRef } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import signInBackground from "src/assets/images/landing-bg.png";
import signUpBackground from "src/assets/images/night-sky.png";
import wishLampLogo from "src/assets/images/wish-lamp-landing.png";
import { authRoutes } from "src/routes";
import { isLoggedIn } from "src/utils/utils";
import Header from "./Header";
import classes from "./styles.module.scss";

const profileRoutes = ["/auth/profile", "/auth/risk-waiver", "/auth/payment"];

const AuthLayout: React.FC = () => {
  const { pathname } = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isSignInPage = pathname === "/auth/login";
  const isProfilePath = profileRoutes.includes(pathname);
  const isPaymentPath = pathname === "/auth/payment";
  const navigate = useNavigate();

  useEffect(() => {
    const isLogIn = isLoggedIn();

    if (pathname.includes("auth") && isLogIn && !isProfilePath) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (containerRef) {
      containerRef?.current?.scroll({ top: 0, behavior: "smooth" });
    }
  }, [pathname]);

  const getRoutes = (
    routes: {
      path: string;
      name: string;
      component: () => JSX.Element;
      layout: string;
    }[]
  ) => {
    return routes.map(({ path, component: Component }, key) => {
      return <Route path={path} element={<Component />} key={key} />;
    });
  };

  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${
          isSignInPage ? signInBackground : signUpBackground
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top",
        backgroundAttachment: "fixed",
      }}
    >
      <Header />
      <div
        className={cx(classes.wrapperLayer, {
          [classes.signInWrapper]: isSignInPage,
        })}
      >
        {isProfilePath && !isPaymentPath && (
          <div className={classes.logoHeader}>
            <img src={wishLampLogo} />
          </div>
        )}
        <div
          ref={containerRef}
          className={cx(classes.container, {
            [classes.signInContainer]: isSignInPage,
            [classes.paymentContainer]: isPaymentPath,
          })}
        >
          <Routes>
            {getRoutes(authRoutes)}
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;

import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import HeroBg from "src/pages/Home/assets/images/hero-bg.png";
import TreasureText from "src/pages/Home/assets/images/title.png";
import TgtextMobile from "src/pages/Subscription/assets/tg-mobile-text.png";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./styles.module.scss";

const HeroSection = ({ redirectLink = "" }) => {
  const navigate = useNavigate();
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  const getHeaderImage = () => {
    if (isMobile) {
      return TgtextMobile;
    }
    return TreasureText;
  };

  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${HeroBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={classes.title}>
        <img src={getHeaderImage()} alt="treasure" />
      </div>
      <div className={classes.content}>
        <Typography
          variant="p32"
          fontFamily="timesNewRoman"
          className={classes.message}
        >
          Spreading more wealth to more people around the world than any other
          company on the planet...
        </Typography>
        <div className={classes.text}>
          <Typography fontFamily="impact">PLAY</Typography>
          <span></span> <Typography fontFamily="impact">WATCH</Typography>
          <span className={classes.hide}></span>
          <Typography fontFamily="impact">ENJOY</Typography>
          <span></span> <Typography fontFamily="impact">WIN</Typography>
        </div>
        <p className={classes.secondMessage}>
          Nationwide Treasure Hunting Games that give players an opportunity to
          win life changing prizes and money all year long!
        </p>
      </div>
      <Button
        onClick={() => navigate(redirectLink)}
        buttonColor="secondary"
        buttonText="Create TG$ Account"
      />
    </div>
  );
};

export default HeroSection;

import {
  ADMIN_ACCESS_ROLES,
  FULL_ACCESS_ROLES,
  ROLE_LEVEL,
} from "src/helpers/constants/role";

export const getAccessLevel = (role: string) => {
  switch (true) {
    case ADMIN_ACCESS_ROLES.includes(role):
      return ROLE_LEVEL.ADMIN;

    case FULL_ACCESS_ROLES.includes(role):
      return ROLE_LEVEL.FULL_ACCESS;

    case ROLE_LEVEL.FREE_TRIAL === role:
      return ROLE_LEVEL.FREE_TRIAL;

    default:
      return ROLE_LEVEL.FREE_ACCESS;
  }
};

export const isAllowedRoute = (permissions: string[], accessLevel: string) => {
  return permissions.includes(accessLevel);
};

import get from "lodash/get";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router";
import { Container } from "reactstrap";
import AppDownload from "src/components/AppDownload";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { ROLE_LEVEL } from "src/helpers/constants/role";
import useUserProfile, {
  useUserProfileLoading,
} from "src/helpers/hooks/useUserProfile";
import CheckIcon from "src/pages/Auth/assets/images/check-icon.png";
import TgtextMobile from "src/pages/Subscription/assets/tg-mobile-text.png";
import { useReducerData } from "src/store/hooks";
import { trackEvent, trackPageViewEvent } from "src/utils/googleAnalytics";
import { getAccessLevel } from "src/utils/role";
import { useActions } from "./selectorData";
import classes from "./styles.module.scss";

const Completed = () => {
  const navigate = useNavigate();
  const { getUserProfile } = useActions();
  const { fromSignup } = useReducerData("auth", "flow", { fromSignup: true });
  const userProfile = useUserProfile();
  const loading = useUserProfileLoading();
  const roleName = getAccessLevel(get(userProfile, "user.role.name"));
  const mobileRedirectLink = process.env.REACT_APP_MOBILE_APP_DEEP_LINK_URL;

  const welcomeText = fromSignup
    ? "Welcome to Treasure Game$"
    : "Congratulations!";

  const successText =
    roleName === ROLE_LEVEL.FREE_ACCESS
      ? "You’ve selected Free Access level to Treasure Game$."
      : "You’ve successfully subscribed to Wish Lamp$";

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.get_access_signup);
  }, []);

  return (
    <Container className={classes.wrapper}>
      <div className={classes.tgMobileImg}>
        <img src={TgtextMobile} alt="tg-text" />
      </div>
      <img className={classes.checkIcon} src={CheckIcon} alt="check-icon" />
      <div className={classes.content}>
        <Typography className={classes.welcomeText} fontFamily="milonga">
          {welcomeText}
        </Typography>
        <Typography
          className={classes.infoText}
          variant="p18"
          fontFamily="inter"
        >
          {successText}
        </Typography>
      </div>
      <div className={classes.buttonWrapper}>
        <Typography variant="p16" fontFamily="inter">
          Click the access button below to continue on your browser.
        </Typography>
      </div>
      {isMobile && (
        <div className={classes.accessOnMobile}>
          <a href={mobileRedirectLink}>
            <Button
              buttonColor="secondary"
              onClick={() => {}}
              buttonText={
                <Typography className={classes.access} variant="p20">
                  Access In app
                </Typography>
              }
              variant="link"
            />
          </a>
        </div>
      )}
      <div className={classes.footer}>
        <Typography variant="p20" className={classes.footerText}>
          Unlock the App on Your Mobile Device
        </Typography>
        <AppDownload />
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          buttonText="Get Access"
          buttonColor="secondary"
          type="submit"
          buttonClassName={classes.button}
          loading={loading}
          onClick={() => {
            trackEvent(
              ANALYTICS_CONSTANTS.category.sign_up,
              ANALYTICS_CONSTANTS.action.sign_up_completed
            );
            getUserProfile(() => {
              navigate("/subscriptions?next-path=/play");
            });
          }}
        />
      </div>
    </Container>
  );
};

export default Completed;

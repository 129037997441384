import cn from "classnames";
import React from "react";
import classes from "./Cards.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const WatchCards = ({ children, className }: Props) => {
  return <div className={cn(classes.wrapper, className)}>{children}</div>;
};

export default WatchCards;

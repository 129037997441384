import React from "react";
import { useNavigate } from "react-router";
import Tsg from "src/assets/images/tgs-button.svg";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import CopyrightIcon from "src/pages/Home/assets/icons/copy-right-icon.svg";
import { useWindowSize } from "src/utils/useWindowSize";
import { currentYear, isLoggedIn } from "src/utils/utils";
import classes from "./styles.module.scss";

const PreviewFooter = () => {
  const navigate = useNavigate();
  const isLogIn = isLoggedIn();
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  const redirectHomeUrl = isLogIn ? "/subscriptions" : "/auth/access-info";

  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.leftSide}>
        <img className={classes.tgsButton} src={Tsg} alt="img" />
        <Typography
          className={classes.copyRight}
          variant="p12"
          fontFamily="inter"
        >
          <img src={CopyrightIcon} alt="copy-right" /> Copyright {currentYear}
        </Typography>
        <div className={classes.links}>
          <a href="/a/privacy-policy-ip-policy">Privacy</a> <span></span>
          <a href="/a/terms-and-conditions">Terms and Conditions</a>
          <span></span>
          <a href="/a/tg-risk-waiver">Risk Waiver</a>
        </div>
      </div>
      <div className={classes.button}>
        <Typography fontWeight="bold" variant="p32" fontFamily="inter">
          Sign Up now for access {!isMobile && "to the mobile app"} and play
          anywhere
        </Typography>
        <Button
          onClick={() => navigate(redirectHomeUrl)}
          buttonText="GET STARTED"
          buttonColor="secondary"
        />
      </div>
      <div className={classes.footerLinks}>
        <img className={classes.tgsButton} src={Tsg} alt="img" />
        <a href="/a/faq">FAQ</a>
        <a href="/a/community-guidelines">Community Guidelines</a>
        <a href="/a/contact">Contact</a>
        <a href="/a/employment">Employment</a>
        <a href="/a/partners">Partners</a>
      </div>
    </footer>
  );
};

export default PreviewFooter;

import { useReducerData } from "src/store/hooks";

export const useIndexData = () => {
  return {
    dashboardWatch: useReducerData("dashboard", "dashboardWatch.data", {}),
    dashboardWatchLoading: useReducerData(
      "dashboard",
      "dashboardWatch.loading",
      false
    ),
  };
};

import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { ReactComponent as StripeBadge } from "src/assets/icons/stripe-badge.svg";
import Spinner from "src/components/Spinner";
import Typography from "src/components/Typography";
import { PlanProps } from "src/pages/Subscription/Plans/Components/PlanCard/types";
import { useReducerData } from "src/store/hooks";
import classes from "./styles.module.scss";

const StripeForm = ({ selectedPlan }: { selectedPlan: PlanProps }) => {
  const stripe = useStripe();
  const elements = useElements();
  const amount = selectedPlan.price;
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState("");
  const { data: paymentDetailsData } = useReducerData(
    "auth",
    "payment",
    {}
  ) as {
    data: any;
  };

  const subscriptionId = paymentDetailsData?.subscription?.id;
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStripeErrorMessage("");

    const url = `${window.location.origin}/play?payment_success=true&subscription_id=${subscriptionId}`;
    if (elements == null || stripe == null) {
      return;
    }

    setPaymentLoading(true);
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError?.message) {
      // Show error to your customer
      setStripeErrorMessage(submitError.message);
      setPaymentLoading(false);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: url,
      },
    });

    if (result.error?.message) {
      setStripeErrorMessage(result.error.message);
    }

    setPaymentLoading(false);
  };

  return (
    <form className={classes.stripeForm} onSubmit={handleSubmit}>
      {stripeErrorMessage && (
        <div style={{ color: "red" }}>{stripeErrorMessage}</div>
      )}
      {paymentLoading && (
        <>
          <Spinner wholePage wrapperClassName={classes.paymentLoader} />
          <div className={classes.paymentLoaderText}>
            <span>Processing...</span>
          </div>
        </>
      )}
      <PaymentElement
        options={{
          wallets: {
            googlePay: "auto",
            applePay: "auto",
          },
          fields: {
            billingDetails: {
              name: "auto",
              email: "auto",
              address: "auto",
            },
          },
        }}
      />
      {amount && (
        <div className={classes.checkoutWrapper}>
          <div className={classes.checkout}>
            <div className={classes.summary}>
              <Typography
                color={"#101828"}
                variant={"p13"}
                fontWeight={"medium"}
                fontFamily={"inter"}
              >
                Check out Summary
              </Typography>
              <FontAwesomeIcon icon={faChevronDown} height={15} width={15} />
            </div>
            {/*<div className={classes.divider} />*/}
            <div className={classes.summary}>
              <Typography
                variant={"p18"}
                fontWeight={"medium"}
                color={"#101828"}
                fontFamily={"inter"}
              >
                Total
              </Typography>
              <Typography
                variant={"p18"}
                fontWeight={"medium"}
                color={"#101828"}
                fontFamily={"inter"}
              >
                $ {amount}
              </Typography>
            </div>
          </div>
        </div>
      )}
      <button
        type="submit"
        disabled={!stripe || !elements}
        className={classes.payBtn}
      >
        Pay Now
      </button>
      <StripeBadge />
    </form>
  );
};

export default StripeForm;

import cn from "classnames";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import wishLampLogo from "src/assets/images/wish-lamp-landing.png";
import Button from "src/components/Button";
import Modal, { ModalBody } from "src/components/Modal";
import Spinner from "src/components/Spinner";
import Typography from "src/components/Typography";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { getUserProfile, verifyPayment } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { trackEvent } from "src/utils/googleAnalytics";
import classes from "./styles.module.scss";

const FreeTrialSignUpSuccessModal = ({
  isOpen,
  onClose,
  isPaymentSuccess,
  subscriptionId,
}: {
  isOpen: boolean;
  subscriptionId: string;
  isPaymentSuccess: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const actions = useStoreActions({ verifyPayment, getUserProfile });
  const { loading } = useReducerData("auth", "verifyPayment", false);
  const callbackAfterVerifyPayment = (res: any) => {
    if (!res?.status) {
      onClose();
      return;
    }
    actions.getUserProfile();
  };
  useEffect(() => {
    if (isPaymentSuccess && subscriptionId) {
      actions.verifyPayment(subscriptionId, callbackAfterVerifyPayment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      backdrop
      className={classes.freeTrialModal}
      toggle={onClose}
      isOpen={isOpen}
    >
      <ModalBody className={classes.modalBody}>
        {loading ? (
          <Spinner center />
        ) : (
          <>
            <div className={classes.logoWrapper}>
              <img src={wishLampLogo} alt="wish lamp" />
            </div>
            <span className={classes.welcomeText}>Welcome to Wish Lamp$</span>
            {isPaymentSuccess ? (
              <div>
                <Typography
                  color={"fff"}
                  fontFamily={"inter"}
                  fontWeight={"medium"}
                  className={classes.paymentSuccessText}
                >
                  Happy Treasure Hunting!
                </Typography>
                <div
                  className={cn(
                    classes.buttonContainer,
                    classes.successButtonContainer
                  )}
                >
                  <Button
                    buttonText="Let's Go!"
                    buttonColor="purple-gradient"
                    onClick={() => {
                      navigate("/play");
                      onClose();
                    }}
                    buttonClassName={cn(classes.button, classes.successButton)}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className={classes.description}>
                  <Typography
                    variant="p28"
                    fontFamily="poppins"
                    fontWeight="semibold"
                    className={classes.title}
                  >
                    FREE TRIAL
                  </Typography>
                  <div className={classes.content}>
                    You have access to <span>10 free clues</span> per active
                    region and to watch our entertainment and content. You can
                    upgrade at anytime to get more clues and be eligible to find
                    the Wish Lamp and win!
                  </div>
                </div>
                <div className={classes.buttonContainer}>
                  <Button
                    buttonText="Continue to Free Trial"
                    buttonColor="purple-gradient"
                    variant="outline"
                    onClick={() => {
                      navigate("/play");
                      onClose();
                    }}
                    buttonClassName={classes.button}
                  />
                  <Button
                    buttonText="Upgrade to Full Access"
                    buttonColor="purple-gradient"
                    onClick={() => {
                      navigate("/auth/profile");
                      trackEvent(
                        ANALYTICS_CONSTANTS.category.sign_up,
                        ANALYTICS_CONSTANTS.action.convert_free_to_full
                      );
                    }}
                    buttonClassName={classes.button}
                  />
                </div>
                <div className={classes.footer}>
                  <Typography
                    variant="p18"
                    fontFamily="poppins"
                    fontStyle="italic"
                  >
                    * Unlock Full Access to be eligible to win prizes and to
                    purchase Hints or Advantage Clues.
                  </Typography>
                </div>
              </>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default FreeTrialSignUpSuccessModal;

import cx from "classnames";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import Button from "src/components/Button";
import LeftIcon from "src/pages/Home/assets/icons/left-icon.svg";
import RightIcon from "src/pages/Home/assets/icons/right-icon.svg";
import Game1 from "src/pages/Home/assets/images/game-1.svg";
import Game2 from "src/pages/Home/assets/images/game-2.svg";
import Game3 from "src/pages/Home/assets/images/game-3.svg";
import Game4 from "src/pages/Home/assets/images/game-4.svg";
import Game5 from "src/pages/Home/assets/images/game-5.svg";
import GameListBackground from "src/pages/Home/assets/images/game-list-bg.png";
import LeftCurtain from "src/pages/Home/assets/images/left-curtain.png";
import RightCurtain from "src/pages/Home/assets/images/right-curtain.png";
import MobileRightIcon from "src/pages/Home/assets/images/rightArrowIcon.svg";
import classes from "./styles.module.scss";

const data = [
  {
    img: Game1,
    title: "Wish Lamp$",
    active: true,
  },
  {
    img: Game2,
    title: "Coming Soon",
    active: false,
  },
  {
    img: Game3,
    title: "Coming Soon",
    active: false,
  },
  {
    img: Game4,
    title: "Coming Soon",
    active: false,
  },
  {
    img: Game5,
    title: "Coming Soon",
    active: false,
  },
];

const GameList = () => {
  const navigate = useNavigate();
  const sliderRef = useRef<Slider | null>(null);
  const [state, setState] = useState(0);

  const settings = {
    dots: false,
    infinite: false,
    speed: 300,

    slidesToShow: 2.5,
    slidesToScroll: 1,
    afterChange: (current: number) => {
      setState(current);
    },

    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className={classes.wrapper}
      style={{
        backgroundImage: `url(${GameListBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={classes.container}>
        <div className={classes.title}>
          <p>THE GAMES</p>
        </div>
        <div>
          <img
            onClick={() => sliderRef.current?.slickNext()}
            className={classes.MobileRightIcon}
            alt="right-icon"
            src={MobileRightIcon}
          />

          <div className={classes.sliderWrapper}>
            {state < 2.5 && (
              <div
                className={classes.shadows}
                style={{
                  right: "-16px",
                  background:
                    "linear-gradient(270deg, #141414 33.23%, rgba(20, 20, 20, 0.04) 94.61%)",
                }}
              ></div>
            )}
            {state !== 0 && (
              <div
                className={classes.shadows}
                style={{
                  left: "-16px",
                  background:
                    "linear-gradient(90deg, #141414 33.23%, rgba(20, 20, 20, 0.04) 94.61%)",
                }}
              ></div>
            )}
            <div
              className={classes.leftIcon}
              onClick={() => sliderRef.current?.slickPrev()}
            >
              <img alt="left-icon" src={LeftIcon} />
            </div>
            <div
              className={classes.RightIcon}
              onClick={() => sliderRef.current?.slickNext()}
            >
              <img alt="right-icon" src={RightIcon} />
            </div>
            <Slider ref={sliderRef} {...settings}>
              {data?.map((game, i) => (
                <div key={i} className={classes.game}>
                  <div className={classes.gameWrapper}>
                    <div
                      className={classes.imageWrapper}
                      style={{
                        backgroundImage: `url(${game.img})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "80% 80%",
                        backgroundPosition: `${i === 1 ? "82%" : "center"}`,
                        backgroundColor: "white",
                      }}
                    >
                      {!game.active && (
                        <>
                          <img
                            className={classes.leftCurtain}
                            src={LeftCurtain}
                            alt="left-curtain"
                          />
                          <img
                            className={classes.rightCurtain}
                            src={RightCurtain}
                            alt="right-curtain"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className={classes.cardBottom}>
                    <p>{game.title}</p>
                    <div
                      className={cx(classes.findButton, {
                        [classes.showButton]: game.active,
                      })}
                    >
                      <Button
                        onClick={() => navigate("/preview")}
                        buttonColor="secondary"
                        buttonText="Find out more"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameList;

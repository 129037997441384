import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";
import React, { useRef } from "react";
import Slider, { Settings } from "react-slick";
import { PurchasedImage } from "src/types/clue";
import classes from "./ResultImageSlider.module.scss";

type Props = {
  images: PurchasedImage[];
  state: number;
  settings: Settings;
  iconSize?: SizeProp | undefined;
  className?: string;
};

const ResultImageSlider: React.FC<Props> = ({
  images,
  state,
  settings,
  iconSize,
  className = "",
}) => {
  const sliderRef = useRef<Slider | null>(null);

  return (
    <div className={cn(classes.content, className)}>
      <div
        className={cn(classes.leftIcon, "leftIcon", {
          [classes.disabled]: state === 0,
        })}
        onClick={() => sliderRef.current?.slickPrev()}
      >
        <FontAwesomeIcon
          size={iconSize}
          fill="white"
          icon={faCircleChevronLeft}
          color="#8053EA"
        />
      </div>
      <Slider className={classes.slider} ref={sliderRef} {...settings}>
        {images.map((post, i) => {
          return (
            <div className={classes.imageWrapper} key={i}>
              <img src={post.url} />
            </div>
          );
        })}
      </Slider>
      <div
        className={cn(classes.rightIcon, "rightIcon", {
          [classes.disabled]: state === images.length - 1,
        })}
        onClick={() => sliderRef.current?.slickNext()}
      >
        <FontAwesomeIcon
          size={iconSize}
          fill="white"
          icon={faCircleChevronRight}
          color="#8053EA"
        />
      </div>
    </div>
  );
};

export default ResultImageSlider;

import cn from "classnames";
import React from "react";
import { ReactComponent as ArrowLeftDownIcon } from "src/assets/icons/arrows-left-down-icon.svg";
import { ReactComponent as EyeIcon } from "src/assets/icons/eye-icon.svg";
import { ReactComponent as ShareIcon } from "src/assets/icons/share-icon.svg";
import { PostTag, PostTagWrapper } from "src/components/Tag";
import { MediaInfo } from "src/types/media";
import { getPostDate } from "src/utils/date";
import { getMediaTags } from "src/utils/media";
import { numberFormatter } from "src/utils/number";
import { useWindowSize } from "src/utils/useWindowSize";
import Description from "../Description";
import OtherAction from "../OtherAction";
import SocialButton from "../SocialButton";
import SocialFollow from "../SocialFollow";
import SocialLove from "../SocialLove";
import classes from "./MediaDetail.module.scss";

interface Props {
  data?: MediaInfo;
  onReportMedia: (reason: string, comment: string, cb: () => void) => void;
  reportLoading?: boolean;
  onLoveChanged: (val: boolean) => void;
  onRepost?: () => void;
  isReposted?: boolean;
  displayActions?: string[];
  isPublic?: boolean;
  onFollow?: (state: boolean) => boolean | void;
  onShare?: () => void;
  followsCount?: number;
  setIsShareModal: (val: boolean) => void;
  handleUserProfileClick: () => void;
  isDisableUserProfile: boolean;
}

const MediaInformation = ({
  data,
  onReportMedia,
  reportLoading,
  onLoveChanged,
  onRepost,
  isReposted,
  displayActions = [],
  isPublic,
  onFollow,
  onShare,
  followsCount,
  setIsShareModal,
  handleUserProfileClick,
  isDisableUserProfile,
}: Props) => {
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  const tags = getMediaTags(data?.tags || []);
  const hasDisplayActions = displayActions?.length > 0;
  const viewEl = (
    <>
      {isMobile && <EyeIcon />}
      {numberFormatter(data?.views_count || 0, 1)}
      {!isMobile && <> view{data?.views_count !== 1 && "s"}</>}
    </>
  );
  const shareEl = (
    <>
      <ShareIcon />
      {numberFormatter(data?.share_count || 0, 1)}
    </>
  );
  const userProfileEl = (
    <>
      <div
        onClick={handleUserProfileClick}
        className={cn(classes.userProfile, {
          "pointer-default": isDisableUserProfile,
        })}
      >
        {!!data?.author?.avatar && (
          <img src={data?.author?.avatar} alt="profile-pic" />
        )}
        <span>
          <span>{data?.author?.username}</span>
          <span>
            • {numberFormatter(followsCount || 0, 1)} Follower
            {followsCount !== 1 && "s"}
          </span>
        </span>
      </div>
    </>
  );
  const socialButtonsEl = (
    <div className={classes.socialButtons}>
      {(!hasDisplayActions ||
        (hasDisplayActions && displayActions.includes("love"))) && (
        <SocialLove
          isLoved={!!data?.has_liked}
          setIsLoved={onLoveChanged}
          likeCount={data?.likes_count}
          isPublic={isPublic}
        />
      )}
      {(!hasDisplayActions ||
        (hasDisplayActions && displayActions.includes("repost"))) && (
        <SocialButton
          icon={<ArrowLeftDownIcon />}
          title="Repost"
          onClick={onRepost}
          active={isReposted}
        />
      )}
      {(!hasDisplayActions ||
        (hasDisplayActions && displayActions.includes("share"))) && (
        <SocialButton
          icon={<ShareIcon />}
          title="Share"
          onClick={
            isPublic
              ? onShare
              : () => {
                  setIsShareModal(true);
                }
          }
        />
      )}
      {(!hasDisplayActions ||
        (hasDisplayActions && displayActions.includes("follow"))) && (
        <SocialFollow isPublic={true} onClick={onFollow} />
      )}
      {(!hasDisplayActions ||
        (hasDisplayActions && displayActions.includes("report"))) && (
        <OtherAction
          onReportMedia={onReportMedia}
          reportLoading={reportLoading}
        />
      )}
    </div>
  );

  return (
    <>
      <div className={classes.info}>
        <div className={classes.top}>
          {isMobile ? (
            <>
              <span className={classes.viewMobile}>
                <span>{viewEl}</span>
                <span>{shareEl}</span>
              </span>
            </>
          ) : (
            userProfileEl
          )}
          <div>
            <span>
              posted: {getPostDate(data?.posted_date)}
              {!isMobile && <> • {viewEl}</>}
            </span>
          </div>
        </div>
        <div className={classes.middle}>
          <span>{data?.title}</span>
          {!isMobile && socialButtonsEl}
        </div>
        <Description className={classes.bottom} content={data?.desc || ""} />
        {tags.length > 0 && (
          <PostTagWrapper>
            {tags.map((tag: string, index: number) => {
              return <PostTag key={index}>{tag}</PostTag>;
            })}
          </PostTagWrapper>
        )}
        {isMobile && (
          <div className={classes.userProfileWrapperMobile}>
            {userProfileEl}
            {socialButtonsEl}
          </div>
        )}
      </div>
    </>
  );
};

export default MediaInformation;

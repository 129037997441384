import cn from "classnames";
import React from "react";
import Season from "./Season";
import classes from "./SeasonBlock.module.scss";

interface Props {
  className?: string;
  season1: any;
  season2: any;
  isInactive?: boolean;
  seasonGap?: number;
}

const SeasonBlock = ({
  className = "",
  season1,
  season2,
  isInactive,
  seasonGap,
}: Props) => {
  const season1DefaultLabel = season1?.defaultLabel || "";
  const season1Name = season1?.name || "";
  const season1ActivateDate = season1?.activateDate || "";
  const season1Status = season1?.status || "";
  const season1Prize = season1?.prize || "";
  const season1Lamp = season1?.lamp || null;
  const season2DefaultLabel = season2?.defaultLabel || "";
  const season2Name = season2?.name || "";
  const season2ActivateDate = season2?.activateDate || "";
  const season2Status = season2?.status || "";
  const season2Prize = season2?.prize || "";
  const season2Lamp = season2?.lamp || null;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isInactive]: isInactive,
        },
        className
      )}
    >
      <Season
        name={season1Name}
        status={season1Status}
        activateDate={season1ActivateDate}
        prize={season1Prize}
        lamp={season1Lamp}
        defaultLabel={season1DefaultLabel}
        gap={seasonGap}
      />
      <Season
        name={season2Name}
        status={season2Status}
        activateDate={season2ActivateDate}
        prize={season2Prize}
        lamp={season2Lamp}
        defaultLabel={season2DefaultLabel}
        gap={seasonGap}
      />
    </div>
  );
};

export default SeasonBlock;

import cn from "classnames";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import classes from "./WarningModal.module.scss";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  className?: string;
  cancelText: string;
  submitText: string;
  title: string;
  children: React.ReactNode;
  submitButonProps?: any;
  cancelButonProps?: any;
}

const WarningModal = ({
  isOpen,
  onClose,
  onSubmit,
  cancelText = "No",
  className,
  submitText = "Yes",
  title,
  children,
  cancelButonProps = {},
  submitButonProps = {},
}: ModalProps) => {
  return (
    <Modal
      backdrop
      className={cn(classes.modalWrapper, className)}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
    >
      <div className={classes.content}>
        <h2>{title}</h2>
        <div>{children}</div>
      </div>
      <div className={classes.footer}>
        <Button
          buttonText={cancelText}
          buttonColor="secondary-light"
          weight="semibold"
          variant="link"
          onClick={onClose}
          {...cancelButonProps}
        />
        <Button
          buttonText={submitText}
          buttonColor="purple"
          weight="semibold"
          onClick={onSubmit}
          size="xs"
          {...submitButonProps}
        />
      </div>
    </Modal>
  );
};

export default WarningModal;

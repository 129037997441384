import cn from "classnames";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "src/components/Spinner";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import MediaDetail from "src/pages/Watch/components/MediaDetail";
import { actionCb } from "src/utils/action";
import classes from "./SharePost.module.scss";
import AuthModal from "./components/AuthModal";
import { useActions, useIndexData } from "./selectorData";
import { getPrivatePath } from "./utils";

const SharePost = () => {
  const { getSharePost } = useActions();
  const { sharedMediaDetail, sharedMediaDetailLoading, previousPostId } =
    useIndexData();
  const userProfile = useUserProfile();
  const userProfileData = userProfile.user;
  const userProfileId = userProfileData?.id;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const postChannel = get(sharedMediaDetail, "channels")?.[0];
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const privatePath = String(searchParams.get("private-path") || "");
  const postId = String(searchParams.get("id"));
  const alternativePrivatePath = getPrivatePath(
    postChannel?.slug,
    postId,
    postChannel?.title
  );
  const noLoading = previousPostId === postId && !userProfileId;
  useEffect(() => {
    if (userProfileId) {
      if (privatePath) {
        navigate(privatePath);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);
  useEffect(() => {
    getSharePost(
      postId,
      actionCb((res: any) => {
        if (userProfileId && !!postId && !privatePath) {
          const channel = get(res, "data.post.channels")?.[0];
          const tPrivatePath = getPrivatePath(
            channel?.slug,
            postId,
            channel?.title
          );
          navigate(tPrivatePath);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.wrapper}>
        {sharedMediaDetailLoading && !noLoading && (
          <Spinner wholePage wrapperClassName={cn(classes.loading)} />
        )}
        <MediaDetail
          fixedVideo={false}
          onCloseFixed={() => {}}
          type={sharedMediaDetail?.media_type}
          data={sharedMediaDetail}
          onReportMedia={() => {}}
          onLoveChanged={() => {
            setIsOpen(true);
          }}
          onRepost={() => {}}
          onShare={() => {
            setIsOpen(true);
          }}
          onFollow={() => {
            setIsOpen(true);
          }}
          onLink={() => {
            setIsOpen(true);
          }}
          isReposted={false}
          displayActions={["love", "share", "follow"]}
          isPublic
          isShare
        />
      </div>
      <AuthModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        privatePath={privatePath || alternativePrivatePath}
      />
    </>
  );
};

export default SharePost;

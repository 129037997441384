import cn from "classnames";
import classes from "./PostView.module.scss";

interface Props {
  title: React.ReactNode | string;
  content: React.ReactNode | string;
  className?: string;
}

const ViewItem = ({ title, content, className }: Props) => {
  return (
    <div className={cn(classes.viewItem, className)}>
      <span>{title}</span>
      {content}
    </div>
  );
};

export default ViewItem;

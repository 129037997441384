import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import Text from "src/components/Text";

import EditProfile from "./EditProfile";
import classes from "./Profile.module.scss";
import ViewProfile from "./ViewProfile";

const Profile = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Text color="#fff" size={30} fontFamily="milonga">
          My Profile
        </Text>
        {pathname === "/user/profile" && (
          <div
            className={classes.edit}
            onClick={() => navigate("/user/profile/edit")}
          >
            <Text color="#fff" size={20}>
              Edit
            </Text>
            <FontAwesomeIcon color="#fff" icon={faPencil} />
          </div>
        )}
      </div>
      <div className={classes.contentWrapper}>
        {pathname === "/user/profile" && <ViewProfile />}
        {pathname === "/user/profile/edit" && <EditProfile />}
      </div>
    </div>
  );
};

export default Profile;

import OSvg from "src/assets/icons/game/o-icon.svg";
import XSvg from "src/assets/icons/game/x-icon.svg";
import { GAME_HEADER_TIME_UP_CLASSNAME } from "src/helpers/constants";

type Params = {
  onStart?: () => void;
  onRestart?: () => void;
  onEnd?: () => void;
  onSuccess?: () => void;
  onDraw?: () => void;
  answer?: string;
};

export const initGame = ({
  onStart,
  onRestart,
  onEnd,
  onSuccess,
  onDraw,
  answer,
}: Params) => {
  const totalTimeOut = 60;

  let setIntervalId: any;
  let timeout = totalTimeOut;

  const xImage = `<img src="${XSvg}" />`;
  const oImage = `<img src="${OSvg}" />`;

  const scoreEl: any = document.querySelector("#clue-game-score");

  const startGamePopup: any = document.querySelector("#start_game");
  const startGameButton: any = document.querySelector("#bt_start_game");

  const failGamePopup: any = document.querySelector("#fail_game");
  const restartGameButton: any = document.querySelector("#bt_restart_game");

  const successGamePopup: any = document.querySelector("#success_game");
  const errorMessage: any = document.querySelector("#error_message");

  const countDown = () => {
    timeout -= 1;
    const displayTimeout = timeout < 10 ? `0${timeout}` : timeout;
    scoreEl.innerHTML = `0:${displayTimeout}`;

    if (timeout === 0) {
      failGamePopup.classList.remove("hide");
      scoreEl.classList.add(GAME_HEADER_TIME_UP_CLASSNAME);
      errorMessage.innerHTML = `Oops, you ran out of time.`;
      clearInterval(setIntervalId);
      if (onEnd) onEnd();
    }
  };

  const GAME: any = {
    // Game State variables
    isComputerPlaying: false,
    isGameOver: false,
    numberOfPlayedSquares: 0,

    // original Game Board;
    gameBoard: [null, null, null, null, null, null, null, null, null],

    // the HTML game square elements
    squares: $(".square"),

    winningCombos: [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ],

    // update the Game board array
    updateGameBoard: function (index: number, value: any, el: any) {
      this.gameBoard[index] = value;
      el.html(value);
    }, // end updateGameBoard

    // initialize the game
    init: function () {
      // clear the squares
      this.squares.each(function (this: any) {
        $(this).html("");
      });

      this.gameBoard = [null, null, null, null, null, null, null, null, null];
      this.isComputerPlaying = false;
      this.isGameOver = false;
      this.numberOfPlayedSquares = 0;
    }, // end GAME.init()

    checkWin: function (value: any) {
      let winner = false;

      for (let combo = 0; combo < this.winningCombos.length; combo++) {
        const a = this.winningCombos[combo][0];
        const b = this.winningCombos[combo][1];
        const c = this.winningCombos[combo][2];

        if (GAME.gameBoard[a] === GAME.gameBoard[b]) {
          if (GAME.gameBoard[b] === GAME.gameBoard[c]) {
            if (GAME.gameBoard[a] !== null) {
              this.endGame(value);
              winner = true;
            } // end if
          } // end if
        } // end if
      } // end for

      return winner;
    },

    endGame: function (value: any) {
      if (value === "X") {
        successGamePopup.classList.remove("hide");
        clearInterval(setIntervalId);
        if (onSuccess) onSuccess();
        return;
      } else if (value === "O") {
        failGamePopup.classList.remove("hide");
        errorMessage.innerHTML = `Sorry, you didn’t win this round.`;
        clearInterval(setIntervalId);
        if (onEnd) onEnd();
      } else {
        failGamePopup.classList.remove("hide");
        errorMessage.innerHTML = `Oops, you tied.`;
        clearInterval(setIntervalId);
        if (onDraw) onDraw();
      }

      this.isGameOver = true;

      // const that = this;

      // var playAgain = confirm("Click Ok to play again...");

      // if (playAgain === true) {
      //   this.init();
      // }
    },
  }; // end GAME

  // AI Object
  //
  // 1. play turn
  // --a: choose best square to play

  const AI = {
    getBestMove: function () {
      // use a heuristic algorithm to determine the best play

      //initial rank based on number of winning combos
      //that go through the cell
      const cellRank = [3, 2, 3, 2, 4, 2, 3, 2, 3];

      //demote any cells already taken
      for (let i = 0; i < GAME.gameBoard.length; i++) {
        if (GAME.gameBoard[i] !== null) {
          cellRank[i] -= 99;
        } // end if
      } // end for

      //look for partially completed combos
      for (let combo = 0; combo < GAME.winningCombos.length; combo++) {
        const a = GAME.winningCombos[combo][0];
        const b = GAME.winningCombos[combo][1];
        const c = GAME.winningCombos[combo][2];

        //if any two cells in a combo are
        //non-blank and the same value,
        //promote the remaining cell
        if (GAME.gameBoard[a] === GAME.gameBoard[b]) {
          if (GAME.gameBoard[a] !== null) {
            if (GAME.gameBoard[c] === null) {
              cellRank[c] += 10;
            } // end if
          } // end if
        } // end if

        if (GAME.gameBoard[a] === GAME.gameBoard[c]) {
          if (GAME.gameBoard[a] !== null) {
            if (GAME.gameBoard[b] === null) {
              cellRank[b] += 10;
            } // end if
          } // end if
        } // end if

        if (GAME.gameBoard[b] === GAME.gameBoard[c]) {
          if (GAME.gameBoard[b] !== null) {
            if (GAME.gameBoard[a] === null) {
              cellRank[a] += 10;
            } // end if
          } // end if
        } // end if
      } // end for

      //determine the best move to make
      let bestCell = -1;
      let highest = -999;

      //step through cellRank to find the best available score
      for (let j = 0; j < GAME.gameBoard.length; j++) {
        if (cellRank[j] > highest) {
          highest = cellRank[j];
          bestCell = j;
        } // end if
      } // end for

      return bestCell;
    },

    playTurn: function () {
      GAME.isComputerPlaying = true;

      const theSquareToPlay = this.getBestMove();

      const $theSelectedSquare = $(".square-0" + theSquareToPlay);

      GAME.numberOfPlayedSquares++;
      // slow the computer down a bit
      setTimeout(function () {
        GAME.updateGameBoard(theSquareToPlay, oImage, $theSelectedSquare);
        GAME.checkWin("O");
        GAME.isComputerPlaying = false;
      }, 500);
    }, // end playTurn()
  }; // end AI

  // Click event handlers
  GAME.squares.click(function (this: any) {
    const squareIndexValue = parseInt($(this).data("index"));

    function checkIfTurnIsReady() {
      if (GAME.isGameOver) {
        return false;
      }
      if (GAME.isComputerPlaying) {
        return false;
      }
      if (GAME.gameBoard[squareIndexValue] === null) {
        return true;
      }
    }

    const isTurnReady = checkIfTurnIsReady();

    if (isTurnReady) {
      GAME.updateGameBoard(squareIndexValue, xImage, $(this));
      GAME.numberOfPlayedSquares++;
      const winner = GAME.checkWin("X");
      if (winner === false && GAME.numberOfPlayedSquares < 9) {
        AI.playTurn();
      } else if (GAME.numberOfPlayedSquares === 9) {
        GAME.endGame("draw");
      }
    } // endif isTurnReady
  }); // end GAME.squares.click event handler

  startGameButton.addEventListener("click", () => {
    if (!answer) {
      startGamePopup.classList.add("hide");
      scoreEl.classList.remove(GAME_HEADER_TIME_UP_CLASSNAME);
      setIntervalId = setInterval(countDown, 1000);
      if (onStart) onStart();
    }
  });

  restartGameButton.addEventListener("click", () => {
    if (!answer) {
      failGamePopup.classList.add("hide");
      scoreEl.classList.remove(GAME_HEADER_TIME_UP_CLASSNAME);
      GAME.init();

      // reset timeout
      timeout = totalTimeOut;
      setIntervalId = setInterval(countDown, 1000);
      if (onRestart) onRestart();
    }
  });

  return { setIntervalId };
};

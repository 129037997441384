import React from "react";

export const useSearchClickOutside = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    const parentClassNameList = e.target.parentNode?.classList?.value;
    if (
      parentClassNameList?.includes("search-inactive") ||
      parentClassNameList?.includes("search-box-wrapper")
    ) {
      callback(true);
    } else if (ref?.current && !ref.current.contains(e.target)) {
      callback(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import * as stripeJs from "@stripe/stripe-js";
import cx from "classnames";
import { Form, Formik, FormikProps } from "formik";
import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import Button from "src/components/Button";
import Input from "src/components/Input";
import "./payment.scss";
import Select from "src/components/Select";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { OptionType } from "src/pages/Auth/Profile/types";
import { addPaymentMethods } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import { useWindowSize } from "src/utils/useWindowSize";
import { countries } from "./constant";
import classes from "./styles.module.scss";
import { ErrorMessageProps, FormProps, Props } from "./types";

export type StripeStateProps =
  | stripeJs.StripeCardCvcElementChangeEvent
  | stripeJs.StripeCardNumberElementChangeEvent
  | stripeJs.StripeCardExpiryElementChangeEvent;

type StripeElementProps = {
  cardExpiry: StripeStateProps | null;
  cardCvc: StripeStateProps | null;
  cardNumber: StripeStateProps | null;
};

const Payment: React.FC<Props> = ({ handleSelectedPlan, selectedPlans }) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { width = 0 } = useWindowSize();
  const actions = useStoreActions({
    addPaymentMethods,
  });

  const isMobile = width <= 768;
  const userDetails = useReducerData("auth", "user", {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorMessageProps>({
    number: "",
    date: "",
    code: "",
  });

  const initialStripeValue = {
    cardExpiry: null,
    cardCvc: null,
    cardNumber: null,
  };

  const [stripeState, setStripeState] =
    useState<StripeElementProps>(initialStripeValue);
  const [stripeError, setStripeError] = useState<string | null>();

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.payment_info_signup);
  }, []);

  useEffect(() => {
    if (!selectedPlans.plan.id) {
      navigate("/subscription/plan");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlans]);

  const fontSize = isMobile ? "14px" : "18px";
  const lineHeight = isMobile ? "16px" : "18px";

  const customCardStyle = {
    base: {
      fontSize: fontSize,
      color: "#FFFFFF",
      "::placeholder": {
        color: "#A1A1A1",
        fontSize: fontSize,
        lineHeight: lineHeight,
      },
    },
    invalid: {
      iconColor: "#333333",
      color: "#FFFFFF",
    },
    complete: {
      iconColor: "#333333",
    },
  };

  const handleInputChange = (event: StripeStateProps) => {
    setStripeState((prev) => ({
      ...prev,
      [event.elementType]: event,
    }));
  };

  const handle_blur = (event: { elementType: string }) => {
    const cardNumberError = stripeState?.cardNumber?.empty
      ? "Card number is required"
      : stripeState?.cardNumber
      ? "Your card number is incomplete"
      : "Card number is required";
    const cardExpiryError = stripeState?.cardExpiry?.empty
      ? "Card expiry is required"
      : stripeState?.cardExpiry
      ? "Your card's expiry date is incomplete"
      : "Card expiry is required";
    const cardCvcError = stripeState?.cardCvc?.empty
      ? "Card cvc is required"
      : stripeState?.cardCvc
      ? "Your card's security code is incomplete"
      : "Card cvc is required";

    switch (event.elementType) {
      case "cardNumber":
        return setError({
          ...error,
          number: stripeState?.cardNumber?.complete
            ? ""
            : stripeState?.cardNumber?.error?.message || cardNumberError,
        });
      case "cardExpiry":
        return setError({
          ...error,
          date: stripeState?.cardExpiry?.complete
            ? ""
            : stripeState?.cardExpiry?.error?.message || cardExpiryError,
        });
      case "cardCvc":
        return setError({
          ...error,
          code: stripeState?.cardCvc?.complete
            ? ""
            : stripeState?.cardCvc?.error?.message || cardCvcError,
        });
      default:
        return error;
    }
  };

  const paymentSchema = Yup.object().shape({
    name_on_card: Yup.string().required("Name is required"),
    zip_code: Yup.string().required("zip code is required"),
    country: Yup.object()
      .required("Please select country")
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable(),
  });

  const handleSubmit = async (values: FormProps) => {
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    const cardNumberElement = await elements?.getElement(CardNumberElement);
    if (!cardNumberElement) {
      setLoading(false);
      return;
    }
    const { token, error } = await stripe.createToken(cardNumberElement, {
      name: values.name_on_card,
    });
    if (error) {
      setStripeError(error.message);
      setLoading(false);
      return;
    }
    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: values?.name_on_card,
          email: userDetails?.email,
          phone: userDetails?.phone,
          address: {
            country: values?.country?.value,
            postal_code: values?.zip_code,
          },
        },
      });
    if (paymentMethodError) {
      setStripeError(paymentMethodError.message);
      setLoading(false);
      return;
    }
    const newPaymentDetails = {
      payment_method_id: paymentMethod?.id,
      is_default: true,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res: any = await actions.addPaymentMethods(newPaymentDetails);
    const status = get(res, "status", 0);
    const message = get(res, "data.message", null);
    if (!status) {
      setStripeError(message);
      setLoading(false);
      return;
    }
    const paymentDetails = {
      payment_id: paymentMethod?.id,
      last4: token?.card?.last4,
      name_on_card: values.name_on_card,
    };
    handleSelectedPlan(paymentDetails);
    navigate("/subscription/confirm");
    setLoading(false);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputs}>
        <Formik
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={paymentSchema}
          initialValues={{
            name_on_card: "",
            zip_code: "",
            country: null as unknown as OptionType,
          }}
        >
          {({
            values,
            touched,
            errors,
            handleBlur,
            handleChange,
            isValid,
            dirty,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<FormProps>) => (
            <Form className={cx(classes.formWrapper)}>
              <div>
                <FormGroup
                  className={cx("text-start mb-3", classes.formGroupInput)}
                >
                  <Input
                    labelClassName={classes.label}
                    label="Name On Card"
                    value={values.name_on_card}
                    placeholder="John Doe"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.name_on_card}
                    touched={touched.name_on_card}
                    name="name_on_card"
                  />
                </FormGroup>
                <div className={classes.stripeInputs}>
                  <div
                    className={classes.cardNumber}
                    data-testid="stripe_card_number"
                  >
                    <label className="mb-2">Card Number</label>
                    <CardNumberElement
                      className={cx("inputFieldStripe", {
                        ["stripeError"]: error.number,
                      })}
                      options={{
                        placeholder: "16 digits",
                        style: customCardStyle,
                      }}
                      onChange={(event) => {
                        handleInputChange(event);
                      }}
                      onBlur={(event) => {
                        handle_blur(event);
                      }}
                    />
                    <p className={classes.errorMessage}>{error.number}</p>
                  </div>
                  <div className={classes.expiryAndCvc}>
                    <div
                      className={classes.cvcExpiry}
                      data-testid="stripe_exp_date"
                    >
                      <label className="mb-2">Exp</label>
                      <CardExpiryElement
                        className={cx("inputFieldStripe", {
                          ["stripeError"]: error.date,
                        })}
                        options={{
                          placeholder: "MM / YY",
                          style: customCardStyle,
                        }}
                        onBlur={(event) => {
                          handle_blur(event);
                        }}
                        onChange={(event) => {
                          handleInputChange(event);
                        }}
                      />
                      <p className={classes.errorMessage}>{error.date}</p>
                    </div>
                    <div data-testid="stripe_cvc">
                      <label className="mb-2">CVC</label>
                      <CardCvcElement
                        className={cx("inputFieldStripe", {
                          ["stripeError"]: error.code,
                        })}
                        options={{
                          placeholder: "123",
                          style: customCardStyle,
                        }}
                        onBlur={(event) => {
                          handle_blur(event);
                        }}
                        onChange={(event) => {
                          handleInputChange(event);
                        }}
                      />
                      <p className={classes.errorMessage}>{error.code}</p>
                    </div>
                  </div>
                </div>
                <div className={classes.address}>
                  <FormGroup className={classes.selectWrapper}>
                    <Select
                      label="Country"
                      name="country"
                      placeholder="Type here"
                      value={values?.country}
                      error={errors?.country as string}
                      touched={touched?.country as unknown as boolean}
                      leftIconClass={classes.icon}
                      onBlur={() => {
                        setFieldTouched("country", true);
                      }}
                      onChange={(selectedOption) => {
                        if (setFieldValue) {
                          setFieldValue(
                            "country",
                            selectedOption as OptionType
                          );
                        }
                      }}
                      options={countries}
                    />
                  </FormGroup>
                  <FormGroup className={classes.selectWrapper}>
                    <Input
                      labelClassName={classes.label}
                      label="Zip Code"
                      value={values.zip_code}
                      placeholder="Type here"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.zip_code}
                      touched={touched.zip_code}
                      name="zip_code"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className={classes.buttonWrapper}>
                {stripeError && (
                  <p className={classes.stripeErrorMessage}>{stripeError}</p>
                )}
                <Button
                  buttonText="Next"
                  buttonColor="secondary"
                  loading={loading}
                  disabled={
                    !isValid ||
                    !dirty ||
                    !stripeState.cardCvc?.complete ||
                    !stripeState.cardExpiry?.complete ||
                    !stripeState.cardNumber?.complete
                  }
                  type="submit"
                  buttonClassName={classes.button}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Payment;

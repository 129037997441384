import cx from "classnames";
import React from "react";
import { ClueProps } from "src/pages/Play/Clue/types";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./styles.module.scss";

type ImageQuestionProps = { clue: ClueProps; isModal?: boolean };

const ImageQuestion: React.FC<ImageQuestionProps> = ({
  isModal = false,
  clue,
}) => {
  const { width = 0 } = useWindowSize();
  const getImageUrl = () => {
    if (width <= 640) {
      return clue?.media[0]?.small_image_path;
    } else if (width > 640 && width <= 1280) {
      return clue?.media[0]?.medium_image_path;
    } else {
      return clue?.media[0]?.large_image_path;
    }
  };
  return (
    <div
      className={cx(classes.wrapper, {
        [classes.modalQuestionWrapper]: isModal,
      })}
    >
      <img src={getImageUrl() || clue.url[0]} alt="img-question" />
    </div>
  );
};

export default ImageQuestion;

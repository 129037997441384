import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import wishLampIcon from "src/assets/images/wish-lamp-landing.png";
import Spinner from "src/components/Spinner";
import { PlanProps } from "src/pages/Subscription/Plans/Components/PlanCard/types";
import {
  getPaymentIntent,
  getSubscriptionPlans,
  postPaymentDetails,
} from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import StripeForm from "./StripeForm";
import classes from "./styles.module.scss";

const getStripePromise = () =>
  loadStripe(process.env.REACT_APP_STRIPE_PAYMENT_API_KEY as string);

const Payment = () => {
  const { width = 0 } = useWindowSize();
  const isMobile = width > 0 && width <= 768;
  const stripe = getStripePromise();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isFullAccess = searchParams.get("full_access") === "true";
  const [clientSecret, setClientSecret] = useState("");
  const [clientSecretLoader, setClientSecretLoader] = useState<boolean>(false);
  const {
    data: { plans },
    loading: plansLoading,
  } = useReducerData("auth", "subscriptionPlans", {}) as {
    data: { plans: PlanProps[] };
    loading: boolean;
  };

  const [selectedPlan, setSelectedPlan] = useState<PlanProps>({});

  const actions = useStoreActions({
    getSubscriptionPlans,
    postPaymentDetails,
    getPaymentIntent,
  });

  const options = {
    clientSecret,
  } as StripeElementsOptions;

  useEffect(() => {
    const onGetSubscriptionPlansSuccess = (res: any) => {
      if (res.status) {
        setSelectedPlan(res.data.plans[0]);
      }
    };
    actions.getSubscriptionPlans(onGetSubscriptionPlansSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedPlan.id) return;
    setClientSecretLoader(true);
    actions.postPaymentDetails(
      {
        payment_type: "payment_intent",
        payment_id: null,
        plan_id: selectedPlan.id || 1,
      },
      "",
      onPostPaymentDetailsSuccess
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);

  const onPostPaymentDetailsSuccess = (res: any) => {
    if (res.status) {
      actions.getPaymentIntent(
        res.data.subscription.id,
        onGetPaymentIntentSuccess
      );
      return;
    }
    setClientSecretLoader(false);
  };

  const onGetPaymentIntentSuccess = (res: any) => {
    if (res.status) {
      setClientSecret(res.data.payment_intent.client_secret);
      // scroll to bottom
      if (isMobile) {
        setTimeout(() => {
          window.scroll({
            top: 3000,
            behavior: "smooth",
          });
        }, 100);
      }
    }
    setClientSecretLoader(false);
  };

  const handleSelectedPlan = (plan: PlanProps) => {
    setSelectedPlan(plan);
  };

  return (
    <div>
      {plansLoading ? (
        <Spinner wholePage wrapperClassName={classes.loader} />
      ) : (
        <div className={classes.container}>
          <div className={classes.leftContainer}>
            <div className={classes.header}>
              <img src={wishLampIcon} alt={"logo"} />
            </div>
            <div className={classes.upperContainer}>
              <div className={classes.title}>Select your Subscription</div>
              <div className={classes.body}>
                Games are played 24/7 all year round.
                <br />
                You can join anytime.
              </div>
              <div className={classes.details}>
                You can cancel at anytime. Cancellation is effective at the end
                of your current subscription time period.
              </div>
            </div>
            <div className={classes.lowerContainer}>
              <div className={classes.title}>Select your Subscription:</div>
              <div className={classes.optionContainer}>
                {plans.map((item, index) => {
                  return (
                    <label htmlFor={item.price} key={index}>
                      <div
                        className={cn(classes.optionWrapper, {
                          [classes.selected]: selectedPlan.price === item.price,
                        })}
                      >
                        <div className={classes.leftWrapper}>
                          <input
                            type="radio"
                            id={item.price}
                            checked={item.price === selectedPlan.price}
                            onChange={() => handleSelectedPlan(item)}
                          />
                        </div>
                        <div className={classes.rightWrapper}>
                          <div
                            className={cn(classes.price, {
                              [classes.selected]:
                                selectedPlan.price === item.price,
                            })}
                          >
                            ${item.price} Per{" "}
                            {item.type === "monthly" ? "Month" : "Year"}
                          </div>
                          <div
                            className={cn(classes.details, {
                              [classes.selected]:
                                selectedPlan.price === item.price,
                            })}
                          >
                            {item.type === "monthly"
                              ? "Automatic renewal every 30 days"
                              : "Automatic renewal every 365 days.(Save $17.89)"}
                          </div>
                          {item.type === "yearly" && (
                            <div className={classes.saleContainer}>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                }}
                              >
                                SAVE
                              </div>
                              <div
                                style={{
                                  fontWeight: "900",
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginLeft: "5px",
                                }}
                              >
                                20
                                <span style={{ fontSize: "12px" }}>%</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </label>
                  );
                })}
              </div>
            </div>
            {isFullAccess && (
              <div className={classes.footerText}>
                Want to try it out first?{" "}
                <span
                  onClick={() => {
                    navigate("/play?free_trial_signup_success=true");
                  }}
                >
                  Try free trial
                </span>
              </div>
            )}
          </div>
          <div className={classes.rightContainer}>
            {clientSecretLoader || !clientSecret ? (
              <Spinner center />
            ) : (
              <Elements stripe={stripe} options={options}>
                <StripeForm selectedPlan={selectedPlan} />
              </Elements>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;

import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { trackEvent } from "src/utils/googleAnalytics";
import classes from "./styles.module.scss";

const GameDetails = ({ redirectLink = "" }) => {
  const navigate = useNavigate();
  const isSignupRedirect = redirectLink.includes("/auth/signup");

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.joinText}>JOIN AT ANY TIME</div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.title}>Nationwide Treasure Hunting Games</div>
        <div className={classes.subText}>
          We give players an opportunity to find real treasure in the real world
          and win <span>life changing prizes</span> and money all year long!
        </div>
        <div className={classes.detailsWrapper}>
          <div className={classes.details}>
            <span className={classes.subDetail_text}>‘WISH LAMP$’</span> is the
            first game we are releasing. We have developed more original and fun
            treasure hunting games that will soon follow. We will be starting in
            the US and then quickly rolling out our games around the world.
          </div>
          <div className={classes.details}>
            A percentage of all profits will be invested in people that are
            working to{" "}
            <span className={classes.subDetail}>
              make the world better for all.
            </span>
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => {
              navigate(redirectLink);
              if (isSignupRedirect) {
                trackEvent(
                  ANALYTICS_CONSTANTS.category.sign_up,
                  ANALYTICS_CONSTANTS.action.sign_up_full
                );
              }
            }}
            buttonClassName={classes.joinButton}
            buttonText="Join Now"
          />
          <Button
            onClick={() => {
              navigate(`${redirectLink}?free_trial=true`);
              if (isSignupRedirect) {
                trackEvent(
                  ANALYTICS_CONSTANTS.category.sign_up,
                  ANALYTICS_CONSTANTS.action.sign_up_free
                );
              }
            }}
            buttonClassName={classes.trialButton}
            buttonText="Try Free Trial"
          />
        </div>
      </div>
    </div>
  );
};

export default GameDetails;

import cx from "classnames";
import React from "react";

import { ClueProps } from "src/pages/Play/Clue/types";

import classes from "./styles.module.scss";

type AudioQuestionProps = { clue: ClueProps; isModal?: boolean };

const AudioQuestion: React.FC<AudioQuestionProps> = ({
  isModal = false,
  clue,
}) => {
  return (
    <div
      className={cx(classes.wrapper, {
        [classes.modalQuestionWrapper]: isModal,
      })}
    >
      {clue?.url && <audio src={clue?.url[0]} controls />}
    </div>
  );
};

export default AudioQuestion;

export type Option = {
  value: string;
  label: string;
  tooltip: string;
};

export const REPORT_OPTIONS: Option[] = [
  {
    value: "GAME_VIOLATION",
    label: "Game violation",
    tooltip: "Violation of Rules or Participation Agreement.",
  },
  {
    value: "SPAM",
    label: "Spam or misleading",
    tooltip:
      "Unsolicited, repetitive, or deceptive content aimed at misleading or tricking users.",
  },
  {
    value: "VIOLENT_CONTENT",
    label: "Violent or repulsive content",
    tooltip:
      "Includes graphic depictions of violence or disturbing imagery that may be unsettling or offensive to viewers.",
  },
  {
    value: "HATEFUL_CONTENT",
    label: "Hateful or abusive content",
    tooltip:
      "Contains material that promotes discrimination, harassment, or harmful behavior towards individuals or groups.",
  },
  {
    value: "BULLYING",
    label: "Harassment or bullying",
    tooltip:
      "Content that is repeated, unwelcome, and hurtful behavior intended to intimidate, humiliate, or harm others emotionally or psychologically.",
  },
  {
    value: "HARMFUL",
    label: "Harmful or dangerous acts",
    tooltip:
      "Content that promotes or encourages activities that could result in harm to oneself or others.",
  },
  {
    value: "MISINFORMATION",
    label: "Misinformation",
    tooltip:
      "False or misleading information that is spread without proper verification or credible sources.",
  },
  {
    value: "CHILD_ABUSE",
    label: "Child abuse",
    tooltip:
      "Harmful content that involves physical, emotional, or sexual mistreatment of children.",
  },
  {
    value: "TERRORISM",
    label: "Promotes terrorism",
    tooltip:
      "Material that encourages, supports, or glorifies violent acts or extremist ideologies with the intention of causing harm, fear, or disruption.",
  },
  {
    value: "NUDITY",
    label: "Nudity or Sexual content",
    tooltip:
      "Content that contains explicit or suggestive depictions of sexual activity or nudity.",
  },
  {
    value: "INFRINGES_RIGHTS",
    label: "Infringes my rights",
    tooltip:
      "Content or actions that may infringe upon your rights or violate your privacy.",
  },
];

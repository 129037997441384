import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { HintProps } from "src/api/clue";
import Card from "src/components/Card";
import Text from "src/components/Text";
import Typography from "src/components/Typography";
import { ClueProps } from "src/pages/Play/Clue/types";
import { useReducerData } from "src/store/hooks";
import classes from "./BaseInputBox.module.scss";
import ClueInput from "./ClueInput";

type Props = {
  currentClue?: ClueProps;
  clues: ClueProps[];
};

const BaseInputBox: React.FC<Props> = ({ currentClue, clues }) => {
  const { data } = useReducerData("clue", "purchaseHintList", {
    data: [],
  });

  const {
    hints = [],
    purchased_hints = 0,
    total_hints = 0,
  }: {
    hints: HintProps[];
    purchased_hints: number;
    total_hints: number;
  } = data;
  const PurchasedHintCount = (
    <div className={classes.purchasedHintCount}>
      <Text size={20} color="#8053EA" fontWeight="medium">
        {purchased_hints}/{total_hints} Hints Purchased{" "}
        <FontAwesomeIcon icon={faCheck} />
      </Text>
    </div>
  );

  const givenAnswerCount =
    currentClue?.answers?.filter((item) => item.answer !== null)?.length || 0;

  const isLastClue = clues?.[clues?.length - 1]?.id === currentClue?.id;
  const answerCount = currentClue?.answer_count || 0;

  if (answerCount === 0) return <></>;

  return (
    <Card className={classes.inputs}>
      <div className={classes.header}>
        <div className={classes.text}>
          <Typography className="mb-1" fontWeight="semibold" variant="p32">
            Enter your answer.
          </Typography>
          <Typography variant="p14">(unlimited attempts)</Typography>
        </div>
        <div className={classes.numberOfInputs}>
          {hints?.length !== 0 && PurchasedHintCount}
          {answerCount > 1 && (
            <Text fontWeight="semibold" size={40}>
              <sup>{givenAnswerCount}</sup>/
              <sub>{currentClue?.answer_count}</sub>
            </Text>
          )}
        </div>
      </div>
      {currentClue &&
        (currentClue?.answers || []).map(({ order, answer }, i) => {
          return (
            <ClueInput
              isLastClue={isLastClue}
              hints={hints}
              currentClue={currentClue}
              answer={answer || null}
              key={`answer-index-` + i}
              order={order}
              clueId={currentClue?.id}
              givenAnswers={currentClue?.answers}
            />
          );
        })}
    </Card>
  );
};

export default BaseInputBox;

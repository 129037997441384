import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Text from "src/components/Text";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { ROLE_CHECKING, ROLE_LEVEL } from "src/helpers/constants/role";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import classes from "./AccessInfo.module.scss";

export const ACCESS_LEVELS = [
  {
    type: ROLE_CHECKING.FREE_ACCESS,
    title: "Free Access Level",
    name: ROLE_LEVEL.FREE_ACCESS,
    list: [
      "No Cost",
      "TG$ News & Entertainment",
      "TG$ Channel Highlights",
      "User Generated Highlights",
      "Winner Highlights",
      "Games & Prizes Information",
      "TG$ Impact Investment Program",
    ],
  },
  {
    type: ROLE_CHECKING.FULL_ACCESS,
    title: "Full Access Level",
    name: ROLE_LEVEL.FULL_ACCESS,
    list: [
      "Subscription Plan Per Game",
      "Full Game Play Access",
      "Full Entertainment Content Access",
      "TG$ News & Entertainment",
      "TG$ Channel Highlights",
      "User Generated Highlights",
      "Winner Highlights",
      "Games & Prizes Information",
      "Weekly Prizes Access",
      "Grand Prize Access",
      "TG$ Impact Investment Program",
    ],
  },
  {
    type: ROLE_CHECKING.FREE_TRIAL,
    title: "Free Trial",
    name: ROLE_LEVEL.FREE_TRIAL,
    list: [
      "10 free clues per active region",
      "Unlimited access to watch all TG$ content",
      "No Time Limit",
      "Free Trial is not eligible to win weekly prizes or the grand prize",
    ],
  },
];

const AccessInfo = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/auth/signup");
  };

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.initial_signup);
  }, []);

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.cardWrapper}>
          {ACCESS_LEVELS.map((access, index) => {
            return (
              <div className={classes.card} key={index}>
                <Text
                  size={18}
                  fontFamily="impact"
                  color="#DEB771"
                  className={classes.title}
                >
                  {access.title}
                </Text>
                <div className={classes.list}>
                  {access.list.map((info, index) => {
                    return (
                      <Text
                        color="#ffffff"
                        size={16}
                        fontFamily="inter"
                        key={index}
                      >
                        <FontAwesomeIcon color="#DEB771" icon={faCircleCheck} />
                        {info}
                      </Text>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className={classes.text}>
          <Text color="#fff" size={24} fontWeight="semibold">
            Create your TG$ Account and then choose access level.
          </Text>
        </div>
      </div>

      <div className={classes.buttonWrapper}>
        <Button
          buttonText="Next"
          buttonColor="secondary"
          type="submit"
          onClick={handleNext}
          buttonClassName={classes.button}
        />
      </div>
    </div>
  );
};

export default AccessInfo;

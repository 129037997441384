import { Reducer } from "redux";
import { ANNOUNCEMENT_IDS } from "src/helpers/constants";
import { getMoreAnnouncements } from "src/utils/reducer/announcement";
import { handleData } from "../middlewares/handleData";
import Types from "../types/announcement";

const initialState = {
  announcements: {
    loading: false,
    data: {},
    error: "",
  },
  currentAnnouncements: {
    loading: false,
    data: {},
    error: "",
  },
  pastAnnouncements: {
    loading: false,
    data: {},
    error: "",
  },
};

const AnnouncementReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.GET_CURRENT_ANNOUNCEMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          currentAnnouncements: {
            ...prevState.currentAnnouncements,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            currentAnnouncements: {
              loading: false,
              error: "",
              // data: payload.data,
              data: meta?.payload?.isMore
                ? getMoreAnnouncements(
                    prevState,
                    payload,
                    "currentAnnouncements",
                    ANNOUNCEMENT_IDS.CURRENT
                  )
                : payload.data,
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          currentAnnouncements: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_PAST_ANNOUNCEMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          pastAnnouncements: {
            ...prevState.pastAnnouncements,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            pastAnnouncements: {
              loading: false,
              error: "",
              data: meta?.payload?.isMore
                ? getMoreAnnouncements(
                    prevState,
                    payload,
                    "pastAnnouncements",
                    ANNOUNCEMENT_IDS.PAST
                  )
                : payload.data,
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          pastAnnouncements: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });

    default:
      return state;
  }
};

export default AnnouncementReducer;

import { Reducer } from "redux";
import { handleData } from "../middlewares/handleData";
import Types from "../types/dashboard";

const initialState = {
  dashboardWatch: {
    loading: false,
    data: {},
    error: "",
  },
  dashboardPrizes: {
    loading: false,
    data: {},
    error: "",
  },
  dashboardAnnouncements: {
    loading: false,
    data: {},
    error: "",
  },
  dashboardEngagements: {
    loading: false,
    data: {},
    error: "",
  },
  newCluesAvailable: {
    loading: false,
    data: 0,
    error: "",
  },
};

const DashboardReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_DASHBOARD_WATCH:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          dashboardWatch: {
            ...prevState.dashboardWatch,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          dashboardWatch: {
            loading: false,
            error: "",
            data: payload.data || {},
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          dashboardWatch: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_DASHBOARD_PRIZES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          dashboardPrizes: {
            ...prevState.dashboardPrizes,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          dashboardPrizes: {
            loading: false,
            error: "",
            data: payload.data || {},
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          dashboardPrizes: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_DASHBOARD_ANNOUNCEMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          dashboardAnnouncements: {
            ...prevState.dashboardAnnouncements,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          dashboardAnnouncements: {
            loading: false,
            error: "",
            data: payload.data || {},
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          dashboardAnnouncements: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_DASHBOARD_ENGAGEMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          dashboardEngagements: {
            ...prevState.dashboardEngagements,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          dashboardEngagements: {
            loading: false,
            error: "",
            data: payload.data || {},
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          dashboardEngagements: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_NEW_CLUES_AVAILABLE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          newCluesAvailable: {
            ...prevState.newCluesAvailable,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          newCluesAvailable: {
            loading: false,
            error: "",
            data: payload.data || 0,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          newCluesAvailable: {
            loading: false,
            data: 0,
            error: payload,
          },
        }),
      });

    default:
      return state;
  }
};

export default DashboardReducer;

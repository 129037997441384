import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import Earth from "src/pages/Home/assets/images/earth.png";
import GameAccountImg from "src/pages/Home/assets/images/gameaccount.png";
import classes from "./styles.module.scss";

const GameAccount = ({ redirectLink = "" }) => {
  const navigate = useNavigate();
  return (
    <div style={{ position: "relative" }}>
      <div className={classes.wrapper}>
        <div className={classes.downloadApp}>
          <Typography variant="p32" fontFamily="milonga">
            DOWNLOAD THE APP
          </Typography>
        </div>
        <div className={classes.imgWrapper}>
          <img src={GameAccountImg} className={classes.imgSide} />
        </div>

        <div className={classes.content}>
          <div className={classes.contentWrapper}>
            <div className={classes.info}>
              <div className={classes.button}>
                <Button
                  onClick={() => navigate(redirectLink)}
                  buttonColor="secondary"
                  buttonText="Create a TG$ Account"
                />
              </div>
              <Typography className={classes.text}>
                Once you have created a TG$ account, you will be able to choose
                the level of access you want from two options; Free Access
                Content or Full Access Content.
                {/*<img*/}
                {/*  src={TGSicon}*/}
                {/*  width={80}*/}
                {/*  height={35}*/}
                {/*  alt="Icon not found"*/}
                {/*/>{" "}*/}
                {/*Mobile App.*/}
              </Typography>
              <Typography className={classes.text}>
                Once you have selected your access level you can engage with our
                games and entertainment on our Mobile & Web app. ENJOY!
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.btnWrapper}>
        <img className={classes.bottomImage} src={Earth} alt="earth" />
        <div className={classes.button}>
          <Button
            buttonText="Impact Investment Program"
            buttonColor="secondary"
            onClick={() => {
              if (window) {
                (window as any).location = "/a/impact-investment-program";
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GameAccount;

import { ReactComponent as EyeIcon } from "src/assets/icons/eye-icon.svg";
import { ReactComponent as PencilIcon } from "src/assets/icons/pencil-icon.svg";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash-icon.svg";
import ActionDropdown from "src/components/ActionDropdown";
import { POST_STATUS_MAPPING, POST_STATUS_VALUES } from "src/helpers/constants";
import { getUploadedPostDate } from "src/utils/date";
import { getIsImage } from "src/utils/media";
import { getVideoDuration } from "src/utils/number";
import classes from "./ManageContentTable.module.scss";

export const statusFormatter = (obj: any) => {
  const val = obj.getValue();
  const isPublished = val === POST_STATUS_VALUES.PUBLISHED;
  return (
    <span className={classes.statusDisplay}>
      {isPublished && <EyeIcon />}
      {POST_STATUS_MAPPING[val] || val}
    </span>
  );
};
export const dateFormatter = (obj: any) => (
  <>
    <span>{getUploadedPostDate(obj.getValue())}</span>
    <br />
    <span>Created</span>
  </>
);
export const contentFormatter = (obj: any) => {
  const tRow = obj.row?.original as any;
  const isImage = getIsImage(tRow?.media_type);
  return (
    <div className={classes.contentColumn}>
      <div className={classes.image}>
        <img
          src={tRow?.images?.find((item: any) => item.w === 480)?.url}
          alt="content"
        />
        {!isImage && (
          <span className={classes.videoDuration}>
            {getVideoDuration(tRow?.metadata?.duration)}
          </span>
        )}
      </div>
      <div className={classes.info}>
        <span>{tRow?.title}</span>
        <span>{tRow?.desc}</span>
      </div>
    </div>
  );
};
export const actionFormatter = (
  row: any,
  onEdit: (row: any) => void,
  onDelete: (row: any) => void,
  loading?: boolean
) => (
  <ActionDropdown
    items={[
      {
        value: "edit",
        label: "Edit",
        icon: <PencilIcon />,
      },
      {
        value: "delete",
        label: "Delete",
        icon: <TrashIcon />,
      },
    ]}
    disabled={loading}
    onChange={(val: string) => {
      if (val === "edit") {
        onEdit(row);
      } else {
        onDelete(row);
      }
    }}
  />
);

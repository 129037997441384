import cn from "classnames";
import { Spinner as RSpinner } from "reactstrap";
import classes from "./Spinner.module.scss";

interface Props {
  className?: string;
  wholePage?: boolean;
  center?: boolean;
  wrapperClassName?: string;
}

const Spinner = ({ className, wholePage, center, wrapperClassName }: Props) => {
  const el = (
    <RSpinner
      className={cn(
        classes.wrapper,
        {
          [classes.center]: center,
        },
        className
      )}
    />
  );
  if (wholePage) {
    return (
      <div className={cn(classes.spinnerWrapper, wrapperClassName)}>{el}</div>
    );
  }
  return el;
};

export default Spinner;

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { MediaInfo } from "src/types/media";
import { getBasicMediaInfo } from "src/utils/media";
import ChannelBlock from "./components/ChannelBlock";
import { useSub1Data } from "./selectorData";

const WatchSub1 = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { watchSubChannel, watchSubChannelLoading } = useSub1Data();
  const medias = (watchSubChannel?.sections?.[0]?.posts || []).map(
    (feedItem: MediaInfo) => {
      return {
        ...getBasicMediaInfo(feedItem),
        onClick: () => navigate(`${pathname}/playing-media/${feedItem.id}`),
      };
    }
  );

  return (
    <>
      <ChannelBlock
        title={params.subChannelTitle}
        medias={medias}
        hideButton
        loading={watchSubChannelLoading}
        loadingBottom={!!watchSubChannel?.sections?.[0]?.meta?.next_cursor}
        paddingBottom
      />
    </>
  );
};

export default WatchSub1;

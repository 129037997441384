import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Button from "src/components/Button";
import UserDropdown from "src/layout/PrivateLayout/UserDropdown";
import BurgerIcon from "src/pages/Home/assets/icons/burger-icon.svg";
import { logoutUser, removeSignupDetails } from "src/store/actions/auth";
import { useReducerData, useStoreActions } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import { isLoggedIn } from "src/utils/utils";
import classes from "./styles.module.scss";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { fromSignup } = useReducerData("auth", "flow", { fromSignup: true });
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  const isLogIn = isLoggedIn();
  const [buttonText, setButtonText] = useState({
    text: "Sign In",
    path: "/auth/login",
  });
  const actions = useStoreActions({ logoutUser, removeSignupDetails });

  useEffect(() => {
    if (isLogIn) {
      setButtonText({ text: "Sign Out", path: "/auth/login" });
    } else {
      if (pathname === "/auth/login") {
        setButtonText({ text: "Sign Up", path: "/auth/access-info" });
      } else {
        setButtonText({ text: "Sign In", path: "/auth/login" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogIn, pathname]);

  const handleSignUp = () => {
    if (isLogIn) {
      actions.logoutUser();
    }
    navigate(buttonText?.path);
  };

  const handleHomeButton = () => {
    if (fromSignup) {
      navigate("/");
    } else {
      navigate("/");
    }
    actions.removeSignupDetails();
  };

  return (
    <div className={classes.navWrapper}>
      <div className={classes.wrapper}>
        {isMobile ? (
          <UserDropdown className={classes.userDropdown}>
            <img alt="burger" src={BurgerIcon} />
          </UserDropdown>
        ) : (
          <div className={classes.btnWrapper}>
            <div className={classes.homeButton}>
              <Button
                onClick={handleHomeButton}
                buttonText="Home"
                variant="link"
              />
            </div>
            <div className={classes.divider} />
            <div className={classes.signUpButton}>
              <Button
                onClick={handleSignUp}
                buttonText={buttonText?.text}
                variant="link"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

import cn from "classnames";
import React from "react";
import Button from "src/components/Button";
import Spinner from "src/components/Spinner";
import { WatchMedia } from "src/types/watch";
import WatchCards from "../Cards";
import MediaBlock from "../MediaBlock";
import classes from "./ChannelBlock.module.scss";

interface Props {
  title?: string | React.ReactNode;
  onClick?: () => void;
  medias: WatchMedia[];
  hideButton?: boolean;
  loading?: boolean;
  loadingBottom?: boolean;
  paddingBottom?: boolean;
  className?: string;
}

const ChannelBlock = (props: Props) => {
  const {
    title,
    onClick,
    medias,
    hideButton,
    loading,
    loadingBottom,
    className,
    paddingBottom,
  } = props;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.paddingBottom]: paddingBottom,
        },
        className
      )}
    >
      {!!title && <h2>{title}</h2>}
      {loading && <Spinner center />}
      <WatchCards className={classes.media}>
        {medias.map((media, index) => {
          return <MediaBlock {...media} key={index} />;
        })}
      </WatchCards>
      {loadingBottom && loading && <Spinner center />}
      {!hideButton && (
        <Button
          buttonText="See All"
          buttonColor="primary"
          dark
          size="lg"
          weight="semibold"
          fullWidth
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default ChannelBlock;

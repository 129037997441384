import { Request } from "./request";

export const apiGetUserNotifications = async (filters = {}) => {
  return Request.call({
    url: `/api/user/notifications`,
    method: "GET",
    params: filters,
  });
};
export const apiGetUserNotificationCount = async (filters = {}) => {
  return Request.call({
    url: `/api/user/notification-count`,
    method: "GET",
    params: filters,
  });
};
export const apiViewUserNotification = async (ids: string[]) => {
  return Request.call({
    url: `/api/user/view-notification`,
    method: "POST",
    data: {
      notification_ids: ids,
    },
  });
};
export const apiOpenUserNotification = async (ids: string[]) => {
  return Request.call({
    url: `/api/user/open-notification`,
    method: "POST",
    data: {
      notification_ids: ids,
    },
  });
};

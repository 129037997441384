/* eslint-disable react/no-multi-comp */
import cx from "classnames";
import get from "lodash/get";
import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import { useSearchParams } from "react-router-dom";
import NightSky from "src/assets/images/night-sky.png";
import Button from "src/components/Button";
import { ROLE_LEVEL, ROLE_NAME } from "src/helpers/constants/role";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { playRoutes } from "src/routes";
import { useSidebar } from "src/utils/helper";
import { getAccessLevel, isAllowedRoute } from "src/utils/role";
import { useCluesData } from "src/utils/useData";
import { useWindowSize } from "src/utils/useWindowSize";
import { isLoggedIn } from "src/utils/utils";
import Notification from "../Notification";
import Header from "./Header";
import Sidebar from "./Sidebar";
import classes from "./styles.module.scss";

const getRoutes = (
  routes: {
    path: string;
    name: string;
    component: () => JSX.Element;
    layout: string;
    permission: string[];
  }[],
  accessLevel: string
) => {
  return routes.map(({ path, component: Component, permission }, key) => {
    const isAllowed = isAllowedRoute(permission, accessLevel);
    if (isAllowed) {
      return <Route path={path} element={<Component />} key={key} />;
    }
    return (
      <Route
        path={path}
        element={<Navigate to="/subscriptions" replace />}
        key={key}
      />
    );
  });
};

const PlayLayout = () => {
  const { width = 0 } = useWindowSize();
  const isMobile = width > 0 && width <= 768;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { expand, toggle } = useSidebar();
  const userProfile = useUserProfile();
  const accessLevel = getAccessLevel(
    get(userProfile, "user.role.name", ROLE_NAME.FREE_ACCESS)
  );
  const isFreeTrial = accessLevel === ROLE_LEVEL.FREE_TRIAL;
  const {
    clues: { data: clues },
  } = useCluesData();
  const [searchParams, setSearchParams] = useSearchParams();

  const getTitle: { [key: string]: string } = {
    "/play/my-dashboard": "My Dashboard",
    "/play": "Play",
    "/play/clues": "Clues",
  };

  const { freeClues, accessedClues } = useMemo(() => {
    const freeClues = clues.filter((clue) => clue.answer_status !== "locked");
    const accessedClues = freeClues?.filter(
      (clue) => clue.answer_status !== "not-viewed"
    );
    return {
      freeClues,
      accessedClues,
    };
  }, [clues]);

  useEffect(() => {
    const isLogIn = isLoggedIn();

    if (!isLogIn) {
      navigate("/auth/signup");
    } else if (isMobile) {
      navigate("/user/device-blocked");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  const ContentRight = () => {
    if (pathname === "/play/clues" && isFreeTrial) {
      return (
        <div className={classes.upgradeContainer}>
          <div className={classes.title}>FREE TRIAL</div>
          <div className={classes.clueInfoWrapper}>
            <div className={classes.openClue}>{accessedClues?.length}</div>
            <div className={classes.totalClue}>
              of {freeClues?.length} Clues Accessed
            </div>
          </div>
          <div>
            <Button
              buttonText="Upgrade"
              buttonColor="purple-gradient"
              buttonClassName={classes.upgradeButton}
              onClick={() => {
                searchParams.set("show_upgrade_modal", "true");
                setSearchParams(searchParams);
              }}
            />
          </div>
        </div>
      );
    } else if (
      (pathname === "/play" || pathname === "/play/my-dashboard") &&
      !isFreeTrial
    ) {
      return <Notification />;
    } else {
      return <></>;
    }
  };

  return (
    <div className={classes.layoutWrapper}>
      <div className={classes.wrapper}>
        <Header
          title={getTitle[pathname] ?? ""}
          contentRight={<ContentRight />}
        />
        <div className={classes.playContainer}>
          <Sidebar expand={expand} toggle={toggle} />
          <div
            className={cx(classes.container, {
              [classes.collapsed]: !expand,
            })}
            style={{
              backgroundImage: `url(${NightSky})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              overflow: "auto",
              height: "calc(100vh -  67px)",
            }}
          >
            {<Routes>{getRoutes(playRoutes, accessLevel)}</Routes>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayLayout;

import React from "react";
import { useNavigate } from "react-router";
import Button from "src/components/Button";
import CoinOne from "src/pages/Home/assets/images/coin-one.png";
import CoinThree from "src/pages/Home/assets/images/coin-three.png";
import CoinTwo from "src/pages/Home/assets/images/coin-two.png";
import Watch from "src/pages/Home/assets/images/watch.png";
import classes from "./styles.module.scss";
const Instructions = ({ redirectLink = "" }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.title}>
          <p>HOW IT WORKS</p>
        </div>
        <div className={classes.images}>
          <div className={classes.watch}>
            <img src={Watch} alt="watch" />
          </div>
          <div className={classes.coinOne}>
            <img src={CoinOne} alt="coin-one" />
          </div>
          <div className={classes.coinTwo}>
            <img src={CoinTwo} alt="coin-two" />
          </div>
          <div className={classes.coinThree}>
            <img src={CoinThree} alt="coin-three" />
          </div>
        </div>
        <div className={classes.button}>
          <Button
            buttonColor="secondary"
            buttonText="Get Started"
            onClick={() => navigate(redirectLink)}
          />
        </div>
      </div>
    </div>
  );
};
export default Instructions;

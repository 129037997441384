import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import TgTile from "src/assets/images/tg-title.png";
import Text from "src/components/Text";
import { MediaInfo } from "src/types/media";
import { Channel } from "src/types/user";
import { useUserActions } from "src/utils/useActions";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./News.module.scss";
import NewsModal from "./NewsModal";
import VideoSection from "./VideoSection";
import { useActions } from "./selectorData";
import { getUpdatedNews } from "./utils";

const News = () => {
  const { getMediaDetail, likeUnlikePost } = useActions();
  const navigate = useNavigate();
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [modalData, setModalData] = useState<MediaInfo | undefined>(undefined);
  const [newsData, setNewsData] = useState<Channel[]>([]);
  const [loading, setLoading] = useState(false);
  const onClose = () => setOpenVideoModal(false);
  const { getNewsData } = useUserActions();
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  const categories = [
    "tgs-channel",
    "treasure-hunters",
    "winners-news",
    "games-and-prizes",
    "announcements-news",
    "impact-investments",
  ];
  const onVideoClick = (data: MediaInfo | undefined) => {
    const tModalData = {
      ...data,
      views_count: (data?.views_count || 0) + 1,
    } as MediaInfo | undefined;
    setModalData(tModalData);
    setOpenVideoModal(true);
    setNewsData(getUpdatedNews(newsData, tModalData));
    getMediaDetail(String(data?.id));
  };
  const handleLoveChanged = (val: boolean) => {
    const tModalData = {
      ...(modalData || {}),
      has_liked: val,
      likes_count: (modalData?.likes_count || 0) + (val ? 1 : -1),
    } as MediaInfo | undefined;
    setModalData(tModalData);
    const newNews = getUpdatedNews(newsData, tModalData);
    setNewsData(newNews);
    likeUnlikePost(String(modalData?.id), val ? "like" : "unlike");
  };

  useEffect(() => {
    const fetchDataForCategories = async () => {
      setLoading(true);
      const promises = categories.map(async (category) => {
        const response: any = await getNewsData(category);
        return response.data.channel;
      });
      const results: any = await Promise.all(promises);
      setLoading(false);
      setNewsData(results);
    };

    fetchDataForCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {openVideoModal && modalData ? (
        <NewsModal
          post={modalData}
          isOpen={openVideoModal}
          onClose={onClose}
          onLoveChanged={handleLoveChanged}
        />
      ) : null}
      <div className={classes.mainWrapper}>
        <div className={classes.header}>
          {!isMobile && (
            <img src={TgTile} className={classes.logo} alt="logo" />
          )}
          <Text color="#fff" size={32} fontFamily="milonga">
            NEWS & ENTERTAINMENT
          </Text>
        </div>
        <div className={classes.container}>
          {loading && <Spinner color="light" className={classes.spinner} />}
          {!loading &&
            newsData?.map((news, i) => {
              return (
                news?.sections?.[0].posts?.length > 0 && (
                  <VideoSection
                    key={i}
                    channel={news}
                    onVideoClick={onVideoClick}
                  />
                )
              );
            })}
        </div>
        <div className={classes.bottomText}>
          <Text color="#fff" size={30} fontWeight="semibold">
            You are enjoying the TG$ Free Access Level. To get Full Access to
            our Games & Entertainment click here:{" "}
            <span
              className={classes.link}
              onClick={() => {
                navigate("/user/settings?plans=true");
              }}
            >
              TG$ FULL ACCESS
            </span>{" "}
            .
          </Text>
        </div>
      </div>
    </>
  );
};

export default News;

import { Dispatch } from "redux";
import { apiAnswerCustomClue } from "src/api/customGame";
import Types from "../types/customGame";

export const answerCustomClue =
  (clueId: number | undefined, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.ANSWER_CUSTOM_CLUE,
      asyncCall: () => {
        return apiAnswerCustomClue(clueId);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

import cn from "classnames";
import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import { FormGroup } from "reactstrap";
import * as Yup from "yup";
import { ReactComponent as CheckCircleIcon } from "src/assets/icons/check-circle-icon.svg";
import Button from "src/components/Button";
import { CloseCircle } from "src/components/Icon";
import Input from "src/components/Input";
import Select from "src/components/Select";
import Spinner from "src/components/Spinner";
import SuggestTag from "src/components/SuggestTag";
import { POST_STATUS_MAPPING, POST_STATUS_VALUES } from "src/helpers/constants";
import useOptions from "src/helpers/hooks/useOptions";
import { ISuggestTag, Mode } from "src/types";
import { getFileSize } from "src/utils/file";
import { getVideoDuration } from "src/utils/number";
import LampIcon from "../LampIcon";
import SourcePreview from "../SourcePreview";
import Thumbnail from "../Thumbnail";
import classes from "./UploadContent.module.scss";

interface Props {
  className?: string;
  onSaveDraft?: (values: any) => void;
  onSave?: (values: any) => void;
  onSubmit: (values: any) => void;
  mediaUploaded?: boolean;
  mediaVerifying?: boolean;
  isUploadImage?: boolean;
  mode?: Mode;
  onDelete?: () => void;
  onCancel?: () => void;
  leftAdditional?: React.ReactNode;
  fileDetails?: any;
  uploadLoading?: boolean;
  publishLoading?: boolean;
  fileSrc?: string;
  editObj?: any;
  sourcePreviewTitle?: string;
  postStatusData?: any;
  inputRef?: any;
  onFileChanged?: (tFile: any) => void;
}

const schema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  region: Yup.mixed().required("Region is required"),
});

const UploadContent = ({
  className,
  onSaveDraft,
  onSubmit,
  mediaUploaded,
  mediaVerifying,
  isUploadImage,
  mode,
  onCancel,
  leftAdditional,
  fileDetails,
  uploadLoading,
  fileSrc,
  publishLoading,
  editObj,
  sourcePreviewTitle,
  onSave,
  postStatusData,
  inputRef,
  onFileChanged,
}: Props) => {
  const formRef = useRef(null);
  const postStatus = postStatusData?.status;
  const { regionOptions } = useOptions();
  const isEditMode = mode === "edit";
  const failedStatus = [
    POST_STATUS_VALUES.VERIFYING_FAILED,
    POST_STATUS_VALUES.BANNED,
    POST_STATUS_VALUES.ERROR,
  ].includes(postStatus);
  // const isUploadLoading =
  //   !postStatus || [POST_STATUS_VALUES.UPLOADING].includes(postStatus);

  useEffect(() => {
    if (
      postStatusData &&
      postStatusData.status === POST_STATUS_VALUES.VERIFIED &&
      !isUploadImage &&
      formRef &&
      formRef.current &&
      !isEditMode
    ) {
      const tFormRefCurrent = formRef.current as any;
      if (!tFormRefCurrent.values?.activeThumbnail) {
        tFormRefCurrent.setFieldValue(
          "activeThumbnail",
          postStatusData.images?.[0]
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postStatusData, formRef]);
  useEffect(() => {
    if (isEditMode && formRef && formRef.current && !isUploadImage) {
      const tFormRefCurrent = formRef.current as any;
      tFormRefCurrent.setFieldValue(
        "activeThumbnail",
        (editObj.cover || []).find((o: any) => o.id === editObj.selected)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isEditMode]: isEditMode,
        },
        className
      )}
    >
      <Formik
        onSubmit={onSubmit}
        validationSchema={schema}
        initialValues={{
          title: isEditMode ? String(editObj?.title || "") : "",
          description: isEditMode ? String(editObj?.desc || "") : "",
          region:
            (regionOptions || []).find(
              (region: Record<string, unknown>) =>
                region.value === editObj?.region
            ) || null,
          suggestTags:
            (editObj?.tags || []).map((v: string) => ({
              id: v,
              text: `$${v}`,
            })) || [],
          activeThumbnail: undefined,
          customThumbnail: null,
        }}
        enableReinitialize
        innerRef={formRef}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
          isValid,
        }) => {
          return (
            <Form
              className={cn(classes.form, {
                [classes.success]: mediaUploaded,
              })}
            >
              {mediaUploaded ? (
                <div className={classes.uploadSuccess}>
                  <LampIcon status={mediaVerifying ? "verifying" : "success"} />
                  <p>
                    {mediaVerifying
                      ? "Your media is still processing."
                      : "Success!"}
                  </p>
                  <span>
                    {mediaVerifying
                      ? `You may close out of this window. A notification will be sent
                    when the review is complete. If it is in line with WL$
                    regulations and restrictions, your upload will post as normal.`
                      : "Your post has been verified and uploaded successfully."}
                  </span>
                </div>
              ) : (
                <>
                  <div className={classes.left}>
                    {leftAdditional}
                    <FormGroup className={classes.formGroup}>
                      <Input
                        labelClassName={classes.label}
                        label="Title"
                        required={true}
                        value={values.title}
                        placeholder="Enter title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.title}
                        touched={touched.title}
                        name="title"
                        inputColor="blue"
                        innerRef={inputRef}
                      />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                      <Input
                        labelClassName={classes.label}
                        label="Description"
                        required={true}
                        value={values.description}
                        placeholder="Tell viewers more about your post... "
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.description}
                        touched={touched.description}
                        name="description"
                        type="textarea"
                        inputColor="blue"
                      />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                      <Select
                        label="Region*"
                        name="region"
                        placeholder="Select one"
                        value={values?.region}
                        error={errors?.region as string}
                        touched={touched?.region as unknown as boolean}
                        leftIconClass={classes.icon}
                        onBlur={() => setFieldTouched("region", true)}
                        onChange={(selectedOption) => {
                          setFieldValue("region", selectedOption);
                        }}
                        options={regionOptions}
                        helperText="Select the region applicable to this post."
                        selectColor="blue"
                      />
                    </FormGroup>
                    <FormGroup className={classes.formGroup}>
                      <SuggestTag
                        value={values.suggestTags}
                        onChange={(_, tags) => {
                          setFieldValue("suggestTags", tags);
                        }}
                        limit={3}
                        placeholder={
                          values.suggestTags?.length === 0
                            ? "Type tags here"
                            : ""
                        }
                        onDelete={(tag) => {
                          setFieldValue(
                            "suggestTags",
                            values.suggestTags.filter(
                              (t: ISuggestTag) => t.id !== tag.id
                            )
                          );
                        }}
                        label="Add Tags"
                        helperText="Separate tags with commas. They will show with a '$' in front when posted."
                      />
                    </FormGroup>
                  </div>
                  <div className={classes.right}>
                    <SourcePreview
                      isUploadImage={isUploadImage}
                      name={
                        isEditMode
                          ? editObj?.metadata?.filename
                          : fileDetails?.name
                      }
                      size={getFileSize(
                        (isEditMode
                          ? editObj?.metadata?.size
                          : fileDetails?.size) || 0
                      )}
                      src={isEditMode ? editObj?.src : fileSrc}
                      title={sourcePreviewTitle}
                      length={getVideoDuration(
                        isEditMode
                          ? editObj?.metadata?.duration
                          : fileDetails?.length
                      )}
                      uploadable={failedStatus}
                      onFileChanged={onFileChanged}
                      loading={uploadLoading}
                    />
                    {!isUploadImage && (
                      <Thumbnail
                        thumbnails={
                          (isEditMode
                            ? editObj?.cover
                            : postStatusData?.images) || []
                        }
                        activeThumbnail={values.activeThumbnail}
                        onThumbnailChanged={(val) => {
                          setFieldValue("activeThumbnail", val);
                        }}
                        onCustomFileChanged={(val) => {
                          setFieldValue("customThumbnail", val);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
              <div className={classes.footer}>
                {mediaUploaded ? (
                  <>
                    {mediaVerifying && (
                      <div className={classes.uploadAlert}>
                        <span>
                          Read further on Wish Lamp${" "}
                          <span>Restrictions and Regulations</span>.
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!isEditMode && (
                      <div
                        className={cn(classes.uploadStatus, {
                          [classes.isError]: failedStatus,
                        })}
                      >
                        {!uploadLoading ? (
                          <>
                            {failedStatus ? (
                              <CloseCircle />
                            ) : (
                              <CheckCircleIcon />
                            )}
                          </>
                        ) : (
                          <Spinner />
                        )}
                        <span>
                          {failedStatus ? (
                            <>
                              {postStatus === POST_STATUS_VALUES.ERROR ? (
                                <>
                                  Uploaded failed. Please try again with other
                                  file!
                                </>
                              ) : (
                                <>
                                  The "{fileDetails?.name}" does not pass our{" "}
                                  <span>verification process</span> and has been
                                  removed. Please select a different file to
                                  publish.{" "}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {POST_STATUS_MAPPING[String(postStatus)] ||
                                postStatus ||
                                "Loading..."}
                            </>
                          )}
                        </span>
                      </div>
                    )}
                    <Button
                      buttonText={isEditMode ? "Cancel" : "Save as Draft"}
                      variant="link"
                      buttonColor="white"
                      linkHasLoading
                      // disabled={isUploadLoading}
                      loading={!isEditMode && publishLoading}
                      onClick={
                        isEditMode
                          ? onCancel
                          : () => {
                              if (onSaveDraft) onSaveDraft(values);
                            }
                      }
                    />
                    {isEditMode && (
                      <Button
                        buttonText="Save"
                        buttonColor="white"
                        isGhost
                        buttonClassName={classes.editModeSaveBtn}
                        loading={publishLoading}
                        onClick={() => {
                          if (onSave) onSave(values);
                        }}
                      />
                    )}
                  </>
                )}
                {(!isEditMode ||
                  (isEditMode &&
                    editObj?.status === POST_STATUS_VALUES.DRAFT)) && (
                  <Button
                    type="submit"
                    buttonText="Publish"
                    buttonColor="purple"
                    buttonClassName={classes.submitBtn}
                    loading={publishLoading}
                    // disabled={!isValid || isUploadLoading}
                    disabled={!isValid}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UploadContent;

import { Request } from "./request";

export type ChangeSubscriptionData = {
  payment_id: string;
  plan_id: number;
};

export type RemoveDeviceProps = {
  id: number;
  current_device_token: string;
};

export const getDevices = async () => {
  return Request.call({
    url: "/api/user/devices",
    method: "GET",
  });
};

export const removeDevice = async (data: RemoveDeviceProps) => {
  return Request.call({
    url: `/api/user/devices`,
    method: "POST",
    data,
  });
};

export const changeSubscription = async (data: ChangeSubscriptionData) => {
  return Request.call({
    url: "api/subscription/change",
    method: "POST",
    data,
  });
};

export const getNewsData = async (category: string) => {
  return Request.call({
    url: `api/watch/channels/${category}`,
    method: "GET",
  });
};

export const getPlayCountdown = async () => {
  return Request.call({
    url: "api/countdown",
    method: "GET",
  });
};

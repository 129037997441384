import { Dispatch } from "redux";
import { apiGetPrizes } from "src/api/prize";
import { CommonGetPayload } from "src/types";

import Types from "../types/prize";

export const getPrizes =
  (filters: CommonGetPayload = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = filters;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_PRIZES,
      asyncCall: () => {
        return apiGetPrizes(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

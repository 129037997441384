import React from "react";
import dummyImage from "src/assets/images/placeholder-image.png";
import { ChannelFollower } from "src/types/watch";
import classes from "./AvatarFollower.module.scss";

type Props = {
  follower: ChannelFollower;
};
const AvatarFollower: React.FC<Props> = ({ follower }) => {
  return (
    <div className={classes.avatarContainer}>
      <img
        src={follower.avatarPath || dummyImage}
        className={classes.avatar}
        alt="avatar"
      />
      <span>{follower.username || "Author Name"}</span>
    </div>
  );
};

export default AvatarFollower;

import cx from "classnames";
import React from "react";
import ReactPlayer from "react-player";
import { ClueProps } from "src/pages/Play/Clue/types";
import classes from "./styles.module.scss";

type VideoQuestionProps = { clue: ClueProps; isModal?: boolean };

const VideoQuestion: React.FC<VideoQuestionProps> = ({
  isModal = false,
  clue,
}) => {
  return (
    <div
      className={cx(classes.wrapper, {
        [classes.modalQuestionWrapper]: isModal,
      })}
    >
      <ReactPlayer
        url={clue?.url?.[0]}
        width="100%"
        playing
        height="100%"
        controls
        light={clue?.media?.[0]?.thumbnail_url}
      />
    </div>
  );
};

export default VideoQuestion;

import * as authActions from "src/store/actions/auth";
import * as prizeActions from "src/store/actions/prize";
import * as watchActions from "src/store/actions/watch";
import { useReducerData, useStoreActions } from "src/store/hooks";

export const useIndexData = () => {
  return {
    userFeed: useReducerData("watch", "watchUserFeed.data", {}),
    watchChannel: useReducerData("watch", "watchChannel.data.sections[0]", {}),
  };
};
export const useMainData = () => {
  return {
    prizeChannels: useReducerData("prize", "prizes.data.channels", []),
    prizeChannelsLoading: useReducerData("prize", "prizes.loading", false),
  };
};
export const useSubData = () => {
  return {
    watchChannel: useReducerData("watch", "watchChannel.data.sections[0]", {}),
    watchChannelLoading: useReducerData("watch", "watchChannel.loading", false),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
    ...prizeActions,
    ...watchActions,
  });
};

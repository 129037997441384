import cn from "classnames";
import range from "lodash/range";
import moment from "moment";
import React from "react";
import { ReactComponent as LeftArrow } from "src/assets/icons/arrow-left-single-icon.svg";
import { ReactComponent as RightArrow } from "src/assets/icons/arrow-right-single-icon.svg";
import { getMomentFormat } from "src/utils/date";
import { currencyFormatter, getCurrencyNumber } from "src/utils/number";
import classes from "./RegionTable.module.scss";
import RegionDate from "./components/RegionDate";
import RegionLabel from "./components/RegionLabel";

export type RegionTablePagination = {
  totalPages: number;
  currentPage: number;
  onChange: (val: number) => void;
};
interface Props {
  className?: string;
  pagination: RegionTablePagination;
  data: any[];
}

const RegionTable = ({ className, pagination, data }: Props) => {
  const totalPages = pagination?.totalPages || 0;
  const currentPage = pagination?.currentPage || 1;
  const today = moment(moment().format("YYYY/MM/DD"));
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.inner}>
        <h4>Region Activation Dates</h4>
        <ul>
          {(data || []).map((region, index) => {
            const tPrize = Number(region.prize || 0);
            const tDateMoment = moment(region.activation_date);
            return (
              <li key={`${region.region_id}_${index}`}>
                <RegionLabel label={`Region ${region.region_id}`} />
                <span>{`Season ${region.season_id}`}</span>
                <RegionDate
                  status={tDateMoment.isAfter(today) ? "Activates" : "Live"}
                  dateStr={getMomentFormat(
                    region.activation_date,
                    "MMMM D, YYYY"
                  )}
                  className={classes.regionDate}
                />
                <span className={classes.price}>
                  <span>Prize:</span>
                  <b>
                    {Number(tPrize) < 1000000
                      ? getCurrencyNumber(tPrize)
                      : `$${currencyFormatter(tPrize, 2, true)}`}
                  </b>
                </span>
              </li>
            );
          })}
        </ul>
        {totalPages > 1 && (
          <div className={classes.pagination}>
            <LeftArrow
              className={cn(classes.prev, {
                [classes.disabled]: currentPage === 1,
              })}
              onClick={
                currentPage === 1
                  ? undefined
                  : () => {
                      if (pagination?.onChange) {
                        pagination.onChange(currentPage - 1);
                      }
                    }
              }
            />
            {range(totalPages).map((i) => {
              const tIndex = i + 1;
              const isActive = currentPage === tIndex;
              return (
                <span
                  className={cn({
                    [classes.active]: isActive,
                  })}
                  key={tIndex}
                  onClick={
                    isActive
                      ? undefined
                      : () => {
                          if (pagination?.onChange) {
                            pagination.onChange(tIndex);
                          }
                        }
                  }
                ></span>
              );
            })}
            <RightArrow
              className={cn(classes.next, {
                [classes.disabled]: currentPage === totalPages,
              })}
              onClick={
                currentPage === totalPages
                  ? undefined
                  : () => {
                      if (pagination?.onChange) {
                        pagination.onChange(currentPage + 1);
                      }
                    }
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default RegionTable;

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import UserIcon from "src/assets/icons/user-icon.svg";
import TgTile from "src/assets/images/tg-title.png";
import Button from "src/components/Button";
import UserDropdown from "src/layout/PrivateLayout/UserDropdown";
import BurgerIcon from "src/pages/Home/assets/icons/burger-icon.svg";
import { logoutUser } from "src/store/actions/auth";
import { useStoreActions } from "src/store/hooks";
import { useWindowSize } from "src/utils/useWindowSize";
import { isLoggedIn } from "src/utils/utils";
import classes from "./styles.module.scss";

const PreviewHeader = () => {
  const navigate = useNavigate();
  const isLogIn = isLoggedIn();
  const { pathname } = useLocation();
  const actions = useStoreActions({ logoutUser });
  const [buttonText, setButtonText] = useState({
    text: "Sign In",
    path: "/auth/access-info",
  });
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    if (isLogIn) {
      setButtonText({ text: "Sign Out", path: "/auth/login" });
    } else {
      if (pathname === "/auth/login") {
        setButtonText({ text: "Sign Up", path: "/auth/access-info" });
      } else {
        setButtonText({ text: "Sign In", path: "/auth/login" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogIn, pathname]);

  const handleHomeButton = () => {
    navigate("/");
  };
  const handleSignUp = () => {
    if (isLogIn) {
      actions.logoutUser();
    }
    navigate(buttonText?.path);
  };
  return (
    <div className={classes.wrapper}>
      <img className={classes.tgsImage} src={TgTile} alt="img" />
      {!isLogIn && (
        <div className={classes.btnWrapper}>
          <div className={classes.homeButton}>
            <Button
              onClick={handleHomeButton}
              buttonText="Home"
              variant="link"
            />
          </div>
          <div className={classes.divider} />
          <div className={classes.signUpButton}>
            <Button
              onClick={handleSignUp}
              buttonText={buttonText.text}
              variant="link"
            />
          </div>
        </div>
      )}
      {(isMobile || isLogIn) && (
        <UserDropdown notAlign>
          {!isLogIn || isMobile ? (
            <img alt="burger" src={BurgerIcon} />
          ) : (
            <>
              <img src={UserIcon} alt="user-icon" />
              <FontAwesomeIcon icon={faAngleDown} size="xs" />
            </>
          )}
        </UserDropdown>
      )}
    </div>
  );
};

export default PreviewHeader;

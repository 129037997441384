import React, { SVGProps } from "react";
import { ReactComponent as ActiveCompleteIcon } from "src/assets/icons/active-complete.svg";
import { ReactComponent as AudioIcon } from "src/assets/icons/audio-upload.svg";
import { ReactComponent as CheckIcon } from "src/assets/icons/check-rounded-icon.svg";
import { ReactComponent as LinkIcon } from "src/assets/icons/link.svg";
import { ReactComponent as LockedIcon } from "src/assets/icons/lock-icon.svg";
import { ReactComponent as PhotoIcon } from "src/assets/icons/photo-icon.svg";
import { ReactComponent as PendingIcon } from "src/assets/icons/purple-outlined-circle-icon.svg";
import { ReactComponent as PuzzleIcon } from "src/assets/icons/puzzle-icon.svg";
import { ReactComponent as RiddleIcon } from "src/assets/icons/riddle-icon.svg";
import { ReactComponent as TextFieldIcon } from "src/assets/icons/text-field.svg";
import { ReactComponent as TextImageIcon } from "src/assets/icons/text-image.svg";
import { ReactComponent as VideoIcon } from "src/assets/icons/video-upload.svg";
import { ReactComponent as PendingNextIcon } from "src/assets/icons/white-outlined-circle-icon.svg";
import { ReactComponent as ZoomIcon } from "src/assets/icons/zoom-icon.svg";
import { isClueCompleted } from "src/utils/utils";
import { ClueProps } from "./types";

type Props = {
  type: string;
} & Partial<SVGProps<SVGSVGElement>>;

export type ProgressIconProps = {
  status: string;
} & Partial<SVGProps<SVGSVGElement>>;

type GetIconProps = {
  clue: ClueProps;
} & Partial<SVGProps<SVGSVGElement>>;

export const getQuestionIcon = ({ type, ...rest }: Props) => {
  switch (type) {
    case "image":
    case "image-upload":
      return <PhotoIcon {...rest} />;
    case "video":
    case "video-upload":
      return <VideoIcon {...rest} />;
    case "music":
    case "audio":
    case "sounds":
    case "audio-file-upload":
      return <AudioIcon {...rest} />;
    case "search":
    case "word-puzzles":
      return <ZoomIcon {...rest} />;
    case "riddle":
    case "riddles":
      return <RiddleIcon {...rest} />;
    case "text-image":
      return <TextImageIcon {...rest} />;
    case "text-field":
      return <TextFieldIcon {...rest} />;
    case "url-link":
      return <LinkIcon {...rest} />;
    case "puzzle":
    case "custom":
      return <PuzzleIcon {...rest} />;
    default:
      return <></>;
  }
};

export const getProgressIcon = ({ clue, ...rest }: GetIconProps) => {
  switch (clue.answer_status) {
    case "complete":
      if (clue.is_current) {
        return <ActiveCompleteIcon style={{ height: 30, width: 30 }} />;
      }
      return <CheckIcon {...rest} />;
    case "active-complete":
      if (isClueCompleted(clue)) {
        return <ActiveCompleteIcon style={{ height: 30, width: 30 }} />;
      }
      return <PendingIcon style={{ height: 30, width: 30 }} {...rest} />;
    case "not-viewed":
    case "viewed":
    case "invalid":
      if (clue.is_current) {
        return <PendingIcon style={{ height: 30, width: 30 }} {...rest} />;
      }
      return (
        <div
          style={{
            borderRadius: "50%",
            border: "2px solid #121429",
            height: 30,
            width: 30,
          }}
        />
      );
    case "locked":
      return <LockedIcon {...rest} />;
    default:
      return <PendingNextIcon {...rest} />;
  }
};

export const getClueNumber = (
  clueId: number | undefined,
  clues: ClueProps[]
) => {
  const index = clues.findIndex((clue) => clue.id === clueId);
  if (index === -1) return;
  return index + 1;
};

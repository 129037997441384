import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import wishLampIcon from "src/assets/images/wish-lamp-landing.png";
import Typography from "src/components/Typography";
import TitleIcon from "src/pages/Auth/assets/icon/gametitle_icon.svg";
import TgtextMobile from "src/pages/Subscription/assets/tg-mobile-text.png";
import { useWindowSize } from "src/utils/useWindowSize";
import classes from "./styles.module.scss";

const authSteps = [
  {
    route: "/auth/signup",
    step: 0,
    title: "Free Trial Sign Up",
    description: (
      <>
        Sign Up to the <span>Treasure Game$</span> platform to play Wish Lamp$
      </>
    ),
    additionalDetails: "Try Wish Lamp$ free, cancel anytime.",
  },
  {
    route: "/auth/create-password",
    step: 1,
    title: "Create Your Password",
    description: "",
  },
  {
    route: "/auth/create-username",
    step: 2,
    title: "Username",
    description: "Pick a unique Username.",
  },
  {
    route: "/auth/profile",
    step: 3,
    isHideForFree: true,
    title: "Play Wish Lamp$",
    description:
      "Complete your account on the Treasure Game$ platform to get full access to Wish Lamp$.",
    additionalDetails:
      "This information is needed to provide the best user experience and is protected per TG$ privacy policy.",
  },
  {
    route: "/auth/risk-waiver",
    step: 4,
    isHideForFree: true,
    title: "Risk Release Waiver",
    description:
      "All Full Access TG$ Account holders must review and agree to our Risk Release Waiver in order to gain product access.",
  },
  {
    route: "/auth/payment",
    step: 5,
    title: "Get Access",
    description: "Select your access level:",
    isHideForFree: true,
  },
  {
    route: "/auth/login",
    step: 6,
    title: "Sign In",
    description: "",
  },
];

const showBreadcrumb = (step: number) => {
  const prevPaths: { [key in number]: boolean } = {
    0: false,
    1: true,
    2: true,
    3: true,
    4: true,
    5: false,
    6: false,
  };
  return prevPaths[step] ?? false;
};
const AuthBreadcrumb = () => {
  const { pathname } = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { width = 0 } = useWindowSize();
  const isMobile = width <= 768;
  const [currentStep, setCurrentStep] = useState(0);
  const [currentRouteDetails, setCurrentRouteDetails] = useState(authSteps[0]);
  const isDefaultStep = currentStep === 6 || currentStep === 0;
  const [searchParams] = useSearchParams();

  const isFullAccess = searchParams.get("full_access") === "true";
  const profilePaths = ["/auth/profile", "/auth/risk-waiver", "/auth/payment"];
  const isProfilePath = profilePaths.includes(pathname);
  const isFreeTrial =
    searchParams.get("free_trial") === "true" ||
    (!isFullAccess && isProfilePath);

  const filteredBreadcrumbs = authSteps.filter(
    (step) =>
      !isFreeTrial ||
      (isFreeTrial && !step.isHideForFree && !isProfilePath) ||
      (isFreeTrial && step.isHideForFree && isProfilePath)
  );

  useEffect(() => {
    const stepDetail = authSteps.find(({ route }) => route === pathname);

    if (containerRef) {
      containerRef?.current?.scroll({ top: 0, behavior: "smooth" });
    }
    if (stepDetail) {
      setCurrentRouteDetails(stepDetail);
      setCurrentStep(stepDetail.step);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderLogo = () => {
    switch (true) {
      case isMobile:
        return <img src={TgtextMobile} className={classes.icon} />;
      case isProfilePath:
        return <img src={TitleIcon} className={classes.profileIcon} />;
      default:
        return (
          <img src={wishLampIcon} alt="wish-lamp" className={classes.icon} />
        );
    }
  };

  return (
    <div
      className={cx(classes.wrapper, {
        [classes.isNotDefaultStep]: isMobile && !isDefaultStep,
      })}
    >
      <div className="position-relative">
        <div className={classes.logoWrapper}>{renderLogo()}</div>
      </div>
      <div className={classes.progressWrapper}>
        {showBreadcrumb(currentStep) && (
          <div className={classes.progress}>
            {filteredBreadcrumbs?.map(({ step }, index) => (
              <div
                className={cx(classes.progressBar, {
                  [classes.activeStep]: step === currentStep,
                })}
                key={index}
              ></div>
            ))}
          </div>
        )}
      </div>

      <div className={cx(classes.headerDetails)}>
        <div
          className={cx(classes.content, {
            [classes.detailsStep1]: currentRouteDetails.step === 1,
            [classes.detailsStep3]: currentRouteDetails.step === 3,
            [classes.detailsStep4]: currentRouteDetails.step === 4,
          })}
        >
          {currentRouteDetails.title && (
            <Typography
              className={cx(classes.accessTitle, {
                [classes.profileTitle]: isProfilePath,
              })}
            >
              {!isFreeTrial && currentRouteDetails.step === 0
                ? "Sign Up"
                : currentRouteDetails.title}
            </Typography>
          )}
          {currentRouteDetails.description && (
            <Typography variant="p24" className={classes.description}>
              {currentRouteDetails.description}
            </Typography>
          )}
          {currentRouteDetails.additionalDetails && (
            <Typography
              variant="p24"
              className={cx(classes.additionalDetails, {
                [classes.detailsStep3]: currentRouteDetails.step === 3,
              })}
            >
              {!isFreeTrial && currentRouteDetails.step === 0
                ? "Join at anytime, cancel anytime."
                : currentRouteDetails.additionalDetails}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthBreadcrumb;

import cn from "classnames";
import React from "react";
import Text from "src/components/Text";
import classes from "./RangeFinderInfo.module.scss";

interface Props {
  children: React.ReactNode;
  className?: string;
  title: string | React.ReactNode;
}

const DetailsBlock = ({ children, className, title }: Props) => {
  return (
    <div className={cn(classes.detailsBlock, className)}>
      <Text className="mb-3" size={18} color="#fff" fontWeight="bold">
        {title}
      </Text>
      <div className={cn(classes.detailsBlockInner)}>{children}</div>
    </div>
  );
};

export default DetailsBlock;

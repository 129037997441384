import React from "react";
import { isMobile } from "react-device-detect";
import { Container } from "reactstrap";
import AppDownload from "src/components/AppDownload";
import Button from "src/components/Button";
import Spinner from "src/components/Spinner";
import Typography from "src/components/Typography";
import useUserProfile, {
  useUserProfileLoading,
} from "src/helpers/hooks/useUserProfile";
import CheckIcon from "src/pages/Auth/assets/images/check-icon.png";
import TgtextMobile from "src/pages/Subscription/assets/tg-mobile-text.png";

import { getUploadedPostDate } from "src/utils/date";
import classes from "./Complete.module.scss";

type Props = {
  onClose: () => void;
  planId: string | null;
};

const Complete: React.FC<Props> = ({ onClose, planId }) => {
  const userProfile = useUserProfile();
  const userProfileLoader = useUserProfileLoading();
  const welcomeText = "Congratulations!";
  const successText = "You’ve successfully subscribed to Wish Lamp$";
  const validTill = getUploadedPostDate(userProfile?.subscription?.valid_till);
  const mobileRedirectLink = process.env.REACT_APP_MOBILE_APP_DEEP_LINK_URL;

  if (userProfileLoader) {
    return (
      <div className="d-flex align-items-center w-100">
        <Spinner center />
      </div>
    );
  }

  return (
    <Container className={classes.wrapper}>
      <div className={classes.tgMobileImg}>
        <img src={TgtextMobile} alt="tg-text" />
      </div>
      <img className={classes.checkIcon} src={CheckIcon} alt="check-icon" />
      <div className={classes.content}>
        <Typography className={classes.welcomeText} fontFamily="milonga">
          {welcomeText}
        </Typography>
        <Typography
          className={classes.infoText}
          variant="p18"
          fontFamily="inter"
        >
          {successText}
        </Typography>
      </div>
      <div className={classes.buttonWrapper}>
        <Typography variant="p16" fontFamily="inter">
          Click the access button below to continue on your browser.
        </Typography>
      </div>
      {planId && (
        <div className={classes.changeSubscriptionText}>
          <Typography
            variant="p16"
            fontFamily="inter"
            className={classes.validTillText}
          >
            You will be billed and your subscription will be updated once your
            previous subscription comes to an end on
          </Typography>
          <Typography variant="p16" fontFamily="inter">
            {validTill}.
          </Typography>
        </div>
      )}
      {isMobile && (
        <div className={classes.accessOnMobile}>
          <a href={mobileRedirectLink}>
            <Button
              buttonColor="secondary"
              onClick={() => {}}
              buttonText={
                <Typography className={classes.access} variant="p20">
                  Access In app
                </Typography>
              }
              variant="link"
            />
          </a>
        </div>
      )}
      <div className={classes.footer}>
        <Typography variant="p20" className={classes.footerText}>
          Unlock the App on Your Mobile Device
        </Typography>
        <AppDownload hideTGSIcon />
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          buttonText="Get Access"
          buttonColor="secondary"
          type="submit"
          //   loading={loading}
          onClick={onClose}
          buttonClassName={classes.button}
        />
      </div>
    </Container>
  );
};

export default Complete;

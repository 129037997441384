import React, { useEffect } from "react";
import Engagement from "./components/Engagement";
import Notifications from "./components/Notifications";
import PlayPreview from "./components/PlayPreview";
import Prizes from "./components/Prizes";
import Watch from "./components/Watch";
import { useActions } from "./selectorData";
import classes from "./styles.module.scss";

const MyDashboard = () => {
  const {
    getUsersSavedRegionList,
    getDashboardWatch,
    getDashboardPrizes,
    getDashboardAnnouncements,
    getDashboardEngagements,
    getNewCluesAvailable,
  } = useActions();
  useEffect(() => {
    getUsersSavedRegionList();
    getDashboardWatch();
    getDashboardPrizes();
    getDashboardAnnouncements();
    getDashboardEngagements();
    getNewCluesAvailable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <PlayPreview />
      <Watch />
      <Prizes />
      <Notifications />
      <Engagement />
    </div>
  );
};

export default MyDashboard;

import get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import Spinner from "src/components/Spinner";
import { ROLE_NAME } from "src/helpers/constants/role";
import useUserProfile from "src/helpers/hooks/useUserProfile";
import { getSeasons } from "src/store/actions/clue";
import { getUsersSavedRegionList } from "src/store/actions/regions";
import { useStoreActions } from "src/store/hooks";
import { getAccessLevel } from "src/utils/role";
import { useUserActions } from "src/utils/useActions";
import { useUserData } from "src/utils/useData";
import CountdownModal from "./BaseComponents/CountdownModal";
import Clue from "./Clue";
import SelectRegions from "./SelectRegions";
import FreeTrialSignUpSuccessModal from "./SelectRegions/Components/FreeTrialSignUpSuccessModal";
import classes from "./styles.module.scss";

const Play = () => {
  const { pathname } = useLocation();
  const actions = useStoreActions({
    getUsersSavedRegionList,
    getSeasons,
  });
  const userProfile = useUserProfile();
  const accessLevel = getAccessLevel(
    get(userProfile, "user.role.name", ROLE_NAME.FREE_ACCESS)
  );
  const [searchParams] = useSearchParams();
  const freeTrialSignUpSuccess =
    searchParams.get("free_trial_signup_success") === "true";
  const isPaymentSuccess = searchParams.get("payment_success") === "true";
  const subscriptionId = searchParams.get("subscription_id") || "";
  const { getPlayCountdown } = useUserActions();
  const [loading, setLoading] = useState(false);
  const [showFreeTrialSignUpSuccessModal, setShowFreeTrialSignUpSuccessModal] =
    useState(false);
  const {
    playCountdown: { loading: modalLoading },
  } = useUserData();

  const fetchSavedRegions = async () => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    actions.getUsersSavedRegionList();
    setLoading(false);
  };

  useEffect(() => {
    actions.getSeasons();
    getPlayCountdown();
    if (
      (accessLevel === "free-trial" && freeTrialSignUpSuccess) ||
      isPaymentSuccess
    ) {
      setShowFreeTrialSignUpSuccessModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPage = () => {
    switch (pathname) {
      case "/play":
        return <SelectRegions fetchSavedRegions={fetchSavedRegions} />;
      case "/play/clues":
        return <Clue />;
      default:
        return <SelectRegions fetchSavedRegions={fetchSavedRegions} />;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {modalLoading || loading ? (
        <Spinner
          wrapperClassName={classes.spinnerWrapper}
          className={classes.spinner}
          wholePage
          center
        />
      ) : (
        <div id="play-section" className={classes.modalWrapper}>
          <CountdownModal />
        </div>
      )}
      {showFreeTrialSignUpSuccessModal && (
        <FreeTrialSignUpSuccessModal
          isOpen={showFreeTrialSignUpSuccessModal}
          onClose={() => setShowFreeTrialSignUpSuccessModal(false)}
          isPaymentSuccess={isPaymentSuccess}
          subscriptionId={subscriptionId}
        />
      )}
      {renderPage()}
    </div>
  );
};

export default Play;

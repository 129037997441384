import React, { useEffect } from "react";

import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import { useWindowSize } from "src/utils/useWindowSize";

import PlansDesktop from "./PlansDesktop";
import PlansMobile from "./PlansMobile";
import { Props } from "./types";

const Plans: React.FC<Props> = ({ handleSelectedPlan, selectedPlans }) => {
  const { width = 0 } = useWindowSize();

  const isMobile = width <= 768;

  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.plan_selection_signup);
  }, []);

  return (
    <>
      {isMobile ? (
        <PlansMobile
          handleSelectedPlan={handleSelectedPlan}
          selectedPlans={selectedPlans}
        />
      ) : (
        <PlansDesktop handleSelectedPlan={handleSelectedPlan} />
      )}
    </>
  );
};

export default Plans;

import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { ModalBody } from "reactstrap";
import Map from "src/assets/images/region-map.png";
import Modal from "src/components/Modal";
import Spinner from "src/components/Spinner";
import Text from "src/components/Text";
import { usePlayCountdown } from "src/utils/helper";
import { useUserData } from "src/utils/useData";
import classes from "./CountdownModal.module.scss";

interface Props {
  className?: string;
}

const mapInfo = [
  "You credit card will not be charged until this first game of  Wish Lamp$ actually starts on January 1, 2024.",
  "You will be able to choose the regions you want to play starting on Jan 1 at 12:00 AM EST.",
  "The first clue will drop Jan 1 at 12:01 AM EST. The first clue will be a poem that if deciphered correctly could lead you to the Lamp on the first day!",
  "From then on there will be a minimum of 4 clues released per day per active region until all active Lamps are found.",
  "There will always be at least 1 Active Region at game launch and as many as the max for the season, which in 2024 is 10 lamps per season.",
  "The number of active regions depends on several factors. As the subscriber numbers grow so will the amount of Lamps and Active Regions.",
  "Make sure to read the rules and all the other participation agreements so you know how to play and how to win.",
  "For now, until the game starts, enjoy looking around and viewing and uploading content.",
  "Check out the prizes page for weekly contest because we are already giving out money and prizes even before Wish Lamp$ starts!",
  "Thank you for subscribing and we hope you find a Lamp this year! Happy Holidays!",
];

const CountdownModal = ({ className = "" }: Props) => {
  const {
    playCountdown: { loading },
  } = useUserData();

  const { durations, isPlayCountdownModalVisible } = usePlayCountdown();
  const [isCountdownModal, setIsCountdownModal] = useState(false);
  const countdownModalToggle = () => setIsCountdownModal(!isCountdownModal);

  useEffect(() => {
    setIsCountdownModal(isPlayCountdownModalVisible);
  }, [isPlayCountdownModalVisible]);

  const { days = 0, hours = 0, minutes = 0, seconds = 0 } = durations;

  const countDownValues = useMemo(
    () => [
      {
        type: "Days",
        value: days,
      },
      {
        type: "Hours",
        value: hours,
      },
      {
        type: "Minutes",
        value: minutes,
      },
      {
        type: "Seconds",
        value: seconds,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [durations]
  );

  const leadingZero = (num: number) => {
    return num < 10 ? "0" + num : num;
  };

  return (
    <>
      <Modal
        backdrop={"static"}
        modalClassName={classes.modalStyle}
        className={cn(classes.modalWrapper, className)}
        contentClassName={classes.modalContent}
        bodyClassName={classes.modalBody}
        backdropClassName={classes.modalBackdrop}
        isOpen={isCountdownModal}
        toggle={countdownModalToggle}
        container="#play-section"
        zIndex={1}
        keyboard={false}
        cssModule={{
          className: classes.mod,
        }}
        style={{
          position: "relative",
        }}
      >
        <ModalBody className={classes.modalBody}>
          <div className={classes.map}>
            <img src={Map} alt="region map" />
          </div>
          {loading ? (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          ) : (
            <div className={classes.countdown}>
              {countDownValues.map((item, index) => (
                <div className="d-flex flex-column text-start" key={index}>
                  <Text
                    className={cn(classes.text, "m-1 mb-0")}
                    color="#fff"
                    size={14}
                    fontWeight="semibold"
                  >
                    {item.type}
                  </Text>
                  <Text
                    className={classes.text}
                    color="#A827F8"
                    size={62}
                    fontWeight="semibold"
                  >
                    {leadingZero(item.value)}
                    {index < countDownValues.length - 1 ? ":" : ""}
                  </Text>
                </div>
              ))}
            </div>
          )}

          <div className="mt-5 lh-sm text-start">
            <div>
              <Text color="#FFF" size={30} fontWeight="semibold">
                We’re almost there!
              </Text>
              <div className="mt-3 ">
                <Text color="#FFF" size={20} fontWeight="medium">
                  Welcome to the Play section of Wish Lamp$. This is where you
                  will be able to choose the regions you want to play and see
                  the clues to play the game.
                </Text>
              </div>
            </div>
            <div className="mt-3">
              <Text color="#FFF" size={20} fontWeight="semibold">
                There are several things to be aware of:
              </Text>
              <Text
                color="#FFF"
                size={16}
                fontWeight="medium"
                className="mb-50"
              >
                <ul>
                  {mapInfo.map((text, index) => (
                    <li key={index} className="mt-1">
                      {text}
                    </li>
                  ))}
                </ul>
              </Text>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CountdownModal;

import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useState } from "react";
import { ButtonProps } from "reactstrap";
import Button from "src/components/Button";
import Spinner from "src/components/Spinner";
import Text from "src/components/Text";
import { RangeFinder } from "src/types/clue";
import classes from "./RangeInputMap.module.scss";

export type LatLng = {
  lat: number;
  lng: number;
};

type Props = {
  next: () => void;
  prev: () => void;
  isLoaded: boolean;
  handlePinLocation: (markerPosition: LatLng) => void;
  BackButton: (props: ButtonProps) => JSX.Element;
  center: LatLng;
  statesPosition: LatLng[] | null;
  selectedOption: RangeFinder | null;
};

const RangeInputMap: React.FC<Props> = ({
  isLoaded = false,
  handlePinLocation,
  BackButton,
  center,
  statesPosition = [],
  selectedOption,
}) => {
  // const googleMapApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [isMarkerPlaced, setIsMarkerPlaced] = useState(false);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event?.latLng?.lat() as number;
    const lng = event?.latLng?.lng() as number;
    setMarkerPosition({ lat, lng });
    setIsMarkerPlaced(true);
  };

  const containerStyle = {
    width: "100%",
    height: "500px",
    borderRadius: "8px",
  };

  return (
    <div className={classes.container}>
      <BackButton />
      <div className={classes.content}>
        <Text fontFamily="inter" size={24} fontWeight="semibold" color="#fff">
          Place Pin on Map
        </Text>
        <Text
          fontFamily="inter"
          size={16}
          style={{ lineHeight: "normal" }}
          color="#fff"
        >
          Think you have an idea where the lamp might be? See if your hunch is
          correct. Place the pin and we’ll let you know if it is within{" "}
          {selectedOption?.range || "-"} from the Outer Zone.
        </Text>
      </div>
      <div className={classes.mapWrapper}>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={5} // Initial zoom level
            onClick={handleMapClick}
            onLoad={(map: any) => {
              setMap(map);
              if (map && statesPosition && statesPosition?.length > 1) {
                const bounds = new window.google.maps.LatLngBounds();
                statesPosition?.forEach((position) => {
                  bounds.extend(position);
                });
                map.fitBounds(bounds);
              }
            }}
            options={{
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
            }}
          >
            {map &&
              statesPosition &&
              statesPosition.map((state, i) => (
                <Marker key={i} position={state} icon={{ path: "" }}></Marker>
              ))}
            {map && isMarkerPlaced && (
              <Marker
                position={markerPosition}
                icon={"http://maps.google.com/mapfiles/ms/icons/purple-dot.png"}
              ></Marker>
            )}
          </GoogleMap>
        ) : (
          <Spinner center />
        )}
      </div>
      <div className={classes.footer}>
        <Button
          disabled={!isMarkerPlaced}
          buttonClassName={classes.proceedButton}
          onClick={() => handlePinLocation(markerPosition)}
          buttonText={"Proceed to Payment"}
        />
      </div>
    </div>
  );
};

export default RangeInputMap;

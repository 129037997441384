import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import { Spinner } from "reactstrap";
import MediaBlock from "src/pages/Watch/components/MediaBlock";
import { MediaInfo } from "src/types/media";
import { getBasicMediaInfo } from "src/utils/media";
import { useWindowSize } from "src/utils/useWindowSize";
import HeaderText from "../HeaderText";
import { useIndexData } from "./selectorData";
import classes from "./styles.module.scss";

const Engagement = () => {
  const navigate = useNavigate();
  const sliderRef = useRef<Slider | null>(null);
  const [sliderState, setSliderState] = useState(0);
  const { width = 0 } = useWindowSize();
  const { dashboardEngagements, dashboardEngagementsLoading } = useIndexData();
  const engagements = dashboardEngagements.engagements || [];
  const engagementsLength = engagements.length;

  const SLIDES_TO_SHOW = width > 1024 ? 3 : width > 480 ? 2 : 1;
  const isLast = sliderState + SLIDES_TO_SHOW === engagementsLength;
  const isFirst = sliderState === 0;
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: SLIDES_TO_SHOW,
    slidesToScroll: 1,
    afterChange: (current: number) => {
      setSliderState(current);
    },

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <HeaderText section="engagement" text="Engagement" />
          {dashboardEngagementsLoading && (
            <Spinner className={classes.loading} color="light" />
          )}
          <div className={classes.sliderActions}>
            <div
              onClick={() => sliderRef.current?.slickPrev()}
              className={cx(classes.iconWrapper, {
                [classes.disableFirst]: isFirst,
              })}
            >
              <FontAwesomeIcon size="lg" color="#fff" icon={faChevronLeft} />
            </div>
            <div
              onClick={() => sliderRef.current?.slickNext()}
              className={cx(classes.iconWrapper, {
                [classes.disableLast]: isLast,
              })}
            >
              <FontAwesomeIcon size="lg" color="#fff" icon={faChevronRight} />
            </div>
          </div>
        </div>
        <div className={classes.sliderWrapper}>
          <Slider className={classes.slider} ref={sliderRef} {...settings}>
            {engagements?.map((data: MediaInfo) => {
              const media = {
                ...getBasicMediaInfo(data),
                onClick: () => {
                  navigate(`/engagement/playing-media/${data.id}`);
                },
              };
              return (
                <MediaBlock
                  {...media}
                  className={classes.mediaBlock}
                  mediaClassName={classes.media}
                  videoClassName={classes.video}
                  noRatio
                  key={data.id}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Engagement;

import { useEffect } from "react";
import { ANALYTICS_CONSTANTS } from "src/helpers/constants/analytics";
import LeftBg from "src/pages/Home/assets/images/sidebar-left-bg.png";
import RightBg from "src/pages/Home/assets/images/sidebar-right-bg.png";
import { trackPageViewEvent } from "src/utils/googleAnalytics";
import { isLoggedIn } from "src/utils/utils";
import CalloutSection from "../CalloutSection";
import GameAbout from "../GameAbout";
import GameAccount from "../GameAccount";
import GameCards from "../GameCards";
import GameList from "../GameList";
import HeroSection from "../HeroSection";
import Instructions from "../Instructions";
import classes from "./styles.module.scss";
const About = () => {
  const isLogIn = isLoggedIn();
  const redirectLink = isLogIn ? "/subscriptions" : "/auth/access-info";
  useEffect(() => {
    trackPageViewEvent(ANALYTICS_CONSTANTS.page.home);
  }, []);
  return (
    <>
      <CalloutSection redirectLink={redirectLink} />
      <div className={classes.homeWrapper}>
        <div
          style={{
            width: "4vw",
            backgroundImage: `url(${LeftBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
          }}
        />
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <HeroSection redirectLink={redirectLink} />
            <Instructions redirectLink={redirectLink} />
            <GameList />
            <GameAbout redirectLink={redirectLink} />
            <div className={classes.bottomContent}>
              <GameCards />
              <GameAccount redirectLink={redirectLink} />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "4vw",
            backgroundImage: `url(${RightBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
          }}
        />
      </div>
    </>
  );
};
export default About;

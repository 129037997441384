import cn from "classnames";
import Button from "src/components/Button";
import Modal, { ModalBody } from "src/components/Modal";
import classes from "./LimitDeviceModal.module.scss";

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  message?: string;
}

const LimitDeviceModal = ({ className, isOpen, onClose, message }: Props) => {
  return (
    <Modal
      backdrop
      className={cn(classes.modalWrapper, className)}
      bodyClassName={classes.modalBody}
      isOpen={isOpen}
      toggle={onClose}
      color="black"
    >
      <ModalBody>
        <h1>Registered Device Limit</h1>
        <p>{message}</p>
        <Button
          buttonText="Okay"
          buttonColor="secondary"
          type="button"
          buttonClassName={classes.button}
          onClick={onClose}
        />
      </ModalBody>
    </Modal>
  );
};

export default LimitDeviceModal;

import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";

export const getMoreAnnouncements = (
  prevState: any,
  payload: any,
  key: string,
  slugKey: string
) => {
  const newAnnouncementsData = cloneDeep(prevState[key].data);
  newAnnouncementsData.channel.sections =
    newAnnouncementsData.channel.sections.map((section: any) => {
      if (section.slug === slugKey) {
        const pastAnnouncementPayload = find(payload.data.channel.sections, {
          slug: slugKey,
        });
        section.posts = [
          ...section.posts,
          ...(pastAnnouncementPayload?.posts || []),
        ];
        section.meta = {
          ...(pastAnnouncementPayload?.meta || {}),
        };
      }
      return section;
    });
  return newAnnouncementsData;
};

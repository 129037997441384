import React from "react";
import { Link } from "react-router-dom";
import AppDownload from "src/components/AppDownload";
import Typography from "src/components/Typography";
import CopyrightIcon from "src/pages/Home/assets/icons/copy-right-icon.svg";
import Tsg from "src/pages/Home/assets/images/tgs-img.svg";
import {
  TGS_BASE_URL,
  tawkPropertyId,
  tawkWidgetId,
} from "src/utils/constants";
import { currentYear } from "src/utils/utils";
import classes from "./styles.module.scss";

const Footer = () => {
  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.upperContainer}>
        <div className={classes.button}>
          <Typography className={classes.footerText}>
            Unlock the App on Your Mobile Device
          </Typography>
          <div>
            <AppDownload downloadClassName={classes.downloadBtn} />
          </div>
        </div>
      </div>
      <div className={classes.lowerContainer}>
        <div className={classes.leftSection}>
          <img src={Tsg} alt="img" height={60} width={120} />
          <Typography
            className={classes.copyRight}
            variant="p12"
            fontFamily="inter"
          >
            <img src={CopyrightIcon} alt="copy-right" /> Copyright {currentYear}
          </Typography>
          <div className={classes.links}>
            <a href={`${TGS_BASE_URL}/a/privacy-policy-ip-policy`}>Privacy</a>{" "}
            <span></span>
            <a href={`${TGS_BASE_URL}/a/terms-and-conditions`}>
              Terms and Conditions
            </a>
            <span></span>
            <a href={`${TGS_BASE_URL}/a/tg-risk-waiver`}>Risk Waiver</a>
          </div>
        </div>
        <div className={classes.rightSection}>
          <div className={classes.footerLinks}>
            <Link to={"/about"}>About Treasure Game$</Link>
            <a href={`${TGS_BASE_URL}/a/tg-participation-agreement`}>
              Participation Agreement
            </a>
            <a href={`${TGS_BASE_URL}/a/community-guidelines`}>
              Community Guidelines
            </a>
            <a href={`${TGS_BASE_URL}/a/impact-investment-program`}>
              Impact Investment Program
            </a>
            <a
              target="_blank"
              href={`https://tawk.to/chat/${tawkPropertyId}/${tawkWidgetId}`}
              rel="noreferrer"
            >
              Contact
            </a>
            <a href={`${TGS_BASE_URL}/a/employment`}>Employment</a>
            <a href={`${TGS_BASE_URL}/a/faq`}>FAQ</a>
            <a href={`${TGS_BASE_URL}/a/partners`}>Partners</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
